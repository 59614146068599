// @flow

import StringUtil from "../../util/StringUtil";
import PageUrl from "./PageUrl";

export default class TopicPageUrl {

    static list(): string {
        return PageUrl.TOPIC_URL;
    }

    static create(): string {
        return PageUrl.TOPIC_CREATE_URL;
    }

    static detail(topicId: string, description: string): string {

        return StringUtil.replaceAllText(PageUrl.TOPIC_DETAIL_URL, [
            {
                key: ":topicId",
                value: topicId + ""
            }, {
                key: ":topicDescription",
                value: description ? StringUtil.removeBlacklistChar(description) : "detail"
            }
        ]);
    }


}