// @flow

import React from 'react';
import swal from "sweetalert2";
import {LLMsg} from "../../IntlCapture";
import QRCode from 'qrcode';
import Api from "../../bundle/Api";
import Page from "../../bundle/Page";
import styles from "./ProjectDetailFragment/ProjectDetailActionBar.css";
import Image from "../../bundle/Image";
import UserDao from "../../dao/UserDao";
import ErrorUtil from "../../util/ErrorUtil";
import {ClassNames} from "../../bundle/ClassNames";


type ShareProjectButtonProps = {
    projectId: string,
    projectName: string
}

export const shareProjectPrompt = (projectId: string, projectName: string) => {

    if (!projectId || !projectName) {
        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        return;
    }

    Api.services.project.share.getToken(projectId)
        .then((response) => {
            if (response.status === 200) {
                let shareLink = Page.convertToFullPath(Page.internals.urls.project.joinProjectByToken(response.data.token));
                swal({
                    title: LLMsg("COMMON.PROJECT.SHARE_PROJECT"),
                    html: '<canvas id="qrcode"></canvas>' +
                        '<div class="m--icon-font-size-lg1">' + LLMsg("COMMON.PROJECT.QRCODE_DESC") + '</div>' +
                        '<input class="form-control m-input mt-3 mb-3" type="text" value="' + shareLink + '" readonly>',
                    showCancelButton: false,
                    confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
                    onOpen: (dom) => {
                        let qrcode = dom.querySelector(".swal2-content #swal2-content #qrcode");
                        QRCode.toCanvas(qrcode, shareLink, {width: 300});
                    },
                    inputValidator: (value) => {
                        return new Promise((resolve) => {
                            if (value) {
                                resolve()
                            } else {
                                resolve(LLMsg("COMMON.PROJECT.MISSING_PROJECT_NAME"));
                            }
                        })
                    }
                })
            } else {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            }
        })
        .catch(() => {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        });


};

const ShareProjectButton = (props: ShareProjectButtonProps) => {
    let projectId = props.projectId;
    let projectName = props.projectName;

    return <div className={styles.actionDiv}>
        <div className={ClassNames(styles.actionButton, "btn btn-primary m-btn m-btn--custom")} onClick={() => {
            if (UserDao.permission.allowShareProject) {
                shareProjectPrompt(projectId, projectName);
            } else {
                ErrorUtil.showPermissionNotAllowed();
            }
        }}>
            {LLMsg("COMMON.ACTION.SHARE")}
        </div>
    </div>;


};

export default ShareProjectButton;