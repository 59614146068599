// @flow

class UserAction {
    static CREATE_USER_ACTION_TYPE = "CREATE_USER_ACTION";
    static GET_LIST_OF_USER_ACTION_TYPE = "GET_LIST_OF_USER_ACTION";
    static GET_ONE_USER_ACTION_TYPE = "GET_ONE_USER_ACTION";
    static UPDATE_USER_ACTION_TYPE = "UPDATE_USER_ACTION";
    static USER_LOGIN_ACTION_TYPE = "USER_LOGIN_ACTION";
    static USER_LOGOUT_ACTION_TYPE = "USER_LOGOUT_ACTION";

    static GET_MYSELF_DONE_ACTION_TYPE = "GET_MYSELF_DONE_ACTION";

}

export default UserAction;