exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1lF6cnsqkYAVsUii7el2aO {\n    cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"userRow": "_1lF6cnsqkYAVsUii7el2aO"
};