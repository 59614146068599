// @flow

import React from 'react';
import styles from './index.css';
import {ClassNames} from "../../../bundle/ClassNames";
import imageStyleTransfer from './image.svg';
import imageRecognition from './imageRecognition.svg';
import faceDetection from './faceDetection.svg';
import poseCapture from './poseCapture.svg';
import textToSpeech from './textToSpeech.svg';
import imageClassification from './imageClassification.svg';
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import UrlBuilder from "../../../url/UrlBuilder";
import type {Project} from "../../../model/model";
import Constant from "../../../bundle/Constant";
import {LLMsg} from "../../../IntlCapture";

type Props = {
    project: Project
}

export default class ProjectOverviewAiFragment extends React.PureComponent<Props, any> {

    renderItem(title: string, desc: string, image: any, queryString: string) {

        const projectId = this.props.project.id;
        const projectName = this.props.project.projectName;
        const query = UrlBuilder.getQueryString();
        query.ai = queryString;

        return <div className={ClassNames(styles.item, "col-12 col-md-6 col-lg-4 p-2")}>
            <Link className={ClassNames("no-a-effect", styles.itemInner)}
                  to={Page.internals.urls.project.detail(projectId, projectName, query)}>
                <div className={styles.itemImageBox}>
                    <img src={image} alt={""} className={styles.itemImage}/>
                </div>
                <div className={styles.itemContent}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.desc}>{desc}</span>
                </div>
            </Link>
        </div>
    }

    render() {

        return (
            <div className={"row"}>

                {this.renderItem(LLMsg("AI.IMAGE_RECOGNITION_TITLE"), LLMsg("AI.IMAGE_RECOGNITION_DESC"), imageRecognition, Constant.aiSection.imageRecognition)}
                {this.renderItem(LLMsg("AI.FACE_DETECTION_TITLE"), LLMsg("AI.IMAGE_RECOGNITION_DESC"), faceDetection, Constant.aiSection.faceDetection)}
                {this.renderItem(LLMsg("AI.IMAGE_STYLE_TRANSFER_TITLE"), LLMsg("AI.IMAGE_RECOGNITION_DESC"), imageStyleTransfer, Constant.aiSection.imageStyleTransfer)}
                {/*{this.renderItem(LLMsg("AI.IMAGE_CLASSIFICATION_TITLE"), LLMsg("AI.IMAGE_RECOGNITION_DESC"), imageClassification, Constant.aiSection.imageClassification)}*/}
                {/*{this.renderItem(LLMsg("AI.AUDIO_CLASSIFICATION_TITLE"), LLMsg("AI.IMAGE_RECOGNITION_DESC"), textToSpeech, Constant.aiSection.audioClassification)}*/}
                {/*{this.renderItem(LLMsg("AI.POSE_CAPTURE_TITLE"), LLMsg("AI.IMAGE_RECOGNITION_DESC"), poseCapture, Constant.aiSection.poseCapture)}*/}

            </div>
        );
    }
}