// @flow

import React, {Component} from "react";
import styles from "./index.css";
import * as _ from "lodash";
import {LLMsg} from "../../../../../IntlCapture";
import ChartTypeItem from "../../ChartTypeSelectFragment/ChartTypeItem";
import Constant from "../../../../../bundle/Constant";
import ChartSourceSelectFragment from "../../ChartSourceSelectFragment";
import PointMapFragment from "../../../MapFragment/PointMapFragment";
import type {ChartSource, ChartSourceConfig, Topic} from "../../../../../model/model";
import StringUtil from "../../../../../util/StringUtil";

type PointMapConfigFragmentProps = {}
type PointMapConfigFragmentState = {
    chartNameError: boolean,
    chartSourceError: boolean,
    minValueError: boolean,
    maxValueError: boolean,
}

class PointMapConfigFragment extends Component<PointMapConfigFragmentProps, PointMapConfigFragmentState> {

    constructor(props) {
        super(props);
        this.state = {
            chartNameError: false,
            chartSourceError: false,
            minValueError: false,
            maxValueError: false
        };
        this.getConfigs = this.getConfigs.bind(this);
        this.validate = this.validate.bind(this);
    }

    chartNameRef: any;
    minValueRef: any;
    maxValueRef: any;
    chartSourceRef: any = React.createRef();
    pointMapRef: any = React.createRef();

    isPickingLocation: boolean = false;

    getConfigs = () => {
        return {
            "CHART_NAME": _.get(this.chartNameRef, "value", undefined),
            "MIN_VALUE": _.get(this.minValueRef, "value", undefined),
            "MAX_VALUE": _.get(this.maxValueRef, "value", undefined),
            "CHART_SOURCE": this.chartSourceRef.current && this.chartSourceRef.current.getConfigs()
        }
    }

    validate = () => {
        let isValid = true;
        let error = {
            chartNameError: false,
            chartSourceError: false,
            minValueError: false,
            maxValueError: false
        };

        let chartName = _.get(this.chartNameRef, "value", undefined);
        let minValue = _.get(this.minValueRef, "value", undefined);
        let maxValue = _.get(this.maxValueRef, "value", undefined);
        let configs = this.chartSourceRef.current ? this.chartSourceRef.current.getConfigs() : [];

        if (!chartName) {
            isValid = false;
            error.chartNameError = true;
        }

        if (!minValue || !StringUtil.isNumber(minValue)) {
            isValid = false;
            error.minValueError = true;
        }

        if (!maxValue || !StringUtil.isNumber(maxValue)) {
            isValid = false;
            error.maxValueError = true;
        }

        if (error.minValueError == false && error.maxValueError == false &&
            minValue >= maxValue) {
            isValid = false;
            error.minValueError = true;
            error.maxValueError = true;
        }

        if (configs.length === 0) {
            isValid = false;
            error.chartSourceError = true
        } else {
            configs.map((config) => {
                if (!config.topicId || !config.dataTypeId || !config.description ||
                    !StringUtil.isNumber(config.latitude) || !StringUtil.isNumber(config.longitude)) {
                    isValid = false;
                    error.chartSourceError = true;
                }
            });
        }

        this.setState(error);
        return isValid;
    }

    onChartSourceUpdated = () => {
        this.isPickingLocation = false;
        this.forceUpdate();
    }

    chartSourceConverter = (chartSourceConfigs: Object): Array<ChartSource> => {
        let chartSources: Array<ChartSource> = [];

        if (chartSourceConfigs != undefined) {
            chartSourceConfigs.forEach((config) => {
                if (isFinite(config.latitude) && isFinite(config.longitude)) {
                    let chartSource: ChartSource = {};
                    let chartSourceConfig: ChartSourceConfig = {};
                    chartSourceConfig.description = config.description;
                    let topic: Topic = {};
                    topic.latitude = Number(config.latitude);
                    topic.longitude = Number(config.longitude);
                    chartSource.chartSourceConfig = chartSourceConfig;
                    chartSource.topic = topic;
                    chartSource.id = config.tempChartSourceId;
                    chartSources.push(chartSource);
                }
            });
        }

        return chartSources;
    }

    onTriggerPickingLocation = () => {
        this.isPickingLocation = true;
        this.forceUpdate();
    }

    onPickedLocation = () => {
        this.forceUpdate();
    }

    getPickedLocation = (): window.google.maps.latLng => {
        let config = this.pointMapRef.current ? this.pointMapRef.current.getConfigs() : null;
        if (!config) {
            return null;
        }
        return config.pickedLocation;
    }

    render() {
        return <div>
            <h3>
                <strong>{LLMsg("COMMON.STEP.STEP_2")} - </strong>
                <span>{LLMsg("COMMON.CHART.CHART_SETTING")}</span>
            </h3>
            <hr/>
            <div>

                <div className="row">
                    <div className={"col-6"}>
                        <div
                            className={"form-group m-form__group row " + (this.state.chartNameError ? styles.error : "")}>

                            <div className={"col-12 " + styles.title}>
                                {LLMsg("COMMON.CHART.CHART_NAME")}
                            </div>
                            <div className="col-12">
                                <input className="form-control m-input" type="text" ref={(ref) => {
                                    this.chartNameRef = ref
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-6"}>
                    </div>
                </div>

                <div className={"form-group m-form__group row"}>
                    <div className="col-12">
                        <PointMapFragment
                            ref={this.pointMapRef}
                            chartSources={this.chartSourceConverter(this.getConfigs().CHART_SOURCE)}
                            isSetting={true}
                            customHeight={"400px"}
                            isPickingLocation={this.isPickingLocation}
                            onPickedLocation={this.onPickedLocation}/>
                    </div>
                </div>

                <div className={"form-group m-form__group row " + (this.state.chartSourceError ? styles.error : "")}>

                    <div className={"col-12 " + styles.title}>
                        {LLMsg("COMMON.CHART.SELECTED_CHART_SOURCE")}
                    </div>
                    <div className="col-12">
                        <ChartSourceSelectFragment isSingleSource={false} showSimpleMessageOption={false}
                                                   ref={this.chartSourceRef}
                                                   showDeviceOption={false}
                                                   showColorOption={false}
                                                   showDescriptionOption={true}
                                                   showLocationOption={true}
                                                   onUpdateCallback={this.onChartSourceUpdated}
                                                   onTriggerPickingLocation={this.onTriggerPickingLocation}
                                                   pickedLocation={this.getPickedLocation()}/>
                    </div>
                </div>

                <div className={"form-group m-form__group row " + (this.state.minValueError ? styles.error : "")}>

                    <div className={"col-12 " + styles.title}>
                        {LLMsg("COMMON.CHART.MIN_MAX_DESCRIPTION")}
                    </div>

                    <label htmlFor="example-text-input"
                           className="col-2 col-form-label">
                        {LLMsg("COMMON.CHART.CHART_MIN_VALUE")}
                    </label>
                    <div className="col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                        <input className="form-control m-input" type="number" ref={(ref) => {
                            this.minValueRef = ref
                        }}/>
                    </div>
                </div>
                <div className={"form-group m-form__group row " + (this.state.maxValueError ? styles.error : "")}>

                    <label htmlFor="example-text-input"
                           className="col-2 col-form-label">
                        {LLMsg("COMMON.CHART.CHART_MAX_VALUE")}
                    </label>
                    <div className="col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                        <input className="form-control m-input" type="number" ref={(ref) => {
                            this.maxValueRef = ref
                        }}/>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default PointMapConfigFragment;