exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._23V39MfldYOFVhFvpYVZPB {\n    /*margin-left: 15px;*/\n    text-align: center;\n}\n\n._23V39MfldYOFVhFvpYVZPB:nth-child(odd) {\n    border-right: 1px solid lightgrey;\n}\n\n.eFON8GsaG21KMbEyKc30k {\n    display: block;\n    font-weight: bolder;\n    font-size: 18px;\n}\n\n._3QMTxeeKKB7gRGQjOxH6or._1CsG5PmNceLd-3Ukf472rK ._2X4qbeHbGDprSrxRSuZap6 {\n    color: #4e73df;\n\n}\n\n._3QMTxeeKKB7gRGQjOxH6or._1pgQzDndTHeiCXHC2N0sNg ._2X4qbeHbGDprSrxRSuZap6 {\n    color: #f63e78;\n}\n\n._3QMTxeeKKB7gRGQjOxH6or.yyUsfAOoiEq7RXh6PB72c ._2X4qbeHbGDprSrxRSuZap6 {\n    color: #faa040;\n}\n\n._2X4qbeHbGDprSrxRSuZap6 {\n    font-weight: bold;\n    font-size: 48px;\n    display: block;\n}\n\n._3-Sm2hZNDhVu5aerU61xam {\n    font-size: 26px;\n    margin-bottom: 13px;\n}", ""]);

// exports
exports.locals = {
	"stats": "_23V39MfldYOFVhFvpYVZPB",
	"statsTitle": "eFON8GsaG21KMbEyKc30k",
	"statsSection": "_3QMTxeeKKB7gRGQjOxH6or",
	"style1": "_1CsG5PmNceLd-3Ukf472rK",
	"statsValue": "_2X4qbeHbGDprSrxRSuZap6",
	"style2": "_1pgQzDndTHeiCXHC2N0sNg",
	"style3": "yyUsfAOoiEq7RXh6PB72c",
	"statsSectionTitle": "_3-Sm2hZNDhVu5aerU61xam"
};