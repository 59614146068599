// @flow

import React from 'react';
import styles from './index.css';
import type {LicenseBriefInfo} from "../../../../model/model";
import ErrorUtil from "../../../../util/ErrorUtil";
import Api from "../../../../bundle/Api";
import {Link} from "react-router-dom";
import Page from "../../../../bundle/Page";


type LicenseOverallInfoFragmentState = {
    briefInfo: LicenseBriefInfo
}

class LicenseOverallInfoFragment extends React.Component <any, LicenseOverallInfoFragmentState> {

    state = {briefInfo: {}};

    componentDidMount() {
        this.getOverallInfo();
    }

    getOverallInfo() {
        Api.services.license.getOverallInfo().then((response) => {
            if (response.status === 200) {
                let briefInfo: LicenseBriefInfo = response.data;
                this.setState({briefInfo});
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
        });
    }

    render() {
        return <div className={"mt-3 mb-3"}>
            <div className="row mb-3">
                <div className={"col-6"}>
                    <div className={"m-stack m-stack--ver m-stack--general m-stack--demo " + styles.item}>
                        <div
                            className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--fluid">
                            <span className={styles.itemTitle}>Total license</span>
                            <span className={styles.itemSubtitle}>Total number of license generated</span>

                        </div>
                        <div className="m-stack__item m-stack__item--center m-stack__item--middle"
                             style={{width: '40%'}}>
                            <span className={styles.itemNumber}>
                                {this.state.briefInfo.totalLicense}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={"m-stack m-stack--ver m-stack--general m-stack--demo " + styles.item}>
                        <div
                            className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--fluid">
                            <span className={styles.itemTitle}>Consumed License</span>
                            <span className={styles.itemSubtitle}>Number of license used by user</span>

                        </div>
                        <div className="m-stack__item m-stack__item--center m-stack__item--middle"
                             style={{width: '40%'}}>
                            <span className={styles.itemNumber}>
                                {this.state.briefInfo.totalConsumedLicense}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className={"col-6"}>
                    <div className={"m-stack m-stack--ver m-stack--general m-stack--demo " + styles.item}>

                        <div
                            className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--fluid">
                            <span className={styles.itemTitle}>Licensed Package</span>
                            <span className={styles.itemSubtitle}>Number of hardware package licensed</span>

                        </div>
                        <div className="m-stack__item m-stack__item--center m-stack__item--middle"
                             style={{width: '40%'}}>
                            <span className={styles.itemNumber}>
                                {this.state.briefInfo.totalPackage}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={"m-stack m-stack--ver m-stack--general m-stack--demo " + styles.itemButtonWrapper}>
                        <Link
                            to={Page.internals.urls.license.createLicenseTarget()}
                            className={"m-stack__item m-stack__item--center m-stack__item--middle no-a-effect " + styles.itemButton}
                            style={{width: '50%'}}>
                            Create a new Package
                        </Link>
                        <Link
                            to={Page.internals.urls.license.createLicenseInBatch()}
                            className={"m-stack__item m-stack__item--center m-stack__item--middle no-a-effect " + styles.itemButton}
                            style={{width: '50%'}}>
                            Create Batch of Licenses
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default LicenseOverallInfoFragment;