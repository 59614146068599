// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import {saveAs} from "file-saver";

class ProjectService {

    static doGetListOfProject(): Object {
        return HttpUtil.ajax(Api.urls.project.getListOfProjectUrl(), Api.methods.get, {
            'Content-Type': 'application/json',
        });
    }

    static detail(projectId: string): Object {
        return HttpUtil.ajax(Api.urls.project.detail(projectId), Api.methods.get, {
            'Content-Type': 'application/json',
        });
    }

    static doGetListOfCoordinate(embedMapToken: string): Object {
        return HttpUtil.ajax(Api.urls.project.getListOfCoordinate(embedMapToken), Api.methods.get, {
            'Content-Type': 'application/json',
        })
    }

    static file = {
        getList: (projectId: string): Object => {
            return HttpUtil.ajax(Api.urls.project.file.getList(projectId), Api.methods.get, {
                'Content-Type': 'application/json',
            });
        },
        deleteProjectFile: (projectId: string, fileId: string): Object => {
            return HttpUtil.ajax(Api.urls.project.file.deleteProjectFile(projectId, fileId), Api.methods.post, {
                'Content-Type': 'application/json',
            });
        },
        downloadProjectFile: (projectId: string, fileId: string, filename: string): Object => {
            return HttpUtil.ajax(Api.urls.project.file.downloadProjectFile(projectId, fileId, filename), Api.methods.get, {
                'Content-Type': 'application/json',
            }, undefined, "blob").then((response) => {
                if (response.status === 200) {
                    saveAs(response.data, filename);
                }
            })
        }
    };

    static share = {
        getToken: (projectId: string): Object => {
            return HttpUtil.ajax(Api.urls.project.share.getToken(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {projectId});
        },
        joinByToken: (projectShareToken: string): Object => {
            return HttpUtil.ajax(Api.urls.project.share.join(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {projectShareToken});
        },
        checkHasJoined: (projectShareToken: string): Object => {
            return HttpUtil.ajax(Api.urls.project.share.checkHasJoined(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {projectShareToken});
        },
        removeUser: (projectId: number, userId: number): Object => {
            return HttpUtil.ajax(Api.urls.project.share.remove(projectId, userId), Api.methods.post, {
                'Content-Type': 'application/json',
            });
        }
    };

    static update = {
        name: (projectId: string, projectName: string): Object => {
            return HttpUtil.ajax(Api.urls.project.update.name(), Api.methods.put, {
                'Content-Type': 'application/json',
            }, {projectId, projectName: projectName});
        },
        removeTopicFromProject: (topicId: string, projectId: string): Object => {
            return HttpUtil.ajax(Api.urls.project.update.removeTopicFromProject(), Api.methods.put, {
                'Content-Type': 'application/json',
            }, {topicId, projectId});
        },
        createAndLinkTopicToProject: (projectId: string, topicDescription: string): Object => {
            return HttpUtil.ajax(Api.urls.project.update.createAndLinkTopicToProject(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {topicDescription, projectId});
        },
        linkTopicToProject: (projectId: string, topicId: string): Object => {
            return HttpUtil.ajax(Api.urls.project.update.linkTopicToProject(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {topicId, projectId});
        },
        deleteProjectWithData: (projectId: string): Object => {
            return HttpUtil.ajax(Api.urls.project.update.deleteWithData(projectId), Api.methods.post, {
                'Content-Type': 'application/json',
            });
        },
    };

    static doCreateProject(projectName: string): Object {
        return HttpUtil.ajax(Api.urls.project.getProjectCreateUrl(), Api.methods.post, {
            'Content-Type': 'application/json',
        }, {
            projectName: projectName
        });
    }

    static doGetCreateProjectLimit(): Object {
        return HttpUtil.ajax(Api.urls.project.getCreateProjectLimitUrl(), Api.methods.get, {
            'Content-Type': 'application/json',
        });
    }
}

export default ProjectService;