// @flow
import React, {Component} from 'react';
import SocketIOService from "../../../service/SocketIOService";
import Constant from "../../../bundle/Constant";
import _ from 'lodash';
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import type {ChartConfig, ChartSource} from "../../../model/model";


type GaugeChartFragmentProps = {

    sources: Array<ChartSource>,
    chartId: string,
    config: ChartConfig,
    title: string,
    sizingClass?: string
}

export default class GaugeChartFragment extends Component <GaugeChartFragmentProps, any> {

    chartDiv: any;
    chart: any;
    chartData: any;
    options: {};

    componentWillUnmount() {
        let socket: any = SocketIOService.getSocket();
        this.props.sources.forEach(source => {
            socket.emit(Constant.socket.data.room.leave, "T" + source.topic.id);
        });
    }

    setupSocketEvent() {

        if (!this.props.sources || this.props.sources.length <= 0) {
            this.setState({hasData: false});
            return;
        }

        let socket: any = SocketIOService.getSocket();
        this.props.sources.forEach(source => {
            socket.emit(Constant.socket.data.room.join, "T" + source.topic.id);
        });
        socket.on(Constant.socket.data.room.newData, (data) => {
            let dataObj = SocketIOService.extractChartDataMessage(data);

            let source: ChartSource = this.props.sources[0];
            console.log("source=", source, "dataObj=", dataObj);

            if (source.topic.id !== dataObj.topicId) return;
            if (source.device && source.device.id !== dataObj.deviceId) return;
            if (!_.get(dataObj, "records[" + source.dataRecordType.keyName + "]", undefined)) return;

            this.chartData.setValue(0, 1, parseInt(_.get(dataObj, "records[" + source.dataRecordType.keyName+ "]", undefined)));
            this.chart.draw(this.chartData, this.options);
        });
    }

    constructor(props: any) {
        super(props);

        this.chartDiv = React.createRef();
    }

    componentDidMount() {

        window.google.charts.load('current', {'packages': ['gauge']});
        window.google.charts.setOnLoadCallback(this.drawChart);
        this.setupSocketEvent();
    }


    drawChart = () => {

        this.chartData = window.google.visualization.arrayToDataTable([
            ['Label', 'Value'],
            [this.props.config.text || "", 0]
        ]);

        this.chart = new window.google.visualization.Gauge(this.chartDiv.current);
        this.options = {
            min: this.props.config.minValue, max: this.props.config.maxValue,
            minorTicks: 5
        };

        this.chart.draw(this.chartData, this.options);
    };

    render() {
        return <div className={this.props.sizingClass || "portlet_height_1x"}>
            <Link to={Page.internals.urls.chart.getChartDetailPageUrl(this.props.chartId, this.props.title)}
                  className={"mb-2 m--icon-font-size-lg5 no-a-effect"}>{this.props.title}</Link>
            <div ref={this.chartDiv} className={"google_chart_gauge mt-4 mb-4"}/>
        </div>;

    }
}