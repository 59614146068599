// @flow


import UserApiUrlHelper from "../url/api/UserApiUrlHelper";
import ProjectService from "../service/ProjectService";
import DashboardService from "../service/DashboardService";
import ChartService from "../service/ChartService";
import SocketIOService from "../service/SocketIOService";
import TopicService from "../service/TopicService";
import UserService from "../service/UserService";
import DashboardApiUrlHelper from "../url/api/DashboardApiUrlHelper";
import ChartApiUrlHelper from "../url/api/ChartApiUrlHelper";
import ApiUrl from "../url/api/ApiUrl";
import ProjectApiUrlHelper from "../url/api/ProjectApiUrlHelper";
import TopicApiUrlHelper from "../url/api/TopicApiUrlHelper";
import TopicAction from "../redux/action/TopicAction";
import ProjectAction from "../redux/action/ProjectAction";
import UserAction from "../redux/action/UserAction";
import TopicDispatcher from "../redux/dispatcher/TopicDispatcher";
import ProjectDispatcher from "../redux/dispatcher/ProjectDispatcher";
import HttpUtil from "../util/HttpUtil";
import DataRecordService from "../service/DataRecordService";
import DataRecordApiUrlHelper from "../url/api/DataRecordApiUrlHelper";
import DashboardAction from "../redux/action/DashboardAction";
import ChartAction from "../redux/action/ChartAction";
import type {SimpleAction} from "../redux/reducer";
import DataTypeAction from "../redux/action/DataTypeAction";
import SchoolApiUrlHelper from "../url/api/SchoolApiUrlHelper";
import SchoolService from "../service/SchoolService";
import SchoolAction from "../redux/action/SchoolAction";
import EventTriggerService from "../service/EventTriggerService";
import EventTriggerApiUrlHelper from "../url/api/EventTriggerApiUrlHelper";
import LicenseApiUrlHelper from "../url/api/LicenseApiUrlHelper";
import LicenseService from "../service/LicenseService";
import EventCodeApiUrlHelper from "../url/api/EventCodeApiUrlHelper";
import EventCodeService from "../service/EventCodeService";
import MaintenanceApiUrlHelper from "../url/api/MaintenanceApiUrlHelper";
import MaintenanceService from "../service/MaintenanceService";
import BackendStatusApiUrlHelper from "../url/api/BackendStatusApiUrlHelper";
import BackendStatusService from "../service/BackendStatusService";
import OpenApiApiUrlHelper from "../url/api/OpenApiApiUrlHelper";
import OpenApiService from "../service/OpenApiService";

class Api {

    static paths = ApiUrl;

    static methods = HttpUtil.methods;

    static urls = {
        user: UserApiUrlHelper,
        dashboard: DashboardApiUrlHelper,
        chart: ChartApiUrlHelper,
        project: ProjectApiUrlHelper,
        eventTrigger: EventTriggerApiUrlHelper,
        topic: TopicApiUrlHelper,
        record: DataRecordApiUrlHelper,
        school: SchoolApiUrlHelper,
        license: LicenseApiUrlHelper,
        eventCode: EventCodeApiUrlHelper,
        maintenancePeriod: MaintenanceApiUrlHelper,
        openApi: OpenApiApiUrlHelper,
        backendStatus: BackendStatusApiUrlHelper
    };
    static services = {
        dashboard: DashboardService,
        chart: ChartService,
        project: ProjectService,
        eventTrigger: EventTriggerService,
        record: DataRecordService,
        io: SocketIOService,
        topic: TopicService,
        user: UserService,
        school: SchoolService,
        license: LicenseService,
        eventCode: EventCodeService,
        maintenanceService: MaintenanceService,
        openApiService: OpenApiService,
        backendStatusService: BackendStatusService
    };
    static actions = {
        project: ProjectAction,
        topic: TopicAction,
        user: UserAction,
        dashboard: DashboardAction,
        dataType: DataTypeAction,
        chart: ChartAction,
        school: SchoolAction,
        detectChange: (prevAction?: SimpleAction, action?: SimpleAction, type: string): boolean => {
            let previousTime = prevAction ? prevAction.time : "";
            return !!action && action.type === type && previousTime !== action.time;
        }
    };
    static dispatchers = {
        project: ProjectDispatcher,
        topic: TopicDispatcher
    };
}

export default Api;