// @flow

import React, {Component} from 'react';
import Constant from "../../../../bundle/Constant";
import styles from "./index.css";
import SocketIOService from "../../../../service/SocketIOService";
import {LLMsg} from "../../../../IntlCapture";
import moment from "moment-timezone";
import type {
    DataRecord,
    NotifyActionDone,
    NotifyFilterProcessed,
    NotifyTriggerTriggered,
    Project
} from "../../../../model/model";
import {ClassNames} from "../../../../bundle/ClassNames";
import TableScrollbar from "react-table-scrollbar";
import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom";
import Page from "../../../../bundle/Page";


type ProjectMessageFragmentProps = {
    projectId: number,
    project: Project
}

type ProjectMessageFragmentStats = {
    messageList: Array<{ time: string, topicName: string, content: string }>,
    isExpanded: boolean
}

class ProjectMessageFragment extends Component<ProjectMessageFragmentProps, ProjectMessageFragmentStats> {

    constructor(props: ProjectMessageFragmentProps) {
        super(props);
        this.state = {
            messageList: [],
            isExpanded: false
        }
    }

    componentWillUnmount() {
        let socket: any = SocketIOService.getSocket();
        socket.emit(Constant.socket.data.room.leave, "P" + this.props.projectId);
    }

    setupSocketEvent() {

        let socket: any = SocketIOService.getSocket();
        socket.emit(Constant.socket.data.room.join, "P" + this.props.projectId);
        socket.emit(Constant.socket.data.room.join, "P" + this.props.projectId + "_APPLET");
        socket.on(Constant.socket.data.room.mqttPlainMessage, (data) => {

            let dataObj = SocketIOService.extractMqttRawDataMessage(data);
            let time = moment(dataObj.timeArrival, "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss');
            let msg = LLMsg("COMMON.ACTION.TOPIC_RECEIVED_MSG", {TOPIC_NAME: dataObj.topicName, MSG: dataObj.message});
            let newMessageList = [{
                time: time,
                content: <span>{msg}</span>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });

        socket.on(Constant.socket.data.room.newChart, (data) => {
            data = JSON.parse(data);

            let msg = LLMsg("COMMON.PROJECT.CREATE_CHART_SOCKET", {CHART_NAME: data.name});
            let time = moment(new Date(), "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss');
            let newMessageList = [{
                time: time,
                content: <Link to={Page.internals.urls.chart.getChartDetailPageUrl(data.chartId, data.name)}>
                    {msg}
                </Link>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });

        socket.on(Constant.socket.data.room.newUser, (data) => {
            data = JSON.parse(data);

            let msg = LLMsg("COMMON.PROJECT.NEW_USER_JOINED", {USERNAME: data.username});
            let newMessageList = [{
                time: moment(new Date(), "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss'),
                content: <span>{msg}</span>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });

        socket.on(Constant.socket.data.room.removeUser, (data) => {
            data = JSON.parse(data);

            let msg = LLMsg("COMMON.PROJECT.REMOVED_USER", {USERNAME: data.username});
            let newMessageList = [{
                time: moment(new Date(), "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss'),
                content: <span>{msg}</span>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });

        socket.on(Constant.socket.data.room.newFile, (data) => {
            data = JSON.parse(data);

            let msg = LLMsg("COMMON.PROJECT.UPLOADED_NEW_FILE_SOCKET", {FILE_NAME: data.filename});
            let newMessageList = [{
                time: moment(new Date(), "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss'),
                content: <span>{msg}</span>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });

        socket.on(Constant.socket.data.room.removeFile, (data) => {
            data = JSON.parse(data);

            let newMessageList = [{
                time: moment(new Date(), "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss'),
                content: <span>File removed {data.filename}</span>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });

        socket.on(Constant.socket.data.room.appletLogTrigger, (data: NotifyTriggerTriggered) => {

            data = JSON.parse(data);
            let time = moment(data.timeArrival, "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss');
            let msg = "";
            if (data.triggerKey === Constant.appletLogKey.DIALOGFLOW_TRIGGER_KEY) {
                msg = LLMsg("COMMON.ACTION.DIALOG_FLOW_APPLET_TRIGGERED", {
                    APPLET_NAME: data.appletName,
                    NAME: data.name
                });
            } else if (data.triggerKey === Constant.appletLogKey.MQTT_MESSAGE_TRIGGER_KEY) {
                msg = LLMsg("COMMON.ACTION.MQTT_APPLET_TRIGGERED", {
                    APPLET_NAME: data.appletName,
                    NAME: data.name
                });
            } else if (data.triggerKey === Constant.appletLogKey.WEB_HOOK_TRIGGER_KEY) {
                msg = LLMsg("COMMON.ACTION.WEBHOOK_APPLET_TRIGGERED", {
                    APPLET_NAME: data.appletName,
                    NAME: data.name
                });
            } else if (data.triggerKey === Constant.appletLogKey.OBJECT_DETECTION_TRIGGER_KEY) {
                msg = LLMsg("COMMON.ACTION.OBJECT_DETECTION_APPLET_TRIGGERED", {
                    APPLET_NAME: data.appletName,
                    NAME: data.name
                });
            } else if (data.triggerKey === Constant.appletLogKey.FACE_DETECTION_TRIGGER_KEY) {
                msg = LLMsg("COMMON.ACTION.FACE_DETECTION_APPLET_TRIGGERED", {
                    APPLET_NAME: data.appletName
                });
            }

            let newMessageList = [{
                time: time,
                content: <span>{msg}</span>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });

        socket.on(Constant.socket.data.room.appletLogFilter, (data: NotifyFilterProcessed) => {

            data = JSON.parse(data);
            let time = moment(data.timeArrival, "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss');
            let msg = "";
            if (data.isSuccess) {
                msg = LLMsg("COMMON.ACTION.APPLET_FILTER_RESULT_SUCCESS", {
                    APPLET_NAME: data.appletName
                });
            } else {
                msg = LLMsg("COMMON.ACTION.APPLET_FILTER_RESULT_FALL", {
                    APPLET_NAME: data.appletName
                });
            }

            let newMessageList = [{
                time: time,
                content: <span>{msg}</span>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });

        socket.on(Constant.socket.data.room.appletLogAction, (data: NotifyActionDone) => {
            console.log("action received: ", data);
            data = JSON.parse(data);
            let time = moment(data.timeArrival, "YYYY-MM-DD HH:mm:ss Z").format('D MMM, HH:mm:ss');
            let msg = "";
            if (data.actionKey === Constant.appletLogKey.HTTP_RESPONSE_ACTION_KEY) {
                msg = LLMsg("COMMON.ACTION.HTTP_RESPONSE_ACTION_DONE", {
                    APPLET_NAME: data.appletName
                });
            } else if (data.actionKey === Constant.appletLogKey.PUBLISH_MQTT_MESSAGE_ACTION_KEY) {
                msg = LLMsg("COMMON.ACTION.MQTT_ACTION_DONE", {
                    APPLET_NAME: data.appletName
                });
            } else if (data.actionKey === Constant.appletLogKey.TRIGGER_IFTTT_WEBHOOK_ACTION_KEY) {
                msg = LLMsg("COMMON.ACTION.IFTTT_ACTION_DONE", {
                    APPLET_NAME: data.appletName
                });
            }

            let newMessageList = [{
                time: time,
                content: <span>{msg}</span>
            }];
            Array.prototype.push.apply(newMessageList, this.state.messageList);
            this.setState({
                messageList: newMessageList
            })
        });
    }

    componentDidMount() {
        this.setupSocketEvent();
    }

    render() {

        let messages: Array<DataRecord> = [];

        this.state.messageList.forEach((item) => {
            messages.push(
                <tr key={messages.length}>
                    <td>
                        {item.time}
                    </td>
                    <td>
                        {item.content}
                    </td>
                </tr>
            );
        });

        return <div className={this.state.isExpanded ? "col-12 p-2" : "col-12 col-lg-6 p-2"}>
            <div className={ClassNames("m-portlet mb-0")}>
                <div className="m-portlet__body">

                    <div className={styles.header}>
                        {LLMsg("COMMON.PROJECT.DATA_VIEWER")}

                        <div data-tip={LLMsg("COMMON.PROJECT.EXPLAIN_REAL_TIME_LOGGER")}
                             className={styles.explainButton}><i className={"flaticon-info m--icon-font-size-lg3"}/>
                        </div>
                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                    </div>
                    <div className={styles.expandButton} onClick={() => {
                        this.setState({isExpanded: !this.state.isExpanded});
                    }}>
                        <i className="fas fa-arrows-alt-h"/>
                    </div>
                    <div className={styles.messageBox}>
                        <TableScrollbar rows={8}>

                            <table
                                className={ClassNames("table table-bordered table-hover m-table", styles.table)}>
                                <thead>
                                <tr>
                                    <th width={"150px"}>{LLMsg("COMMON.TIME")}</th>
                                    <th>{LLMsg("EVENT_TRIGGER.MESSAGE")}</th>
                                </tr>
                                </thead>
                                <tbody className={styles.messageBoxBody}>
                                {messages.length > 0 && messages}
                                </tbody>
                            </table>

                            {messages.length === 0 &&
                            <div className={styles.noMessage}>{LLMsg("COMMON.ACTION.NO_RECORD")}</div>}
                        </TableScrollbar>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default ProjectMessageFragment;