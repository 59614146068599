// @flow

import PageUrl from "./PageUrl";
import StringUtil from "../../util/StringUtil";

export default class LicensePageUrl {

    static adminHome(): string {
        return PageUrl.ADMIN_LICENSE_MANAGEMENT_HOME_URL;
    }

    static adminLicenseBatchDetail(licenseBatchId: string): string {
        return StringUtil.replaceAllText(PageUrl.ADMIN_LICENSE_BATCH_DETAIL_URL, [
            {
                key: ":licenseBatchId",
                value: licenseBatchId
            }
        ]);
    }

    static myLicenseHome(): string {
        return PageUrl.LICENSE_MANAGEMENT_HOME_URL;
    }

    static createLicenseTarget(): string {
        return PageUrl.ADMIN_LICENSE_TARGET_CREATE_URL;
    }

    static createLicenseInBatch(): string {
        return PageUrl.ADMIN_LICENSE_BATCH_CREATE_URL;
    }

    static updateLicenseTarget(licenseTargetId: number): string {
        return StringUtil.replaceAllText(PageUrl.ADMIN_LICENSE_TARGET_UPDATE_URL, [
            {
                key: ":licenseTargetId",
                value: licenseTargetId + ""
            }
        ]);
    }

    static licenseDetailQueryOage(): string {
        return PageUrl.LICENSE_DETAIL_QUERY_URL;
    }

    static licenseDetailPage(licenseKey: string): string {
        return StringUtil.replaceAllText(PageUrl.LICENSE_DETAIL_URL, [
            {
                key: ":licenseKey",
                value: licenseKey
            }
        ]);
    }

    static licenseTargetDetailPage(licenseTargetId: string): string {
        return StringUtil.replaceAllText(PageUrl.ADMIN_LICENSE_TARGET_DETAIL_URL, [
            {
                key: ":licenseTargetId",
                value: licenseTargetId
            }
        ]);
    }
}