// @flow


class SwaggerUrl {
    static AI_AUDIO_ANALYSIS = "/swagger/ai-audio-analysis.json";
    static AI_FACE_ANALYSIS = "/swagger/ai-face-analysis.json";
    static AI_IMAGE_CLASSIFICATION = "/swagger/ai-image-classification.json";
    static AI_IMAGE_RECOGNITION = "/swagger/ai-image-recognition.json";
    static AI_TEXT_ANALYSIS = "/swagger/ai-text-analysis.json";
    static GS_ACCESS_CONTROL = "/swagger/gs-access-control.json";
    static GS_CODE_FILE = "/swagger/gs-code-file.json";
    static GS_STORE = "/swagger/gs-store.json";
    static GS_TIMER = "/swagger/gs-timer.json";
    static IOT_DATA_STORE = "/swagger/iot-data-store.json";
    static IOT_LOCATION = "/swagger/iot-location.json";
    static IOT_TOPIC = "/swagger/iot-topic.json";
    static MC_PROJECT = "/swagger/mc-project.json";
    static MC_UCOA = "/swagger/mc-ucoa.json";
    static OD_CURRENCY = "/swagger/od-currency.json";
    static OD_SCHOOL_LIST = "/swagger/od-school-list.json";
    static OD_WEATHER = "/swagger/od-weather.json";

}

export default SwaggerUrl;