exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3lY4FIOOfWqG922AQHjnE2 {\n    padding-left: 20px;\n    border: 1px solid #eee;\n    background-color: white;\n}", ""]);

// exports
exports.locals = {
	"breadcrumbWrapper": "_3lY4FIOOfWqG922AQHjnE2"
};