exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2DQkIqMq5fEWXEMnTv5cWt::-webkit-outer-spin-button,\n._2DQkIqMq5fEWXEMnTv5cWt::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n._2DQkIqMq5fEWXEMnTv5cWt {\n    -moz-appearance: textfield;\n}\n", ""]);

// exports
exports.locals = {
	"numberNoSpinner": "_2DQkIqMq5fEWXEMnTv5cWt"
};