// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../../../IntlCapture";
import swal from "sweetalert2";
import PubSub from 'pubsub-js';
import Api from "../../../../../bundle/Api";

type ApproveSchoolApplicationButtonProps = {
    schoolApplicationId: string
}

class ApproveSchoolApplicationButton extends Component<ApproveSchoolApplicationButtonProps, any> {

    approve() {
        swal({
            text: LLMsg("COMMON.SCHOOL.CONFIRM_APPROVE_APPLICATION"),
            type: 'warning',
            showCancelButton: !0,
            confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
            cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        }).then((e) => {
            if (e.dismiss) {
                return;
            }
            Api.services.school.application.approve(this.props.schoolApplicationId, "").then(response => {
                if (response.status === 200) {
                    swal(LLMsg("COMMON.SCHOOL.APPROVED"), "", "success").then(() => {
                        PubSub.publish(Api.actions.school.UPDATED_SCHOOL_APPLICATION_ACTION_TYPE, '');
                    });
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch(() => {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            });
        });
    }

    render() {
        return <button type="button"
                       onClick={() => {
                           this.approve();
                       }}
                       className="btn m-btn--square  btn-accent m-btn m-btn--custom m-btn--bolder mr-3">
            {LLMsg("COMMON.SCHOOL.APPROVE")}
        </button>;
    }
}

export default ApproveSchoolApplicationButton;