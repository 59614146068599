exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._11xuG7O62AUpXgfYetHQRV {\n    margin-right: 10px;\n    color: #67666e;\n    background-color: #ebedf2;\n    border-color: #ebedf2;\n}\n\n._11xuG7O62AUpXgfYetHQRV:hover {\n\n    /*color: #fff;*/\n    /*background-color: #00c5dc;*/\n    /*border-color: #00c5dc;*/\n}", ""]);

// exports
exports.locals = {
	"button": "_11xuG7O62AUpXgfYetHQRV"
};