// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import {saveAs} from 'file-saver';

class SchoolService {

    static list(): Object {
        return HttpUtil.ajax(Api.urls.school.list(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static detail(schoolId: string): Object {
        return HttpUtil.ajax(Api.urls.school.detail(schoolId), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static application = {
        approve: (schoolApplicationId: string, remarks: string) => {
            return HttpUtil.ajax(Api.urls.school.application.approve(schoolApplicationId), "POST", {
                'Content-Type': 'application/json',
            }, {remarks});
        },
        reject: (schoolApplicationId: string, remarks: string) => {
            return HttpUtil.ajax(Api.urls.school.application.reject(schoolApplicationId), "POST", {
                'Content-Type': 'application/json',
            }, {remarks});
        }
    };

    static translateTeacherRole(roleKey: string): string {

        const map = {
            "ps_stem": "STEM/ STEAM",
            "ps_general_knowledge": "電腦",
            "ps_ict": "電腦",
            "ps_others": "其他",

            "ss_stem": "STEM/ STEAM",
            "ss_ict": "電腦/ICT",
            "ss_dnt": "設計與科技 D&T",
            "ss_science": "科學（物理、化學、生物）",
            "ss_math": "數學",
            "ss_others": "其他",

            "others": "其他（非中學或小學）",

        };
        let result = map[roleKey];
        if (roleKey.startsWith("ps_")) {
            result = "小學 Primary School: " + result;
        } else if (roleKey.startsWith("ps_")) {
            result = "中學 Secondary School: " + result;
        }

        return result;
    }
}

export default SchoolService;