// @flow

import React, {Component} from 'react';
import Api from "../../../bundle/Api";
import {withRouter} from "react-router";
import Constant from "../../../bundle/Constant";
import Image from "../../../bundle/Image";
import {LLMsg} from "../../../IntlCapture";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";

type JoinProjectFragmentProps = {
    match?: Object,
    location?: Object,
    history?: Object
}
type JoinProjectFragmentState = {
    token: string,
    projectName: string,
    projectId: string,
    hasJoined: boolean,
    isInvalid: boolean
}

class JoinProjectFragment extends Component<JoinProjectFragmentProps, JoinProjectFragmentState> {

    constructor(props) {
        super(props);

        this.state = {
            token: "",
            projectName: "",
            projectId: "",
            hasJoined: false,
            isInvalid: false
        }
    }

    componentDidMount() {
        // check if user already joined the project
        let token = this.props.match && this.props.match.params["token"];
        if (token) {
            Api.services.project.share.checkHasJoined(token).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        token: response.data.token,
                        projectName: response.data.projectName,
                        projectId: response.data.projectId,
                        hasJoined: response.data.hasJoined
                    })
                } else {
                    this.setState({isInvalid: true});
                }
            }).catch(() => {
                this.setState({isInvalid: true});
            })
        }
    }

    confirmJoinProject() {
        Api.services.project.share.joinByToken(this.state.token)
            .then((response) => {
                if (response.status === 204) {
                    this.props.history && this.props.history.push(Page.internals.urls.project.detail(this.state.projectId, this.state.projectName));
                } else {
                    this.setState({isInvalid: true});

                }
            }).catch(() => {
                this.setState({isInvalid: true});
            }
        );
    }

    render() {

        return <div className={Constant.portlet.size.colWidth.w12}>
            <div className="m-portlet mb-0 ">
                <div className="m-portlet__body">
                    <div className="m-stack m-stack--hor m-stack--general">
                        <div className="m-stack__item m-stack__item--center" style={{height: "100px"}}>
                            <img src={Image.urls.getUserJoinedImage()} className={"m-3"} height={200}/>
                        </div>
                        {
                            this.state.isInvalid && <div className="m-stack__item m-stack__item--center">
                                <div className={"m--icon-font-size-lg3 mt-5"}>
                                    {LLMsg("COMMON.PROJECT.INVALID_PROJECT_SHARE_TOKEN")}
                                    <br/>
                                    <Link
                                        to={Page.internals.urls.misc.home()}
                                        className="btn btn-primary btn-md mt-4">
                                        {LLMsg("COMMON.HOME.BACK_TO_HOME")}
                                    </Link>
                                </div>
                            </div>
                        }
                        {
                            this.state.hasJoined && <div className="m-stack__item m-stack__item--center">
                                <div className={"m--icon-font-size-lg3 mt-5"}>
                                    {LLMsg("COMMON.PROJECT.ALREADY_JOINED_PROJECT")}<u>{this.state.projectName}</u>
                                    <br/>
                                    {LLMsg("COMMON.PROJECT.VIEW_PROJECT_LABEL")}
                                    <br/>
                                    <Link
                                        to={Page.internals.urls.project.detail(this.state.projectId, this.state.projectName)}
                                        className="btn btn-primary btn-md mt-4">
                                        {LLMsg("COMMON.ACTION.VIEW")}
                                    </Link>
                                </div>
                            </div>
                        }
                        {
                            !this.state.hasJoined && !this.state.isInvalid &&
                            <div className="m-stack__item m-stack__item--center">
                                <div className={"m--icon-font-size-lg3 mt-5"}>
                                    {LLMsg("COMMON.PROJECT.CONFIRM_TO_JOIN_PROJECT", {"PROJECT_NAME": this.state.projectName})}
                                    <br/>
                                    <button type="button"
                                            onClick={() => {
                                                this.confirmJoinProject();
                                            }}
                                            className="btn btn-primary btn-md mt-4">
                                        {LLMsg("COMMON.ACTION.CONFIRM")}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withRouter(JoinProjectFragment);
