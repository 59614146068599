// @flow

import React from "react";
import BreadcrumbItem from "./BreadcrumbItem";
import {Link} from "react-router-dom";
import Page from "../../bundle/Page";
import type {ToObject} from "../../url/page/PageUrl";
import {LLMsg} from "../../IntlCapture";


type BreadcrumbProps = {
    items?: Array<{ linkTo?: string | ToObject, displayText: string }>
}

const Breadcrumb = (props: BreadcrumbProps) => {

    const {items} = props;
    return <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
        <li className="m-nav__item m-nav__item--home">
            <Link to={Page.internals.urls.misc.home()} className="m-nav__link m-nav__link--icon ">
                <i className="m-nav__link-icon la la-home m--icon-font-size-lg2"/>
                <span className="m-nav__link-text m--icon-font-size-lg2" style={{width:"50px"}}>
                    {LLMsg("COMMON.HOME")}
                </span>
            </Link>
        </li>
        {
            items && items.map((item, index) => {
                return <BreadcrumbItem key={index} displayText={item.displayText} linkTo={item.linkTo}/>
            })
        }
    </ul>;
};

export default Breadcrumb;