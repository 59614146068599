// @flow

import React from 'react';
import styles from "./TriggerFragment.css";
import StringUtil from "../../../util/StringUtil";
import {DataRecordType} from "../../../model/model";
import {
    DIALOGFLOW_TRIGGER_KEY, FACE_DETECTION_TRIGGER_KEY,
    MQTT_MESSAGE_TRIGGER_KEY,
    OBJECT_DETECTION_TRIGGER_KEY,
    WEB_HOOK_TRIGGER_KEY
} from "../filter/FilterFragment";
import {LLMsg} from "../../../IntlCapture";
import CopyWebHookUrlButtonFragment from "./CopyWebHookUrlButtonFragment";
import Constant from "../../../bundle/Constant";
import EventTriggerItemFragment from "../DataTriggerFragment/EventTriggerItemFragment";
import {ClassNames} from "../../../bundle/ClassNames";

type TriggerFragmentProps = {
    triggerId: string,
    triggerIftttwebHookUrl?: string,
    triggerOneNETWebHookUrl?: string,
    triggerDialogflowURL?: string,
    webHookName: string,
    topicName: string,
    objectDetected: string,
    dialogflowIntentName: string,
    isTopicTriggerSelected: boolean,
    isWebHookTriggerSelected: boolean,
    isDialogflowTriggerSelected: boolean,
    isObjectDetectionSelected: boolean,
    isFaceDetectionSelected: boolean,
    topics: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }>
}
type TriggerFragmentState = {
    showError: boolean,
    isTopicTriggerSelected: boolean,
    isWebHookTriggerSelected: boolean,
    isDialogflowTriggerSelected: boolean,
    isObjectDetectionSelected: boolean,
    isFaceDetectionSelected: boolean,
    triggerSourceError: boolean,
    topicNameInputError: boolean,
    webHookNameInputError: boolean,
    dialogflowIntentInputError: boolean,
    topicName: string,
    objectDetected: string,
    webHookName: string,
    webHookType: string,
    dialogflowIntentName: string
}
const webHookType = (name: string, key: string) => {
    return {name, key}
};

const webHookTypeArray = [
    webHookType("IFTTT", "triggerIftttwebHookUrl"),
    webHookType("oneNET", "triggerOneNETWebHookUrl")
];

const defaultWebHookType = "triggerIftttwebHookUrl";

class TriggerFragment extends React.Component<TriggerFragmentProps, TriggerFragmentState> {

    constructor(props: TriggerFragmentProps) {
        super(props);
        this.state = {
            topicName: props.topicName,
            objectDetected: props.objectDetected || Constant.objectDetectionAllClasses[0],
            webHookName: props.webHookName,
            dialogflowIntentName: props.dialogflowIntentName,
            isTopicTriggerSelected: props.isTopicTriggerSelected,
            isWebHookTriggerSelected: props.isWebHookTriggerSelected,
            isDialogflowTriggerSelected: props.isDialogflowTriggerSelected,
            isObjectDetectionSelected: props.isObjectDetectionSelected,
            isFaceDetectionSelected: props.isFaceDetectionSelected,
            showError: false,
            triggerSourceError: false,
            webHookNameInputError: false,
            topicNameInputError: false,
            dialogflowIntentInputError: false,
            webHookType: defaultWebHookType
        };
        this.onTopicTriggerSelected = this.onTopicTriggerSelected.bind(this);
        this.onWebHookTriggerSelected = this.onWebHookTriggerSelected.bind(this);
        this.onDialogflowTriggerSelected = this.onDialogflowTriggerSelected.bind(this);
        this.onObjectDetectionSelected = this.onObjectDetectionSelected.bind(this);
        this.onFaceDetectionSelected = this.onFaceDetectionSelected.bind(this);
    }

    componentDidUpdate(prevProps: TriggerFragmentProps) {
        if (prevProps.triggerId !== this.props.triggerId) {
            this.setState({
                topicName: this.props.topicName,
                objectDetected: this.props.objectDetected || Constant.objectDetectionAllClasses[0],
                webHookName: this.props.webHookName,
                dialogflowIntentName: this.props.dialogflowIntentName,
                isTopicTriggerSelected: this.props.isTopicTriggerSelected,
                isWebHookTriggerSelected: this.props.isWebHookTriggerSelected,
                isDialogflowTriggerSelected: this.props.isDialogflowTriggerSelected,
                isObjectDetectionSelected: this.props.isObjectDetectionSelected,
                isFaceDetectionSelected: this.props.isFaceDetectionSelected,
                showError: false,
                triggerSourceError: false,
                webHookNameInputError: false,
                topicNameInputError: false,
                dialogflowIntentInputError: false
            });
        }
    }

    validateTriggerData = (): boolean => {

        let isValid = true;
        let triggerSourceValid = true;
        let topicNameInputValid = true;
        let webHookNameInputValid = true;
        let dialogflowIntentInputValid = true;
        let isWebHookTriggerSelected = this.state.isWebHookTriggerSelected;
        let isTopicTriggerSelected = this.state.isTopicTriggerSelected;
        let isDialogflowTriggerSelected = this.state.isDialogflowTriggerSelected;
        let isObjectDetectionSelected = this.state.isObjectDetectionSelected;
        let isFaceDetectionSelected = this.state.isFaceDetectionSelected;

        if (!isTopicTriggerSelected && !isWebHookTriggerSelected && !isDialogflowTriggerSelected && !isObjectDetectionSelected && !isFaceDetectionSelected) {
            isValid = false;
            triggerSourceValid = false;
        }

        if (isTopicTriggerSelected && StringUtil.isOnlySpaceOrEmpty(this.state.topicName)) {
            topicNameInputValid = false;
            isValid = false;
        }

        if (isWebHookTriggerSelected && StringUtil.isOnlySpaceOrEmpty(this.state.webHookName)) {
            webHookNameInputValid = false;
            isValid = false;
        }

        if (isDialogflowTriggerSelected && StringUtil.isOnlySpaceOrEmpty(this.state.dialogflowIntentName)) {
            dialogflowIntentInputValid = false;
            isValid = false;
        }

        this.setState({
            showError: !isValid,
            triggerSourceError: !triggerSourceValid,
            topicNameInputError: !topicNameInputValid,
            webHookNameInputError: !webHookNameInputValid,
            dialogflowIntentInputError: !dialogflowIntentInputValid
        });
        return isValid;
    };

    getIsTopicTriggerSelected = () => {
        return this.state.isTopicTriggerSelected;
    };

    getIsWebHookTriggerSelected = () => {
        return this.state.isWebHookTriggerSelected;
    };

    getIsDialogflowTriggerSelected = () => {
        return this.state.isDialogflowTriggerSelected;
    };

    getTriggerData = (): Array<{ topicName?: string, objectDetected?: string, webHookName?: string, dialogflowIntentName?: string, type: string }> => {
        let result = [];
        if (this.state.isTopicTriggerSelected) {
            result.push({
                topicName: this.state.topicName,
                type: MQTT_MESSAGE_TRIGGER_KEY
            });
        }
        if (this.state.isWebHookTriggerSelected) {
            result.push({
                webHookName: this.state.webHookName,
                type: WEB_HOOK_TRIGGER_KEY
            });
        }
        if (this.state.isDialogflowTriggerSelected) {
            result.push({
                dialogflowIntentName: this.state.dialogflowIntentName,
                type: DIALOGFLOW_TRIGGER_KEY
            });
        }
        if (this.state.isObjectDetectionSelected) {
            result.push({
                objectDetected: this.state.objectDetected,
                type: OBJECT_DETECTION_TRIGGER_KEY
            });
        }
        if (this.state.isFaceDetectionSelected) {
            result.push({
                type: FACE_DETECTION_TRIGGER_KEY
            });
        }
        return result;
    };

    onTopicNameChanged = (e: any) => {
        let topicName = e.target.value;
        this.setState({topicName: topicName, topicNameInputError: false});
    };

    onObjectDetectedChanged = (e: any) => {
        let objectDetected = e.target.value;
        this.setState({objectDetected: objectDetected});
    };

    onWebHookNameChanged = (e: any) => {
        let webHookName = e.target.value;
        this.setState({webHookName: webHookName, webHookNameInputError: false});
    };

    onWebHookTypeChanged = (e: any) => {
        let webHookType = e.target.value;
        this.setState({webHookType: webHookType})
    };

    onDialogflowIntentNameChanged = (e: any) => {
        let dialogflowIntentName = e.target.value;
        this.setState({dialogflowIntentName: dialogflowIntentName})
    };

    onTopicTriggerSelected = (isOn: boolean) => {
        if (this.state.isTopicTriggerSelected !== isOn) {
            this.setState({isTopicTriggerSelected: isOn, triggerSourceError: false});
        }
    };

    onWebHookTriggerSelected = (isOn: boolean) => {
        if (this.state.isWebHookTriggerSelected !== isOn) {
            this.setState({isWebHookTriggerSelected: isOn, triggerSourceError: false});
        }
    };

    onDialogflowTriggerSelected = (isOn: boolean) => {
        if (this.state.isDialogflowTriggerSelected !== isOn) {
            this.setState({isDialogflowTriggerSelected: isOn, triggerSourceError: false})
        }
    };

    onObjectDetectionSelected = (isOn: boolean) => {
        if (this.state.isObjectDetectionSelected !== isOn) {
            this.setState({isObjectDetectionSelected: isOn, triggerSourceError: false})
        }
    };
    onFaceDetectionSelected = (isOn: boolean) => {
        if (this.state.isFaceDetectionSelected !== isOn) {
            this.setState({isFaceDetectionSelected: isOn, triggerSourceError: false})
        }
    };

    render() {
        return <div>
            <div className={"m-form__group form-group " + styles.triggerSectionContent}>
                {
                    this.state.showError &&
                    <div
                        className={ClassNames("m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show")}
                        role="alert">
                        <div className="m-alert__icon"
                             style={{backgroundColor: Constant.theme.eventTrigger.trigger.color}}>
                            <i className="flaticon-exclamation-1"/>
                            <span style={{borderColor: Constant.theme.eventTrigger.trigger.color}}/>
                        </div>
                        <div className="m-alert__text"
                             style={{color: Constant.theme.eventTrigger.trigger.color}}>
                            <strong>
                                {LLMsg("EVENT_TRIGGER.VALIDATION_FAILED")}
                            </strong>
                            {LLMsg("EVENT_TRIGGER.TRIGGER_VALIDATION_FAILED")}
                        </div>
                    </div>
                }
                {
                    this.state.isTopicTriggerSelected && (
                        <div>
                            <EventTriggerItemFragment
                                onDelete={() => {
                                    this.onTopicTriggerSelected(false);
                                }}
                                allowDelete={true}
                                showError={this.state.showError}
                                backgroundColor={Constant.theme.eventTrigger.trigger.color}
                                titleContent={
                                    <span>
                                                {LLMsg("EVENT_TRIGGER.WHEN")}
                                        <select
                                            className={
                                                "form-control form-inline m-input m-input--air "
                                                + styles.topicNameInput +
                                                (this.state.topicNameInputError ? " " + styles.errorInput : "")
                                            }
                                            value={this.state.topicName || ""}
                                            onChange={this.onTopicNameChanged}
                                        >

                                                    <option value={""}>
                                                        {LLMsg("EVENT_TRIGGER.TOPIC_NAME")}
                                                    </option>
                                            {
                                                this.props.topics.map((topicDropdownValue) => {
                                                    return <option key={topicDropdownValue.topicName}
                                                                   value={topicDropdownValue.topicName}>
                                                        {topicDropdownValue.topicName + " (" + topicDropdownValue.description + ")"}
                                                    </option>;
                                                })
                                            }
                                                </select>
                                        {LLMsg("EVENT_TRIGGER.NEW_TOPIC_MESSAGE_TRIGGER")}
                                            </span>
                                }
                            />
                        </div>
                    )
                }
                {
                    this.state.isWebHookTriggerSelected && (
                        <div>
                            <EventTriggerItemFragment
                                onDelete={() => {
                                    this.onWebHookTriggerSelected(false);
                                }}
                                allowDelete={true}
                                showError={this.state.showError}
                                backgroundColor={Constant.theme.eventTrigger.trigger.color}
                                titleContent={
                                    <span>
                                                {LLMsg("EVENT_TRIGGER.WHEN")}

                                        <input type={"text"} value={this.state.webHookName || ""}
                                               onChange={this.onWebHookNameChanged}
                                               placeholder={LLMsg("EVENT_TRIGGER.WEBHOOK_NAME")}
                                               className={
                                                   "form-control form-inline m-input "
                                                   + styles.webHookNameInput +
                                                   (this.state.webHookNameInputError ? " " + styles.errorInput : "")
                                               }/>

                                        {LLMsg("EVENT_TRIGGER.WEBHOOK_IS_TRIGGERED")}
                                            </span>
                                }

                                extraContent={
                                    this.props.triggerIftttwebHookUrl ? (
                                        <div className={"mt-2"}>

                                            {LLMsg("EVENT_TRIGGER.WEBHOOK_URL")}
                                            <select
                                                className={
                                                    "form-control form-inline m-input m-input--air "
                                                    + styles.webHookUrlSelect
                                                }
                                                value={this.state.webHookType || ""}
                                                onChange={this.onWebHookTypeChanged}
                                            >
                                                {
                                                    webHookTypeArray.map((webHookType) => {
                                                        return <option key={webHookType.key}
                                                                       value={webHookType.key}>
                                                            {webHookType.name}
                                                        </option>;
                                                    })
                                                }
                                            </select>
                                            <input type={"text"}
                                                   className={"form-control form-inline m-input " + styles.webHookUrlInput}
                                                   onClick={(e) => {
                                                       e.target.select();
                                                   }}
                                                   value={this.props[this.state.webHookType]} width={20}/>
                                            <CopyWebHookUrlButtonFragment
                                                className={"ml-2 pb-3 btn btn-link m-btn--pill btn-md"}
                                                text={this.props[this.state.webHookType] || ""}/>
                                        </div>
                                    ) : (
                                        <div className={"mt-2"}>
                                            <span className={styles.webHookHiddenDescription}>
                                                {LLMsg("EVENT_TRIGGER.WEBHOOK_URL_HIDDEN_DESCRIPTION")}
                                            </span>
                                        </div>
                                    )
                                }
                            />
                        </div>
                    )
                }
                {
                    this.state.isDialogflowTriggerSelected && (
                        <div>
                            <EventTriggerItemFragment
                                allowDelete={true}
                                onDelete={() => {
                                    this.onDialogflowTriggerSelected(false);
                                }}
                                showError={this.state.showError}
                                backgroundColor={Constant.theme.eventTrigger.trigger.color}
                                titleContent={
                                    <span>
                                                {LLMsg("EVENT_TRIGGER.WHEN")}
                                        <input type={"text"} value={this.state.dialogflowIntentName || ""}
                                               onChange={this.onDialogflowIntentNameChanged}
                                               placeholder={LLMsg("EVENT_TRIGGER.INTENT_NAME")}
                                               className={
                                                   "form-control form-inline m-input "
                                                   + styles.dialogflowIntentInput +
                                                   (this.state.dialogflowIntentInputError ? " " + styles.errorInput : "")
                                               }/>

                                        {LLMsg("EVENT_TRIGGER.DIALOGFLOW_INTENT_IS_TRIGGED")}
                                            </span>
                                }

                                extraContent={
                                    this.props.triggerDialogflowURL ? (
                                        <div className={"mt-2"}>

                                            {LLMsg("EVENT_TRIGGER.DIALOGFLOW_URL")}
                                            <input type={"text"}
                                                   className={"form-control form-inline m-input " + styles.dialogflowUrlInput}
                                                   onClick={(e) => {
                                                       e.target.select();
                                                   }}
                                                   value={this.props.triggerDialogflowURL} width={20}/>
                                            <CopyWebHookUrlButtonFragment
                                                className={"ml-2 pb-3 btn btn-link m-btn--pill btn-md"}
                                                text={this.props.triggerDialogflowURL || ""}/>
                                        </div>
                                    ) : (
                                        <div className={"mt-2"}>
                                            <span className={styles.dialogflowHiddenDescription}>
                                                {LLMsg("EVENT_TRIGGER.DIALOGFLOW_URL_HIDDEN_DESCRIPTION")}
                                            </span>
                                        </div>
                                    )

                                }
                            />
                        </div>
                    )
                }
                {
                    this.state.isObjectDetectionSelected && (
                        <div>
                            <EventTriggerItemFragment
                                allowDelete={true}
                                onDelete={() => {
                                    this.onObjectDetectionSelected(false);
                                }}
                                backgroundColor={Constant.theme.eventTrigger.trigger.color}
                                titleContent={
                                    <span>
                                        {LLMsg("EVENT_TRIGGER.OBJECT_IS_DETECTED")}

                                        <select
                                            className={
                                                "form-control form-inline m-input m-input--air "
                                                + styles.objectDetectedInput
                                            }
                                            value={this.state.objectDetected}
                                            onChange={this.onObjectDetectedChanged}
                                        >
                                            {
                                                Constant.objectDetectionAllClasses.map((item: string) => {
                                                    return <option key={item}
                                                                   value={item}>
                                                        {item}
                                                    </option>;
                                                })
                                            }
                                        </select>
                                    </span>
                                }
                            />
                        </div>
                    )
                }
                {
                    this.state.isFaceDetectionSelected && (
                        <div>
                            <EventTriggerItemFragment
                                allowDelete={true}
                                onDelete={() => {
                                    this.onFaceDetectionSelected(false);
                                }}
                                showError={this.state.showError}
                                backgroundColor={Constant.theme.eventTrigger.trigger.color}
                                titleContent={
                                    <span>
                                        {LLMsg("EVENT_TRIGGER.FACE_IS_DETECTED")}
                                    </span>
                                }
                            />
                        </div>
                    )
                }
            </div>
        </div>;
    }
}

export default TriggerFragment;