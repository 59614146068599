// @flow

import _ from "lodash";

export default class UrlBuilder {
    static toQueryString(obj: Object): string {
        let parts = [];
        for (let i in obj) {
            if (obj.hasOwnProperty(i)) {
                if (i) {
                    parts.push(i + "=" + obj[i]);
                }
            }
        }
        return parts.join("&");
    }

    static getQueryString(): Object {
        return _.chain(window.location.href).split('?').nth(1).split('&').map(_.partial(_.split, _, '=', 2)).fromPairs().value();
    }

    static getCurrentUrl(): string {
        return window.location.pathname;
    }
}