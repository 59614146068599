// @flow

import React from "react";

const NotAllowedFragment = () => {
    return <div>
        not allowed
    </div>;
};

export default NotAllowedFragment;