// @flow

import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";
import UrlBuilder from "../UrlBuilder";

export default class UserApiUrlHelper {

    static getListOfWhitelistEmailUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.WHITELIST_EMAIL_API, []);
    }

    static getDeleteWhitelistEmailUrl(whitelistEmailId: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.WHITELIST_EMAIL_DELETE_API, [{
            key: ":whitelistEmailId",
            value: whitelistEmailId
        }]);
    }

    static getUpdateWhitelistEmailUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.WHITELIST_EMAIL_UPDATE_API, []);
    }

    static getCreateWhitelistEmailUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.WHITELIST_EMAIL_CREATE_API, []);
    }

    static getListOfWhitelistedUserUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_LISTING_BY_WHITE_LIST_API, []);
    }


    static getListOfUserByLicenseTargetIdUrl(licenseTargetId: number): string {
        let queryString = "?" + UrlBuilder.toQueryString({"licenseTargetId": licenseTargetId});
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_LISTING_BY_LICENSE_TARGET_API, []) + queryString;
    }

    static getListOfUserByEventIdUrl(eventId: number): string {
        let queryString = "?" + UrlBuilder.toQueryString({"eventId": eventId});
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_LISTING_BY_EVENT_API, []) + queryString;
    }

    static getUserDetailUrl(userId: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_DETAIL_API, [{
            key: ":userId",
            value: userId
        }]);
    }

    static getUserMyselfUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_MYSELF_API, []);
    }

    static getSetupPasswordForNewAccountUrl(): string {
        return StringUtil.replaceAllText(Config.SSO_MAKER_CLOUD + Api.paths.USER_SETUP_PASSWORD_API, []);
    }

    static doResetUserPassword(): string {
        return StringUtil.replaceAllText(Config.SSO_MAKER_CLOUD + Api.paths.SEND_FORGET_PASSWORD_EMAIL_API, []);
    }

    static getChangeAccountPasswordUrl(): string {
        return StringUtil.replaceAllText(Config.SSO_MAKER_CLOUD + Api.paths.USER_CHANGE_PASSWORD_API, []);
    }

    static getCreateUserByGoogleSignUpUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_CREATE_BY_GOOGLE_SIGN_UP_API, []);
    }

    static getCreateGuestUserUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_GUEST_CREATE_API, []);
    }

    static getCreateUserWithLicenseUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_CREATE_WITH_LICENSE_API, []);
    }

    static getSignUpSchoolUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.SCHOOL_SIGNUP_API, []);
    }

    static getCreateUserUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_CREATE_API, []);
    }

    static getMySubscriptionStatusUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.USER_SUBSCRIPTION_API, []);
    }

    static getOauthTokenUrl(): string {
        return StringUtil.replaceAllText(Config.SSO_MAKER_CLOUD + Api.paths.USER_TOKEN_API, []);
    }

    static getUserLogoutUrl(): string {
        return StringUtil.replaceAllText(Config.SSO_MAKER_CLOUD + Api.paths.USER_LOGOUT_API, []);
    }

    static getUserCreateUrl(): string {
        return StringUtil.replaceAllText(Config.SSO_MAKER_CLOUD + Api.paths.TOPIC_CREATE_API, []);
    }

}