// @flow
import React from 'react';
import {LLMsg} from "../../../../../IntlCapture";
import Constant from "../../../../../bundle/Constant";
import ChartTypeItem from "../../ChartTypeSelectFragment/ChartTypeItem";
import * as _ from 'lodash';
import styles from './index.css';
import ChartSourceSelectFragment from "../../ChartSourceSelectFragment/index";

type LineChartConfigFragmentProps = {}
type LineChartConfigFragmentState = {
    chartNameError: boolean,
    chartSourceError: boolean
}

export default class LineChartConfigFragment extends React.Component <LineChartConfigFragmentProps, LineChartConfigFragmentState> {
    constructor(props: LineChartConfigFragmentProps) {
        super(props);
        this.state = {
            chartNameError: false,
            chartSourceError: false
        };
        this.getConfigs = this.getConfigs.bind(this);
        this.validate = this.validate.bind(this);
    }

    chartNameRef: any;
    chartSourceRef: any = React.createRef();

    getConfigs = () => {
        return {
            "CHART_NAME": _.get(this.chartNameRef, "value", undefined),
            "CHART_SOURCE": this.chartSourceRef.current && this.chartSourceRef.current.getConfigs()
        }
    };

    validate = () => {
        let isValid = true;
        let error = {
            chartNameError: false,
            chartSourceError: false
        };

        if (!_.get(this.chartNameRef, "value", undefined)) {
            isValid = false;
            error.chartNameError = true;
        }

        let configs = this.chartSourceRef.current ? this.chartSourceRef.current.getConfigs() : [];

        if (configs.length === 0) {
            isValid = false;
            error.chartSourceError = true
        } else {
            configs.map((config) => {
                if (!config.topicId || !config.dataTypeId || !config.deviceId) {
                    isValid = false;
                    error.chartSourceError = true;
                }
            });
        }

        this.setState(error);
        return isValid;
    };

    render() {

        return <div>
            <h3>
                <strong>{LLMsg("COMMON.STEP.STEP_2")} - </strong>
                <span>{LLMsg("COMMON.CHART.CHART_SETTING")}</span>
            </h3>
            <hr/>
            <div>

                <div className="row">
                    <div className={"col-6"}>
                        <div
                            className={"form-group m-form__group row " + (this.state.chartNameError ? styles.error : "")}>

                            <div className={"col-12 " + styles.title}>
                                {LLMsg("COMMON.CHART.CHART_NAME")}
                            </div>
                            <div className="col-12">
                                <input className="form-control m-input" type="text" ref={(ref) => {
                                    this.chartNameRef = ref
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-6"}>
                    </div>
                    <div className={"col-6"}>
                        <div className="form-group m-form__group row">
                            <div className={styles.title + " col-12"}>
                                {LLMsg("COMMON.CHART.SELECTED_CHART_TYPE")}
                            </div>
                            <div className="col-12">
                                <ChartTypeItem chartType={Constant.chart.type.basicLineChart}
                                               defaultChecked={true}
                                               inputName={"chart_config_radio"}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"form-group m-form__group row " + (this.state.chartSourceError ? styles.error : "")}>

                    <div className={"col-12 " + styles.title}>
                        {LLMsg("COMMON.CHART.SELECTED_CHART_SOURCE")}
                    </div>
                    <div className="col-12">
                        <ChartSourceSelectFragment
                            isSingleSource={false} showSimpleMessageOption={false}
                            ref={this.chartSourceRef}
                            showDeviceOption={true}
                            showColorOption={true} showDescriptionOption={false} showLocationOption={false}/>
                    </div>
                </div>

            </div>
        </div>;
    }
}
