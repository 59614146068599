// @flow

import React from "react";
import Page from "../../../bundle/Page";
import {LLMsg} from "../../../IntlCapture";
import swal from "sweetalert2";
import Api from "../../../bundle/Api";
import {RouterHistory} from "../../../AppRoute";

export const createProjectPrompt = () => {

    swal({
        title: LLMsg("COMMON.PROJECT.CREATE_PROJECT"),
        input: 'text',
        inputPlaceholder: LLMsg("COMMON.PROJECT.CREATE_PROJECT"),
        showCancelButton: true,
        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
        cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value) {
                    resolve()
                } else {
                    resolve('project name cannot empty!');
                }
            })
        }
    }).then((e) => {
        if (e.dismiss) {
            return;
        }
        if (e.value) {
            Api.services.project.doCreateProject(e.value).then(response => {
                if (response.status === 200) {
                    swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), "", "success");
                    RouterHistory().push(Page.internals.urls.project.detail(response.data.id, response.data.projectName, null));
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch(() => {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            });
        } else {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        }
    });
};


const ProjectListCreateProjectButton = () => {
    return <li className="m-portlet__nav-item">
        <div onClick={() => {
            createProjectPrompt()
        }}
             className="btn btn-primary m-btn m-btn--custom m-btn--icon m-btn--air">
              <span>
                <i className="la la-plus"/>
                <span>
                    {LLMsg("COMMON.PROJECT.CREATE_PROJECT")}
                </span>
              </span>
        </div>
    </li>;
};

export default ProjectListCreateProjectButton;