// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../IntlCapture";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import styles from './index.css';
import UrlBuilder from "../../../url/UrlBuilder";
import StringUtil from "../../../util/StringUtil";
import {withRouter} from "react-router";
import Select from 'react-select';
import Api from "../../../bundle/Api";
import ErrorUtil from "../../../util/ErrorUtil";
import swal from "sweetalert2";

type GuestRegisterFormWrapperProps = {
    match?: Object,
    location?: Object,
    history?: Object
}

type GuestRegisterFormWrapperState = {
    showUsernameError: boolean,
    showTeacherNameError: boolean,
    showTeacherRoleError: boolean,
    showTeacherRoleRemarksError: boolean,
    showTeacherTelError: boolean,
    showEventCodeError: boolean
}

class SchoolRegisterFormWrapper extends Component<GuestRegisterFormWrapperProps, GuestRegisterFormWrapperState> {

    emailInput: any;
    teacherNameInput: any;
    teacherRoleInputValue: string;
    teacherRoleRemarksInput: any;
    teacherTelInput: any;
    schoolNameInput: any;
    eventCodeInput: any;

    constructor(props: GuestRegisterFormWrapperProps) {
        super(props);
        this.state = {
            showUsernameError: false,
            showTeacherNameError: false,
            showTeacherRoleError: false,
            showTeacherRoleRemarksError: false,
            showTeacherTelError: false,
            showSchoolNameError: false,
            showEventCodeError: false
        };
        this.registerSchool = this.registerSchool.bind(this);
    }

    registerSchool = () => {
        const username = this.emailInput.value;
        const teacherName = this.teacherNameInput.value;
        const teacherRole = this.teacherRoleInputValue;
        const teacherRoleRemarks = this.teacherRoleRemarksInput.value;
        const teacherTel = this.teacherTelInput.value;
        const schoolName = this.schoolNameInput.value;
        const eventCode = this.eventCodeInput.value;

        let isValid = true;
        let newState: GuestRegisterFormWrapperState = {
            showUsernameError: false,
            showTeacherNameError: false,
            showTeacherRoleError: false,
            showTeacherRoleRemarksError: false,
            showTeacherTelError: false,
            showSchoolNameError: false,
            showEventCodeError: false
        };
        if (!StringUtil.validateEmail(username)) {
            newState = {...newState, showUsernameError: true};
            isValid = false;
        }

        if (!eventCode) {
            newState = {...newState, showEventCodeError: true};
            isValid = false;
        }

        if (!teacherName) {
            newState = {...newState, showTeacherNameError: true};
            isValid = false;
        }
        if (!schoolName) {
            newState = {...newState, showSchoolNameError: true};
            isValid = false;
        }
        if (!teacherRole) {
            newState = {...newState, showTeacherRoleError: true};
            isValid = false;
        } else {
            if (teacherRole === "ps_others" || teacherRole === "ss_others" || teacherRole === "others") {
                if (!teacherRoleRemarks) {
                    newState = {...newState, showTeacherRoleRemarksError: true};
                    isValid = false;
                }
            }
        }
        if (!teacherTel) {
            newState = {...newState, showTeacherTelError: true};
            isValid = false;
        }

        this.setState(newState);

        if (!isValid) {
            return;
        }

        Api.services.user.doSignUpSchool(schoolName, username, teacherName, teacherRole, teacherRoleRemarks, teacherTel, eventCode).then((response) => {
            if (response.status === 200) {
                swal(LLMsg("REGISTER_PAGE.SCHOOL_SIGN_UP_SUCCESS"), "", "success").then(() => {
                    this.props.history && this.props.history.push(Page.internals.urls.misc.home());
                });
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
        })

    };

    componentDidMount() {
        this.emailInput.focus();
    }

    handleTeacherRoleDropdownChange = (selectedOption) => {
        this.teacherRoleInputValue = selectedOption.value;
    };

    render() {

        const formatGroupLabel = data => (
            <div>
                <span className={styles.groupLabel}>{data.label}</span>
            </div>
        );

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                padding: 10,
            }),
            control: (provided, state) => ({
                ...provided,
                border: "none",
                backgroundColor: "inherit",
                margin: 0
            })
        };


        const options = [
            {
                label: "小學 Primary School",
                options: [
                    {label: "STEM/ STEAM", value: "ps_stem"},
                    {label: "常識", value: "ps_general_knowledge"},
                    {label: "電腦", value: "ps_ict"},
                    {label: "其他（請註明）", value: "ps_others"}
                ]
            },
            {
                label: "中學 Secondary School",
                options: [
                    {label: "STEM/ STEAM", value: "ss_stem"},
                    {label: "設計與科技 D&T", value: "ss_dnt"},
                    {label: "電腦/ICT", value: "ss_ict"},
                    {label: "科學（物理、化學、生物）", value: "ss_science"},
                    {label: "數學", value: "ss_math"},
                    {label: "其他（請註明）", value: "ss_others"}
                ]
            },
            {
                label: "其他",
                options: [
                    {label: "非中學或小學（請註明）", value: "others"}
                ]
            }
        ];

        const ref = UrlBuilder.getQueryString()["ref"];
        return <div
            className="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper">
            <div className="m-login__body">
                <div className="m-login__signin">
                    <div className={"m-login__title " + styles.formTitle}>
                        <h3>{LLMsg("REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT")}</h3>
                    </div>
                    <form className="m-login__form m-form">
                        <div className={styles.sectionTitle}>
                            {LLMsg("REGISTER_PAGE.PERSONAL_INFO_LABEL")}
                        </div>
                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.GUEST_USERNAME") + "*"}
                                   name="username" autoComplete="off" ref={(emailInput) => {
                                this.emailInput = emailInput
                            }}/>
                            {
                                this.state.showUsernameError &&
                                <div className={styles.errorPrompt + " alert"}
                                     role="alert">
                                    {LLMsg("REGISTER_PAGE.INVALID_GUEST_USERNAME")}
                                </div>
                            }
                        </div>
                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.TEACHER_NAME") + "*"}
                                   name="eventCode" autoComplete="off" ref={(teacherNameInput) => {
                                this.teacherNameInput = teacherNameInput
                            }}/>
                            {
                                this.state.showUsernameError &&
                                <div className={styles.errorPrompt + " alert"}
                                     role="alert">
                                    {LLMsg("REGISTER_PAGE.TEACHER_NAME_INVALID")}
                                </div>
                            }

                        </div>
                        <div className={"form-group m-form__group " + styles.teachingRoleBox}>
                            <Select className={styles.dropdown}
                                    onChange={this.handleTeacherRoleDropdownChange}
                                    placeholder={LLMsg("REGISTER_PAGE.TEACHER_ROLE") + "*"}
                                    styles={customStyles}
                                    options={options}
                                    formatGroupLabel={formatGroupLabel}/>

                            {
                                this.state.showTeacherRoleError &&
                                <div className={styles.errorPrompt + " alert"}
                                     role="alert">
                                    {LLMsg("REGISTER_PAGE.TEACHER_ROLE_INVALID")}
                                </div>
                            }
                            <input className={"form-control m-input pt-0 mt-4 " + styles.teachingRoleRemarks}
                                   type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.TEACHER_ROLE_REMARKS")}
                                   name="eventCode" autoComplete="off" ref={(teacherRoleRemarksInput) => {
                                this.teacherRoleRemarksInput = teacherRoleRemarksInput
                            }}/>

                            {
                                this.state.showTeacherRoleRemarksError &&
                                <div className={styles.errorPrompt + " alert"}
                                     role="alert">
                                    {LLMsg("REGISTER_PAGE.TEACHER_ROLE_REMARKS_INVALID")}
                                </div>
                            }
                        </div>

                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.TEACHER_TEL") + "*"}
                                   name="eventCode" autoComplete="off" ref={(teacherTelInput) => {
                                this.teacherTelInput = teacherTelInput
                            }}/>

                            {
                                this.state.showTeacherTelError &&
                                <div className={styles.errorPrompt + " alert"}
                                     role="alert">
                                    {LLMsg("REGISTER_PAGE.TEACHER_TEL_INVALID")}
                                </div>
                            }

                        </div>

                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.SCHOOL_NAME_LABEL") + "*"}
                                   name="eventCode" autoComplete="off" ref={(schoolNameInput) => {
                                this.schoolNameInput = schoolNameInput
                            }}/>

                            {
                                this.state.showTeacherTelError &&
                                <div className={styles.errorPrompt + " alert"}
                                     role="alert">
                                    {LLMsg("REGISTER_PAGE.SCHOOL_NAME_INVALID")}
                                </div>
                            }

                        </div>


                        <div className={styles.sectionTitle}>
                            {LLMsg("REGISTER_PAGE.EVENT_INFO_LABEL")}
                        </div>
                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.EVENT_CODE_LABEL") + "*"}
                                   name="eventCode" autoComplete="off" ref={(eventCodeInput) => {
                                this.eventCodeInput = eventCodeInput
                            }}/>
                        </div>
                        {
                            this.state.showEventCodeError &&
                            <div className={styles.errorPrompt + " alert"}
                                 role="alert">
                                {LLMsg("REGISTER_PAGE.EVENT_CODE_INVALID")}
                            </div>
                        }

                        <div className={styles.expiryNotice}>
                            {LLMsg("REGISTER_PAGE.NO_EVENT_CODE")}
                        </div>

                        <hr className={"mt-5"}/>
                        <div className={styles.expiryNotice}>
                            {LLMsg("REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT_NOTICE")}
                        </div>
                    </form>
                    <div className="m-login__action">
                        <Link to={Page.internals.urls.user.login(ref)} className="m-link">
                            <span>{LLMsg("REGISTER_PAGE.BACK_TO_LOGIN_PAGE")}</span>
                        </Link>
                        <a href="javascript:" className="m-link ml-2" onClick={() => {
                            this.registerSchool()
                        }}>
                            <button className="btn btn-brand m-btn m-btn--pill p-3">
                                {LLMsg("REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT")}
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>;
    }

}

export default withRouter(SchoolRegisterFormWrapper);