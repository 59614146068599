// @flow

import type ReduxAction from "./index";
import ProjectAction from "../action/ProjectAction";

const ProjectReducer = (state: any = [], action: ReduxAction) => {
    switch (action.type) {
        case (ProjectAction.GET_LIST_OF_PROJECT_ACTION_TYPE):
            return {
                ...state,
                [ProjectAction.GET_LIST_OF_PROJECT_ACTION_TYPE]: action
            };
        default:
            return state
    }
};
export default ProjectReducer;