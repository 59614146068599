// @flow

import React, {Component} from "react";
import type {ChartMessage, ChartSource, ChartSourceConfig, Topic} from "../../../../model/model";
import {renderToStaticMarkup} from "react-dom/server";
import SocketIOService from "../../../../service/SocketIOService";
import {LLMsg} from "../../../../IntlCapture";
import Constant from "../../../../bundle/Constant";
import _ from "lodash";
import StringUtil from "../../../../util/StringUtil";
import styles from "./index.css";

export type PointMapData = {
    topicName: string,
    circle: window.google.maps.Circle,
    infowindow: window.google.maps.InfoWindow
}

type PointMapFragmentProps = {
    customHeight?: string,
    chartSources: Array<ChartSource>,
    minValue?: number,
    maxValue?: number,
    isSetting?: boolean,
    isPickingLocation?: boolean,
    onPickedLocation?: Function
}

type PointMapFragmentState = {
    chartSources: Array<ChartSource>
}

export function PointMapInfoBox(chartSourceConfig: ChartSourceConfig, topic: Topic, message: string) {
    let contentArr = SocketIOService.extractMqttDataMessage(message);
    return <div>
        <div>{LLMsg("COMMON.CHART.SOURCE_DESCRIPTION") + ": " + chartSourceConfig.description}</div>
        <div>{LLMsg("COMMON.TOPIC.TOPIC") + ": " + topic.topicName}</div>
        <div>{LLMsg("COMMON.PROJECT.DATA_VIEWER") + ": "}</div>
        {
            contentArr.map((content, index) => {
                return <div key={index}>
                    {
                        content.key && <span>{content.key}={content.value}</span>
                    }
                    {
                        !content.key && <span>{content.value}</span>
                    }
                </div>;
            })
        }
    </div>;
}

class PointMapFragment extends Component<PointMapFragmentProps, PointMapFragmentState> {

    map: any;
    mapRef: any;

    pointMapDataMap: { [chartSourceId:string]: PointMapData } = {};
    defaultLat: number = 22.3193;
    defaultLng: number = 114.1694;
    defaultZoom: number = 12;
    minZoom: number = 14;

    maxRadius: number = 10000;
    minRadius: number = 500;

    pickedLocation: window.google.maps.LatLng = null;

    constructor(props) {
        super(props);
        this.state = { hasError: false };
        try {
            new window.google.maps.LatLng(0, 0);
        } catch (error) {
            this.state = { hasError: true };
        }

        this.getConfigs = this.getConfigs.bind(this);
    }

    getConfigs = (): {pickedLocation: window.google.maps.LatLng} => {
        return {
            pickedLocation: this.pickedLocation
        }
    };

    componentWillUnmount() {
        let socket: any = SocketIOService.getSocket();
        this.props.chartSources.forEach(source => {
            socket.emit(Constant.socket.data.room.leave, "T" + source.topic.id);
        });
    }

    calculateCircleSize = (value: number):number => {
        let minValue = this.props.minValue;
        let maxValue = this.props.maxValue;

        if (!minValue && !maxValue) {
            return this.minRadius;
        }

        let newValue = value - minValue > 0 ? value - minValue : 0;
        let percentage = newValue / (maxValue - minValue);
        let result = (this.maxRadius - this.minRadius) * percentage + this.minRadius;

        if (result > this.maxRadius) {
            return this.maxRadius;
        } else if (result < this.minRadius) {
            return this.minRadius;
        } else {
            return result;
        }
    }

    setupSocketEvent = () => {
        let socket: any = SocketIOService.getSocket();
        this.props.chartSources.forEach((source: ChartSource) => {
            socket.emit(Constant.socket.data.room.join, "T" + source.topic.id);
        });
        socket.on(Constant.socket.data.room.newData, (data) => {
            let dataObj: ChartMessage = SocketIOService.extractChartDataMessage(data);

            this.props.chartSources.forEach((source: ChartSource) => {
                console.log("source=", source, "dataObj=", dataObj);
                if (!source || !source.dataRecordType || !source.dataRecordType.keyName) {
                    return;
                }
                let content = _.get(dataObj, "records[" + source.dataRecordType.keyName + "]", undefined);
                if (source.topic.id == dataObj.topicId && content) {
                    // update circle size
                    if (StringUtil.isNumber(content)) {
                        this.pointMapDataMap[source.id].circle.setRadius(this.calculateCircleSize(Number(content)));
                    }

                    // update infowindow
                    this.pointMapDataMap[source.id].infowindow.setContent(renderToStaticMarkup(PointMapInfoBox(source.chartSourceConfig, source.topic, content)));
                }
            });
        });
    }

    componentDidMount() {
        if (this.state.hasError) { return }

        this.map = new window.google.maps.Map(this.mapRef, {
            zoom: this.defaultZoom,
            center: new window.google.maps.LatLng(this.defaultLat, this.defaultLng),
            streetViewControl: false,
            mapTypeControl: false,
            clickableIcons: false
        });

        window.google.maps.event.addListener(this.map, 'click', (event) => {
            if (this.props.isPickingLocation) {
                console.log( "Latitude: "+event.latLng.lat()+" "+", Longitude: "+event.latLng.lng() );
                this.pickedLocation = event.latLng;
                this.props.onPickedLocation();
            }
        });

        this.setupSocketEvent();
        this.forceUpdate();
    }

    componentDidUpdate() {
        if (this.state.hasError) { return }

        this.clearRemovedPointMapData();

        if (!this.props.isPickingLocation) {
            this.pickedLocation = null;
        }

        var shouldMapBoundsUpdate: boolean = false;

        for (let i = 0; i < this.props.chartSources.length; i++) {
            let chartSource: ChartSource = this.props.chartSources[i];
            let chartSourceConfig: ChartSourceConfig = chartSource.chartSourceConfig;
            let topic: Topic  = chartSource.topic;

            // if exists
            if (this.pointMapDataMap[chartSource.id] != null) {
                let pointMapData = this.pointMapDataMap[chartSource.id]

                // update position
                if (topic.latitude != pointMapData.circle.getCenter().lat() ||
                    topic.longitude != pointMapData.circle.getCenter().lng()) {
                    let newPos = {lat: topic.latitude, lng: topic.longitude};
                    pointMapData.circle.setCenter(newPos);
                    pointMapData.infowindow.setPosition(newPos);
                    shouldMapBoundsUpdate = true;
                }

                // update info window
                if (chartSourceConfig.description != pointMapData.infowindow.content) {
                    pointMapData.infowindow.setContent(chartSourceConfig.description)
                }

            // create new if not exists
            } else if (topic.latitude && topic.longitude) {
                let circle = new window.google.maps.Circle({
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                    map: this.map,
                    center: {lat: topic.latitude, lng: topic.longitude},
                    radius: this.minRadius
                });

                //setup infowindow
                let infowindow = new window.google.maps.InfoWindow({
                    position: circle.center
                });

                if (this.props.isSetting == true) {
                    infowindow.setContent(chartSourceConfig.description);
                } else {
                    infowindow.setContent(renderToStaticMarkup(PointMapInfoBox(chartSourceConfig, topic, "")));
                }

                circle.addListener('mouseover', () => {
                    infowindow.open(this.map, circle);
                });

                circle.addListener('mouseout', () => {
                    infowindow.close();
                });

                const pointMapData: PointMapData = {
                    topicName: topic.topicName,
                    circle: circle,
                    infowindow: infowindow
                };

                this.pointMapDataMap[chartSource.id] = pointMapData;

                shouldMapBoundsUpdate = true;
            }
        }

        // now fit the map to the newly inclusive bounds
        if (shouldMapBoundsUpdate == true && this.props.chartSources.length != 0 &&
            !(this.props.chartSources.length == 1 && this.props.chartSources[0].topic.longitude == 0 && this.props.chartSources[0].topic.latitude == 0)) {

            //extend the bounds to include each marker's position
            let mapBounds = new window.google.maps.LatLngBounds();
            Object.keys(this.pointMapDataMap).forEach((key) => {
                mapBounds.extend(this.pointMapDataMap[key].circle.center);
            });
            this.map.fitBounds(mapBounds);

            //(optional) restore the zoom level after the map is done scaling
            if (this.props.isSetting) {
                if (this.map.getZoom() > this.minZoom) {
                    this.map.setZoom(this.minZoom);
                }
            } else {
                let listener = window.google.maps.event.addListener(this.map, "idle", () => {
                    if (this.map.getZoom() > this.minZoom) {
                        this.map.setZoom(this.minZoom);
                    }
                    window.google.maps.event.removeListener(listener);
                });
            }
        }
    }

    clearRemovedPointMapData = () => {
        Object.keys(this.pointMapDataMap).forEach((key) => {
            if (!this.props.chartSources.map(x => x.id).includes(key)) {
                let pointMapData = this.pointMapDataMap[key];

                pointMapData.infowindow.close();
                window.google.maps.event.clearListeners(pointMapData.circle, 'mouseover');
                window.google.maps.event.clearListeners(pointMapData.circle, 'mouseout');

                pointMapData.circle.setMap(null);

                delete this.pointMapDataMap[key];
            }
        });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div className={styles.errorMsg}>
                {LLMsg('COMMON.CHART.NOT_AVAILABLE_GOOGLE_MAP')}
            </div>;
        }

        return <div ref={(ref) => {
                    this.mapRef = ref;
                }} style={{
                    height: this.props.customHeight || "100%",
                    width: "100%"
                }}/>;
    }
}

export default PointMapFragment;