// @flow

import React, {Component} from 'react';
import DashboardHeaderFragment from "../DashboardHeaderFragment";
import Api from "../../../bundle/Api";
import {Redirect, withRouter} from "react-router";
import _ from "lodash";
import LineChartFragment from "../../chart/LineChartFragment";
import GaugeChartFragment from "../../chart/GaugeChartFragment";
import NoChartInDashboard from "../NoChartInDashboard";
import Page from "../../../bundle/Page";
import {connect} from 'react-redux'
import type {SimpleAction} from "../../../redux/reducer";
import {createDashboardPrompt} from "../CreateDashboardButton";
import {LLMsg} from "../../../IntlCapture";
import LoadingUtil from "../../../util/LoadingUtil";
import ErrorUtil from "../../../util/ErrorUtil";
import Constant from "../../../bundle/Constant";
import PlainTextChartFragment from "../../chart/PlainTextChartFragment";
import ToggleButtonChartFragment from "../../chart/ToggleButtonChartFragment";
import PushButtonChartFragment from "../../chart/PushButtonChartFragment";
import PointMapChartFragment from "../../chart/PointMapChartFragment";

type DashboardWrapperFragmentProps = {
    action?: SimpleAction,
    match?: Object,
    location?: Object,
    history?: Object
};
type DashboardWrapperFragmentStates = {
    dashboards?: Array<Object>,
    currentDashboard?: Object
};

class DashboardWrapperFragment extends Component<DashboardWrapperFragmentProps, DashboardWrapperFragmentStates> {

    currentDashboardId: string;

    constructor(props: DashboardWrapperFragmentProps) {
        super(props);
        this.state = {};
        this.fetchDashboardData = this.fetchDashboardData.bind(this);
        this.currentDashboardId = _.get(this.props, "match.params.dashboardId", undefined);

    }

    fetchDashboardData = () => {
        LoadingUtil.showFullScreenLoading();
        Api.services.dashboard.get.list().then(response => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({
                    dashboards: response.data,
                    currentDashboard: _.find(response.data, dashboard => {
                        return dashboard.id + "" === this.currentDashboardId
                    })
                }, () => {
                    if (this.state.dashboards && this.state.dashboards.length === 0) {
                        createDashboardPrompt(this.props.history);
                    }
                });
            }
        }).catch(error => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(error);
        });
    };

    componentDidMount() {
        this.fetchDashboardData();
    }

    componentDidUpdate(prevProps, prevState) {

        let previousDashboardId = _.get(prevProps, "match.params.dashboardId", undefined);
        let currentDashboardId = _.get(this.props, "match.params.dashboardId", undefined);

        if (previousDashboardId !== currentDashboardId) {
            this.currentDashboardId = currentDashboardId;
            this.fetchDashboardData();
        }

        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.dashboard.CHART_ADDED_TO_DASHBOARD)) {
            this.fetchDashboardData();
        }

        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.chart.DELETED_CHART)) {
            this.fetchDashboardData();
        }

        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.dashboard.DASHBOARD_RENAMED)) {
            this.fetchDashboardData();
        }
    }

    render() {

        let dashboardCharts: ?Array<Object>;
        if (this.currentDashboardId && this.state.dashboards && this.state.currentDashboard) {
            dashboardCharts = this.state.currentDashboard.dashboardCharts;
        }

        if (!this.currentDashboardId && this.state.dashboards && this.state.dashboards.length > 0) {

            let dashboard = this.state.dashboards[0];
            return <Redirect to={Page.internals.urls.dashboard.getDashboardPageUrl(dashboard.id, dashboard.name)}/>
        }

        Page.updateTitle("PAGE.TITLE.DASHBOARD", [{
            key: "DASHBOARD_NAME",
            value: _.get(this.state, "currentDashboard.name", "")
        }]);
        return <div className="m-portlet m-portlet--accent m-portlet--head-solid-bg m-portlet--head-sm" m-portlet="true"
                    id="DashboardWrapperFragment">
            <DashboardHeaderFragment
                currentDashboard={this.state.currentDashboard ? {
                    dashboardId: this.state.currentDashboard.id,
                    dashboardName: this.state.currentDashboard.name
                } : null}

                title={this.state.currentDashboard ? this.state.currentDashboard.name : ""}
                dashboards={this.state.dashboards ? this.state.dashboards.map((dashboard) => {
                    return {
                        dashboardId: dashboard.id,
                        dashboardName: dashboard.name
                    }
                }) : []}/>


            <div className="m-portlet__body">
                {
                    (!dashboardCharts || dashboardCharts.length === 0) && <NoChartInDashboard/>
                }
                <div className={"row"}>
                    {
                        dashboardCharts &&
                        dashboardCharts.map((dashboardChart) => {

                            let chart = dashboardChart.chart;
                            let chartType = chart.chartType.name;
                            let sizeClass = "p-2 col-" + _.get(dashboardChart, "size", "12");
                            let chartName = dashboardChart.chart.name;

                            return <div key={dashboardChart.id} className={sizeClass}>
                                <div className={"m-portlet mb-0"}>
                                    <div className="m-portlet__body">
                                        {
                                            chartType === Constant.chart.type.basicLineChart &&
                                            <LineChartFragment title={chartName}
                                                               aggregate={null} endTime={null}
                                                               interval={null} isLive={true} startTime={null}
                                                               chartId={dashboardChart.chart.id}
                                                               config={chart.chartConfig}
                                                               sources={chart.chartSources}/>
                                        }
                                        {
                                            chartType === Constant.chart.type.gauge &&
                                            <GaugeChartFragment title={chartName}
                                                                chartId={dashboardChart.chart.id}
                                                                config={chart.chartConfig}
                                                                sources={chart.chartSources}/>
                                        }

                                        {
                                            chartType === Constant.chart.type.plainTextChart &&
                                            <PlainTextChartFragment title={chart.name}
                                                                    chartId={chart.id}
                                                                    config={chart.chartConfig}
                                                                    sources={chart.chartSources}/>
                                        }
                                        {
                                            chartType === Constant.chart.type.toggleButton &&
                                            <ToggleButtonChartFragment title={chart.name}
                                                                       chartId={chart.id}
                                                                       config={chart.chartConfig}
                                                                       sources={chart.chartSources}/>
                                        }

                                        {
                                            chartType === Constant.chart.type.pushButton &&
                                            <PushButtonChartFragment title={chart.name}
                                                                     chartId={chart.id}
                                                                     config={chart.chartConfig}
                                                                     sources={chart.chartSources}/>
                                        }

                                        {
                                            chartType === Constant.chart.type.pointMap &&
                                            <PointMapChartFragment title={chart.name}
                                                                     chartId={chart.id}
                                                                     config={chart.chartConfig}
                                                                     sources={chart.chartSources}/>
                                        }
                                    </div>
                                </div>
                            </div>;
                        })
                    }
                </div>

            </div>
        </div>;

    }
}

const mapStateToProps = state => {
    return {
        action: state.ActionReducer.action
    };
};

export default withRouter(connect(mapStateToProps, null)(DashboardWrapperFragment));