// @flow

class ProjectAction {
    static CREATE_PROJECT_ACTION_TYPE = "CREATE_PROJECT_ACTION";
    static GET_LIST_OF_PROJECT_ACTION_TYPE = "GET_LIST_OF_PROJECT_ACTION";
    static GET_ONE_PROJECT_ACTION_TYPE = "GET_ONE_PROJECT_ACTION";
    static PROJECT_UPDATED = "PROJECT_UPDATED_ACTION";

    static SHOW_PROJECT_FILE_MODAL_ACTION_TYPE = "SHOW_PROJECT_FILE_MODAL_ACTION";

}

export default ProjectAction;