exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1SJprqTOcJ3prCkgN5AQfb {\n\n    list-style-type: none;\n}", ""]);

// exports
exports.locals = {
	"languageBox": "_1SJprqTOcJ3prCkgN5AQfb"
};