// @flow

import React from 'react';
import styles from './CreateConditionButton.css';
import {LLMsg} from "../../../IntlCapture";
import Constant from "../../../bundle/Constant";

type CreateConditionButtonProps = {
    conditionKey: number,
    createTopicTextContains: (conditionKey: number, sameLevel: boolean) => void,
    createTopicKeyValueCompare: (conditionKey: number, sameLevel: boolean) => void
}

class CreateConditionButton extends React.Component<CreateConditionButtonProps, any> {

    createTopicTextContains = (sameLevel: boolean) => {
        this.props.createTopicTextContains(this.props.conditionKey, sameLevel);
    };

    createTopicKeyValueCompare = (sameLevel: boolean) => {
        this.props.createTopicKeyValueCompare(this.props.conditionKey, sameLevel);
    };

    render() {
        return <div className="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--large"
                    style={{top: "35px"}}
                    m-dropdown-toggle="hover" m-dropdown-persistent="0"
                    aria-expanded="true">
            <button className={"m-dropdown__toggle  " + styles.addNewCondition}
                    style={{backgroundColor: Constant.theme.eventTrigger.filter.color}}>
                {/*{LLMsg("EVENT_TRIGGER.ADD_NEW_FILTER")}*/}
                <i className={"fas fa-plus"}/>
            </button>
            <div className="m-dropdown__wrapper" style={{zIndex: 101}}>
                <span className="m-dropdown__arrow m-dropdown__arrow--left"/>
                <div className="m-dropdown__inner" style={{minWidth: 500}}>
                    <div className="m-dropdown__body">
                        <div className="m-dropdown__content">
                            <div>
                                <div className={styles.addNewConditionItemLabel}>
                                    {LLMsg("EVENT_TRIGGER.CONDITION") + " 1: "}
                                    {LLMsg("EVENT_TRIGGER.SIMPLE_MESSAGE_CONDITION")}
                                </div>
                                <div className={styles.addNewConditionItemContentWrapper}>
                                    <div className={styles.addNewConditionItemSameLevel} onClick={() => {
                                        this.createTopicTextContains(true)
                                    }}>
                                        {LLMsg("EVENT_TRIGGER.SAME_LEVEL")}
                                    </div>

                                    <div className={styles.addNewConditionItemSubLevel} onClick={() => {
                                        this.createTopicTextContains(false)
                                    }}>
                                        {LLMsg("EVENT_TRIGGER.SUB_LEVEL")}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={styles.addNewConditionItemLabel}>
                                    {LLMsg("EVENT_TRIGGER.CONDITION") + " 2: "}
                                    {LLMsg("EVENT_TRIGGER.KEY_VALUE_CONDITION")}
                                </div>

                                <div className={styles.addNewConditionItemContentWrapper}>
                                    <div className={styles.addNewConditionItemSameLevel} onClick={() => {
                                        this.createTopicKeyValueCompare(true)
                                    }}>
                                        {LLMsg("EVENT_TRIGGER.SAME_LEVEL")}
                                    </div>

                                    <div className={styles.addNewConditionItemSubLevel} onClick={() => {
                                        this.createTopicKeyValueCompare(false)
                                    }}>
                                        {LLMsg("EVENT_TRIGGER.SUB_LEVEL")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;

    }
}

export default CreateConditionButton;