// @flow
import React, {Component} from "react";
import Image from "../../bundle/Image";
import {Link} from "react-router-dom";
import Api from "../../bundle/Api";
import Page from "../../bundle/Page";
import UserDao from "../../dao/UserDao";
import {LLMsg} from "../../IntlCapture";
import _ from 'lodash';
import * as PubSub from "pubsub-js";
import UrlBuilder from "../../url/UrlBuilder";

type HeaderUserProfileFragmentProps = {}

class HeaderUserProfileFragment extends Component<HeaderUserProfileFragmentProps, any> {

    subToken: any;

    componentDidMount() {
        this.subToken = PubSub.subscribe(Api.actions.user.GET_MYSELF_DONE_ACTION_TYPE, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subToken);
    }

    renderDropdown() {
        return <div className="m-dropdown__wrapper">
            <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"/>
            <div className="m-dropdown__inner">
                <div className="m-dropdown__header m--align-center">
                    <div className="m-card-user m-card-user--skin-light">
                        <div className="m-card-user__pic">
                            <img src={UserDao.pictureUrl}
                                 className="m--img-rounded m--marginless"
                                 alt=""/>
                        </div>
                        <div className="m-card-user__details">
                            <span className="m-card-user__name m--font-weight-500">{UserDao.firstName}</span>
                            <span className="m-card-user__email m--font-weight-300">{UserDao.email}</span>
                            <div className="mt-2 m--font-weight-300">{UserDao.schoolName}</div>
                        </div>
                    </div>
                </div>
                <div className="m-dropdown__body">
                    <div className="m-dropdown__content">
                        <ul className="m-nav m-nav--skin-light">
                            <li className="m-nav__item">
                                <Link to={Page.internals.urls.user.changePassword()}
                                   className="m-nav__link">
                                    <i className="m-nav__link-icon 	fab fa-keycdn"/>
                                    <span
                                        className="m-nav__link-text">{LLMsg("PASSWORD.CHANGE_PASSWORD_TITLE")}</span>
                                </Link>
                            </li>
                            <li className="m-nav__item">
                                <a href={Page.externals.scaleContactUs()}
                                   className="m-nav__link">
                                    <i className="m-nav__link-icon flaticon-info"/>
                                    <span
                                        className="m-nav__link-text">{LLMsg("HEADER.USER.FAQ")}</span>
                                </a>
                            </li>
                            <li className="m-nav__item">
                                <a href={Page.externals.scaleContactUs()}
                                   className="m-nav__link">
                                    <i className="m-nav__link-icon flaticon-lifebuoy"/>
                                    <span
                                        className="m-nav__link-text">{LLMsg("HEADER.USER.SUPPORT")}</span>
                                </a>
                            </li>
                            <li className="m-nav__separator m-nav__separator--fit">
                            </li>
                            <li className="m-nav__item">
                                <Link to={Page.internals.urls.user.logout(UrlBuilder.getCurrentUrl())}
                                      className="btn m-btn--pill    btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">
                                    {LLMsg("HEADER.USER.LOGOUT")}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>;
    }

    render() {
        return <li
            className="m-nav__item m-topbar__user-profile  m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
            m-dropdown-toggle="hover">

            <a href="#" className="m-nav__link m-dropdown__toggle">
                <span className="m-topbar__userpic">
                  <img src={UserDao.pictureUrl}
                       className="m--img-rounded m--marginless m--img-centered" alt=""/>
                </span>
                <span className="m-nav__link-icon m-topbar__usericon  m--hide">
                  <span className="m-nav__link-icon-wrapper">
                      <i className="flaticon-user-ok"/>
                  </span>
                </span>
                <span className="m-topbar__username m--hide">{UserDao.lastName}</span>
            </a>
            {this.renderDropdown()}
        </li>;
    }
}

export default HeaderUserProfileFragment;