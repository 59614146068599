// @flow

import React, {Component} from 'react';
import styles from './LicenseListTable.css';
import LicenseListTableRowDetailWrapper from "./LicenseListTableRowDetailWrapper";
import LicenseListTableRowDetail from "./LicenseListTableRowDetail";
import {LLMsg} from "../../../../IntlCapture";
import TimeUtil from "../../../../util/TimeUtil";
import Constant from "../../../../bundle/Constant";
import type {LicenseGenerationBatch} from "../../../../model/model";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";

type SchoolApplicationTableRowState = {
    showDetail: boolean
}
type SchoolApplicationTableRowProps = {
    licenseGenerationBatch: LicenseGenerationBatch
}

class LicenseListTableRow extends Component<SchoolApplicationTableRowProps, SchoolApplicationTableRowState> {

    state: SchoolApplicationTableRowState = {
        showDetail: false
    };

    render() {

        let status = "";
        if (this.props.licenseGenerationBatch.startGenerationTime && this.props.licenseGenerationBatch.completeGenerationTime) {
            status = "Completed"
        } else if (!this.props.licenseGenerationBatch.startGenerationTime && !this.props.licenseGenerationBatch.completeGenerationTime) {
            status = "Pending"
        } else if (this.props.licenseGenerationBatch.startGenerationTime && !this.props.licenseGenerationBatch.completeGenerationTime) {
            status = "Generating"
        }

        return <React.Fragment>
            <tr data-row={0} className={"m-datatable__row " + styles.row} onClick={() => {
                this.setState({showDetail: !this.state.showDetail})
            }}>
                <td className="m-datatable__cell m-datatable__toggle--detail">
                    <a className="m-datatable__toggle-detail">
                        <i className="fa fa-caret-right" style={{width: '21px'}}/>
                    </a>
                </td>
                <td className="m-datatable__cell">
                    <span style={{width: '50px'}}>
                        {this.props.licenseGenerationBatch.id}
                    </span>
                </td>
                <td className="m-datatable__cell">
                    <span style={{width: '100px'}}>
                        {this.props.licenseGenerationBatch.licenseTarget.targetName}
                    </span>
                </td>
                <td className="m-datatable__cell">
                    <span style={{width: '100px'}}>
                        {this.props.licenseGenerationBatch.licenseCount}
                    </span>
                </td>
                <td className="m-datatable__cell">
                    <span style={{width: '100px'}}>
                        {this.props.licenseGenerationBatch.trialDays + " Days"}
                    </span>
                </td>
                <td className="m-datatable__cell">
                    <span style={{width: '120px'}}>
                        {this.props.licenseGenerationBatch.maxConsumptionCount}
                    </span>
                </td>
                <td className="m-datatable__cell">
                    <span style={{width: '140px'}}>
                        {this.props.licenseGenerationBatch.maxConcurrentCount}
                    </span>
                </td>
                <td className="m-datatable__cell">
                    <span style={{width: '100px'}}>
                        {status}
                    </span>
                </td>
            </tr>
            {
                this.state.showDetail && <tr>
                    <LicenseListTableRowDetailWrapper>
                        <LicenseListTableRowDetail label={LLMsg("COMMON.ID")}
                                                   value={this.props.licenseGenerationBatch.id}/>
                        <LicenseListTableRowDetail label={"Target Package Name"}
                                                   value={this.props.licenseGenerationBatch.licenseTarget.targetName}/>
                        <LicenseListTableRowDetail label={"Target Company Name"}
                                                   value={this.props.licenseGenerationBatch.licenseTarget.companyName}/>
                        <LicenseListTableRowDetail label={"Number of license"}
                                                   value={this.props.licenseGenerationBatch.licenseCount}/>
                        <LicenseListTableRowDetail label={"Trial Period"}
                                                   value={this.props.licenseGenerationBatch.trialDays + " Days"}/>
                        <LicenseListTableRowDetail label={"Max Consumption Count"}
                                                   value={this.props.licenseGenerationBatch.maxConsumptionCount}/>
                        <LicenseListTableRowDetail label={"Max Concurrent Login Count"}
                                                   value={this.props.licenseGenerationBatch.maxConcurrentCount}/>
                        <LicenseListTableRowDetail label={"License Generation Start Time"}
                                                   value={TimeUtil.convertTimeFormat(this.props.licenseGenerationBatch.startGenerationTime, TimeUtil.FULL_DATE_DISPLAY_FORMAT) || "N/A"}/>
                        <LicenseListTableRowDetail label={"License Generation Finish Time"}
                                                   value={TimeUtil.convertTimeFormat(this.props.licenseGenerationBatch.completeGenerationTime, TimeUtil.FULL_DATE_DISPLAY_FORMAT) || "N/A"}/>
                        <LicenseListTableRowDetail label={"Created on"}
                                                   value={TimeUtil.convertTimeFormat(this.props.licenseGenerationBatch.createdOn, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}/>
                        <LicenseListTableRowDetail label={""}
                                                   value={<button type="button" className="btn btn-success"
                                                                  onClick={() => {
                                                                      RouterHistory().push(Page.internals.urls.license.adminLicenseBatchDetail(this.props.licenseGenerationBatch.id))
                                                                  }}>
                                                       Details
                                                   </button>}/>

                    </LicenseListTableRowDetailWrapper>
                </tr>
            }
        </React.Fragment>
    }
}

export default LicenseListTableRow;