// @flow

import React, {Component} from "react";
import Constant from "../../bundle/Constant";
import swal from "sweetalert2";
import Api from "../../bundle/Api";
import {createAction} from "../../redux/reducer";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import type {Match} from "../../App";
import {LLMsg} from "../../IntlCapture";
import LoadingUtil from "../../util/LoadingUtil";
import ErrorUtil from "../../util/ErrorUtil";

type CreateDataRecordTypeInlineButtonProps = {

    topicId: string,

    dispatchNewDataTypeCreated?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}

export const createDataRecordTypePrompt = (topicId: string, dispatchDataTypeCreatedCallback: ? any) => {

    let form = ' <form class="m-form m-form--fit m-form--label-align-right text-left">' +
        '            <h1 style="color: ' + Constant.theme.dataType.color + '" class="mb-3 m--icon-font-size-lg5 text-center">' +
        '                <i class="' + Constant.theme.dataType.icon + ' fa-1x mr-1"}></i>' +
        '                <span>' + LLMsg("COMMON.DATA_TYPE.DATA_TYPE") + '</span>' +
        '            </h1>' +
        '            <div class="row">' +
        '                <div class="col-12">' +
        '                    <div class="form-group m-form__group pb-0 ">' +
        '                        <label class=" m--icon-font-size-lg2">' + LLMsg("COMMON.DATA_TYPE.DATA_TYPE_NAME") + '</label>' +
        '                        <input name="newDataTypeName" type="text" autofocus="true" class="form-control m-input m-input--square"' +
        '                               placeholder="' + LLMsg("COMMON.DATA_TYPE.NAME_PLACEHOLDER") + '"/>' +
        '                    </div>' +
        '                    <div class="form-group m-form__group pb-0">' +
        '                        <label class=" m--icon-font-size-lg2">' + LLMsg("COMMON.DATA_TYPE.DATA_TYPE_KEY") + '</label>' +
        '                        <input name="newDataTypeKey" type="text" class="form-control m-input m-input--square" ' +
        '                               placeholder="' + LLMsg("COMMON.DATA_TYPE.KEY_PLACEHOLDER") + '"/>' +
        '                        <span class="m-form__help m--icon-font-size-lg0">' + LLMsg("COMMON.DATA_TYPE.UNIQUE_LABEL") + '</span>' +
        '                    </div>' +
        '                    <div class="form-group m-form__group pb-0">' +
        '                        <label class=" m--icon-font-size-lg2">' + LLMsg("COMMON.DATA_TYPE.DATA_TYPE_DESC") + '</label>' +
        '                        <input name="newDataTypeDescription" type="text" class="form-control m-input m-input--square"' +
        '                               placeholder="' + LLMsg("COMMON.DATA_TYPE.DESC_PLACEHOLDER") + '"/>' +
        '                    </div>' +
        '                </div>' +
        '            </div>' +
        '        </form>';

    swal({
        html: form,
        showCancelButton: true,
        width: 600,
        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
        cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        preConfirm: () => {
            let newNameInput: Object = document.querySelector('input[name="newDataTypeName"]') || {};
            let newKeyInput: Object = document.querySelector('input[name="newDataTypeKey"]') || {};
            let newDescriptionInput: Object = document.querySelector('input[name="newDataTypeDescription"]') || {};

            let hasError = false;
            if (!newNameInput.value || newNameInput.value.length > 100) {
                hasError = true;
            }
            if (!newKeyInput.value || newKeyInput.value.length > 20) {
                hasError = true;
            }
            if ((newDescriptionInput || "").length > 100) {
                hasError = true;
            }
            if (hasError) {
                swal.showValidationMessage(
                    LLMsg("COMMON.DATA_TYPE.INVALID_FIELD")
                );
                return false;
            }
            return true;
        },
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value) {
                    resolve()
                } else {
                    resolve('Data Type name cannot empty!');
                }
            })
        }
    }).then((e) => {
        if (e.dismiss) {
            return;
        }
        if (e.value) {
            let newNameInput: Object = document.querySelector('input[name="newDataTypeName"]') || {};
            let newKeyInput: Object = document.querySelector('input[name="newDataTypeKey"]') || {};
            let newDescriptionInput: Object = document.querySelector('input[name="newDataTypeDescription"]') || {};

            LoadingUtil.showFullScreenLoading();
            Api.services.topic.update.newDataType(topicId, newNameInput.value, newKeyInput.value, newDescriptionInput.value).then(response => {
                LoadingUtil.hideFullScreenLoading();
                if (response.status === 200) {
                    swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), "", "success");
                    dispatchDataTypeCreatedCallback && dispatchDataTypeCreatedCallback();
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch((e) => {
                LoadingUtil.hideFullScreenLoading();
                ErrorUtil.promptError(e);
            });
        } else {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        }
    });
};

class CreateDataRecordTypeInlineButton extends Component <CreateDataRecordTypeInlineButtonProps, any> {

    render() {
        return <button className="btn  m-btn btn-sm ml-2 mr-2" onClick={() => {
            createDataRecordTypePrompt(this.props.topicId, this.props.dispatchNewDataTypeCreated);
        }}>
         <span>
            <i className={Constant.theme.add.icon}/>
            <span className={"m--hidden-mobile ml-2"} style={{letterSpacing:"1.3px"}}>
                {LLMsg("COMMON.DATA_TYPE.CREATE_DATA_TYPE")}
            </span>
        </span>
        </button>;

    }
};

const mapDispatchToProps = dispatch => ({
    dispatchNewDataTypeCreated: () => dispatch(createAction(Api.actions.dataType.CREATE_DATA_TYPE_ACTION))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(CreateDataRecordTypeInlineButton));
