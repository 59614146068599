// @flow

import React, {Component} from 'react';
import LicenseListTableRow from "./LicenseListTableRow";
import type {LicenseGenerationBatch} from "../../../../model/model";


type LicenseListTableContentProps = {
    licenseList: Array<LicenseGenerationBatch>
};

class LicenseListTableContent extends Component<LicenseListTableContentProps, any> {

    render() {

        return <tbody className="m-datatable__body">
        {
            this.props.licenseList && this.props.licenseList.map((licenseGenerationBatch, index) => {
                return <LicenseListTableRow
                    key={index}
                    licenseGenerationBatch={licenseGenerationBatch}
                 />
            })
        }
        </tbody>;
    }
}

export default LicenseListTableContent;