// @flow

import PageUrl from "./PageUrl";
import UrlBuilder from "../UrlBuilder";
import type {ToObject} from "./PageUrl";
import StringUtil from "../../util/StringUtil";

export default class UserPageUrl {

    static admin = {
        list: (): string => {
            return PageUrl.ADMIN_USER_LIST_URL;
        },
        detail: (userId: number): string => {
            return StringUtil.replaceAllText(PageUrl.ADMIN_USER_DETAIL_URL, [
                {
                    key: ":userId",
                    value: userId + ""
                }
            ]);
        },
        whitelistUserList: (): string => {
            return PageUrl.ADMIN_WHITELIST_USER_LIST_URL;
        }
    };

    static login(ref: ?string): ToObject {
        return {
            pathname: PageUrl.LOGIN_URL,
            search: ref ? "?" + UrlBuilder.toQueryString({"ref": ref}) : ""
        };
    }

    static registerAsGuest(ref: ?string): ToObject {
        return {
            pathname: PageUrl.GUEST_REGISTER_URL,
            search: ref ? "?" + UrlBuilder.toQueryString({"ref": ref}) : ""
        };
    }

    static registerWithLicense(ref: ?string): ToObject {
        return {
            pathname: PageUrl.LICENSED_USER_REGISTER_URL,
            search: ref ? "?" + UrlBuilder.toQueryString({"ref": ref}) : ""
        };
    }

    static register(): ToObject {
        return {
            pathname: PageUrl.REGISTER_URL
        };
    }

    static schoolRegister(): ToObject {
        return {
            pathname: PageUrl.SCHOOL_REGISTER_URL
        };
    }

    static changePassword(): ToObject {
        return {
            pathname: PageUrl.CHANGE_ACCOUNT_PASSWORD_URL
        };
    }

    static resetPassword(): ToObject {
        return {
            pathname: PageUrl.RESET_PASSWORD_URL
        };
    }

    static logout(ref: ?string): ToObject {
        return {
            pathname: PageUrl.LOGOUT_URL,
            search: ref ? "?" + UrlBuilder.toQueryString({"ref": ref}) : ""
        };
    }

}