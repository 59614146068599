// @flow

import React from 'react';
import Constant from "../../../bundle/Constant";
import Api from "../../../bundle/Api";
import {createAction} from "../../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import {renameChartPrompt} from "../ChartDropdownFragment/RenameChartDropdownButton";
import {LLMsg} from "../../../IntlCapture";

type RenameChartButtonProps = {

    dispatchChartRenamed?: Function,
    chartId: string,
    chartName: string
}

const RenameChartButton = (props: RenameChartButtonProps) => {

    return <button
        className="ml-2 pb-3 btn btn-link m-btn--pill btn-md mr-3 no-a-effect"
        style={{color: "#3e4567"}}
        onClick={() => {
            renameChartPrompt(props.chartName, props.chartId, props.dispatchChartRenamed);
        }}>
        <span>
            <i className={Constant.theme.edit.icon}/>
            <span className={"m--hidden-mobile ml-2"}>
                {LLMsg("COMMON.ACTION.EDIT")}</span>
        </span>
    </button>;
};

const mapDispatchToProps = dispatch => ({
    dispatchChartRenamed: () => dispatch(createAction(Api.actions.chart.RENAMED_CHART))
});

export default connect(null, mapDispatchToProps)(RenameChartButton);
