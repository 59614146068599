exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1fLct2TDKlWv-XmYR_1nhu {\n    margin-top: 10px;\n    margin-left: 10px;\n    margin-bottom: 5px;\n    font-weight: bolder;\n    font-size: larger;\n}\n\n._1fLct2TDKlWv-XmYR_1nhu a {\n    color: #0a6aa1 !important;\n\n}\n\n.AWoJuLiEnm5TddfJxDcBU {\n    margin-top: 20px;\n    padding-left: 20px;\n    height: 200px;\n    overflow-y: scroll;\n    overflow-x: auto;\n}\n\n._3o8OG58cg7SX-A9WLYmZuc {\n\n    border-left: 5px solid #0a6aa1;\n    padding: 5px 20px;\n    font-size: 22px;\n}\n\n._2n1Hu8i9nhTHiEOc80TwU7 {\n    height: 300px;\n}\n\n.en3dymCZzi7u4kMB8Zyz3 {\n    min-height: 100%;\n    height: 100%;\n}", ""]);

// exports
exports.locals = {
	"item": "_1fLct2TDKlWv-XmYR_1nhu",
	"ol": "AWoJuLiEnm5TddfJxDcBU",
	"title": "_3o8OG58cg7SX-A9WLYmZuc",
	"wrapper": "_2n1Hu8i9nhTHiEOc80TwU7",
	"content": "en3dymCZzi7u4kMB8Zyz3"
};