// @flow

import React from 'react';
import {LLMsg} from "../../../../IntlCapture";
import {Link} from "react-router-dom";
import styles from './ChartSourceFragment.css';
import Page from "../../../../bundle/Page";
import type {ChartSource, ChartType} from "../../../../model/model";
import Constant from "../../../../bundle/Constant";

type ChartSourceFragmentProps = {
    chartType: string,
    sources: ? Array<ChartSource>
}

class ChartSourceFragment extends React.Component <ChartSourceFragmentProps, any> {
    render() {
        let sources = this.props.sources;
        return <div className={"mt-0 mb-3 "}>
            <table className={"table table-bordered m-table " + styles.table}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{LLMsg("COMMON.TOPIC.TOPIC")}</th>
                    <th>{LLMsg("COMMON.DATA_TYPE.DATA_TYPE")}</th>
                    <th>{LLMsg("COMMON.DEVICE.DEVICE")}</th>
                    {
                        this.props.chartType == Constant.chart.type.pointMap &&
                        <th>{LLMsg("COMMON.CHART.SOURCE_DESCRIPTION")}</th>
                    }
                    {
                        this.props.chartType == Constant.chart.type.pointMap &&
                        <th>{LLMsg("COMMON.CHART.LATITUDE")}</th>
                    }
                    {
                        this.props.chartType == Constant.chart.type.pointMap &&
                        <th>{LLMsg("COMMON.CHART.LONGITUDE")}</th>
                    }
                </tr>
                </thead>
                <tbody>

                {
                    sources && sources.map((source: ChartSource, index: number) => {

                        return <tr key={source.id}>
                            <td>{index + 1}</td>
                            <td>
                                {
                                    source.topic && <Link
                                        to={Page.internals.urls.topic.detail(source.topic.id, source.topic.description)}
                                        className={"m-link m--font-bolder m-link--danger " + styles.topicCol}>
                                        {source.topic.description} - {source.topic.topicName}
                                    </Link>
                                }
                            </td>
                            <td>
                                {
                                    source.dataRecordType && <Link
                                        to={Page.internals.urls.topic.detail(source.topic.id, source.topic.description)}
                                        className={"m-link m--font-bolder m-link--warning " + styles.dataTypeCol}>
                                        {source.dataRecordType.dataRecordTypeName} - {source.dataRecordType.keyName}
                                    </Link>
                                }
                            </td>
                            <td>
                                {
                                    source.device && <Link
                                        to={Page.internals.urls.topic.detail(source.topic.id, source.topic.description)}

                                        className={"m-link m--font-bolder m-link--success " + styles.deviceCol}>
                                        {source.device.serialName} - {source.device.serialNumber}
                                    </Link>
                                }
                            </td>
                            {
                                this.props.chartType == Constant.chart.type.pointMap &&
                                <td>
                                    {source.chartSourceConfig.description}
                                </td>
                            }
                            {
                                this.props.chartType == Constant.chart.type.pointMap &&
                                <td>
                                    {source.topic.latitude}
                                </td>
                            }
                            {
                                this.props.chartType == Constant.chart.type.pointMap &&
                                <td>
                                    {source.topic.longitude}
                                </td>
                            }
                        </tr>;
                    })
                }
                </tbody>
            </table>
        </div>;
    }
}

export default ChartSourceFragment;