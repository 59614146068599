// @flow

import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";

class DashboardService {

    static get = {
        list: (): Object => {
            return HttpUtil.ajax(Api.urls.dashboard.get.list(), Api.methods.get, {
                'Content-Type': 'application/json',
            }, {});
        }
    };

    static update = {
        name: (dashboardId: string, newName: string): Object => {
            return HttpUtil.ajax(Api.urls.dashboard.update.name(), Api.methods.put, {
                'Content-Type': 'application/json',
            }, {dashboardId, dashboardName: newName});
        },
        addChart: (dashboardId: string, chartId: string, size?: string): Object => {
            return HttpUtil.ajax(Api.urls.dashboard.update.addChart(), Api.methods.put, {
                'Content-Type': 'application/json',
            }, {
                dashboardId, chartId, size
            });
        }
    };

    static create = (dashboardName: string): Object => {
        return HttpUtil.ajax(Api.urls.dashboard.create(), Api.methods.post, {
            'Content-Type': 'application/json',
        }, {name: dashboardName});
    };

    static delete = (dashboardId: string): Object => {
        return HttpUtil.ajax(Api.urls.dashboard.delete(), Api.methods.post, {
            'Content-Type': 'application/json',
        }, {dashboardId: dashboardId});
    };
}

export default DashboardService;