// @flow

import StringUtil from "../../util/StringUtil";
import PageUrl from "./PageUrl";
import UrlBuilder from "../UrlBuilder";

export default class ProjectPageUrl {

    static list(): string {
        return PageUrl.PROJECT_URL;
    }

    static detail(projectId: string, projectName: string, queryObject: Object): object {
        return {
            pathname: StringUtil.replaceAllText(PageUrl.PROJECT_DETAIL_URL, [
                {
                    key: ":projectId",
                    value: projectId + ""
                }, {
                    key: ":projectName",
                    value: StringUtil.removeBlacklistChar(projectName)
                }
            ]),
            search: "?" + UrlBuilder.toQueryString(queryObject)
        };
    }

    static dataTrigger(projectId: string, projectName: string): string {
        return this.detail(projectId, projectName, {section: "event-trigger"});
    }

    static eventTriggerDetail(projectId: string, appletId: string): string {
        return StringUtil.replaceAllText(PageUrl.PROJECT_TRIGGER_DETAIL_URL, [
            {
                key: ":projectId",
                value: projectId
            }, {
                key: ":appletId",
                value: appletId
            }
        ]);

    }

    static create(): string {
        return PageUrl.PROJECT_CREATE_URL;
    }

    static joinProjectByToken(token: string): string {
        return StringUtil.replaceAllText(PageUrl.PROJECT_JOIN_BY_TOKEN_URL, [
            {
                key: ":token",
                value: token
            }
        ]);
    }

    static getEmbeddedMapUrl(token: string): string {
        return StringUtil.replaceAllText(PageUrl.PROJECT_MAP_EMBEDDED_URL, [
            {
                key: ":token",
                value: token
            }
        ]);
    }
}