exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#_3LZxepgNURVqIhRD_Ty9QJ {\n    background: #fbfbfb;\n    border: 1px solid #eee;\n}\n\n.jjJLvuYYscZognZjQ628Y {\n    word-wrap: break-word;\n}\n\n.KBKrcLbTFVPp8_ZJMMtCq {\n    word-wrap: break-word;\n}\n\n._1BeK1PItNzIKXz-d4T_w11 {\n    word-wrap: break-word;\n}\n\n._1U26jF4Ui6SW8oNU9K19mR td, ._1U26jF4Ui6SW8oNU9K19mR th {\n    border: 1px solid #ebedf2 !important;\n}", ""]);

// exports
exports.locals = {
	"container": "_3LZxepgNURVqIhRD_Ty9QJ",
	"topicCol": "jjJLvuYYscZognZjQ628Y",
	"dataTypeCol": "KBKrcLbTFVPp8_ZJMMtCq",
	"deviceCol": "_1BeK1PItNzIKXz-d4T_w11",
	"table": "_1U26jF4Ui6SW8oNU9K19mR"
};