// @flow

import React from 'react';
import Constant from "../../../bundle/Constant";
import {addChartToAnyDashboardPrompt} from "../ChartDropdownFragment/AddChartToAnyDashboardDropdownButton";
import {LLMsg} from "../../../IntlCapture";

type AddChartToAnyDashboardButtonProps = {
    chartId: string,
    chartType: string
}

const AddChartToAnyDashboardButton = (props: AddChartToAnyDashboardButtonProps) => {

    return <button className="btn btn-outline-success m-btn mr-3" onClick={() => {
        addChartToAnyDashboardPrompt(props.chartId, props.chartType);
    }}>
        <span>
            <i className={Constant.theme.dashboard.icon}/>
            <span className={"m--hidden-mobile ml-2"}>
                {LLMsg("COMMON.DASHBOARD.ADD_CHART_TO_DASHBOARD")}
                </span>
        </span>
    </button>;
};
export default AddChartToAnyDashboardButton;