// @flow

import React, {Component} from "react";
import {Link} from "react-router-dom";
import {withRouter} from 'react-router';
import Page from "../../../bundle/Page";
import Constant from "../../../bundle/Constant";
import {LLMsg} from "../../../IntlCapture";

type TopicListTableRecordProps = {
    index: number,
    topicId: number,
    topicName: string,
    description: string,
    isActive: boolean,
    projects: Array<Object>,

    match?: Object,
    location?: Object,
    history?: Object
}

class TopicListTableRecord extends Component<TopicListTableRecordProps> {

    redirectToTopicDetailPage = (topicId: number, topicDescription: string) => {
        this.props.history && this.props.history.push({pathname: Page.internals.urls.topic.detail(topicId + "", topicDescription)});
    };

    render() {
        const {index, topicId, topicName, description, projects} = this.props;
        let size = "m--icon-font-size-lg3";
        return <tr role="row" className="odd">

            <td className="cursor-pointer" onClick={(e) => {
                this.redirectToTopicDetailPage(topicId, topicName)
            }}>
                <div>
                    <span className={"mr-3 " + size} style={{color: Constant.theme.topic.color, fontWeight: 400}}>
                         <i className={Constant.theme.topic.icon + " mr-2 fa-1x"}/>
                        {index}. {description}
                    </span>
                </div>
                <span className={"m--icon-font-size-lg0 ml-4 mr-2"}
                      style={{color: Constant.theme.project.color, fontWeight: 400}}>
                    <i className={Constant.theme.project.icon + " mr-2 fa-1x"}/>

                    {LLMsg("COMMON.PROJECT.PROJECT")}:
                </span>
                {
                    projects && projects.map((project, index) => {
                        return <Link key={index}
                                     className={"m--icon-font-size-lg1 cursor-pointer"}
                                     style={{color: "grey", fontWeight: 400}}
                                     to={Page.internals.urls.project.detail(project.id, project.projectName)}>

                            <button key={index} type="button"
                                    style={{
                                        borderColor: "#f9f9f9",
                                        color: Constant.theme.project.color,
                                        background: "#f9f9f9"
                                    }}
                                    className="btn m-btn--pill m-btn btn-sm m--margin-right-5 pt-1 pb-1 btm-sm m--margin-bottom-5 m--margin-top-5">
                                {project.projectName}
                            </button>
                        </Link>
                    })
                }
                {
                    (!projects || projects.length === 0) && <span>{" - "}</span>
                }

            </td>
            <td>
                    <span className={"mr-3 pl-2 pr-2 pt-1 pb-1  m--icon-font-size-lg0"}
                          style={{backgroundColor: "#f9f9f9", color: Constant.theme.topic.color}}>{topicName}</span>
            </td>
        </tr>;
    }
}

export default withRouter(TopicListTableRecord);