// @flow


import PageUrl from "./PageUrl";
import StringUtil from "../../util/StringUtil";
import Constant from "../../bundle/Constant";
import DataStore from "../../store/DataStore";

export default class PageUrlBuilder {

    static home(): string {
        return PageUrl.HOME_URL;
    }

    static updateLogs(): string {
        return PageUrl.WHATS_NEW_URL;
    }

    static privacy(): string {
        let language;
        let currentLanguage = DataStore.cookies.get(Constant.cookies.language);
        if (currentLanguage === Constant.language.english) {
            language = "en";
        } else if (currentLanguage === Constant.language.traditionalChinese || currentLanguage === Constant.language.simplifiedChinese) {
            language = "tc";
        } else {
            language = "tc";
        }
        return StringUtil.replaceAllText(PageUrl.PRIVACY_URL, [
            {
                key: ":language",
                value: language
            }
        ]);

    }

    static copyright(): string {
        let language;
        let currentLanguage = DataStore.cookies.get(Constant.cookies.language);
        if (currentLanguage === Constant.language.english) {
            language = "en";
        } else if (currentLanguage === Constant.language.traditionalChinese || currentLanguage === Constant.language.simplifiedChinese) {
            language = "tc";
        } else {
            language = "tc";
        }
        return StringUtil.replaceAllText(PageUrl.COPYRIGHT_URL, [
            {
                key: ":language",
                value: language
            }
        ]);
    }

    static disclaimer(): string {
        let language;
        let currentLanguage = DataStore.cookies.get(Constant.cookies.language);
        if (currentLanguage === Constant.language.english) {
            language = "en";
        } else if (currentLanguage === Constant.language.traditionalChinese || currentLanguage === Constant.language.simplifiedChinese) {
            language = "tc";
        } else {
            language = "tc";
        }
        return StringUtil.replaceAllText(PageUrl.DISCLAIMER_URL, [
            {
                key: ":language",
                value: language
            }
        ]);
    }

    static terms(): string {
        let language;
        let currentLanguage = DataStore.cookies.get(Constant.cookies.language);
        if (currentLanguage === Constant.language.english) {
            language = "en";
        } else if (currentLanguage === Constant.language.traditionalChinese || currentLanguage === Constant.language.simplifiedChinese) {
            language = "tc";
        } else {
            language = "tc";
        }
        return StringUtil.replaceAllText(PageUrl.TERMS_URL, [
            {
                key: ":language",
                value: language
            }
        ]);
    }

    static thirdPartyUse(): string {
        return StringUtil.replaceAllText(PageUrl.THIRD_PARTY_USE_URL);
    }

    static resourceSelection(): string {
        return PageUrl.RESOURCE_URL;
    }


}