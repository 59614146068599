import moment from "moment-timezone";

export type LocalDateTimeType = {
    year: number,
    month: string, // "JANUARY",
    hour: number,
    minute: number,
    second: number,
    nano: number,
    dayOfMonth: number,
    dayOfWeek: string, // SATURDAY,
    dayOfYear: number,
    monthValue: number,
    chronology: LocalDateTimeChronologyType
}

type LocalDateTimeChronologyType = {

    id: string, // "ISO"
    calendarType: string // "iso8601"
}

export default class TimeUtil {

    static FULL_DATE_DISPLAY_FORMAT = "YYYY-MM-D HH:mm:ss";
    static FULL_DATE_NO_SECOND_DISPLAY_FORMAT = "YYYY-MM-D HH:mm";
    static FULL_DATE_WITH_TIMEZONE_FORMAT = "YYYY-MM-DD HH:mm:ss ZZ";


    static readTime(originalTimeString: string, format: string) {
        if (!originalTimeString) {
            return null;
        }
        return moment(originalTimeString, format);
    }

    static convertTimeFormat(originalTimeString: string, newFormat: string) {
        if (!originalTimeString) {
            return ""
        }
        return moment(originalTimeString, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT).format(newFormat);
    }

    static isBeforeNow(originalTimeString: string): boolean {
        if (!originalTimeString) {
            return ""
        }
        return moment(originalTimeString, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT).isBefore(moment())
    }

    static isAfterNow(originalTimeString: string): boolean {
        if (!originalTimeString) {
            return ""
        }
        return moment(originalTimeString, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT).isAfter(moment())
    }

}
