// @flow
import React from 'react';
import {Link} from "react-router-dom";
import Image from "../../bundle/Image";
import Page from "../../bundle/Page";
import {LLMsg} from "../../IntlCapture";
import ImageUrlBuilder from "../../url/image/ImageUrlBuilder";

const HugeBannerFragment = () => {
    return <div className="m-grid__item   m-grid__item--order-tablet-and-mobile-3  m-grid m-grid--hor m-login__aside "
                style={{backgroundImage: 'url(' + ImageUrlBuilder.getHugeBannerBackground() + ')'}}>
        <div className="m-grid__item">
            <div className="m-login__logo">
                <Link to={Page.internals.urls.misc.home()}>
                    <img width={"100px"} alt={"Icon"} src={Image.urls.getMakerCloudWithScaleIcon()}/>
                </Link>
            </div>
        </div>
        <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver">
            <div className="m-grid__item m-grid__item--middle">
                <span className="m-login__title">{LLMsg("LOGIN_PAGE.DESC")}</span>
                <span className="m-login__subtitle">{LLMsg("LOGIN_PAGE.SUB_DESC")}</span>
            </div>
        </div>
        <div className="m-grid__item">
            <div className="m-login__info">
                <div className="m-login__section">
                    <a href={Page.externals.scaleHome()} className="m-link">
                        {(new Date()).getFullYear()} © Maker Cloud
                    </a>
                </div>
                <div className="m-login__section">
                    <a className="m-link" target="_blank" rel="noopener noreferrer"
                       href={Page.internals.urls.misc.privacy()}>{LLMsg("COMMON.FOO.PRIVACY")}</a>
                    <a className="m-link" target="_blank" rel="noopener noreferrer"
                       href={Page.internals.urls.misc.copyright()}>{LLMsg("COMMON.FOO.COPYRIGHT")}</a>
                    <a className="m-link" target="_blank" rel="noopener noreferrer"
                       href={Page.internals.urls.misc.disclaimer()}>{LLMsg("COMMON.FOO.DISCLAIMER")}</a>
                    <a className="m-link" target="_blank" rel="noopener noreferrer"
                       href={Page.internals.urls.misc.terms()}>{LLMsg("COMMON.FOO.TERMS")}</a>
                    <a className="m-link" target="_blank" rel="noopener noreferrer"
                       href={Page.externals.scaleContactUs()}>{LLMsg("COMMON.FOO.CONTACT_US")}</a>
                </div>
            </div>
        </div>
    </div>;
};

export default HugeBannerFragment;