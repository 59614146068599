// @flow

import React from 'react';
import Api from "../../bundle/Api";
import swal from "sweetalert2";
import {withRouter} from "react-router";
import type {Match} from "../../App";
import {createAction} from "../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../IntlCapture";
import Image from "../../bundle/Image";
import ErrorUtil from "../../util/ErrorUtil";


type CreateAndLinkTopicToProjectButtonProps = {
    projectId: string,

    dispatchTopicInProjectUpdated?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}

export const createAndLinkTopicToProjectPrompt = (projectId: string, dispatchProjectUpdatedCallback: ?any) => {

    if (!projectId) {
        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        return;
    }

    Api.services.topic.doGetCreateTopicLimit().then((response) => {
        if (response.status === 200) {
            if (response.data.allowCreate) {
                swal({
                    title: LLMsg("COMMON.TOPIC.CREATE_TOPIC"),
                    input: 'text',
                    inputPlaceholder: LLMsg("COMMON.TOPIC.TOPIC_DESC_PLACEHOLDER"),
                    showCancelButton: true,
                    confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
                    cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
                    inputValidator: (value) => {
                        return new Promise((resolve) => {
                            if (value) {
                                resolve()
                            } else {
                                resolve('Topic description cannot empty!');
                            }
                        })
                    }
                }).then((e) => {
                    if (e.dismiss) {
                        return;
                    }
                    if (e.value) {
                        Api.services.project.update.createAndLinkTopicToProject(projectId, e.value).then(response => {
                            if (response.status === 200) {
                                swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), "", "success");
                                dispatchProjectUpdatedCallback && dispatchProjectUpdatedCallback();
                            } else {
                                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                            }
                        }).catch(() => {
                            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                        });

                    } else {
                        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                    }
                });
            } else {
                ErrorUtil.showPermissionNotAllowed();
            }
        }
    }).catch((error =>
            ErrorUtil.promptError(error)
    ));
};

const CreateAndLinkTopicToProjectButton = (props: CreateAndLinkTopicToProjectButtonProps) => {
    let projectId = props.projectId;

    return <div className={"mt-5"}>

        <div className={"btn btn-primary m-btn m-btn--custom"} onClick={() => {
            createAndLinkTopicToProjectPrompt(projectId, props.dispatchTopicInProjectUpdated);
        }}>
            {LLMsg("COMMON.TOPIC.CREATE_TOPIC")}
        </div>
    </div>;
};

const mapDispatchToProps = dispatch => ({
    dispatchTopicInProjectUpdated: () => dispatch(createAction(Api.actions.project.PROJECT_UPDATED))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(CreateAndLinkTopicToProjectButton));