// @flow


import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Image from "../../bundle/Image";

export default class ImageUrlBuilder {
    static getUserProfileImage(): string {
        return Config.IMAGE_MAKER_CLOUD + StringUtil.replaceAllText(Image.paths.USER_PROFILE_IMAGE_URL, []);
    }

    static getDashboardImage(): string {
        return Config.IMAGE_MAKER_CLOUD + StringUtil.replaceAllText(Image.paths.DASHBOARD_IMAGE_URL, []);
    }

    static getHugeBannerBackground(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.HUGE_BANNER_BACKGROUND_URL;
    }

    static getMakerCloudIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.MAKER_CLOUD_ICON_IMAGE_URL;
    }

    static getMakerCloudWhiteIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.MAKER_CLOUD_ICON_IMAGE_WHITE_URL;
    }

    static getMakerCloudWithScaleIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.MAKER_CLOUD_WITH_SCALE_ICON_IMAGE_URL;
    }

    static getGaugeChartImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.GAUGE_CHART_IMAGE_URL;
    }

    static getPlainTextChartImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.PLAIN_TEXT_CHART_IMAGE_URL;
    }

    static getBasicLineChartImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.BASIC_LINE_CHART_IMAGE_URL;
    }

    static getProjectIconImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.PROJECT_ICON_IMAGE_URL;
    }

    static getProjectWallpaperImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.PROJECT_WALLPAPER_IMAGE_URL;
    }

    static getUserJoinedImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.USER_JOINED_IMAGE_URL;
    }

    static getLanguageToggleBackgroundImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.LANGUAGE_TOGGLE_BACKGROUND_IMAGE_URL;
    }

    static getRegisterSchoolImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.REGISTER_SCHOOL_IMAGE_URL;
    }

    static getCheckLicenseImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.CHECK_LICENSE_ICON_URL;
    }

    static getRegisterWithLicenseImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.REGISTER_WITH_LICENSE_URL;
    }

    static getRegisterWithEventCodeImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.REGISTER_WITH_EVENT_CODE_URL;
    }

    static getCreateTopicIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.CREATE_TOPIC_COLOR_ICON_URL;
    }

    static getShareProjectIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.SHARE_PROJECT_COLOR_ICON_URL;
    }

    static getProjectFileIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.SHOW_PROJECT_FILE_COLOR_ICON_URL;
    }

    static getEventTriggerIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.EVENT_TRIGGER_COLOR_ICON_URL;
    }

    static getCreateChartIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.CREATE_CHART_COLOR_ICON_URL;
    }

    static getExportProjectDataIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.EXPORT_PROJECT_DATA_COLOR_ICON_URL;
    }
    static getPencilIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.PENCIL_ICON_URL;
    }

    static getLightBulbOnIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.LIGHTBULB_ON_IMAGE_URL;
    }

    static getLightBulbOffIcon(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.LIGHTBULB_OFF_IMAGE_URL;
    }

    static getPointMapChartImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.POINT_MAP_CHART_IMAGE_URL;
    }

    static getPublishDataImage(): string {
        return Config.IMAGE_MAKER_CLOUD + Image.paths.PUBLISH_DATA_IMAGE_URL;
    }
}
