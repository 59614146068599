// @flow

import React, {Component} from 'react';
import type {ProjectFile} from "../../../../model/model";
import styles from './index.css';
import image from './image.svg';
import {LLMsg} from "../../../../IntlCapture";
import {ClassNames} from "../../../../bundle/ClassNames";
import Api from "../../../../bundle/Api";
import LoadingUtil from "../../../../util/LoadingUtil";
import swal from "sweetalert2";
import ErrorUtil from "../../../../util/ErrorUtil";
import Dropzone from 'react-dropzone-uploader';
import DataStore from "../../../../store/DataStore";
import Constant from "../../../../bundle/Constant";
import ReactTooltip from 'react-tooltip';

type Props = {
    projectFiles: Array<ProjectFile>,
    projectId: number
}
type State = {}

export default class ProjectFileListFragment extends Component<Props, State> {

    renderEmptyRow(key: number) {
        return <tr key={key}>
            <th scope="row">{"-"}</th>
            <td>{"-"}</td>
            <td>{"-"}
            </td>
        </tr>;
    }

    deleteProjectFile = (fileId: string) => {
        LoadingUtil.showFullScreenLoading();
        Api.services.project.file.deleteProjectFile(this.props.projectId, fileId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.REMOVE_SUCCESS"), "", "success").then(() => {
                    this.setState({
                        modalIsOpen: false
                    });
                });
            }
        }).catch((err) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(err);
        });
    };

    // specify upload params and url for your files
    getUploadParams = () => {
        return {
            url: Api.urls.project.file.uploadProjectFile(this.props.projectId),
            method: "POST",
            headers: {
                "Authorization": "Bearer " + DataStore.cookies.get(Constant.cookies.user.accessToken)
            }
        }
    };

    render(): React$Node {

        let emptyRowCount = 5 - this.props.projectFiles.length;
        emptyRowCount = emptyRowCount < 0 ? 0 : emptyRowCount;

        let emptyRow = [];
        for (let i = 0; i < emptyRowCount; i++) {
            emptyRow.push(this.renderEmptyRow(this.props.projectFiles.length + i));
        }
        return <div className="m-portlet">
            <div className="m-portlet__body">
                <div className={styles.title}>
                    {LLMsg("COMMON.PROJECT.FILE_LIST")}

                    <div data-tip={LLMsg("COMMON.PROJECT.EXPLAIN_FILE_LIST")} className={styles.explainButton}><i className={"flaticon-info m--icon-font-size-lg3"}/></div>
                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                </div>
                <div className={styles.tableBox}>
                    <table
                        className={"table table-bordered table-hover  m-table m-table--head-bg-success " + styles.table}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{LLMsg("COMMON.PROJECT.FILE_LIST")}</th>
                            <th style={{width: "180px"}}>{" "}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.projectFiles.map((projectFile: ProjectFile, index: number) => {
                                return <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{projectFile.filename}</td>
                                    <td style={{textAlign: 'right'}}>
                                        <button className={"btn btn-link btn-sm mr-2"} onClick={() => {
                                            Api.services.project.file.downloadProjectFile(this.props.projectId, projectFile.id, projectFile.filename)
                                        }}>
                                            {LLMsg("COMMON.ACTION.SAVE")}
                                        </button>
                                        <button className={"btn btn-link btn-sm"} onClick={() => {
                                            this.deleteProjectFile(projectFile.id)
                                        }}>
                                            {LLMsg("COMMON.ACTION.DELETE")}
                                        </button>
                                    </td>
                                </tr>
                            })
                        }
                        {emptyRow}
                        </tbody>
                    </table>

                    <Dropzone
                        getUploadParams={this.getUploadParams}
                        canCancel={false}
                        canRestart={false}
                        canRemove={false}
                        // accept="image/*,audio/*,video/*"
                        inputContent={(files, extra) => (extra.reject ? 'Not allowed' : LLMsg("COMMON.PROJECT.FILE_UPLOAD_BOX_TEXT"))}
                        styles={{
                            dropzoneReject: {borderColor: 'red', backgroundColor: '#DAA'},
                            dropzone: {overflow: "hidden", minHeight: "100px", width: "100%"},
                            inputLabel: (files, extra) => (extra.reject ? {
                                color: 'red',
                                paddingTop: "6px"
                            } : {
                                color: "black", paddingTop: "6px"
                            }),
                        }}
                    />
                </div>
            </div>

        </div>
    }
}