// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../IntlCapture";
import CheckLicenseStatusButton from "./CheckLicenseStatusButton";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";

class ResourceSelectionFragment extends Component<any, any> {
    render() {
        return <div
            className="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper">
            <div className="m-login__body">
                <div className="m-login__signin">
                    <div className="m-login__title mb-5">
                        <h3>{LLMsg("LOGIN_PAGE.MORE_RESOURCES")}</h3>
                    </div>

                    <CheckLicenseStatusButton/>
                    <hr/>

                    <div className="m-login__action">
                        <Link to={Page.internals.urls.misc.home()} className="m-link">
                            <span>{LLMsg("COMMON.HOME.BACK_TO_HOME")}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ResourceSelectionFragment;