// @flow

import React, {Component} from "react";
import LoadingUtil from "../../../util/LoadingUtil";
import type {Topic} from "../../../model/model";
import ProjectMapFragment from "../../chart/MapFragment/ProjectMapFragment";
import {withRouter} from "react-router";
import Api from "../../../bundle/Api";
import styles from "./index.css"
import Page from "../../../bundle/Page";
import {LLMsg} from "../../../IntlCapture";

type MapEmbeddedFragmentProps = {
    match?: Object
}

type MapEmbeddedFragmentState = {
    topics: Array<Topic>,
    projectId: number
}

class MapEmbeddedFragment extends Component<MapEmbeddedFragmentState, MapEmbeddedFragmentProps> {
    constructor(props) {
        super(props);
        this.state = {
            topics: [],
            projectId: null
        }
        const token = this.props.match && this.props.match.params["token"];
        console.log(token);
        this.getListOfCoordinate(token || "")
    }

    componentDidUpdate() {

        if (document.body) {
            document.body.style.backgroundColor = "#ffffff";
        }
    }


    getListOfCoordinate = (embedMapToken: string) => {
        LoadingUtil.showFullScreenLoading();
        Api.services.project.doGetListOfCoordinate(embedMapToken).then((response) => {
            LoadingUtil.hideFullScreenLoading();

            if (response.status === 200) {
                this.setState({
                    topics: response.data.topics,
                    projectId: response.data.id
                });
            }
        }).catch(() => {
            LoadingUtil.hideFullScreenLoading()
        })
    }

    render() {
        let showProjectMap: boolean = false;
        for (const topic: Topic of this.state.topics) {
            if (topic.latitude && topic.longitude) {
                showProjectMap = true;
                break;
            }
        }
        return <div className={styles.wrapper}>
            {
                showProjectMap &&
                <ProjectMapFragment topics={this.state.topics}
                                    projectId={this.state.projectId}
                                    customHeight={"100%"}/>
            }

            <span className="m-footer__copyright pull-right">
                <a href={Page.internals.urls.misc.home()} className={"m-link " + styles.watermark} target="_blank">
                    {LLMsg("COMMON.FOO.MAKER_CLOUD")}
                </a>
            </span>
        </div>;

    }
}

export default withRouter(MapEmbeddedFragment);