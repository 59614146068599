// @flow

import React, {Component} from 'react';
import ChartEmbeddedFragment from "../chart/ChartEmbeddedFragment";

class ChartEmbeddedPage extends Component<any, any> {
    render() {
        return <ChartEmbeddedFragment/>
    }
}

export default ChartEmbeddedPage;