// @flow

import React from "react";

const BackToTopButton = () => {
    return <div id="m_scroll_top" className="m-scroll-top">
        <i className="la la-arrow-up"/>
    </div>;
};

export default BackToTopButton;