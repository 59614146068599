// @flow

import React, {Component} from 'react';
import OthersLoginFragment from "./OthersLoginFragment";
import Api from "../../../bundle/Api";
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import _ from 'lodash';
import Page from "../../../bundle/Page";
import {LLMsg} from "../../../IntlCapture";
import UrlBuilder from "../../../url/UrlBuilder";
import UserDao from "../../../dao/UserDao";
import HeaderLanguageFragment from "../../header/HeaderLanguageFragment";
import styles from './index.css';
import LoadingUtil from "../../../util/LoadingUtil";
import {Link} from "react-router-dom";
import ErrorUtil from "../../../util/ErrorUtil";
import swal from "sweetalert2";

type LoginFormWrapperProps = {
    location?: Object,
    history?: Object,
    match?: Match
};

class LoginFormWrapper extends Component<LoginFormWrapperProps, any> {

    validateLogin = (username: string, password: string) => {
        if (!username || !password) {
            return false;
        }
        return true;
    };

    login = () => {
        const username = this.usernameInput.value;
        const password = this.passwordInput.value;
        if (!this.validateLogin(username, password)) {
            return;
        }

        LoadingUtil.showFullScreenLoading();
        Api.services.user.loginByToken(username, password).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                let accessToken = response.data.access_token;
                let refreshToken = response.data.refresh_token;
                let expiresIn = response.data.expires_in;
                if (accessToken) {
                    Api.services.user.updateToken(accessToken, refreshToken, expiresIn);


                    Api.services.user.getMe().then((userResponse) => {
                        LoadingUtil.hideFullScreenLoading();
                        if (userResponse.status === 200) {

                            UserDao.updateUser(userResponse);
                            const ref = UrlBuilder.getQueryString()["ref"];
                            if (ref) {
                                this.props.history && this.props.history.push(ref);
                            } else {
                                this.props.history && this.props.history.push(Page.internals.urls.misc.home());
                            }
                        }
                    }).catch((e) => {
                        LoadingUtil.hideFullScreenLoading();
                        ErrorUtil.promptError(e);
                    });
                }
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();

            if (_.get(e, "response.data.error_description", "") === "Bad credentials") {
                swal("", LLMsg("LOGIN_PAGE.WRONG_USERNAME_OR_PASSWORD"), "error");
            }
        });
    };

    usernameInput: any;
    passwordInput: any;


    render() {
        return <div
            className="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper">
            <div className="m-login__head">
                <div className={styles.languageBox}>
                    <HeaderLanguageFragment color={"white"}/>
                </div>
            </div>
            <div className="m-login__body">
                <div className="m-login__signin">
                    <div className="m-login__title">
                        <h3>{LLMsg("LOGIN_PAGE.TITLE")}</h3>
                    </div>
                    <form className="m-login__form m-form">
                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("LOGIN_PAGE.USERNAME")}
                                   name="username" autoComplete="off" ref={(usernameInput) => {
                                this.usernameInput = usernameInput
                            }}/>
                        </div>
                        <div className="form-group m-form__group">
                            <input className="form-control m-input m-login__form-input--last" type="password"

                                   onKeyDown={(event) => {
                                       if (event.key === "Enter") {
                                           this.login();
                                       }
                                   }}
                                   placeholder={LLMsg("LOGIN_PAGE.PASSWORD")} name="password" ref={(passwordInput) => {
                                this.passwordInput = passwordInput
                            }}/>
                        </div>
                    </form>
                    <div className="m-login__action">
                        <Link to={Page.internals.urls.user.resetPassword()} className="m-link">
                            <span>{LLMsg("LOGIN_PAGE.FORGET_PASSWORD")}</span>
                        </Link>
                        <a href="javascript:" onClick={() => {
                            this.login()
                        }}>
                            <button id="m_login_signin_submit"
                                    className="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary">
                                {LLMsg("LOGIN_PAGE.LOGIN")}
                            </button>
                        </a>
                    </div>
                    <div className="m-login__form-divider">
                        <div className="m-divider">
                            <span/>
                            <span>{LLMsg("LOGIN_PAGE.OR")}</span>
                            <span/>
                        </div>
                    </div>
                    <OthersLoginFragment/>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(LoginFormWrapper);
