exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2k0txeB97J3Jjf_wfwTjNF {\n    margin-left: 10px;\n    cursor: pointer;\n    border: solid 1px whitesmoke;\n    display: block;\n    padding: 10px;\n    border-radius: 6px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    background-color: #ebedf2;\n}\n\n._2KBLG09KLXkD6GzhkQdV_I {\n    padding-left: 15px;\n}\n\n._2yCFTuYJedHGZjtwp0hdTz {\n    font-size: 1.5em;\n}\n\n.boeDWWn11fKs5NR2XZygr {\n    color: #575962;\n}", ""]);

// exports
exports.locals = {
	"button": "_2k0txeB97J3Jjf_wfwTjNF",
	"content": "_2KBLG09KLXkD6GzhkQdV_I",
	"title": "_2yCFTuYJedHGZjtwp0hdTz",
	"desc": "boeDWWn11fKs5NR2XZygr"
};