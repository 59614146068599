// @flow

import React from 'react';
import styles from './index.css';
import LicenseTargetTableHeader from "./LicenseTargetTableHeader";
import LicenseTargetTableContentWrapper from "./LicenseTargetTableContentWrapper";
import type {LicenseTarget} from "../../../../model/model";
import Api from "../../../../bundle/Api";
import ErrorUtil from "../../../../util/ErrorUtil";

type LicenseTargetListFragmentState = {
    licenseTargets: Array<LicenseTarget>
}

class LicenseTargetListFragment extends React.Component <any, LicenseTargetListFragmentState> {


    state = {licenseTargets: []};

    componentDidMount() {
        this.getLicenseTargets();
    }

    getLicenseTargets() {
        Api.services.license.getLicenseTargetList().then((response) => {
            if (response.status === 200) {
                let licenseTargets: Array<LicenseTarget> = response.data;
                this.setState({licenseTargets});
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
        });
    }


    render() {
        return <div>
            <hr/>
            <div className={styles.title}>License Target</div>
            <div className={styles.subTitle}>The Package included Maker Cloud Trial License Key</div>
            <div className={"mb-3 mt-3 p-3 " + styles.tableWrapper}>

                <div className="m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded"
                     id="mytable">
                    <table className="m-datatable__table"
                           style={{display: 'block', minHeight: '300px', overflowX: 'auto'}}>

                        <LicenseTargetTableHeader/>
                        <LicenseTargetTableContentWrapper licenseTargets={this.state.licenseTargets}/>
                    </table>
                </div>
            </div>
        </div>;
    }
}

export default LicenseTargetListFragment;