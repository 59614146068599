// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../IntlCapture";
import styles from './CheckLicenseStatusButton.css';
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import Image from "../../../bundle/Image";

class CheckLicenseStatusButton extends Component<any, any> {

    render() {
        return <Link
            to={Page.internals.urls.license.licenseDetailQueryOage()}
            className={styles.button + " no-a-effect"}>

            <div className="m-stack m-stack--ver m-stack--general ">
                <div className="m-stack__item m-stack__item--center m-stack__item--middle" style={{width: '100px'}}>
                    <img src={Image.urls.getCheckLicenseImage()} width={75}/>
                </div>
                <div className={"m-stack__item m-stack__item--middle " + styles.content}>
                    <div className={styles.title}>
                        {LLMsg("MY_SUB.VIEW_LICENSE_DETAIL_BUTTON")}
                    </div>
                    <div className={styles.desc}>
                        {/*{LLMsg("REGISTER_PAGE.REGISTER_FOR_SCHOOL_DESCRIPTION")}*/}
                    </div>
                </div>
            </div>


        </Link>
    }

}

export default CheckLicenseStatusButton;