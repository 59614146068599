// @flow


import ImageUrlBuilder from "../url/image/ImageUrlBuilder";
import ImageUrl from "../url/image/ImageUrl";

class Image {

    static paths = ImageUrl;
    static urls = ImageUrlBuilder;
}

export default Image;