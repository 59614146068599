// @flow

import React from 'react';
import type {Topic} from "../../../../model/model";
import {LLMsg} from "../../../../IntlCapture";
import {renderToStaticMarkup} from "react-dom/server";
import SocketIOService from "../../../../service/SocketIOService";
import Constant from "../../../../bundle/Constant";
import styles from "../PointMapFragment/index.css";

type ProjectMapFragmentProps = {
    topics: Array<Topic>,
    projectId: number,
    customHeight?: string
}

export function TopicInfoBox(topic: Topic, message: string) {
    let contentArr = SocketIOService.extractMqttDataMessage(message);
    let desc = topic.description ? " (" + topic.description + ")" : "";
    return <div>
        <div>{LLMsg("COMMON.TOPIC.TOPIC") + ": " + topic.topicName + desc}</div>
        <div>{LLMsg("COMMON.PROJECT.DATA_VIEWER") + ": "}</div>
        {
            contentArr.map((content, index) => {
                return <div key={index}>
                    {
                        content.key && <span>{content.key}={content.value}</span>
                    }
                    {
                        !content.key && <span>{content.value}</span>
                    }
                </div>;
            })
        }
    </div>;
}

type State = {
    hasError: boolean
}

class ProjectMapFragment extends React.Component <ProjectMapFragmentProps, State> {

    map: any;
    marker: any;
    mapRef: any;
    topicContentMap: { [topic: string]: string } = {};
    topicInfoWindowMap: { [topic: string]: any } = {};
    topicMarkerMap: { [topic: string]: any } = {};
    mapBounds: any;

    constructor(props) {
        super(props);
        this.state = {hasError: false};
        try {
            new window.google.maps.LatLng(0, 0);
        } catch (error) {
            this.state = {hasError: true};
        }
    }

    componentWillUnmount() {
        let socket: any = SocketIOService.getSocket();
        socket.emit(Constant.socket.data.room.leave, "P" + this.props.projectId);
    }

    setupSocketEvent() {

        let socket: any = SocketIOService.getSocket();
        socket.emit(Constant.socket.data.room.join, "P" + this.props.projectId);
        socket.on(Constant.socket.data.room.mqttPlainMessage, (data) => {

            let dataObj = SocketIOService.extractMqttRawDataMessage(data);
            let topicName = dataObj.topicName;
            let content = dataObj.message;

            this.topicContentMap[topicName] = content;

            for (let i = 0; i < this.props.topics.length; i++) {
                if (this.props.topics[i].topicName === topicName) {
                    let topic: Topic = this.props.topics[i];
                    let contentArr = SocketIOService.extractMqttDataMessage(content);
                    let newLat = undefined, newLng = undefined;

                    contentArr.forEach((item: { key: string, value: string }) => {
                        if (item.key === "_lat") {
                            newLat = item.value;
                        } else if (item.key === "_lng") {
                            newLng = item.value;
                        }
                    })

                    if (this.topicInfoWindowMap[topicName]) {
                        this.topicInfoWindowMap[topicName].setContent(renderToStaticMarkup(TopicInfoBox(topic, content)));
                        // it had lat lng
                        if (newLat && newLng) {
                            if (topic.latitude !== newLat || topic.longitude !== newLng) {
                                // lat lng updated
                                this.topicMarkerMap[topic.topicName].setPosition(
                                    new window.google.maps.LatLng(newLat, newLng)
                                );
                            }
                        }

                    } else {
                        let marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(newLat, newLng),
                            map: this.map
                        });
                        this.topicMarkerMap[topic.topicName] = marker;

                        let infowindow = new window.google.maps.InfoWindow();
                        infowindow.setContent(renderToStaticMarkup(TopicInfoBox(topic, content)));
                        infowindow.open(this.map, marker);
                        this.topicInfoWindowMap[topic.topicName] = infowindow;

                        this.mapBounds.extend(marker.position);
                        this.map.fitBounds(this.mapBounds);
                    }

                    break;
                }
            }
        });

    }

    componentDidMount() {

        if (this.state.hasError) {
            return
        }

        this.mapBounds = new window.google.maps.LatLngBounds();

        this.map = new window.google.maps.Map(this.mapRef, {
            zoom: 14,
            streetViewControl: false,
            mapTypeControl: false
        });

        for (let i = 0; i < this.props.topics.length; i++) {
            let topic: Topic = this.props.topics[i];
            if (topic.latitude && topic.longitude) {

                let infowindow = new window.google.maps.InfoWindow();

                let marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(topic.latitude, topic.longitude),
                    map: this.map
                });
                this.topicMarkerMap[topic.topicName] = marker;

                //extend the bounds to include each marker's position
                this.mapBounds.extend(marker.position);

                window.google.maps.event.addListener(marker, 'click', ((marker, i) => {
                    return () => {
                        infowindow.setContent(renderToStaticMarkup(TopicInfoBox(topic, this.topicContentMap[topic.topicName])));
                        infowindow.open(this.map, marker);
                    }
                })(marker, i));

                infowindow.setContent(renderToStaticMarkup(TopicInfoBox(topic, this.topicContentMap[topic.topicName])));
                infowindow.open(this.map, marker);
                this.topicInfoWindowMap[topic.topicName] = infowindow;
            }
        }

        //now fit the map to the newly inclusive bounds
        this.map.fitBounds(this.mapBounds);

        //(optional) restore the zoom level after the map is done scaling
        let listener = window.google.maps.event.addListener(this.map, "idle", () => {
            this.map.setZoom(this.map.getZoom() - 1);
            window.google.maps.event.removeListener(listener);
        });

        this.setupSocketEvent();
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div className={styles.errorMsg}>
                {LLMsg('COMMON.CHART.NOT_AVAILABLE_GOOGLE_MAP')}
            </div>;
        }

        return <div ref={(ref) => {
            this.mapRef = ref;
        }} style={{
            height: this.props.customHeight || "400px",
            width: "100%"
        }}/>;
    }
}

export default ProjectMapFragment;