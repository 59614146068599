// @flow

import React, {Component} from 'react';
import HeaderFragment from "../../header/HeaderFragment";
import BodyWrapper from "../../body/BodyWrapper";
import SidebarWrapper from "../../sidebar/SidebarWrapper";
import GeneralSidebarFragment from "../../sidebar/GeneralSidebarFragment";
import ContentWrapper from "../../content/ContentWrapper";
import ContentHeaderFragment from "../../content/ContentHeaderFragment";
import FooterFragment from "../../footer/FooterFragment";
import BackToTopButton from "../../BackToTopButton";
import StickyQuickNav from "../../SickyQuickNav";
import ProjectCreateFragment from "../../project/ProjectCreateFragment/index";
import ContentInnerWrapper from "../../content/ContentInnerWrapper";
import Breadcrumb from "../../content/Breadcrumb";
import Page from "../../../bundle/Page";
import {LLMsg} from "../../../IntlCapture";

class ProjectCreatePage extends Component<any, any> {

    render() {
        Page.updateTitle("PAGE.TITLE.PROJECT_CREATE");
        return <div className="m-grid m-grid--hor m-grid--root m-page">
            <HeaderFragment/>
            <BodyWrapper>
                <SidebarWrapper>
                    <GeneralSidebarFragment/>
                </SidebarWrapper>
                <ContentWrapper>
                    <ContentHeaderFragment>
                        <Breadcrumb items={[
                            {linkTo: Page.internals.urls.project.list(), displayText: LLMsg("COMMON.PROJECT.PROJECT_LIST")},
                            {displayText: LLMsg("COMMON.PROJECT.CREATE_PROJECT")}
                        ]}/>
                    </ContentHeaderFragment>
                    <ContentInnerWrapper>
                        <ProjectCreateFragment/>
                    </ContentInnerWrapper>
                </ContentWrapper>
            </BodyWrapper>

            <FooterFragment/>
            <BackToTopButton/>
            <StickyQuickNav/>
        </div>
    }
}

export default ProjectCreatePage;