// @flow

import React, {Component} from 'react';
import Constant from "../../bundle/Constant";
import Api from "../../bundle/Api";
import {LLMsg} from "../../IntlCapture";
import TopicMessageSendingToolFragment from "./TopicMessageSendingToolFragment";

type TalkToTopicFragmentProps = {
    topicId: string
}


class TalkToTopicFragment extends Component<TalkToTopicFragmentProps, any> {

    messageInputRef: any;

    constructor(props: TalkToTopicFragmentProps) {
        super(props);
        this.messageInputRef = React.createRef();
    }

    sendMessageToTopic() {

        let message = this.messageInputRef.value;
        if (!message) {
            window.toastr.options = Constant.toastr.defaultOptions;
            window.toastr.error(LLMsg("COMMON.TOPIC.TOPIC_MESSAGE_EMPTY"));

            return;
        }
        Api.services.topic.doSendNewMessageToTopic(this.props.topicId, "", message).then((response) => {

            if (response.status === 200) {
                this.messageInputRef.value = "";
                window.toastr.options = Constant.toastr.defaultOptions;
                window.toastr.success(LLMsg("COMMON.TOPIC.TOPIC_MESSAGE_SENT"));
            } else {

                window.toastr.options = Constant.toastr.defaultOptions;
                window.toastr.error(LLMsg("COMMON.ACTION.ACTION_FAILED"));
            }
        }).catch(() => {
            window.toastr.options = Constant.toastr.defaultOptions;
            window.toastr.error(LLMsg("COMMON.ACTION.ACTION_FAILED"));
        });
    }

    render() {
        return <div className={Constant.portlet.size.colWidth.w6}>
            <div className="m-portlet mb-0">
                <div className="m-portlet__body">
                    <div className={"portlet_height_1x"}>
                        <div className="m-widget12">
                            <div className="m-widget12__item mb-0">
                            <span className="m-widget12__text2 m--icon-font-size-lg3">
                                  <div className={"mb-3"}>
                                      <span>
                                          {LLMsg("COMMON.TOPIC.TALK_TO_TOPIC")}
                                      </span>
                                      <i className="flaticon-chat-1 m--icon-font-size-lg4 ml-3"/>
                                  </div>

                                  <textarea className="form-control m-input" rows={5}
                                            placeholder={LLMsg("COMMON.TOPIC.TOPIC_MESSAGE_PLACEHOLDER")}
                                            defaultValue={""} style={{fontSize: "18px"}}
                                            ref={(messageInputRef) => {
                                                this.messageInputRef = messageInputRef
                                            }}/>
                                  <div className="text-right mt-3 mb-2">
                                      <div style={{display: 'inline-block'}} className={"pull-right"}>
                                          <button
                                              onClick={(e) => {
                                                  this.sendMessageToTopic();
                                              }}
                                              className="btn m-btn--square  btn-outline-accent m-btn m-btn--custom pull-right">
                                              <i className="fa fa-location-arrow mr-2"/>
                                              {LLMsg("COMMON.ACTION.SEND")}
                                          </button>
                                      </div>
                                      <div style={{display: 'inline-block'}}>
                                        <TopicMessageSendingToolFragment/>
                                      </div>
                                  </div>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
};

export default TalkToTopicFragment;