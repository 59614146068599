// @flow

import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

let injectedIntl;

export const LLMsg = (id: string, data?: Object) => {
    return !id ? "" : injectedIntl.formatMessage({id: id}, data);
};

class IntlCapture extends Component<any, any> {

    componentWillMount() {
        injectedIntl = this.props.intl;
    }

    render() {
        return this.props.children;
    }
}

export default injectIntl(IntlCapture);
