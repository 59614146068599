// @flow

import React from 'react';
import LoadingUtil from "../../../../util/LoadingUtil";
import ErrorUtil from "../../../../util/ErrorUtil";
import Api from "../../../../bundle/Api";
import {withRouter} from "react-router";
import _ from "lodash";
import TimeUtil from "../../../../util/TimeUtil";
import styles from "./index.css";
import type {Event} from "../../../../model/model";
import Constant from "../../../../bundle/Constant";
import {LLMsg} from "../../../../IntlCapture";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";
import moment from "moment";


class EventCodeCreateFragment extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    constructEditItem(title: any, content: any, updateCallback: Function) {
        return <div className={styles.row}>
            <div className={styles.title}>
                {title}
            </div>
            <div
                className={styles.content}>
                <input
                    className={"form-control m-input"}
                    type="text"
                    defaultValue={content}
                    onChange={(event) => {
                        updateCallback(event.target.value);
                    }}
                />
            </div>
        </div>;
    }

    create = () => {

        let startTime = TimeUtil.convertTimeFormat(this.startTime, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        let endUntil = TimeUtil.convertTimeFormat(this.endUntil, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        let regEndDate = TimeUtil.convertTimeFormat(this.regEndDate, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);

        LoadingUtil.showFullScreenLoading();
        Api.services.eventCode.createEventCode(
            this.eventCode, this.eventName, startTime, endUntil, regEndDate
        ).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                window.toastr.options = Constant.toastr.defaultOptions;
                window.toastr.success("Updated!");
                RouterHistory().push(Page.internals.urls.eventCode.admin.list());
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    };

    eventCode: string = "";
    eventName: string = "";
    startTime: string = "";
    endUntil: string = "";
    regEndDate: string = "";

    render() {

        this.startTime = TimeUtil.convertTimeFormat(moment().hour(0).minute(0).second(0), TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        this.endUntil = TimeUtil.convertTimeFormat(moment().hour(23).minute(59).second(59), TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        this.regEndDate = TimeUtil.convertTimeFormat(moment().hour(23).minute(59).second(59), TimeUtil.FULL_DATE_DISPLAY_FORMAT);

        return <div className="m-portlet">
            <div className="m-portlet__body">
                <div className="m-section">
                    {
                        this.constructEditItem("Event Code", "", (value: string) => {
                            this.eventCode = value
                        })
                    }
                    {
                        this.constructEditItem("Event Name", "", (value: string) => {
                            this.eventName = value
                        })
                    }
                    {
                        this.constructEditItem("Event Start Time", this.startTime, (value: string) => {
                            console.log("updatedd={}", value);
                            this.startTime = value
                        })
                    }
                    {
                        this.constructEditItem("Event End Until", this.endUntil, (value: string) => {
                            this.endUntil = value
                        })
                    }
                    {
                        this.constructEditItem("User Registration End Date", this.regEndDate, (value: string) => {
                            this.regEndDate = value
                        })
                    }
                </div>

                <div>
                    {
                        <button type="button" className="btn btn-success" onClick={() => {
                            this.create();
                        }}>
                            Create
                        </button>
                    }
                    {
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            RouterHistory().push(Page.internals.urls.eventCode.admin.list());
                        }}>
                            Cancel
                        </button>
                    }
                </div>
            </div>
        </div>;
    }
}

export default EventCodeCreateFragment;