// @flow

import React from 'react';
import {ClassNames} from "../../../bundle/ClassNames";
import styles from './index.css';

type Props = {
    style: number,
    title: string,
    desc: string,
    isFixedWidth?: boolean,
    className?: string
}

export default class Stats extends React.Component<Props, any> {
    render() {
        return <div className={ClassNames(styles.statsBox, {
            [styles.border1]: this.props.style === 1,
            [styles.border2]: this.props.style === 2,
            [styles.border3]: this.props.style === 3,
            [styles.border4]: this.props.style === 4,
            [styles.border5]: this.props.style === 5,
            [styles.fixedWidth]: this.props.isFixedWidth,
            [this.props.className]: this.props.className,
            [styles.styles6]: this.props.style === 6
        })}>
            {/*return <div className={ClassNames(styles.statsBox)}>*/}
            <div className={styles.statsDesc}>{this.props.desc}</div>
            <div className={styles.statsTitle}>
                {this.props.title}
            </div>
        </div>
    }
}