exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Z27xUtgEp_eSBGlusRsWq {\n}\n\n._3qXvXupHp2WC_deD10to7K {\n    text-align: center;\n}\n\n._1GUd4wvy8DV8u18Q6GzkmT {\n    margin-bottom: 20px;\n}\n\n._1aNti8X6qdCSJ6bBls8Yxj {\n    font-weight: bold;\n    font-size: 1.2em;\n    margin-top: 25px;\n    margin-bottom: 25px;\n}\n\n._3UJxHeHfNBYL-riIpIJcPl {\n    margin-top: 25px;\n    margin-bottom: 25px;\n    font-size: 0.9em;\n\n}\n\n._2C-U0RKBfAb5poumz_0aql {\n    float: right;\n}\n\n.oQIBjLfW7rJMZvBHueSvq {\n    float: right;\n    color: darkgrey;\n}\n", ""]);

// exports
exports.locals = {
	"box": "_2Z27xUtgEp_eSBGlusRsWq",
	"imageBox": "_3qXvXupHp2WC_deD10to7K",
	"titleBox": "_1GUd4wvy8DV8u18Q6GzkmT",
	"title": "_1aNti8X6qdCSJ6bBls8Yxj",
	"desc": "_3UJxHeHfNBYL-riIpIJcPl",
	"submitBtn": "_2C-U0RKBfAb5poumz_0aql",
	"checkBtn": "oQIBjLfW7rJMZvBHueSvq"
};