const messages = {

    /* PAGE_TITLE_01 */ 'PAGE.TITLE.HOME': 'Home',
    /* PAGE_TITLE_02 */ 'PAGE.TITLE.PRIVACY': 'Privacy Policy',
    /* PAGE_TITLE_03 */ 'PAGE.TITLE.LEGAL': 'Terms and Conditions',
    /* PAGE_TITLE_04 */ 'PAGE.TITLE.CONTACT_US': 'Contact Us',
    /* PAGE_TITLE_05 */ 'PAGE.TITLE.LOGIN': 'Please login first',
    /* PAGE_TITLE_06 */ 'PAGE.TITLE.PROJECT_CREATE': 'Create new project',
    /* PAGE_TITLE_07 */ 'PAGE.TITLE.PROJECT_LISTING': 'List of Projects',
    /* PAGE_TITLE_08 */ 'PAGE.TITLE.PROJECT_DETAIL': '{PROJECT_NAME} - Project Detail',
    /* PAGE_TITLE_09 */ 'PAGE.TITLE.TOPIC_CREATE': 'Create Topic',
    /* PAGE_TITLE_10 */ 'PAGE.TITLE.TOPIC_LISTING': 'List of Topics',
    /* PAGE_TITLE_11 */ 'PAGE.TITLE.TOPIC_DETAIL': '{TOPIC_NAME} - Topic Detail',
    /* PAGE_TITLE_12 */ 'PAGE.TITLE.DASHBOARD': '{DASHBOARD_NAME} - Dashboard',
    /* PAGE_TITLE_13 */ 'PAGE.TITLE.CHART_DETAIL': '{CHART_NAME} - Chart',
    /* PAGE_TITLE_14 */ 'PAGE.TITLE.CHART_CREATE': 'Create Chart',
    /* PAGE_TITLE_15 */ 'PAGE.TITLE.POSTFIX': ' - Maker Cloud - IOT Platform for STEM  Education',
    /* PAGE_TITLE_16 */ 'PAGE.TITLE.PAGE_NOT_FOUND': 'Page not found',
    /* PAGE_TITLE_17 */ 'PAGE.TITLE.LOGOUT': 'Logout',
    /* PAGE_TITLE_18 */ 'PAGE.TITLE.CHANGE_PASSWORD': 'Change Password',
    /* PAGE_TITLE_19 */ 'PAGE.TITLE.MY_SUBSCRIPTION': 'My Subscription',
    /* PAGE_TITLE_20 */ 'PAGE.TITLE.SETUP_PASSWORD': 'Setup Password',
    /* PAGE_TITLE_21 */ 'PAGE.TITLE.REGISTRATION_SELECTION': 'Register Account',
    /* PAGE_TITLE_22 */ 'PAGE.TITLE.REGISTER_FOR_SCHOOL': 'Register School Account',
    /* PAGE_TITLE_23 */ 'PAGE.TITLE.REGISTER_WITH_LICENSE_KEY': 'Register with License Key',
    /* PAGE_TITLE_24 */ 'PAGE.TITLE.REGISTER_WITH_EVENT_CODE': 'Register with Event Code',
    /* PAGE_TITLE_25 */ 'PAGE.TITLE.RESET_PASSWORD': 'Reset Password',
    /* PAGE_TITLE_26 */ 'PAGE.TITLE.VIRTUAL_LIGHT_BULB': 'Virtual Light Bulb',
    /* PAGE_TITLE_27 */ 'PAGE.TITLE.WHATS_NEW': 'What\'s New',

    /* LOG_01 */        'LOGIN_PAGE.TITLE': 'Welcome, please login!',
    /* LOG_02 */        'LOGIN_PAGE.PRIVACY': 'Privacy',
    /* LOG_03 */        'LOGIN_PAGE.LEGAL': 'Legal',
    /* LOG_04 */        'LOGIN_PAGE.CONTACT': 'Contact',
    /* LOG_05 */        'LOGIN_PAGE.DESC': 'Smart Campus Active Learning Environment',
    /* LOG_06 */        'LOGIN_PAGE.SUB_DESC': 'Of, By And For Tomorrow\'s Smart Citizen',
    /* LOG_07 */        'LOGIN_PAGE.COPYRIGHT': 'Maker Cloud',
    /* LOG_08 */        'LOGIN_PAGE.NO_ACCOUNT': 'Don\'t have an account?',
    /* LOG_09 */        'LOGIN_PAGE.REGISTER': 'Sign Up',
    /* LOG_10 */        'LOGIN_PAGE.USERNAME': 'Email',
    /* LOG_11 */        'LOGIN_PAGE.PASSWORD': 'Password',
    /* LOG_12 */        'LOGIN_PAGE.FORGET_PASSWORD': 'Forgot Password ?',
    /* LOG_13 */        'LOGIN_PAGE.LOGIN': 'Sign In',
    /* LOG_14 */        'LOGIN_PAGE.OR': 'OR',
    /* LOG_15 */        'LOGIN_PAGE.GOOGLE': 'Google',
    /* LOG_16 */        'LOGIN_PAGE.GUEST_LOGIN': 'Signup with Event Code',
    /* LOG_17 */        'LOGIN_PAGE.SCHOOL_SIGN_UP': 'School Signup',
    /* LOG_18 */        'LOGIN_PAGE.WRONG_USERNAME_OR_PASSWORD': 'Wrong username or password. If you forget password, please click "Forget Password" link to reset your password',
    /* LOG_19 */        'LOGIN_PAGE.MORE_RESOURCES': 'Other Resources',

    /* REG_01 */        'REGISTER_PAGE.REGISTER_ACCOUNT': 'Register an account',
    /* REG_02 */        'REGISTER_PAGE.REGISTER_GUEST_ACCOUNT': 'Register as Guest',
    /* REG_03 */        'REGISTER_PAGE.BACK_TO_LOGIN_PAGE': 'Already registered an account? Login with your account now!',
    /* REG_04 */        'REGISTER_PAGE.GUEST_EXPIRY_NOTICE': 'The guest account will expiry in 7 days once you registered. Please contact us if you have any problems',
    /* REG_05 */        'REGISTER_PAGE.GUEST_USERNAME': 'Email',
    /* REG_06 */        'REGISTER_PAGE.CONFIRM_REGISTER_GUEST': 'Create guest',
    /* REG_07 */        'REGISTER_PAGE.INVALID_GUEST_USERNAME': 'Please provide a valid email',
    /* REG_08 */        'REGISTER_PAGE.EVENT_CODE_LABEL': 'Event Code',
    /* REG_09 */        'REGISTER_PAGE.EVENT_CODE_INVALID': 'Incorrect Event Code',
    /* REG_10 */        'REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT': 'Register for school',
    /* REG_11 */        'REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT_NOTICE': 'Once the application is submitted, we will process your request as soon as possible. You will receive a confirmation email when it is approved. We may contact you to verify the information.',
    /* REG_12 */        'REGISTER_PAGE.TEACHER_NAME': 'Teacher Name',
    /* REG_13 */        'REGISTER_PAGE.TEACHER_ROLE': 'Teaching subject / responsible project',
    /* REG_14 */        'REGISTER_PAGE.TEACHER_TEL': 'Office Tel/ Mobile Tel',
    /* REG_15 */        'REGISTER_PAGE.PERSONAL_INFO_LABEL': 'Personal Information',
    /* REG_16 */        'REGISTER_PAGE.EVENT_INFO_LABEL': 'Event Information',
    /* REG_17 */        'REGISTER_PAGE.NO_EVENT_CODE': 'If you do not have a trial code, please contact support@scaleinnnotech.com to apply for a trial code.',
    /* REG_18 */        'REGISTER_PAGE.TEACHER_ROLE_REMARKS': 'Remarks',
    /* REG_19 */        'REGISTER_PAGE.TEACHER_NAME_INVALID': 'Invalid Teacher Name',
    /* REG_20 */        'REGISTER_PAGE.TEACHER_TEL_INVALID': 'Please provide an valid phone number',
    /* REG_21 */        'REGISTER_PAGE.TEACHER_ROLE_INVALID': 'Please select an item',
    /* REG_22 */        'REGISTER_PAGE.TEACHER_ROLE_REMARKS_INVALID': 'Please fill in the remarks',
    /* REG_23 */        'REGISTER_PAGE.SCHOOL_SIGN_UP_SUCCESS': 'Your application has been submitted',
    /* REG_24 */        'REGISTER_PAGE.SCHOOL_NAME_LABEL': 'School full name',
    /* REG_25 */        'REGISTER_PAGE.SCHOOL_NAME_INVALID': 'Please provide school full name',
    /* REG_26 */        'REGISTER_PAGE.REGISTER_WITH_LICENSE': 'Register with license',
    /* REG_27 */        'REGISTER_PAGE.REGISTER_WITH_LICENSE_DESCRIPTION': 'It\'s good to pair up Maker Cloud with your IOT hardware to power up the whole experience. Go and get some Maker Cloud verified package.',
    /* REG_28 */        'REGISTER_PAGE.REGISTER_FOR_SCHOOL_DESCRIPTION': 'Register for your student now to enjoy the most premium features to power up your stem class!',
    /* REG_29 */        'REGISTER_PAGE.REGISTER_WITH_EVENT_CODE_DESCRIPTION': 'Register as a guest now to have a 7-day trial on Maker Cloud without any long-term commitment.',
    /* REG_30 */        'REGISTER_PAGE.LICENSE_KEY_INVALID': 'License Key is invalid',

    /* PW_01 */         'PASSWORD.RESET_PASSWORD_TITLE': 'Reset your password',
    /* PW_02 */         'PASSWORD.RESET_PASSWORD_NOTICE': 'After submitting your application, a link to reset your password will be sent to your email. Please be patient. If you have not received the email, please make sure the email address is correct and check the spam.',
    /* PW_03 */         'PASSWORD.RESET_PASSWORD_COOL_DOWN': 'You have already submitted a password reset request. Please submit your new application after one minute.',
    /* PW_04 */         'PASSWORD.RESET_PASSWORD_RESET_BUTTON': 'Reset',
    /* PW_05 */         'PASSWORD.RESET_PASSWORD_EMAIL_NOT_EXIST': 'Email does not exist',
    /* PW_06 */         'PASSWORD.OLD_PASSWORD': 'Old Password',
    /* PW_07 */         'PASSWORD.NEW_PASSWORD': 'New Password',
    /* PW_08 */         'PASSWORD.NEW_PASSWORD_AGAIN': 'Enter Again',
    /* PW_09 */         'PASSWORD.PASSWORD_LENGTH_HINTS': 'The password length should be in between 6 and 20',
    /* PW_10 */         'PASSWORD.SKIP_SETUP_PASSWORD': 'Skip password setup for now',
    /* PW_11 */         'PASSWORD.CHANGE_PASSWORD_BUTTON': 'The current password is incorrect',
    /* PW_12 */         'PASSWORD.CURRENT_PASSWORD_INCORRECT': 'Change Password',
    /* PW_13 */         'PASSWORD.CHANGE_PASSWORD_TITLE': 'Change Password',
    /* PW_14 */         'PASSWORD.NEW_PASSWORD_NOT_MATCH': 'The password does not match',
    /* PW_15 */         'PASSWORD.SETUP_PASSWORD_TITLE': 'Setup Password',
    /* PW_16 */         'PASSWORD.SETUP_PASSWORD_NOTICE': 'For Google Signin User, you are not required to setup a password. If you are using other authentication method, please setup a password for future login.',
    /* PW_17 */         'PASSWORD.SETUP_PASSWORD_BUTTON': 'Setup Password',
    /* PW_18 */         'PASSWORD.SETUP_PASSWORD_PASSWORD_EXISTED': 'You have previously created a password. Please go to Change Password Page',

    /* HEA_LANG_01 */   'HEADER.SELECT_LANGUAGE': 'Select Your Language',
    /* HEA_LANG_02 */   'HEADER.LANGUAGE_TC': '繁體中文',
    /* HEA_LANG_03 */   'HEADER.LANGUAGE_SC': '简体中文',
    /* HEA_LANG_04 */   'HEADER.LANGUAGE_EN': 'English',

    /* HEA_USER_01 */   'HEADER.USER.FAQ': 'FAQ',
    /* HEA_USER_02 */   'HEADER.USER.SUPPORT': 'Support',
    /* HEA_USER_03 */   'HEADER.USER.LOGOUT': 'Sign Out',

    /* FOO_01 */        'COMMON.FOO.SCALE': 'Maker Cloud - SCALE InnoTech Limited',
    /* FOO_02 */        'COMMON.FOO.CONTACT_US': 'Contact us',
    /* FOO_03 */        'COMMON.FOO.PRIVACY': 'Privacy',
    /* FOO_04 */        'COMMON.FOO.COPYRIGHT': 'Copyright',
    /* FOO_05 */        'COMMON.FOO.DISCLAIMER': 'Disclaimer',
    /* FOO_06 */        'COMMON.FOO.TERMS': 'Terms',
    /* FOO_07 */        'COMMON.FOO.MAKER_CLOUD': 'Maker Cloud',
    /* FOO_08 */        'COMMON.FOO.THIRD_PARTY': 'Third Library Use',

    /* TT_PROJ_01 */    'COMMON.PROJECT.PROJECT': 'Project',
    /* TT_PROJ_02 */    'COMMON.PROJECT.PROJECT_NAME': 'Project Name',
    /* TT_PROJ_03 */    'COMMON.PROJECT.CREATE_PROJECT': 'Create Project',
    /* TT_PROJ_04 */    'COMMON.PROJECT.UPDATE_PROJECT': 'Update Project',
    /* TT_PROJ_05 */    'COMMON.PROJECT.DELETE_PROJECT': 'Delete Project',
    /* TT_PROJ_06 */    'COMMON.PROJECT.SHARE_PROJECT': 'Share Project',
    /* TT_PROJ_07 */    'COMMON.PROJECT.ADD_TOPIC': 'Add Topic to Project',
    /* TT_PROJ_08 */    'COMMON.PROJECT.REMOVE_TOPIC': 'Remove Topic From Project',
    /* TT_PROJ_09 */    'COMMON.PROJECT.TITLE_LABEL': 'Choose Project Type:',
    /* TT_PROJ_10 */    'COMMON.PROJECT.TYPE.IOT.NAME': 'IOT Project',
    /* TT_PROJ_11 */    'COMMON.PROJECT.TYPE.IOT.DESC': 'Connect your Micro-bit to MakerCloud, enjoy many features including data visualization and dashboard presentation',
    /* TT_PROJ_12 */    'COMMON.PROJECT.TYPE.LBS.NAME': 'Location Base Service Project (LBS)',
    /* TT_PROJ_13 */    'COMMON.PROJECT.TYPE.LBS.DESC': 'Make your Micro-bit become an Ibeacon or Eddystone, track your current location, define walkable node to give you the shortest path to a destination. Coming soon.."',
    /* TT_PROJ_14 */    'COMMON.PROJECT.TYPE.MLP.NAME': 'Machine Learning Project',
    /* TT_PROJ_15 */    'COMMON.PROJECT.TYPE.MLP.DESC': 'Coming soon...',
    /* TT_PROJ_16 */    'COMMON.PROJECT.POWERED_BY_QBS': 'powered by QBS',
    /* TT_PROJ_17 */    'COMMON.PROJECT.PROJECT_NAME_PLACEHOLDER': 'New Project Name',
    /* TT_PROJ_18 */    'COMMON.PROJECT.CREATE_SUCCESS': 'Your project has been created.',
    /* TT_PROJ_19 */    'COMMON.PROJECT.MISSING_PROJECT_NAME': 'Project Name is missing',
    /* TT_PROJ_20 */    'COMMON.PROJECT.UPDATE_SUCCESS': 'Your project has been updated.',
    /* TT_PROJ_21 */    'COMMON.PROJECT.PROJECT_LIST': 'List of Projects',
    /* TT_PROJ_22 */    'COMMON.PROJECT.REMOVE_TOPIC_TITLE': 'Remove topic from project',
    /* TT_PROJ_23 */    'COMMON.PROJECT.REMOVE_TOPIC_DESC': 'This will not delete the topic, you may add it back to the project later!',
    /* TT_PROJ_24 */    'COMMON.PROJECT.SHOW_BRIEF_INFO_TAB': 'Project details',
    /* TT_PROJ_25 */    'COMMON.PROJECT.SHOW_TOPICS_UNDER_PROJECT_TAB': 'Related Topics',
    /* TT_PROJ_26 */    'COMMON.PROJECT.SHOW_CHART_UNDER_PROJECT_TAB': 'Generated Charts',
    /* TT_PROJ_27 */    'COMMON.PROJECT.DOWNLOAD_DATA_UNDER_PROJECT_TAB': 'Download All Data',
    /* TT_PROJ_28 */    'COMMON.PROJECT.EVENT_TRIGGER': 'Data Trigger',
    /* TT_PROJ_29 */    'COMMON.PROJECT.QRCODE_DESC': 'Scan the QR Code to join the project or share the link to others to join the project',
    /* TT_PROJ_30 */    'COMMON.PROJECT.ALREADY_JOINED_PROJECT': 'Your have already joined the project ',
    /* TT_PROJ_31 */    'COMMON.PROJECT.VIEW_PROJECT_LABEL': 'Do you want to view the project?',
    /* TT_PROJ_32 */    'COMMON.PROJECT.INVALID_PROJECT_SHARE_TOKEN': 'The token is invalid. You are unable to join the project.',
    /* TT_PROJ_33 */    'COMMON.PROJECT.CONFIRM_TO_JOIN_PROJECT': 'Are you sure to join the project {PROJECT_NAME}?',
    /* TT_PROJ_34 */    'COMMON.PROJECT.DATA_VIEWER': 'Data Viewer',
    /* TT_PROJ_35 */    'COMMON.PROJECT.SHOW_100_RECORD': 'Showing at most 100 Records',
    /* TT_PROJ_36 */    'COMMON.PROJECT.DELETE_PROJECT_AND_DATA': 'Delete project & data',
    /* TT_PROJ_37 */    'COMMON.PROJECT.MORE_SETTING': 'More setting',
    /* TT_PROJ_38 */    'COMMON.PROJECT.MY_FILES': 'My Files',
    /* TT_PROJ_39 */    'COMMON.PROJECT.FILE_LIST': 'Uploaded Files',
    /* TT_PROJ_40 */    'COMMON.PROJECT.FILE_UPLOAD_BOX_TEXT': 'Drag here or click to upload file',
    /* TT_PROJ_41 */    'COMMON.PROJECT.FILE_NAME': 'Name',
    /* TT_PROJ_42 */    'COMMON.PROJECT.FILE_SIZE': 'File Size',
    /* TT_PROJ_43 */    'COMMON.PROJECT.ADD_FILE': 'Add Files',
    /* TT_PROJ_44 */    'COMMON.PROJECT.COPY_EMBEDDED_MAP_URL': 'Copy embedded URL',
    /* TT_PROJ_45 */    'COMMON.PROJECT.TOTAL_NUMBER_OF_DATA': 'IoT Data',
    /* TT_PROJ_46 */    'COMMON.PROJECT.NUMBER_OF_EVENT_TRIGGER': 'Number of Event Trigger',
    /* TT_PROJ_47 */    'COMMON.PROJECT.NUMBER_OF_CHART': 'Number of Chart & Widget',
    /* TT_PROJ_48 */    'COMMON.PROJECT.NUMBER_OF_PROJECT_FILE': 'Number of Project File',
    /* TT_PROJ_49 */    'COMMON.PROJECT.NUMBER_OF_PROJECT_USER': 'Number of User',
    /* TT_PROJ_50 */    'COMMON.PROJECT.IOT_USAGE': 'IoT Usage',
    /* TT_PROJ_51 */    'COMMON.PROJECT.AI_USAGE': 'AI Usage',
    /* TT_PROJ_52 */    'COMMON.PROJECT.VISUALIZATION_USAGE': 'Visualization Usage',
    /* TT_PROJ_53 */    'COMMON.PROJECT.OPEN_API_USAGE': 'Open API \nUsage',
    /* TT_PROJ_54 */    'COMMON.PROJECT.EVENT_TRIGGER_USAGE': 'Event Trigger Usage',
    /* TT_PROJ_55 */    'COMMON.PROJECT.COLLABORATION_USAGE': 'Collaboration \n Usage',
    /* TT_PROJ_56 */    'COMMON.PROJECT.TOTAL_MESSAGE_COUNT': 'Total Message Count',
    /* TT_PROJ_57 */    'COMMON.PROJECT.NUMBER_OF_DATA_TYPE': 'Data Type',
    /* TT_PROJ_58 */    'COMMON.PROJECT.NUMBER_OF_TOPIC': 'MQTT Topic',
    /* TT_PROJ_59 */    'COMMON.PROJECT.PROJECT_OVERVIEW': 'Project Overview',
    /* TT_PROJ_60 */    'COMMON.PROJECT.IOT': 'Internet of Things (IoT)',
    /* TT_PROJ_61 */    'COMMON.PROJECT.AI': 'Artificial Intelligence (AI)',
    /* TT_PROJ_62 */    'COMMON.PROJECT.OPEN_API': 'Open API ',
    /* TT_PROJ_63 */    'COMMON.PROJECT.EXPLAIN_REAL_TIME_LOGGER': 'Real Time shows the recent activity including IoT Data, Event Trigger, Open API',
    /* TT_PROJ_64 */    'COMMON.PROJECT.EXPLAIN_FILE_LIST': 'You can upload code files, such as: MakeCode / Python, etc.',
    /* TT_PROJ_65 */    'COMMON.PROJECT.EXPLAIN_TOPIC_MESSAGE_COUNT': 'Shows the number of data in each Iot Topic',
    /* TT_PROJ_66 */    'COMMON.PROJECT.EXPLAIN_USER_LIST': 'Invite others to collaborate with you on this project',
    /* TT_PROJ_67 */    'COMMON.PROJECT.PROJECT_USAGE_ANALYSIS': 'Usage analysis',
    /* TT_PROJ_68 */    'COMMON.PROJECT.EXPLORE_AND_LEARN': 'Explore and Learning',
    /* TT_PROJ_69 */    'COMMON.PROJECT.CREATE_CHART_SOCKET': 'Created Chart {CHART_NAME}',
    /* TT_PROJ_70 */    'COMMON.PROJECT.UPLOADED_NEW_FILE_SOCKET': 'Uploaded New File {FILE_NAME}',
    /* TT_PROJ_71 */    'COMMON.PROJECT.REMOVED_FILE': 'Removed File {FILE_NAME}',
    /* TT_PROJ_72 */    'COMMON.PROJECT.NEW_USER_JOINED': 'New User Joined {USERNAME}',
    /* TT_PROJ_73 */    'COMMON.PROJECT.REMOVED_USER': 'Removed User {USERNAME}',

    /* TT_TOP_01 */    'COMMON.TOPIC.TOPIC': 'Topic',
    /* TT_TOP_02 */    'COMMON.TOPIC.TOPIC_DESCRIPTION': 'Topic Description',
    /* TT_TOP_03 */    'COMMON.TOPIC.TOPIC_NAME': 'Topic Name',
    /* TT_TOP_04 */    'COMMON.TOPIC.TOPIC_LIST': 'Topic List',
    /* TT_TOP_05 */    'COMMON.TOPIC.CREATE_TOPIC': 'Create Topic',
    /* TT_TOP_06 */    'COMMON.TOPIC.UPDATE_TOPIC': 'Update Topic',
    /* TT_TOP_07 */    'COMMON.TOPIC.DELETE_TOPIC': 'Delete Topic',
    /* TT_TOP_08 */    'COMMON.TOPIC.ADD_PROJECT': 'Add Project To Topic',
    /* TT_TOP_09 */    'COMMON.TOPIC.REMOVE_PROJECT': 'Remove Project From Topic',
    /* TT_TOP_10 */    'COMMON.TOPIC.TALK_TO_TOPIC': 'Talk to topic',
    /* TT_TOP_11 */    'COMMON.TOPIC.SHARE_TOPIC': 'Share Topic',
    /* TT_TOP_12 */    'COMMON.TOPIC.ACTIVE': 'Active',
    /* TT_TOP_13 */    'COMMON.TOPIC.TOPIC_NAME_PLACEHOLDER': 'Topic name will be automatically assigned',
    /* TT_TOP_14 */    'COMMON.TOPIC.TOPIC_DESC_PLACEHOLDER': 'Describe your topic',
    /* TT_TOP_15 */    'COMMON.TOPIC.TOPIC_MESSAGE_PLACEHOLDER': 'Message',
    /* TT_TOP_16 */    'COMMON.TOPIC.TOPIC_MESSAGE_EMPTY': 'Message cannot empty',
    /* TT_TOP_17 */    'COMMON.TOPIC.TOPIC_MESSAGE_SENT': 'Successfully sent to topic',
    /* TT_TOP_18 */    'COMMON.TOPIC.NO_PROJECT_SELECTED': 'Select a project',
    /* TT_TOP_19 */    'COMMON.TOPIC.TOPIC_DESC_EMPTY': 'Description cannot empty',
    /* TT_TOP_20 */    'COMMON.TOPIC.CHANGE_TOPIC_DESCRIPTION_TITLE': 'Update Topic Description',
    /* TT_TOP_21 */    'COMMON.TOPIC.TOPIC_NAME_NOT_FOUND': 'Topic Name not found',
    /* TT_TOP_22 */    'COMMON.TOPIC.MQTT_SEND_MESSAGE_TOOL': 'IoT Message Sending Tool',
    /* TT_TOP_23 */    'COMMON.TOPIC.TOPIC_LOCATION': 'Location',

    /* TT_DATP_01 */   'COMMON.DATA_TYPE.DATA_TYPE': 'Data Type',
    /* TT_DATP_02 */   'COMMON.DATA_TYPE.DATA_TYPE_KEY': 'Key',
    /* TT_DATP_03 */   'COMMON.DATA_TYPE.DATA_TYPE_NAME': 'Name',
    /* TT_DATP_04 */   'COMMON.DATA_TYPE.DATA_TYPE_DESC': 'Description',
    /* TT_DATP_05 */   'COMMON.DATA_TYPE.CREATE_DATA_TYPE': 'Create Data Type',
    /* TT_DATP_06 */   'COMMON.DATA_TYPE.UPDATE_DATA_TYPE': 'Update Data Type',
    /* TT_DATP_07 */   'COMMON.DATA_TYPE.DELETE_DATA_TYPE': 'Delete Data Type',
    /* TT_DATP_08 */   'COMMON.DATA_TYPE.DATA_TYPE_LIST': 'Data Type List',
    /* TT_DATP_09 */   'COMMON.DATA_TYPE.UNIQUE_LABEL': 'Key name should be unique',
    /* TT_DATP_10 */   'COMMON.DATA_TYPE.NAME_PLACEHOLDER': 'Name of the type',
    /* TT_DATP_11 */   'COMMON.DATA_TYPE.KEY_PLACEHOLDER': 'Identifier of the data',
    /* TT_DATP_12 */   'COMMON.DATA_TYPE.DESC_PLACEHOLDER': 'Description of the data type',
    /* TT_DATP_13 */   'COMMON.DATA_TYPE.INVALID_FIELD': 'Invalid field Name or Key',
    /* TT_DATP_14 */   'COMMON.DATA_TYPE.DELETE_DATA_TYPE_HELP_TEXT': 'Are you sure to delete the data type? All the data under this data type will also be deleted. This action cannot be recovered. ',

    /* TT_DASH_01 */   'COMMON.DASHBOARD.DASHBOARD': 'Dashboard',
    /* TT_DASH_02 */   'COMMON.DASHBOARD.DELETE_DASHBOARD': 'Delete Dashboard',
    /* TT_DASH_03 */   'COMMON.DASHBOARD.CREATE_DASHBOARD': 'Create Dashboard',
    /* TT_DASH_04 */   'COMMON.DASHBOARD.UPDATE_DASHBOARD': 'Update Dashboard',
    /* TT_DASH_05 */   'COMMON.DASHBOARD.SWITCH_DASHBOARD': 'Switch Dashboard',
    /* TT_DASH_06 */   'COMMON.DASHBOARD.NO_CHART_IN_DASHBOARD': 'There is no chart in this dashboard, you may add created chart into this dashboard',
    /* TT_DASH_07 */   'COMMON.DASHBOARD.DASHBOARD_CREATED': 'Your new dashboard has been created.',
    /* TT_DASH_08 */   'COMMON.DASHBOARD.DASHBOARD_PLACEHOLDER': 'Dashboard Name',
    /* TT_DASH_09 */   'COMMON.DASHBOARD.ADD_CHART_TO_DASHBOARD': 'Add to dashboard',
    /* TT_DASH_10 */   'COMMON.DASHBOARD.DASHBOARD_NAME_EMPTY': 'Dashboard cannot empty',
    /* TT_DASH_11 */   'COMMON.DASHBOARD.SELECT_A_DASHBOARD': 'Select a dashboard to add:',
    /* TT_DASH_12 */   'COMMON.DASHBOARD.NO_DASHBOARD_SELECTED': 'Please choose a dashboard',

    /* TT_CHAR_01 */   'COMMON.CHART.CHART': 'Chart',
    /* TT_CHAR_02 */   'COMMON.CHART.CREATE_CHART': 'Create Chart',
    /* TT_CHAR_03 */   'COMMON.CHART.DELETE_CHART': 'Delete Chart',
    /* TT_CHAR_04 */   'COMMON.CHART.UPDATE_CHART': 'Update Chart',
    /* TT_CHAR_05 */   'COMMON.CHART.VIEW_CHART_DETAIL': 'View Detail',
    /* TT_CHAR_06 */   'COMMON.CHART.CREATE_CHART_TIPS': 'Only sources that has data will be shown here',
    /* TT_CHAR_07 */   'COMMON.CHART.CHART_NAME_EMPTY': 'Chart name cannot empty',
    /* TT_CHAR_08 */   'COMMON.CHART.CHART_NAME': 'Chart name',
    /* TT_CHAR_09 */   'COMMON.CHART.RECENT_CHART': 'Recent Charts',
    /* TT_CHAR_10 */   'COMMON.CHART.CHOOSE_CHART_TYPE': 'Choose your Chart Type',
    /* TT_CHAR_11 */   'COMMON.CHART.GAUGE_CHART': 'Gauge Chart',
    /* TT_CHAR_12 */   'COMMON.CHART.LINE_CHART': 'Line Chart',
    /* TT_CHAR_13 */   'COMMON.CHART.CHART_SETTING': 'Chart Setting',
    /* TT_CHAR_14 */   'COMMON.CHART.TOGGLE': 'Toggle',
    /* TT_CHAR_15 */   'COMMON.CHART.CHECKBOX': 'Checkbox',
    /* TT_CHAR_16 */   'COMMON.CHART.ON_OFF_BUTTON': 'ON/OFF Button',
    /* TT_CHAR_17 */   'COMMON.CHART.PUSH_BUTTON': 'Push Button',
    /* TT_CHAR_18 */   'COMMON.CHART.PLAIN_TEXT': 'Plain Text',
    /* TT_CHAR_19 */   'COMMON.CHART.CONTROL_BUTTON': 'Control Button',
    /* TT_CHAR_20 */   'COMMON.CHART.CHOOSE_SOURCE': 'Choose data source',
    /* TT_CHAR_21 */   'COMMON.CHART.SELECT_CHART_TYPE_FIRST': 'Please select a chart type',
    /* TT_CHAR_22 */   'COMMON.CHART.SELECTED_CHART_SOURCE': 'Select Chart Source',
    /* TT_CHAR_23 */   'COMMON.CHART.SELECTED_CHART_TYPE': 'Selected Chart Type',
    /* TT_CHAR_24 */   'COMMON.CHART.BUTTON_DISPLAY_TEXT': 'Display Text',
    /* TT_CHAR_25 */   'COMMON.CHART.CHART_COLOR': 'Color',
    /* TT_CHAR_26 */   'COMMON.CHART.BUTTON_ACTIVE_VALUE': 'Active Value',
    /* TT_CHAR_27 */   'COMMON.CHART.BUTTON_INACTIVE_VALUE': 'Inactive Value',
    /* TT_CHAR_28 */   'COMMON.CHART.CHART_MIN_VALUE': 'Min Value',
    /* TT_CHAR_29 */   'COMMON.CHART.CHART_MAX_VALUE': 'Max Value',
    /* TT_CHAR_30 */   'COMMON.CHART.LOCK_BUTTON': 'Lock Button',
    /* TT_CHAR_31 */   'COMMON.CHART.PLAY_BUTTON': 'Play Button',
    /* TT_CHAR_32 */   'COMMON.CHART.SOUND_BUTTON': 'Volume Button',
    /* TT_CHAR_33 */   'COMMON.CHART.CHART_HISTORY_STARTING_FROM': 'Starting From',
    /* TT_CHAR_34 */   'COMMON.CHART.CHART_HISTORY_UNTIL': 'Until',
    /* TT_CHAR_35 */   'COMMON.CHART.CHART_HISTORY_FOR_EVERY': 'For Every',
    /* TT_CHAR_36 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_VALUE': 'Value',
    /* TT_CHAR_37 */   'COMMON.CHART.CHART_HISTORY_EVERY_MONTH': 'Month',
    /* TT_CHAR_38 */   'COMMON.CHART.CHART_HISTORY_EVERY_DAY': 'Day',
    /* TT_CHAR_39 */   'COMMON.CHART.CHART_HISTORY_EVERY_HOUR': 'Hour',
    /* TT_CHAR_40 */   'COMMON.CHART.CHART_HISTORY_EVERY_MINUTE': 'Minute',
    /* TT_CHAR_41 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_AVG': 'Average',
    /* TT_CHAR_42 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_SUM': 'Sum',
    /* TT_CHAR_43 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_MIN': 'Minimum',
    /* TT_CHAR_44 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_MAX': 'Maximum',
    /* TT_CHAR_45 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_COUNT': 'Count',
    /* TT_CHAR_46 */   'COMMON.CHART.CHART_LIVE_DATA': 'LIVE',
    /* TT_CHAR_47 */   'COMMON.CHART.CHART_HISTORY_DATA': 'HISTORY',
    /* TT_CHAR_48 */   'COMMON.CHART.OUTPUT_TYPE': 'Output Type',
    /* TT_CHAR_49 */   'COMMON.CHART.KEY_VALUE_PAIR': 'Key Value Pair',
    /* TT_CHAR_50 */   'COMMON.CHART.SIMPLE_MESSAGE': 'Simple Message',
    /* TT_CHAR_51 */   'COMMON.CHART.MESSAGE_TYPE': 'Message Type',
    /* TT_CHAR_52 */   'COMMON.CHART.CHOOSE_STYLE': 'Choose a style or input text',
    /* TT_CHAR_53 */   'COMMON.CHART.WHEN_BUTTON_ON': 'When it is ON',
    /* TT_CHAR_54 */   'COMMON.CHART.WHEN_BUTTON_OFF': 'When it is OFF',
    /* TT_CHAR_55 */   'COMMON.CHART.COPY_EMBEDDED_CHART_URL': 'Copy embedded URL',
    /* TT_CHAR_56 */   'COMMON.CHART.POINT_MAP': 'Point Map',
    /* TT_CHAR_57 */   'COMMON.CHART.SOURCE_DESCRIPTION': 'Description',
    /* TT_CHAR_58 */   'COMMON.CHART.LATITUDE': 'Latitude',
    /* TT_CHAR_59 */   'COMMON.CHART.LONGITUDE': 'Longitude',
    /* TT_CHAR_60 */   'COMMON.CHART.NOT_AVAILABLE_GOOGLE_MAP': 'Not available for your country',
    /* TT_CHAR_61 */   'COMMON.CHART.MIN_MAX_DESCRIPTION': 'Min & Max of your data input',
    /* TT_CHAR_62 */   'COMMON.CHART.CHART_TOOLBAR_MOVE': 'Hand Tool',
    /* TT_CHAR_63 */   'COMMON.CHART.CHART_TOOLBAR_SELECT': 'Select Tool',
    /* TT_CHAR_64 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_XY': 'Zoom XY',
    /* TT_CHAR_65 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_X': 'Zoom X',
    /* TT_CHAR_66 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_Y': 'ZOOM Y',
    /* TT_CHAR_67 */   'COMMON.CHART.SHOW_LATEST_POINT': 'Show latest: ',
    /* TT_CHAR_67 */   'COMMON.CHART.SHOW_LATEST_POINT_ALL': 'ALL',

    /* TT_SCH_01 */    'COMMON.SCHOOL.SCHOOL_LIST': 'School List',
    /* TT_SCH_02 */    'COMMON.SCHOOL.SCHOOL_ID': 'ID',
    /* TT_SCH_03 */    'COMMON.SCHOOL.SCHOOL_NAME': 'School Name',
    /* TT_SCH_04 */    'COMMON.SCHOOL.SCHOOL_EMAIL_DOMAIN': 'Domain',
    /* TT_SCH_05 */    'COMMON.SCHOOL.VALID_UNTIL': 'Valid Until',
    /* TT_SCH_06 */    'COMMON.SCHOOL.VALID_DATE': 'Valid Date',
    /* TT_SCH_07 */    'COMMON.SCHOOL.APPLICATION_STATUS': 'Application Status',
    /* TT_SCH_08 */    'COMMON.SCHOOL.NO_PENDING_APPLICATION': 'No Pending Application',
    /* TT_SCH_09 */    'COMMON.SCHOOL.INACTIVE': 'Inactive',
    /* TT_SCH_10 */    'COMMON.SCHOOL.PROCESSED': 'Processed',
    /* TT_SCH_11 */    'COMMON.SCHOOL.APPLICATION': 'Application',
    /* TT_SCH_12 */    'COMMON.SCHOOL.TOTAL': 'Total',
    /* TT_SCH_13 */    'COMMON.SCHOOL.APPROVED': 'Approved',
    /* TT_SCH_14 */    'COMMON.SCHOOL.REJECTED': 'Rejected',
    /* TT_SCH_15 */    'COMMON.SCHOOL.PENDING': 'Pending',
    /* TT_SCH_16 */    'COMMON.SCHOOL.SUBMISSION_TIME': 'Submitted on',
    /* TT_SCH_17 */    'COMMON.SCHOOL.APPROVE': 'Approve',
    /* TT_SCH_18 */    'COMMON.SCHOOL.REJECT': 'Reject',
    /* TT_SCH_19 */    'COMMON.SCHOOL.OFFICIAL_REMARKS': 'Official Remarks',
    /* TT_SCH_20 */    'COMMON.SCHOOL.CONFIRM_APPROVE_APPLICATION': 'Are you sure to approve the application?',
    /* TT_SCH_21 */    'COMMON.SCHOOL.CONFIRM_REJECT_APPLICATION': 'Are you sure to reject the application?',

    /* TT_EVENT_01 */  'COMMON.EVENT.EVENT_NAME': 'Event Name',
    /* TT_EVENT_02 */  'COMMON.EVENT.EVENT_CODE': 'Event Code',
    /* TT_EVENT_03 */  'COMMON.EVENT.EVENT_TRIAL_PERIOD': 'Event Trial Period',

    /* TT_VIRT_01 */  'COMMON.VIRTUAL.VIRTUAL_SMART_HOME': 'Virtual Smart Home',
    /* TT_VIRT_02 */  'COMMON.VIRTUAL.SETTING': 'Setting',
    /* TT_VIRT_03 */  'COMMON.VIRTUAL.HARDWARE_SETTING': 'Hardware Setting',
    /* TT_VIRT_04 */  'COMMON.VIRTUAL.TURN_ON': 'Turn On',
    /* TT_VIRT_05 */  'COMMON.VIRTUAL.TURN_OFF': 'Turn Off',
    /* TT_VIRT_06 */  'COMMON.VIRTUAL.TURN_ON_PLACEHOLDER': 'Turn On',
    /* TT_VIRT_07 */  'COMMON.VIRTUAL.TURN_OFF_PLACEHOLDER': 'Turn Off',
    /* TT_VIRT_08 */  'COMMON.VIRTUAL.SAVE': 'Save',
    /* TT_VIRT_09 */  'COMMON.VIRTUAL.LIGHT_BULB': 'Light Bulb',
    /* TT_VIRT_10 */  'COMMON.VIRTUAL.VIRTUAL_LIGHT_BULB': 'Virtual Light Bulb',

    /* ET_01 */        'EVENT_TRIGGER.TRIGGER_TITLE': 'When this happened',
    /* ET_02 */        'EVENT_TRIGGER.FILTER_TITLE': 'If this is true',
    /* ET_03 */        'EVENT_TRIGGER.ACTION_TITLE': 'Then',
    /* ET_04 */        'EVENT_TRIGGER.NEW_TOPIC_MESSAGE_TRIGGER': 'topic received any message',
    /* ET_05 */        'EVENT_TRIGGER.MACHINE_LEARNING_COMPLETED': 'Machine Learning Process completed',
    /* ET_06 */        'EVENT_TRIGGER.AND': 'AND',
    /* ET_07 */        'EVENT_TRIGGER.OR': 'OR',
    /* ET_08 */        'EVENT_TRIGGER.SAME_LEVEL': 'Add to same level',
    /* ET_09 */        'EVENT_TRIGGER.SUB_LEVEL': 'Add to sub level',
    /* ET_10 */        'EVENT_TRIGGER.TOPIC': 'MQTT Topic',
    /* ET_11 */        'EVENT_TRIGGER.S_MESSAGE': '\'s Message',
    /* ET_12 */        'EVENT_TRIGGER.S': '\'s',
    /* ET_13 */        'EVENT_TRIGGER.SUBJECT_KEY': 'Data Type',
    /* ET_14 */        'EVENT_TRIGGER.OBJECT_KEY': 'Content',
    /* ET_15 */        'EVENT_TRIGGER.COMPARATOR': 'Comparator',
    /* ET_16 */        'EVENT_TRIGGER.TOPIC_NAME': 'Topic Name',
    /* ET_17 */        'EVENT_TRIGGER.ADD': 'add',
    /* ET_18 */        'EVENT_TRIGGER.PUBLISH_MQTT_MESSAGE': 'MQTT Message',
    /* ET_19 */        'EVENT_TRIGGER.SEND_SMS': 'Send SMS',
    /* ET_10 */        'EVENT_TRIGGER.SEND_EMAIL': 'Send Email',
    /* ET_21 */        'EVENT_TRIGGER.PUBLISH': 'Publish',
    /* ET_22 */        'EVENT_TRIGGER.TO_TOPIC': 'to MQTT topic',
    /* ET_23 */        'EVENT_TRIGGER.APPLET': 'Applet',
    /* ET_24 */        'EVENT_TRIGGER.VALIDATION_FAILED': 'Validation Failed!',
    /* ET_25 */        'EVENT_TRIGGER.TRIGGER_VALIDATION_FAILED': 'Please make sure you have selected at least one trigger with the correct information',
    /* ET_26 */        'EVENT_TRIGGER.FILTER_VALIDATION_FAILED': 'Please make sure you have selected at least one filter with the correct information',
    /* ET_27 */        'EVENT_TRIGGER.ACTION_VALIDATION_FAILED': 'Please make sure you have selected at least one action with the correct information',
    /* ET_28 */        'EVENT_TRIGGER.ADD_NEW_FILTER': 'Add Condition',
    /* ET_29 */        'EVENT_TRIGGER.WHEN': 'When',
    /* ET_30 */        'EVENT_TRIGGER.CONDITION': 'Condition',
    /* ET_31 */        'EVENT_TRIGGER.SIMPLE_MESSAGE_CONDITION': 'When MQTT Message contains specific messages',
    /* ET_32 */        'EVENT_TRIGGER.KEY_VALUE_CONDITION': 'When MQTT Message contains specific data type value',
    /* ET_33 */        'EVENT_TRIGGER.MESSAGE': 'message',
    /* ET_34 */        'EVENT_TRIGGER.IF': 'If ',
    /* ET_35 */        'EVENT_TRIGGER.CONTAINS': 'contains',
    /* ET_36 */        'EVENT_TRIGGER.NOT_CONTAIN': 'not contain',
    /* ET_37 */        'EVENT_TRIGGER.CREATE_APPLET': 'Create Applet',
    /* ET_38 */        'EVENT_TRIGGER.APPLET_LIST': 'Applet List',
    /* ET_39 */        'EVENT_TRIGGER.APPLET_NAME': 'Applet Name',
    /* ET_40 */        'EVENT_TRIGGER.APPLET_NAME_VALIDATION_FAILED': 'Please give a name to your applet',
    /* ET_41 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_ACTION': 'Trigger IFTTT Web Hook',
    /* ET_42 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_EVENT': 'IFTTT Webhook Event',
    /* ET_43 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_KEY': 'IFTTT Webhook Key',
    /* ET_44 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_JSON_CONTENT': 'Optional Message',
    /* ET_45 */        'EVENT_TRIGGER.WEBHOOK_IS_TRIGGERED': 'webhook is triggered',
    /* ET_46 */        'EVENT_TRIGGER.WEBHOOK_NAME': 'Webhook Name',
    /* ET_47 */        'EVENT_TRIGGER.WEBHOOK_URL': 'Webhook URL',
    /* ET_48 */        'EVENT_TRIGGER.WEBHOOK_URL_HIDDEN_DESCRIPTION': 'Webhook URL will be displayed after creation',
    /* ET_49 */        'EVENT_TRIGGER.DIALOGFLOW_INTENT_IS_TRIGGED': 'Dialogflow Intent is triggered',
    /* ET_50 */        'EVENT_TRIGGER.INTENT_NAME': 'Intent Name',
    /* ET_51 */        'EVENT_TRIGGER.DIALOGFLOW_URL': 'Dialogflow URL',
    /* ET_52 */        'EVENT_TRIGGER.DIALOGFLOW_URL_HIDDEN_DESCRIPTION': 'Dialogflow URL will be displayed after creation',
    /* ET_53 */        'EVENT_TRIGGER.APPLET_ENABLE': 'Enable',
    /* ET_54 */        'EVENT_TRIGGER.MQTT_TRIGGER_NAME': 'MQTT Trigger',
    /* ET_55 */        'EVENT_TRIGGER.WEB_HOOK_TRIGGER_NAME': 'Webhook Trigger',
    /* ET_56 */        'EVENT_TRIGGER.AI_TRIGGER_NAME': 'AI Trigger',
    /* ET_57 */        'EVENT_TRIGGER.DIALOG_FLOW_TRIGGER_NAME': 'DialogFlow Trigger',
    /* ET_58 */        'EVENT_TRIGGER.CONDITION_FILTER_NAME': 'Logic',
    /* ET_59 */        'EVENT_TRIGGER.IFTTT_ACTION_NAME': 'IFTTT Action',
    /* ET_60 */        'EVENT_TRIGGER.MQTT_ACTION_NAME': 'MQTT Action',
    /* ET_61 */        'EVENT_TRIGGER.OBJECT_IS_DETECTED': 'When Object is detected',
    /* ET_62 */        'EVENT_TRIGGER.FACE_IS_DETECTED': 'When Face is detected',
    /* ET_63 */        'EVENT_TRIGGER.SEND_HTTP_RESPONSE_ACTION': 'Set HTTP Response',
    /* ET_64 */        'EVENT_TRIGGER.SEND_HTTP_RESPONSE_ACTION_TITLE': 'HTTP Response',
    /* ET_65 */        'EVENT_TRIGGER.FACE_DETECTION_TRIGGER': 'Face Detection',
    /* ET_66 */        'EVENT_TRIGGER.OBJECT_DETECTION_TRIGGER': 'Object Detection',
    /* ET_67 */        'EVENT_TRIGGER.UPDATE_GOOGLE_SHEET': 'Update Google Spreadsheet',
    /* ET_68 */        'EVENT_TRIGGER.ALLOW_EDIT_GOOGLE_SHEET': 'Step 1 : Allow us to edit your Google Sheet',
    /* ET_69 */        'EVENT_TRIGGER.GOOGLE_SHEET_URL': 'Step 2 : Google Sheet URL',
    /* ET_70 */        'EVENT_TRIGGER.GET_GOOGLE_SHEET_ID': 'Get ID',
    /* ET_71 */        'EVENT_TRIGGER.CHANGE_GOOGLE_SHEET_URL': 'Change Url',
    /* ET_72 */        'EVENT_TRIGGER.GOOGLE_SHEET_FEATURE_TITLE': 'Google sheet update',

    /* MY_SUB_01 */    'MY_SUB.MY_SUBSCRIPTION': 'My Subscription',
    /* MY_SUB_02 */    'MY_SUB.MANAGE_MY_SUBSCRIPTION': 'Manage My Subscription',
    /* MY_SUB_03 */    'MY_SUB.USE_LICENSE_KEY': 'Use License Key',
    /* MY_SUB_04 */    'MY_SUB.USE_EVENT_CODE': 'Use Event Code',
    /* MY_SUB_05 */    'MY_SUB.ENTER_LICENSE_KEY': 'Enter License Key',
    /* MY_SUB_06 */    'MY_SUB.LICENSE_KEY': 'License Key',
    /* MY_SUB_07 */    'MY_SUB.ENTER_LICENSE_KEY_HINTS': 'Please contact our support for license extension if your license key is expired.',
    /* MY_SUB_08 */    'MY_SUB.ENTER_EVENT_CODE': 'Enter Event Code',
    /* MY_SUB_09 */    'MY_SUB.EVENT_CODE': 'Event Code',
    /* MY_SUB_10 */    'MY_SUB.ENTER_EVENT_CODE_HINTS': 'Unless specified, the event code access will be expired in 7 days.',
    /* MY_SUB_11 */    'MY_SUB.SUBMIT_BUTTON': 'Submit',
    /* MY_SUB_12 */    'MY_SUB.SUB_STATUS_PREFIX': 'Your account is ',
    /* MY_SUB_13 */    'MY_SUB.ACTIVE': 'Active',
    /* MY_SUB_14 */    'MY_SUB.INACTIVE': 'Inactive',
    /* MY_SUB_15 */    'MY_SUB.WHITELIST_STATEMENT': 'your are on the whitelist to use Maker Cloud.',
    /* MY_SUB_16 */    'MY_SUB.SCHOOL_SUB_STATEMENT': 'you are licensed under School Based Subscription.',
    /* MY_SUB_17 */    'MY_SUB.EVENT_CODE_STATEMENT': 'you are licensed under Event Registration.',
    /* MY_SUB_18 */    'MY_SUB.LICENSE_SUB_STATEMENT': 'you are licensed under License Registration.',
    /* MY_SUB_19 */    'MY_SUB.LICENSED_TO': 'License To',
    /* MY_SUB_20 */    'MY_SUB.CONCURRENT_LOGIN': 'Concurrent Login',
    /* MY_SUB_21 */    'MY_SUB.CONSUMED': 'Consumed',
    /* MY_SUB_22 */    'MY_SUB.EXPIRY_DATE': 'Expiry Date',
    /* MY_SUB_23 */    'MY_SUB.NO_EXPIRE_DATE': 'Your account does not expire.',
    /* MY_SUB_24 */    'MY_SUB.EXPIRE_DATE': 'Expire on',
    /* MY_SUB_25 */    'MY_SUB.VIEW_LICENSE_DETAIL_BUTTON': 'Check License',
    /* MY_SUB_26 */    'MY_SUB.MANAGE_LICENSE': 'License Management',
    /* MY_SUB_27 */    'MY_SUB.UPGRADE_LICENSE_TITLE': 'Upgrade License',
    /* MY_SUB_28 */    'MY_SUB.UPGRADE_LICENSE_MSG': 'Upgrade your license to enjoy more feature!',

    /* OA_001 */    'OPEN_API.OPEN_API_DESC': 'Open API let our users connect to MakerCloud services. It provides HTTP Interface for users to connect via Network Client',
    /* OA_002 */    'OPEN_API.OPEN_API_KEY_TITLE': 'Open API Token',
    /* OA_003 */    'OPEN_API.AI_CATEGORY': 'Artificial Intelligence (AI)',
    /* OA_004 */    'OPEN_API.IMAGE_RECOGNITION_TITLE': 'Image Recognition',
    /* OA_005 */    'OPEN_API.IMAGE_RECOGNITION_DESC': 'Image Recognition uses computer to process, analyze and understand the image, and identity the objects on the image',
    /* OA_006 */    'OPEN_API.IMAGE_CLASSIFICATION_TITLE': 'Image Classification',
    /* OA_007 */    'OPEN_API.IMAGE_CLASSIFICATION_DESC': 'Image Classification is the process of analyzing an image and categorize into pre-defined classes',
    /* OA_008 */    'OPEN_API.FACE_ANALYSIS_TITLE': 'Face Analysis',
    /* OA_009 */    'OPEN_API.FACE_ANALYSIS_DESC': 'Face Analysis can real-time detect and highlight your face',
    /* OA_010 */    'OPEN_API.TEXT_ANALYSIS_TITLE': 'Text Analysis',
    /* OA_011 */    'OPEN_API.TEXT_ANALYSIS_DESC': 'Turn unstructured text into meaningful insights with Text Analytics, including Emotion analysis, important pharse extraction',
    /* OA_012 */    'OPEN_API.AUDIO_ANALYSIS_TITLE': 'Audio Analysis',
    /* OA_013 */    'OPEN_API.AUDIO_ANALYSIS_DESC': 'Audio Analysis can translate your audio message into text message',
    /* OA_014 */    'OPEN_API.IOT_CATEGORY': 'Internet of Things (IoT)',
    /* OA_015 */    'OPEN_API.IOT_DATA_STORE_TITLE': 'IoT Data Store Service',
    /* OA_016 */    'OPEN_API.IOT_DATA_STORE_DESC': 'Store your IoT Enabled device\'s data on MakerCloud, and group them into different topics, data types and hardware devices',
    /* OA_017 */    'OPEN_API.IOT_LOCATION_TITLE': 'IoT Location Service',
    /* OA_018 */    'OPEN_API.IOT_LOCATION_DESC': 'Base on the geolocation of the IoT Topic, calculates the geometric difference between topics',
    /* OA_019 */    'OPEN_API.IOT_TOPIC_TITLE': 'IoT Topic Service',
    /* OA_020 */    'OPEN_API.IOT_TOPIC_DESC': 'Retrieve the information of the topic',
    /* OA_021 */    'OPEN_API.MAKER_CLOUD_CATEGORY': 'MakerCloud Open API',
    /* OA_022 */    'OPEN_API.UCOA_TITLE': 'User Create Open API',
    /* OA_023 */    'OPEN_API.UCOA_DESC': 'User can create their own open api and serve it to other users. The input, processing logic, and the output can also be defined by the user',
    /* OA_024 */    'OPEN_API.PROJECT_API_TITLE': 'Project Service',
    /* OA_025 */    'OPEN_API.PROJECT_API_DESC': 'Retrieve the information of the MakerCloud Project',
    /* OA_026 */    'OPEN_API.OPEN_DATA_CATEGORY': 'Open Data',
    /* OA_027 */    'OPEN_API.WEATHER_API_TITLE': 'Weather Service',
    /* OA_028 */    'OPEN_API.WEATHER_API_DESC': 'Retrieve the weather information for real-time, one day, or 7 days forecast. ',
    /* OA_029 */    'OPEN_API.CURRENCY_API_TITLE': 'Currency Exchange Rate Service',
    /* OA_030 */    'OPEN_API.CURRENCY_API_DESC': 'Retrieve the exchange rate of different currency',
    /* OA_031 */    'OPEN_API.SCHOOL_API_TITLE': 'School List Service',
    /* OA_032 */    'OPEN_API.SCHOOL_API_DESC': 'Retrieve all school information in Hong Kong',
    /* OA_033 */    'OPEN_API.GENERIC_DATA_STORE_APPLICATION_CATEGORY': 'Generic Data Store & Application ',
    /* OA_034 */    'OPEN_API.GENERIC_DATA_STORE_TITLE': 'Generic Data Store',
    /* OA_035 */    'OPEN_API.GENERIC_DATA_STORE_DESC': 'Generic Data Store allows user to store any kind of data on MakerCloud',
    /* OA_036 */    'OPEN_API.ACCESS_CONTROL_TITLE': 'Access Control Service',
    /* OA_037 */    'OPEN_API.ACCESS_CONTROL_DESC': 'Access Control Service allows user to define access right for different entities',
    /* OA_038 */    'OPEN_API.TIME_TRACKER_TITLE': 'Timer Service',
    /* OA_039 */    'OPEN_API.TIME_TRACKER_DESC': 'Timer Service allows user to start, pause, and stop a timer',
    /* OA_040 */    'OPEN_API.CODE_FILE_STORAGE_TITLE': 'Code File Storage Service',
    /* OA_041 */    'OPEN_API.CODE_FILE_STORAGE_DESC': 'Code File Storage Service allows user to upload their coding file to MakerCloud',
    /* OA_042 */    'OPEN_API.OPEN_API_TITLE': 'Open API',
    /* OA_042 */    'OPEN_API.WEATHER.TEMPERATURE': 'Temperature',
    /* OA_042 */    'OPEN_API.WEATHER.HUMIDITY': 'Humidity',
    /* OA_042 */    'OPEN_API.WEATHER.UV_INDEX': 'UV Index',
    /* OA_042 */    'OPEN_API.WEATHER.RAINFALL': 'Rainfall',

    /* AI_001 */    'AI.IMAGE_RECOGNITION_TITLE': 'Object Detection',
    /* AI_002 */    'AI.IMAGE_RECOGNITION_DESC': 'Image Recognition uses computer to process, analyze and understand the image, and identity the objects on the image',
    /* AI_003 */    'AI.IMAGE_STYLE_TRANSFER_TITLE': 'Transfer Image Style',
    /* AI_004 */    'AI.IMAGE_STYLE_TRANSFER_DESC': 'Use Deep Learning to compose one image in the style of another image',
    /* AI_005 */    'AI.IMAGE_CLASSIFICATION_TITLE': 'Image Classification',
    /* AI_006 */    'AI.IMAGE_CLASSIFICATION_DESC': 'Image Classification is the process of analyzing an image and categorize into pre-defined classes',
    /* AI_007 */    'AI.AUDIO_CLASSIFICATION_TITLE': 'Audio Classification',
    /* AI_008 */    'AI.AUDIO_CLASSIFICATION_DESC': 'Audio Classification is the process of analyzing an audio and categorize into pre-defined classes',
    /* AI_009 */    'AI.POSE_CAPTURE_TITLE': 'Pose Detection',
    /* AI_010 */    'AI.POSE_CAPTURE_DESC': 'Use Machine Learning to detect and classify your pose',
    /* AI_011 */    'AI.FACE_DETECTION_TITLE': 'Face Detection',
    /* AI_012 */    'AI.FACE_DETECTION_DESC': 'Use Machine Learning to detect the position of the faces in an image',
    /* AI_013 */    'AI.IMAGE_TRANSFER.STEP_1': 'Step 1: Upload the photo',
    /* AI_014 */    'AI.IMAGE_TRANSFER.STEP_2': 'Step 2: Upload the style',
    /* AI_015 */    'AI.IMAGE_TRANSFER.STEP_3': 'Transfer Result',
    /* AI_016 */    'AI.IMAGE_TRANSFER.DETECTED_OBJECTS': 'Detected Object',
    /* AI_017 */    'AI.IMAGE_TRANSFER.TRIGGER_IN_SECONDS': ' Second',
    /* AI_018 */    'AI.IMAGE_TRANSFER.SEND_INTERVAL': 'Send Interval',
    /* AI_019 */    'AI.IMAGE_TRANSFER.MIN_SCORE': 'Minimum Score',
    /* AI_020 */    'AI.IMAGE_TRANSFER.TRIGGER_IN_PREFIX': 'Trigger in ',

    /* PER_01 */    'PERMISSION.YOUR_PERMISSIONS': 'Your Permissions',
    /* PER_02 */    'PERMISSION.PROJECT': 'Project',
    /* PER_03 */    'PERMISSION.SHARE_PROJECT': 'License Management',
    /* PER_04 */    'PERMISSION.PROJECT_COUNT': 'Project Count',
    /* PER_05 */    'PERMISSION.TOPIC_COUNT': 'Topic Count',
    /* PER_06 */    'PERMISSION.DATA': 'Data',
    /* PER_07 */    'PERMISSION.INTERVAL_LIMIT': 'Interval Limit',
    /* PER_08 */    'PERMISSION.DAILY_LIMIT': 'Daily Limit',
    /* PER_09 */    'PERMISSION.EXPORT_DATA': 'Export Data',
    /* PER_10 */    'PERMISSION.EVENT_TRIGGER': 'Event Trigger',
    /* PER_11 */    'PERMISSION.MQTT_TRIGGER': 'MQTT Trigger',
    /* PER_12 */    'PERMISSION.WEB_HOOK_TRIGGER': 'Web hook Trigger',
    /* PER_13 */    'PERMISSION.DIALOGFLOW_TRIGGER': 'Dialogflow Trigger',
    /* PER_14 */    'PERMISSION.MQTT_ACTION': 'MQTT Action',
    /* PER_15 */    'PERMISSION.IFTTT_ACTION': 'IFTTT Action',
    /* PER_16 */    'PERMISSION.PROJECT_MAP': 'Project Map',
    /* PER_17 */    'PERMISSION.DYNAMIC_LOCATION_UPDATE': 'Dynamic Location Update',
    /* PER_18 */    'PERMISSION.EMBED_PROJECT_MAP': 'Embed Project Map',
    /* PER_19 */    'PERMISSION.CHART': 'Chart',
    /* PER_20 */    'PERMISSION.LIVE_DATA': 'Live Data',
    /* PER_21 */    'PERMISSION.HISTORICAL_DATA': 'Historical Data',
    /* PER_22 */    'PERMISSION.BASIC_CHART': 'Basic Chart (Line Chart, Gauge, Text)',
    /* PER_23 */    'PERMISSION.CIRCLE_POINT_MAP': 'Circle Point Map',
    /* PER_24 */    'PERMISSION.EMBED_CHART': 'Embed Chart',
    /* PER_25 */    'PERMISSION.WIDGET': 'Widget',
    /* PER_26 */    'PERMISSION.BUTTON': 'Button',
    /* PER_27 */    'PERMISSION.TOGGLE': 'Toggle',
    /* PER_28 */    'PERMISSION.SUPPORTED': 'Supported',
    /* PER_29 */    'PERMISSION.UNLIMITED': 'Unlimited',
    /* PER_30 */    'PERMISSION.NOT_SUPPORT': 'Not Support',

    /* MAINTAIN_01 */  'MAINTAIN.PRE_MAINTENANCE_PERIOD_WARNING_MESSAGE': 'There will be a scheduled maintenance of MakerCloud Service between {START_FROM} and {END_UNTIL}. Service will be interrupted during the maintenance. For enquiries, please contact us at support@scaleinnotech.com',
    /* MAINTAIN_02 */  'MAINTAIN.MAINTENANCE_PERIOD_LABEL': 'System Upgrade',

    /* TT_ACT_01 */    'COMMON.ACTION.ADD': 'Add',
    /* TT_ACT_02 */    'COMMON.ACTION.CREATE': 'Create',
    /* TT_ACT_03 */    'COMMON.ACTION.EDIT': 'Edit',
    /* TT_ACT_04 */    'COMMON.ACTION.SHARE': 'Share',
    /* TT_ACT_05 */    'COMMON.ACTION.SEND': 'Send',
    /* TT_ACT_06 */    'COMMON.ACTION.REMOVE': 'Remove',
    /* TT_ACT_07 */    'COMMON.ACTION.DELETE': 'Delete',
    /* TT_ACT_08 */    'COMMON.ACTION.SEARCH': 'Search',
    /* TT_ACT_09 */    'COMMON.ACTION.COPY': 'Copy',
    /* TT_ACT_10 */    'COMMON.ACTION.ACTION_FAILED': 'The action is failed. Please try again later. If you think this is an error, please contact our support.',
    /* TT_ACT_11 */    'COMMON.ACTION.NO_RECORD': 'No record found',
    /* TT_ACT_12 */    'COMMON.ACTION.LOADING_MESSAGE': 'Please wait..',
    /* TT_ACT_13 */    'COMMON.ACTION.CONFIRM': 'Yes and confirm',
    /* TT_ACT_14 */    'COMMON.ACTION.CANCEL': 'Cancel',
    /* TT_ACT_15 */    'COMMON.ACTION.ADD_SUCCESS': 'Added!',
    /* TT_ACT_16 */    'COMMON.ACTION.CREATE_SUCCESS': 'Created!',
    /* TT_ACT_17 */    'COMMON.ACTION.UPDATE_SUCCESS': 'Updated!',
    /* TT_ACT_18 */    'COMMON.ACTION.REMOVE_SUCCESS': 'Removed!',
    /* TT_ACT_19 */    'COMMON.ACTION.FAILED': 'Failed!',
    /* TT_ACT_20 */    'COMMON.ACTION.COPY_SUCCESS': 'Copied!',
    /* TT_ACT_21 */    'COMMON.ACTION.SHOW': 'Show:',
    /* TT_ACT_22 */    'COMMON.ACTION.VIEW': 'View',
    /* TT_ACT_23 */    'COMMON.ACTION.EXPAND': 'Expand',
    /* TT_ACT_24 */    'COMMON.ACTION.COLLAPSE': 'Collapse',
    /* TT_ACT_25 */    'COMMON.ACTION.CLOSE': 'Close',
    /* TT_ACT_26 */    'COMMON.ACTION.PREVIOUS': 'Previous',
    /* TT_ACT_27 */    'COMMON.ACTION.NEXT': 'Next',
    /* TT_ACT_28 */    'COMMON.ACTION.OK': 'OK',
    /* TT_ACT_29 */    'COMMON.ACTION.FILL_IN_ALL_DATA': 'Please fill in the required data',
    /* TT_ACT_30 */    'COMMON.ACTION.SAVE': 'Save',
    /* TT_ACT_31 */    'COMMON.ACTION.MQTT_APPLET_TRIGGERED': '{APPLET_NAME} \'s MQTT Trigger {NAME} is triggered',
    /* TT_ACT_32 */    'COMMON.ACTION.WEBHOOK_APPLET_TRIGGERED': '{APPLET_NAME} \'s Webhook Trigger {NAME} is triggered',
    /* TT_ACT_33 */    'COMMON.ACTION.DIALOG_FLOW_APPLET_TRIGGERED': '{APPLET_NAME} \'s DialogFlow Trigger {NAME} is triggered',
    /* TT_ACT_34 */    'COMMON.ACTION.APPLET_FILTER_RESULT_SUCCESS': '{APPLET_NAME} \'s filter passed',
    /* TT_ACT_35 */    'COMMON.ACTION.APPLET_FILTER_RESULT_FALL': '{APPLET_NAME} \'s filter failed, no action will be performed',
    /* TT_ACT_36 */    'COMMON.ACTION.IFTTT_ACTION_DONE': '{APPLET_NAME} \'s IFTTT Action is performed',
    /* TT_ACT_37 */    'COMMON.ACTION.MQTT_ACTION_DONE': '{APPLET_NAME} \'s MQTT Action is performed',
    /* TT_ACT_38 */    'COMMON.ACTION.HTTP_RESPONSE_ACTION_DONE': '{APPLET_NAME} \'s HTTP Response is performed',
    /* TT_ACT_39 */    'COMMON.ACTION.TOPIC_RECEIVED_MSG': '{TOPIC_NAME} received {MSG}',
    /* TT_ACT_40 */    'COMMON.ACTION.OBJECT_DETECTION_APPLET_TRIGGERED': '{APPLET_NAME} \'s Object Detection Trigger {NAME} is triggered',
    /* TT_ACT_41 */    'COMMON.ACTION.FACE_DETECTION_APPLET_TRIGGERED': '{APPLET_NAME} \'s Face Detection Trigger is triggered',
    /* TT_ACT_42 */    'COMMON.ACTION.RENAME': 'Rename',

    /* TT_HOME_01 */   'COMMON.HOME': 'Home',
    /* TT_HOME_02 */   'COMMON.HOME.BACK_TO_HOME': 'Back to Home',

    /* TT_BRD_01 */    'COMMON.BREADCRUMB.DETAIL': 'Details',

    /* TT_DEVI_01 */   'COMMON.DEVICE.DEVICE': 'Device',
    /* TT_DEVI_02 */   'COMMON.DEVICE.DEVICE_SERIAL_NUMBER': 'Device Serial Name',
    /* TT_DEVI_03 */   'COMMON.DEVICE.DEVICE_NAME': 'Device Name',

    /* TT_MENU_01 */   'COMMON.MENU.USER_LIST': 'User List',
    /* TT_MENU_02 */   'COMMON.MENU.WHITELIST_USER': 'Whitelist',

    /* TT_COM_01 */   'COMMON.CREATED_ON': 'Created On',
    /* TT_COM_02 */   'COMMON.CREATED_BY': 'Created By',
    /* TT_COM_03 */   'COMMON.MODIFIED_ON': 'Modified By',
    /* TT_COM_04 */   'COMMON.MODIFIED_BY': 'Modified On',
    /* TT_COM_05 */   'COMMON.ID': 'ID',
    /* TT_COM_06 */   'COMMON.STEP.STEP_1': 'Step 1',
    /* TT_COM_07 */   'COMMON.STEP.STEP_2': 'Step 2',
    /* TT_COM_08 */   'COMMON.STEP.STEP_3': 'Step 3',
    /* TT_COM_09 */   'COMMON.STEP.STEP_4': 'Step 4',
    /* TT_COM_10 */   'COMMON.TIME': 'Time'
};
export default messages;