exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3gpn1v4KC-OYGCtF-mAZgT {\n    border-left: 5px solid #34bfa3;\n    padding: 5px 20px;\n    font-size: 20px;\n}\n\n._3yyPq_CMdNIrUas1fREC4M {\n    width: 100%;\n    overflow-wrap: break-word;\n}\n\n._1vzCedR7WuukrwhpAsfYu4 {\n    width: 320px;\n\n    -moz-user-select: none;\n    -webkit-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    -o-user-select: none;\n}\n\n._2oqttty7GhLecAfU3oasBD {\n    margin-top: 20px;\n}\n\n.-rQ30n2BaD5qGLs8Jc_jG {\n    font-size: 16px;\n}\n\n.aYmWKvFuK9jM5O2CCyu3H {\n    text-align: center;\n}\n\n.fVLyGsixRBxTZpMLSN0vP {\n    margin-top: 60px;\n    margin-bottom: 40px;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.cGNHmRfCrpZILObxIYl4f {\n    position: absolute;\n    top: 35px;\n    right: 35px;\n}", ""]);

// exports
exports.locals = {
	"title": "_3gpn1v4KC-OYGCtF-mAZgT",
	"username": "_3yyPq_CMdNIrUas1fREC4M",
	"userTitleImage": "_1vzCedR7WuukrwhpAsfYu4",
	"tableBox": "_2oqttty7GhLecAfU3oasBD",
	"table": "-rQ30n2BaD5qGLs8Jc_jG",
	"imageBox": "aYmWKvFuK9jM5O2CCyu3H",
	"row": "fVLyGsixRBxTZpMLSN0vP",
	"explainButton": "cGNHmRfCrpZILObxIYl4f"
};