// @flow

import React from 'react';
import type {Match} from "../../App";
import swal from "sweetalert2";
import Api from "../../bundle/Api";
import _ from "lodash";
import Constant from "../../bundle/Constant";
import {createAction} from "../../redux/reducer";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../IntlCapture";

type UnlinkTopicFromProjectButtonProps = {
    topicId: string,
    projectId: string,

    dispatchTopicUpdated?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}

const removeTopicFromProjectPrompt = (topicId: string, projectId: string, dispatchTopicUpdatedCallback: ?any) => {

    if (!topicId || !projectId) {
        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        return;
    }

    swal({
        title: LLMsg("COMMON.PROJECT.REMOVE_TOPIC_TITLE"),
        text: LLMsg("COMMON.PROJECT.REMOVE_TOPIC_DESC"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
    }).then((e) => {
        if (e.dismiss) {
            return;
        }
        if (e.value) {
            Api.services.project.update.removeTopicFromProject(topicId, projectId).then(response => {
                if (response.status === 200) {
                    swal(LLMsg("COMMON.ACTION.REMOVE_SUCCESS"), "", "success");
                    dispatchTopicUpdatedCallback && dispatchTopicUpdatedCallback();
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch(() => {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");

            });
        } else {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        }
    });

};


const UnlinkTopicFromProjectButton = (props: UnlinkTopicFromProjectButtonProps) => {
    let topicId = _.get(props, "topicId", "");
    let projectId = _.get(props, "projectId", "");
    return <button className="btn btn-secondary m-btn btn-sm" onClick={() => {
        removeTopicFromProjectPrompt(topicId, projectId, props.dispatchTopicUpdated);
    }}>
         <span>
            <i className={Constant.theme.delete.icon}/>
            <span className={"m--hidden-mobile ml-2"}>
                {LLMsg("COMMON.ACTION.DELETE")}
            </span>
        </span>
    </button>

};

const mapDispatchToProps = dispatch => ({
    dispatchTopicUpdated: () => dispatch(createAction(Api.actions.project.PROJECT_UPDATED))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(UnlinkTopicFromProjectButton));