// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";

export default class BackendStatusService {

    static getStatus(): Object {
        return HttpUtil.ajax(Api.urls.backendStatus.getStatus(), "GET", {
            'Content-Type': 'application/json',
        });
    }
    static getUpdateLogs(): Object {
        return HttpUtil.ajax(Api.urls.backendStatus.getUpdateLogs(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static getLearningLinks(): Object {
        return HttpUtil.ajax(Api.urls.backendStatus.getLearningLinks(), "GET", {
            'Content-Type': 'application/json',
        });
    }



}