// @flow

export default class DataStore {
    static cookies = {
        set: (key: string, value:string): void => {
            const days = 30;
            let expires;
            if (days) {
                let date: any = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toGMTString();
            } else {
                expires = '';
            }
            document.cookie = key + '=' + value + expires + '; path=/';
        },
        get: (key: string) => {
            let b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
            return b ? b.pop() : '';
        }
    }
}