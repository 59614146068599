// @flow
import React, {Component} from 'react';
import CreateDashboardButton from "../../CreateDashboardButton";
import {Link} from "react-router-dom";
import Page from "../../../../bundle/Page";
import {LLMsg} from "../../../../IntlCapture";

type DashboardHeaderMoreFragmentProps = {
    dashboards: Array<{ dashboardId: string, dashboardName: string }>
}

export default class DashboardHeaderMoreFragment extends Component <DashboardHeaderMoreFragmentProps, any> {
    render() {
        const dashboards = this.props.dashboards;
        const colorList = ["m--font-info", "m--font-success", "m--font-warning", "m--font-primary"];
        return <li
            className="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
            m-dropdown-toggle="hover" aria-expanded="true">
            <a href="#" className="m-portlet__nav-link m-portlet__nav-link--icon m-dropdown__toggle">
                <i className="la la-ellipsis-h"></i>
            </a>
            <div className="m-dropdown__wrapper" style={{zIndex: 101}}>
                <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                      style={{left: 'auto', right: '16.5px'}}/>
                <div className="m-dropdown__inner">
                    <div className="m-dropdown__body">
                        <div className="m-dropdown__content">
                            <ul className="m-nav">
                                <li className="m-nav__section m-nav__section--first mb-3">
                                    <span className="m-nav__section-text m--icon-font-size-lg1 ">
                                        {LLMsg("COMMON.DASHBOARD.SWITCH_DASHBOARD")}
                                    </span>
                                </li>

                                {
                                    dashboards && dashboards.length > 0 && dashboards.map((dashboard, index) => {
                                        return <li key={index} className="m-nav__item">
                                            <Link
                                                to={Page.internals.urls.dashboard.getDashboardPageUrl(dashboard.dashboardId, dashboard.dashboardName)}
                                                className="m-nav__link ">

                                                <i className={"m-nav__link-icon flaticon-imac " + colorList[index % colorList.length]}/>
                                                <span
                                                    className={"m-nav__link-text " + colorList[index % colorList.length]}>
                                                    {dashboard.dashboardName}
                                                </span>

                                            </Link>
                                        </li>
                                    })

                                }

                                <li className="m-nav__separator m-nav__separator--fit"/>
                                <li className="m-nav__item">
                                    <CreateDashboardButton/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>;
    }
}