// @flow

import React from 'react';
import styles from './index.css';
import {ClassNames} from "../../../bundle/ClassNames";
import {Link} from "react-router-dom";
import UrlBuilder from "../../../url/UrlBuilder";
import Page from "../../../bundle/Page";
import Constant from "../../../bundle/Constant";
import type {Project} from "../../../model/model";
import {LLMsg} from "../../../IntlCapture";
import ErrorUtil from "../../../util/ErrorUtil";

type Props = {
    project: Project
}
export default class OpenApiListFragment extends React.PureComponent<Props, State> {

    renderItem(title: string, desc: string, openApiType: string) {


        const projectId = this.props.project.id;
        const projectName = this.props.project.projectName;
        const query = UrlBuilder.getQueryString();
        query.openApi = openApiType;

        return <div className={"col-12 col-md-6 col-lg-4"}>
            <div className={ClassNames(styles.apiItemBox, "no-a-effect")}
                 onClick={() => {
                     ErrorUtil.showPermissionNotAllowed();
                 }}
                // to={Page.internals.urls.project.detail(projectId, projectName, query)}
            >
                <div className={styles.apiItemTitle}>{title}</div>
                <i className={ClassNames("fa fa-angle-right", styles.apiItemTitleArrow)}/>
                <div className={styles.apiItemDesc}>
                    {desc}
                </div>
            </div>
        </div>;
    }

    render() {
        return <div className={"row"}>
            <div className={"col-12 p-2"}>
                <div className="m-portlet mb-0">
                    <div className={"m-portlet__body"}>

                        <div className={styles.titleBox}>

                            <div className={styles.title}>{LLMsg("OPEN_API.OPEN_API_TITLE")}</div>
                            <div className={styles.titleBoxDesc}>
                                {LLMsg("OPEN_API.OPEN_API_DESC")}
                                <div className={styles.apiKeyBox}>
                                    <span>{LLMsg("OPEN_API.OPEN_API_KEY_TITLE")}</span>
                                    <input readOnly={true} className={styles.apiKey}
                                           value={this.props.project.openApiKey}/>
                                </div>
                            </div>
                        </div>
                        <div className={styles.apiGroup}>
                            <div className={styles.apiGroupTitle}>
                                {LLMsg("OPEN_API.AI_CATEGORY")}
                            </div>
                            <div className={"row"}>
                                {this.renderItem(LLMsg("OPEN_API.IMAGE_RECOGNITION_TITLE"), LLMsg("OPEN_API.IMAGE_RECOGNITION_DESC"), Constant.openApiSection.imageRecognition)}
                                {this.renderItem(LLMsg("OPEN_API.IMAGE_CLASSIFICATION_TITLE"), LLMsg("OPEN_API.IMAGE_CLASSIFICATION_DESC"), Constant.openApiSection.imageClassification)}
                                {this.renderItem(LLMsg("OPEN_API.FACE_ANALYSIS_TITLE"), LLMsg("OPEN_API.FACE_ANALYSIS_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.TEXT_ANALYSIS_TITLE"), LLMsg("OPEN_API.TEXT_ANALYSIS_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.AUDIO_ANALYSIS_TITLE"), LLMsg("OPEN_API.AUDIO_ANALYSIS_DESC"), Constant.openApiSection.comingSoon)}
                            </div>

                        </div>
                        <div className={styles.apiGroup}>
                            <div className={styles.apiGroupTitle}>
                                {LLMsg("OPEN_API.IOT_CATEGORY")}
                            </div>
                            <div className={"row"}>
                                {this.renderItem(LLMsg("OPEN_API.IOT_DATA_STORE_TITLE"), LLMsg("OPEN_API.IOT_DATA_STORE_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.IOT_LOCATION_TITLE"), LLMsg("OPEN_API.IOT_LOCATION_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.IOT_TOPIC_TITLE"), LLMsg("OPEN_API.IMAGE_RECOGNITION_TITLE"), Constant.openApiSection.comingSoon)}
                            </div>
                        </div>
                        <div className={styles.apiGroup}>
                            <div className={styles.apiGroupTitle}>
                                {LLMsg("OPEN_API.MAKER_CLOUD_CATEGORY")}
                            </div>
                            <div className={"row"}>
                                {this.renderItem(LLMsg("OPEN_API.UCOA_TITLE"), LLMsg("OPEN_API.UCOA_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.PROJECT_API_TITLE"), LLMsg("OPEN_API.PROJECT_API_DESC"), Constant.openApiSection.project)}
                            </div>
                        </div>
                        <div className={styles.apiGroup}>
                            <div className={styles.apiGroupTitle}>
                                {LLMsg("OPEN_API.OPEN_DATA_CATEGORY")}
                            </div>
                            <div className={"row"}>
                                {this.renderItem(LLMsg("OPEN_API.WEATHER_API_TITLE"), LLMsg("OPEN_API.WEATHER_API_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.CURRENCY_API_TITLE"), LLMsg("OPEN_API.CURRENCY_API_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.SCHOOL_API_TITLE"), LLMsg("OPEN_API.SCHOOL_API_DESC"), Constant.openApiSection.comingSoon)}
                            </div>
                        </div>
                        <div className={styles.apiGroup}>
                            <div className={styles.apiGroupTitle}>
                                {LLMsg("OPEN_API.GENERIC_DATA_STORE_APPLICATION_CATEGORY")}
                            </div>
                            <div className={"row"}>
                                {this.renderItem(LLMsg("OPEN_API.GENERIC_DATA_STORE_TITLE"), LLMsg("OPEN_API.GENERIC_DATA_STORE_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.ACCESS_CONTROL_TITLE"), LLMsg("OPEN_API.ACCESS_CONTROL_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.TIME_TRACKER_TITLE"), LLMsg("OPEN_API.TIME_TRACKER_DESC"), Constant.openApiSection.comingSoon)}
                                {this.renderItem(LLMsg("OPEN_API.CODE_FILE_STORAGE_TITLE"), LLMsg("OPEN_API.CODE_FILE_STORAGE_DESC"), Constant.openApiSection.comingSoon)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

