exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wq0_W3OfXcj2MfujdFcQ6 {\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n._33XZTm-Yq-BS4JOxQsizBA {\n    background-color: #fbfbfb;\n\n}\n\n._1d9sHd03dDDm1ietcN_sdx {\n    padding: 10px;\n    width: 200px;\n    border-right: 1px solid #eee;\n    border-bottom: 1px solid #eee;\n}\n\n._2UFPXxGDo7MPo_R_vSAxuY {\n    padding: 10px;\n    border-bottom: 1px solid #eee;\n}\n\n._2M_KsX8DDeZSuIBVct_zxS {\n    color: #716aca;\n    margin-left: 10px;\n}\n\n._3UX8Pa_Hs2Pufb9D3k0-d2 {\n    font-size: 20px;\n    padding-left: 15px;\n    color: #585661;\n}\n\n._24tEnl2VXq1re7XvDJPWhL {\n    font-weight: bold;\n    padding-left: 15px;\n    color: #8b8d97;\n\n}\n", ""]);

// exports
exports.locals = {
	"itemWrapper": "wq0_W3OfXcj2MfujdFcQ6",
	"tableWrapper": "_33XZTm-Yq-BS4JOxQsizBA",
	"itemLabel": "_1d9sHd03dDDm1ietcN_sdx",
	"itemValue": "_2UFPXxGDo7MPo_R_vSAxuY",
	"approvalStatusMark": "_2M_KsX8DDeZSuIBVct_zxS",
	"title": "_3UX8Pa_Hs2Pufb9D3k0-d2",
	"subTitle": "_24tEnl2VXq1re7XvDJPWhL"
};