// @flow

import {combineReducers} from "redux";
import ProjectReducer from "./ProjectReducer";
import UserReducer from "./UserReducer";
import TopicReducer from "./TopicReducer";
import ActionReducer from "./ActionReducer";

export default combineReducers({
    ActionReducer,
    ProjectReducer,
    UserReducer,
    TopicReducer,
});

type ReduxAction = {
    type: string,
    completed: boolean,
    statusCode?: number,
    payload?: ? Object
}

export type SimpleAction = {
    type: string,
    time: number
}

export const createReduxAction = (type: string, completed: boolean, data: ?Object, statusCode?: number): ReduxAction => ({
    type: type,
    completed: completed,
    statusCode: statusCode,
    payload: data
});

export const createAction = (type: string): SimpleAction => {
    return {type: type, time: new Date().valueOf()}
};