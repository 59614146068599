// @flow

import React, {Component} from 'react';
import ErrorUtil from "../../../../util/ErrorUtil";
import Api from "../../../../bundle/Api";
import type {User} from "../../../../model/model";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";
import styles from './index.css';
import AdminUserListFilter from './AdminUserListFilter';
import LoadingUtil from "../../../../util/LoadingUtil";

type AdminUserListFragmentState = {
    userList: Array<User>
}

class AdminUserListFragment extends Component<any, AdminUserListFragmentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            userList: []
        };
        this.filterByWhitelistUser = this.filterByWhitelistUser.bind(this);
        this.filterByLicenseTarget = this.filterByLicenseTarget.bind(this);
        this.filterByEventCode = this.filterByEventCode.bind(this);
    }

    filterByWhitelistUser = () => {

        LoadingUtil.showFullScreenLoading();
        Api.services.user.doGetListOfWhiteListedUser().then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({userList: response.data.users});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })

    };

    filterByLicenseTarget = (targetId: number) => {


        LoadingUtil.showFullScreenLoading();
        Api.services.user.doGetListOfUserByLicenseTargetId(targetId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({userList: response.data.users});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })
    };


    filterByEventCode = (eventCodeId: number) => {

        LoadingUtil.showFullScreenLoading();
        Api.services.user.doGetListOfUserByEventCodeId(eventCodeId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({userList: response.data.users});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })
    };

    render() {
        return <div className="m-portlet">
            <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                        <h3 className="m-portlet__head-text">
                            User List
                        </h3>
                    </div>
                </div>
            </div>
            <div className="m-portlet__body">
                <div className="m-section">
                    <div className="m-section__sub">
                        <AdminUserListFilter
                            filterByWhitelistUser={this.filterByWhitelistUser}
                            filterByEventCode={this.filterByEventCode}
                            filterByLicenseTarget={this.filterByLicenseTarget}
                        />
                    </div>
                    <div className="m-section__content">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>user email</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    this.state.userList.map((user: User, index: number) => {
                                        return <tr key={user.id}
                                                   className={styles.userRow}
                                                   onClick={() => {
                                                       RouterHistory().push(Page.internals.urls.user.admin.detail(user.id));
                                                   }}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{user.email}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default AdminUserListFragment;
