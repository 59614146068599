exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1p43JnXA87AywolqjhsjB7 {\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n._2XEOyZRvWW93mp7vH3qb0L {\n    background-color: #fbfbfb;\n\n}\n\n._1UrZus-nQPMHoCb4DIxIDQ {\n    padding: 10px;\n    width: 200px;\n    border-right: 1px solid #eee;\n    border-bottom: 1px solid #eee;\n}\n\n.TaYxAgCBTSbL90dljZ7E2 {\n    padding: 10px;\n    border-bottom: 1px solid #eee;\n}\n\n._39cdVG3S9LzVFEA_9ZVUdI {\n    cursor: pointer;\n    left: 0;\n}\n\n._39cdVG3S9LzVFEA_9ZVUdI:hover {\n    background-color: whitesmoke;\n}\n\n.xSrqhCD8jwxDUejGYbdeL {\n    font-size: 20px;\n    padding-left: 15px;\n    color: #585661;\n}\n\n.bF-RXoPNkfVXqFsS62PCS {\n    font-weight: bold;\n    padding-left: 15px;\n    color: #8b8d97;\n\n}", ""]);

// exports
exports.locals = {
	"itemWrapper": "_1p43JnXA87AywolqjhsjB7",
	"tableWrapper": "_2XEOyZRvWW93mp7vH3qb0L",
	"itemLabel": "_1UrZus-nQPMHoCb4DIxIDQ",
	"itemValue": "TaYxAgCBTSbL90dljZ7E2",
	"row": "_39cdVG3S9LzVFEA_9ZVUdI",
	"title": "xSrqhCD8jwxDUejGYbdeL",
	"subTitle": "bF-RXoPNkfVXqFsS62PCS"
};