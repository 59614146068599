// @flow

import React from 'react';
import styles from "./index.css";
import type {LicenseTarget} from "../../../../model/model";
import Api from "../../../../bundle/Api";
import ErrorUtil from "../../../../util/ErrorUtil";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";
import swal from "sweetalert2";
import {LLMsg} from "../../../../IntlCapture";
import {withRouter} from "react-router";
import LoadingUtil from "../../../../util/LoadingUtil";

type LicenseTargetUpdateFragmentState = {
    licenseTarget: LicenseTarget
}

class LicenseTargetUpdateFragment extends React.Component<any, LicenseTargetUpdateFragmentState> {

    packageNameRef: any;
    companyNameRef: any;

    state: LicenseTargetUpdateFragmentState = {
        licenseTarget: {}
    };

    constructor(props: any) {
        super(props);

        this.packageNameRef = React.createRef();
        this.companyNameRef = React.createRef();
    }

    componentDidMount() {
        this.getLicenseTargetDetail();
    }

    getLicenseTargetDetail = () => {
        const licenseTargetId = this.props.match && this.props.match.params["licenseTargetId"];
        if (licenseTargetId) {
            Api.services.license.getLicenseTargetDetail(licenseTargetId).then((response) => {
                if (response.status === 200) {
                    const licenseTarget: LicenseTarget = response.data;
                    this.setState({
                        licenseTarget
                    });
                    this.packageNameRef.value = licenseTarget.targetName;
                    this.companyNameRef.value = licenseTarget.companyName;
                }
            });
        }
    };

    create = () => {
        let packageName = this.packageNameRef.value;
        let companyName = this.companyNameRef.value;
        LoadingUtil.showFullScreenLoading();

        Api.services.license.createLicenseTarget(packageName, companyName).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.ADD_SUCCESS"), "", "success").then(() => {
                    RouterHistory().push(Page.internals.urls.license.adminHome());
                });
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    };

    update = () => {
        let packageName = this.packageNameRef.value;
        let companyName = this.companyNameRef.value;
        LoadingUtil.showFullScreenLoading();
        Api.services.license.updateLicenseTarget(this.state.licenseTarget.id, packageName, companyName).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success").then(() => {
                    RouterHistory().push(Page.internals.urls.license.licenseTargetDetailPage(this.state.licenseTarget.id));
                });
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    };

    render() {
        return <div>
            <div className={"mb-3 mt-3 p-3 " + styles.formWrapper}>

                <div className="m-portlet m-portlet--tab">
                    <form className="m-form m-form--fit m-form--label-align-right">
                        <div className="m-portlet__body pt-1">

                            <div className={styles.titleWrapper}>

                                <div className={styles.title}>
                                    {this.state.licenseTarget && this.state.licenseTarget.id ?
                                        "Update License Target" :
                                        "Create a new License Target"}
                                </div>
                                <div className={styles.subTitle}>
                                    License Target is the Package that included a Maker Cloud Trial License Key
                                </div>
                            </div>
                            <hr/>

                            {
                                this.state.licenseTarget && this.state.licenseTarget.id &&
                                <div className="form-group m-form__group row">
                                    <label htmlFor="example-text-input" className="col-2 col-form-label">
                                        Package ID
                                    </label>
                                    <div className="col-10">

                                        <input className="form-control m-input"
                                               value={this.state.licenseTarget.id}
                                               readOnly={true}
                                               type="text"/>
                                    </div>
                                </div>
                            }
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input" className="col-2 col-form-label">
                                    Package Name
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input"
                                           autoFocus={true}
                                           ref={(packageNameRef) => {
                                               this.packageNameRef = packageNameRef
                                           }} type="text"/>
                                </div>
                            </div>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-search-input" className="col-2 col-form-label">
                                    Company Name
                                </label>
                                <div className="col-10">
                                    <input
                                        className="form-control m-input"
                                        ref={(companyNameRef) => {
                                            this.companyNameRef = companyNameRef
                                        }} type="text"/>
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__foot m-portlet__foot--fit">
                            <div className="m-form__actions">
                                <div className="row">
                                    <div className="col-2">
                                    </div>
                                    <div className="col-10">
                                        {
                                            !(this.state.licenseTarget && this.state.licenseTarget.id) &&
                                            <button type="button" className="btn btn-success"
                                                    onClick={this.create}>Submit
                                            </button>
                                        }
                                        {
                                            this.state.licenseTarget && this.state.licenseTarget.id &&
                                            <button type="button" className="btn btn-info"
                                                    onClick={this.update}>Update
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(LicenseTargetUpdateFragment);