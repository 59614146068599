// @flow
import React from "react";

const StickyQuickNav = () => {
    if (true) {
        return null;
    }
    return <ul className="m-nav-sticky" style={{marginTop: 30}}>
        <li className="m-nav-sticky__item" data-toggle="m-tooltip" title="Purchase" data-placement="left">
            <a href="/"
               target="_blank"><i className="la la-cart-arrow-down"/></a>
        </li>
        <li className="m-nav-sticky__item" data-toggle="m-tooltip" title="Documentation"
            data-placement="left">
            <a href="/" target="_blank"><i
                className="la la-code-fork"/></a>
        </li>
        <li className="m-nav-sticky__item" data-toggle="m-tooltip" title="Support" data-placement="left">
            <a href="/" target="_blank"><i
                className="la la-life-ring"/></a>
        </li>
    </ul>;
};
export default StickyQuickNav;