// @flow

import React from 'react';
import styles from "./action.css";
import PublishMqttMessageActionFragment from "./PublishMqttMessageActionFragment";
import _ from 'lodash';
import {DataRecordType} from "../../../model/model";
import {LLMsg} from "../../../IntlCapture";
import TriggerIFTTTWebhookActionFragment from "./TriggerIFTTTWebhookActionFragment";
import Constant from "../../../bundle/Constant";
import HttpResponseActionFragment from "./HttpResponseActionFragment";
import GoogleSheetUpdateActionFragment from "./GoogleSheetUpdateActionFragment";


export type AppletAction = {
    type: string,
    topicName?: string,
    publishContent?: string,
    publishJsonContent?: string,
    iftttWebhookEvent?: string,
    iftttWebhookKey?: string,
    spreadsheetId?: string
}

type ActionFragmentState = {
    actionId: string,
    actions: ?Array<AppletAction>,
    actionRefs: Array<any>,
    showError: boolean
}
type ActionFragmentProps = {
    actionId: string,
    topics: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }>,
    actions: ?Array<AppletAction>
}

export const GOOGLE_SHEET_UPDATE_ACTION = "GOOGLE_SHEET_UPDATE_ACTION";
export const PUBLISH_MQTT_MESSAGE_ACTION = "PUBLISH_MQTT_MESSAGE_ACTION";
export const TRIGGER_IFTTT_WEBHOOK_ACTION = "TRIGGER_IFTTT_WEBHOOK_ACTION";
export const HTTP_RESPONSE_ACTION = "HTTP_RESPONSE_ACTION";

class ActionFragment extends React.Component<ActionFragmentProps, ActionFragmentState> {

    state = {
        actionId: "",
        actions: [],
        actionRefs: [],
        showError: false
    };

    constructor(props: ActionFragmentProps) {
        super(props);
        this.state = {
            actionId: props.actionId,
            actions: props.actions,
            actionRefs: [],
            showError: false
        };

        this.createPublishMqttMessageActionCallback = this.createPublishMqttMessageActionCallback.bind(this);
        this.createTriggerIFTTTWebbookActionCallback = this.createTriggerIFTTTWebbookActionCallback.bind(this);
        this.createGoogleSheetUpdateActionCallback = this.createGoogleSheetUpdateActionCallback.bind(this);
        this.createHttpResponseActionCallback = this.createHttpResponseActionCallback.bind(this);
    }

    createHttpResponseActionCallback = () => {
        let actions = this.state.actions || [];
        actions.push({
            type: HTTP_RESPONSE_ACTION,
            publishContent: ""
        });
        let actionRefs = this.state.actionRefs;
        actionRefs.push(React.createRef());
        this.setState({
            actions: actions,
            actionRefs: actionRefs
        });
    };

    createPublishMqttMessageActionCallback = () => {
        let actions = this.state.actions || [];
        actions.push({
            type: PUBLISH_MQTT_MESSAGE_ACTION,
            topicName: "",
            publishContent: ""
        });
        let actionRefs = this.state.actionRefs;
        actionRefs.push(React.createRef());
        this.setState({
            actions: actions,
            actionRefs: actionRefs
        });
    };

    createGoogleSheetUpdateActionCallback = () => {
        let actions = this.state.actions || [];
        actions.push({
            type: GOOGLE_SHEET_UPDATE_ACTION,
            spreadsheetId: "",
            publishContent: ""
        });
        let actionRefs = this.state.actionRefs;
        actionRefs.push(React.createRef());
        this.setState({
            actions: actions,
            actionRefs: actionRefs
        });
    };

    createTriggerIFTTTWebbookActionCallback = () => {
        let actions = this.state.actions || [];
        actions.push({
            type: TRIGGER_IFTTT_WEBHOOK_ACTION,
            publishJsonContent: JSON.stringify({value1: "", value2: "", value3: ""}),
            iftttWebhookEvent: "",
            iftttWebhookKey: ""
        });
        let actionRefs = this.state.actionRefs;
        actionRefs.push(React.createRef());
        this.setState({
            actions: actions,
            actionRefs: actionRefs
        });
    };

    componentDidUpdate() {
        this.updateAction(this.props.actionId, this.props.actions);
    }

    handleMessageValueChange = (message: string, index: number) => {

        let actions: Array<AppletAction> = this.state.actions || [];
        actions[index - 1].publishContent = message;
        this.setState({actions: actions});
    };

    handleIFTTTEventNameChange = (message: string, index: number) => {

        let actions: Array<AppletAction> = this.state.actions || [];
        actions[index - 1].iftttWebhookEvent = message;
        this.setState({actions: actions});
    };

    handleIFTTTKeyChange = (message: string, index: number) => {

        let actions: Array<AppletAction> = this.state.actions || [];
        actions[index - 1].iftttWebhookKey = message;
        this.setState({actions: actions});
    };

    handleIFTTTJsonContentChange = (message: string, index: number) => {

        let actions: Array<AppletAction> = this.state.actions || [];
        let content = "";
        try {
            content = JSON.stringify(JSON.parse(message))
        } catch (e) {
            content = message;
        }
        actions[index - 1].publishJsonContent = content;
        this.setState({actions: actions});
    };

    handleTopicNameChange = (message: string, index: number) => {
        let actions: Array<AppletAction> = this.state.actions || [];
        actions[index - 1].topicName = message;
        this.setState({actions: actions});
    };

    handleSpreadsheetIdChange = (message: string, index: number) => {
        let actions: Array<AppletAction> = this.state.actions || [];
        actions[index - 1].spreadsheetId = message;
        this.setState({actions: actions});
    };

    updateAction(actionId: string, actions: ?Array<AppletAction>) {
        if (this.state.actionId !== actionId) {
            this.setState({actionId: actionId, actions: actions, showError: false});
        }
    }

    validateActionData = (): boolean => {
        let valid: boolean = true;
        console.log("action=", this.state.actions);
        if (!this.state.actions || this.state.actions.length === 0) {
            valid = false;
        } else {
            const actions = this.state.actions;
            if (_.compact(actions).length === 0) {
                valid = false;
            }
            this.state.actionRefs.forEach((ref: any) => {
                if (ref && ref.validateAction && !ref.validateAction()) {
                    valid = false;
                }
            });
        }

        this.setState({showError: !valid});
        return valid;
    };

    getActionData = (): ?Array<AppletAction> => {
        return _.compact(this.state.actions);
    };

    onDeleteCallback = (index: number) => {
        let actions: Array<AppletAction> = this.state.actions || [];
        let actionRefs: Array<any> = this.state.actionRefs || [];
        delete actions[index - 1];
        delete actionRefs[index - 1];
        this.setState({actions: actions});
    };

    render() {

        let actionIndex = 1;
        let actions = this.state.actions ? this.state.actions.map((action: AppletAction, index: number) => {
            if (!action) {
                return null;
            } else if (action.type === PUBLISH_MQTT_MESSAGE_ACTION) {
                return <PublishMqttMessageActionFragment key={index}
                                                         displayIndex={actionIndex++}
                                                         index={index + 1}
                                                         ref={(ref) => {
                                                             this.state.actionRefs[index] = ref
                                                         }}
                                                         topics={this.props.topics}
                                                         topicName={action.topicName}
                                                         onDeleteCallback={this.onDeleteCallback}
                                                         handleTopicNameChange={this.handleTopicNameChange}
                                                         handleMessageValueChange={this.handleMessageValueChange}
                                                         publishContent={action.publishContent}/>
            } else if (action.type === TRIGGER_IFTTT_WEBHOOK_ACTION) {
                return <TriggerIFTTTWebhookActionFragment key={index}
                                                          displayIndex={actionIndex++}
                                                          index={index + 1}
                                                          ref={(ref) => {
                                                              this.state.actionRefs[index] = ref
                                                          }}
                                                          onDeleteCallback={this.onDeleteCallback}
                                                          handleIFTTTJsonContentChange={this.handleIFTTTJsonContentChange}
                                                          handleIFTTTEventNameChange={this.handleIFTTTEventNameChange}
                                                          handleIFTTTKeyChange={this.handleIFTTTKeyChange}
                                                          iftttWebhookEvent={action.iftttWebhookEvent}
                                                          iftttWebhookKey={action.iftttWebhookKey}
                                                          publishJsonContent={action.publishJsonContent}/>
            } else if (action.type === HTTP_RESPONSE_ACTION) {
                return <HttpResponseActionFragment key={index}
                                                   displayIndex={actionIndex++}
                                                   index={index + 1}
                                                   ref={(ref) => {
                                                       this.state.actionRefs[index] = ref
                                                   }}
                                                   topics={this.props.topics}
                                                   onDeleteCallback={this.onDeleteCallback}
                                                   handleMessageValueChange={this.handleMessageValueChange}
                                                   publishContent={action.publishContent}/>
            } else if (action.type === GOOGLE_SHEET_UPDATE_ACTION) {
                return <GoogleSheetUpdateActionFragment key={index}
                                                        displayIndex={actionIndex++}
                                                        index={index + 1}
                                                        ref={(ref) => {
                                                            this.state.actionRefs[index] = ref
                                                        }}
                                                        topics={this.props.topics}
                                                        onDeleteCallback={this.onDeleteCallback}
                                                        handleSpreadsheetIdChange={this.handleSpreadsheetIdChange}
                                                        spreadsheetId={action.spreadsheetId}/>
            }

        }) : [];
        return <div>
            {
                this.state.showError && <div
                    className="m-alert m-alert--icon mt-4 m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show"
                    role="alert">
                    <div className="m-alert__icon"
                         style={{backgroundColor: Constant.theme.eventTrigger.action.color}}>
                        <i className="flaticon-exclamation-1"/>
                        <span
                            style={{borderColor: Constant.theme.eventTrigger.action.color}}/>
                    </div>
                    <div className="m-alert__text" style={{color: Constant.theme.eventTrigger.action.color}}>
                        <strong>
                            {LLMsg("EVENT_TRIGGER.VALIDATION_FAILED")}
                        </strong>
                        {LLMsg("EVENT_TRIGGER.ACTION_VALIDATION_FAILED")}
                    </div>
                </div>
            }
            <div className={styles.actionItemWrapper}>
                {actions}
            </div>
        </div>;
    }
}

export default ActionFragment;