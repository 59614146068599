// @flow

import React from 'react';
import Api from "../../../bundle/Api";
import swal from "sweetalert2";
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import {createAction} from "../../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../../IntlCapture";


type RenameTopicButtonProps = {

    projectId: string,
    projectName: string,

    dispatchProjectNameUpdated?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}

const changeProjectNamePrompt = (projectId: string, projectName: string, dispatchProjectUpdatedCallback: ?any) => {

    if (!projectId || !projectName) {
        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        return;
    }

    swal({
        title: LLMsg("COMMON.PROJECT.UPDATE_PROJECT"),
        input: 'text',
        inputValue: projectName,
        inputPlaceholder: LLMsg("COMMON.PROJECT.PROJECT_NAME"),
        showCancelButton: true,
        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
        cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value) {
                    resolve();
                } else {
                    resolve(LLMsg("COMMON.PROJECT.MISSING_PROJECT_NAME"));
                }
            })
        }
    }).then((e) => {
        if (e.dismiss) {
            return;
        }
        if (e.value) {
            Api.services.project.update.name(projectId, e.value).then(response => {
                if (response.status === 200) {
                    swal("", LLMsg("COMMON.PROJECT.UPDATE_SUCCESS"), "success");
                    dispatchProjectUpdatedCallback && dispatchProjectUpdatedCallback();
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch(() => {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            });
        } else {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        }
    });

};

const RenameProjectButton = (props: RenameTopicButtonProps) => {
    let projectId = props.projectId;
    let projectName = props.projectName;

    return <button
        className={"btn btn-primary btn-sm m-btn mr-2"}
        onClick={() => {
            changeProjectNamePrompt(projectId, projectName, props.dispatchProjectNameUpdated);
        }}>

        <i className="m-nav__link-icon flaticon-edit mr-2"/>
        <span className="m-nav__link-text">{LLMsg("COMMON.ACTION.RENAME")}</span>
    </button>;
};

const mapDispatchToProps = dispatch => ({
    dispatchProjectNameUpdated: () => dispatch(createAction(Api.actions.project.PROJECT_UPDATED))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(RenameProjectButton));