// @flow

export type ToObject = {
    pathname: string,
    search?: string
}


class PageUrl {
    static ROOT_URL = "/";
    static HOME_URL = "/home";
    static DEMO_URL = "/demo";
    static STATUS_DOWN_URL = "/service-unavailable";
    static WHATS_NEW_URL = "/whats-new";

    static LOGIN_URL = "/user/login";
    static LICENSED_USER_REGISTER_URL = "/user/register/register-with-license";
    static GUEST_REGISTER_URL = "/user/register/guest";
    static SCHOOL_REGISTER_URL = "/user/register/school";
    static REGISTER_URL = "/user/register";
    static DIRECT_LOGIN_ACCESS_URL = "/user/login/direct";
    static SETUP_PASSWORD_FOR_NEW_ACCOUNT_URL = "/user/setup-password";
    static RESET_PASSWORD_URL = "/user/reset-password";
    static CHANGE_ACCOUNT_PASSWORD_URL = "/user/change-password";
    static LOGOUT_URL = "/user/logout";

    // Projects
    static PROJECT_URL = "/project";
    static PROJECT_DETAIL_URL = "/project/:projectId/:projectName";
    static PROJECT_TRIGGER_URL = "/project/applet/:projectId/:projectName";
    static PROJECT_TRIGGER_DETAIL_URL = "/project/:projectId/applet/:appletId";
    static PROJECT_CREATE_URL = "/project/create";
    static PROJECT_JOIN_BY_TOKEN_URL = "/project/join/token/:token";
    static PROJECT_MAP_EMBEDDED_URL = "/project/map/embedded/token/:token";

    // School
    static SCHOOL_URL = "/admin/school";
    static SCHOOL_DETAIL_URL = "/admin/school/:schoolId/:schoolName";

    // Topics
    static TOPIC_URL = "/topic";
    static TOPIC_CREATE_URL = "/topic/create";
    static TOPIC_DETAIL_URL = "/topic/:topicId/:topicDescription";

    // Dashboard
    static DASHBOARD_DEFAULT_URL = "/dashboards";
    static DASHBOARD_URL = "/dashboard/:dashboardId/:dashboardName";

    // Charts
    static CHARTS_LINE_CHART_URL = "/charts/line-chart";
    static CHARTS_CREATE_URL = "/charts/create";
    static CHARTS_DETAIL_URL = "/charts/:chartId/:chartName";
    static CHART_EMBEDDED_URL = "/chart/embedded/token/:token";


    // License
    static LICENSE_MANAGEMENT_HOME_URL = "/license/home";
    static LICENSE_DETAIL_URL = "/license/detail/:licenseKey";
    static LICENSE_DETAIL_QUERY_URL = "/license/detail";
    static ADMIN_LICENSE_MANAGEMENT_HOME_URL = "/admin/license/home";
    static ADMIN_LICENSE_TARGET_CREATE_URL = "/admin/license/target/create-license-target";
    static ADMIN_LICENSE_TARGET_UPDATE_URL = "/admin/license/target/:licenseTargetId/update-license-target-:licenseTargetId";
    static ADMIN_LICENSE_TARGET_DETAIL_URL = "/admin/license/target/:licenseTargetId/license-target-:licenseTargetId";
    static ADMIN_LICENSE_BATCH_CREATE_URL = "/admin/license/create-license-batch";
    static ADMIN_LICENSE_BATCH_DETAIL_URL = "/admin/license/license-batch-:licenseBatchId";
    static ADMIN_EVENT_CODE_LIST_URL = "/admin/eventCode/list";
    static ADMIN_EVENT_CODE_CREATE_URL = "/admin/eventCode/create-event-code";
    static ADMIN_EVENT_CODE_DETAIL_URL = "/admin/eventCode/event-code-:eventCodeId";
    static ADMIN_USER_LIST_URL = "/admin/user/list";
    static ADMIN_WHITELIST_USER_LIST_URL = "/admin/user/whitelist-user/list";
    static ADMIN_USER_DETAIL_URL = "/admin/user/user-:userId";
    static ADMIN_MAINTENANCE_PERIOD_LIST_URL = "/admin/maintenance-period/list";

    // Virtual Smart Home
    static VIRTUAL_SMART_HOME_LIGHT_BULB_URL = "/virtual/light-bulb"

    // public resources
    static RESOURCE_URL = "/resource";
    static OPEN_API_WEATHER = "/resource/open-api/weather";

    static PRIVACY_URL = "/static/:language/privacy/content.html";
    static COPYRIGHT_URL = "/static/:language/copyright/content.html";
    static DISCLAIMER_URL = "/static/:language/disclaimer/content.html";
    static TERMS_URL = "/static/:language/terms/content.html";
    static THIRD_PARTY_USE_URL = "/static/en/thirdParty/content.html";

    static SCALE_HOME_URL = "https://www.scaleinnotech.com";
    static SCALE_CONTACT_US_URL = "https://www.scaleinnotech.com/contact-us";
}

export default PageUrl;