// @flow

import React from 'react';
import styles from './index.css';
import Constant from "../../../bundle/Constant"
import OpenApi from "../../../bundle/OpenApi";
import HttpUtil from "../../../util/HttpUtil";
import * as _ from 'lodash';
import Config from "../../../config/Config";
import type {Project} from "../../../model/model";

type Props = {
    openApiType: $Values<typeof Constant.openApiSection>,
    project: Project
}
const SwaggerUIBundle = window.SwaggerUIBundle;

export default class OpenApiDetailFragment extends React.PureComponent<Props, State> {

    componentDidMount() {

        let openApiType = this.props.openApiType;
        let swaggerUrl;
        if (openApiType === Constant.openApiSection.imageRecognition) {
            swaggerUrl = OpenApi.urls.getImageRecognition();
        } else if (openApiType === Constant.openApiSection.imageClassification) {
            swaggerUrl = OpenApi.urls.getImageClassification();
        } else if (openApiType === Constant.openApiSection.project) {
            swaggerUrl = OpenApi.urls.getProject();
        }

        HttpUtil.ajax(swaggerUrl, "GET", {
            'Content-Type': 'application/json',
        }).then(r => {
            let spec = r.data;
            _.set(spec, "servers[0].variables.openApiToken.default", this.props.project.openApiKey);
            let openApiEndpoint = Config.OPEN_API_ENDPOINT.replace("{basePath}", _.get(spec, "servers[0].variables.basePath.default", ""));
            _.set(spec, "servers[0].url", openApiEndpoint);
            _.set(spec, "servers[0].variables.basePath", undefined);

            SwaggerUIBundle({
                dom_id: '#swaggerUi',
                docExpansion: 'list',
                spec: r.data
            });
        });


    }

    render() {

        let openApiType = this.props.openApiType;
        let isComingSoon = false;
        if (openApiType === Constant.openApiSection.imageRecognition) {
        } else if (openApiType === Constant.openApiSection.imageClassification) {
        } else if (openApiType === Constant.openApiSection.project) {
        } else {
            isComingSoon = true;
        }

        return <div className={"row"}>
            <div className={"col-12 p-2"}>
                <div className="m-portlet mb-0">
                    <div className={"m-portlet__body"}>
                        <div className={styles.wrapper}>
                            {isComingSoon && <h1 className={"text-center"}>Coming Soon!</h1>}
                            <div id={"swaggerUi"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

