// @flow
import React from "react";

const BodyWrapper = (props: any) => {
    return <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        {props.children}
    </div>;
};

export default BodyWrapper;