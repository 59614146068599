// @flow

import React from "react";
import Page from "../../bundle/Page";
import {LLMsg} from "../../IntlCapture";

const CopyrightFragment = () => {
    return <div
        className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                        <span className="m-footer__copyright">
                            {(new Date()).getFullYear()}{" © "}
                            <a href={Page.externals.scaleHome()}
                               className="m-link">
                                {LLMsg("COMMON.FOO.SCALE")}
                            </a>
                        </span>
    </div>

};

export default CopyrightFragment;