// @flow

import React from 'react';
import type {Project, Topic} from "../../../model/model";
import * as PubSub from "pubsub-js";
import Api from "../../../bundle/Api";
import styles from "./index.css";
import Stats from "../../content/Stats";
import {ClassNames} from "../../../bundle/ClassNames";
import {LLMsg} from "../../../IntlCapture";
import Image from "../../../bundle/Image";
import UserDao from "../../../dao/UserDao";
import ErrorUtil from "../../../util/ErrorUtil";
import TopicMessageSendingToolFragment from "../TopicMessageSendingToolFragment";
import {createAndLinkTopicToProjectPrompt} from "../CreateAndLinkTopicToProjectButton";
import {createAction} from "../../../redux/reducer";
import {withRouter} from "react-router";
import connect from "react-redux/lib/connect/connect";

type Props = {
    project: Project,
    dispatchTopicInProjectUpdated: Function
}
type State = {
    dataCount: number
}

class TopicOverviewStatsFragment extends React.Component <Props, State> {

    state = {dataCount: 0};

    subToken: any;

    renderStats(title: string, content: string | number) {
        return <div key={title} className={ClassNames(styles.stats, "col")}>
            <span className={styles.statsValue}>{content}</span>
            <span className={styles.statsTitle}>{title}</span>
        </div>
    }

    renderStatsSection(title: string, stats: Array<any>, sectionStyleClassName: any) {
        return <div className={ClassNames("col-12 col-lg-6 p-2", styles.sectionHeight)}>
            <div className={ClassNames("m-portlet mb-0", styles.fullHeight)}>
                <div className={"m-portlet__body"}>
                    <div className={ClassNames("", styles.statsSection, sectionStyleClassName)}>
                        <div className={"row"}>
                            {stats}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    renderButton(src: any, label: string, onClick: Function) {
        return <div className={ClassNames(styles.button, "col", styles.fullHeight)} onClick={onClick}>
            <div className={styles.buttonText}>
                <img src={src}
                     className={styles.buttonIcon}/>
            </div>
            <div className={styles.buttonText}>
                {label}
            </div>
        </div>;
    }

    componentDidMount(): * {

        let project: Project = this.props.project;
        let totalData = 0;
        project.topics.forEach((topic: Topic) => {
            totalData += topic.totalNumberOfDataRecords;
        })

        this.setState({dataCount: totalData});

        this.subToken = PubSub.subscribe(Api.actions.topic.TOPIC_NEW_DATA_ACTION_TYPE, () => {
            this.setState({dataCount: this.state.dataCount + 1});
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subToken);
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        if (prevProps.project.id !== this.props.project.id) {
            let project: Project = this.props.project;
            let totalData = 0;
            project.topics.forEach((topic: Topic) => {
                totalData += topic.totalNumberOfDataRecords;
            })
            this.setState({dataCount: totalData});
        }
    }

    render() {

        let dataTypeCount = 0;
        this.props.project.topics.forEach((topic: Topic, index: number) => {
            dataTypeCount += topic.dataRecordTypes.length;
        })

        return <div className={ClassNames("row")}>
            {
                this.renderStatsSection(
                    "物聯網總覽",
                    [
                        this.renderStats(LLMsg("COMMON.PROJECT.NUMBER_OF_TOPIC"), this.props.project.topics.length),
                        this.renderStats(LLMsg("COMMON.PROJECT.TOTAL_NUMBER_OF_DATA"), this.state.dataCount),
                        this.renderStats(LLMsg("COMMON.PROJECT.NUMBER_OF_DATA_TYPE"), dataTypeCount)
                    ],
                    styles.style3
                )
            }
            <div className={ClassNames("col-4 col-lg-2 p-2", styles.sectionHeight)}>

                <TopicMessageSendingToolFragment>
                    {
                        this.renderButton(
                            Image.urls.getPublishDataImage(),
                            LLMsg("EVENT_TRIGGER.PUBLISH_MQTT_MESSAGE"),
                            undefined
                        )
                    }
                </TopicMessageSendingToolFragment>

            </div>
            <div className={ClassNames("col-4 col-lg-2 p-2", styles.sectionHeight)}>
            {
                    this.renderButton(
                        Image.urls.getCreateTopicIcon(),
                        LLMsg("COMMON.TOPIC.CREATE_TOPIC"),
                        () => {
                            createAndLinkTopicToProjectPrompt(this.props.project.id, this.props.dispatchTopicInProjectUpdated);
                        }
                    )
                }
            </div>
            <div className={ClassNames("col-4 col-lg-2 p-2", styles.sectionHeight)}>
                {
                    this.renderButton(
                        Image.urls.getExportProjectDataIcon(),
                        LLMsg("COMMON.PROJECT.DOWNLOAD_DATA_UNDER_PROJECT_TAB"),
                        () => {
                            if (UserDao.permission.allowExportData) {
                                Api.services.record.download.dataRecordByProjectId(this.props.project.id, this.props.project.projectName);
                            } else {
                                ErrorUtil.showPermissionNotAllowed();
                            }
                        }
                    )
                }
            </div>
        </div>;
    }

}


const mapDispatchToProps = dispatch => ({
    dispatchTopicInProjectUpdated: () => dispatch(createAction(Api.actions.project.PROJECT_UPDATED))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(TopicOverviewStatsFragment));