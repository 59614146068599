// @flow

import React from 'react';
import styles from './index.css';
import {ClassNames} from "../../../../bundle/ClassNames";
import {LLMsg} from "../../../../IntlCapture";
import Api from "../../../../bundle/Api";
import type {LearningLink} from "../../../../model/model";
import DataStore from "../../../../store/DataStore";
import Constant from "../../../../bundle/Constant";

type State = {
    learnLinks: Array<LearningLink>
}

export default class ProjectTutorialLinkFragment extends React.Component<any, State> {
    state = {learnLinks: []}

    componentDidMount() {
        Api.services.backendStatusService.getLearningLinks()
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        learnLinks: response.data
                    })
                }
            })
    }

    renderLink(url: string, label: string, key: number) {
        return <li className={styles.item} key={key}>
            <a href={url} target={"_blank"}>
                {label}
            </a>
        </li>;
    }

    render() {

        let languagePostfix;
        let currentLanguage = DataStore.cookies.get(Constant.cookies.language);
        if (currentLanguage === Constant.language.english) {
            languagePostfix = "En";
        } else if (currentLanguage === Constant.language.traditionalChinese) {
            languagePostfix = "Tc";
        } else if (currentLanguage === Constant.language.simplifiedChinese) {
            languagePostfix = "Sc";
        } else {
            languagePostfix = 'Tc';
        }

        return <div className={ClassNames("m-portlet mb-0", styles.wrapper)}>
            <div className={ClassNames("m-portlet__body", styles.content)}>
                <div className={styles.title}>{LLMsg("COMMON.PROJECT.EXPLORE_AND_LEARN")}</div>
                <ol className={styles.ol}>
                    {
                        this.state.learnLinks.map((learnLink: LearningLink) => {
                            return this.renderLink(learnLink["url" + languagePostfix], learnLink["title" + languagePostfix], learnLink.id)
                        })
                    }
                </ol>
            </div>
        </div>;
    }
}