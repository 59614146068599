// @flow

import React, {Component} from "react";
import Constant from "../../bundle/Constant";
import swal from "sweetalert2";
import Api from "../../bundle/Api";
import {createAction} from "../../redux/reducer";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import type {Match} from "../../App";
import {LLMsg} from "../../IntlCapture";
import styles from "./DeleteDataRecordTypeButton.css";

type DeleteDataRecordTypePromptProps = {

    topicId: string,
    dataTypeId: string,

    dispatchDataTypeUpdated?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}


class DeleteDataRecordTypeButton extends Component <DeleteDataRecordTypePromptProps, any> {

    deleteDataRecordTypePrompt = (topicId: string, dataTypeId: string, dispatchDataTypeCreatedCallback: ? any) => {

        swal({
            text: LLMsg("COMMON.DATA_TYPE.DELETE_DATA_TYPE_HELP_TEXT"),
            type: 'info',
            showCancelButton: true,
            confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
            cancelButtonText: LLMsg("COMMON.ACTION.CANCEL")
        }).then((e) => {
            if (e.dismiss) {
                return;
            }
            if (e.value) {
                Api.services.topic.update.deleteDataType(topicId, dataTypeId).then(response => {
                    if (response.status === 200) {
                        swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success");
                        dispatchDataTypeCreatedCallback && dispatchDataTypeCreatedCallback();
                    } else {
                        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                    }
                }).catch(() => {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                });
            } else {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            }
        });
    };

    render() {
        return <button className={"btn btn-secondary m-btn m-btn--icon m-btn--icon-only btn-sm"}
                       onClick={() => {
                           this.deleteDataRecordTypePrompt(this.props.topicId, this.props.dataTypeId, this.props.dispatchDataTypeUpdated);
                       }}>
            <i className={Constant.theme.delete.icon}/>
        </button>
    }
}

const mapDispatchToProps = dispatch => ({
    dispatchDataTypeUpdated: () => dispatch(createAction(Api.actions.dataType.UPDATE_DATA_TYPE_ACTION))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(DeleteDataRecordTypeButton));
