// @flow
import React from 'react';
import DeleteChartDropdownButton from "./DeleteChartDropdownButton";
import AddChartToAnyDashboardDropdownButton from "./AddChartToAnyDashboardDropdownButton";
import ViewChartDetailButtonFragment from "./ViewChartDetailButtonFragment";
import RenameChartDropdownButton from "./RenameChartDropdownButton";

type ChartDropdownFragmentProps = {
    chartId: string,
    chartType: string,
    chartName: string,
    isDefaultChart: boolean
}

const ChartDropdownFragment = (props: ChartDropdownFragmentProps) => {
    return <div
        className="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
        m-dropdown-toggle="hover" aria-expanded="true">
        <a href="#" className="m-portlet__nav-link m-dropdown__toggle btn m-btn no-a-effect">
            <i className="fa fa-ellipsis-h" style={{fontWeight: 900}}/>
        </a>
        <div className="m-dropdown__wrapper" style={{zIndex: 101}}>
            <span className="m-dropdown__arrow m-dropdown__arrow--left m-dropdown__arrow--adjust"
                  style={{right: 'auto', left: '24.5px'}}/>
            <div className="m-dropdown__inner">
                <div className="m-dropdown__body">
                    <div className="m-dropdown__content">
                        <ul className="m-nav">
                            <RenameChartDropdownButton chartId={props.chartId} chartName={props.chartName}/>
                            <AddChartToAnyDashboardDropdownButton chartId={props.chartId} chartType={props.chartType}/>
                            <ViewChartDetailButtonFragment chartId={props.chartId} chartName={props.chartName}/>
                            {!props.isDefaultChart && <DeleteChartDropdownButton chartId={props.chartId}/>}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default ChartDropdownFragment;