// @flow

import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";
import UrlBuilder from "../UrlBuilder";

export default class LicenseApiUrlHelper {

    static downloadLicenseFile(filename: string): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.LICENSE_FILE_DOWNLOAD_API, [{
            key: ":filename",
            value: filename
        }])
    }

    static LicenseList(): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.ADMIN_LICENSE_LISTING_API, []);
    }

    static batchCreate(): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.ADMIN_LICENSE_BATCH_CREATE_API, []);
    }

    static overallInfo(): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.ADMIN_LICENSE_OVERALL_INFO_API, []);
    }

    static licenseTargetList(): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.ADMIN_LICENSE_TARGET_LISTING_API, []);
    }

    static licenseTargetDetail(licenseTargetId: number): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.ADMIN_LICENSE_TARGET_DETAIL_API, [{
            key: ":licenseTargetId",
            value: licenseTargetId + ""
        }]);
    }

    static licenseBatchDetail(licenseBatchId: string): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.ADMIN_LICENSE_BATCH_DETAIL_API, [{
            key: ":licenseBatchId",
            value: licenseBatchId
        }]);
    }

    static createLicenseTarget(): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.ADMIN_LICENSE_TARGET_CREATE_API, []);
    }

    static consumeLicense(): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.LICENSE_CONSUME_API, []);
    }

    static getLicenseDetail(licenseKey: string): string {

        let queryString = "?" + UrlBuilder.toQueryString({"licenseKey": licenseKey});
        return Config.LICENSE_API_MAKER_CLOUD + Api.paths.LICENSE_GET_API + queryString;
    }

    static updateLicenseTarget(): string {
        return StringUtil.replaceAllText(Config.LICENSE_API_MAKER_CLOUD + Api.paths.ADMIN_LICENSE_TARGET_UPDATE_API, []);
    }

}