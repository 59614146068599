import React, {Component} from 'react';
import {LLMsg} from "../../../IntlCapture";
import Constant from "../../../bundle/Constant";
import swal from "sweetalert2";
import styles from "./index.css"

type VirtualSmartHomeSettingFragmentProps = {
    topicName: string,
    dataKey: string,
    turnOn: string,
    turnOff: string,
    topicNameError: string,
    callback: (topicName: string,
               dataKey: string,
               turnOn: string,
               turnOff: string) => void
}

type VirtualSmartHomeSettingFragmentState = {
    topicName: string,
    dataKey: string,
    turnOn: string,
    turnOff: string
}

class VirtualSmartHomeSettingFragment extends Component<VirtualSmartHomeSettingFragmentProps, VirtualSmartHomeSettingFragmentState> {
    constructor(props) {
        super(props);
        this.state = {
            topicName: this.props.topicName,
            dataKey: this.props.dataKey,
            turnOn: this.props.turnOn,
            turnOff: this.props.turnOff
        }
    }

    onTopicNameChanged = (e: any) => {
        this.setState({
            topicName: e.target.value
        });
    }

    onDataKeyChanged = (e: any) => {
        this.setState({
            dataKey: e.target.value
        });
    }

    onTurnOnChanged = (e: any) => {
        this.setState({
            turnOn: e.target.value
        });
    }

    onTurnOffChanged = (e: any) => {
        this.setState({
            turnOff: e.target.value
        });
    }

    onSave = () => {
        const topicName = this.state.topicName;
        const dataKey = this.state.dataKey;
        const turnOn = this.state.turnOn;
        const turnOff = this.state.turnOff;

        let hasError = false;
        if (!topicName) {
            hasError = true;
        }
        if (!dataKey) {
            hasError = true;
        }
        if (!turnOn) {
            hasError = true;
        }
        if (!turnOff) {
            hasError = true;
        }
        if (hasError) {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.FILL_IN_ALL_DATA"), "warning");
            return;
        }

        this.props.callback(
            topicName,
            dataKey,
            turnOn,
            turnOff
        )
    }

    render() {
        return <div className="m-portlet mb-0">
            <div className="m-portlet__body">
                <form className="m-form m-form--fit m-form--label-align-right text-left">
                    <h1 style={{color:Constant.theme.setting.color}} className="mb-3 m--icon-font-size-lg5 text-center">
                        <i className="{Constant.theme.setting.icon} fa-1x mr-1"></i>
                        <span>{LLMsg("COMMON.VIRTUAL.HARDWARE_SETTING")}</span>
                    </h1>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group m-form__group pb-0 row">
                                <label className="m--icon-font-size-lg1 col-sm-4 col-form-label">{LLMsg("COMMON.TOPIC.TOPIC_NAME")}</label>
                                <div className="col-sm-8">
                                    <input name="topicName" type="text" className={"form-control m-input m-input--square " + (this.props.topicNameError != "" ? "is-invalid" : "")}
                                           placeholder={LLMsg("COMMON.TOPIC.TOPIC_NAME")} defaultValue={this.props.topicName}
                                           onChange={this.onTopicNameChanged}/>
                                    <div className="invalid-feedback">
                                        {this.props.topicNameError}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group m-form__group pb-0 row">
                                <label className="m--icon-font-size-lg1 col-sm-4 col-form-label"> {LLMsg("COMMON.DATA_TYPE.DATA_TYPE_KEY")}</label>
                                <div className="col-sm-8">
                                    <input name="dataKey" type="text" className="form-control m-input m-input--square"
                                           placeholder={LLMsg("COMMON.DATA_TYPE.KEY_PLACEHOLDER")} defaultValue={this.props.dataKey}
                                           onChange={this.onDataKeyChanged}/>
                                </div>
                            </div>
                            <div className="form-group m-form__group pb-0 row">
                                <label className="m--icon-font-size-lg1 col-sm-4 col-form-label">{LLMsg("COMMON.VIRTUAL.TURN_ON")}</label>
                                <div className="col-sm-8">
                                    <input name="turnOn" type="text" className="form-control m-input m-input--square"
                                           placeholder={LLMsg("COMMON.VIRTUAL.TURN_ON_PLACEHOLDER")} defaultValue={this.props.turnOn}
                                           onChange={this.onTurnOnChanged}/>
                                </div>
                            </div>
                            <div className="form-group m-form__group pb-0 row">
                                <label className="m--icon-font-size-lg1 col-sm-4 col-form-label">{LLMsg("COMMON.VIRTUAL.TURN_OFF")}</label>
                                <div className="col-sm-8">
                                    <input name="turnOff" type="text" className="form-control m-input m-input--square"
                                           placeholder={LLMsg("COMMON.VIRTUAL.TURN_OFF_PLACEHOLDER")} defaultValue={this.props.turnOff}
                                           onChange={this.onTurnOffChanged}/>
                                </div>
                            </div>
                            <div className="form-group m-form__group pb-0 row">
                                <div className="col-sm-12">
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary m-btn"
                                                onClick={()=>{
                                                    this.onSave();
                                                }}>
                                            {LLMsg("COMMON.ACTION.SAVE")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    }
}

export default VirtualSmartHomeSettingFragment;