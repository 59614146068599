// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";

export default class OpenApiService {

    static getWeatherAvailableOption(): Object {
        return HttpUtil.ajax(Api.urls.openApi.getAvailableWeatherOption(), "GET", {
            'Content-Type': 'application/json',
        });
    }

}