exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._26EizX20x2y4XYLIqCRo6T {\n    position: relative;\n}\n\n._3BTzm4aqo9TDOUfFMBK8-R {\n    position: absolute;\n    left: 0;\n}\n\n.Uyb5mKIh0P_VaRlPxKS1y {\n}\n\n\n._2T25vKoFBP1PTMHJguJjsN {\n    width: 100%;\n    padding: 25px;\n    overflow-wrap: break-word;\n}\n\n._2XvUn2CZvWucTS6W9K5SvV {\n    font-size: 25px;\n    font-weight: bolder;\n    min-height: 150px;\n    text-wrap: normal;\n}\n\n\n._3dXxqYW3-JkD480sTrgNza {\n    padding: 5px 15px;\n    cursor: pointer;\n    display: inline-block;\n    color: grey;\n}\n\n._3dXxqYW3-JkD480sTrgNza i {\n    font-size: 25px;\n}\n\n._2M4B5Xzv7uiJLGvZK1Heuw {\n    font-size: 20px;\n    margin-bottom: 10px;\n    margin-left: 5px;\n}\n\n._1Xd0awShfa4LwNr-Td0-Ya {\n    border: 1px solid grey;\n    border-radius: 15px;\n    padding: 15px;\n    margin-bottom: 15px;\n}\n\n.b5P-K8feLsyfYPBTNui9X {\n    display: inline-block;\n    padding: 10px 20px;\n    background-color: lightgrey;\n    margin-right: 10px;\n    border-radius: 15px;\n    margin-bottom: 10px;\n    color: darkslategrey;\n    font-size: 20px;\n    cursor: pointer;\n}\n\n.b5P-K8feLsyfYPBTNui9X.weIlap-V9g77rYAmNtmaB {\n    border: 2px solid darkgray;\n}", ""]);

// exports
exports.locals = {
	"box": "_26EizX20x2y4XYLIqCRo6T",
	"canvas": "_3BTzm4aqo9TDOUfFMBK8-R",
	"video": "Uyb5mKIh0P_VaRlPxKS1y",
	"result": "_2T25vKoFBP1PTMHJguJjsN",
	"resultClass": "_2XvUn2CZvWucTS6W9K5SvV",
	"iconButton": "_3dXxqYW3-JkD480sTrgNza",
	"settingLabel": "_2M4B5Xzv7uiJLGvZK1Heuw",
	"settingWrapper": "_1Xd0awShfa4LwNr-Td0-Ya",
	"settingButton": "b5P-K8feLsyfYPBTNui9X",
	"active": "weIlap-V9g77rYAmNtmaB"
};