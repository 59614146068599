// @flow

import React from 'react';
import styles from "./FilterFragment.css";
import {
    COMPARATOR,
    FIELD_NAME_COMPARATOR_KEY,
    FIELD_NAME_PUBLISH_CONTENT, FIELD_NAME_SUBJECT_KEY,
    FIELD_NAME_TOPIC_NAME
} from "./FilterFragment";
import StringUtil from "../../../util/StringUtil";
import {DataRecordType} from "../../../model/model";
import {LLMsg} from "../../../IntlCapture";
import EventTriggerItemFragment from "../DataTriggerFragment/EventTriggerItemFragment";
import Constant from "../../../bundle/Constant";
import {ClassNames} from "../../../bundle/ClassNames";
import MessageInputWrapper from "../DataTriggerFragment/EventTriggerItemFragment/MessageInputWrapper";
import _ from "lodash";

type TopicKeyValueMessageConditionProps = {
    conditionKey: number,
    topicName: string,
    subjectKey: string,
    comparatorKey: string,
    publishContent: string,
    topics: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }>,
    onDeleteCallback: (conditionKey: number) => void,
    updateInputField: (conditionKey: number, fieldName: string, fieldValue: string) => void
}
type TopicKeyValueMessageConditionState = {
    showTopicNameError: boolean,
    showComparatorKeyError: boolean,
    showPublishContentError: boolean,
    showSubjectKeyError: boolean
}

class TopicKeyValueMessageCondition extends React.Component<TopicKeyValueMessageConditionProps, TopicKeyValueMessageConditionState> {

    constructor(props: TopicKeyValueMessageConditionProps) {
        super(props);
        this.state = {
            showTopicNameError: false,
            showComparatorKeyError: false,
            showPublishContentError: false,
            showSubjectKeyError: false
        };
    }

    validateCondition = (): void => {

        let showTopicNameError = false;
        let showComparatorKeyError = false;
        let showPublishContentError = false;
        let showSubjectKeyError = false;
        if (StringUtil.isOnlySpaceOrEmpty(this.props.topicName)) {
            showTopicNameError = true;
        }
        if (StringUtil.isOnlySpaceOrEmpty(this.props.subjectKey)) {
            showSubjectKeyError = true;
        }
        if (StringUtil.isOnlySpaceOrEmpty(this.props.comparatorKey)) {
            showComparatorKeyError = true;
        }
        if (StringUtil.isOnlySpaceOrEmpty(this.props.publishContent)) {
            showPublishContentError = true;
        }


        this.setState({
            showTopicNameError,
            showComparatorKeyError,
            showPublishContentError,
            showSubjectKeyError
        })
    };

    onTopicNameChanged = (e: any) => {
        let topicName = e.target.value;

        this.setState({
            showTopicNameError: false
        });
        this.props.updateInputField(this.props.conditionKey, FIELD_NAME_TOPIC_NAME, topicName);
    };

    onSubjectKeyChanged = (e: any) => {
        let subjectKey = e.target.value;

        this.setState({
            showSubjectKeyError: false
        });
        this.props.updateInputField(this.props.conditionKey, FIELD_NAME_SUBJECT_KEY, subjectKey);
    };

    onComparatorChanged = (e: any) => {
        let comparatorKey = e.target.value;

        this.setState({
            showComparatorKeyError: false
        });
        this.props.updateInputField(this.props.conditionKey, FIELD_NAME_COMPARATOR_KEY, comparatorKey);
    };

    onPublishContentChanged = (newValue: string) => {
        let content = newValue;

        this.setState({
            showPublishContentError: false
        });
        this.props.updateInputField(this.props.conditionKey, FIELD_NAME_PUBLISH_CONTENT, content);
    };

    render() {

        let subjectKeyOptions = [];
        this.props.topics && this.props.topics.forEach((topic) => {
            if (topic.topicName === this.props.topicName) {
                subjectKeyOptions = topic.dataRecordTypes.map((dataRecordType: DataRecordType, index) => {
                    return <option key={index} value={dataRecordType.keyName}>
                        {dataRecordType.dataRecordTypeName + " (" + dataRecordType.keyName + ")"}
                    </option>;

                });
            }
        });

        return <div className={styles.condition}>
            <EventTriggerItemFragment
                onDelete={() => {
                    this.props.onDeleteCallback(this.props.conditionKey);
                }}
                allowDelete={true}
                showError={false}
                backgroundColor={Constant.theme.eventTrigger.filter.color}
                titleContent={
                    <span>

                    {LLMsg("EVENT_TRIGGER.IF")}
                        {LLMsg("EVENT_TRIGGER.TOPIC")}
                        <select
                            onChange={this.onTopicNameChanged}
                            className={styles.select + " form-control form-inline m-input m-input--air" +
                            (this.state.showTopicNameError ? " " + styles.errorInput : "")}
                            value={this.props.topicName}
                        >
                        <option value={""}>
                            {LLMsg("EVENT_TRIGGER.TOPIC_NAME")}
                        </option>
                            {
                                this.props.topics && this.props.topics.map((topicDropdownValue, index) => {
                                    return <option key={index}
                                                   value={topicDropdownValue.topicName}>
                                        {topicDropdownValue.topicName + " (" + topicDropdownValue.description + ")"}
                                    </option>;

                                })
                            }
                    </select>
                        {LLMsg("EVENT_TRIGGER.S")}
                        <select
                            className={styles.select + " form-control form-inline m-input m-input--air" +
                            (this.state.showSubjectKeyError ? " " + styles.errorInput : "")}
                            value={this.props.subjectKey}
                            onChange={this.onSubjectKeyChanged}>
                        <option value={""}>
                            {LLMsg("EVENT_TRIGGER.SUBJECT_KEY")}
                        </option>
                            {subjectKeyOptions}
                    </select>
                    <select
                        className={styles.select + " form-control form-inline m-input m-input--air" +
                        (this.state.showComparatorKeyError ? " " + styles.errorInput : "")}
                        value={this.props.comparatorKey}
                        onChange={this.onComparatorChanged}>

                        <option value={""}>
                            {LLMsg("EVENT_TRIGGER.COMPARATOR")}
                        </option>
                        {
                            Object.entries(COMPARATOR()).map((entry: any, index) => {
                                return <option key={index} value={entry[1].key}>{entry[1].label}</option>
                            })
                        }
                    </select>

                        <MessageInputWrapper
                            className={styles.select}
                            showError={this.state.showPublishContentError}
                            placeholder={LLMsg("EVENT_TRIGGER.MESSAGE")}
                            onChange={this.onPublishContentChanged}
                            value={this.props.publishContent}
                            topics={_.sortBy(this.props.topics, ['id'])}
                        />
                        </span>
                }
            />
        </div>
    }
}

export default TopicKeyValueMessageCondition;