// @flow

import React from 'react';
import styles from "./action.css";
import StringUtil from "../../../util/StringUtil";
import {LLMsg} from "../../../IntlCapture";
import EventTriggerItemFragment from "../DataTriggerFragment/EventTriggerItemFragment";
import Constant from "../../../bundle/Constant";

type TriggerIFTTTWebhookActionFragmentProps = {
    index: number,
    displayIndex: number,
    publishJsonContent: ? string,
    iftttWebhookEvent?: string,
    iftttWebhookKey?: string,
    onDeleteCallback: (index: number) => void,
    handleIFTTTJsonContentChange: (message: string, index: number) => void,
    handleIFTTTEventNameChange: (message: string, index: number) => void,
    handleIFTTTKeyChange: (message: string, index: number) => void

}
type TriggerIFTTTWebhookActionFragmentState = {
    eventError: boolean,
    webhookKeyError: boolean,
    publishJsonContentError: boolean
}

class TriggerIFTTTWebhookActionFragment extends React.Component<TriggerIFTTTWebhookActionFragmentProps, TriggerIFTTTWebhookActionFragmentState> {

    constructor(props: TriggerIFTTTWebhookActionFragmentProps) {
        super(props);

        this.state = {
            eventError: false,
            webhookKeyError: false,
            publishJsonContentError: false
        };

    }

    validateAction = (): boolean => {
        let valid = true;
        let publishContentError = false;
        let eventError = false;
        let keyError = false;
        if (!StringUtil.IsJsonString(this.props.publishJsonContent)) {
            valid = false;
            publishContentError = true;
        }
        if (StringUtil.isOnlySpaceOrEmpty(this.props.iftttWebhookEvent)) {
            valid = false;
            eventError = true;
        }
        if (StringUtil.isOnlySpaceOrEmpty(this.props.iftttWebhookKey)) {
            valid = false;
            keyError = true;
        }
        this.setState({
            eventError: eventError,
            webhookKeyError: keyError,
            publishJsonContentError: publishContentError
        });
        return valid;
    };

    handleIFTTTWebhookKeyValueChange = (e: any) => {

        let publishContent = e.target.value;
        this.props.handleIFTTTKeyChange(publishContent, this.props.index);
        this.setState({
            webhookKeyError: false
        })
    };

    handleIFTTTWebhookEventValueChange = (e: any) => {

        let topicName = e.target.value;
        this.props.handleIFTTTEventNameChange(topicName, this.props.index);
        this.setState({
            eventError: false
        })
    };

    handleIFTTTWebhookJsonContentChange = (e: any) => {

        let topicName = e.target.value;
        this.props.handleIFTTTJsonContentChange(topicName, this.props.index);
        this.setState({
            publishJsonContentError: false
        })
    };

    render() {

        let content = "";
        let isJsonError = false;
        try {
            content = JSON.stringify(JSON.parse(this.props.publishJsonContent || ""), undefined, 4);
        } catch (e) {
            content = this.props.publishJsonContent;
            isJsonError = true;
        }

        return <span className={styles.actionItem}>

            <EventTriggerItemFragment
                allowDelete={true}
                showError={this.state.showError}
                backgroundColor={Constant.theme.eventTrigger.action.color}
                titleContent={
                    <span>
                        {LLMsg("EVENT_TRIGGER.IFTTT_WEBHOOK_ACTION")}
                            </span>
                }
                extraContent={
                    <div className={"ml-5 mt-3 mb-3"}>
                        <div className={"mb-2"}>

                            {LLMsg("EVENT_TRIGGER.IFTTT_WEBHOOK_EVENT")} :
                            <input
                                className={styles.actionTextInput + " form-control m-input" +
                                (this.state.eventError ? " " + styles.errorInput : "")}
                                type="text"
                                placeholder={LLMsg("EVENT_TRIGGER.IFTTT_WEBHOOK_EVENT")}
                                onChange={this.handleIFTTTWebhookEventValueChange}
                                value={this.props.iftttWebhookEvent}/>
                        </div>
                        <div className={"mb-2"}>
                            {LLMsg("EVENT_TRIGGER.IFTTT_WEBHOOK_KEY")} :
                            <input
                                className={styles.actionTextInput + " " + styles.longerTextInput + " form-control m-input" +
                                (this.state.webhookKeyError ? " " + styles.errorInput : "")}
                                type="text"
                                placeholder={LLMsg("EVENT_TRIGGER.IFTTT_WEBHOOK_KEY")}
                                onChange={this.handleIFTTTWebhookKeyValueChange}
                                value={this.props.iftttWebhookKey}/>
                        </div>
                        <div className={"mb-2"}>
                            {LLMsg("EVENT_TRIGGER.IFTTT_WEBHOOK_JSON_CONTENT")} :
                            <textarea
                                className={" form-control m-input" +
                                (this.state.publishJsonContentError || isJsonError ? " " + styles.errorInput : "")}
                                onChange={this.handleIFTTTWebhookJsonContentChange}
                                rows={5}
                                placeholder={LLMsg("")}
                                onBlur={this.handleIFTTTWebhookJsonContentChange}
                                value={content}/>
                        </div>
                    </div>}
                onDelete={() => {
                    this.props.onDeleteCallback(this.props.index);
                }}
            />


        </span>;
    }
}

export default TriggerIFTTTWebhookActionFragment;