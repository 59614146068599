// @flow

import React from 'react';
import Page from "../../bundle/Page";
import {Link} from "react-router-dom";
import Api from "../../bundle/Api";
import {RouterHistory} from "../../AppRoute";


const ServiceUnavailablePage = () => {

    let tryAgain = () => {

        Api.services.backendStatusService.getStatus().then((response) => {
            if (response.status === 200) {
                RouterHistory().push(Page.internals.urls.misc.home());
            }
        }).catch(() => {
            RouterHistory().push(Page.internals.urls.maintenancePeriod.underMaintenance());
        });
    }

    Page.updateTitle("Service not available");
    return <div className="m-grid m-grid--hor m-grid--root m-page">
        <div className="m-grid__item m-grid__item--fluid m-grid  m-error-6"
             style={{backgroundImage: 'url(/image/service_unavailable_background.jpg)'}}>
            <div className="m-error_container">
                <div className="m-error_subtitle m--font-light">
                    <h1>Hello!</h1>
                </div>
                <p className="m-error_description m--font-light">

                    <button className={"btn btn-info"} onClick={tryAgain}>Try again!</button>
                    <br/>
                    MakerCloud Service is not available now.<br/>
                    Please email support@scaleinnotech.com for inquiry
                </p>
            </div>
        </div>
    </div>;
};

export default ServiceUnavailablePage