// @flow
import React, {Component} from 'react';
import DashboardHeaderMoreFragment from "./DashboardHeaderMoreFragment";
import DeleteDashboardButton from "./DeleteDashboardButton";
import RenameDashboardButton from "./RenameDashboardButton";

type DashboardHeaderFragmentProps = {
    title: string,
    currentDashboard: ?{ dashboardId: string, dashboardName: string },
    dashboards: Array<{ dashboardId: string, dashboardName: string }>
}

export default class DashboardHeaderFragment extends Component<DashboardHeaderFragmentProps, any> {
    render() {

        const {title} = this.props;

        return <div className="m-portlet__head">
            <div className="m-portlet__head-caption">
                <div className="m-portlet__head-title">
                      <span className="m-portlet__head-icon">
                        <i className="flaticon-line-graph"/>
                      </span>
                    <h3 className="m-portlet__head-text m--icon-font-size-lg4">
                        {title}
                    </h3>
                </div>
            </div>
            <div className="m-portlet__head-tools">
                <ul className="m-portlet__nav">
                    <RenameDashboardButton dashboard={this.props.currentDashboard}/>
                    <DeleteDashboardButton dashboard={this.props.currentDashboard}/>
                    {/*<li className="m-portlet__nav-item">*/}
                        {/*<a href="#" m-portlet-tool="fullscreen"*/}
                           {/*className="m-portlet__nav-link m-portlet__nav-link--icon">*/}
                            {/*<i className="la la-expand"/>*/}
                        {/*</a>*/}
                    {/*</li>*/}
                    <DashboardHeaderMoreFragment dashboards={this.props.dashboards}/>
                </ul>
            </div>
        </div>;

    }
}