// @flow

import React, {Component} from "react";
import Constant from "../../bundle/Constant";
import Slider from "react-slick/lib/index";
import _ from "lodash";
import ProjectFragment from "./ProjectFragment";
import AddProjectToTopicButton from "./AddProjectToTopicButton";
import {LLMsg} from "../../IntlCapture";

type MappedProjectListFragmentProps = {
    projects: Array<{
        projectName: string,
        projectId: string
    }>,
    topicId: string
}

class MappedProjectListFragment extends Component<MappedProjectListFragmentProps, any> {


    sliderRef: any;

    constructor(props: MappedProjectListFragmentProps) {
        super(props);
        this.sliderRef = React.createRef();
    }

    settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        swipeToSlide: false,
        slidesPerRow: 4,
        arrows: false,
    };

    render() {
        return <div className={"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2"}>
            <div className={"m-portlet mb-0"}>
                <div className={"m-portlet__body"}>
                    <div className="m-stack m-stack--ver m-stack--general">
                        <div className="m-stack__item  m-stack__item--fluid">
                            <h2>
                                {LLMsg("COMMON.PROJECT.PROJECT_LIST")}
                            </h2>
                        </div>
                        <div className="m-stack__item m-stack__item--right" style={{width: 120}}>
                            <AddProjectToTopicButton topicId={this.props.topicId}/>
                        </div>
                    </div>
                    <div className="m-stack m-stack--ver m-stack--general m-stack--demo">

                        {
                            this.props.projects.length > 4 && <div
                                className="m-stack__item m-stack__item--center m-stack__item--middle cursor-pointer custom-slick-prev"
                                onClick={() => {
                                    this.sliderRef.current.slickPrev();
                                }}>
                                <i className="fa fa-arrow-circle-left fa-2x"/>
                            </div>
                        }
                        <div
                            className="m-stack__item m-stack__item--center m-stack__item--middle m-stack__item--fluid"
                            style={{backgroundColor: "inherit", border: "0px"}}>
                            <Slider ref={this.sliderRef} {...this.settings}>
                                {
                                    this.props.projects && this.props.projects.length > 0 && _.sortBy(this.props.projects, ['projectId']).map((project, index) => {
                                        return <ProjectFragment projectId={project.projectId} name={project.projectName}
                                                                key={index} index={index}/>;
                                    })
                                }
                            </Slider>
                        </div>


                        {
                            this.props.projects.length > 4 && <div
                                className="m-stack__item m-stack__item--center m-stack__item--middle cursor-pointer custom-slick-next"
                                onClick={() => {
                                    this.sliderRef.current.slickNext();
                                }}>
                                <i className="fa fa-arrow-circle-right fa-2x"/>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>;
    }
}

export default MappedProjectListFragment;