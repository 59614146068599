// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux'
import _ from "lodash";
import Api from "../../../bundle/Api";
import swal from "sweetalert2";
import CreateTopicDataTypeFragment from "./CreateTopicDataTypeFragment";
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import Page from "../../../bundle/Page";
import {LLMsg} from "../../../IntlCapture";
import ErrorUtil from "../../../util/ErrorUtil";

type TopicCreateFragmentProps = {
    createTopic?: Function,
    getProjectList?: Function,
    getProjectListResult?: Object,
    createTopicResult?: Object,

    location?: Object,
    history?: Object,
    match?: Match
}

class TopicCreateFragment extends Component<TopicCreateFragmentProps, any> {

    constructor(props) {
        super(props);
        this.getProjectListResult();
    }

    topicDescriptionInput: any;
    createTopicSensorTypeFragment: any;
    isActiveInput: any;
    selectedProject: Array<number> = [];

    createTopic = () => {
        let topicName = this.topicDescriptionInput.value;
        if (!topicName) {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.TOPIC.TOPIC_DESC_EMPTY"), "warning");
            return;
        }

        Api.services.topic.doGetCreateTopicLimit().then((response) => {
            if (response.status === 200) {
                if (response.data.allowCreate) {

                    let isActive = this.isActiveInput.value === "on";
                    let dataRecordTypes = this.createTopicSensorTypeFragment.getSelectedResult();
                    Api.services.topic.doCreateTopic(topicName, isActive, this.selectedProject, dataRecordTypes).then((response) => {
                        if (response.status === 200) {
                            swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), "Your new topic has been created.", "success").then(() => {
                                this.props.history && this.props.history.push({pathname: Page.internals.urls.topic.detail(response.data.id, response.data.description)});
                            });
                        } else {
                            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                        }
                    }).catch(() => {
                        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                    });

                    try {
                        this.createTopicSensorTypeFragment.current.clearSelectedResult();
                        this.topicDescriptionInput.value = "";
                        this.isActiveInput.value = "on";
                    } catch (ex) {

                    }
                } else {
                    ErrorUtil.showPermissionNotAllowed();
                }

            }
        }).catch((error =>
                ErrorUtil.promptError(error)
        ));
    };

    getProjectListResult = () => {
        this.props.getProjectList && this.props.getProjectList();
    };

    componentDidMount() {
        window.$('#project_multiple_select').selectpicker();
        this.topicDescriptionInput.focus();
    }

    componentDidUpdate() {
        window.$('#project_multiple_select').selectpicker("refresh");
    }

    onProjectSelected = (values: Array<number>) => {
        this.selectedProject = values;
    };

    render() {
        const projects = _.get(this.props, "getProjectListResult.payload.projects", []);

        return <div className={"row"}>
            <div className={"col-md-12"}>
                <div className="m-portlet m-portlet--mobile">

                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <span className="m-portlet__head-icon m--hide">
                                  <i className="la la-gear"/>
                                </span>
                                <h3 className="m-portlet__head-text">
                                    {LLMsg("COMMON.TOPIC.CREATE_TOPIC")}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body">
                        <div className="form-group m-form__group row">
                            <label className="col-3 col-form-label">
                                {LLMsg("COMMON.TOPIC.TOPIC_NAME")}
                            </label>
                            <div className="col-9">
                                <input className="form-control m-input" type="text" readOnly="true"
                                       placeholder={LLMsg("COMMON.TOPIC.TOPIC_NAME_PLACEHOLDER")}/>
                            </div>
                        </div>
                        <div className="form-group m-form__group row">
                            <label className="col-3 col-form-label">
                                {LLMsg("COMMON.TOPIC.TOPIC_DESCRIPTION")}
                            </label>
                            <div className="col-9">
                                <input className="form-control m-input" type="text"
                                       placeholder={LLMsg("COMMON.TOPIC.TOPIC_DESC_PLACEHOLDER")}
                                       ref={(topicDescriptionInput) => {
                                           this.topicDescriptionInput = topicDescriptionInput
                                       }}/>
                            </div>
                        </div>
                        <div className="form-group m-form__group row">
                            <label className="col-3 col-form-label">
                                {LLMsg("COMMON.TOPIC.ACTIVE")}
                            </label>
                            <div className="col-9">
                                <span className="m-switch m-switch--outline m-switch--accent">
                                    <label>
                                      <input type="checkbox" defaultChecked="checked" name="true"
                                             ref={(isActiveInput) => {
                                                 this.isActiveInput = isActiveInput
                                             }}/>
                                      <span/>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <div className="form-group m-form__group row">
                            <label className="col-form-label col-3">
                                {LLMsg("COMMON.PROJECT.PROJECT")}
                            </label>
                            <div className="col-9">
                                <div className="dropdown bootstrap-select show-tick form-control m-bootstrap-select m_">
                                    <select className="form-control m-bootstrap-select m_selectpicker"
                                            id="project_multiple_select"
                                            onChange={(e) => {
                                                let options = e.target.options;
                                                let values = [];
                                                for (let i = 0, l = options.length; i < l; i++) {
                                                    if (options[i].selected) {
                                                        values.push(parseInt(options[i].value));
                                                    }
                                                }
                                                this.onProjectSelected(values);
                                            }}
                                            multiple="multiple" tabIndex={-98}>
                                        <option selected={"true"}
                                                disabled={"true"}>{LLMsg("COMMON.TOPIC.NO_PROJECT_SELECTED")}</option>

                                        {
                                            projects && projects.map(project => {
                                                return <option key={project.id}
                                                               value={project.id}>{project.projectName}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <CreateTopicDataTypeFragment ref={(createTopicSensorTypeFragment) => {
                            this.createTopicSensorTypeFragment = createTopicSensorTypeFragment
                        }}/>

                        <div className="form-group m-form__group row">

                            <label htmlFor="example-search-input" className="col-3 col-form-label">
                                {" "}
                            </label>
                            <div className="col-9">
                                <div className="input-group-append">
                                    <button className="btn btn-accent m-btn m-btn--custom" type="button"
                                            onClick={(e) => {
                                                this.createTopic();
                                            }}>
                                        {LLMsg("COMMON.ACTION.CREATE")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = state => {
    return {
        createTopicResult: state.TopicReducer[Api.actions.topic.CREATE_TOPIC_ACTION_TYPE],
        getProjectListResult: state.ProjectReducer[Api.actions.project.GET_LIST_OF_PROJECT_ACTION_TYPE]
    };
};

const mapDispatchToProps = dispatch => ({
    getProjectList: () => Api.dispatchers.project.doGetListOfProject(dispatch)
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TopicCreateFragment));
