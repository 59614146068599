// @flow

import React, {Component} from 'react';
import SchoolListTableContentItem from "./SchoolListTableContentItem";
import TimeUtil from "../../../../util/TimeUtil";


type SchoolListTableContentWrapperProps = {
    schools: Array<{
        id: string,
        schoolName: string,
        emailDomain: string,
        validUntil: string,
        totalApplication: number,
        totalProcessedApplication: number
    }>
}

class SchoolListTableContentWrapper extends Component <SchoolListTableContentWrapperProps, any> {

    render() {
        const {schools} = this.props;
        return <tbody className="m-datatable__body">
        {
            schools && schools.map((school, index) => {
                return <SchoolListTableContentItem
                    key={index}
                    id={school.id}
                    emailDomain={school.emailDomain}
                    schoolName={school.schoolName}
                    validUntil={TimeUtil.convertTimeFormat(school.validUntil, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                    totalApplication={school.totalApplication}
                    totalProcessedApplication={school.totalProcessedApplication}
                />;
            })
        }
        </tbody>;

    }
}

export default SchoolListTableContentWrapper;