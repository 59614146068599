// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import MappedProjectListFragment from "../../project/MappedProjectListFragment";
import MappedDataRecordTypeListFragment from "../../dataRecord/MappedDataRecordTypeListFragment";
import Constant from "../../../bundle/Constant";
import RelatedTopicChartFragment from "../../chart/RelatedTopicChartFragment";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import TalkToTopicFragment from "../TalkToTopicFragment";
import CopyTopicNameButtonFragment from "../CopyTopicNameButtonFragment";
import RenameTopicButton from "../RenameTopicButton";
import Api from "../../../bundle/Api";
import type {SimpleAction} from "../../../redux/reducer";
import {LLMsg} from "../../../IntlCapture";
import TopicMapFragment from "../../chart/MapFragment/TopicMapFragment";
import Image from "../../../bundle/Image";
import styles from './index.css';
import type {ChartMessage, Project, Topic} from "../../../model/model";
import SocketIOService from "../../../service/SocketIOService";
import PubSub from "pubsub-js";

type TopicDetailFragmentProps = {
    action?: SimpleAction,

    location?: Object,
    history?: Object,
    match?: Match
}

type TopicDetailFragmentState = {
    topic: Topic
};

class TopicDetailFragment extends Component<TopicDetailFragmentProps, TopicDetailFragmentState> {

    constructor(props) {
        super(props);
        this.state = {topic: {}};
        const topicId = this.props.match && this.props.match.params["topicId"];
        this.getTopicDetail(topicId || "");
    }

    getTopicDetail = (topicId: string) => {
        window.mApp.block("#TopicDetailFragment", {
            overlayColor: "#000000",
            type: "loader",
            state: "success",
            message: LLMsg("COMMON.ACTION.LOADING_MESSAGE")
        });
        Api.services.topic.getTopicDetail(topicId).then(response => {
            window.mApp.unblock("#TopicDetailFragment");
            if (response.status === 200) {

                this.setState({
                    topic: response.data
                });

                let socket: any = SocketIOService.getSocket();
                socket.emit(Constant.socket.data.room.join, "T" + response.data.id);

                socket.on(Constant.socket.data.room.newData, (data) => {
                    let dataObj: ChartMessage = SocketIOService.extractChartDataMessage(data);
                    PubSub.publish(Api.actions.topic.TOPIC_NEW_DATA_ACTION_TYPE, dataObj);
                });
            }
        }).catch(() => {
            window.mApp.unblock("#TopicDetailFragment");
        });
    };

    componentDidUpdate(prevProps, prevState) {

        const topicId = this.props.match && this.props.match.params["topicId"];
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.topic.TOPIC_UPDATED)) {
            this.getTopicDetail(topicId || "");
        }
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.dataType.CREATE_DATA_TYPE_ACTION)) {
            this.getTopicDetail(topicId || "");
        }
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.dataType.UPDATE_DATA_TYPE_ACTION)) {
            this.getTopicDetail(topicId || "");
        }
    }

    render() {
        const topic: Topic = this.state.topic || {};

        Page.updateTitle("PAGE.TITLE.TOPIC_DETAIL", [{
            key: "TOPIC_NAME",
            value: topic.description || topic.topicName
        }]);

        return <div className={"row"} id={"TopicDetailFragment"}>
            <div className={Constant.portlet.size.colWidth.w6}>
                <div className="m-portlet mb-0">
                    <div className="m-portlet__body">
                        <div className={"portlet_height_1x"}>
                            <div className="m-widget12 row mb-5">
                                <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-2 pr-0">
                                    <span className="m-widget12__text1 m--icon-font-size-lg2">
                                        <span className={"mr-2"}>{LLMsg("COMMON.TOPIC.TOPIC_NAME")}</span>
                                        <br className={"d-none d-sm-none d-md-none d-lg-none "}/>
                                        <strong
                                            style={{color: Constant.theme.topic.color}}>
                                            {topic.topicName}
                                        </strong>

                                        <CopyTopicNameButtonFragment
                                            className={"ml-2 pb-3 btn btn-link m-btn--pill btn-md"}
                                            color={Constant.theme.topic.color} text={topic.topicName}/>
                                    </span>
                                </div>
                                <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-2">
                                    <span className="m-widget12__text2 m--icon-font-size-lg2">
                                        <span className={"mr-2"}>{LLMsg("COMMON.TOPIC.TOPIC_DESCRIPTION")}</span>
                                        <br className={"d-none d-sm-none d-md-none d-lg-none "}/>
                                            <span>{topic.description}</span>
                                    </span>
                                </div>
                            </div>

                            <div className=" row">
                                <div
                                    className={styles.actionDiv + " col-6"}>
                                    <Link to={Page.internals.urls.chart.getChartCreatePageUrl()}
                                          className={styles.actionButton + " no-a-effect"}>

                                        <img src={Image.urls.getCreateChartIcon()}
                                             alt={"create chart"}
                                             className={styles.actionImageIcon}/>
                                        <span className={styles.actionLabel}>
                                             {LLMsg("COMMON.CHART.CREATE_CHART")}
                                       </span>
                                    </Link>
                                </div>
                                <RenameTopicButton topic={{topicId: topic.id, description: topic.description}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TalkToTopicFragment topicId={topic.id}/>
            <div className={"col-12 col-xl-6 p-2"}>
                <TopicMapFragment topicId={topic.id} latitude={topic.latitude} longitude={topic.longitude}/>
            </div>
            <MappedProjectListFragment topicId={topic.id} projects={
                (topic.projects && topic.projects.length > 0) ? topic.projects.map((project) => {
                    return {
                        projectId: project.id,
                        projectName: project.projectName
                    }
                }) : []
            }/>
            {topic.id && <MappedDataRecordTypeListFragment dataRecordTypes={topic.dataRecordTypes} topicId={topic.id}/>}
            {topic.id && <RelatedTopicChartFragment topicId={topic.id}/>}
        </div>;
    }
}


const mapStateToProps = state => {
    return {
        action: state.ActionReducer.action
    };
};

export default withRouter(connect(mapStateToProps, null)(TopicDetailFragment));
