// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import {saveAs} from 'file-saver';

class DataRecordService {

    static doGetRecentDataRecord(topicId: string, dataTypeId: string, deviceId: string, count: number): Object {
        return HttpUtil.ajax(Api.urls.record.recent(topicId, dataTypeId, deviceId, count), "GET", {
            'Content-Type': 'application/json',
        });
    }
    static doGetAllDataRecord(topicId: string, dataTypeId: string, deviceId: string): Object {
        return HttpUtil.ajax(Api.urls.record.all(topicId, dataTypeId, deviceId), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static doGetAggregateHistoryDataRecord(topicId: string, dataTypeId: string, deviceId: string, startTime: string, endTime: string, interval: string, aggregate: string): Object {
        return HttpUtil.ajax(Api.urls.record.aggregateHistory(topicId, dataTypeId, deviceId, startTime, endTime, interval, aggregate), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static download = {
        dataRecordByProjectId: (projectId: string, projectName: string): Object => {
            return HttpUtil.ajax(Api.urls.record.download.dataByProjectId(projectId), Api.methods.get, {
                'Content-Type': 'application/json',
            }, undefined, "blob").then((response) => {
                if (response.status === 200) {
                    saveAs(response.data, "Data Record for " + projectName + ".xlsx");
                }
            })
        }
    }
}

export default DataRecordService;