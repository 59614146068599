exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3cISA8wt6GHJZXVs6KemXC {\n    width: 100%;\n}\n\n.MVT4vAnZV0JWkxFSknqO6 {\n    border: none;\n}", ""]);

// exports
exports.locals = {
	"box": "_3cISA8wt6GHJZXVs6KemXC",
	"iframe": "MVT4vAnZV0JWkxFSknqO6"
};