// @flow

import React, {Component} from 'react';
import SchoolRegisterButtonWrapper from "../LoginFormWrapper/OthersLoginFragment/SchoolRegisterButtonWrapper";
import RegisterAsGuestButtonWrapper from "../LoginFormWrapper/OthersLoginFragment/RegisterAsGuestButtonWrapper";
import {LLMsg} from "../../../IntlCapture";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import RegisterWithLicenseButtonWrapper from "../LoginFormWrapper/OthersLoginFragment/RegisterWithLicenseButtonWrapper";

class RegistrationSelectionFragment extends Component<any, any> {
    render() {
        return <div
            className="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper">
            <div className="m-login__body">
                <div className="m-login__signin">
                    <div className="m-login__title mb-5">
                        <h3>{LLMsg("REGISTER_PAGE.REGISTER_ACCOUNT")}</h3>
                    </div>


                    <SchoolRegisterButtonWrapper/>
                    <RegisterWithLicenseButtonWrapper/>
                    <RegisterAsGuestButtonWrapper/>
                    <hr/>
                    <Link to={Page.internals.urls.user.login()} className="m-link mt-2">
                        <span>{LLMsg("REGISTER_PAGE.BACK_TO_LOGIN_PAGE")}</span>
                    </Link>
                </div>
            </div>
        </div>
    }
}

export default RegistrationSelectionFragment;