const messages = {
    /* PAGE_TITLE_01 */ 'PAGE.TITLE.HOME': '主页',
    /* PAGE_TITLE_02 */ 'PAGE.TITLE.PRIVACY': '私隱政策',
    /* PAGE_TITLE_03 */ 'PAGE.TITLE.LEGAL': '使用條款',
    /* PAGE_TITLE_04 */ 'PAGE.TITLE.CONTACT_US': '聯繫我們',
    /* PAGE_TITLE_05 */ 'PAGE.TITLE.LOGIN': '请先登录',
    /* PAGE_TITLE_06 */ 'PAGE.TITLE.PROJECT_CREATE': '创建项目',
    /* PAGE_TITLE_07 */ 'PAGE.TITLE.PROJECT_LISTING': '项目列表',
    /* PAGE_TITLE_08 */ 'PAGE.TITLE.PROJECT_DETAIL': '{PROJECT_NAME} - 详细项目资料',
    /* PAGE_TITLE_09 */ 'PAGE.TITLE.TOPIC_CREATE': '创建主题',
    /* PAGE_TITLE_10 */ 'PAGE.TITLE.TOPIC_LISTING': '主题列表',
    /* PAGE_TITLE_11 */ 'PAGE.TITLE.TOPIC_DETAIL': '{TOPIC_NAME} - 详细主题资料',
    /* PAGE_TITLE_12 */ 'PAGE.TITLE.DASHBOARD': '{DASHBOARD_NAME} - 仪表板',
    /* PAGE_TITLE_13 */ 'PAGE.TITLE.CHART_DETAIL': '{CHART_NAME} - 详细图表资料',
    /* PAGE_TITLE_14 */ 'PAGE.TITLE.CHART_CREATE': '创建图表',
    /* PAGE_TITLE_15 */ 'PAGE.TITLE.POSTFIX': ' - Maker Cloud - STEM教学的物联网平台',
    /* PAGE_TITLE_16 */ 'PAGE.TITLE.PAGE_NOT_FOUND': '找不到网页',
    /* PAGE_TITLE_17 */ 'PAGE.TITLE.LOGOUT': '登出',
    /* PAGE_TITLE_18 */ 'PAGE.TITLE.CHANGE_PASSWORD': '更改密码',
    /* PAGE_TITLE_19 */ 'PAGE.TITLE.MY_SUBSCRIPTION': '我的订阅状态',
    /* PAGE_TITLE_20 */ 'PAGE.TITLE.SETUP_PASSWORD': '初始化密码',
    /* PAGE_TITLE_21 */ 'PAGE.TITLE.REGISTRATION_SELECTION': '注册账户',
    /* PAGE_TITLE_22 */ 'PAGE.TITLE.REGISTER_FOR_SCHOOL': '注册学校帐户',
    /* PAGE_TITLE_23 */ 'PAGE.TITLE.REGISTER_WITH_LICENSE_KEY': '以授权码注册帐户',
    /* PAGE_TITLE_24 */ 'PAGE.TITLE.REGISTER_WITH_EVENT_CODE': '以活动代码注册帐户',
    /* PAGE_TITLE_25 */ 'PAGE.TITLE.RESET_PASSWORD': '重设密码申请',
    /* PAGE_TITLE_26 */ 'PAGE.TITLE.VIRTUAL_LIGHT_BULB': '虚拟灯泡',
    /* PAGE_TITLE_27 */ 'PAGE.TITLE.WHATS_NEW': '最新消息',

    /* LOG_01 */        'LOGIN_PAGE.TITLE': '歡迎您，請登錄！',
    /* LOG_02 */        'LOGIN_PAGE.PRIVACY': '隱私',
    /* LOG_03 */        'LOGIN_PAGE.LEGAL': '法律',
    /* LOG_04 */        'LOGIN_PAGE.CONTACT': '聯繫',
    /* LOG_05 */        'LOGIN_PAGE.DESC': 'Smart Campus Active Learning Environment',
    /* LOG_06 */        'LOGIN_PAGE.SUB_DESC': 'Of, By And For Tomorrow\'s Smart Citizen',
    /* LOG_07 */        'LOGIN_PAGE.COPYRIGHT': 'Maker Cloud',
    /* LOG_08 */        'LOGIN_PAGE.NO_ACCOUNT': '沒有賬號？',
    /* LOG_09 */        'LOGIN_PAGE.REGISTER': '註冊',
    /* LOG_10 */        'LOGIN_PAGE.USERNAME': '电邮地址',
    /* LOG_11 */        'LOGIN_PAGE.PASSWORD': '密碼',
    /* LOG_12 */        'LOGIN_PAGE.FORGET_PASSWORD': '忘記密碼 ？',
    /* LOG_13 */        'LOGIN_PAGE.LOGIN': '登入',
    /* LOG_14 */        'LOGIN_PAGE.OR': '或',
    /* LOG_15 */        'LOGIN_PAGE.GOOGLE': 'Google 登录',
    /* LOG_16 */        'LOGIN_PAGE.GUEST_LOGIN': '以活动码注册帐户',
    /* LOG_17 */        'LOGIN_PAGE.SCHOOL_SIGN_UP': '注册学校帐户',
    /* LOG_18 */        'LOGIN_PAGE.WRONG_USERNAME_OR_PASSWORD': '错误的用户名或密码。如果忘记密码，请点击“忘记密码”重置您的密码',
    /* LOG_19 */        'LOGIN_PAGE.MORE_RESOURCES': '其他资源',

    /* REG_01 */        'REGISTER_PAGE.REGISTER_ACCOUNT': '注册账户',
    /* REG_02 */        'REGISTER_PAGE.REGISTER_GUEST_ACCOUNT': '以访客身份注册',
    /* REG_03 */        'REGISTER_PAGE.BACK_TO_LOGIN_PAGE': '已经注册了一个帐户？ 立即登录您的帐户！',
    /* REG_04 */        'REGISTER_PAGE.GUEST_EXPIRY_NOTICE': '一旦您以访客身份注册，访客帐户将在7天后到期。 如果您有任何问题，请与我们联系',
    /* REG_05 */        'REGISTER_PAGE.GUEST_USERNAME': '电邮地址',
    /* REG_06 */        'REGISTER_PAGE.CONFIRM_REGISTER_GUEST': '创建访客帐号',
    /* REG_07 */        'REGISTER_PAGE.INVALID_GUEST_USERNAME': '请提供一个有效的电子邮件',
    /* REG_08 */        'REGISTER_PAGE.EVENT_CODE_LABEL': '活动码',
    /* REG_09 */        'REGISTER_PAGE.EVENT_CODE_INVALID': '无效的活动码',
    /* REG_10 */        'REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT': '注册学校帐户',
    /* REG_11 */        'REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT_NOTICE': '提交申请后，我们会尽快处理您的申请。申请获得批准后，您将收到一封确认电子邮件。我们可能会与您联系并验证您的资料。',
    /* REG_12 */        'REGISTER_PAGE.TEACHER_NAME': '教师姓名',
    /* REG_13 */        'REGISTER_PAGE.TEACHER_ROLE': '任教科目/负责项目',
    /* REG_14 */        'REGISTER_PAGE.TEACHER_TEL': '办公室电话/流动电话',
    /* REG_15 */        'REGISTER_PAGE.PERSONAL_INFO_LABEL': '个人资料',
    /* REG_16 */        'REGISTER_PAGE.EVENT_INFO_LABEL': '活动资料',
    /* REG_17 */        'REGISTER_PAGE.NO_EVENT_CODE': '如果您没有活动码，请联系support@scaleinnnotech.com申请活动码。',
    /* REG_18 */        'REGISTER_PAGE.TEACHER_ROLE_REMARKS': '任教科目/负责项目(备注/说明)',
    /* REG_19 */        'REGISTER_PAGE.TEACHER_NAME_INVALID': '请提供一个教师姓名',
    /* REG_20 */        'REGISTER_PAGE.TEACHER_TEL_INVALID': '请提供有效的电话号码',
    /* REG_21 */        'REGISTER_PAGE.TEACHER_ROLE_INVALID': '请选择一个项目',
    /* REG_22 */        'REGISTER_PAGE.TEACHER_ROLE_REMARKS_INVALID': '请填写备注',
    /* REG_23 */        'REGISTER_PAGE.SCHOOL_SIGN_UP_SUCCESS': '您的申请已提交',
    /* REG_24 */        'REGISTER_PAGE.SCHOOL_NAME_LABEL': '学校全名',
    /* REG_25 */        'REGISTER_PAGE.SCHOOL_NAME_INVALID': '请提供学校全名',
    /* REG_26 */        'REGISTER_PAGE.REGISTER_WITH_LICENSE': '以授权码注册帐户',
    /* REG_27 */        'REGISTER_PAGE.REGISTER_WITH_LICENSE_DESCRIPTION': '立即购买含了Maker Cloud的硬件以获取授权码，享受Maker Cloud的乐趣。',
    /* REG_28 */        'REGISTER_PAGE.REGISTER_FOR_SCHOOL_DESCRIPTION': '立即为您的学生注册，享受最优质的Maker Cloud功能，使您的STEM课堂更丰富。',
    /* REG_29 */        'REGISTER_PAGE.REGISTER_WITH_EVENT_CODE_DESCRIPTION': '立即注册访客帐号，试用7天的Maker Cloud。',
    /* REG_30 */        'REGISTER_PAGE.LICENSE_KEY_INVALID': '无效的授权码',

    /* PW_01 */         'PASSWORD.RESET_PASSWORD_TITLE': '重设密码申请',
    /* PW_02 */         'PASSWORD.RESET_PASSWORD_NOTICE': '提交申请后，重设密码的连结将发送到你的电子邮件。请耐心等候，如未能收到邮件，请确保电邮地址正确，并检查垃圾邮件匣。',
    /* PW_03 */         'PASSWORD.RESET_PASSWORD_COOL_DOWN': '你已提交過重設密碼申請，請於一分鐘後才提交新的申請。',
    /* PW_04 */         'PASSWORD.RESET_PASSWORD_RESET_BUTTON': '重設密碼',
    /* PW_05 */         'PASSWORD.RESET_PASSWORD_EMAIL_NOT_EXIST': '用户电邮不存在',
    /* PW_06 */         'PASSWORD.OLD_PASSWORD': '现有密码',
    /* PW_07 */         'PASSWORD.NEW_PASSWORD': '新密码',
    /* PW_08 */         'PASSWORD.NEW_PASSWORD_AGAIN': '再次输入新密码',
    /* PW_09 */         'PASSWORD.PASSWORD_LENGTH_HINTS': '密码长度应在6到20之间',
    /* PW_10 */         'PASSWORD.SKIP_SETUP_PASSWORD': '暂时不设定密码',
    /* PW_11 */         'PASSWORD.CHANGE_PASSWORD_BUTTON': '更改密码',
    /* PW_12 */         'PASSWORD.CURRENT_PASSWORD_INCORRECT': '现有密码不正确',
    /* PW_13 */         'PASSWORD.CHANGE_PASSWORD_TITLE': '更改密码',
    /* PW_14 */         'PASSWORD.NEW_PASSWORD_NOT_MATCH': '请输入相同的密码',
    /* PW_15 */         'PASSWORD.SETUP_PASSWORD_TITLE': 'The password does not match',
    /* PW_16 */         'PASSWORD.SETUP_PASSWORD_NOTICE': '如果你使用谷歌服务登入，你不一定需要建立密码。如你不是使用第三方认证登入，请建立密码。',
    /* PW_17 */         'PASSWORD.SETUP_PASSWORD_BUTTON': '建立密码',
    /* PW_18 */         'PASSWORD.SETUP_PASSWORD_PASSWORD_EXISTED': '你已曾经建立密码，请到更改密码页。',

    /* HEA_LANG_01 */   'HEADER.SELECT_LANGUAGE': '选择语言',
    /* HEA_LANG_02 */   'HEADER.LANGUAGE_TC': '繁體中文',
    /* HEA_LANG_03 */   'HEADER.LANGUAGE_SC': '简体中文',
    /* HEA_LANG_04 */   'HEADER.LANGUAGE_EN': 'English',

    /* HEA_USER_01 */   'HEADER.USER.FAQ': '常见问题',
    /* HEA_USER_02 */   'HEADER.USER.SUPPORT': '联系',
    /* HEA_USER_03 */   'HEADER.USER.LOGOUT': '登出',

    /* FOO_01 */        'COMMON.FOO.SCALE': 'Maker Cloud - SCALE InnoTech Limited',
    /* FOO_02 */        'COMMON.FOO.CONTACT_US': '联系我们',
    /* FOO_03 */        'COMMON.FOO.PRIVACY': '私隐政策声明',
    /* FOO_04 */        'COMMON.FOO.COPYRIGHT': '版权及知识产权',
    /* FOO_05 */        'COMMON.FOO.DISCLAIMER': '免责声明',
    /* FOO_06 */        'COMMON.FOO.TERMS': '服务条款',
    /* FOO_07 */        'COMMON.FOO.MAKER_CLOUD': 'Maker Cloud',
    /* FOO_08 */        'COMMON.FOO.THIRD_PARTY': '第三方软件使用',

    /* TT_PROJ_01 */    'COMMON.PROJECT.PROJECT': '项目',
    /* TT_PROJ_02 */    'COMMON.PROJECT.PROJECT_NAME': '项目名称',
    /* TT_PROJ_03 */    'COMMON.PROJECT.CREATE_PROJECT': '创建项目',
    /* TT_PROJ_04 */    'COMMON.PROJECT.UPDATE_PROJECT': '更新项目',
    /* TT_PROJ_05 */    'COMMON.PROJECT.DELETE_PROJECT': '删除项目',
    /* TT_PROJ_06 */    'COMMON.PROJECT.SHARE_PROJECT': '分享项目',
    /* TT_PROJ_07 */    'COMMON.PROJECT.ADD_TOPIC': '将主题添加到项目',
    /* TT_PROJ_08 */    'COMMON.PROJECT.REMOVE_TOPIC': '从项目中删除主题',
    /* TT_PROJ_09 */    'COMMON.PROJECT.TITLE_LABEL': '选择项目类型：',
    /* TT_PROJ_10 */    'COMMON.PROJECT.TYPE.IOT.NAME': '物联网项目',
    /* TT_PROJ_11 */    'COMMON.PROJECT.TYPE.IOT.DESC': '将 Micro-bit 连接到MakerCloud，享受许多功能，包括数据可视化和仪表板演示',
    /* TT_PROJ_12 */    'COMMON.PROJECT.TYPE.LBS.NAME': '位置基础服务项目（LBS）',
    /* TT_PROJ_13 */    'COMMON.PROJECT.TYPE.LBS.DESC': '让您的 Micro-bit 成为Ibeacon或Eddystone，跟踪您当前的位置，定义可步行节点，为您提供到达目的地的最短路径, 即将推出...',
    /* TT_PROJ_14 */    'COMMON.PROJECT.TYPE.MLP.NAME': '机器学习项目',
    /* TT_PROJ_15 */    'COMMON.PROJECT.TYPE.MLP.DESC': '即将推出...',
    /* TT_PROJ_16 */    'COMMON.PROJECT.POWERED_BY_QBS': '由QBS提供支持',
    /* TT_PROJ_17 */    'COMMON.PROJECT.PROJECT_NAME_PLACEHOLDER': '新的项目名称',
    /* TT_PROJ_18 */    'COMMON.PROJECT.CREATE_SUCCESS': '您的项目已创建。',
    /* TT_PROJ_19 */    'COMMON.PROJECT.MISSING_PROJECT_NAME': '缺少项目名称',
    /* TT_PROJ_20 */    'COMMON.PROJECT.UPDATE_SUCCESS': '您的项目已更新。',
    /* TT_PROJ_21 */    'COMMON.PROJECT.PROJECT_LIST': '项目列表',
    /* TT_PROJ_22 */    'COMMON.PROJECT.REMOVE_TOPIC_TITLE': '从项目中删除主题',
    /* TT_PROJ_23 */    'COMMON.PROJECT.REMOVE_TOPIC_DESC': '这不会删除主题，您可以稍后将其添加回项目！',
    /* TT_PROJ_24 */    'COMMON.PROJECT.SHOW_BRIEF_INFO_TAB': '基本信息',
    /* TT_PROJ_25 */    'COMMON.PROJECT.SHOW_TOPICS_UNDER_PROJECT_TAB': '相关主题',
    /* TT_PROJ_26 */    'COMMON.PROJECT.SHOW_CHART_UNDER_PROJECT_TAB': '相关图表',
    /* TT_PROJ_27 */    'COMMON.PROJECT.DOWNLOAD_DATA_UNDER_PROJECT_TAB': '下载数据',
    /* TT_PROJ_28 */    'COMMON.PROJECT.EVENT_TRIGGER': '数据触发',
    /* TT_PROJ_29 */    'COMMON.PROJECT.QRCODE_DESC': '扫描二维码以加入项目或与其他人分享链接以加入项目',
    /* TT_PROJ_30 */    'COMMON.PROJECT.ALREADY_JOINED_PROJECT': '你已经加入了这个项目',
    /* TT_PROJ_31 */    'COMMON.PROJECT.VIEW_PROJECT_LABEL': '您需要浏览项目内容吗？',
    /* TT_PROJ_32 */    'COMMON.PROJECT.INVALID_PROJECT_SHARE_TOKEN': '该邀请码无效，无法加入该项目。',
    /* TT_PROJ_33 */    'COMMON.PROJECT.CONFIRM_TO_JOIN_PROJECT': '你确定加入这个项目{PROJECT_NAME}吗？',
    /* TT_PROJ_34 */    'COMMON.PROJECT.DATA_VIEWER': '即时数据记录',
    /* TT_PROJ_35 */    'COMMON.PROJECT.SHOW_100_RECORD': '最多显示100条记录',
    /* TT_PROJ_36 */    'COMMON.PROJECT.DELETE_PROJECT_AND_DATA': '刪除項目和所有數據',
    /* TT_PROJ_37 */    'COMMON.PROJECT.MORE_SETTING': '更多设定',
    /* TT_PROJ_38 */    'COMMON.PROJECT.MY_FILES': '我的档案',
    /* TT_PROJ_39 */    'COMMON.PROJECT.FILE_LIST': '档案列表',
    /* TT_PROJ_40 */    'COMMON.PROJECT.FILE_UPLOAD_BOX_TEXT': '拖到此处或点击以上载文件',
    /* TT_PROJ_41 */    'COMMON.PROJECT.FILE_NAME': '档案名称',
    /* TT_PROJ_42 */    'COMMON.PROJECT.FILE_SIZE': '档案大小',
    /* TT_PROJ_43 */    'COMMON.PROJECT.ADD_FILE': '加入档案',
    /* TT_PROJ_44 */    'COMMON.PROJECT.COPY_EMBEDDED_MAP_URL': '复制嵌入连结',
    /* TT_PROJ_45 */    'COMMON.PROJECT.TOTAL_NUMBER_OF_DATA': '数据数量',
    /* TT_PROJ_46 */    'COMMON.PROJECT.NUMBER_OF_EVENT_TRIGGER': '数据触发数量',
    /* TT_PROJ_47 */    'COMMON.PROJECT.NUMBER_OF_CHART': '图表数量',
    /* TT_PROJ_48 */    'COMMON.PROJECT.NUMBER_OF_PROJECT_FILE': '档案数量',
    /* TT_PROJ_49 */    'COMMON.PROJECT.NUMBER_OF_PROJECT_USER': '用户数量',
    /* TT_PROJ_50 */    'COMMON.PROJECT.IOT_USAGE': '物联网\n 功能用量',
    /* TT_PROJ_51 */    'COMMON.PROJECT.AI_USAGE': '人工智能\n 功能用量',
    /* TT_PROJ_52 */    'COMMON.PROJECT.VISUALIZATION_USAGE': '图表\n 功能用量',
    /* TT_PROJ_53 */    'COMMON.PROJECT.OPEN_API_USAGE': '开放API\n 功能用量',
    /* TT_PROJ_54 */    'COMMON.PROJECT.EVENT_TRIGGER_USAGE': '数据触发\n 功能用量',
    /* TT_PROJ_55 */    'COMMON.PROJECT.COLLABORATION_USAGE': '协作\n 功能用量',
    /* TT_PROJ_56 */    'COMMON.PROJECT.TOTAL_MESSAGE_COUNT': '总数据数量',
    /* TT_PROJ_57 */    'COMMON.PROJECT.NUMBER_OF_DATA_TYPE': '数据类型数量',
    /* TT_PROJ_58 */    'COMMON.PROJECT.NUMBER_OF_TOPIC': '主题数量',
    /* TT_PROJ_59 */    'COMMON.PROJECT.PROJECT_OVERVIEW': '項目簡介',
    /* TT_PROJ_60 */    'COMMON.PROJECT.IOT': '物联网',
    /* TT_PROJ_61 */    'COMMON.PROJECT.AI': '人工智能',
    /* TT_PROJ_62 */    'COMMON.PROJECT.OPEN_API': '开放应用程式介面',
    /* TT_PROJ_63 */    'COMMON.PROJECT.EXPLAIN_REAL_TIME_LOGGER': '即时显示项目的活动记录，包括物联网的数据，数据触发，Open API 的使用',
    /* TT_PROJ_64 */    'COMMON.PROJECT.EXPLAIN_FILE_LIST': '你可以上载代码文件，例如：MakeCode/ Python 等等',
    /* TT_PROJ_65 */    'COMMON.PROJECT.EXPLAIN_TOPIC_MESSAGE_COUNT': '显示物联网主题的数据数量',
    /* TT_PROJ_66 */    'COMMON.PROJECT.EXPLAIN_USER_LIST': '邀请别人同共编辑你的项目',
    /* TT_PROJ_67 */    'COMMON.PROJECT.PROJECT_USAGE_ANALYSIS': '用量分析',
    /* TT_PROJ_68 */    'COMMON.PROJECT.EXPLORE_AND_LEARN': '探索/教学',
    /* TT_PROJ_69 */    'COMMON.PROJECT.CREATE_CHART_SOCKET': '已创建图表{CHART_NAME}',
    /* TT_PROJ_70 */    'COMMON.PROJECT.UPLOADED_NEW_FILE_SOCKET': '上载了新文件{FILE_NAME}',
    /* TT_PROJ_71 */    'COMMON.PROJECT.REMOVED_FILE': '已删除文件{FILE_NAME}',
    /* TT_PROJ_72 */    'COMMON.PROJECT.NEW_USER_JOINED': 'New 已加入新用户{USERNAME}',
    /* TT_PROJ_73 */    'COMMON.PROJECT.REMOVED_USER': '已删除用户{USERNAME}',

    /* TT_TOP_01 */    'COMMON.TOPIC.TOPIC': '主题',
    /* TT_TOP_02 */    'COMMON.TOPIC.TOPIC_DESCRIPTION': '主题描述',
    /* TT_TOP_03 */    'COMMON.TOPIC.TOPIC_NAME': '主题名称',
    /* TT_TOP_04 */    'COMMON.TOPIC.TOPIC_LIST': '主题列表',
    /* TT_TOP_05 */    'COMMON.TOPIC.CREATE_TOPIC': '创建主题',
    /* TT_TOP_06 */    'COMMON.TOPIC.UPDATE_TOPIC': '更新主题',
    /* TT_TOP_07 */    'COMMON.TOPIC.DELETE_TOPIC': '删除主题',
    /* TT_TOP_08 */    'COMMON.TOPIC.ADD_PROJECT': '将项目添加到主题',
    /* TT_TOP_09 */    'COMMON.TOPIC.REMOVE_PROJECT': '从主题中删除项目',
    /* TT_TOP_10 */    'COMMON.TOPIC.TALK_TO_TOPIC': '发送消息到主题',
    /* TT_TOP_11 */    'COMMON.TOPIC.SHARE_TOPIC': '分享主题',
    /* TT_TOP_12 */    'COMMON.TOPIC.ACTIVE': '启用',
    /* TT_TOP_13 */    'COMMON.TOPIC.TOPIC_NAME_PLACEHOLDER': '主题名称将自动生成',
    /* TT_TOP_14 */    'COMMON.TOPIC.TOPIC_DESC_PLACEHOLDER': '描述你的主题',
    /* TT_TOP_15 */    'COMMON.TOPIC.TOPIC_MESSAGE_PLACEHOLDER': '文字信息',
    /* TT_TOP_16 */    'COMMON.TOPIC.TOPIC_MESSAGE_EMPTY': '文字信息不能为空白',
    /* TT_TOP_17 */    'COMMON.TOPIC.TOPIC_MESSAGE_SENT': '文字信息成功发送到主题',
    /* TT_TOP_18 */    'COMMON.TOPIC.NO_PROJECT_SELECTED': '没有选择任何项目',
    /* TT_TOP_19 */    'COMMON.TOPIC.TOPIC_DESC_EMPTY': '主题描述不能为空白',
    /* TT_TOP_20 */    'COMMON.TOPIC.CHANGE_TOPIC_DESCRIPTION_TITLE': '更改主题描述',
    /* TT_TOP_21 */    'COMMON.TOPIC.TOPIC_NAME_NOT_FOUND': '找不到主题名称',
    /* TT_TOP_22 */    'COMMON.TOPIC.MQTT_SEND_MESSAGE_TOOL': '物联网信息发送工具',
    /* TT_TOP_23 */    'COMMON.TOPIC.TOPIC_LOCATION': '主题定位',

    /* TT_DATP_01 */   'COMMON.DATA_TYPE.DATA_TYPE': '数据类型',
    /* TT_DATP_02 */   'COMMON.DATA_TYPE.DATA_TYPE_KEY': '识别码',
    /* TT_DATP_03 */   'COMMON.DATA_TYPE.DATA_TYPE_NAME': '名称',
    /* TT_DATP_04 */   'COMMON.DATA_TYPE.DATA_TYPE_DESC': '描述',
    /* TT_DATP_05 */   'COMMON.DATA_TYPE.CREATE_DATA_TYPE': '创建数据类型',
    /* TT_DATP_06 */   'COMMON.DATA_TYPE.UPDATE_DATA_TYPE': '更新数据类型',
    /* TT_DATP_07 */   'COMMON.DATA_TYPE.DELETE_DATA_TYPE': '删除数据类型',
    /* TT_DATP_08 */   'COMMON.DATA_TYPE.DATA_TYPE_LIST': '数据类型列表',
    /* TT_DATP_09 */   'COMMON.DATA_TYPE.UNIQUE_LABEL': '数据类型不能重复',
    /* TT_DATP_10 */   'COMMON.DATA_TYPE.NAME_PLACEHOLDER': '您的数据类型名称',
    /* TT_DATP_11 */   'COMMON.DATA_TYPE.KEY_PLACEHOLDER': '您的数据类型识别码',
    /* TT_DATP_12 */   'COMMON.DATA_TYPE.DESC_PLACEHOLDER': '描述您的数据类型',
    /* TT_DATP_13 */   'COMMON.DATA_TYPE.INVALID_FIELD': '无效的数据类型名称或识别码',
    /* TT_DATP_14 */   'COMMON.DATA_TYPE.DELETE_DATA_TYPE_HELP_TEXT': '您确定要删除数据类型吗？ 所有这些数据类型下的数据也将被删除。 此操作无法恢复。',

    /* TT_DASH_01 */   'COMMON.DASHBOARD.DASHBOARD': '仪表板',
    /* TT_DASH_02 */   'COMMON.DASHBOARD.DELETE_DASHBOARD': '删除仪表板',
    /* TT_DASH_03 */   'COMMON.DASHBOARD.CREATE_DASHBOARD': '创建仪表板',
    /* TT_DASH_04 */   'COMMON.DASHBOARD.UPDATE_DASHBOARD': '更新仪表板',
    /* TT_DASH_05 */   'COMMON.DASHBOARD.SWITCH_DASHBOARD': '切换仪表板',
    /* TT_DASH_06 */   'COMMON.DASHBOARD.NO_CHART_IN_DASHBOARD': '此仪表板中没有图表，您可以将已创建的图表添加到此仪表板中',
    /* TT_DASH_07 */   'COMMON.DASHBOARD.DASHBOARD_CREATED': '您的新仪表盘已创建。',
    /* TT_DASH_08 */   'COMMON.DASHBOARD.DASHBOARD_PLACEHOLDER': '仪表板名称',
    /* TT_DASH_09 */   'COMMON.DASHBOARD.ADD_CHART_TO_DASHBOARD': '添加到仪表板',
    /* TT_DASH_10 */   'COMMON.DASHBOARD.DASHBOARD_NAME_EMPTY': '仪表板名称不能为空白',
    /* TT_DASH_11 */   'COMMON.DASHBOARD.SELECT_A_DASHBOARD': '将图表添加到哪个仪表板？',
    /* TT_DASH_12 */   'COMMON.DASHBOARD.NO_DASHBOARD_SELECTED': '请选择一个仪表板',

    /* TT_CHAR_01 */   'COMMON.CHART.CHART': '图表',
    /* TT_CHAR_02 */   'COMMON.CHART.CREATE_CHART': '创建图表',
    /* TT_CHAR_03 */   'COMMON.CHART.DELETE_CHART': '删除图表',
    /* TT_CHAR_04 */   'COMMON.CHART.UPDATE_CHART': '更新图表',
    /* TT_CHAR_05 */   'COMMON.CHART.VIEW_CHART_DETAIL': '查看详细图表资料',
    /* TT_CHAR_06 */   'COMMON.CHART.CREATE_CHART_TIPS': '只有具有数据的会显示在这里',
    /* TT_CHAR_07 */   'COMMON.CHART.CHART_NAME_EMPTY': '图表名称不能为空白',
    /* TT_CHAR_08 */   'COMMON.CHART.CHART_NAME': '图表名称',
    /* TT_CHAR_09 */   'COMMON.CHART.RECENT_CHART': '最近创建的图表',
    /* TT_CHAR_10 */   'COMMON.CHART.CHOOSE_CHART_TYPE': '选择图表类型',
    /* TT_CHAR_11 */   'COMMON.CHART.GAUGE_CHART': '计量表',
    /* TT_CHAR_12 */   'COMMON.CHART.LINE_CHART': '折线图',
    /* TT_CHAR_13 */   'COMMON.CHART.CHART_SETTING': '图表设置',
    /* TT_CHAR_14 */   'COMMON.CHART.TOGGLE': '切换按钮',
    /* TT_CHAR_15 */   'COMMON.CHART.CHECKBOX': '复选方块',
    /* TT_CHAR_16 */   'COMMON.CHART.ON_OFF_BUTTON': '开关按钮',
    /* TT_CHAR_17 */   'COMMON.CHART.PUSH_BUTTON': '单点按钮',
    /* TT_CHAR_18 */   'COMMON.CHART.PLAIN_TEXT': '纯文字',
    /* TT_CHAR_19 */   'COMMON.CHART.CONTROL_BUTTON': '控制按钮',
    /* TT_CHAR_20 */   'COMMON.CHART.CHOOSE_SOURCE': '选择数据来源',
    /* TT_CHAR_21 */   'COMMON.CHART.SELECT_CHART_TYPE_FIRST': '请选择图表类型',
    /* TT_CHAR_22 */   'COMMON.CHART.SELECTED_CHART_SOURCE': '选择图表来源',
    /* TT_CHAR_23 */   'COMMON.CHART.SELECTED_CHART_TYPE': '已选择的图表类型',
    /* TT_CHAR_24 */   'COMMON.CHART.BUTTON_DISPLAY_TEXT': '显示文字',
    /* TT_CHAR_25 */   'COMMON.CHART.CHART_COLOR': '颜色',
    /* TT_CHAR_26 */   'COMMON.CHART.BUTTON_ACTIVE_VALUE': '启动时数值',
    /* TT_CHAR_27 */   'COMMON.CHART.BUTTON_INACTIVE_VALUE': '非启动时数值',
    /* TT_CHAR_28 */   'COMMON.CHART.CHART_MIN_VALUE': '最小值',
    /* TT_CHAR_29 */   'COMMON.CHART.CHART_MAX_VALUE': '最大值',
    /* TT_CHAR_30 */   'COMMON.CHART.LOCK_BUTTON': '锁开关',
    /* TT_CHAR_31 */   'COMMON.CHART.PLAY_BUTTON': '播放开关',
    /* TT_CHAR_32 */   'COMMON.CHART.SOUND_BUTTON': '音量开关',
    /* TT_CHAR_33 */   'COMMON.CHART.CHART_HISTORY_STARTING_FROM': '开始时间',
    /* TT_CHAR_34 */   'COMMON.CHART.CHART_HISTORY_UNTIL': '完结时间',
    /* TT_CHAR_35 */   'COMMON.CHART.CHART_HISTORY_FOR_EVERY': '相隔时间',
    /* TT_CHAR_36 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_VALUE': '数值取样方法',
    /* TT_CHAR_37 */   'COMMON.CHART.CHART_HISTORY_EVERY_MONTH': '每月',
    /* TT_CHAR_38 */   'COMMON.CHART.CHART_HISTORY_EVERY_DAY': '每天',
    /* TT_CHAR_39 */   'COMMON.CHART.CHART_HISTORY_EVERY_HOUR': '每小时',
    /* TT_CHAR_40 */   'COMMON.CHART.CHART_HISTORY_EVERY_MINUTE': '每分钟',
    /* TT_CHAR_41 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_AVG': '平均',
    /* TT_CHAR_42 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_SUM': '总和',
    /* TT_CHAR_43 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_MIN': '最低值',
    /* TT_CHAR_44 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_MAX': '最大值',
    /* TT_CHAR_45 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_COUNT': '总次数',
    /* TT_CHAR_46 */   'COMMON.CHART.CHART_LIVE_DATA': '最新数据',
    /* TT_CHAR_47 */   'COMMON.CHART.CHART_HISTORY_DATA': '历史数据',
    /* TT_CHAR_48 */   'COMMON.CHART.OUTPUT_TYPE': '传送格式',
    /* TT_CHAR_49 */   'COMMON.CHART.KEY_VALUE_PAIR': '数据类型信息',
    /* TT_CHAR_50 */   'COMMON.CHART.SIMPLE_MESSAGE': '纯文字讯息',
    /* TT_CHAR_51 */   'COMMON.CHART.MESSAGE_TYPE': '信息类别',
    /* TT_CHAR_52 */   'COMMON.CHART.CHOOSE_STYLE': '请选择款式或输入文字',
    /* TT_CHAR_53 */   'COMMON.CHART.WHEN_BUTTON_ON': '开启时',
    /* TT_CHAR_54 */   'COMMON.CHART.WHEN_BUTTON_OFF': '关闭时',
    /* TT_CHAR_55 */   'COMMON.CHART.COPY_EMBEDDED_CHART_URL': '复制嵌入连结',
    /* TT_CHAR_56 */   'COMMON.CHART.POINT_MAP': '圆形符号地图',
    /* TT_CHAR_57 */   'COMMON.CHART.SOURCE_DESCRIPTION': '描述',
    /* TT_CHAR_58 */   'COMMON.CHART.LATITUDE': '纬度',
    /* TT_CHAR_59 */   'COMMON.CHART.LONGITUDE': '经度',
    /* TT_CHAR_60 */   'COMMON.CHART.NOT_AVAILABLE_GOOGLE_MAP': '你的所在地未能支援谷歌地图',
    /* TT_CHAR_61 */   'COMMON.CHART.MIN_MAX_DESCRIPTION': '请提供数据的最小及最大值',
    /* TT_CHAR_62 */   'COMMON.CHART.CHART_TOOLBAR_MOVE': '掌形工具',
    /* TT_CHAR_63 */   'COMMON.CHART.CHART_TOOLBAR_SELECT': '选择工具',
    /* TT_CHAR_64 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_XY': '放大',
    /* TT_CHAR_65 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_X': '放大 x 轴',
    /* TT_CHAR_66 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_Y': '放大 y 轴',
    /* TT_CHAR_67 */   'COMMON.CHART.SHOW_LATEST_POINT': '显示最新',
    /* TT_CHAR_67 */   'COMMON.CHART.SHOW_LATEST_POINT_ALL': '所有',

    /* TT_SCH_01 */    'COMMON.SCHOOL.SCHOOL_LIST': '学校名单',
    /* TT_SCH_02 */    'COMMON.SCHOOL.SCHOOL_ID': '编号',
    /* TT_SCH_03 */    'COMMON.SCHOOL.SCHOOL_NAME': '学校名称',
    /* TT_SCH_04 */    'COMMON.SCHOOL.SCHOOL_EMAIL_DOMAIN': '电子邮件',
    /* TT_SCH_05 */    'COMMON.SCHOOL.VALID_UNTIL': '有效期至',
    /* TT_SCH_06 */    'COMMON.SCHOOL.VALID_DATE': '有效期',
    /* TT_SCH_07 */    'COMMON.SCHOOL.APPLICATION_STATUS': '申请状态',
    /* TT_SCH_08 */    'COMMON.SCHOOL.NO_PENDING_APPLICATION': '没有待处理的申请',
    /* TT_SCH_09 */    'COMMON.SCHOOL.INACTIVE': '未启用',
    /* TT_SCH_10 */    'COMMON.SCHOOL.PROCESSED': '已处理',
    /* TT_SCH_11 */    'COMMON.SCHOOL.APPLICATION': '申请',
    /* TT_SCH_12 */    'COMMON.SCHOOL.TOTAL': '总共',
    /* TT_SCH_13 */    'COMMON.SCHOOL.APPROVED': '已批准',
    /* TT_SCH_14 */    'COMMON.SCHOOL.REJECTED': '已拒绝',
    /* TT_SCH_15 */    'COMMON.SCHOOL.PENDING': '待处理',
    /* TT_SCH_16 */    'COMMON.SCHOOL.SUBMISSION_TIME': '申请时间',
    /* TT_SCH_17 */    'COMMON.SCHOOL.APPROVE': '批准',
    /* TT_SCH_18 */    'COMMON.SCHOOL.REJECT': '拒绝',
    /* TT_SCH_19 */    'COMMON.SCHOOL.OFFICIAL_REMARKS': '備註',
    /* TT_SCH_20 */    'COMMON.SCHOOL.CONFIRM_APPROVE_APPLICATION': '您确定要批准申请？',
    /* TT_SCH_21 */    'COMMON.SCHOOL.CONFIRM_REJECT_APPLICATION': '你确定拒绝申请吗？',

    /* TT_EVENT_01 */  'COMMON.EVENT.EVENT_NAME': '活动名称',
    /* TT_EVENT_02 */  'COMMON.EVENT.EVENT_CODE': '活动代码',
    /* TT_EVENT_03 */  'COMMON.EVENT.EVENT_TRIAL_PERIOD': '活动试用期',

    /* TT_VIRT_01 */  'COMMON.VIRTUAL.VIRTUAL_SMART_HOME': '虚拟智能家居',
    /* TT_VIRT_02 */  'COMMON.VIRTUAL.SETTING': '设定',
    /* TT_VIRT_03 */  'COMMON.VIRTUAL.HARDWARE_SETTING': '硬件设定',
    /* TT_VIRT_04 */  'COMMON.VIRTUAL.TURN_ON': '开启',
    /* TT_VIRT_05 */  'COMMON.VIRTUAL.TURN_OFF': '关闭',
    /* TT_VIRT_06 */  'COMMON.VIRTUAL.TURN_ON_PLACEHOLDER': '开启',
    /* TT_VIRT_07 */  'COMMON.VIRTUAL.TURN_OFF_PLACEHOLDER': '关闭',
    /* TT_VIRT_08 */  'COMMON.VIRTUAL.SAVE': '保存',
    /* TT_VIRT_09 */  'COMMON.VIRTUAL.LIGHT_BULB': '灯泡',
    /* TT_VIRT_10 */  'COMMON.VIRTUAL.VIRTUAL_LIGHT_BULB': '虚拟灯泡',

    /* ET_01 */        'EVENT_TRIGGER.TRIGGER_TITLE': '当以下情况发生时',
    /* ET_02 */        'EVENT_TRIGGER.FILTER_TITLE': '当以下条件为正确',
    /* ET_03 */        'EVENT_TRIGGER.ACTION_TITLE': '然后',
    /* ET_04 */        'EVENT_TRIGGER.NEW_TOPIC_MESSAGE_TRIGGER': '主题收到任何信息',
    /* ET_05 */        'EVENT_TRIGGER.MACHINE_LEARNING_COMPLETED': '人工智能程序完成',
    /* ET_06 */        'EVENT_TRIGGER.AND': '及',
    /* ET_07 */        'EVENT_TRIGGER.OR': '或',
    /* ET_08 */        'EVENT_TRIGGER.SAME_LEVEL': '加到同一层',
    /* ET_09 */        'EVENT_TRIGGER.SUB_LEVEL': '加到下一层',
    /* ET_10 */        'EVENT_TRIGGER.TOPIC': 'MQTT 主题',
    /* ET_11 */        'EVENT_TRIGGER.S_MESSAGE': '的信息',
    /* ET_12 */        'EVENT_TRIGGER.S': '的',
    /* ET_13 */        'EVENT_TRIGGER.SUBJECT_KEY': '数据类型',
    /* ET_14 */        'EVENT_TRIGGER.OBJECT_KEY': '内容',
    /* ET_15 */        'EVENT_TRIGGER.COMPARATOR': '比较',
    /* ET_16 */        'EVENT_TRIGGER.TOPIC_NAME': '主题名称',
    /* ET_17 */        'EVENT_TRIGGER.ADD': '加入',
    /* ET_18 */        'EVENT_TRIGGER.PUBLISH_MQTT_MESSAGE': '发布信息',
    /* ET_19 */        'EVENT_TRIGGER.SEND_SMS': '发送 SMS 信息',
    /* ET_10 */        'EVENT_TRIGGER.SEND_EMAIL': '发送电邮',
    /* ET_21 */        'EVENT_TRIGGER.PUBLISH': '发送',
    /* ET_22 */        'EVENT_TRIGGER.TO_TOPIC': '到 MQTT 主题',
    /* ET_23 */        'EVENT_TRIGGER.APPLET': '小程式',
    /* ET_24 */        'EVENT_TRIGGER.VALIDATION_FAILED': '资料错误！',
    /* ET_25 */        'EVENT_TRIGGER.TRIGGER_VALIDATION_FAILED': '请确保您已选择至少一个条件并填写正确的信息',
    /* ET_26 */        'EVENT_TRIGGER.FILTER_VALIDATION_FAILED': '请确保您已选择至少一个条件并填写正确的信息',
    /* ET_27 */        'EVENT_TRIGGER.ACTION_VALIDATION_FAILED': '请确保您已选择至少一个操作并填写正确的信息',
    /* ET_28 */        'EVENT_TRIGGER.ADD_NEW_FILTER': '加入条件',
    /* ET_29 */        'EVENT_TRIGGER.WHEN': '當',
    /* ET_30 */        'EVENT_TRIGGER.CONDITION': '条件',
    /* ET_31 */        'EVENT_TRIGGER.SIMPLE_MESSAGE_CONDITION': '当 MQTT 主题收到指定内容',
    /* ET_32 */        'EVENT_TRIGGER.KEY_VALUE_CONDITION': '当 MQTT 主题收到数据类型为指定内容',
    /* ET_33 */        'EVENT_TRIGGER.MESSAGE': '信息',
    /* ET_34 */        'EVENT_TRIGGER.IF': '如果',
    /* ET_35 */        'EVENT_TRIGGER.CONTAINS': '包含',
    /* ET_36 */        'EVENT_TRIGGER.NOT_CONTAIN': '不包含',
    /* ET_37 */        'EVENT_TRIGGER.CREATE_APPLET': '新增小程式',
    /* ET_38 */        'EVENT_TRIGGER.APPLET_LIST': '小程式列表',
    /* ET_39 */        'EVENT_TRIGGER.APPLET_NAME': '小程式名称',
    /* ET_40 */        'EVENT_TRIGGER.APPLET_NAME_VALIDATION_FAILED': '请给你的小程式命名',
    /* ET_41 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_ACTION': '触发 IFTTT Webhook',
    /* ET_42 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_EVENT': 'IFTTT Webhook 事件',
    /* ET_43 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_KEY': 'IFTTT Webhook 钥匙',
    /* ET_44 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_JSON_CONTENT': '补充信息',
    /* ET_45 */        'EVENT_TRIGGER.WEBHOOK_IS_TRIGGERED': 'Webhook 被触发',
    /* ET_46 */        'EVENT_TRIGGER.WEBHOOK_NAME': 'Webhook名称',
    /* ET_47 */        'EVENT_TRIGGER.WEBHOOK_URL': 'Webhook网址',
    /* ET_48 */        'EVENT_TRIGGER.WEBHOOK_URL_HIDDEN_DESCRIPTION': 'Webhook网址会在创建后显示',
    /* ET_49 */        'EVENT_TRIGGER.DIALOGFLOW_INTENT_IS_TRIGGED': 'Dialogflow Intent被触发',
    /* ET_50 */        'EVENT_TRIGGER.INTENT_NAME': 'Intent名称',
    /* ET_51 */        'EVENT_TRIGGER.DIALOGFLOW_URL': 'Dialogflow网址',
    /* ET_52 */        'EVENT_TRIGGER.DIALOGFLOW_URL_HIDDEN_DESCRIPTION': 'Dialogflow网址会在创建后显示',
    /* ET_53 */        'EVENT_TRIGGER.APPLET_ENABLE': '启用',
    /* ET_54 */        'EVENT_TRIGGER.MQTT_TRIGGER_NAME': 'MQTT 触发',
    /* ET_55 */        'EVENT_TRIGGER.WEB_HOOK_TRIGGER_NAME': 'Webhook 触发',
    /* ET_56 */        'EVENT_TRIGGER.AI_TRIGGER_NAME': 'AI 触发',
    /* ET_57 */        'EVENT_TRIGGER.DIALOG_FLOW_TRIGGER_NAME': 'DialogFlow 触发',
    /* ET_58 */        'EVENT_TRIGGER.CONDITION_FILTER_NAME': '条件筛选',
    /* ET_59 */        'EVENT_TRIGGER.IFTTT_ACTION_NAME': '通知 IFTTT',
    /* ET_60 */        'EVENT_TRIGGER.MQTT_ACTION_NAME': '通知 MQTT',
    /* ET_61 */        'EVENT_TRIGGER.OBJECT_IS_DETECTED': '当图像识别发现物件',
    /* ET_62 */        'EVENT_TRIGGER.FACE_IS_DETECTED': '当人睑识别发现人睑',
    /* ET_63 */        'EVENT_TRIGGER.SEND_HTTP_RESPONSE_ACTION': '设置 HTTP Response',
    /* ET_64 */        'EVENT_TRIGGER.SEND_HTTP_RESPONSE_ACTION_TITLE': '设置 HTTP Response',
    /* ET_65 */        'EVENT_TRIGGER.FACE_DETECTION_TRIGGER': '人脸检测触发',
    /* ET_66 */        'EVENT_TRIGGER.OBJECT_DETECTION_TRIGGER': '图像识别触发',
    /* ET_67 */        'EVENT_TRIGGER.UPDATE_GOOGLE_SHEET': '更新谷歌表格',
    /* ET_68 */        'EVENT_TRIGGER.ALLOW_EDIT_GOOGLE_SHEET': '第 1 步：允许我们编辑您的 Google 表格',
    /* ET_69 */        'EVENT_TRIGGER.GOOGLE_SHEET_URL': '第 2 步：Google 表格 URL',
    /* ET_70 */        'EVENT_TRIGGER.GET_GOOGLE_SHEET_ID': '获取 ID',
    /* ET_71 */        'EVENT_TRIGGER.CHANGE_GOOGLE_SHEET_URL': '更改网址',
    /* ET_72 */        'EVENT_TRIGGER.GOOGLE_SHEET_FEATURE_TITLE': '更新谷歌表格',

    /* MY_SUB_01 */    'MY_SUB.MY_SUBSCRIPTION': '我的订阅状态',
    /* MY_SUB_02 */    'MY_SUB.MANAGE_MY_SUBSCRIPTION': '管理我的订阅状态',
    /* MY_SUB_03 */    'MY_SUB.USE_LICENSE_KEY': '使用授权码',
    /* MY_SUB_04 */    'MY_SUB.USE_EVENT_CODE': '使用活动码',
    /* MY_SUB_05 */    'MY_SUB.ENTER_LICENSE_KEY': '输入授权码',
    /* MY_SUB_06 */    'MY_SUB.LICENSE_KEY': '授权码',
    /* MY_SUB_07 */    'MY_SUB.ENTER_LICENSE_KEY_HINTS': '如果授权码已过期，请联系我们绩期。',
    /* MY_SUB_08 */    'MY_SUB.ENTER_EVENT_CODE': '输入活动码',
    /* MY_SUB_09 */    'MY_SUB.EVENT_CODE': '活动码',
    /* MY_SUB_10 */    'MY_SUB.ENTER_EVENT_CODE_HINTS': '除非另有说明，否则活动码将在7天后过期。',
    /* MY_SUB_11 */    'MY_SUB.SUBMIT_BUTTON': '使用',
    /* MY_SUB_12 */    'MY_SUB.SUB_STATUS_PREFIX': '您的帐户已',
    /* MY_SUB_13 */    'MY_SUB.ACTIVE': '生效',
    /* MY_SUB_14 */    'MY_SUB.INACTIVE': '过期',
    /* MY_SUB_15 */    'MY_SUB.WHITELIST_STATEMENT': '您已被加入到白名單中。',
    /* MY_SUB_16 */    'MY_SUB.SCHOOL_SUB_STATEMENT': '你的学校或组织已申请大量授权。',
    /* MY_SUB_17 */    'MY_SUB.EVENT_CODE_STATEMENT': '你已申请活动码授权。',
    /* MY_SUB_18 */    'MY_SUB.LICENSE_SUB_STATEMENT': '你已申请授权码授权。',
    /* MY_SUB_19 */    'MY_SUB.LICENSED_TO': '授权到',
    /* MY_SUB_20 */    'MY_SUB.CONCURRENT_LOGIN': '同时登入人数限制',
    /* MY_SUB_21 */    'MY_SUB.CONSUMED': '最多使用人次',
    /* MY_SUB_22 */    'MY_SUB.EXPIRY_DATE': '到期日',
    /* MY_SUB_23 */    'MY_SUB.NO_EXPIRE_DATE': '您的帐户不会过期。',
    /* MY_SUB_24 */    'MY_SUB.EXPIRE_DATE': '到期时间',
    /* MY_SUB_25 */    'MY_SUB.VIEW_LICENSE_DETAIL_BUTTON': '查看授权码资料',
    /* MY_SUB_26 */    'MY_SUB.MANAGE_LICENSE': '授权码管理',
    /* MY_SUB_27 */    'MY_SUB.UPGRADE_LICENSE_TITLE': '升级授权',
    /* MY_SUB_28 */    'MY_SUB.UPGRADE_LICENSE_MSG': '升级您的授权以享受更多功能!',

    /* OA_001 */    'OPEN_API.OPEN_API_DESC': '使用应用程序介面（API）连接到MakerCloud的各种服务。应用程序介面提供HTTP接口，用户可以通过网络客户端（网络客户端）连接。',
    /* OA_002 */    'OPEN_API.OPEN_API_KEY_TITLE': '连接钥匙（Open Api令牌）',
    /* OA_003 */    'OPEN_API.AI_CATEGORY': '人工智能（AI）类别',
    /* OA_004 */    'OPEN_API.IMAGE_RECOGNITION_TITLE': '图像识别',
    /* OA_005 */    'OPEN_API.IMAGE_RECOGNITION_DESC': '图像识别是指利用电脑对图像进行处理，分析和理解，以识别图像中的物体',
    /* OA_006 */    'OPEN_API.IMAGE_CLASSIFICATION_TITLE': '图像分类',
    /* OA_007 */    'OPEN_API.IMAGE_CLASSIFICATION_DESC': '图像分类是根据图像的分析结果，使用分类器把图像分类',
    /* OA_008 */    'OPEN_API.FACE_ANALYSIS_TITLE': '人脸分析',
    /* OA_009 */    'OPEN_API.FACE_ANALYSIS_DESC': '人脸分析能即时分析你的脸部位置，并显示出来',
    /* OA_010 */    'OPEN_API.TEXT_ANALYSIS_TITLE': '文字分析',
    /* OA_011 */    'OPEN_API.TEXT_ANALYSIS_DESC': '使用文字分析将非结构化文字转换成有意义的见解。取得情感分析、关键片语撷取及语言与实体侦测',
    /* OA_012 */    'OPEN_API.AUDIO_ANALYSIS_TITLE': '音讯分析',
    /* OA_013 */    'OPEN_API.AUDIO_ANALYSIS_DESC': '音讯分析将音讯转译成文字',
    /* OA_014 */    'OPEN_API.IOT_CATEGORY': '物联网 (IoT) 类别',
    /* OA_015 */    'OPEN_API.IOT_DATA_STORE_TITLE': '物联网数据存储',
    /* OA_016 */    'OPEN_API.IOT_DATA_STORE_DESC': '将物联网的硬体数据存储到创客云，并根据不同的主题、数据类型，硬体分类数据',
    /* OA_017 */    'OPEN_API.IOT_LOCATION_TITLE': '物联网位置应用',
    /* OA_018 */    'OPEN_API.IOT_LOCATION_DESC': '根据物联网主题的地理位置，而作出计算的应用',
    /* OA_019 */    'OPEN_API.IOT_TOPIC_TITLE': '物联网主题应用',
    /* OA_020 */    'OPEN_API.IOT_TOPIC_DESC': '使用数据接口取得物联网主题的资料',
    /* OA_021 */    'OPEN_API.MAKER_CLOUD_CATEGORY': '创客云 (MakerCloud) 类别',
    /* OA_022 */    'OPEN_API.UCOA_TITLE': '用户自订开放接口',
    /* OA_023 */    'OPEN_API.UCOA_DESC': '由用户自行定义的开放应用程式介面，用户能设定对应的输入、处理逻辑及输出内容',
    /* OA_024 */    'OPEN_API.PROJECT_API_TITLE': '项目应用',
    /* OA_025 */    'OPEN_API.PROJECT_API_DESC': '使用数据接口取得创客云项目的资料',
    /* OA_026 */    'OPEN_API.OPEN_DATA_CATEGORY': '开放数据 (Open Data) 类别',
    /* OA_027 */    'OPEN_API.WEATHER_API_TITLE': '天气数据应用',
    /* OA_028 */    'OPEN_API.WEATHER_API_DESC': '使用数据接口取得即时、一天、及七天的天气资料',
    /* OA_029 */    'OPEN_API.CURRENCY_API_TITLE': '货币汇率应用',
    /* OA_030 */    'OPEN_API.CURRENCY_API_DESC': '使用数据接口取得每天不同的凡货币汇率',
    /* OA_031 */    'OPEN_API.SCHOOL_API_TITLE': '学校列表',
    /* OA_032 */    'OPEN_API.SCHOOL_API_DESC': '使用数据接口取得香港所有学校的资料',
    /* OA_033 */    'OPEN_API.GENERIC_DATA_STORE_APPLICATION_CATEGORY': '通用数据存储及应用类别',
    /* OA_034 */    'OPEN_API.GENERIC_DATA_STORE_TITLE': '通用数据存储',
    /* OA_035 */    'OPEN_API.GENERIC_DATA_STORE_DESC': '让用户把不同类型都数据存储到创客云',
    /* OA_036 */    'OPEN_API.ACCESS_CONTROL_TITLE': '自订存取控制',
    /* OA_037 */    'OPEN_API.ACCESS_CONTROL_DESC': '让用户设定存取控制的权限',
    /* OA_038 */    'OPEN_API.TIME_TRACKER_TITLE': '计时器',
    /* OA_039 */    'OPEN_API.TIME_TRACKER_DESC': '让用户开始、暂停，及继续计时',
    /* OA_040 */    'OPEN_API.CODE_FILE_STORAGE_TITLE': '代码文件存储',
    /* OA_041 */    'OPEN_API.CODE_FILE_STORAGE_DESC': '让用户上传及下载代码文件',
    /* OA_042 */    'OPEN_API.OPEN_API_TITLE': '开放应用程序介面',
    /* OA_042 */    'OPEN_API.WEATHER.TEMPERATURE': '温度',
    /* OA_042 */    'OPEN_API.WEATHER.HUMIDITY': '湿度',
    /* OA_042 */    'OPEN_API.WEATHER.UV_INDEX': '紫外线指数',
    /* OA_042 */    'OPEN_API.WEATHER.RAINFALL': '降雨量',

    /* AI_001 */    'AI.IMAGE_RECOGNITION_TITLE': '图像识别',
    /* AI_002 */    'AI.IMAGE_RECOGNITION_DESC': '图像识别是指利用电脑对图像进行处理，分析和理解，以识别图像中的物体',
    /* AI_003 */    'AI.IMAGE_STYLE_TRANSFER_TITLE': '图片艺术师',
    /* AI_004 */    'AI.IMAGE_STYLE_TRANSFER_DESC': '使用深度学习分析一张图片的特性,并应用到另一张图片上, 最后产生一张新的图片',
    /* AI_005 */    'AI.IMAGE_CLASSIFICATION_TITLE': '图像分类',
    /* AI_006 */    'AI.IMAGE_CLASSIFICATION_DESC': '图像分类是根据图像的分析结果，使用分类器把图像分类',
    /* AI_007 */    'AI.AUDIO_CLASSIFICATION_TITLE': '声音分类',
    /* AI_008 */    'AI.AUDIO_CLASSIFICATION_DESC': '声音分类是根据声音的分析结果，使用分类器把声音分类',
    /* AI_009 */    'AI.POSE_CAPTURE_TITLE': '姿势检测',
    /* AI_010 */    'AI.POSE_CAPTURE_DESC': '使用机器学习来检测和分类您的姿势',
    /* AI_011 */    'AI.FACE_DETECTION_TITLE': '人脸检测',
    /* AI_012 */    'AI.FACE_DETECTION_DESC': '使用机器学习来检测图像中人脸的位置',
    /* AI_013 */    'AI.IMAGE_TRANSFER.STEP_1': '第一步：上传第一张图片',
    /* AI_014 */    'AI.IMAGE_TRANSFER.STEP_2': '第二步：上传你想要的风格',
    /* AI_015 */    'AI.IMAGE_TRANSFER.STEP_3': '风格转换后的结果',
    /* AI_016 */    'AI.IMAGE_TRANSFER.DETECTED_OBJECTS': '检测到的物体',
    /* AI_017 */    'AI.IMAGE_TRANSFER.TRIGGER_IN_SECONDS': ' 秒内触发',
    /* AI_018 */    'AI.IMAGE_TRANSFER.SEND_INTERVAL': '发送间隔',
    /* AI_019 */    'AI.IMAGE_TRANSFER.MIN_SCORE': '最低分数',
    /* AI_020 */    'AI.IMAGE_TRANSFER.TRIGGER_IN_PREFIX': '在',

    /* PER_01 */    'PERMISSION.YOUR_PERMISSIONS': '您的权限',
    /* PER_02 */    'PERMISSION.PROJECT': '项目',
    /* PER_03 */    'PERMISSION.SHARE_PROJECT': '分享项目',
    /* PER_04 */    'PERMISSION.PROJECT_COUNT': '项目数量',
    /* PER_05 */    'PERMISSION.TOPIC_COUNT': '主题数量',
    /* PER_06 */    'PERMISSION.DATA': '数据',
    /* PER_07 */    'PERMISSION.INTERVAL_LIMIT': '间隔限制',
    /* PER_08 */    'PERMISSION.DAILY_LIMIT': '每日限额',
    /* PER_09 */    'PERMISSION.EXPORT_DATA': '下载数据',
    /* PER_10 */    'PERMISSION.EVENT_TRIGGER': '数据触发',
    /* PER_11 */    'PERMISSION.MQTT_TRIGGER': 'MQTT 触发',
    /* PER_12 */    'PERMISSION.WEB_HOOK_TRIGGER': 'Webhook 触发',
    /* PER_13 */    'PERMISSION.DIALOGFLOW_TRIGGER': 'Dialogflow 触发',
    /* PER_14 */    'PERMISSION.MQTT_ACTION': '通知 MQTT',
    /* PER_15 */    'PERMISSION.IFTTT_ACTION': '通知 IFTTT',
    /* PER_16 */    'PERMISSION.PROJECT_MAP': '项目地图',
    /* PER_17 */    'PERMISSION.DYNAMIC_LOCATION_UPDATE': '动态位置更新',
    /* PER_18 */    'PERMISSION.EMBED_PROJECT_MAP': '项目地图嵌入',
    /* PER_19 */    'PERMISSION.CHART': '图表',
    /* PER_20 */    'PERMISSION.LIVE_DATA': '最新数据',
    /* PER_21 */    'PERMISSION.HISTORICAL_DATA': '历史数据',
    /* PER_22 */    'PERMISSION.BASIC_CHART': '基本图表 (折线图, 计量表, 纯文字)',
    /* PER_23 */    'PERMISSION.CIRCLE_POINT_MAP': '圆形符号地图',
    /* PER_24 */    'PERMISSION.EMBED_CHART': '图表嵌入',
    /* PER_25 */    'PERMISSION.WIDGET': '控制按钮',
    /* PER_26 */    'PERMISSION.BUTTON': '单点按钮',
    /* PER_27 */    'PERMISSION.TOGGLE': '切换按钮',
    /* PER_28 */    'PERMISSION.SUPPORTED': '支持',
    /* PER_29 */    'PERMISSION.UNLIMITED': '无限',
    /* PER_30 */    'PERMISSION.NOT_SUPPORT': '不支持',

    /* MAINTAIN_01 */  'MAINTAIN.PRE_MAINTENANCE_PERIOD_WARNING_MESSAGE': '系统将于{START_FROM}至{END_UNTIL}进行升级，届时系统服务将受到影响或暂时性中断。如有任何问题，请电邮至support@scaleinnotech.com。',
    /* MAINTAIN_02 */  'MAINTAIN.MAINTENANCE_PERIOD_LABEL': '系统升级',

    /* TT_ACT_01 */    'COMMON.ACTION.ADD': '增加',
    /* TT_ACT_02 */    'COMMON.ACTION.CREATE': '创建',
    /* TT_ACT_03 */    'COMMON.ACTION.EDIT': '编辑',
    /* TT_ACT_04 */    'COMMON.ACTION.SHARE': '分享',
    /* TT_ACT_05 */    'COMMON.ACTION.SEND': '发送',
    /* TT_ACT_06 */    'COMMON.ACTION.REMOVE': '除去',
    /* TT_ACT_07 */    'COMMON.ACTION.DELETE': '删除',
    /* TT_ACT_08 */    'COMMON.ACTION.SEARCH': '搜索',
    /* TT_ACT_09 */    'COMMON.ACTION.COPY': '复制',
    /* TT_ACT_10 */    'COMMON.ACTION.ACTION_FAILED': '指令失效。请稍后再试，如果您认为这是一个错误，请与我们联系',
    /* TT_ACT_11 */    'COMMON.ACTION.NO_RECORD': '没有找到记录',
    /* TT_ACT_12 */    'COMMON.ACTION.LOADING_MESSAGE': '请稍候..',
    /* TT_ACT_13 */    'COMMON.ACTION.CONFIRM': '确认',
    /* TT_ACT_14 */    'COMMON.ACTION.CANCEL': '取消',
    /* TT_ACT_15 */    'COMMON.ACTION.ADD_SUCCESS': '成功添加！',
    /* TT_ACT_16 */    'COMMON.ACTION.CREATE_SUCCESS': '成功创建！',
    /* TT_ACT_17 */    'COMMON.ACTION.UPDATE_SUCCESS': '成功更新！',
    /* TT_ACT_18 */    'COMMON.ACTION.REMOVE_SUCCESS': '成功删除了！',
    /* TT_ACT_19 */    'COMMON.ACTION.FAILED': '无法完成！',
    /* TT_ACT_20 */    'COMMON.ACTION.COPY_SUCCESS': '已成功复制！',
    /* TT_ACT_21 */    'COMMON.ACTION.SHOW': '显示：',
    /* TT_ACT_22 */    'COMMON.ACTION.VIEW': '立即查看',
    /* TT_ACT_23 */    'COMMON.ACTION.EXPAND': '放大',
    /* TT_ACT_24 */    'COMMON.ACTION.COLLAPSE': '缩小',
    /* TT_ACT_25 */    'COMMON.ACTION.CLOSE': '关闭',
    /* TT_ACT_26 */    'COMMON.ACTION.PREVIOUS': '上一步',
    /* TT_ACT_27 */    'COMMON.ACTION.NEXT': '下一步',
    /* TT_ACT_28 */    'COMMON.ACTION.OK': '确定',
    /* TT_ACT_29 */    'COMMON.ACTION.FILL_IN_ALL_DATA': '请填写所有资料',
    /* TT_ACT_30 */    'COMMON.ACTION.SAVE': '保存',
    /* TT_ACT_31 */    'COMMON.ACTION.MQTT_APPLET_TRIGGERED': '{APPLET_NAME} 的 MQTT 触发 {NAME} 已触发',
    /* TT_ACT_32 */    'COMMON.ACTION.WEBHOOK_APPLET_TRIGGERED': '{APPLET_NAME} 的 Webhook 触发 {NAME} 已触发',
    /* TT_ACT_33 */    'COMMON.ACTION.DIALOG_FLOW_APPLET_TRIGGERED': '{APPLET_NAME} 的 DialogFlow 触发 {NAME} 已触发',
    /* TT_ACT_34 */    'COMMON.ACTION.APPLET_FILTER_RESULT_SUCCESS': '{APPLET_NAME} 条件检查为通过',
    /* TT_ACT_35 */    'COMMON.ACTION.APPLET_FILTER_RESULT_FALL': '{APPLET_NAME} 条件检查不通过，将不进行动作',
    /* TT_ACT_36 */    'COMMON.ACTION.IFTTT_ACTION_DONE': '{APPLET_NAME} 已通知 IFTTT',
    /* TT_ACT_37 */    'COMMON.ACTION.MQTT_ACTION_DONE': '{APPLET_NAME} 已发送信息到MQTT',
    /* TT_ACT_38 */    'COMMON.ACTION.HTTP_RESPONSE_ACTION_DONE': '{APPLET_NAME} 已发送HTTP 回应',
    /* TT_ACT_39 */    'COMMON.ACTION.TOPIC_RECEIVED_MSG': '{TOPIC_NAME} 收到信息 {MSG}',
    /* TT_ACT_40 */    'COMMON.ACTION.OBJECT_DETECTION_APPLET_TRIGGERED': '{APPLET_NAME} 的图像识别触发 {NAME} 已触发',
    /* TT_ACT_41 */    'COMMON.ACTION.FACE_DETECTION_APPLET_TRIGGERED': '{APPLET_NAME} 的人脸检测触发已触发',
    /* TT_ACT_42 */    'COMMON.ACTION.RENAME': '更改名称',

    /* TT_HOME_01 */   'COMMON.HOME': '主页',
    /* TT_HOME_02 */   'COMMON.HOME.BACK_TO_HOME': '返回主页',

    /* TT_BRD_01 */    'COMMON.BREADCRUMB.DETAIL': '详细资料',

    /* TT_DEVI_01 */   'COMMON.DEVICE.DEVICE': '物联网设备',
    /* TT_DEVI_02 */   'COMMON.DEVICE.DEVICE_SERIAL_NUMBER': '设备序列号',
    /* TT_DEVI_03 */   'COMMON.DEVICE.DEVICE_NAME': '设备名称',

    /* TT_MENU_01 */   'COMMON.MENU.USER_LIST': '用户清单',
    /* TT_MENU_02 */   'COMMON.MENU.WHITELIST_USER': '白名单用户',

    /* TT_COM_01 */   'COMMON.CREATED_ON': '创建时间',
    /* TT_COM_02 */   'COMMON.CREATED_BY': '创建者',
    /* TT_COM_03 */   'COMMON.MODIFIED_ON': '修改者',
    /* TT_COM_04 */   'COMMON.MODIFIED_BY': '修改时间',
    /* TT_COM_05 */   'COMMON.ID': '编号',
    /* TT_COM_06 */   'COMMON.STEP.STEP_1': '第1步',
    /* TT_COM_07 */   'COMMON.STEP.STEP_2': '第2步',
    /* TT_COM_08 */   'COMMON.STEP.STEP_3': '第3步',
    /* TT_COM_09 */   'COMMON.STEP.STEP_4': '第4步',
    /* TT_COM_10 */   'COMMON.TIME': '时间'


};
export default messages;