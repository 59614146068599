// @flow


class Constant {

    static socket = {
        data: {
            room: {
                join: "REQUEST_DATA",
                leave: "UNDO_REQUEST_DATA",
                newData: "READ_DATA",
                newChart: "NEW_CHART",
                newFile: "NEW_FILE",
                removeFile: "REMOVE_FILE",
                newUser: "NEW_USER",
                removeUser: "REMOVE_USER",
                mqttPlainMessage: "MQTT_PLAIN_MESSAGE",
                appletLogTrigger: "APPLET_LOG_TRIGGER",
                appletLogFilter: "APPLET_LOG_FILTER",
                appletLogAction: "APPLET_LOG_ACTION",
                userConnected: "USER_CONNECTED"
            }
        }
    };

    static cookies = {
        user: {
            accessToken: "ACCESS_TOKEN",
            refreshToken: "REFRESH_TOKEN",
            expiresIn: "EXPIRES_IN"
        },
        language: "MAKER_CLOUD_LANGUAGE"
    };

    static school = {
        application: {
            status: {
                pending: "pending",
                approved: "approved",
                rejected: "rejected"
            }
        }
    };

    static authority = {
        makerCloudAdmin: "ROLE_MAKER_CLOUD_ADMIN",
        student: "ROLE_STUDENT",
        teacher: "ROLE_TEACHER"
    };

    static language = {
        english: "en",
        traditionalChinese: "zh-HK",
        simplifiedChinese: "zh-CN"
    };

    static theme = {
        whatsNew: {
            icon: "fa fa-bullhorn",
            color: "#516ad0"
        },
        home: {
            icon: "fa fa-home",
            color: "#6a9cda"
        },
        project: {
            icon: "fa fa-folder-open",
            color: "#2bc7c7"
        },
        topic: {
            icon: "fa fa-bullseye",
            color: "#f49080"
        },
        dashboard: {
            icon: "fa fa-desktop",
            color: "#b5c748"
        },
        user: {
            icon: "fa fa-user-alt",
            color: "#bc5090"
        },
        whitelistUser: {
            icon: "fa fa-user-tie",
            color: "#5081bc"
        },
        demo: {
            icon: "fab fa-tencent-weibo",
            color: "#2bc7c7"
        },
        chart: {
            icon: "fa fa-chart-line",
            color: "#ffbb00"
        },
        share: {
            icon: "fab fa-mixcloud",
            color: "#7cbb00"
        },
        dataType: {
            icon: "fa fa-tint",
            color: "#ab6ac3"
        },
        edit: {
            icon: "fa fa-edit",
            color: "#b5c748"
        },
        add: {
            icon: "fa fa-plus",
            color: "#22f69d"
        },
        delete: {
            icon: "fa fa-times-circle",
            color: "#f65314"
        },
        language: {
            icon: "fa fa-language",
            color: ""
        },
        download: {
            icon: "fa fa-cloud-download-alt",
            color: "#575D90"
        },
        realTimeRecord: {
            icon: "flaticon-chat-2",
            color: ""
        },
        expand: {
            icon: "fa fa-expand"
        },
        collapse: {
            icon: "fa fa-compress"
        },
        school: {
            icon: "far fa-building ",
            color: "#575D90"
        },
        license: {
            icon: "fas fa-key fa-sm",
            color: "#ab6ac3"
        },
        adminLicense: {
            icon: "fa fa-money-check",
            color: "#ab6ac3"
        },
        eventCode: {
            icon: "fab fa-flipboard",
            color: "#58508d"
        },
        maintenance: {
            icon: "fa fa-cogs",
            color: "#508d6b"
        },
        setting: {
            icon: "fa fa-cog",
            color: "#a8a1a5"
        },
        virtualSmartHomeLightBulb: {
            icon: "fa fa-lightbulb",
            color: "#a8a1a5"
        },
        eventTrigger: {
            appletList: {
                color: "rgb(230, 48, 34)"
            },
            name: {
                color: "rgb(23, 108, 191)"
            },
            trigger: {
                color: "rgb(0, 122, 75)"
            },
            filter: {
                color: "rgb(0, 164, 166)"
            },
            action: {
                color: "rgb(92, 45, 145)"
            },
        }
    };

    static chart = {
        type: {
            basicLineChart: "Basic Line Chart",
            gauge: "Meter",
            plainTextChart: "PLAIN_TEXT",
            pushButton: "PUSH_BUTTON",
            toggleButton: "TOGGLE",
            pointMap: "POINT_MAP"
        },
        history: {
            thisWeek: "THIS_WEEK",
            today: "TODAY",
            lastHour: "LAST_HOUR",
            last5Minutes: "LAST_5_MINUTES",
            recently: "RECENTLY",
        },
        interval: {
            MONTH: "INTERVAL_MONTH",
            DAY: "INTERVAL_DAY",
            HOUR: "INTERVAL_HOUR",
            MINUTE: "INTERVAL_MINUTE"
        },
        aggregate: {
            AVERAGE: "AGGREGATE_AVG",
            SUM: "AGGREGATE_SUM",
            MIN: "AGGREGATE_MIN",
            MAX: "AGGREGATE_MAX",
            COUNT: "AGGREGATE_COUNT"
        },
        button: {
            style: [
                "fa fa-angle-double-down",
                "fa fa-angle-double-left",
                "fa fa-angle-double-right",
                "fa fa-angle-double-up",
                "fa fa-angle-down",
                "fa fa-angle-left",
                "fa fa-angle-right",
                "fa fa-angle-up",
                "fa fa-arrow-alt-circle-down",
                "fa fa-arrow-alt-circle-right",
                "fa fa-arrow-alt-circle-left",
                "fa fa-arrow-alt-circle-up",
                "fa fa-arrow-down",
                "fa fa-arrow-up",
                "fa fa-arrow-left",
                "fa fa-arrow-right",
                "fa fa-battery-full",
                "fa fa-battery-empty",
                "fa fa-bell",
                "fa fa-bell-slash",
                "fa fa-calendar-check",
                "fa fa-calendar-times",
                "fa fa-calendar-plus",
                "fa fa-calendar-minus",
                "fa fa-check",
                "fa fa-check-circle",
                "fa fa-times",
                "fa fa-times-circle",
                "fa fa-plus",
                "fa fa-plus-circle",
                "fa fa-minus",
                "fa fa-minus-circle",
                "fa fa-dice-six",
                "fa fa-dice-five",
                "fa fa-dice-four",
                "fa fa-dice-three",
                "fa fa-dice-two",
                "fa fa-dice-one",
                "fa fa-expand",
                "fa fa-compress",
                "fa fa-eye",
                "fa fa-eye-slash",
                "fa fa-hand-paper",
                "fa fa-hand-scissors",
                "fa fa-hand-rock",
                "fa fa-heart",
                "fab fa-hotjar",
                "fa fa-lightbulb",
                "fa fa-lock",
                "fa fa-lock-open",
                "fa fa-mobile-alt",
                "fa fa-tv",
                "fa fa-moon",
                "fa fa-paper-plane",
                "fa fa-search-plus",
                "fa fa-search-minus",
                "fa fa-star",
                "fa fa-thermometer-full",
                "fa fa-thermometer-empty",
                "fa fa-thumbs-up",
                "fa fa-thumbs-down",
                "fa fa-toggle-off",
                "fa fa-toggle-on",
                "fa fa-tint",
                "fa fa-tint-slash",
                "fa fa-trash-alt",
                "fa fa-unlock",
                "fa fa-unlock-alt",
                "fa fa-video",
                "fa fa-video-slash",
            ]
        }
    };
    static portlet = {
        size: {
            colWidth: {
                w12: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2",
                w6: "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-2",
                w3: "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-2",
                w4: "col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 p-2"
            },
            width: {
                w12: "12",
                w6: "6",
                w3: "3"
            },
            height: {
                h12: "12",
                h6: "6",
                h3: "3"
            }
        }
    };

    static toastr = {
        defaultOptions: {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        },
        noAutoHiding: {
            "timeOut": "0",
            "extendedTimeOut": "0"
        }
    };

    static projectSection = {
        overall: "overall",
        iot: "iot",
        ai: "ai",
        chart: "chart",
        openApi: "open-api",
        eventTrigger: "event-trigger"
    }

    static aiSection = {
        imageRecognition: "image-recognition",
        imageStyleTransfer: "image-style-transfer",
        imageClassification: "image-classification",
        textToSpeech: "text-to-speech",
        audioClassification: "audio-classification",
        poseCapture: "pose-capture",
        faceDetection: "face-detection"
    }

    static openApiSection = {
        imageRecognition: "image-recognition",
        imageClassification: "image-classification",
        project: "project",
        comingSoon: "coming-soon",
    }

    static objectDetectionAllClasses = [
        "person",
        "bicycle",
        "car",
        "motorcycle",
        "airplane",
        "bus",
        "train",
        "truck",
        "boat",
        "traffic light",
        "fire hydrant",
        "stop sign",
        "parking meter",
        "bench",
        "bird",
        "cat",
        "dog",
        "horse",
        "sheep",
        "cow",
        "elephant",
        "bear",
        "zebra",
        "giraffe",
        "backpack",
        "umbrella",
        "handbag",
        "tie",
        "suitcase",
        "frisbee",
        "skis",
        "snowboard",
        "sports ball",
        "kite",
        "baseball bat",
        "baseball glove",
        "skateboard",
        "surfboard",
        "tennis racket",
        "bottle",
        "wine glass",
        "cup",
        "fork",
        "knife",
        "spoon",
        "bowl",
        "banana",
        "apple",
        "sandwich",
        "orange",
        "broccoli",
        "carrot",
        "hot dog",
        "pizza",
        "donut",
        "cake",
        "chair",
        "couch",
        "potted plant",
        "bed",
        "dining table",
        "toilet",
        "tv",
        "laptop",
        "mouse",
        "remote",
        "keyboard",
        "cell phone",
        "microwave",
        "oven",
        "toaster",
        "sink",
        "refrigerator",
        "book",
        "clock",
        "vase",
        "scissors",
        "teddy bear",
        "hair drier",
        "toothbrush",
    ];

    static appletLogKey = {
        MQTT_MESSAGE_TRIGGER_KEY: "MQTT_MESSAGE_TRIGGER",
        WEB_HOOK_TRIGGER_KEY: "WEB_HOOK_TRIGGER",
        DIALOGFLOW_TRIGGER_KEY: "DIALOGFLOW_TRIGGER",
        OBJECT_DETECTION_TRIGGER_KEY: "OBJECT_DETECTION_TRIGGER",
        FACE_DETECTION_TRIGGER_KEY: "FACE_DETECTION_TRIGGER",
        PUBLISH_MQTT_MESSAGE_ACTION_KEY: "PUBLISH_MQTT_MESSAGE_ACTION",
        TRIGGER_IFTTT_WEBHOOK_ACTION_KEY: "TRIGGER_IFTTT_WEBHOOK_ACTION",
        HTTP_RESPONSE_ACTION_KEY: "HTTP_RESPONSE_ACTION"

    }
}

export default Constant;