// @flow

import React, {Component} from 'react';
import Constant from "../../bundle/Constant";
import {LLMsg} from "../../IntlCapture";
import DataStore from "../../store/DataStore";
import Image from "../../bundle/Image";

type HeaderLanguageFragmentProps = {
    color?: string
}

class HeaderLanguageFragment extends Component <HeaderLanguageFragmentProps, any> {

    changeToEnglish() {
        DataStore.cookies.set(Constant.cookies.language, Constant.language.english);
        window.location.reload();
    }

    changeToTc() {
        DataStore.cookies.set(Constant.cookies.language, Constant.language.traditionalChinese);
        window.location.reload();
    }

    changeToSc() {
        DataStore.cookies.set(Constant.cookies.language, Constant.language.simplifiedChinese);
        window.location.reload();
    }

    getLanguageButton = (text: string, callback: Function) => {
        return <li className="m-nav__item">
            <a href="javascript:void(0)" className="m-nav__link m-nav__link--active" onClick={() => {
                callback();
            }}>
                <span className="m-nav__link-icon">
                      <i className={Constant.theme.language.icon + " fa-1x"}/>
                </span>
                <span className="m-nav__link-title m-topbar__language-text m-nav__link-text">
                    {text}
                </span>
            </a>
        </li>
    };

    render() {

        let languageLabel;
        let currentLanguage = DataStore.cookies.get(Constant.cookies.language);
        if (currentLanguage === Constant.language.english) {
            languageLabel = "HEADER.LANGUAGE_EN";
        } else if (currentLanguage === Constant.language.traditionalChinese) {
            languageLabel = "HEADER.LANGUAGE_TC";
        } else if (currentLanguage === Constant.language.simplifiedChinese) {
            languageLabel = "HEADER.LANGUAGE_SC";
        } else {
            languageLabel = 'HEADER.LANGUAGE_TC';
        }

        return <li
            className="m-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width pl-1 pr-1 mr-1"
            m-dropdown-toggle="hover" aria-expanded="true" style={{backgroundColor: "#2e40d4"}}>
            <a href="#" className="m-nav__link m-dropdown__toggle">
                <span className="m-nav__link-icon" style={{width: "125px"}}>
                    <span className="m-nav__link-icon-wrapper"
                          style={{color: this.props.color ? this.props.color : "white"}}>
                        {LLMsg(languageLabel)}
                    </span>
                </span>
            </a>
            <div className="m-dropdown__wrapper" style={{zIndex: 101}}>
                <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                      style={{left: 'auto', right: '5.5px'}}/>
                <div className="m-dropdown__inner">
                    <div className="m-dropdown__header m--align-center" style={{
                        background: 'url(' + Image.urls.getLanguageToggleBackgroundImage() + ')',
                        backgroundSize: 'cover'
                    }}>
                        <span className="m-dropdown__header-subtitle">{LLMsg("HEADER.SELECT_LANGUAGE")}</span>
                    </div>
                    <div className="m-dropdown__body">
                        <div className="m-dropdown__content">
                            <ul className="m-nav m-nav--skin-light">

                                {this.getLanguageButton(LLMsg("HEADER.LANGUAGE_TC"), this.changeToTc)}
                                {this.getLanguageButton(LLMsg("HEADER.LANGUAGE_SC"), this.changeToSc)}
                                {this.getLanguageButton(LLMsg("HEADER.LANGUAGE_EN"), this.changeToEnglish)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>;
    }
}

export default HeaderLanguageFragment;