// @flow

import {Link} from "react-router-dom";
import Page from "../../../../bundle/Page";
import styles from "./RegisterButtonWrapper.css";
import {LLMsg} from "../../../../IntlCapture";
import React from "react";

class RegisterButtonWrapper extends React.Component<any, any> {
    render() {
        return <Link
            to={Page.internals.urls.user.register()}
            className={styles.button + " btn m-btn m-btn--pill  m-btn  m-btn m-btn--icon"}>
           <span>
               <i className="far fa-user"/>
              <span>{LLMsg("REGISTER_PAGE.REGISTER_ACCOUNT")}</span>
           </span>
        </Link>;
    }
}

export default RegisterButtonWrapper;