// @flow

import React from "react";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import {LLMsg} from "../../../IntlCapture";


const TopicListCreateTopicButton = () => {

    return <li className="m-portlet__nav-item">
        <Link to={Page.internals.urls.topic.create()}
              className="btn btn-primary m-btn m-btn--custom m-btn--icon m-btn--air">
              <span>
                <i className="la la-plus"/>
                <span>
                    {LLMsg("COMMON.TOPIC.CREATE_TOPIC")}
                </span>
              </span>
        </Link>
    </li>;
};

export default TopicListCreateTopicButton;