// @flow

import React, {Component} from 'react';


class LicenseListTableHeader extends Component<any, any> {

    render() {
        return <thead className="m-datatable__head">
        <tr className="m-datatable__row" style={{left: '0px'}}>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '50px'}} className={"pl-3"}>
                    {"ID"}
                </span>
            </th>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '150px'}}>
                    {"Package Name"}
               </span>
            </th>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '150px'}}>
                    {"Company"}
               </span>
            </th>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '150px'}}>
                    {"Created on"}
               </span>
            </th>
        </tr>
        </thead>;
    }
}

export default LicenseListTableHeader;