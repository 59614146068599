// @flow

import React, {Component} from 'react';
import {IntlProvider, addLocaleData} from 'react-intl';
import IntlCapture from "./IntlCapture";

import localeMessagesEn from './locale/en';
import localeMessagesTc from './locale/tc';
import localeMessagesSc from './locale/sc';
import DataStore from "./store/DataStore";
import Constant from "./bundle/Constant";

import enLocaleData from 'react-intl/locale-data/en';
import zhLocaleData from 'react-intl/locale-data/zh';

class AppIntl extends Component<any, any> {

    componentWillMount() {
        addLocaleData(enLocaleData, zhLocaleData);
    }

    render() {
        let config;
        let language = DataStore.cookies.get(Constant.cookies.language);
        if (language === Constant.language.english) {
            config = {
                locale: Constant.language.english,
                key: Constant.language.english,
                messages: localeMessagesEn
            }
        } else if (language === Constant.language.simplifiedChinese) {
            config = {
                locale: Constant.language.english,
                key: Constant.language.simplifiedChinese,
                messages: localeMessagesSc
            }
        } else {
            config = {
                locale: Constant.language.english,
                key: Constant.language.traditionalChinese,
                messages: localeMessagesTc
            }
        }

        return (
            <IntlProvider {...config}>
                <IntlCapture>
                    {this.props.children}
                </IntlCapture>
            </IntlProvider>
        )
    }
}

export default AppIntl;
