// @flow
import React, {Component} from 'react';
import {LLMsg} from "../../../IntlCapture";
import Api from "../../../bundle/Api";
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import styles from './index.css';
import FilterFragment, {
    DIALOGFLOW_TRIGGER_KEY, FACE_DETECTION_TRIGGER_KEY,
    MQTT_MESSAGE_TRIGGER_KEY, OBJECT_DETECTION_TRIGGER_KEY,
    WEB_HOOK_TRIGGER_KEY
} from "../filter/FilterFragment";
import TriggerFragment from "../trigger/TriggerFragment";
import type {AppletAction} from "../action/ActionFragment";
import ActionFragment from "../action/ActionFragment";
import ConfirmCreateAppletButton from "./ConfirmCreateAppletButton";
import type {FilterCondition} from "../filter/ConditionFragment";
import type {Applet, Project, Topic} from "../../../model/model";
import {DataRecordType} from "../../../model/model";
import Page from "../../../bundle/Page";
import AppletNameFragment from "../applet/AppletNameFragment";
import _ from 'lodash';
import DeleteAppletButton from "./DeleteAppletButton";
import swal from "sweetalert2";
import CreateAppletLink from "../applet/CreateAppletLink";
import UpdateAppletButton from "./UpdateAppletButton";
import ErrorUtil from "../../../util/ErrorUtil";
import LoadingUtil from "../../../util/LoadingUtil";
import BlockPanelItem from "../applet/BlockPanelItem";
import Constant from "../../../bundle/Constant";
import AppletListWrapper from "../applet/AppletListWrapper";
import UserDao from "../../../dao/UserDao";

type DataTriggerFragmentProps = {
    location?: Object,
    history?: Object,
    match?: Match,
    project: Project
}

type DataTriggerFragmentState = {
    project: Project,
    currentApplet: ?Applet,
    appletList: Array<Applet>
};

class DataTriggerFragment extends React.PureComponent<DataTriggerFragmentProps, DataTriggerFragmentState> {

    triggerRef = React.createRef();
    filterRef = React.createRef();
    actionRef = React.createRef();
    appletNameRef = React.createRef();
    topicDropdownValues: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }> = [];

    constructor(props: DataTriggerFragmentProps) {
        super(props);
        this.state = {
            project: {},
            currentApplet: null,
            appletList: []
        };
        this.createApplet = this.createApplet.bind(this);
        this.updateApplet = this.updateApplet.bind(this);
        this.deleteApplet = this.deleteApplet.bind(this);
    }

    componentDidMount(): * {
        let project: Project = this.props.project;
        this.topicDropdownValues = project.topics.map((topic: Topic) => {
            return {
                topicName: topic.topicName,
                description: topic.description,
                dataRecordTypes: topic.dataRecordTypes
            }
        });
        this.setState({project: project});

        this.getAppletList(project.id);
    }

    componentDidUpdate(prevProps: DataTriggerFragmentProps) {
        const prevAppletId = (prevProps.match && prevProps.match.params["appletId"]) || "";
        const projectId = (this.props.match && this.props.match.params["projectId"]) || "";
        const appletId = (this.props.match && this.props.match.params["appletId"]) || "";

        if (prevAppletId && !appletId) {
            // goes to create page
            this.setState({currentApplet: null});
        } else if (prevAppletId !== appletId) {
            this.getAppletList(projectId);
        }

    }

    getAppletDetail = (projectId: string, appletId: string) => {
        LoadingUtil.showFullScreenLoading();
        Api.services.eventTrigger.get.detail(projectId, appletId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                console.log("detail", response.data);
                let applet: Applet = response.data;
                this.setState({currentApplet: applet});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    };

    deleteApplet = () => {

        const projectId = (this.props.match && this.props.match.params["projectId"]) || "";
        const appletId = (this.props.match && this.props.match.params["appletId"]) || "";

        LoadingUtil.showFullScreenLoading();
        Api.services.eventTrigger.delete(projectId, appletId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.REMOVE_SUCCESS"), "", "success");
                this.props.history && this.props.history.replace(Page.internals.urls.project.dataTrigger(this.state.project.id, this.state.project.projectName));
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    };

    getAppletList = (projectId: string) => {

        LoadingUtil.showFullScreenLoading();
        Api.services.eventTrigger.get.list(projectId).then((response) => {

            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                let applets: Array<Applet> = response.data;
                this.setState({appletList: applets});

                const appletId = (this.props.match && this.props.match.params["appletId"]) || "";
                if (appletId) {
                    this.getAppletDetail(projectId, appletId);
                }

            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    };

    createApplet = () => {
        let isTopicTrigger: boolean = this.triggerRef.current && this.triggerRef.current.getIsTopicTriggerSelected() || false;
        let isWebHookTrigger: boolean = this.triggerRef.current && this.triggerRef.current.getIsWebHookTriggerSelected() || false;
        let isDialogflowTrigger: boolean = this.triggerRef.current && this.triggerRef.current.getIsDialogflowTriggerSelected() || false;
        let isAppletNameValid: boolean = this.appletNameRef.current && this.appletNameRef.current.validateAppletName() || false;
        let isTriggerValid: boolean = this.triggerRef.current && this.triggerRef.current.validateTriggerData() || false;
        let isFilterValid: boolean = this.filterRef.current && this.filterRef.current.validateFilterData(isTopicTrigger, isWebHookTrigger, isDialogflowTrigger) || false;
        let isActionValid: boolean = this.actionRef.current && this.actionRef.current.validateActionData() || false;
        if (!isTriggerValid || !isFilterValid || !isActionValid || !isAppletNameValid) {
            console.log({isTriggerValid, isFilterValid, isActionValid});
            return;
        }


        let projectId = this.state.project.id;
        let appletName: ?string = this.appletNameRef.current && this.appletNameRef.current.getAppletName();
        let enabled: boolean = this.appletNameRef.current ? this.appletNameRef.current.getEnabled() : true;
        let triggers: ? Array<{ topicName: string, type: string }> = this.triggerRef.current && this.triggerRef.current.getTriggerData();
        let filters: ? Array<FilterCondition> = this.filterRef.current && this.filterRef.current.getFilterData();
        let actions: ? Array<AppletAction> = this.actionRef.current && this.actionRef.current.getActionData();

        console.log("submit", triggers, filters, actions, appletName, enabled, projectId);

        LoadingUtil.showFullScreenLoading();
        Api.services.eventTrigger.create(triggers, filters, actions, appletName, enabled, projectId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                let applet: Applet = response.data;
                swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), "", "success");
                this.props.history && this.props.history.replace(Page.internals.urls.project.eventTriggerDetail(applet.projectId, applet.id));
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });

    };

    updateApplet = () => {
        let isAppletNameValid: boolean = this.appletNameRef.current && this.appletNameRef.current.validateAppletName() || false;
        let isTriggerValid: boolean = this.triggerRef.current && this.triggerRef.current.validateTriggerData() || false;
        let isFilterValid: boolean = this.filterRef.current && this.filterRef.current.validateFilterData() || false;
        let isActionValid: boolean = this.actionRef.current && this.actionRef.current.validateActionData() || false;
        if (!isTriggerValid || !isFilterValid || !isActionValid || !isAppletNameValid) {
            console.log({isTriggerValid, isFilterValid, isActionValid});
            return;
        }

        let projectId = this.state.project.id;
        let appletName: ?string = this.appletNameRef.current && this.appletNameRef.current.getAppletName();
        let enabled: boolean = this.appletNameRef.current ? this.appletNameRef.current.getEnabled() : true;
        let triggers: ? Array<{ topicName: string, type: string }> = this.triggerRef.current && this.triggerRef.current.getTriggerData();
        let filters: ? Array<FilterCondition> = this.filterRef.current && this.filterRef.current.getFilterData();
        let actions: ? Array<AppletAction> = this.actionRef.current && this.actionRef.current.getActionData();
        let appletId = this.state.currentApplet && this.state.currentApplet.id || "";

        console.log("submit", triggers, filters, actions, appletName, enabled, projectId);
        LoadingUtil.showFullScreenLoading();
        Api.services.eventTrigger.update(appletId, triggers, filters, actions, appletName, enabled, projectId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                let applet: Applet = response.data;
                swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success");
                this.props.history && this.props.history.replace(Page.internals.urls.project.eventTriggerDetail(applet.projectId, applet.id));
                const projectId = (this.props.match && this.props.match.params["projectId"]) || "";
                this.getAppletList(projectId)
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });

    };

    createEmptyApplet(): Applet {
        return {
            appletAction: {
                actions: []
            },
            appletFilter: {
                filters: []
            },
            appletTrigger: {
                triggers: []
            },
            name: "",
            enabled: true
        }
    }

    render() {

        const currentApplet: Applet = this.state.currentApplet || this.createEmptyApplet();
        let isTopicTriggerSelected = false;
        let isWebHookTriggerSelected = false;
        let isDialogflowTriggerSelected = false;
        let isObjectDetectionSelected = false;
        let isFaceDetectionSelected = false;
        let triggerTopicName = "";
        let triggerObjectDetected = "";
        let triggerWebHookName = "";
        let triggerDialogflowIntentName = "";
        let triggerDialogflowUrl = "";
        let triggerIftttwebHookUrl = "";
        let triggerOneNETWebHookUrl = "";
        if (this.state.project.id && currentApplet.appletTrigger.triggers) {
            currentApplet.appletTrigger.triggers.forEach((trigger) => {
                if (trigger.type === MQTT_MESSAGE_TRIGGER_KEY) {
                    isTopicTriggerSelected = true;
                    triggerTopicName = trigger.topicName;
                } else if (trigger.type === WEB_HOOK_TRIGGER_KEY) {
                    isWebHookTriggerSelected = true;
                    triggerWebHookName = trigger.webHookName;
                    triggerIftttwebHookUrl = trigger.iftttwebHookUrl;
                    triggerOneNETWebHookUrl = trigger.oneNETWebHookUrl;
                } else if (trigger.type === DIALOGFLOW_TRIGGER_KEY) {
                    isDialogflowTriggerSelected = true;
                    triggerDialogflowIntentName = trigger.dialogflowIntentName;
                    triggerDialogflowUrl = trigger.dialogflowUrl;
                } else if (trigger.type === OBJECT_DETECTION_TRIGGER_KEY) {
                    isObjectDetectionSelected = true;
                    triggerObjectDetected = trigger.objectDetected;
                } else if (trigger.type === FACE_DETECTION_TRIGGER_KEY) {
                    isFaceDetectionSelected = true;
                }
            })
        }
        return <div className={"row"}>
            <div className={"col-12 p-2"}>
                <div className="m-portlet m-portlet--mobile mb-0">
                    <div className="m-portlet__body p-0">
                        {
                            this.state.project.id && <div id={"DataTriggerFragment"} className={styles.wrapper}>
                                <div className={styles.appletListWrapper}>
                                    <AppletListWrapper
                                        currentAppletId={currentApplet.id}
                                        appletList={this.state.appletList && _.sortBy(this.state.appletList, ['id'])}/>
                                    <CreateAppletLink
                                        projectName={this.state.project.projectName}
                                        projectId={this.state.project.id}
                                        active={!this.state.currentApplet}
                                    />

                                    <hr/>
                                    <BlockPanelItem backgroundColor={Constant.theme.eventTrigger.trigger.color}
                                                    onClick={() => {
                                                        if (UserDao.permission.allowMqttTrigger) {
                                                            this.triggerRef.current && this.triggerRef.current.onTopicTriggerSelected(true)
                                                        } else {
                                                            ErrorUtil.showPermissionNotAllowed();
                                                        }
                                                    }}
                                                    label={LLMsg("EVENT_TRIGGER.MQTT_TRIGGER_NAME")}/>
                                    <BlockPanelItem backgroundColor={Constant.theme.eventTrigger.trigger.color}
                                                    label={LLMsg("EVENT_TRIGGER.WEB_HOOK_TRIGGER_NAME")}
                                                    onClick={() => {
                                                        if (UserDao.permission.allowWebHookTrigger) {
                                                            this.triggerRef.current && this.triggerRef.current.onWebHookTriggerSelected(true)
                                                        } else {
                                                            ErrorUtil.showPermissionNotAllowed();
                                                        }
                                                    }}
                                    />
                                    <BlockPanelItem backgroundColor={Constant.theme.eventTrigger.trigger.color}
                                                    label={LLMsg("EVENT_TRIGGER.AI_TRIGGER_NAME")}
                                                    subItems={[
                                                        {
                                                            label: LLMsg("EVENT_TRIGGER.DIALOG_FLOW_TRIGGER_NAME"),
                                                            onClick: () => {
                                                                if (UserDao.permission.allowDialogFlowTrigger) {
                                                                    this.triggerRef.current && this.triggerRef.current.onDialogflowTriggerSelected(true)
                                                                } else {
                                                                    ErrorUtil.showPermissionNotAllowed();
                                                                }
                                                            }
                                                        },
                                                        {
                                                            label: LLMsg("AI.IMAGE_RECOGNITION_TITLE"),
                                                            onClick: () => {
                                                                this.triggerRef.current && this.triggerRef.current.onObjectDetectionSelected(true)
                                                            }
                                                        },
                                                        {
                                                            label: LLMsg("AI.FACE_DETECTION_TITLE"),
                                                            onClick: () => {
                                                                this.triggerRef.current && this.triggerRef.current.onFaceDetectionSelected(true)
                                                            }
                                                        }
                                                    ]}/>
                                    <BlockPanelItem backgroundColor={Constant.theme.eventTrigger.filter.color}
                                                    onClick={() => {
                                                        this.filterRef.current && this.filterRef.current.createFirstCondition();
                                                    }}
                                                    label={LLMsg("EVENT_TRIGGER.CONDITION_FILTER_NAME")}/>
                                    <BlockPanelItem backgroundColor={Constant.theme.eventTrigger.action.color}
                                                    onClick={() => {
                                                        if (UserDao.permission.allowIftttAction) {
                                                            this.actionRef.current && this.actionRef.current.createTriggerIFTTTWebbookActionCallback()
                                                        } else {
                                                            ErrorUtil.showPermissionNotAllowed();
                                                        }
                                                    }}
                                                    label={LLMsg("EVENT_TRIGGER.IFTTT_ACTION_NAME")}/>
                                    <BlockPanelItem backgroundColor={Constant.theme.eventTrigger.action.color}
                                                    onClick={() => {
                                                        if (UserDao.permission.allowMqttAction) {
                                                            this.actionRef.current && this.actionRef.current.createPublishMqttMessageActionCallback()
                                                        } else {
                                                            ErrorUtil.showPermissionNotAllowed();
                                                        }
                                                    }}
                                                    label={LLMsg("EVENT_TRIGGER.MQTT_ACTION_NAME")}/>
                                    <BlockPanelItem backgroundColor={Constant.theme.eventTrigger.action.color}
                                                    onClick={() => {
                                                        if (UserDao.permission.allowMqttAction) {
                                                            this.actionRef.current && this.actionRef.current.createHttpResponseActionCallback()
                                                        } else {
                                                            ErrorUtil.showPermissionNotAllowed();
                                                        }
                                                    }}
                                                    label={LLMsg("EVENT_TRIGGER.SEND_HTTP_RESPONSE_ACTION_TITLE")}/>
                                    <BlockPanelItem backgroundColor={Constant.theme.eventTrigger.action.color}
                                                    onClick={() => {
                                                        this.actionRef.current && this.actionRef.current.createGoogleSheetUpdateActionCallback()
                                                    }}
                                                    label={LLMsg("EVENT_TRIGGER.GOOGLE_SHEET_FEATURE_TITLE")}/>
                                </div>
                                <div className={styles.appletContent}>
                                    <div className={styles.appletContentInner}>
                                        <AppletNameFragment appletName={currentApplet.name}
                                                            enabled={currentApplet.enabled}
                                                            totalAppletCount={this.state.appletList ? this.state.appletList.length : 0}
                                                            ref={this.appletNameRef}/>

                                        <TriggerFragment ref={this.triggerRef}
                                                         triggerId={currentApplet.appletTrigger.id}
                                                         topicName={triggerTopicName}
                                                         objectDetected={triggerObjectDetected}
                                                         webHookName={triggerWebHookName}
                                                         dialogflowIntentName={triggerDialogflowIntentName}
                                                         triggerIftttwebHookUrl={triggerIftttwebHookUrl}
                                                         triggerOneNETWebHookUrl={triggerOneNETWebHookUrl}
                                                         triggerDialogflowURL={triggerDialogflowUrl}
                                                         topics={this.topicDropdownValues}
                                                         isTopicTriggerSelected={isTopicTriggerSelected}
                                                         isWebHookTriggerSelected={isWebHookTriggerSelected}
                                                         isDialogflowTriggerSelected={isDialogflowTriggerSelected}
                                                         isFaceDetectionSelected={isFaceDetectionSelected}
                                                         isObjectDetectionSelected={isObjectDetectionSelected}
                                        />
                                        <FilterFragment ref={this.filterRef}
                                                        filterId={currentApplet.appletFilter && currentApplet.appletFilter.id}
                                                        topics={this.topicDropdownValues}
                                                        conditionSet={currentApplet.appletFilter ? currentApplet.appletFilter.filters : []}/>
                                        <ActionFragment ref={this.actionRef}
                                                        topics={this.topicDropdownValues}
                                                        actionId={currentApplet.appletAction.id}
                                                        actions={currentApplet.appletAction.actions}/>
                                    </div>
                                    {currentApplet.id && <UpdateAppletButton updateCallback={this.updateApplet}/>}
                                    {!currentApplet.id &&
                                    <ConfirmCreateAppletButton createCallback={this.createApplet}/>}
                                    {currentApplet.id && <DeleteAppletButton deleteCallback={this.deleteApplet}/>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(DataTriggerFragment);
