// @flow

import React from "react";
import Constant from "../../bundle/Constant";
import Page from "../../bundle/Page";
import {Link} from "react-router-dom";

type ProjectFragmentProps = {
    index: number,
    projectId: string,
    name: string
}

const ProjectFragment = (props: ProjectFragmentProps) => {
    return <div className={"ProjectFragment text-left"}>

        <Link to={Page.internals.urls.project.detail(props.projectId, props.name)} className={"mb-2 no-a-effect"}>
            <i className={Constant.theme.project.icon + " fa-2x mr-3"} style={{color: Constant.theme.project.color}}/>
            <span className={"m--icon-font-size-lg5"}
                  style={{"color": Constant.theme.project.color}}>{props.index + 1}. {props.name}</span>
        </Link>
    </div>
};

export default ProjectFragment;