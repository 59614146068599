// @flow

import React from 'react';
import {ClassNames} from "../../../../bundle/ClassNames";
import {LLMsg} from "../../../../IntlCapture";
import type {Project} from "../../../../model/model";
import styles from './index.css';
import Stats from "../../../content/Stats";

type Props = {
    project: Project,
    dataCount: number
}

export default class ProjectOverviewStatsFragment extends React.Component<Props, any> {

    renderStats(title: string, content: string | number) {
        return <div className={ClassNames(styles.stats, "col")}>
            <span className={styles.statsTitle}>{title}</span>
            <span className={styles.statsValue}>{content}</span>
        </div>
    }

    renderStatsSection(title: string, stats: Array<any>, sectionStyleClassName: any, isLast: boolean) {
        return <div className={ClassNames("col-12", {"pb-3": !isLast})}>
            <div className="m-portlet mb-0">
                <div className={"m-portlet__body"}>
                    <div className={ClassNames("", styles.statsSection, sectionStyleClassName)}>
                        <div className={"row"}>
                            {stats}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    render() {
        const project = this.props.project;
        return <div className={"row"}>
            {
                this.renderStatsSection(
                    "物聯網總覽",
                    [
                        this.renderStats(LLMsg("COMMON.PROJECT.NUMBER_OF_TOPIC"), project.topics.length),
                        this.renderStats(LLMsg("COMMON.PROJECT.TOTAL_NUMBER_OF_DATA"), this.props.dataCount)
                    ],
                    styles.style1
                )
            }
            {
                this.renderStatsSection(
                    LLMsg("COMMON.CHART.CHART"),
                    [
                        this.renderStats(LLMsg("COMMON.PROJECT.NUMBER_OF_CHART"), project.chartCount),
                        this.renderStats(LLMsg("COMMON.PROJECT.NUMBER_OF_EVENT_TRIGGER"), project.eventTriggerCount)
                    ],
                    styles.style2
                )
            }
            {
                this.renderStatsSection(
                    LLMsg("PERMISSION.EVENT_TRIGGER"),
                    [
                        this.renderStats(LLMsg("COMMON.PROJECT.NUMBER_OF_PROJECT_FILE"), project.projectFiles.length),
                        this.renderStats(LLMsg("COMMON.PROJECT.NUMBER_OF_PROJECT_USER"), project.projectUsers.length)
                    ],
                    styles.style3, true
                )
            }
        </div>
    }
}