// @flow

import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";
import UrlBuilder from "../UrlBuilder";

export default class DataRecordApiUrlHelper {
    static recent = (topicId: string, dataTypeId: string, deviceId: string, count: number): string => {
        let queryString = "?" + UrlBuilder.toQueryString(
            {
                "topicId": topicId,
                "dataTypeId": dataTypeId,
                "deviceId": deviceId,
                "count": count
            }
        );

        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DATA_RECORD_LIST_RECENT_API, []) + queryString;

    };

    static all = (topicId: string, dataTypeId: string, deviceId: string): string => {
        let queryString = "?" + UrlBuilder.toQueryString(
            {
                "topicId": topicId,
                "dataTypeId": dataTypeId,
                "deviceId": deviceId
            }
        );

        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DATA_RECORD_LIST_ALL_API, []) + queryString;

    };

    static aggregateHistory = (topicId: string, dataTypeId: string, deviceId: string, startTime: string, endTime: string, interval: string, aggregate: string): string => {
        let queryString = "?" + UrlBuilder.toQueryString(
            {
                "topicId": topicId,
                "dataTypeId": dataTypeId,
                "deviceId": deviceId,
                startTime: StringUtil.encodeURI(startTime),
                endTime: StringUtil.encodeURI(endTime),
                interval, aggregate
            }
        );

        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DATA_RECORD_LIST_HISTORICAL_API, []) + queryString;

    };

    static download = {
        dataByProjectId: (projectId: string): string => {
            let queryString = "?" + UrlBuilder.toQueryString(
                {"projectId": projectId}
            );
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DATA_RECORD_EXPORT_BY_PROJECT_API, []) + queryString;
        }
    }
}
