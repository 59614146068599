// @flow

import React, {Component} from "react";

type HeaderSearchFragmentProps = {}

class HeaderSearchFragment extends Component<HeaderSearchFragmentProps, any> {
    render() {
        return <li
            className="m-nav__item m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light	m-list-search m-list-search--skin-light"
            m-dropdown-toggle="click" id="m_quicksearch" m-quicksearch-mode="dropdown" m-dropdown-persistent={1}>
            <a href="#" className="m-nav__link m-dropdown__toggle">
                <span className="m-nav__link-icon">
                    <span className="m-nav__link-icon-wrapper" style={{color: "white"}}>
                        <i className="flaticon-search-1"/>
                    </span>
                </span>
            </a>
            <div className="m-dropdown__wrapper">
                <span className="m-dropdown__arrow m-dropdown__arrow--center"/>
                <div className="m-dropdown__inner ">
                    <div className="m-dropdown__header">
                        <form className="m-list-search__form">
                            <div className="m-list-search__form-wrapper">
                                    <span className="m-list-search__form-input-wrapper">
                                      <input id="m_quicksearch_input" autoComplete="off" type="text" name="q"
                                             className="m-list-search__form-input" defaultValue={""}
                                             placeholder="Search..."/>
                                    </span>
                                <span className="m-list-search__form-icon-close"
                                      id="m_quicksearch_close">
                                      <i className="la la-remove"/>
                                    </span>
                            </div>
                        </form>
                    </div>
                    <div className="m-dropdown__body">
                        <div className="m-dropdown__scrollable m-scrollable"
                             data-scrollable="true" data-height={300}
                             data-mobile-height={200}>
                            <div className="m-dropdown__content">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>;
    }
}

export default HeaderSearchFragment;