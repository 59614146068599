// @flow

import React, {Component} from 'react';
import type {Event} from "../../../../model/model";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";
import styles from './index.css';
import LoadingUtil from "../../../../util/LoadingUtil";
import ErrorUtil from "../../../../util/ErrorUtil";
import Api from "../../../../bundle/Api";

type EventCodeListFragmentState = {
    events: Array<Event>
}

class EventCodeListFragment extends Component<any, EventCodeListFragmentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            events: []
        };
    }

    componentDidMount(props: any) {
        LoadingUtil.showFullScreenLoading();

        Api.services.eventCode.list().then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({events: response.data});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })
    }

    render() {
        return <div className="m-portlet">
            <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                        <h3 className="m-portlet__head-text">
                            Event Code List
                        </h3>
                    </div>
                </div>
            </div>
            <div className="m-portlet__body">
                <div className="m-section">
                    <div className="m-section__content">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Event Code</th>
                                    <th>Event Name</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    this.state.events.map((event: Event, index: number) => {
                                        return <tr key={event.id}
                                                   className={styles.row}
                                                   onClick={() => {
                                                       RouterHistory().push(Page.internals.urls.eventCode.admin.detail(event.id));
                                                   }}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{event.eventCode}</td>
                                            <td>{event.name}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <button type="button" className="btn btn-success" onClick={() => {
                            RouterHistory().push(Page.internals.urls.eventCode.admin.create())
                        }}>
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default EventCodeListFragment;
