// @flow

import React, {Component} from 'react';
import HugeBannerFragment from "../../content/HugeBannerFragment";
import LoginFormWrapper from "../../user/LoginFormWrapper/index";
import Api from "../../../bundle/Api";
import {Redirect} from "react-router";
import Page from "../../../bundle/Page";

class LoginPage extends Component<any, any> {

    render() {
        if (Api.services.user.isLogin()){
            return <Redirect to={Page.internals.urls.misc.home()}/>
        }
        Page.updateTitle("PAGE.TITLE.LOGIN");
        return <div className={"m-grid m-grid--hor m-grid--root m-page"}>

            <div
                className={"m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"}>
                <HugeBannerFragment/>
                <LoginFormWrapper/>
            </div>
        </div>
    }
}

export default LoginPage;