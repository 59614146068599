// @flow

import React from 'react';
import {LLMsg} from "../../IntlCapture";
import styles from "./ProjectDetailFragment/ProjectDetailActionBar.css";
import Image from "../../bundle/Image";
import {Link} from "react-router-dom";
import Page from "../../bundle/Page";

type CreateChartLinkButtonProps = {}

class CreateChartLinkButton extends React.Component<CreateChartLinkButtonProps, any> {
    render() {
        return <div
            className={styles.actionDiv}>
            <Link to={Page.internals.urls.chart.getChartCreatePageUrl()}
                  className={styles.actionButton + " mt-5 btn btn-outline-primary"}>

                                      {LLMsg("COMMON.CHART.CREATE_CHART")}
            </Link>
        </div>
    }
}

export default CreateChartLinkButton;