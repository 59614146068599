// @flow

import React from 'react';
import {LLMsg} from "../../../../IntlCapture";
import Api from "../../../../bundle/Api";
import ChartTypeSelectFragment from "../ChartTypeSelectFragment";
import * as PubSub from "pubsub-js";
import swal from "sweetalert2";
import Page from "../../../../bundle/Page";
import ChartService from "../../../../service/ChartService";
import {RouterHistory} from "../../../../AppRoute";
import WidgetConfigFragment from "../WidgetConfigFragment";
import ErrorUtil from "../../../../util/ErrorUtil";
import Constant from "../../../../bundle/Constant";
import ToastrUtil from "../../../../util/ToastrUtil";

type CreateChartStepsFragmentState = {
    currentStep: number,
    currentChartType?: string
}

class CreateChartStepsFragment extends React.Component<any, CreateChartStepsFragmentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            currentStep: 1,
            currentChartType: Constant.chart.type.basicLineChart
        };
        this.onPageChange = this.onPageChange.bind(this);
        this.createChart = this.createChart.bind(this);
    }

    portletRef = React.createRef();
    chartConfigRef = React.createRef();
    subToken: any;

    componentDidMount() {
        this.subToken = PubSub.subscribe(Api.actions.chart.CREATE_CHART_TYPE_CHANGED_ACTION, (action: string, newChartType: string) => {
            this.setState({
                currentChartType: newChartType
            })
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subToken);
    }

    onPageChange = (step: number) => {
        this.setState({
            currentStep: step
        })
    };

    createChart = () => {
        let chartType = this.state.currentChartType || "";
        let isValid = this.chartConfigRef.current && this.chartConfigRef.current.validate();

        if (isValid) {
            let config: Object = this.chartConfigRef.current && this.chartConfigRef.current.getConfigs() || {};
            let chartSourceConfigs = config.CHART_SOURCE;
            delete config.CHART_SOURCE;
            ChartService.doCreateChart(chartType, config, chartSourceConfigs).then(response => {
                if (response.status === 200) {
                    swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), "", "success").then(() => {
                        RouterHistory().push(Page.internals.urls.chart.getChartDetailPageUrl(response.data.id, response.data.name));
                    });
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch((e) => {
                ErrorUtil.promptError(e);
            });
        } else {
            ToastrUtil.show(LLMsg("COMMON.ACTION.FILL_IN_ALL_DATA"), Constant.toastr.noAutoHiding, ToastrUtil.error);
        }
    };

    render() {

        return <div className={"m-portlet"}>
            <div className="m-portlet__body" ref={this.portletRef}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ChartTypeSelectFragment show={this.state.currentStep === 1}/>
                        <WidgetConfigFragment chartType={this.state.currentChartType || ""}
                                              show={this.state.currentStep === 2}
                                              innerRef={this.chartConfigRef}/>
                    </div>
                    <div className={"col-12"}>
                        <div className="m-divider mt-3 mb-3">
                            <span/>
                        </div>
                        <div className={"pull-right"}>
                            {
                                this.state.currentStep !== 1 &&
                                <button type="button" className={"btn m-btn--pill btn-secondary m-btn--wide ml-2 mr-2 "}
                                        onClick={() => {
                                            this.setState({currentStep: this.state.currentStep - 1})
                                        }}>{LLMsg("COMMON.ACTION.PREVIOUS")}</button>
                            }
                            {
                                this.state.currentStep !== 2 &&
                                <button type="button" className={"btn m-btn--pill btn-success m-btn--wide ml-2 mr-2 "}
                                        onClick={() => {
                                            this.setState({currentStep: this.state.currentStep + 1});
                                        }}>{LLMsg("COMMON.ACTION.NEXT")}</button>
                            }
                            {
                                this.state.currentStep === 2 &&
                                <button type="button"
                                        className={"btn m-btn--pill btn-success m-btn--wide"} onClick={() => {
                                    this.createChart();
                                }}>
                                    {LLMsg("COMMON.CHART.CREATE_CHART")}
                                </button>
                            }
                        </div>
                    </div>
                </div>


            </div>

        </div>
    }
}

export default CreateChartStepsFragment;