// @flow

import React, {Component} from 'react';
import {Redirect, withRouter} from "react-router";
import Page from "../../../bundle/Page";
import Api from "../../../bundle/Api";
import type {Match} from "../../../App";
import UrlBuilder from "../../../url/UrlBuilder";


type LogoutPageProps = {
    location?: Object,
    history?: Object,
    match?: Match
};

class LogoutPage extends Component<LogoutPageProps, any> {

    render() {
        Api.services.user.logout();

        Page.updateTitle("PAGE.TITLE.LOGOUT");
        return <Redirect to={Page.internals.urls.user.login()}/>
    }
}

export default withRouter(LogoutPage);