// @flow

import React, {Component} from "react";
import styles from "./index.css";
import type {ChartConfig, ChartSource} from "../../../model/model";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import PointMapFragment from "../MapFragment/PointMapFragment";

type PointMapChartFragmentProps = {
    sources: Array<ChartSource>,
    chartId: string,
    config: ChartConfig,
    title: string,
    sizingClass?: string
}

class PointMapChartFragment extends Component<PointMapChartFragmentProps, any> {

    constructor(props) {
        super(props);
    }

    render() {
        let sizingClassname = this.props.sizingClass || "portlet_height_1x";
        return <div className={sizingClassname}>
            <Link to={Page.internals.urls.chart.getChartDetailPageUrl(this.props.chartId, this.props.title)}
                  className={"mb-2 m--icon-font-size-lg5 no-a-effect"}>{this.props.title}</Link>
            <div className="m-stack m-stack--ver m-stack--general">
                <PointMapFragment
                    chartSources={this.props.sources}
                    minValue={this.props.config.minValue}
                    maxValue={this.props.config.maxValue}
                    isSetting={false}
                    customHeight={sizingClassname == "portlet_height_1x" ? "211px" :null}
                />
            </div>
        </div>;
    }
}

export default PointMapChartFragment;