// @flow

import React from 'react';
import Page from "../../../bundle/Page";
import styles from "../DataTriggerFragment/index.css";
import {LLMsg} from "../../../IntlCapture";
import {Link} from "react-router-dom";
import {ClassNames} from "../../../bundle/ClassNames";

type CreateAppletLinkProps = {

    active: boolean,
    projectId: string,
    projectName: string
}

class CreateAppletLink extends React.Component<CreateAppletLinkProps, any> {

    render() {
        return <Link
            to={Page.internals.urls.project.dataTrigger(this.props.projectId, this.props.projectName)}
            className={ClassNames(" no-a-effect ",
                styles.newAppletButton,
                (this.props.active ? styles.appletListActiveItem : "")
            )}>
            {LLMsg("EVENT_TRIGGER.CREATE_APPLET")}
            <i className={ClassNames("fas fa-plus", styles.dropdownArrow)}/>
        </Link>
    }
}


export default CreateAppletLink;