// @flow
import React from 'react';
import Constant from "../../../bundle/Constant";
import LineChartFragment from "./../LineChartFragment";
import GaugeChartFragment from "./../GaugeChartFragment";
import Api from "../../../bundle/Api";
import ChartDropdownFragment from "./../ChartDropdownFragment";
import type {SimpleAction} from "../../../redux/reducer";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../../IntlCapture";
import PlainTextChartFragment from "./../PlainTextChartFragment";
import type {Chart} from "../../../model/model";
import ToggleButtonChartFragment from "./../ToggleButtonChartFragment";
import PushButtonChartFragment from "./../PushButtonChartFragment";
import PointMapChartFragment from "./../PointMapChartFragment";
import image from './no_chart_image.svg';
import styles from './index.css';
import CreateChartLinkButton from "../../project/CreateChartLinkButton";
import {ClassNames} from "../../../bundle/ClassNames";
import LazyLoad from 'react-lazyload';

type Props = {
    projectId: string,
    action?: SimpleAction,
    display: boolean
}
type State = {
    charts: Array<Chart>
}

class RelatedProjectChartFragment extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {charts: []}
    }

    componentDidMount() {
        this.getRelatedProjectChart(this.props.projectId);
    }

    getRelatedProjectChart(projectId: string) {
        window.mApp.block(".RelatedProjectChartFragment", {
            overlayColor: "#000000",
            type: "loader",
            state: "success",
            message: LLMsg("COMMON.ACTION.LOADING_MESSAGE")
        });
        Api.services.chart.doGetRelatedProjectChart(projectId).then(response => {
            window.mApp.unblock(".RelatedProjectChartFragment");
            if (response.status === 200) {
                this.setState({charts: response.data});
            }
        }).catch(() => {
            window.mApp.unblock(".RelatedProjectChartFragment");
        });
    }

    componentDidUpdate(prevProps: Props) {

        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.chart.RENAMED_CHART)) {
            this.getRelatedProjectChart(this.props.projectId);
        }

        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.chart.DELETED_CHART)) {
            this.getRelatedProjectChart(this.props.projectId);
        }
    }

    render() {
        let charts = this.state.charts;

        if (charts.length === 0) {
            return <div className={"row"}>
                <div className={"col-12 p-2"}>
                    <div className={ClassNames("m-portlet")}>
                        <div className={"m-portlet__body"}>
                            <div className={styles.noChartImageBox}>
                                <img src={image} className={styles.noChartImage} alt={"chart"}/>
                                <CreateChartLinkButton/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        return <div className={"row"}>
            <div className={ClassNames(styles.createButtonBox, "col-12")}>
                <CreateChartLinkButton/>
            </div>


            {
                charts.map((chart: Chart, index) => {
                    let chartType = chart.chartType.name;
                    let title = chart.name;

                    let sizeClassname = Constant.portlet.size.colWidth.w3;
                    if (chartType === Constant.chart.type.toggleButton || chartType === Constant.chart.type.pushButton) {
                        sizeClassname = "col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-2"
                    } else if (chartType === Constant.chart.type.basicLineChart || chartType === Constant.chart.type.pointMap) {
                        sizeClassname = "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-2"
                    }


                    return <div
                        className={sizeClassname + " RelatedProjectChartFragment"}
                        key={index}>
                        <LazyLoad>
                            <div className="m-portlet mb-0">
                                <div className="m-portlet__body portlet_right_top_hover_trigger">
                                    <div className={"portlet_right_top_buttons"}>
                                        <ChartDropdownFragment isDefaultChart={chart.isDefaultChart} chartId={chart.id}
                                                               chartType={chartType} chartName={title}/>
                                    </div>
                                    {
                                        chartType === Constant.chart.type.basicLineChart &&
                                        <LineChartFragment title={title} chartId={chart.id}
                                                           aggregate={null} endTime={null}
                                                           interval={null} isLive={true} startTime={null}
                                                           chartType={chartType}
                                                           config={chart.chartConfig}
                                                           sources={chart.chartSources}/>
                                    }
                                    {
                                        chartType === Constant.chart.type.gauge &&
                                        <GaugeChartFragment title={title} chartId={chart.id}
                                                            config={chart.chartConfig}
                                                            sources={chart.chartSources}/>
                                    }
                                    {
                                        chartType === Constant.chart.type.plainTextChart &&
                                        <PlainTextChartFragment title={title} chartId={chart.id}
                                                                config={chart.chartConfig}
                                                                sources={chart.chartSources}/>
                                    }
                                    {
                                        chartType === Constant.chart.type.toggleButton &&
                                        <ToggleButtonChartFragment title={title} chartId={chart.id}
                                                                   config={chart.chartConfig}
                                                                   sources={chart.chartSources}/>
                                    }

                                    {
                                        chartType === Constant.chart.type.pushButton &&
                                        <PushButtonChartFragment title={title} chartId={chart.id}
                                                                 config={chart.chartConfig}
                                                                 sources={chart.chartSources}/>
                                    }

                                    {
                                        chartType === Constant.chart.type.pointMap &&
                                        <PointMapChartFragment title={title}
                                                               chartId={chart.id}
                                                               config={chart.chartConfig}
                                                               sources={chart.chartSources}
                                        />
                                    }
                                </div>
                            </div>
                        </LazyLoad>
                    </div>;
                })
            }

        </div>
    };

}

const mapStateToProps = state => {
    return {
        action: state.ActionReducer.action
    };
};
export default withRouter(connect(mapStateToProps, null)(RelatedProjectChartFragment));