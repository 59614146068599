// @flow

import React from 'react';
import Constant from "../../../bundle/Constant";
import Page from "../../../bundle/Page";
import {Link} from "react-router-dom";
import {LLMsg} from "../../../IntlCapture";

type ViewChartDetailButtonFragmentProps = {
    chartId: string,
    chartName: string
}
const ViewChartDetailButtonFragment = (props: ViewChartDetailButtonFragmentProps) => {
    return <li className="m-nav__item ">
        <Link to={Page.internals.urls.chart.getChartDetailPageUrl(props.chartId, props.chartName)}
              className="m-nav__link cursor-pointer">
            <i className={"m-nav__link-icon fa-2x  " + Constant.theme.chart.icon}/>
            <span className="m-nav__link-text pl-2  m--icon-font-size-lg1 ">
                {LLMsg("COMMON.BREADCRUMB.DETAIL")}
            </span>
        </Link>
    </li>
};
export default ViewChartDetailButtonFragment;