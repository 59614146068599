// @flow

import React, {Component} from 'react';
import ErrorUtil from "../../../../util/ErrorUtil";
import Api from "../../../../bundle/Api";
import type {User} from "../../../../model/model";
import {withRouter} from "react-router";
import styles from './index.css'
import type {Match} from "../../../../App";
import _ from 'lodash';
import TimeUtil from "../../../../util/TimeUtil";

type AdminUserDetailFragmentProps = {
    location?: Object,
    history?: Object,
    match?: Match
};
type AdminUserDetailFragmentState = {
    user: User
}

class AdminUserDetailFragment extends Component<AdminUserDetailFragmentProps, AdminUserDetailFragmentState> {

    constructor(props: AdminUserDetailFragmentProps) {
        super(props);
        this.state = {
            user: {}
        }
    }

    componentDidMount() {
        this.getUserList();
    }

    getUserList = () => {
        const userId = this.props.match && this.props.match.params["userId"];

        if (userId) {
            Api.services.user.doGetUserDetail(userId).then((response) => {
                if (response.status === 200) {
                    this.setState({user: response.data});
                }
            }).catch((e) => {
                ErrorUtil.promptError(e);
            })
        }

    };

    constructItem(title: any, content: any) {
        return <div className={styles.row}>
            <div className={styles.title}>
                {title}
            </div>
            <div
                className={styles.content}>
                {content}
            </div>
        </div>
    }

    render() {
        let user: User = this.state.user;

        let isSchoolSubscription: boolean = _.get(user, "subscriptionStatus.isSchoolSubscription", false);
        let isEventSubscription: boolean = _.get(user, "subscriptionStatus.isEventSubscription", false);
        let isLicenseSubscription: boolean = _.get(user, "subscriptionStatus.isLicenseSubscription", false);

        return <div className="m-portlet">
            <div className="m-portlet__body">
                <div className="m-section">
                    {this.constructItem(("User Email"), user.email)}
                    {this.constructItem(("First Name"), user.firstName)}
                    {this.constructItem(("Last Name"), user.lastName)}
                    {
                        this.constructItem(
                            ("Active"),
                            _.get(user, "subscriptionStatus.isActive", "") ? "YES" : "NO"
                        )
                    }
                    {
                        this.constructItem(
                            ("Effective Until"),
                            _.get(user, "subscriptionStatus.effectiveUntil", "") || ("FOREVER")
                        )
                    }
                    <hr/>
                    {
                        this.constructItem(
                            ("Whitelisted Email"),
                            _.get(user, "subscriptionStatus.isWhitelistEmail", false) ? ("YES") : ("NO")
                        )
                    }
                    <hr/>
                    {this.constructItem(("Event Code Subscription"), isEventSubscription ? ("YES") : ("NO"))}
                    {isEventSubscription && this.constructItem(("Event Code"), _.get(user, "subscriptionStatus.event.eventCode", ""))}
                    {isEventSubscription && this.constructItem(("Event name"), _.get(user, "subscriptionStatus.event.name", ""))}
                    {isEventSubscription && this.constructItem(("Event Valid From"), TimeUtil.convertTimeFormat(_.get(user, "subscriptionStatus.event.startFrom", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT))}
                    {isEventSubscription && this.constructItem(("Event Valid Until"), TimeUtil.convertTimeFormat(_.get(user, "subscriptionStatus.event.endUntil", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT))}

                    <hr/>
                    {this.constructItem(("License Subscription"), isLicenseSubscription ? ("YES") : ("NO"))}
                    {isLicenseSubscription && this.constructItem(("License Key"), _.get(user, "subscriptionStatus.licenseSubscription.licenseKey", ""))}
                    {isLicenseSubscription && this.constructItem(("Licensed Party"), _.get(user, "subscriptionStatus.licenseSubscription.licenseTargetName", ""))}
                    {isLicenseSubscription && this.constructItem(("expiry Date"), TimeUtil.convertTimeFormat(_.get(user, "subscriptionStatus.licenseSubscription.expiryDate", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT))}
                    {isLicenseSubscription && this.constructItem(("currentConsumptionCount"), _.get(user, "subscriptionStatus.licenseSubscription.currentConsumptionCount", ""))}
                    {isLicenseSubscription && this.constructItem(("maxConcurrentLoginCount"), _.get(user, "subscriptionStatus.licenseSubscription.maxConcurrentLoginCount", ""))}
                    {isLicenseSubscription && this.constructItem(("currentConsumptionCount"), _.get(user, "subscriptionStatus.licenseSubscription.currentConsumptionCount", ""))}
                    {isLicenseSubscription && this.constructItem(("maxConsumptionCount"), _.get(user, "subscriptionStatus.licenseSubscription.maxConsumptionCount", ""))}

                    <hr/>
                    {this.constructItem(("School Subscription"), isSchoolSubscription ? ("YES") : ("NO"))}
                    {isSchoolSubscription && this.constructItem(("School name"), _.get(user, "subscriptionStatus.school.schoolName", ""))}
                    {isSchoolSubscription && this.constructItem(("School Email Domain"), _.get(user, "subscriptionStatus.school.emailDomain", ""))}
                    {isEventSubscription && this.constructItem(("School Valid From"), TimeUtil.convertTimeFormat(_.get(user, "subscriptionStatus.school.validFrom", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT))}
                    {isEventSubscription && this.constructItem(("School Valid Until"), TimeUtil.convertTimeFormat(_.get(user, "subscriptionStatus.school.validTo", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT))}


                </div>
            </div>
        </div>;
    }
}

export default withRouter(AdminUserDetailFragment);
