// @flow

import React from "react";

type SidebarGroupProps = {
    children?: any
}

const SidebarGroup = (props: SidebarGroupProps) => {
    return <React.Fragment>
        {props.children}
    </React.Fragment>;
};


export default SidebarGroup;