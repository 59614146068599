// @flow

import React, {Component} from "react";
import Constant from "../../bundle/Constant";
import swal from "sweetalert2";
import Api from "../../bundle/Api";
import {createAction} from "../../redux/reducer";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import type {Match} from "../../App";
import {LLMsg} from "../../IntlCapture";
import styles from "./UpdateDataRecordTypeButton.css";
import ErrorUtil from "../../util/ErrorUtil";

type UpdateDataRecordTypePromptProps = {

    topicId: string,
    dataTypeId: string,
    dataTypeName: string,
    dataTypeKey: string,
    dataTypeDescription: string,

    dispatchDataTypeUpdated?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}


class UpdateDataRecordTypeButton extends Component <UpdateDataRecordTypePromptProps, any> {

    updateDataRecordTypePrompt = (topicId: string, dataTypeId: string, dataTypeName: string, dataTypeKey: string, dataTypeDescription: string, dispatchDataTypeCreatedCallback: ? any) => {

        let form = ' <form class="m-form m-form--fit m-form--label-align-right text-left">' +
            '            <h1 style="color: ' + Constant.theme.dataType.color + '" class="mb-3 m--icon-font-size-lg5 text-center">' +
            '                <i class="' + Constant.theme.dataType.icon + ' fa-1x mr-1"}></i>' +
            '                <span>' + LLMsg("COMMON.DATA_TYPE.DATA_TYPE") + '</span>' +
            '            </h1>' +
            '            <div class="row">' +
            '                <div class="col-12">' +
            '                    <div class="form-group m-form__group pb-0 ">' +
            '                        <label class=" m--icon-font-size-lg2">' + LLMsg("COMMON.DATA_TYPE.DATA_TYPE_NAME") + '</label>' +
            '                        <input name="newDataTypeName" type="text" class="form-control m-input m-input--square" autofocus="true" value="' + dataTypeName + '"' +
            '                               placeholder="' + LLMsg("COMMON.DATA_TYPE.NAME_PLACEHOLDER") + '"/>' +
            '                    </div>' +
            '                    <div class="form-group m-form__group pb-0">' +
            '                        <label class=" m--icon-font-size-lg2">' + LLMsg("COMMON.DATA_TYPE.DATA_TYPE_KEY") + '</label>' +
            '                        <input name="newDataTypeKey" type="text" class="form-control m-input m-input--square"  value="' + dataTypeKey + '" ' +
            '                               placeholder="' + LLMsg("COMMON.DATA_TYPE.KEY_PLACEHOLDER") + '"/>' +
            '                        <span class="m-form__help m--icon-font-size-lg0">' + LLMsg("COMMON.DATA_TYPE.UNIQUE_LABEL") + '</span>' +
            '                    </div>' +
            '                    <div class="form-group m-form__group mb-3">' +
            '                        <label class=" m--icon-font-size-lg2">' + LLMsg("COMMON.DATA_TYPE.DATA_TYPE_DESC") + '</label>' +
            '                        <input name="newDataTypeDescription" type="text" class="form-control m-input m-input--square" value="' + dataTypeDescription + '"' +
            '                               placeholder="' + LLMsg("COMMON.DATA_TYPE.DESC_PLACEHOLDER") + '"/>' +
            '                    </div>' +
            '                </div>' +
            '            </div>' +
            '        </form>';

        swal({
            html: form,
            showCancelButton: true,
            confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
            cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
            width: 600,
            preConfirm: () => {
                let newNameInput: Object = document.querySelector('input[name="newDataTypeName"]') || {};
                let newKeyInput: Object = document.querySelector('input[name="newDataTypeKey"]') || {};
                let newDescriptionInput: Object = document.querySelector('input[name="newDataTypeDescription"]') || {};

                let hasError = false;
                if (!newNameInput.value) {
                    hasError = true;
                }
                if (!newKeyInput.value) {
                    hasError = true;
                }
                if (hasError) {
                    swal.showValidationMessage(
                        LLMsg("COMMON.DATA_TYPE.INVALID_FIELD")
                    );
                    return false;
                }
                return true;
            }
        }).then((e) => {
            if (e.dismiss) {
                return;
            }
            if (e.value) {
                let newNameInput: Object = document.querySelector('input[name="newDataTypeName"]') || {};
                let newKeyInput: Object = document.querySelector('input[name="newDataTypeKey"]') || {};
                let newDescriptionInput: Object = document.querySelector('input[name="newDataTypeDescription"]') || {};

                Api.services.topic.update.updateDataType(topicId, dataTypeId, newNameInput.value, newKeyInput.value, newDescriptionInput.value).then(response => {
                    if (response.status === 200) {
                        swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success");
                        dispatchDataTypeCreatedCallback && dispatchDataTypeCreatedCallback();
                    } else {
                        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                    }
                }).catch((e) => {
                    ErrorUtil.promptError(e);
                });
            } else {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            }
        });
    };

    render() {
        return <button className={"btn btn-secondary m-btn m-btn--icon m-btn--icon-only btn-sm mr-3"}
                    onClick={() => {
                        this.updateDataRecordTypePrompt(this.props.topicId, this.props.dataTypeId, this.props.dataTypeName, this.props.dataTypeKey, this.props.dataTypeDescription, this.props.dispatchDataTypeUpdated);
                    }}>
            <i className={Constant.theme.edit.icon}/>
        </button>
    }
}

const mapDispatchToProps = dispatch => ({
    dispatchDataTypeUpdated: () => dispatch(createAction(Api.actions.dataType.UPDATE_DATA_TYPE_ACTION))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(UpdateDataRecordTypeButton));
