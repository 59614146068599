// @flow

import React from "react";
import {LLMsg} from "../../IntlCapture";

const NoChartInDashboard = () => {

    return <div className={"text-center mt-5 mb-5"} >
        <span>
            {LLMsg("COMMON.DASHBOARD.NO_CHART_IN_DASHBOARD")}
        </span>
    </div>;

};

export default NoChartInDashboard;