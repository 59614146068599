// @flow

import React from 'react';
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import SocketIOService from "../../../service/SocketIOService";
import Constant from "../../../bundle/Constant";
import type {ChartConfig, ChartSource} from "../../../model/model";
import styles from './index.css';
import {LLMsg} from "../../../IntlCapture";
import Api from "../../../bundle/Api";
import LoadingUtil from "../../../util/LoadingUtil";
import ToastrUtil from "../../../util/ToastrUtil";

type ToggleButtonChartFragmentState = {
    showOn: boolean,
    showOff: boolean
}
type ToggleButtonChartFragmentProps = {
    sources: Array<ChartSource>,
    chartId: string,
    title: string,
    config: ChartConfig,
    sizingClass?: string,
}

class ToggleButtonChartFragment extends React.Component<ToggleButtonChartFragmentProps, ToggleButtonChartFragmentState> {


    constructor(props: any) {
        super(props);
        this.state = {
            showOn: false,
            showOff: true
        };
        this.changeToOn = this.changeToOn.bind(this);
        this.changeToOff = this.changeToOff.bind(this);
    }

    changeToOn = () => {

        let source = this.props.sources[0];
        let topicId = source.topic.id;
        let onValue = this.props.config.onValue;
        let message = SocketIOService.constructMakerCloudMessage(source.dataRecordType ? source.dataRecordType.keyName + "=" + onValue : onValue);
        LoadingUtil.showFullScreenLoading();
        Api.services.topic.doSendNewMessageToTopic(topicId, this.props.config.outputType, message).then((response) => {
            if (response.status === 200) {
                ToastrUtil.show(LLMsg(onValue), Constant.toastr.defaultOptions, ToastrUtil.success);
                this.setState({
                    showOn: true,
                    showOff: false
                }, () => {
                    LoadingUtil.hideFullScreenLoading();
                });
            } else {
                LoadingUtil.hideFullScreenLoading();
                ToastrUtil.show(LLMsg("COMMON.ACTION.ACTION_FAILED"), Constant.toastr.noAutoHiding, ToastrUtil.error);
            }
        }).catch(() => {
            LoadingUtil.hideFullScreenLoading();
            ToastrUtil.show(LLMsg("COMMON.ACTION.ACTION_FAILED"), Constant.toastr.noAutoHiding, ToastrUtil.error);
        });

    };

    changeToOff = () => {

        let source = this.props.sources[0];
        let topicId = source.topic.id;
        let offValue = this.props.config.offValue;
        let message = SocketIOService.constructMakerCloudMessage(source.dataRecordType ? source.dataRecordType.keyName + "=" + offValue : offValue);
        LoadingUtil.showFullScreenLoading();
        Api.services.topic.doSendNewMessageToTopic(topicId, this.props.config.outputType, message).then((response) => {
            if (response.status === 200) {
                ToastrUtil.show(LLMsg(offValue), Constant.toastr.defaultOptions, ToastrUtil.success);
                this.setState({
                    showOn: false,
                    showOff: true
                }, () => {
                    LoadingUtil.hideFullScreenLoading();
                });
            } else {
                LoadingUtil.hideFullScreenLoading();
                ToastrUtil.show(LLMsg("COMMON.ACTION.ACTION_FAILED"), Constant.toastr.noAutoHiding, ToastrUtil.error);
            }
        }).catch(() => {
            LoadingUtil.hideFullScreenLoading();
            ToastrUtil.show(LLMsg("COMMON.ACTION.ACTION_FAILED"), Constant.toastr.noAutoHiding, ToastrUtil.error);
        });
    };

    render() {

        let style = {
            fontSize: '50px',
            margin: '30px 30px 30px 10px',
            cursor: 'pointer'
        };

        let content;

        if (this.state.showOn) {
            if (this.props.config.onText) {
                content = <button style={style}
                                  onClick={this.changeToOff}
                                  type="button"
                                  className="btn m-btn btn-outline m-btn--custom">
                    {this.props.config.onText}
                </button>;
            } else {
                content = <i className={this.props.config.onStyle}
                             onClick={this.changeToOff}
                             style={{
                                 color: this.props.config.onColor,
                                 borderColor: this.props.config.onColor, ...style
                             }}/>;
            }
        } else {
            if (this.props.config.offText) {
                content = <button style={style}
                                  onClick={this.changeToOn}
                                  type="button"
                                  className="btn m-btn btn-outline m-btn--custom">
                    {this.props.config.offText}
                </button>;
            } else {
                content = <i className={this.props.config.offStyle}
                             onClick={this.changeToOn}
                             style={{
                                 color: this.props.config.offColor,
                                 borderColor: this.props.config.offColor, ...style
                             }}/>;
            }
        }

        return <div className={this.props.sizingClass || "portlet_height_1x"}>
            <Link to={Page.internals.urls.chart.getChartDetailPageUrl(this.props.chartId, this.props.title)}
                  className={"mb-2 m--icon-font-size-lg5 no-a-effect"}>{this.props.title}</Link>
            <div className="m-stack m-stack--ver m-stack--general">
                <div
                    className={"m-stack__item m-stack__item--center m-stack__item--middle + " + styles.value}
                    style={{color: this.props.config.color}}
                >
                    {content}
                </div>
            </div>
        </div>;

    }
}

export default ToggleButtonChartFragment;