// @flow

import React, {Component} from 'react';
import ClipboardJS from "clipboard";
import {LLMsg} from "../../IntlCapture";
import Constant from "../../bundle/Constant";

type Props = {
    text: string,
    label: string
}

class CopyOpenApiButtonFragment extends Component<Props, any> {
    buttonRef: any;

    constructor(props: Props) {
        super(props);
        this.buttonRef = React.createRef();
    }

    initClipboard() {

        let clipboard = new ClipboardJS(this.buttonRef);

        clipboard.on('success', function (e) {
            window.toastr.options = Constant.toastr.defaultOptions;
            window.toastr.success(LLMsg("COMMON.ACTION.COPY_SUCCESS"));
        });
    }

    componentDidMount() {
        this.initClipboard();
    }

    render() {

        return <button type="button"
                       data-clipboard-text={this.props.text}
                       ref={(ref) => {
                           this.buttonRef = ref;
                       }}
                       className={"ml-4 mb-4 btn btn-link m-btn--pill btn-md p-0"}>
            <i className="fa fa-copy mr-2"/>
            {this.props.label ? this.props.label : LLMsg("COMMON.ACTION.COPY")}
        </button>;
    }
};
export default CopyOpenApiButtonFragment;