exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3MYesVUFYbxXnd9ZtWwOBR {\n    display: inline;\n    cursor: pointer;\n}\n\n._3MYesVUFYbxXnd9ZtWwOBR:hover {\n    font-weight: bold;\n    color: #403f76;\n}\n\n._21ShvOfYr9uA2kmYmeqHPA {\n    display: block;\n    margin-bottom: 3px;\n    cursor: pointer;\n}\n\n._358ROK2VorlkFszV1KYy-z {\n    width: 60px;\n    margin-left: 10px;\n}\n\n._2DIqtyZ33zHCnGVNNreBrw {\n    font-weight: bolder;\n    display: block;\n    font-size: 1.5em;\n    margin-top: 10px;\n}\n\n.rbT1oWR33pJ1iPVxi7Bnc {\n    margin-top: 20px;\n    margin-bottom: 20px;\n    text-align: center;\n}", ""]);

// exports
exports.locals = {
	"actionButton": "_3MYesVUFYbxXnd9ZtWwOBR",
	"actionIcon": "_21ShvOfYr9uA2kmYmeqHPA",
	"actionImageIcon": "_358ROK2VorlkFszV1KYy-z",
	"actionLabel": "_2DIqtyZ33zHCnGVNNreBrw",
	"actionDiv": "rbT1oWR33pJ1iPVxi7Bnc"
};