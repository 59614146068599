// @flow
import React, {Component} from 'react';
import Api from "../../../bundle/Api";
import {withRouter} from "react-router";
import {LLMsg} from "../../../IntlCapture";
import ChartDropdownFragment from "../ChartDropdownFragment";
import Constant from "../../../bundle/Constant";
import LineChartFragment from "../LineChartFragment";
import GaugeChartFragment from "../GaugeChartFragment";
import PlainTextChartFragment from "../PlainTextChartFragment";
import ToggleButtonChartFragment from "../ToggleButtonChartFragment";
import PushButtonChartFragment from "../PushButtonChartFragment";
import type {Chart} from "../../../model/model";
import PointMapChartFragment from "../PointMapChartFragment";

type RecentChartFragmentProps = {

    match?: Object,
    location?: Object,
    history?: Object
}
type RecentChartFragmentStates = {
    recentCharts: Array<Object>
}

class RecentChartFragment extends Component<RecentChartFragmentProps, RecentChartFragmentStates> {

    state: RecentChartFragmentStates = {
        recentCharts: []
    };

    constructor(props: RecentChartFragmentProps) {
        super(props);

        Api.services.chart.doGetRecentChart(10).then(response => {
            this.setState({recentCharts: response.data})
        });

    }

    render() {

        return <div>

            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"mb-5"}>
                        <h2>
                            {LLMsg("COMMON.CHART.RECENT_CHART")}
                        </h2>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                {
                    this.state.recentCharts && this.state.recentCharts.length > 0 && this.state.recentCharts.map((chart: Chart, index) => {

                        return <div key={index} className={"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2"}>


                            <div className="m-portlet mb-0">
                                <div className="m-portlet__body portlet_right_top_hover_trigger">
                                    <div className={"portlet_right_top_buttons"}>
                                        <ChartDropdownFragment isDefaultChart={chart.isDefaultChart}
                                                               chartId={chart.id}
                                                               chartType={chart.chartType.name}
                                                               chartName={chart.name}/>
                                    </div>
                                    {
                                        chart.chartType.name === Constant.chart.type.basicLineChart &&
                                        <LineChartFragment title={chart.name}
                                                           chartId={chart.id}
                                                           config={chart.chartConfig}
                                                           sources={chart.chartSources} aggregate={null} endTime={null}
                                                           interval={null} isLive={true} startTime={null}/>
                                    }
                                    {
                                        chart.chartType.name === Constant.chart.type.gauge &&
                                        <GaugeChartFragment title={chart.name} chartId={chart.id}
                                                            config={chart.chartConfig}
                                                            sources={chart.chartSources}/>
                                    }
                                    {
                                        chart.chartType.name === Constant.chart.type.plainTextChart &&
                                        <PlainTextChartFragment title={chart.name}
                                                                chartId={chart.id}
                                                                config={chart.chartConfig}
                                                                sources={chart.chartSources}
                                        />
                                    }

                                    {
                                        chart.chartType.name === Constant.chart.type.toggleButton &&
                                        <ToggleButtonChartFragment title={chart.name}
                                                                   chartId={chart.id}
                                                                   config={chart.chartConfig}
                                                                   sources={chart.chartSources}
                                        />
                                    }

                                    {
                                        chart.chartType.name === Constant.chart.type.pushButton &&
                                        <PushButtonChartFragment title={chart.name}
                                                                 chartId={chart.id}
                                                                 config={chart.chartConfig}
                                                                 sources={chart.chartSources}
                                        />
                                    }

                                    {
                                        chart.chartType.name === Constant.chart.type.pointMap &&
                                        <PointMapChartFragment title={chart.name}
                                                               chartId={chart.id}
                                                               config={chart.chartConfig}
                                                               sources={chart.chartSources}
                                        />
                                    }
                                </div>
                            </div>
                        </div>;
                    })
                }
            </div>
        </div>;
    }
}

export default withRouter(RecentChartFragment);