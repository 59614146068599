// @flow

import React from "react";
import {LLMsg} from "../../../IntlCapture";

const ProjectListTableNoRecord = () => {

    return <tr role="row" className="odd">
        <td >
            <div className="m-datatable--error m--icon-font-size-lg1 text-center">
                {LLMsg("COMMON.ACTION.NO_RECORD")}
            </div>
        </td>
    </tr>;
};

export default ProjectListTableNoRecord;