exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vsyNs-V-ZLT-OpOqwVgbc {\n    padding: 10px 10px 15px 15px;\n    font-size: 1.4em;\n}\n\n._1uomjGC607K4H38XvZYCwz {\n    color: red;\n}\n\n._1uomjGC607K4H38XvZYCwz input {\n    border: 1px solid red;\n}\n._1uomjGC607K4H38XvZYCwz select {\n    border: 1px solid red;\n}\n", ""]);

// exports
exports.locals = {
	"title": "vsyNs-V-ZLT-OpOqwVgbc",
	"error": "_1uomjGC607K4H38XvZYCwz"
};