// @flow

import React from 'react';
import styles from "./FilterFragment.css";
import type {FilterCondition} from "./ConditionFragment";
import ConditionFragment from "./ConditionFragment";
import _ from 'lodash';
import StringUtil from "../../../util/StringUtil";
import {DataRecordType} from "../../../model/model";
import {LLMsg} from "../../../IntlCapture";
import Constant from "../../../bundle/Constant";


type FilterFragmentState = {
    nextConditionKey: number,
    conditionSet: Array<FilterCondition>,
    showError: boolean
}

type FilterFragmentProps = {
    conditionSet: Array<FilterCondition>,
    filterId: string,
    topics: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }>
}

export const MQTT_MESSAGE_TRIGGER_KEY = "MQTT_MESSAGE_TRIGGER";
export const WEB_HOOK_TRIGGER_KEY = "WEB_HOOK_TRIGGER";
export const DIALOGFLOW_TRIGGER_KEY = "DIALOGFLOW_TRIGGER";
export const OBJECT_DETECTION_TRIGGER_KEY = "OBJECT_DETECTION_TRIGGER";
export const FACE_DETECTION_TRIGGER_KEY = "FACE_DETECTION_TRIGGER";

export const SUB_FILTER_TYPE = "SUB_FILTER";
export const TOPIC_MESSAGE_VALUE_TYPE = "MQTT_SIMPLE_MESSAGE_FILTER";
export const TOPIC_KEY_VALUE_MESSAGE_TYPE = "MQTT_KEY_VALUE_MESSAGE_FILTER";

export const AND_OPERATOR_KEY = "AND";
export const OR_OPERATOR_KEY = "OR";

export const FIELD_NAME_TOPIC_NAME = "topicName";
export const FIELD_NAME_SUBJECT_KEY = "subjectKey";
export const FIELD_NAME_COMPARATOR_KEY = "comparatorKey";
export const FIELD_NAME_PUBLISH_CONTENT = "publishContent";

export const COMPARATOR = () => ({
    CONTAINS: {
        key: "contain_case_sensitive",
        label: LLMsg("EVENT_TRIGGER.CONTAINS")
    },
    NOT_CONTAINS: {
        key: "not_contain_case_sensitive",
        label: LLMsg("EVENT_TRIGGER.NOT_CONTAIN")
    },
    GREATER_THAN: {
        key: "greater_than",
        label: ">"
    },
    GREATER_THAN_OR_EQUAL: {
        key: "greater_or_equal_than",
        label: ">="
    },
    SMALLER_THAN: {
        key: "smaller_than",
        label: "<"
    },
    SMALLER_THAN_OR_EQUAL: {
        key: "smaller_or_equal_than",
        label: "<="
    },
});


class FilterFragment extends React.Component<FilterFragmentProps, FilterFragmentState> {

    childConditionRef: any = React.createRef();

    validateFilterData = (): boolean => {
        let isValid = true;
        this.state.conditionSet.forEach((condition) => {
            if (!condition) {
                isValid = false;
            }
            if (condition.type === TOPIC_MESSAGE_VALUE_TYPE) {
                if (StringUtil.isOnlySpaceOrEmpty(condition.topicName)
                    || StringUtil.isOnlySpaceOrEmpty(condition.comparatorKey)
                    || StringUtil.isOnlySpaceOrEmpty(condition.publishContent)
                ) {
                    isValid = false;
                }
            } else if (condition.type === TOPIC_KEY_VALUE_MESSAGE_TYPE) {
                if (StringUtil.isOnlySpaceOrEmpty(condition.topicName)
                    || StringUtil.isOnlySpaceOrEmpty(condition.subjectKey)
                    || StringUtil.isOnlySpaceOrEmpty(condition.comparatorKey)
                    || StringUtil.isOnlySpaceOrEmpty(condition.publishContent)
                ) {
                    isValid = false;
                }
            }

        });

        if (this.childConditionRef && this.childConditionRef.validateCondition) {
            this.childConditionRef.validateCondition();
        }
        this.setState({showError: !isValid});
        return isValid;
    };

    constructor(props: FilterFragmentProps) {
        super(props);

        let nextConditionKey = 0;
        this.props.conditionSet.forEach((condition) => {
            if (condition.key > nextConditionKey) {
                nextConditionKey = condition.key;
            }
        });
        this.state = {conditionSet: this.props.conditionSet, nextConditionKey: nextConditionKey + 1, showError: false};
    }

    createFirstCondition() {

        if (!this.state.conditionSet || this.state.conditionSet.length === 0) {
            let conditionSet = [{
                key: 0,
                type: SUB_FILTER_TYPE,
                subConditionKeys: [],
                conditionBetweenSubConditions: []
            }];
            this.setState({conditionSet: conditionSet});
        }
    }


    componentDidUpdate(prevProps: FilterFragmentProps) {

        if (prevProps.filterId !== this.props.filterId) {

            let nextConditionKey = 0;
            this.props.conditionSet.forEach((condition) => {
                if (condition.key > nextConditionKey) {
                    nextConditionKey = condition.key;
                }
            });
            let conditionSet = this.props.conditionSet && this.props.conditionSet.length > 0 ? this.props.conditionSet : [
                {
                    key: 0,
                    type: SUB_FILTER_TYPE,
                    subConditionKeys: [],
                    conditionBetweenSubConditions: []
                }
            ];
            console.log("componentDidUpdate, conditionSet", conditionSet);

            this.setState({conditionSet: conditionSet, nextConditionKey: nextConditionKey + 1, showError: false});
        }
    }

    getFilterData = (): Array<Object> => {
        return this.state.conditionSet;
    };

    updateInputField = (conditionKey: number, fieldName: string, fieldValue: string): void => {
        let conditionSet = this.state.conditionSet;
        console.log("updateInputField: fieldName=", fieldName, ",fieldValue=", fieldValue);

        conditionSet.forEach((condition: FilterCondition) => {
            if (condition.key === conditionKey) {
                condition[fieldName] = fieldValue;

                if (fieldName === FIELD_NAME_TOPIC_NAME && condition.type === TOPIC_KEY_VALUE_MESSAGE_TYPE) {
                    condition[FIELD_NAME_SUBJECT_KEY] = "";
                }

                console.log("updateInputField: conditionSet=", conditionSet, ",conditionKey=", conditionKey, ", condition=", conditionKey);
            }
        });

        this.setState({
            conditionSet: conditionSet
        })
    };

    createTopicKeyValueCompare = (conditionKey: number, sameLevel: boolean): void => {
        this.createCondition(conditionKey, sameLevel, TOPIC_KEY_VALUE_MESSAGE_TYPE);
    };

    createTopicTextContains = (conditionKey: number, sameLevel: boolean): void => {
        this.createCondition(conditionKey, sameLevel, TOPIC_MESSAGE_VALUE_TYPE);
    };

    createCondition(conditionKey: number, sameLevel: boolean, type: string) {

        let conditionSet = this.state.conditionSet;

        // find the sub filter that has the condition key
        conditionSet.forEach((condition: FilterCondition) => {
            if (condition.type === SUB_FILTER_TYPE && condition.key === conditionKey) {
                if (!condition.subConditionKeys) {
                    condition.subConditionKeys = []
                }
                condition.subConditionKeys.push(this.state.nextConditionKey);
                if (!condition.conditionBetweenSubConditions) {
                    condition.conditionBetweenSubConditions = []
                }
                if (condition.subConditionKeys && condition.subConditionKeys.length > 1 && condition.conditionBetweenSubConditions) {
                    condition.conditionBetweenSubConditions.push(AND_OPERATOR_KEY);
                }
            }
        });

        let nextConditionKey;

        if (sameLevel) {

            conditionSet.push({
                key: this.state.nextConditionKey,
                type: type
            });

            nextConditionKey = this.state.nextConditionKey + 1;
        } else {

            // create a sub filter (next level)
            conditionSet.push({
                key: this.state.nextConditionKey,
                type: SUB_FILTER_TYPE,
                subConditionKeys: [this.state.nextConditionKey + 1],
                conditionBetweenSubConditions: []
            });

            conditionSet.push({
                key: this.state.nextConditionKey + 1,
                type: type
            });
            nextConditionKey = this.state.nextConditionKey + 2;

        }


        this.setState({
            conditionSet: conditionSet,
            nextConditionKey: nextConditionKey
        })
    }

    onAndOrOperatorChangedCallback = (conditionKey: number, operatorIndex: number) => {
        let newConditionSet = this.state.conditionSet;
        newConditionSet.forEach((condition) => {
            if (condition.key === conditionKey && condition.conditionBetweenSubConditions) {
                if (condition.conditionBetweenSubConditions[operatorIndex] === AND_OPERATOR_KEY) {
                    condition.conditionBetweenSubConditions[operatorIndex] = OR_OPERATOR_KEY;
                } else {
                    condition.conditionBetweenSubConditions[operatorIndex] = AND_OPERATOR_KEY
                }
            }
        });

        this.setState({
            conditionSet: newConditionSet
        })
    };

    onDeleteCallback = (conditionKeyToDelete: number) => {
        let conditionSet = this.state.conditionSet;
        console.log("conditionKeyToDelete=", conditionKeyToDelete, ",conditionSet=", conditionSet);

        let emptySubFilterConditionKey;


        // remove the condition
        conditionSet = _.filter(conditionSet, (c) => {
            return c.key !== conditionKeyToDelete
        });
        console.log("removed the condition, conditionSet=", conditionSet);

        // remove sub_filter condition with no sub condition key
        conditionSet = conditionSet.map((condition: FilterCondition) => {
            let newCondition: FilterCondition = {
                ...condition,
                subConditionKeys: _.filter(condition.subConditionKeys || [], (ck) => {
                    return ck !== conditionKeyToDelete;
                })
            };
            if ((newCondition.subConditionKeys || []).length === 1) {
                newCondition.conditionBetweenSubConditions = undefined;
            }
            if ((newCondition.subConditionKeys || []).length === 0 && newCondition.type === SUB_FILTER_TYPE) {
                emptySubFilterConditionKey = condition.key;
                return null;
            }
            return newCondition;
        });
        console.log("remove sub_filter condition with no sub condition key, conditionSet=", conditionSet);

        // remove null
        conditionSet = _.filter(conditionSet, (c) => {
            return c;
        });
        console.log("remove null, conditionSet=", conditionSet);

        // remove all sub filter that has no sub condition keys
        while (emptySubFilterConditionKey) {
            const newConditionKeyToDelete = emptySubFilterConditionKey;
            emptySubFilterConditionKey = null;

            conditionSet = conditionSet.map((condition) => {
                let newCondition = {
                    ...condition,
                    subConditionKeys: _.filter(condition.subConditionKeys || [], (ck) => {
                        return ck !== newConditionKeyToDelete;
                    })
                };
                if ((newCondition.subConditionKeys || []).length === 1) {
                    newCondition.conditionBetweenSubConditions = undefined;
                }
                if (newCondition.subConditionKeys.length === 0 && newCondition.type === SUB_FILTER_TYPE) {
                    emptySubFilterConditionKey = condition.key;
                    console.log("emptySubFilterConditionKey=", emptySubFilterConditionKey);
                    return null;
                }
                return newCondition;
            });
            // remove null
            conditionSet = _.filter(conditionSet, (c) => {
                return c;
            });

        }
        console.log("remove all sub filter that has no sub condition keys, conditionSet=", conditionSet);


        // in case all delete, put default condition set
        if (conditionSet.length === 0) {
            conditionSet.push({
                key: 0,
                type: SUB_FILTER_TYPE,
                subConditionKeys: [],
                conditionBetweenSubConditions: []
            });
        }
        console.log("in case all delete, put default condition set, conditionSet=", conditionSet);

        this.setState({
            conditionSet: conditionSet
        });
    };


    render() {

        console.log("rendering, conditionSet=", this.state.conditionSet);

        return <div>
            {
                this.state.showError && <div
                    className="m-alert mt-4 m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show"
                    role="alert">
                    <div className="m-alert__icon"
                         style={{backgroundColor: Constant.theme.eventTrigger.filter.color}}>
                        <i className="flaticon-exclamation-1"/>
                        <span style={{borderColor: Constant.theme.eventTrigger.filter.color}}/>
                    </div>
                    <div className="m-alert__text"
                         style={{color: Constant.theme.eventTrigger.filter.color}}>
                        <strong>
                            {LLMsg("EVENT_TRIGGER.VALIDATION_FAILED")}
                        </strong>

                        {LLMsg("EVENT_TRIGGER.FILTER_VALIDATION_FAILED")}
                    </div>
                </div>
            }
            {
                this.state.conditionSet && this.state.conditionSet.length > 0 && <div className={styles.filterWrapper}>

                    <div className={styles.ifLabel}>
                        {LLMsg("EVENT_TRIGGER.IF")}
                    </div>
                    <ConditionFragment
                        currentRenderingConditionKey={0}
                        filterId={this.props.filterId}
                        conditionSet={this.state.conditionSet}
                        topics={this.props.topics}
                        onDeleteCallback={this.onDeleteCallback}
                        onAndOrOperatorChangedCallback={this.onAndOrOperatorChangedCallback}
                        updateInputField={this.updateInputField}
                        ref={(ref) => {
                            this.childConditionRef = ref;
                        }}
                        createTopicKeyValueCompare={this.createTopicKeyValueCompare}
                        createTopicTextContains={this.createTopicTextContains}
                    />
                    <div className={styles.thenLabel}>
                        {LLMsg("EVENT_TRIGGER.ACTION_TITLE")}
                    </div>
                </div>
            }

        </div>;
    }
}

export default FilterFragment;