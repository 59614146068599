// @flow

import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";

export default class MaintenanceApiUrlHelper {

    static getList(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_MAINTENANCE_LIST_API, []);
    }

    static getNext(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_MAINTENANCE_NEXT_API, []);
    }

    static update(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_MAINTENANCE_UPDATE_API, [])
    }

    static create(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_MAINTENANCE_CREATE_API, [])
    }

    static delete(maintenancePeriodId: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_MAINTENANCE_DELETE_API, [{
            key: ":maintenancePeriodId",
            value: maintenancePeriodId
        }])
    }
}