// @flow

import React, {Component} from 'react';
import Api from "../../../bundle/Api";
import {withRouter} from "react-router";
import Page from "../../../bundle/Page";
import UrlBuilder from "../../../url/UrlBuilder";
import styles from './index.css';
import Constant from "../../../bundle/Constant";
import UserDao from "../../../dao/UserDao";
import LoadingUtil from "../../../util/LoadingUtil";

type DirectLoginAccessFragmentProps = {
    match?: Object,
    location?: Object,
    history?: Object
}
type DirectLoginAccessFragmentState = {
    isInvalid: boolean
}

class DirectLoginAccessFragment extends Component<DirectLoginAccessFragmentProps, DirectLoginAccessFragmentState> {

    constructor(props) {
        super(props);

        this.state = {
            isInvalid: false
        }
    }

    componentDidMount() {
        let token = UrlBuilder.getQueryString()["token"];
        let email = UrlBuilder.getQueryString()["email"];
        if (token && email) {
            LoadingUtil.showFullScreenLoading();
            Api.services.user.loginByDirectAccessKey(email, token).then((response) => {
                if (response.status === 200) {
                    let accessToken = response.data.access_token;
                    let refreshToken = response.data.refresh_token;
                    let expiresIn = response.data.expires_in;
                    if (accessToken) {
                        Api.services.user.updateToken(accessToken, refreshToken, expiresIn);
                        Api.services.user.getMe().then((userResponse) => {
                            LoadingUtil.hideFullScreenLoading();

                            if (userResponse.status === 200) {

                                UserDao.updateUser(userResponse);

                                const ref = UrlBuilder.getQueryString()["ref"];
                                if (ref) {
                                    this.props.history && this.props.history.push(ref);
                                } else {
                                    this.props.history && this.props.history.push(Page.internals.urls.misc.home());
                                }
                            }
                        }).catch(() => {
                            LoadingUtil.hideFullScreenLoading();
                            this.setState({isInvalid: true});
                        });
                    } else {
                        LoadingUtil.hideFullScreenLoading();
                        this.setState({isInvalid: true});
                    }
                } else {
                    LoadingUtil.hideFullScreenLoading();
                    this.setState({isInvalid: true});
                }
            }).catch(() => {
                LoadingUtil.hideFullScreenLoading();
                this.setState({isInvalid: true});
            })
        } else {
            this.setState({isInvalid: true});
        }
    }

    render() {

        return <div>
            {
                this.state.isInvalid && <div className={Constant.portlet.size.colWidth.w12}>
                    <div className="m-portlet mb-0">
                        <div className="m-portlet__body">
                            <div className={styles.errorBox}>
                                The Login is expired or the link is invalid.
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>;
    }
}

export default withRouter(DirectLoginAccessFragment);
