// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../IntlCapture";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import styles from './index.css';
import UrlBuilder from "../../../url/UrlBuilder";
import StringUtil from "../../../util/StringUtil";
import ErrorUtil from "../../../util/ErrorUtil";
import Api from "../../../bundle/Api";
import {withRouter} from "react-router";
import LoadingUtil from "../../../util/LoadingUtil";
import UserDao from "../../../dao/UserDao";
import {RouterHistory} from "../../../AppRoute";

type GuestRegisterFormWrapperProps = {
    match?: Object,
    location?: Object,
    history?: Object
}

type GuestRegisterFormWrapperState = {
    showUsernameError: boolean,
    showEventCodeError: boolean
}

class GuestRegisterFormWrapper extends Component<GuestRegisterFormWrapperProps, GuestRegisterFormWrapperState> {

    usernameInput: any;
    eventCodeInput: any;

    constructor(props: GuestRegisterFormWrapperProps) {
        super(props);
        this.state = {
            showUsernameError: false,
            showEventCodeError: false
        };
        this.login = this.login.bind(this);
    }

    login = () => {
        const username = this.usernameInput.value;
        const eventCode = this.eventCodeInput.value;

        let isValid = true;
        if (!StringUtil.validateEmail(username)) {
            this.setState({showUsernameError: true});
            isValid = false;
        }

        if (!eventCode) {
            this.setState({showEventCodeError: true});
            isValid = false;
        }

        if (!isValid) {
            return;
        }

        LoadingUtil.showFullScreenLoading();
        this.setState({showUsernameError: false, showEventCodeError: false});
        Api.services.user.doCreateGuestUser(username, eventCode).then((response) => {
            if (response.status === 200) {
                let email = response.data.email;
                let directAccessKey = response.data.directAccessKey;

                Api.services.user.loginByDirectAccessKey(email, directAccessKey).then((response) => {
                    LoadingUtil.hideFullScreenLoading();
                    if (response.status === 200) {
                        let accessToken = response.data.access_token;
                        let refreshToken = response.data.refresh_token;
                        let expiresIn = response.data.expires_in;
                        if (accessToken) {
                            Api.services.user.updateToken(accessToken, refreshToken, expiresIn);

                            Api.services.user.getMe().then((userResponse) => {
                                LoadingUtil.hideFullScreenLoading();
                                if (userResponse.status === 200) {
                                    UserDao.updateUser(userResponse);
                                    const ref = UrlBuilder.getQueryString()["ref"];
                                    RouterHistory().push(ref || Page.internals.urls.misc.home());
                                }
                            }).catch((e) => {
                                LoadingUtil.hideFullScreenLoading();
                                ErrorUtil.promptError(e);
                            });
                        }
                    }
                }).catch((e) => {
                    ErrorUtil.promptError(e);
                    LoadingUtil.hideFullScreenLoading();
                });
            } else {
                LoadingUtil.hideFullScreenLoading();
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
            LoadingUtil.hideFullScreenLoading();
        })

    };

    componentDidMount() {
        this.usernameInput.focus();
    }

    render() {

        const ref = UrlBuilder.getQueryString()["ref"];
        return <div
            className="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper">
            <div className="m-login__body">
                <div className="m-login__signin">
                    <div className="m-login__title">
                        <h3>{LLMsg("REGISTER_PAGE.REGISTER_GUEST_ACCOUNT")}</h3>
                    </div>
                    <form className="m-login__form m-form">

                        {
                            this.state.showUsernameError &&
                            <div className={styles.invalidGuestEmailPrompt + " alert"}
                                 role="alert">
                                {LLMsg("REGISTER_PAGE.INVALID_GUEST_USERNAME")}
                            </div>
                        }
                        {
                            this.state.showEventCodeError &&
                            <div className={styles.invalidGuestEmailPrompt + " alert"}
                                 role="alert">
                                {LLMsg("REGISTER_PAGE.EVENT_CODE_INVALID")}
                            </div>
                        }
                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.GUEST_USERNAME")}
                                   name="username" autoComplete="off" ref={(usernameInput) => {
                                this.usernameInput = usernameInput
                            }}/>
                        </div>
                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.EVENT_CODE_LABEL")}
                                   name="eventCode" autoComplete="off" ref={(eventCodeInput) => {
                                this.eventCodeInput = eventCodeInput
                            }}/>
                        </div>
                        <hr className={"mt-5"}/>
                        <div className={styles.expiryNotice}>
                            {LLMsg("REGISTER_PAGE.GUEST_EXPIRY_NOTICE")}
                        </div>
                    </form>
                    <div className="m-login__action">
                        <Link to={Page.internals.urls.user.login(ref)} className="m-link">
                            <span>{LLMsg("REGISTER_PAGE.BACK_TO_LOGIN_PAGE")}</span>
                        </Link>
                        <a href="javascript:" onClick={() => {
                            this.login()
                        }}>
                            <button className="btn btn-accent m-btn m-btn--pill p-3">
                                {LLMsg("REGISTER_PAGE.CONFIRM_REGISTER_GUEST")}
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>;
    }

}

export default withRouter(GuestRegisterFormWrapper);