// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import type {FilterCondition} from "../component/dataTrigger/filter/ConditionFragment";
import type {AppletAction} from "../component/dataTrigger/action/ActionFragment";

class EventTriggerService {

    static create(triggers: ? Array<Object>, filters: ? Array<FilterCondition>, actions: ? Array<AppletAction>, appletName: ?string, enabled: boolean, projectId: string): Object {
        return HttpUtil.ajax(Api.urls.eventTrigger.create(), "POST", {
            'Content-Type': 'application/json',
        }, {
            triggers, filters, actions, name: appletName, enabled: enabled, projectId
        });
    }

    static update(appletId: string, triggers: ? Array<Object>, filters: ? Array<FilterCondition>, actions: ? Array<AppletAction>, appletName: ?string, enabled: boolean, projectId: string): Object {
        return HttpUtil.ajax(Api.urls.eventTrigger.update(), "POST", {
            'Content-Type': 'application/json',
        }, {
            appletId, triggers, filters, actions, name: appletName, enabled: enabled, projectId
        });
    }

    static delete(projectId: string, appletId: string) {
        return HttpUtil.ajax(Api.urls.eventTrigger.delete(appletId, projectId), "POST", {
            'Content-Type': 'application/json',
        });
    };

    static get = {
        list: (projectId: string) => {
            return HttpUtil.ajax(Api.urls.eventTrigger.get.list(projectId), "GET", {
                'Content-Type': 'application/json',
            });
        },
        detail: (projectId: string, appletId: string) => {
            return HttpUtil.ajax(Api.urls.eventTrigger.get.detail(appletId, projectId), "GET", {
                'Content-Type': 'application/json',
            });
        }
    }

    static trigger = {
        objectDetection: (projectId: string, detectedObject: string) => {
            return HttpUtil.ajax(Api.urls.eventTrigger.trigger.objectDetection(projectId, detectedObject), "GET", {
                'Content-Type': 'application/json',
            });
        },
        faceDetection: (projectId: string) => {
            return HttpUtil.ajax(Api.urls.eventTrigger.trigger.faceDetection(projectId), "GET", {
                'Content-Type': 'application/json',
            });
        }
    }

}

export default EventTriggerService;