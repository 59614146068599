exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3KpwCmnuLqp3MiWLCmk0K {\n}\n\n.Kd5Q7g2yBRWj9nSzR-grt {\n    margin-top: 30px;\n    text-align: center;\n}\n\n.Kd5Q7g2yBRWj9nSzR-grt img {\n    width: 250px;\n}\n\n.qQWESLpe9Yude5s9E-DdM {\n    text-align: center;\n}\n\n.qQWESLpe9Yude5s9E-DdM .Kd5Q7g2yBRWj9nSzR-grt {\n    margin-top: 50px !important;\n}\n\n.qQWESLpe9Yude5s9E-DdM ._2I2OA43hKZtv8-zXnSgZMt {\n    font-size: 20px;\n    font-weight: bold;\n}\n\n._2r259DUzL1MbXhSmIWqnny {\n}\n\n._3pK0lyP6gzbb_NqpVO-ui2 {\n    display: flex;\n}\n\n._1DIEb-GcQOygwVpYeJ5tQX {\n\n    border-radius: 30px;\n    margin-right: 20px;\n    margin-bottom: 20px;\n    display: inline-block;\n    padding: 20px;\n    box-shadow: 0 0 36px rgba(0, 0, 0, 0.1) !important;\n}\n\n._2I2OA43hKZtv8-zXnSgZMt {\n    font-size: 16px;\n    margin-bottom: 20px;\n}", ""]);

// exports
exports.locals = {
	"contentBox": "_3KpwCmnuLqp3MiWLCmk0K",
	"imageBox": "Kd5Q7g2yBRWj9nSzR-grt",
	"resultBox": "qQWESLpe9Yude5s9E-DdM",
	"boxTitle": "_2I2OA43hKZtv8-zXnSgZMt",
	"styleBox": "_2r259DUzL1MbXhSmIWqnny",
	"row": "_3pK0lyP6gzbb_NqpVO-ui2",
	"box": "_1DIEb-GcQOygwVpYeJ5tQX"
};