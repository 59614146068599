exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3nCXLyB52vqhIMDEWZyzrf {\n    font-size: 18px;\n    padding-left: 25px;\n    padding-top: 20px;\n    padding-bottom: 20px;\n    font-weight: initial;\n    border-bottom: 1px solid lightgrey;\n}\n\n._1Id8sOZYa9WNFJIFYLTweW {\n    font-family: \"Arial Black\", Gadget, sans-serif\n}\n\n._2s-OhAhoAtrzuspWxFdaxv:hover {\n    background-color: #3498db !important;\n    color: white;\n}\n\n._2s-OhAhoAtrzuspWxFdaxv {\n    border-bottom: 1px solid lightgrey;\n\n}\n\n._2Ifd_uIwLZ8jlQZq5s4KmV {\n    background-color: #ecf0f1;\n    color: #575962;\n    padding: 7px 10px;\n    width: 100%;\n}\n\n._39JDg8r4j4IbfgasixJG8L {\n    display: inline-block;\n    width: 250px;\n    padding-top: 7px;\n    padding-bottom: 7px;\n    padding-left: 15px;\n\n}\n\n._1CF0lp6-eKCeXZaw0dycbP {\n    padding-left: 5px;\n    padding-top: 7px;\n    padding-bottom: 7px;\n    display: inline-block;\n    width: 50px;\n}", ""]);

// exports
exports.locals = {
	"sectionHeader": "_3nCXLyB52vqhIMDEWZyzrf",
	"portletBox": "_1Id8sOZYa9WNFJIFYLTweW",
	"item": "_2s-OhAhoAtrzuspWxFdaxv",
	"groupTitle": "_2Ifd_uIwLZ8jlQZq5s4KmV",
	"itemTitle": "_39JDg8r4j4IbfgasixJG8L",
	"itemValue": "_1CF0lp6-eKCeXZaw0dycbP"
};