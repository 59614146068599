// @flow
import React, {Component} from 'react';
import styles from './index.css';
import {ClassNames} from "../../../../bundle/ClassNames";

type Props = {
    titleContent: any,
    extraContent?: any,
    allowDelete: boolean,
    backgroundColor: string,
    onDelete?: Function
}

export default class EventTriggerItemFragment extends Component<Props, any> {
    render() {
        return <React.Fragment>
            <div className={styles.wrapper}
                 style={{
                     background: this.props.backgroundColor,
                     border: "solid 3px " + this.props.backgroundColor
                 }}>

                <div className={styles.title}>
                    {this.props.titleContent}
                    {
                        this.props.allowDelete &&
                        <i className={ClassNames(styles.deleteWrapper, "fas fa-times")} onClick={this.props.onDelete}/>
                    }
                </div>
                {
                    this.props.extraContent && <div className={styles.extraContent}>
                        {this.props.extraContent}
                    </div>
                }
                <div className={styles.wrapperAfter} style={{
                    borderTop: "10px solid " + this.props.backgroundColor
                }}/>
            </div>
        </React.Fragment>
    }
}

