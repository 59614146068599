exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3hXexSvnQDL1bi690T6sB9 {\n    width: 100%;\n}\n\n._3KEx6faKe28MY_YUI29g5_ {\n    border: none;\n}", ""]);

// exports
exports.locals = {
	"box": "_3hXexSvnQDL1bi690T6sB9",
	"iframe": "_3KEx6faKe28MY_YUI29g5_"
};