// @flow

import TopicAction from "../action/TopicAction";
import type ReduxAction from "./index";

const TopicReducer = (state: any = [], action: ReduxAction) => {
    switch (action.type) {
        case (TopicAction.GET_LIST_OF_TOPIC_ACTION_TYPE):
            return {
                ...state,
                [TopicAction.GET_LIST_OF_TOPIC_ACTION_TYPE]: action
            };
        case (TopicAction.GET_ONE_TOPIC_ACTION_TYPE):
            return {
                ...state,
                [TopicAction.GET_ONE_TOPIC_ACTION_TYPE]: action
            };
        default:
            return state
    }
};

export default TopicReducer;