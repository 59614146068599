import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";

export default class ProjectApiUrlHelper {

    static getProjectCreateUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_CREATE_API, []);
    }

    static detail(projectId: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_DETAIL_API, [{
            key: ":projectId",
            value: projectId
        }]);
    }

    static getListOfCoordinate(embedMapToken: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_COORDINATE_API, [{
            key: ":embedMapToken",
            value: embedMapToken
        }])
    }

    static file = {
        getList: (projectId: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_FILE_LIST_API, [{
                key: ":projectId",
                value: projectId
            }])
        },
        uploadProjectFile: (projectId: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_FILE_UPLOAD_API, [{
                key: ":projectId",
                value: projectId
            }])
        },
        deleteProjectFile: (projectId: string, fileId: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_FILE_DELETE_API, [{
                key: ":projectId",
                value: projectId
            }, {
                key: ":fileId",
                value: fileId
            }])
        },
        downloadProjectFile: (projectId: string, fileId: string, filename: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_FILE_DOWNLOAD_API, [{
                key: ":projectId",
                value: projectId
            }, {
                key: ":fileId",
                value: fileId
            }, {
                key: ":filename",
                value: filename
            }])
        }
    };

    static share = {
        getToken: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_GET_SHARE_TOKEN_API, []);
        },
        join: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_JOIN_BY_TOKEN_API, []);
        },
        checkHasJoined: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_CHECK_TOKEN_STATUS_API, []);
        },
        remove: (projectId: number, userId: number): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_REMOVE_USER_API, [{
                key: ":projectId",
                value: projectId
            }, {
                key: ":userId",
                value: userId
            }])
        }
    };

    static getCreateProjectLimitUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_CREATE_LIMIT_API, []);
    }

    static update = {
        name: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_UPDATE_API, []);
        },
        removeTopicFromProject: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.UNLINK_TOPIC_FROM_PROJECT, []);
        },
        createAndLinkTopicToProject: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.LINK_NEW_TOPIC_TO_PROJECT, []);
        },
        linkTopicToProject: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.LINK_EXISTING_TOPIC_TO_PROJECT, []);
        },
        deleteWithData: (projectId: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_DELETE_WITH_DATA_API, [{
                key: ":projectId",
                value: projectId
            }]);
        }
    };

    static getListOfProjectUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.PROJECT_LISTING_API, []);
    }


}