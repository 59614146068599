// @flow

import React from "react";
import styles from './ContentHeaderFragment.css';

type ContentHeaderFragmentProps = {
    children: Object
}

const ContentHeaderFragment = (props: ContentHeaderFragmentProps) => {

    return <div className="m-subheader pl-4 pr-4 pt-4">
        <div className={"d-flex align-items-center " + styles.breadcrumbWrapper}>
            <div className="mr-auto">
                {props.children}
            </div>
        </div>
    </div>;
};
export default ContentHeaderFragment;