// @flow

import Config from "../../config/Config";
import Api from "../../bundle/Api";
import StringUtil from "../../util/StringUtil";

export default class OpenApiApiUrlHelper {

    static getAvailableWeatherOption(): string {
        return Config.API_MAKER_CLOUD + Api.paths.OPEN_API_WEATHER_OPTIONS;
    }

    static getLatestWeather(typeName: string, stationName: string, withoutDomain?: boolean): string {

        let prefix = Config.API_MAKER_CLOUD + Api.paths.OPEN_API_LATEST_WEATHER;
        if (withoutDomain) {
            prefix = Api.paths.OPEN_API_LATEST_WEATHER;
        }

        return StringUtil.replaceAllText(prefix, [{
                key: ":typeName",
                value: typeName
            }, {
                key: ":stationName",
                value: stationName
            }]
        );
    }

}