exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._7ZcW4vv2msurs1SPyt2nB {\n\n    border-color: #f4516c;\n    color: #f4516c;\n    background-color: #F7F7F9;\n    font-weight: 400;\n}\n\n._2_QVKKO4DCPv3tRbh-H66- {\n    margin: 10px 15px 5px 25px;\n    color: #585661;\n}", ""]);

// exports
exports.locals = {
	"errorPrompt": "_7ZcW4vv2msurs1SPyt2nB",
	"notice": "_2_QVKKO4DCPv3tRbh-H66-"
};