// @flow

import React from "react";

type SidebarGroupHeaderProps = {
    displayText: string
}

const SidebarGroupHeader = (props: SidebarGroupHeaderProps) => {

    const {displayText} = props;
    return <li className="m-menu__section m-menu__section--first">
        <span className="m-menu__section-text m--icon-font-size-lg1">{displayText}</span>
        <i className="m-menu__section-icon flaticon-more-v2"/>
    </li>;
};


export default SidebarGroupHeader;