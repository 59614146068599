// @flow

import PageUrl from "./PageUrl";

export default class MaintenancePeriodPageUrl {

    static admin = {
        list: (): string => {
            return PageUrl.ADMIN_MAINTENANCE_PERIOD_LIST_URL;
        }
    };
    
    static underMaintenance = (): string => {
        return PageUrl.STATUS_DOWN_URL;
    }
}