// @flow

import {LLMsg} from "../IntlCapture";

export default class LoadingUtil {
    static showFullScreenLoading(): void {
        window.mApp.block("body", {
            overlayColor: "#000000",
            type: "loader",
            state: "success",
            message: LLMsg("COMMON.ACTION.LOADING_MESSAGE")
        });
    }

    static hideFullScreenLoading(): void {
        window.mApp.unblock("body");
    }

}
