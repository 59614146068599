// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../../../IntlCapture";
import SchoolApplicationItemWrapper from "../SchoolApplicationItemWrapper";
import SchoolApplicationItem from "../SchoolApplicationItem";
import styles from './SchoolApplicationTable.css';
import Constant from "../../../../../bundle/Constant";
import SchoolService from "../../../../../service/SchoolService";
import ApproveSchoolApplicationButton from "./ApproveSchoolApplicationButton";
import RejectSchoolApplicationButton from "./RejectSchoolApplicationButton";
import TimeUtil from "../../../../../util/TimeUtil";

type SchoolApplicationTableRowState = {
    showDetail: boolean
}
type SchoolApplicationTableRowProps = {
    id: string,
    eventName: string,
    eventCode: string,
    eventStart: string,
    eventEnd: string,
    teacherName: string,
    teacherEmail: string,
    teacherTel: string,
    teacherRole: string,
    teacherRoleRemarks: string,
    applicationStatus: string,
    createdOn: string
}

class SchoolApplicationTableRow extends Component<SchoolApplicationTableRowProps, SchoolApplicationTableRowState> {

    state: SchoolApplicationTableRowState = {
        showDetail: false
    };

    render() {

        let applicationStatus = LLMsg("COMMON.SCHOOL.PENDING");
        if (this.props.applicationStatus === Constant.school.application.status.approved) {
            applicationStatus = LLMsg("COMMON.SCHOOL.APPROVED");
        } else if (this.props.applicationStatus === Constant.school.application.status.rejected) {
            applicationStatus = LLMsg("COMMON.SCHOOL.REJECTED");
        }
        return <React.Fragment>
            <tr data-row={0} className={"m-datatable__row " + styles.row} onClick={() => {
                this.setState({showDetail: !this.state.showDetail})
            }}>
                <td className="m-datatable__cell m-datatable__toggle--detail">
                    <a className="m-datatable__toggle-detail">
                        <i className="fa fa-caret-right" style={{width: '21px'}}/>
                    </a>
                </td>
                <td data-field="OrderID" className="m-datatable__cell">
                                <span style={{width: '50px'}}>
                                    {this.props.id}
                                </span>
                </td>
                <td data-field="OrderID" className="m-datatable__cell">
                                <span style={{width: '150px'}}>
                                    {this.props.eventName}
                                </span>
                </td>
                <td data-field="OrderID" className="m-datatable__cell">
                                <span style={{width: '150px'}}>
                                    {this.props.teacherName}
                                </span>
                </td>
                <td data-field="OrderID" className="m-datatable__cell">
                                <span style={{width: '100px'}}>
                                    {applicationStatus}

                                    {
                                        (this.props.applicationStatus === Constant.school.application.status.pending) &&
                                        <i className={"fa fa-exclamation " + styles.approvalStatusMark}/>
                                    }
                                </span>
                </td>
                <td data-field="OrderID" className="m-datatable__cell">
                                <span style={{width: '200px'}}>
                                    {TimeUtil.convertTimeFormat(this.props.createdOn, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                                </span>
                </td>
            </tr>
            {
                this.state.showDetail && <tr>
                    <SchoolApplicationItemWrapper>
                        <SchoolApplicationItem label={LLMsg("COMMON.ID")}
                                               value={this.props.id}/>

                        <SchoolApplicationItem label={LLMsg("COMMON.SCHOOL.APPLICATION_STATUS")}
                                               value={applicationStatus}/>

                        <SchoolApplicationItem label={LLMsg("COMMON.EVENT.EVENT_NAME")}
                                               value={this.props.eventName}/>

                        <SchoolApplicationItem label={LLMsg("COMMON.EVENT.EVENT_CODE")}
                                               value={this.props.eventCode}/>

                        <SchoolApplicationItem label={LLMsg("COMMON.EVENT.EVENT_TRIAL_PERIOD")}
                                               value={this.props.eventStart && this.props.eventEnd ?
                                                   TimeUtil.convertTimeFormat(this.props.eventStart, TimeUtil.FULL_DATE_DISPLAY_FORMAT) + " - " + TimeUtil.convertTimeFormat(this.props.eventEnd, TimeUtil.FULL_DATE_DISPLAY_FORMAT) :
                                                   ""}/>
                        <SchoolApplicationItem label={LLMsg("REGISTER_PAGE.TEACHER_NAME")}
                                               value={this.props.teacherName}/>
                        <SchoolApplicationItem label={LLMsg("REGISTER_PAGE.GUEST_USERNAME")}
                                               value={this.props.teacherEmail}/>
                        <SchoolApplicationItem label={LLMsg("REGISTER_PAGE.TEACHER_TEL")}
                                               value={this.props.teacherTel}/>
                        <SchoolApplicationItem label={LLMsg("REGISTER_PAGE.TEACHER_ROLE")}
                                               value={SchoolService.translateTeacherRole(this.props.teacherRole)}/>
                        <SchoolApplicationItem label={LLMsg("REGISTER_PAGE.TEACHER_ROLE_REMARKS")}
                                               value={this.props.teacherRoleRemarks}/>
                        <SchoolApplicationItem label={LLMsg("COMMON.SCHOOL.SUBMISSION_TIME")}
                                               value={TimeUtil.convertTimeFormat(this.props.createdOn, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}/>
                        {
                            this.props.applicationStatus === Constant.school.application.status.pending &&
                            <SchoolApplicationItem label={<span>&nbsp;</span>}
                                                   value={<span>
                                                    <ApproveSchoolApplicationButton
                                                        schoolApplicationId={this.props.id}/>
                                                    <RejectSchoolApplicationButton schoolApplicationId={this.props.id}/>
                                               </span>}/>
                        }


                    </SchoolApplicationItemWrapper>
                </tr>
            }
        </React.Fragment>
    }
}

export default SchoolApplicationTableRow;