// @flow


import React from "react";
import {Link} from "react-router-dom";
import type {ToObject} from "../../url/page/PageUrl";

type BreadcrumbItemProps = {
    linkTo?: string | ToObject,
    displayText: string
}

const BreadcrumbItem = (props: BreadcrumbItemProps) => {

    const {linkTo, displayText} = props;
    return <React.Fragment>
        <li className="m-nav__separator">
                <i className="fa fa-angle-right ml-2 mr-2 pt-1 m--icon-font-size-lg1"/>
        </li>
        <li className="m-nav__item">
            {
                linkTo && <Link to={linkTo} className="m-nav__link">
                    <span className="m-nav__link-text m--icon-font-size-lg2">{displayText}</span>
                </Link>
            }
            {
                !linkTo && <span className="m-nav__link">
                    <span className="m-nav__link-text m--icon-font-size-lg2">{displayText}</span>
                </span>
            }
        </li>
    </React.Fragment>
};

export default BreadcrumbItem;