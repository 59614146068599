exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2VlrsOTAePSMc89wlrHhvQ {\n    border-top: 1px solid #eee;\n    left: 0;\n    cursor: pointer;\n}\n\n._2VlrsOTAePSMc89wlrHhvQ:hover {\n    background-color: #eee;\n}\n\n._2UpuohpvcYDOQtJ0tPKOiC {\n    color: #716aca;\n    margin-left: 10px;\n}\n\n._2JjaKvlyaUIvRoIgwt7DjX {\n    padding-bottom: 10px;\n}\n\n._2_HHHtWpKD8nVyLOaYA87_ {\n}\n\n.Ae5HfoGDmVvaZk2kGrlpO {\n    padding: 10px;\n    width: 300px;\n    font-weight: bold;\n    border-right: 1px solid #eee;\n\n}\n\n._2HGuJJHnFYLhwkhZ-h_eqR {\n    padding: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"row": "_2VlrsOTAePSMc89wlrHhvQ",
	"approvalStatusMark": "_2UpuohpvcYDOQtJ0tPKOiC",
	"itemWrapper": "_2JjaKvlyaUIvRoIgwt7DjX",
	"item": "_2_HHHtWpKD8nVyLOaYA87_",
	"itemLabel": "Ae5HfoGDmVvaZk2kGrlpO",
	"itemValue": "_2HGuJJHnFYLhwkhZ-h_eqR"
};