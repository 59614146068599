// @flow

import React from 'react';
import styles from './index.css';
import {ClassNames} from "../../../../bundle/ClassNames";
import LoadingUtil from "../../../../util/LoadingUtil";
import defaultTransferResult from './defaultTransferResult.png';
import defaultTransferStep1 from './defaultTransferStep1.jpg';
import defaultTransferStep2 from './defaultTransferStep2.jpg';
import {LLMsg} from "../../../../IntlCapture";

const model = new window.mi.ArbitraryStyleTransferNetwork();

export default class ImageStyleTransferFragment extends React.Component<any, any> {

    contentImageRef: any;
    styleImageRef: any;
    resultRef: any;
    isUnmounted: false;

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    constructor(props) {
        super(props);
        this.contentImageRef = React.createRef();
        this.styleImageRef = React.createRef();
        this.resultRef = React.createRef();
    }


    componentDidMount() {
        this.setupDemo();

        let base_image = new Image();
        base_image.src = defaultTransferResult;
        base_image.onload = () => {
            const ctx = this.resultRef.current.getContext("2d");
            ctx.drawImage(base_image, 0, 0);
        }
    }

    setupDemo() {
        model.initialize().then(() => {
            if (!this.isUnmounted) {
                // this.stylize();
            }
        });
    }

    async clearCanvas() {
        const ctx = this.resultRef.current.getContext("2d");
        // Don't block painting until we've reset the state.
        await window.mi.tf.nextFrame();
        ctx.clearRect(0, 0, this.resultRef.current.width, this.resultRef.current.height);
        await window.mi.tf.nextFrame();
    }


    startLoading() {
        LoadingUtil.showFullScreenLoading();
    }

    stopLoading() {
        LoadingUtil.hideFullScreenLoading();
    }

    async stylize() {
        await this.clearCanvas();

        // Resize the canvas to be the same size as the source image.
        this.resultRef.current.width = this.contentImageRef.current.width;
        this.resultRef.current.height = this.contentImageRef.current.height;

        // This does all the work!
        model.stylize(this.contentImageRef.current, this.styleImageRef.current).then((imageData) => {
            if (!this.isUnmounted) {
                this.stopLoading();
                const ctx = this.resultRef.current.getContext("2d");
                ctx.putImageData(imageData, 0, 0);
            }
        });
    }

    loadImage(event, imgElement) {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (this.isUnmounted) return;
            imgElement.src = e.target.result;
            this.startLoading();
            this.stylize();
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    loadContent(event) {
        this.loadImage(event, this.contentImageRef.current);
    }

    loadStyle(event) {
        this.loadImage(event, this.styleImageRef.current);
    }

    render() {
        return <div>
            <div className={ClassNames(styles.row, "row")}>
                <div className={ClassNames(styles.contentBox, styles.box, "col-12 col-lg-3")}>
                    <div className={styles.boxTitle}>{LLMsg("AI.IMAGE_TRANSFER.STEP_1")}</div>
                    <input type="file"
                           onChange={(event => this.loadContent(event))}/>

                    <div className={styles.imageBox}>
                        <img ref={this.contentImageRef} className="image" crossOrigin="anonymous"
                             src={defaultTransferStep1}
                             alt={""}/>
                    </div>

                </div>
                <div className={ClassNames(styles.styleBox, styles.box, "col-12 col-lg-3")}>
                    <div className="frame">
                        <div className={styles.boxTitle}>{LLMsg("AI.IMAGE_TRANSFER.STEP_2")}</div>
                        <input type="file"
                               onChange={event => this.loadStyle(event)}/>
                        <div className={styles.imageBox}>
                            <img ref={this.styleImageRef}
                                 className="image" crossOrigin="anonymous"
                                 alt={""}
                                 src={defaultTransferStep2}/>
                        </div>
                    </div>
                </div>
                <div className={ClassNames(styles.resultBox, styles.box, "col-12 col-lg-5")}>

                    <h2 className="dark">
                        <div className={styles.boxTitle}>{LLMsg("AI.IMAGE_TRANSFER.STEP_3")}</div>
                    </h2>
                    <canvas ref={this.resultRef} className={styles.imageBox} height="250px"/>
                </div>

            </div>
        </div>
    }
}