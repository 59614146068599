exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3yU5A00-TzXJjlMLL-OJEw {\n    margin-right: 10px;\n    margin-left: 10px;\n\n    color: #67666e;\n    background-color: #ebedf2;\n    border-color: #ebedf2;\n}\n\n._3yU5A00-TzXJjlMLL-OJEw:hover {\n\n    color: #fff;\n    background-color: #00c5dc;\n    border-color: #00c5dc;\n}", ""]);

// exports
exports.locals = {
	"button": "_3yU5A00-TzXJjlMLL-OJEw"
};