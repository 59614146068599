// @flow

import React from 'react';
import styles from "../index.css";
import Switch from "react-bootstrap-switch";
import Constant from "../../../../bundle/Constant";
import {LLMsg} from "../../../../IntlCapture";
import * as Datetime from "react-datetime";
import UserDao from "../../../../dao/UserDao";
import moment from "moment";
import ErrorUtil from "../../../../util/ErrorUtil";

type ChartHistoryDataFragmentProps = {
    chartType: string,
    showLiveData: () => void,
    searchHistoryData: (startTime: string, endTime: string, interval: string, aggregate: string) => void

}

type ChartHistoryDataFragmentState = {
    isLive: boolean,
    interval: string,
    aggregate: string,
    startTime: any,
    endTime: any;
}

class ChartHistoryDataFragment extends React.Component<ChartHistoryDataFragmentProps, ChartHistoryDataFragmentState> {

    searchHistoryData = () => {

        if (this.state.startTime && this.state.startTime.diff(moment(), "d") <= UserDao.permission.allowViewableHistoryDay) {

            let interval = this.state.interval;
            let aggregate = this.state.aggregate;
            let startTime = this.state.startTime && this.state.startTime.format("YYYY-MM-DD HH:mm:ss Z");
            let endTime = this.state.endTime && this.state.endTime.format("YYYY-MM-DD HH:mm:ss Z");

            this.props.searchHistoryData(startTime, endTime, interval, aggregate);
        } else {
            ErrorUtil.showPermissionNotAllowed();
        }
    };

    constructor(props: ChartHistoryDataFragmentProps) {
        super(props);
        this.state = {isLive: true, interval: "", aggregate: "", startTime: "", endTime: ""};
    }

    handleIsLiveSwitch(elem: any, state: any) {
        console.log("newstate=", state);
        if (state) {
            this.props.showLiveData();
        }
        this.setState({isLive: state});
    }

    render() {
        return <div>
            <div className={styles.liveBar}>
                <Switch
                    disabled={this.props.chartType !== Constant.chart.type.basicLineChart}
                    value={this.state.isLive}
                    labelText={this.state.isLive ?
                        <span><i className="fa fa-history"/> <strong>{LLMsg("COMMON.CHART.CHART_HISTORY_DATA")}</strong></span> :
                        <span><i
                            className="fa fa-circle"/> <strong>{LLMsg("COMMON.CHART.CHART_LIVE_DATA")}</strong></span>}
                    onChange={(el, state) => this.handleIsLiveSwitch(el, state)}
                    wrapperClass={styles.liveButton}
                    inverse={true}
                    bsSize={"normal"}
                    onText={<span><i className="fa fa-circle"/> <strong>{LLMsg("COMMON.CHART.CHART_LIVE_DATA")}</strong></span>}
                    offText={<span><i
                        className="fa fa-history"/> <strong>{LLMsg("COMMON.CHART.CHART_HISTORY_DATA")}</strong></span>}
                    offColor={"default"}
                    onColor={"danger"}/>
            </div>
            {
                !this.state.isLive && <div className={"pt-2 pb-5 pl-5 pr-5"}>
                    <div className="form-group m-form__group row">
                        <label htmlFor="example-text-input"
                               className="col-2 col-form-label">
                            {LLMsg("COMMON.CHART.CHART_HISTORY_STARTING_FROM")}
                        </label>
                        <div className="col-10">
                            <Datetime
                                value={this.state.startTime}
                                onChange={value => this.setState({startTime: value})}
                            />
                        </div>
                    </div>
                    <div className="form-group m-form__group row">
                        <label htmlFor="example-text-input"
                               className="col-2 col-form-label">
                            {LLMsg("COMMON.CHART.CHART_HISTORY_UNTIL")}
                        </label>
                        <div className="col-10">
                            <Datetime
                                value={this.state.endTime}
                                onChange={value => this.setState({endTime: value})}
                            />
                        </div>
                    </div>
                    <div className="form-group m-form__group row">
                        <label htmlFor="example-text-input"
                               className="col-2 col-form-label">
                            {LLMsg("COMMON.CHART.CHART_HISTORY_FOR_EVERY")}
                        </label>
                        <div className="col-10">
                            <div className="m-radio-inline">
                                <label className="m-radio">
                                    <input type="radio" name="INTERVAL"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   interval: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.interval === Constant.chart.interval.MONTH}
                                           value={Constant.chart.interval.MONTH}
                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_EVERY_MONTH")}
                                    <span/>
                                </label>
                                <label className="m-radio">
                                    <input type="radio" name="INTERVAL"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   interval: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.interval === Constant.chart.interval.DAY}
                                           value={Constant.chart.interval.DAY}
                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_EVERY_DAY")}
                                    <span/>
                                </label>
                                <label className="m-radio">
                                    <input type="radio" name="INTERVAL"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   interval: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.interval === Constant.chart.interval.HOUR}
                                           value={Constant.chart.interval.HOUR}
                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_EVERY_HOUR")}
                                    <span/>
                                </label>

                                <label className="m-radio">
                                    <input type="radio" name="INTERVAL"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   interval: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.interval === Constant.chart.interval.MINUTE}
                                           value={Constant.chart.interval.MINUTE}
                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_EVERY_MINUTE")}
                                    <span/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group m-form__group row">
                        <label htmlFor="example-text-input"
                               className="col-2 col-form-label">
                            {LLMsg("COMMON.CHART.CHART_HISTORY_AGGREGATE_VALUE")}
                        </label>
                        <div className="col-10">
                            <div className="m-radio-inline">
                                <label className="m-radio">
                                    <input type="radio" name="AGGREGATE"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   aggregate: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.aggregate === Constant.chart.aggregate.AVERAGE}
                                           value={Constant.chart.aggregate.AVERAGE}
                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_AGGREGATE_AVG")}
                                    <span/>
                                </label>
                                <label className="m-radio">
                                    <input type="radio" name="AGGREGATE"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   aggregate: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.aggregate === Constant.chart.aggregate.SUM}
                                           value={Constant.chart.aggregate.SUM}
                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_AGGREGATE_SUM")}
                                    <span/>
                                </label>
                                <label className="m-radio">
                                    <input type="radio" name="AGGREGATE"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   aggregate: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.aggregate === Constant.chart.aggregate.MIN}
                                           value={Constant.chart.aggregate.MIN}
                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_AGGREGATE_MIN")}
                                    <span/>
                                </label>
                                <label className="m-radio">
                                    <input type="radio" name="AGGREGATE"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   aggregate: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.aggregate === Constant.chart.aggregate.MAX}
                                           value={Constant.chart.aggregate.MAX}

                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_AGGREGATE_MAX")}
                                    <span/>
                                </label>
                                <label className="m-radio">
                                    <input type="radio" name="AGGREGATE"
                                           onChange={(changeEvent) => {
                                               this.setState({
                                                   aggregate: changeEvent.target.value
                                               });
                                           }}
                                           checked={this.state.aggregate === Constant.chart.aggregate.COUNT}
                                           value={Constant.chart.aggregate.COUNT}

                                    />
                                    {LLMsg("COMMON.CHART.CHART_HISTORY_AGGREGATE_COUNT")}
                                    <span/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={styles.searchHistoryButtonWrapper}>
                            <button type="button"
                                    onClick={this.searchHistoryData}
                                    className={"btn btn-outline-info active " + styles.searchHistoryButton}>
                                {LLMsg("COMMON.ACTION.OK")}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>

    }
}

export default ChartHistoryDataFragment;