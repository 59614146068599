// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import {saveAs} from 'file-saver';


export default class MaintenanceService {

    static list(): Object {
        return HttpUtil.ajax(Api.urls.maintenancePeriod.getList(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static getNext(): Object {
        return HttpUtil.ajax(Api.urls.maintenancePeriod.getNext(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static delete(maintenancePeriodId: string): Object {
        return HttpUtil.ajax(Api.urls.maintenancePeriod.delete(maintenancePeriodId), "POST", {
            'Content-Type': 'application/json',
        });
    }

    static update(maintenancePeriodId: string, startFrom: string, endUntil: string, showSince: string): Object {
        return HttpUtil.ajax(Api.urls.maintenancePeriod.update(), "POST", {
            'Content-Type': 'application/json',
        }, {maintenancePeriodId, startFrom, endUntil, showSince});
    }

    static create(startFrom: string, endUntil: string, showSince: string): Object {
        return HttpUtil.ajax(Api.urls.maintenancePeriod.create(), "POST", {
            'Content-Type': 'application/json',
        }, {startFrom, endUntil, showSince});

    }


}