exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1dKze89wD4y8ru6_bKKaNn {\n}\n\n._1kkXG_564-t-Ntrvyb1Vxi {\n    margin-bottom: 15px;\n    font-size: 24px;\n\n}\n\n._1n9bl6d5OjPwBd9wpgMgAM {\n    margin-left: 20px;\n}\n\n._3NIbzGgmPaQ0zfp1el8n4H {\n    width: 50px;\n    margin-right: 20px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_1dKze89wD4y8ru6_bKKaNn",
	"header": "_1kkXG_564-t-Ntrvyb1Vxi",
	"content": "_1n9bl6d5OjPwBd9wpgMgAM",
	"headerTitleImage": "_3NIbzGgmPaQ0zfp1el8n4H"
};