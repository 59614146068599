exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1cFBMNQw2z362ehzAzx8hl {\n    font-size: 1.5em;\n    text-align: center;\n    background-color: whitesmoke;\n    border: 2px solid whitesmoke;\n    padding: 10px 0 10px 0;\n    cursor: pointer;\n}\n\n._1cFBMNQw2z362ehzAzx8hl:hover {\n    background-color: #fbfbfb;\n}", ""]);

// exports
exports.locals = {
	"edit": "_1cFBMNQw2z362ehzAzx8hl"
};