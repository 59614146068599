// @flow

import React, {Component} from 'react';
import LicenseTargetTableContentItem from "./LicenseTargetTableContentItem";
import type {LicenseTarget} from "../../../../model/model";


type LicenseTargetTableContentWrapperProps = {
    licenseTargets: Array<LicenseTarget>
}

class LicenseTargetTableContentWrapper extends Component <LicenseTargetTableContentWrapperProps, any> {

    render() {
        const licenseTargets = this.props.licenseTargets;
        return <tbody className="m-datatable__body">
        {
            licenseTargets && licenseTargets.map((licenseTarget) => {
                return <LicenseTargetTableContentItem
                    key={licenseTarget.id}
                    licenseTarget={licenseTarget}
                />;
            })
        }
        </tbody>;

    }
}

export default LicenseTargetTableContentWrapper;