// @flow
import React, {Component} from 'react';
import {Route, Router, Switch} from "react-router";
import LoginPage from "./component/page/user/LoginPage";
import createBrowserHistory from 'history/createBrowserHistory'
import HomePage from "./component/page/HomePage";
import ProjectPage from "./component/page/project/ProjectPage";
import TopicPage from "./component/page/topic/TopicPage";
import TopicDetailPage from "./component/page/topic/TopicDetailPage";
import Error404Page from "./component/page/Error404Page";
import AdminUserListPage from "./component/page/admin/AdminUserListPage";
import ChartPage from "./component/page/ChartPage";
import ChartCreatePage from "./component/page/ChartCreatePage";
import ProtectedRoute from "./ProtectedRoute";
import DashboardPage from "./component/page/DashboardPage";
import Page from "./bundle/Page";
import DashboardDetailPage from "./component/page/DashboardDetailPage";
import ProjectDetailPage from "./component/page/project/ProjectDetailPage";
import ProjectCreatePage from "./component/page/project/ProjectCreatePage";
import TopicCreatePage from "./component/page/topic/TopicCreatePage";
import LogoutPage from "./component/page/user/LogoutPage";
import JoinProjectPage from "./component/page/project/JoinProjectPage";
import RegisterAsGuestPage from "./component/page/user/registration/RegisterAsGuestPage";
import DirectLoginAccessPage from "./component/page/DirectLoginAccessPage";
import SchoolRegisterPage from "./component/page/user/registration/SchoolRegisterPage";
import AdminSchoolPage from "./component/page/admin/AdminSchoolPage";
import Constant from "./bundle/Constant";
import UserDao from "./dao/UserDao";
import AdminSchoolDetailPage from "./component/page/admin/AdminSchoolDetailPage";
import AdminLicenseManagementPage from "./component/page/admin/AdminLicenseManagementPage";
import AdminCreateLicenseTargetPage from "./component/page/admin/AdminCreateLicenseTargetPage";
import AdminLicenseTargetUpdatePage from "./component/page/admin/AdminLicenseTargetUpdatePage";
import AdminCreateLicensePage from "./component/page/admin/AdminCreateLicensePage";
import AdminLicenseBatchDetailPage from "./component/page/admin/AdminLicenseBatchDetailPage";
import MyLicensePage from "./component/page/license/MyLicensePage";
import RegistrationSelectionPage from "./component/page/user/registration/RegistrationSelectionPage";
import RegisterWithLicensePage from "./component/page/user/registration/RegisterWithLicensePage";
import SetupPasswordPage from "./component/page/user/SetupPasswordPage";
import ChangePasswordPage from "./component/page/user/ChangePasswordPage";
import ResetPasswordPage from "./component/page/user/ResetPasswordPage";
import LicenseDetailPage from "./component/page/license/LicenseDetailPage";
import ResourcePage from "./component/page/resource/ResourcePage";
import AdminEventCodeDetailPage from "./component/page/admin/AdminEventCodeDetailPage";
import AdminUserDetailPage from "./component/page/admin/AdminUserDetailPage";
import AdminEventCodeListPage from "./component/page/admin/AdminEventCodeListPage";
import AdminLicenseTargetDetailPage from "./component/page/admin/AdminLicenseTargetDetailPage";
import AdminEventCodeCreatePage from "./component/page/admin/AdminEventCodeCreatePage";
import AdminWhitelistListPage from "./component/page/admin/AdminWhitelistListPage";
import AdminMaintenancePeriodPage from "./component/page/admin/AdminMaintenancePeriodPage";
import Api from "./bundle/Api";
import ServiceUnavailablePage from "./component/page/ServiceUnavailablePage";
import ChartEmbeddedPage from "./component/page/ChartEmbeddedPage";
import VirtualLightBulbPage from "./component/page/VirtualLightBulbPage";
import ProjectMapEmbeddedPage from "./component/page/project/ProjectMapEmbeddedPage";
import PlatformUpdateLogPage from "./component/page/PlatformUpdateLogPage";
import WeatherOpenApiPage from "./component/page/weatherApi/WeatherOpenApiPage";

var browserHistory = createBrowserHistory();

export const RouterHistory = () => {
    return browserHistory;
};
type AppRouteState = {
    isBackendUp: boolean
};

class AppRoute extends Component<any, AppRouteState> {

    componentWillMount() {
        UserDao.fetchMe();
        Api.services.backendStatusService.getStatus().then((response) => {
            if (response.status !== 200) {
                RouterHistory().push(Page.internals.urls.maintenancePeriod.underMaintenance());
            }
        }).catch(() => {
            RouterHistory().push(Page.internals.urls.maintenancePeriod.underMaintenance());
        });
    }

    render() {
        return <Router history={browserHistory}>
            <Switch>


                <ProtectedRoute exact path={Page.internals.paths.HOME_URL} component={HomePage}
                                role={Constant.authority.student}/>

                {/*Projects*/}
                <ProtectedRoute exact path={Page.internals.paths.PROJECT_DETAIL_URL}
                                component={ProjectDetailPage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.PROJECT_TRIGGER_URL}
                                component={ProjectDetailPage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.PROJECT_TRIGGER_DETAIL_URL}
                                component={ProjectDetailPage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.PROJECT_CREATE_URL}
                                component={ProjectCreatePage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.PROJECT_JOIN_BY_TOKEN_URL}
                                component={JoinProjectPage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.PROJECT_URL} component={ProjectPage}
                                role={Constant.authority.student}/>
                <Route exact path={Page.internals.paths.PROJECT_MAP_EMBEDDED_URL} component={ProjectMapEmbeddedPage}/>

                {/*Topics*/}
                <ProtectedRoute exact path={Page.internals.paths.TOPIC_DETAIL_URL}
                                component={TopicDetailPage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.TOPIC_CREATE_URL}
                                component={TopicCreatePage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.TOPIC_URL} component={TopicPage}
                                role={Constant.authority.student}/>

                {/*Charts*/}
                <ProtectedRoute exact path={Page.internals.paths.CHARTS_CREATE_URL}
                                component={ChartCreatePage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.CHARTS_DETAIL_URL} component={ChartPage}
                                role={Constant.authority.student}/>
                <Route exact path={Page.internals.paths.CHART_EMBEDDED_URL} component={ChartEmbeddedPage}/>

                {/*Dashboard*/}
                <ProtectedRoute exact path={Page.internals.paths.DASHBOARD_DEFAULT_URL}
                                component={DashboardPage}
                                role={Constant.authority.student}/>
                <ProtectedRoute exact path={Page.internals.paths.DASHBOARD_URL}
                                component={DashboardDetailPage}
                                role={Constant.authority.student}/>

                {/*License Page*/}
                <ProtectedRoute exact path={Page.internals.paths.LICENSE_MANAGEMENT_HOME_URL}
                                component={MyLicensePage}
                                role={Constant.authority.student}/>
                <Route exact path={Page.internals.paths.LICENSE_DETAIL_URL} component={LicenseDetailPage}/>
                <Route exact path={Page.internals.paths.LICENSE_DETAIL_QUERY_URL} component={LicenseDetailPage}/>

                {/*Resource Page*/}
                <Route exact path={Page.internals.paths.RESOURCE_URL} component={ResourcePage}/>
                <Route exact path={Page.internals.paths.OPEN_API_WEATHER} component={WeatherOpenApiPage}/>


                {/*Admin Page*/}
                <ProtectedRoute exact path={Page.internals.paths.SCHOOL_URL} component={AdminSchoolPage}
                                role={Constant.authority.makerCloudAdmin}/>
                <ProtectedRoute exact path={Page.internals.paths.SCHOOL_DETAIL_URL}
                                component={AdminSchoolDetailPage}
                                role={Constant.authority.makerCloudAdmin}/>
                <ProtectedRoute exact path={Page.internals.paths.ADMIN_LICENSE_MANAGEMENT_HOME_URL}
                                component={AdminLicenseManagementPage}
                                role={Constant.authority.makerCloudAdmin}/>
                <ProtectedRoute exact path={Page.internals.paths.ADMIN_LICENSE_TARGET_CREATE_URL}
                                component={AdminCreateLicenseTargetPage}
                                role={Constant.authority.makerCloudAdmin}/>
                <ProtectedRoute exact path={Page.internals.paths.ADMIN_LICENSE_TARGET_UPDATE_URL}
                                component={AdminLicenseTargetUpdatePage}
                                role={Constant.authority.makerCloudAdmin}/>
                <ProtectedRoute exact path={Page.internals.paths.ADMIN_LICENSE_TARGET_DETAIL_URL}
                                component={AdminLicenseTargetDetailPage}
                                role={Constant.authority.makerCloudAdmin}/>
                <ProtectedRoute exact path={Page.internals.paths.ADMIN_LICENSE_BATCH_CREATE_URL}
                                component={AdminCreateLicensePage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ADMIN_LICENSE_BATCH_DETAIL_URL}
                                component={AdminLicenseBatchDetailPage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ADMIN_EVENT_CODE_LIST_URL}
                                component={AdminEventCodeListPage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ADMIN_EVENT_CODE_DETAIL_URL}
                                component={AdminEventCodeDetailPage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ADMIN_EVENT_CODE_CREATE_URL}
                                component={AdminEventCodeCreatePage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ADMIN_USER_LIST_URL}
                                component={AdminUserListPage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ADMIN_USER_DETAIL_URL}
                                component={AdminUserDetailPage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ADMIN_WHITELIST_USER_LIST_URL}
                                component={AdminWhitelistListPage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ADMIN_MAINTENANCE_PERIOD_LIST_URL}
                                component={AdminMaintenancePeriodPage}
                                role={Constant.authority.makerCloudAdmin}/>

                <ProtectedRoute exact path={Page.internals.paths.ROOT_URL} component={HomePage}
                                role={Constant.authority.student}/>

                {/*Virtual Smart Home*/}
                <ProtectedRoute exact path={Page.internals.paths.VIRTUAL_SMART_HOME_LIGHT_BULB_URL}
                                component={VirtualLightBulbPage}
                                role={Constant.authority.student}/>

                <Route exact path={Page.internals.paths.LOGIN_URL} component={LoginPage}/>

                <Route exact path={Page.internals.paths.REGISTER_URL}
                       component={RegistrationSelectionPage}/>
                <Route exact path={Page.internals.paths.GUEST_REGISTER_URL}
                       component={RegisterAsGuestPage}/>
                <Route exact path={Page.internals.paths.SETUP_PASSWORD_FOR_NEW_ACCOUNT_URL}
                       component={SetupPasswordPage}/>
                <Route exact path={Page.internals.paths.RESET_PASSWORD_URL} component={ResetPasswordPage}/>
                <Route exact path={Page.internals.paths.CHANGE_ACCOUNT_PASSWORD_URL}
                       component={ChangePasswordPage}/>
                <Route exact path={Page.internals.paths.LICENSED_USER_REGISTER_URL}
                       component={RegisterWithLicensePage}/>
                <Route exact path={Page.internals.paths.SCHOOL_REGISTER_URL}
                       component={SchoolRegisterPage}/>

                <Route exact path={Page.internals.paths.DIRECT_LOGIN_ACCESS_URL}
                       component={DirectLoginAccessPage}/>
                <Route exact path={Page.internals.paths.LOGOUT_URL} component={LogoutPage}/>

                <Route exact path={Page.internals.paths.STATUS_DOWN_URL} component={ServiceUnavailablePage}/>
                <Route exact path={Page.internals.paths.WHATS_NEW_URL} component={PlatformUpdateLogPage}/>

                <Route exact path={"*"} component={Error404Page}/>


            </Switch>
        </Router>;
    }
}

export default AppRoute;