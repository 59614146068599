// @flow

import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";

export default class DashboardApiUrlHelper {

    static get = {
        list: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DASHBOARD_LISTING_API, []);
        }
    };

    static create = (): string => {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DASHBOARD_CREATE_API, []);
    };

    static delete = (): string => {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DASHBOARD_DELETE_API, []);
    };

    static update = {
        name: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DASHBOARD_UPDATE_API, []);
        },
        addChart: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.DASHBOARD_ADD_CHART_API, []);
        }
    }

}