// @flow

import React from 'react';
import swal from "sweetalert2";
import Constant from "../../../bundle/Constant";
import Api from "../../../bundle/Api";
import connect from "react-redux/es/connect/connect";
import {createAction} from "../../../redux/reducer";
import {LLMsg} from "../../../IntlCapture";

type DeleteChartFragmentProps = {
    chartId: string,

    dispatchChartDeleted?: () => void
}

export const deleteChartPrompt = (chartId: string, callback: ?Function) => {

    swal({
        text: LLMsg("COMMON.CHART.DELETE_CHART"),
        showCancelButton: !0,
        type: 'warning',
        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
        cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
    }).then((e) => {
        if (e.dismiss) {
            return;
        }
        Api.services.chart.doDeleteChart(chartId).then(response => {
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.REMOVE_SUCCESS"), "", "success");
                callback && callback();
            } else {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            }
        }).catch(() => {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        });
    });
};

const DeleteChartDropdownButton = (props: DeleteChartFragmentProps) => {
    return <li className="m-nav__item ">
        <span className="m-nav__link cursor-pointer" onClick={() => {
            deleteChartPrompt(props.chartId, props.dispatchChartDeleted);
        }}>
            <i className={"m-nav__link-icon fa-2x " + Constant.theme.delete.icon}/>
            <span className="m-nav__link-text  pl-2 m--icon-font-size-lg1 ">
                {LLMsg("COMMON.CHART.DELETE_CHART")}
            </span>
        </span>
    </li>
};

const mapDispatchToProps = dispatch => ({
    dispatchChartDeleted: () => dispatch(createAction(Api.actions.chart.DELETED_CHART))
});

export default connect(null, mapDispatchToProps)(DeleteChartDropdownButton);
