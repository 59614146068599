// @flow

import React, {Component} from "react";
import type {Topic} from "../../../model/model";
import ProjectMapFragment from "../../chart/MapFragment/ProjectMapFragment";
import CopyMapEmbedUrlButtonFragment from "../MapEmbeddedFragment/CopyMapEmbedUrlButtonFragment";
import Page from "../../../bundle/Page";
import UserDao from "../../../dao/UserDao";


type ProjectDetailMapWrapperProps = {
    topics: Array<Topic>,
    projectId: number,
    embedMapToken: string
}

class ProjectDetailMapWrapper extends React.PureComponent<any, ProjectDetailMapWrapperProps> {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2"}>
            <div className="m-portlet mb-0">
                <div className="m-portlet__body">
                    {
                        UserDao.permission.allowEmbedProjectMap && <div className={"float-right"}>
                            <CopyMapEmbedUrlButtonFragment
                                text={Page.convertToFullPath(Page.internals.urls.project.getEmbeddedMapUrl(this.props.embedMapToken))}/>
                        </div>
                    }
                    <ProjectMapFragment topics={this.props.topics} projectId={this.props.projectId}/>
                </div>
            </div>
        </div>;
    }
}

export default ProjectDetailMapWrapper;