// @flow

import React from 'react';
import Page from "../../bundle/Page";


const Error404Page = () => {

    Page.updateTitle("PAGE.TITLE.PAGE_NOT_FOUND");
    return <div
        className="m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default">
        <div className="m-grid m-grid--hor m-grid--root m-page">
            <div className="m-grid__item m-grid__item--fluid m-grid  m-error-3"
                 style={{backgroundImage: 'url(/image/error404_bg.jpg)'}}>
                <div className="m-error_container">
            <span className="m-error_number">
              <h1>404</h1>
            </span>
                    <p className="m-error_title m--font-light">
                        How did you get here
                    </p>
                    <p className="m-error_subtitle">
                        Sorry we can't seem to find the page you're looking for.
                    </p>
                    <p className="m-error_description">
                        There may be amisspelling in the URL entered,<br/>
                        or the page you are looking for may no longer exist.
                    </p>
                </div>
            </div>
        </div>
    </div>;
};

export default Error404Page;