// @flow
import React, {Component} from 'react';
import styles from './index.css';
import {ClassNames} from "../../../../../bundle/ClassNames";
import Popup from "reactjs-popup";
import {LLMsg} from "../../../../../IntlCapture";
import type {DataRecordType, Topic} from "../../../../../model/model";

type Props = {
    className: string,
    placeholder: string,
    onChange: (newValue: string)=>void,
    value: string,
    showError?: boolean,
    topics: Array<Topic>,
    index: number,
}

type State = {
    isPlainTextMode: boolean,
    selectedTopicName: string,
    selectedDataTypeKey: string
}

export default class MessageInputWrapper extends Component<Props, State> {
    constructor(props) {
        super(props);

        let extractVariable = this.extractVariable(props.value);
        this.state = {
            isPlainTextMode: !this.isVariable(props.value),
            selectedTopicName: extractVariable ? extractVariable.topicName : "",
            selectedDataTypeKey: extractVariable ? extractVariable.dataTypeKeyName : ""
        }

    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        if (this.props.value !== prevProps.value) {
            let extractVariable = this.extractVariable(this.props.value);
            this.setState({
                selectedTopicName: extractVariable ? extractVariable.topicName : "",
                selectedDataTypeKey: extractVariable ? extractVariable.dataTypeKeyName : ""
            });
        }
    }

    extractVariable(text: string): { topicName: string, dataTypeKeyName: string } {
        if (this.isVariable(text)) {
            let split = text.replace("{", "").replace("}", "").split(".");
            return {
                topicName: split[0],
                dataTypeKeyName: split[1]
            }
        }
        return null;
    }

    isVariable(text: string) {
        return text && text.startsWith("{") && text.endsWith("}") && text.indexOf(".") > 0;
    }

    handleDataTypeKeyChange = (e: any) => {
        let selectedDataTypeKey = e.target.value;
        this.setState({selectedDataTypeKey: selectedDataTypeKey});
        this.props.onChange("{" + this.state.selectedTopicName + "." + selectedDataTypeKey + "}");
    };

    handleTopicNameChange = (e: any) => {
        let topicName = e.target.value;
        this.setState({selectedTopicName: topicName});
        if (this.state.selectedDataTypeKey) {
            this.props.onChange("{" + topicName + "." + this.state.selectedDataTypeKey + "}");
        }
    };


    handlePlainTextChange = (e: any) => {
        let plainTextMessage = e.target.value;
        this.props.onChange(plainTextMessage);
    };

    renderForm(close: Function) {
        let subjectKeyOptions = [];
        this.props.topics && this.props.topics.forEach((topic) => {
            if (topic.topicName === this.state.selectedTopicName) {
                subjectKeyOptions = topic.dataRecordTypes.map((dataRecordType: DataRecordType, index) => {
                    return <option key={index} value={dataRecordType.keyName}>
                        {dataRecordType.dataRecordTypeName + " (" + dataRecordType.keyName + ")"}
                    </option>;
                });
            }
        });
        return <div className={styles.popupWrapper}>
            <div className={styles.tabsWrapper}>
                <div className={styles.tabInfo}>
                    {LLMsg("COMMON.CHART.MESSAGE_TYPE")}
                </div>
                <div
                    className={ClassNames(styles.tab, {[styles.active]: this.state.isPlainTextMode})}
                    onClick={() => {
                        this.setState({isPlainTextMode: true})
                    }}
                >
                    {LLMsg("COMMON.CHART.SIMPLE_MESSAGE")}
                </div>
                <div
                    onClick={() => {
                        this.setState({isPlainTextMode: false})
                    }}
                    className={ClassNames(styles.tab, {[styles.active]: !this.state.isPlainTextMode})}>
                    {LLMsg("COMMON.CHART.KEY_VALUE_PAIR")}
                </div>
            </div>
            <div className={styles.popupContent}>
                {
                    this.state.isPlainTextMode && <div className={styles.popupContentInner}>
                        <input
                            className={ClassNames(styles.popupContentInput, "form-control m-input", {[styles.error]: this.props.showError})}
                            type="text"
                            autoFocus={true}
                            placeholder={this.props.placeholder}
                            onChange={this.handlePlainTextChange}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    close();
                                }
                            }}
                            value={this.isVariable(this.props.value) ? "" : this.props.value}/>
                    </div>
                }
                {
                    !this.state.isPlainTextMode && <div className={styles.popupContentInner}>

                        {LLMsg("COMMON.TOPIC.TOPIC")}
                        <select
                            onChange={this.handleTopicNameChange}
                            className={styles.select + " form-control form-inline m-input m-input--air"}
                            value={this.state.selectedTopicName}
                        >
                            <option value={""}>
                                {LLMsg("EVENT_TRIGGER.TOPIC_NAME")}
                            </option>
                            {
                                this.props.topics && this.props.topics.map((topicDropdownValue, index) => {
                                    return <option key={index}
                                                   value={topicDropdownValue.topicName}>
                                        {topicDropdownValue.topicName + " (" + topicDropdownValue.description + ")"}
                                    </option>;
                                })
                            }
                        </select>
                        {LLMsg("EVENT_TRIGGER.S")}
                        <select
                            className={ClassNames(" form-control form-inline m-input m-input--air", styles.select)}
                            value={this.state.selectedDataTypeKey}
                            onChange={(e) => {
                                this.handleDataTypeKeyChange(e);
                                close();
                            }}>
                            <option value={""}>
                                {LLMsg("EVENT_TRIGGER.SUBJECT_KEY")}
                            </option>
                            {subjectKeyOptions}
                        </select>
                    </div>
                }
            </div>
        </div>
    }

    renderInputValue(): string {
        if (this.isVariable(this.props.value)) {
            let extractedVariable = this.extractVariable(this.props.value);
            return LLMsg("COMMON.TOPIC.TOPIC") + " " + extractedVariable.topicName + " " + LLMsg("EVENT_TRIGGER.S") + " " + extractedVariable.dataTypeKeyName
        }

        return this.props.value;
    }

    render() {
        return <Popup
            trigger={
                <div className={styles.wrapper}>
                    <input
                        className={ClassNames(styles.input, "form-control m-input", {[styles.error]: this.props.showError})}
                        type="text"
                        placeholder={this.props.placeholder}
                        readOnly={true}
                        value={this.renderInputValue()}/>

                    <div className={styles.settingIcon}>
                        <i className={"fa fa-cog fa-lg"}/>
                    </div>
                </div>
            }
            modal
            closeOnDocumentClick
        >
            {close => (
                this.renderForm(close)
            )}
        </Popup>
    }
}

