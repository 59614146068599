// @flow

import _ from "lodash";

export default class StringUtil {
    static replaceAllText(stringToBeReplaced: string, data: Array<{ key: string, value: string }>): string {
        let text = stringToBeReplaced || "";
        data && data.forEach(item => {
            text = _.replace(text, new RegExp(item.key, "g"), item.value || '')
        });
        return text;
    }

    static removeBlacklistChar(text: ?string): string {
        if (!text) {
            return '';
        }

        /*
        *
        * Blacklist Approach
        * The Following Char will be replaced
        * spaces and
        * - * & \ ! / @ # $ ^ } { [ ] { } . , ~ ' " < > : ; ?
        */

        // blacklist approach
        return text.replace(/[\s-*&/\\!@#$%^)(\[\]{}.,~'"<>:;?]+/g, '-').replace(/-+$/g, '').replace(/^-+/g, '');


    }

    static base64Encode(str: string): string {
        return btoa(str || "");
    }

    static base64Decode(str: string): string {
        return atob(str || "");
    }

    static validateEmail(str: string): boolean {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(str).toLowerCase());
    }

    static isOnlySpaceOrEmpty(str: ? string): boolean {
        return (str || "").trim().replace(" ", "") === "";
    }

    static IsJsonString(str: ? string): boolean {
        try {
            let json = JSON.parse(str || "");
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

    static encodeURI(str: ?string): string {
        return encodeURIComponent(str || "");
    }

    static isNumber = (value: string | number): boolean => {
        return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
    }
}
