exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BDpYuyQ-PdYzKJWkA3Az8 {\n\n    margin: 10px 15px 5px 25px;\n    color: #585661;\n}\n\n.UWqcMcWt-TO3nacZmeyDE {\n\n    border-color: #f4516c;\n    color: #f4516c;\n    background-color: #F7F7F9;\n    font-weight: 400;\n}", ""]);

// exports
exports.locals = {
	"expiryNotice": "BDpYuyQ-PdYzKJWkA3Az8",
	"invalidGuestEmailPrompt": "UWqcMcWt-TO3nacZmeyDE"
};