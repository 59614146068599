// @flow

import React, {Component} from 'react';
import styles from './index.css';
import LoadingUtil from "../../../../../util/LoadingUtil";
import ErrorUtil from "../../../../../util/ErrorUtil";
import type {Event, LicenseTarget} from "../../../../../model/model";
import Api from "../../../../../bundle/Api";
import {Link} from "react-router-dom";
import Page from "../../../../../bundle/Page";

type AdminUserListFilterState = {
    licenseTargetId: ?string,
    eventCodeId: ?string,
    isWhitelistedFilter: boolean,
    licenseTargets: Array<LicenseTarget>,
    events: Array<Event>
}
type AdminUserListFilterProps = {
    filterByWhitelistUser: () => void,
    filterByLicenseTarget: (targetId: number) => void,
    filterByEventCode: (eventCodeId: number) => void
}

class AdminUserListFilter extends Component<AdminUserListFilterProps, AdminUserListFilterState> {


    constructor(props: any) {
        super(props);
        this.state = {
            licenseTargetId: "",
            eventCodeId: "",
            isWhitelistedFilter: false,
            licenseTargets: [],
            events: []
        };

        this.filterByWhitelistUser = this.filterByWhitelistUser.bind(this);
    }

    componentDidMount(props: any) {
        LoadingUtil.showFullScreenLoading();
        Api.services.license.getLicenseTargetList().then((response) => {
            if (response.status === 200) {
                this.setState({licenseTargets: response.data});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });

        Api.services.eventCode.list().then((response) => {
            if (response.status === 200) {
                this.setState({events: response.data});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
        this.filterByWhitelistUser();

    }

    filterByWhitelistUser = () => {
        this.props.filterByWhitelistUser();
        this.setState({
            licenseTargetId: "",
            eventCodeId: "",
            isWhitelistedFilter: true
        })
    };

    onEventCodeChanged = (e: any) => {
        let eventCodeId = e.target.value;

        if (eventCodeId) {
            this.props.filterByEventCode(eventCodeId);
            this.setState({
                licenseTargetId: "",
                isWhitelistedFilter: false
            })
        }
        this.setState({
            eventCodeId: eventCodeId
        });
    };
    onLicenseChanged = (e: any) => {
        let licenseTargetId = e.target.value;

        if (licenseTargetId) {
            this.props.filterByLicenseTarget(licenseTargetId);
            this.setState({
                eventCodeId: "",
                isWhitelistedFilter: false
            })
        }
        this.setState({
            licenseTargetId: licenseTargetId
        });
    };

    render() {
        return <div className="m-portlet m-portlet--creative m-portlet--bordered-semi">
            <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
						<span className="m-portlet__head-icon m--hide">
							<i className="flaticon-statistics"/>
						</span>
                        <h3 className="m-portlet__head-text">
                            Filter Criteria
                        </h3>
                        <h2 className="m-portlet__head-label m-portlet__head-label--success">
                            <span>Filters</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="m-portlet__body">
                <div className="row ml-2 mr-2 mb-4">
                    <div className={"col-4 " + styles.box}>
                        <div>
                            <label className="m-radio">
                                <input type="radio"
                                       name="filterBy"
                                       checked={this.state.isWhitelistedFilter}
                                       onChange={this.filterByWhitelistUser}
                                /> Whitelist Email
                                <span/>
                            </label>
                        </div>
                    </div>
                    <div className={"col-4 " + styles.box}>
                        <div className={"pl-3 pb-2"}>
                            License Batch
                        </div>

                        <select
                            onChange={this.onLicenseChanged}
                            value={this.state.licenseTargetId}
                            className={"form-control form-inline m-input m-input--air"}
                        >
                            <option value={""}>
                                {("Select a target")}
                            </option>

                            {
                                this.state.licenseTargets && this.state.licenseTargets.map((licenseTarget) => {
                                    return <option key={licenseTarget.id}
                                                   value={licenseTarget.id}>
                                        {licenseTarget.targetName + " OF " + licenseTarget.companyName}
                                    </option>;

                                })
                            }
                        </select>

                        {
                            this.state.licenseTargetId && <div className={"pl-3 pb-2 pt-2"}>
                                <Link to={Page.internals.urls.license.licenseTargetDetailPage(this.state.licenseTargetId)}>Check
                                    Detail</Link>
                            </div>
                        }
                    </div>
                    <div className={"col-4 " + styles.box}>

                        <div className={"pl-3 pb-2"}>
                            Event Code
                        </div>
                        <select
                            onChange={this.onEventCodeChanged}
                            value={this.state.eventCodeId}
                            className={"form-control form-inline m-input m-input--air"}
                        >
                            <option value={""}>
                                {("Select an event")}
                            </option>

                            {
                                this.state.events && this.state.events.map((event: Event) => {
                                    return <option key={event.id}
                                                   value={event.id}>
                                        {event.name + " (" + event.eventCode + ")"}
                                    </option>;
                                })
                            }
                        </select>

                        {
                            this.state.eventCodeId && <div className={"pl-3 pb-2 pt-2"}>
                                <Link to={Page.internals.urls.eventCode.admin.detail(this.state.eventCodeId)}>Check
                                    Detail</Link>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>


    }
}

export default AdminUserListFilter;
