// @flow

import React from 'react';
import styles from './index.css';
import LicenseListTableHeader from "./LicenseListTableHeader";
import LicenseListTableContent from "./LicenseListTableContent";
import Api from "../../../../bundle/Api";
import type {LicenseGenerationBatch} from "../../../../model/model";
import ErrorUtil from "../../../../util/ErrorUtil";
import LoadingUtil from "../../../../util/LoadingUtil";

type LicenseListFragmentState = {
    licenseList: Array<LicenseGenerationBatch>
}

class LicenseListFragment extends React.Component <any, LicenseListFragmentState> {

    state = {licenseList: []};

    componentDidMount() {
        this.getLicenseList();
    }

    getLicenseList() {
        LoadingUtil.showFullScreenLoading();
        Api.services.license.list().then((response) => {

            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                let licenseList: Array<LicenseGenerationBatch> = response.data;
                this.setState({licenseList});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    }


    render() {
        return <div>

            <hr/>
            <div className={styles.title}>License Generation Batch</div>
            <div className={styles.subTitle}>The license are generated in batch, expand to see more details</div>
            <div className={"mb-3 mt-3 p-3 " + styles.tableWrapper}>
                <div className="m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded"
                     id="mytable">
                    <table className="m-datatable__table"
                           style={{display: 'block', minHeight: '300px', overflowX: 'auto'}}>

                        <LicenseListTableHeader/>
                        <LicenseListTableContent
                            licenseList={this.state.licenseList}
                        />
                    </table>
                </div>
            </div>
        </div>;
    }
}

export default LicenseListFragment;