// @flow

import Server from "../config/Config";
import type {ChartMessage} from "../model/model";

export default class SocketIOService {

    static socket: any = null;

    static getSocket() {
        if (SocketIOService.socket === null) {
            SocketIOService.socket = window.io.connect(Server.SIO_MAKER_CLOUD, {
                transports: ['websocket', 'xhr-polling', 'jsonp-polling']
            });
            return SocketIOService.socket;
        } else {
            return SocketIOService.socket;
        }
    }

    static extractMqttRawDataMessage(str: string): {
        topicName: string, timeArrival: string, message: string
    } {
        return JSON.parse(str);
    }

    static extractMqttDataMessage(message: string): Array<{ key?: string, value: string }> {
        let contentList = [];

        let split: Array<string> = (message || "").split(",");
        for (let i = 0; i < split.length; i++) {
            let segment: string = split[i];
            if (segment.includes("=")) {
                let keyValuePair = segment.split("=");
                contentList.push({key: keyValuePair[0], value: keyValuePair[1]});
            } else {
                contentList.push({value: segment});
            }
        }
        return contentList;
    }

    static extractChartDataMessage(str: string): ChartMessage {
        return JSON.parse(str);
    }

    static generateRoomName(topicId: string, deviceId: string, dataTypeId: string): string {
        return "T" + topicId + "/D" + deviceId + "/DT" + dataTypeId;
    }

    static extractRoomName(roomName: string): { topicId: number, deviceId: number, dataTypeId: number } {

        let re = /T(\d+)\/D(\d+)\/DT(\d+)/i;
        let found: Array<string> = (roomName || "").match(re) || ["", "", "", "", ""];

        return {
            topicId: parseInt(found[1]),
            deviceId: parseInt(found[2]),
            dataTypeId: parseInt(found[3])
        }
    }

    static constructMakerCloudMessage(message: string): string {
        return "_dn=makercloud,_dsn=mc," + message;
    }
}