// @flow

import React, {Component} from 'react';
import type {ProjectUser} from "../../../../model/model";
import styles from './index.css';
import image from './image.svg';
import {LLMsg} from "../../../../IntlCapture";
import {ClassNames} from "../../../../bundle/ClassNames";
import Api from "../../../../bundle/Api";
import ShareProjectButton from "../../ShareProjectButton";
import LoadingUtil from "../../../../util/LoadingUtil";
import swal from "sweetalert2";
import Page from "../../../../bundle/Page";
import ErrorUtil from "../../../../util/ErrorUtil";
import ReactTooltip from 'react-tooltip';

type Props = {
    projectUserList: Array<ProjectUser>,
    projectId: number,
    projectName: string
}
type State = {}

export default class ProjectUserListFragment extends Component<Props, State> {

    renderEmptyRow(key: number) {
        return <tr key={key}>
            <th scope="row">{"-"}</th>
            <td>{"-"}</td>
            <td>{"-"}
            </td>
        </tr>;
    }

    removeUser(userId: number) {

        LoadingUtil.showFullScreenLoading();
        Api.services.project.share.removeUser(this.props.projectId, userId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.REMOVE_SUCCESS"), "", "success");
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });

    }

    render(): React$Node {

        let emptyRowCount = 5 - this.props.projectUserList.length;
        emptyRowCount = emptyRowCount < 0 ? 0 : emptyRowCount;

        let emptyRow = [];
        for (let i = 0; i < emptyRowCount; i++) {
            emptyRow.push(this.renderEmptyRow(this.props.projectUserList.length + i));
        }
        return <div className="m-portlet">
            <div className="m-portlet__body">
                <div className={styles.title}>
                    {LLMsg("COMMON.MENU.USER_LIST")}

                    <div data-tip={LLMsg("COMMON.PROJECT.EXPLAIN_USER_LIST")} className={styles.explainButton}><i className={"flaticon-info m--icon-font-size-lg3"}/></div>
                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                </div>
                <div className={ClassNames(styles.userBoxList)}>
                    <div className={styles.tableWrapper}>
                        <table
                            className={"table table-bordered table-hover m-table m-table--head-bg-brand"}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{LLMsg("COMMON.MENU.USER_LIST")}</th>
                                <th style={{width: "100px"}}>{" "}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.projectUserList.map((projectUser: ProjectUser, index: number) => {
                                    return <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{projectUser.username}</td>
                                        <td>
                                            <button className={"btn btn-secondary btn-sm mr-2"} onClick={() => {
                                                this.removeUser(projectUser.userId);
                                            }}>
                                                {LLMsg("COMMON.ACTION.DELETE")}
                                            </button>
                                        </td>
                                    </tr>
                                })
                            }
                            {emptyRow}
                            </tbody>
                        </table>

                        <div className={styles.shareToUserRow}>
                            <ShareProjectButton projectId={this.props.projectId} projectName={this.props.projectName}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}