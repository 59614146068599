exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1FTWeV04GWqLD-Is8z-5u_ {\n    padding: 10px 10px 15px 15px;\n    font-size: 1.4em;\n}\n\n._3s7a_q61b97hYq9_EmjiKE {\n    color: red;\n}\n\n._3s7a_q61b97hYq9_EmjiKE input {\n    border: 1px solid red;\n}\n._3s7a_q61b97hYq9_EmjiKE select {\n    border: 1px solid red;\n}\n", ""]);

// exports
exports.locals = {
	"title": "_1FTWeV04GWqLD-Is8z-5u_",
	"error": "_3s7a_q61b97hYq9_EmjiKE"
};