exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3DmOnOzfgU9rIEZZJdEi2C:hover {\n    border: solid 3px yellow !important;\n}\n\n._3DmOnOzfgU9rIEZZJdEi2C {\n    color: white;\n    display: block;\n    padding: 10px 25px 10px 20px;\n    cursor: pointer;\n    font-size: 16px;\n    border-radius: 10px;\n    margin-top: 10px;\n    position: relative;\n}\n\n._1HTvdHhh4UBW0EMQOPLh5Y {\n    position: absolute;\n    right: 15px;\n    color: white;\n    font-size: 16px;\n    height: 24px;\n    line-height: 24px;\n    vertical-align: middle;\n}\n\n\n._3DmOnOzfgU9rIEZZJdEi2C:hover ._3rqtvkBtFUBPEAw6Q02G7p {\n    display: block;\n}\n\n._3rqtvkBtFUBPEAw6Q02G7p {\n    display: none;\n    position: absolute;\n    right: -250px;\n    z-index: 2;\n    top: 0;\n    width: 250px;\n    border: solid 1px grey;\n    background-color: white;\n    padding: 20px;\n    cursor: initial;\n}\n\n._2iCK8ODCGWOG3aRtEgxLEX:hover {\n    border: solid 3px yellow !important;\n}\n\n._2iCK8ODCGWOG3aRtEgxLEX {\n    color: white;\n    display: block;\n    padding: 10px 25px 10px 20px;\n    cursor: pointer;\n    font-size: 16px;\n    border-radius: 10px;\n    margin-top: 10px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_3DmOnOzfgU9rIEZZJdEi2C",
	"dropdownArrow": "_1HTvdHhh4UBW0EMQOPLh5Y",
	"dropdownWrapper": "_3rqtvkBtFUBPEAw6Q02G7p",
	"dropdownItem": "_2iCK8ODCGWOG3aRtEgxLEX"
};