// @flow
import React from 'react';
import type {Match} from "../../App";
import swal from "sweetalert2";
import Api from "../../bundle/Api";
import _ from "lodash";
import Constant from "../../bundle/Constant";
import {createAction} from "../../redux/reducer";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../IntlCapture";

type AddProjectToTopicButtonProps = {
    topicId: string,

    dispatchProjectUpdated?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}

const AddProjectToTopicPrompt = (topicId: string, dispatchTopicUpdatedCallback: ?any) => {

    if (!topicId) {
        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        return;
    }

    Api.services.project.doGetListOfProject().then((response) => {
        if (response.status === 200) {
            let projects: ?Array<Object> = response.data.projects;

            let inputOptions: { [key: string]: string } = {};
            projects && projects.forEach(project => {
                inputOptions[project.id] = project.projectName;
            });
            swal({
                title: LLMsg("COMMON.TOPIC.ADD_PROJECT"),
                type: 'warning',
                input: 'select',
                inputOptions: inputOptions,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
                cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
            }).then((e) => {
                if (e.dismiss) {
                    return;
                }
                if (e.value) {
                    Api.services.project.update.linkTopicToProject(e.value, topicId).then(response => {
                        if (response.status === 200) {
                            swal(LLMsg("COMMON.ACTION.ADD_SUCCESS"), "", "success");
                            dispatchTopicUpdatedCallback && dispatchTopicUpdatedCallback();
                        } else {
                            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                        }
                    }).catch(() => {
                        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                    });
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            });
        }
    }).catch(() => {

    });

};

const AddProjectToTopicButton = (props: AddProjectToTopicButtonProps) => {
    let topicId = _.get(props, "topicId", "");
    return <button className="btn btn-outline-accent m-btn m-btn--icon m-btn--wide" onClick={() => {
        AddProjectToTopicPrompt(topicId, props.dispatchProjectUpdated);
    }}>
         <span>
            <i className={Constant.theme.add.icon}/>
            <span>
                {LLMsg("COMMON.ACTION.ADD")}
            </span>
        </span>
    </button>

};

const mapDispatchToProps = dispatch => ({
    dispatchProjectUpdated: () => dispatch(createAction(Api.actions.topic.TOPIC_UPDATED))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(AddProjectToTopicButton));