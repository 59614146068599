exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1n9Gy4CAiV7-gmdEGEp7lD {\n    font-size: 80px;\n    padding-bottom: 20px;\n}", ""]);

// exports
exports.locals = {
	"value": "_1n9Gy4CAiV7-gmdEGEp7lD"
};