// @flow

import React, {Component} from 'react';
import ResourceSelectionFragment from "../../resource/ResourceSelectionFragment";
import HugeBannerFragment from "../../content/HugeBannerFragment";
import Page from "../../../bundle/Page";

class ResourcePage extends Component<any, any> {
    render() {
        Page.updateTitle("Resource");
        return <div className={"m-grid m-grid--hor m-grid--root m-page"}>
            <div
                className={"m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"}>
                <HugeBannerFragment/>
                <ResourceSelectionFragment/>
            </div>
        </div>
    }
}

export default ResourcePage;