exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1o-FpbQrR11SDFN-G7O4U3 {\n  text-align: center;\n}\n\n._3JCPttIlaO1n9gbarj-AYQ {\n  animation: RvI1Jd7nZvV5oabR4RbAY infinite 20s linear;\n  height: 80px;\n}\n\n._3iH8LjVH-8aINDFVrlCaZ1 {\n  background-color: #222;\n  height: 150px;\n  padding: 20px;\n  color: white;\n}\n\n.EIoWqrQKD2eTf7hUlljY9 {\n  font-size: 1.5em;\n}\n\n._1OgPcCismP_tc5RWUvuMBa {\n  font-size: large;\n}\n\n@keyframes RvI1Jd7nZvV5oabR4RbAY {\n  from { transform: rotate(0deg); }\n  to { transform: rotate(360deg); }\n}\n", ""]);

// exports
exports.locals = {
	"App": "_1o-FpbQrR11SDFN-G7O4U3",
	"App-logo": "_3JCPttIlaO1n9gbarj-AYQ",
	"App-logo-spin": "RvI1Jd7nZvV5oabR4RbAY",
	"App-header": "_3iH8LjVH-8aINDFVrlCaZ1",
	"App-title": "EIoWqrQKD2eTf7hUlljY9",
	"App-intro": "_1OgPcCismP_tc5RWUvuMBa"
};