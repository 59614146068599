// @flow
import React from 'react';
import {LLMsg} from "../../../../../IntlCapture";
import Constant from "../../../../../bundle/Constant";
import ChartTypeItem from "../../ChartTypeSelectFragment/ChartTypeItem";
import * as _ from 'lodash';
import styles from './index.css';
import ChartSourceSelectFragment from "../../ChartSourceSelectFragment/index";

type GaugeConfigFragmentProps = {}
type GaugeConfigFragmentState = {
    chartNameError: boolean,
    displayTextError: boolean,
    minValueError: boolean,
    maxValueError: boolean,
    chartSourceError: boolean
}

export default class GaugeConfigFragment extends React.Component <GaugeConfigFragmentProps, GaugeConfigFragmentState> {
    constructor(props: GaugeConfigFragmentProps) {
        super(props);
        this.state = {
            chartNameError: false,
            displayTextError: false,
            minValueError: false,
            maxValueError: false,
            chartSourceError: false
        };
        this.getConfigs = this.getConfigs.bind(this);
        this.validate = this.validate.bind(this);

    }

    chartNameRef: any;
    minValueRef: any;
    maxValueRef: any;
    displayTextRef: any;
    chartSourceRef: any = React.createRef();

    validate = () => {
        let isValid = true;
        let error = {
            chartNameError: false,
            displayTextError: false,
            minValueError: false,
            maxValueError: false,
            chartSourceError: false
        };

        if (!_.get(this.chartNameRef, "value", undefined)) {
            isValid = false;
            error.chartNameError = true;
        }

        if (!_.get(this.displayTextRef, "value", undefined)) {
            isValid = false;
            error.displayTextError = true;
        }

        if (!_.get(this.minValueRef, "value", undefined)) {
            isValid = false;
            error.minValueError = true;
        }

        if (!_.get(this.maxValueRef, "value", undefined)) {
            isValid = false;
            error.maxValueError = true;
        }

        let configs = this.chartSourceRef.current ? this.chartSourceRef.current.getConfigs() : [];

        configs.map((config) => {
            if (!config.topicId) {
                isValid = false;
                error.chartSourceError = true;
            }
        });

        this.setState(error);
        return isValid;
    };

    getConfigs = () => {
        return {
            "CHART_NAME": _.get(this.chartNameRef, "value", undefined),
            "TEXT": _.get(this.displayTextRef, "value", undefined),
            "MIN_VALUE": _.get(this.minValueRef, "value", undefined),
            "MAX_VALUE": _.get(this.maxValueRef, "value", undefined),
            "CHART_SOURCE": this.chartSourceRef.current && this.chartSourceRef.current.getConfigs()

        }
    };

    render() {

        return <div>
            <h3>
                <strong>{LLMsg("COMMON.STEP.STEP_2")} - </strong>
                <span>{LLMsg("COMMON.CHART.CHART_SETTING")}</span>
            </h3>
            <hr/>
            <div>

                <div className="row">
                    <div className={"col-6"}>
                        <div
                            className={"form-group m-form__group row " + (this.state.chartNameError ? styles.error : "")}>
                            <div className={"col-12 " + styles.title}>
                                {LLMsg("COMMON.CHART.CHART_NAME")}
                            </div>
                            <div className="col-12">
                                <input className="form-control m-input" type="text" ref={(ref) => {
                                    this.chartNameRef = ref
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-6"}>
                    </div>
                    <div className={"col-6"}>
                        <div className="form-group m-form__group row">
                            <div className={styles.title + " col-12"}>
                                {LLMsg("COMMON.CHART.SELECTED_CHART_TYPE")}
                            </div>
                            <div className="col-12">
                                <ChartTypeItem chartType={Constant.chart.type.gauge}
                                               defaultChecked={true}
                                               inputName={"chart_config_radio"}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"form-group m-form__group row " + (this.state.chartSourceError ? styles.error : "")}>

                    <div className={"col-12 " + styles.title}>
                        {LLMsg("COMMON.CHART.SELECTED_CHART_SOURCE")}
                    </div>
                    <div className="col-12">
                        <ChartSourceSelectFragment
                            isSingleSource={true}
                            ref={this.chartSourceRef}
                            showSimpleMessageOption={false}
                            showDeviceOption={true}
                            showColorOption={false}
                            showDescriptionOption={false}
                            showLocationOption={false}/>
                    </div>
                </div>
                <div className={"form-group m-form__group row " + (this.state.displayTextError ? styles.error : "")}>

                    <label htmlFor="example-text-input"
                           className="col-2 col-form-label">
                        {LLMsg("COMMON.CHART.BUTTON_DISPLAY_TEXT")}
                    </label>
                    <div className="col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                        <input className="form-control m-input" type="text" ref={(ref) => {
                            this.displayTextRef = ref
                        }}/>
                    </div>
                </div>
                <div className={"form-group m-form__group row " + (this.state.minValueError ? styles.error : "")}>

                    <label htmlFor="example-text-input"
                           className="col-2 col-form-label">
                        {LLMsg("COMMON.CHART.CHART_MIN_VALUE")}
                    </label>
                    <div className="col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                        <input className="form-control m-input" type="text" ref={(ref) => {
                            this.minValueRef = ref
                        }}/>
                    </div>
                </div>
                <div className={"form-group m-form__group row " + (this.state.maxValueError ? styles.error : "")}>

                    <label htmlFor="example-text-input"
                           className="col-2 col-form-label">
                        {LLMsg("COMMON.CHART.CHART_MAX_VALUE")}
                    </label>
                    <div className="col-10 col-sm-10 col-md-4 col-lg-4 col-xl-4">
                        <input className="form-control m-input" type="text" ref={(ref) => {
                            this.maxValueRef = ref
                        }}/>
                    </div>
                </div>

            </div>
        </div>;
    }
}
