exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1q-CII6q3aMwWOunW5IpuE {\n    margin: 10px;\n}\n\n._1q-CII6q3aMwWOunW5IpuE._1d2moMaftmFS5-2AREhlYg {\n    background-color: #f5f5f5;\n}\n\n._1d2moMaftmFS5-2AREhlYg label {\n    cursor: not-allowed;\n}\n\n._1d2moMaftmFS5-2AREhlYg {\n}\n", ""]);

// exports
exports.locals = {
	"item": "_1q-CII6q3aMwWOunW5IpuE",
	"disabled": "_1d2moMaftmFS5-2AREhlYg"
};