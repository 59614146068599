exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n._45hEigYlp2cEmMHQwcJZT {\n    font-size: 20px;\n    padding-left: 15px;\n    color: #585661;\n}\n\n._3CyUhqOb1iSMD7UyrXF8f {\n    font-weight: bold;\n    padding-left: 15px;\n    color: #8b8d97;\n    display: block;\n}\n\n\n.L9g9uoCm2RT584YE6qFsa{\n    margin-left: 15px;\n    margin-bottom: 10px;\n    margin-top: 15px;\n}\n\n._3aAvVVhSOWcGbxRzn9PKLD {\n\n}", ""]);

// exports
exports.locals = {
	"title": "_45hEigYlp2cEmMHQwcJZT",
	"subTitle": "_3CyUhqOb1iSMD7UyrXF8f",
	"titleWrapper": "L9g9uoCm2RT584YE6qFsa",
	"formWrapper": "_3aAvVVhSOWcGbxRzn9PKLD"
};