// @flow

import React, {Component} from 'react';
import Constant from "../../../bundle/Constant";
import Api from "../../../bundle/Api";
import LineChartFragment from "../LineChartFragment";
import GaugeChartFragment from "../GaugeChartFragment";
import _ from "lodash";
import Page from "../../../bundle/Page";
import PlainTextChartFragment from "../PlainTextChartFragment";
import type {Chart} from "../../../model/model";
import ToggleButtonChartFragment from "../ToggleButtonChartFragment";
import PushButtonChartFragment from "../PushButtonChartFragment";
import LoadingUtil from "../../../util/LoadingUtil";
import {withRouter} from "react-router";
import styles from './index.css'
import {LLMsg} from "../../../IntlCapture";
import PointMapChartFragment from "../PointMapChartFragment";

type ChartEmbeddedFragmentProps = {
    match?: Object,
    location?: Object,
    history?: Object
};

type ChartEmbeddedFragmentState = {
    chart: ?Chart
};

class ChartEmbeddedFragment extends Component<ChartEmbeddedFragmentProps, ChartEmbeddedFragmentState> {

    constructor(props: ChartEmbeddedFragmentProps) {
        super(props);
        this.state = {chart: null};

        const token = this.props.match && this.props.match.params["token"];
        console.log(token);
        this.getChartDetail(token || "");
    }

    componentDidUpdate() {

        if (document.body) {
            document.body.style.backgroundColor = "#ffffff";
        }
    }

    getChartDetail = (token: string) => {

        LoadingUtil.showFullScreenLoading();
        Api.services.chart.get.byToken(token).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({chart: response.data});
            }
        }).catch(() => {
            LoadingUtil.hideFullScreenLoading();
        });
    };

    render() {
        let chart: Chart = this.state.chart;
        if (!chart || !chart.id) {
            return <div/>;
        }
        let chartType = _.get(this.state, "chart.chartType.name", "");
        let title = chart.name;

        let chartConfig = chart.chartConfig || {};

        Page.updateTitle("PAGE.TITLE.CHART_DETAIL", [{
            key: "CHART_NAME",
            value: title
        }]);
        return <div style={{height: "100%"}}>
            {
                chartType === Constant.chart.type.basicLineChart &&
                <LineChartFragment title={""} chartId={chart.id}
                                   config={chartConfig}
                                   aggregate={""}
                                   endTime={""}
                                   interval={""}
                                   isLive={true}
                                   startTime={""}
                                   sizingClass={"portlet_height_full"}
                                   sources={chart.chartSources}/>
            }
            {
                chartType === Constant.chart.type.gauge &&
                <GaugeChartFragment title={""} chartId={chart.id}
                                    config={chartConfig}
                                    sources={chart.chartSources}
                                    sizingClass={""}/>
            }
            {
                chartType === Constant.chart.type.plainTextChart &&
                <PlainTextChartFragment title={chart.name}
                                        chartId={chart.id}
                                        config={chartConfig}
                                        sources={chart.chartSources}
                                        sizingClass={""}/>
            }
            {
                chartType === Constant.chart.type.toggleButton &&
                <ToggleButtonChartFragment title={""}
                                           chartId={chart.id}
                                           config={chartConfig}
                                           sources={chart.chartSources}
                                           sizingClass={"portlet_height_full"}/>
            }

            {
                chartType === Constant.chart.type.pushButton &&
                <PushButtonChartFragment title={""}
                                         chartId={chart.id}
                                         config={chartConfig}
                                         sources={chart.chartSources}
                                         sizingClass={"portlet_height_full"}/>
            }
            {
                chartType === Constant.chart.type.pointMap &&
                <div className={styles.mapWrapper}>
                    <PointMapChartFragment title={""}
                                           chartId={chart.id}
                                           config={chartConfig}
                                           sources={chart.chartSources}
                                           sizingClass={"portlet_height_full"}/>
                </div>
            }

            <span className="m-footer__copyright pull-right">
                <a href={Page.internals.urls.misc.home()} className={"m-link " + styles.watermark} target="_blank">
                    {LLMsg("COMMON.FOO.MAKER_CLOUD")}
                </a>
            </span>
        </div>;
    }
}


export default withRouter(ChartEmbeddedFragment);