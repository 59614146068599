exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".D6h7-W9KL67nMdj-CacUD {\n    cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"row": "D6h7-W9KL67nMdj-CacUD"
};