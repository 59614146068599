// @flow

import React, {Component} from 'react';
import Constant from "../../bundle/Constant";
import ClipboardJS from "clipboard";
import {LLMsg} from "../../IntlCapture";

type CopyTopicNameButtonFragmentProps = {
    text: string,
    color: string,
    className: string
}

class CopyTopicNameButtonFragment extends Component<CopyTopicNameButtonFragmentProps, any> {
    copyTopicNameButtonRef: any;

    constructor(props: CopyTopicNameButtonFragmentProps) {
        super(props);
        this.copyTopicNameButtonRef = React.createRef();
    }

    initClipboard() {

        let clipboard = new ClipboardJS(this.copyTopicNameButtonRef);

        clipboard.on('success', function (e) {
            window.toastr.options = Constant.toastr.defaultOptions;
            window.toastr.success(LLMsg("COMMON.ACTION.COPY_SUCCESS"));
        });
    }

    componentDidMount() {
        this.initClipboard();
    }

    render() {

        return <button type="button"
                       data-clipboard-text={this.props.text}
                       ref={(copyTopicNameButtonRef) => {
                           this.copyTopicNameButtonRef = copyTopicNameButtonRef;
                       }}
                       style={{color: this.props.color}}
                       className={this.props.className}>
            <i className="fa fa-copy"/> {LLMsg("COMMON.ACTION.COPY")}
        </button>;
    }
};
export default CopyTopicNameButtonFragment;