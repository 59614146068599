exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._8cxnMi7dIATvk0aqvXT4o {\n    font-size: 24px;\n}\n\n._3XrYpGb0WAapPIlx6X79aN {\n}\n\n.bHj9gBIVyo_VHiPJ-J-aK {\n    font-size: 20px;\n    display: block;\n}\n\n._2lv-PhBOTd97TqCDxI5AMh {\n    border: 1px solid red !important;\n}\n\n._37wJeqoJo72Lk66V72gETM {\n}\n\n._85gzyDQuU_Y8P240mI182 {\n    width: 400px;\n}\n\n._1M-I5LzOLBNsumQn58jyof {\n    z-index: auto !important;\n    opacity: 1 !important;\n    margin: -7px 15px 3px 15px;\n    width: 200px;\n    display: inline-block !important;\n}", ""]);

// exports
exports.locals = {
	"title": "_8cxnMi7dIATvk0aqvXT4o",
	"actionItemWrapper": "_3XrYpGb0WAapPIlx6X79aN",
	"actionItem": "bHj9gBIVyo_VHiPJ-J-aK",
	"errorInput": "_2lv-PhBOTd97TqCDxI5AMh",
	"actionTextInput": "_37wJeqoJo72Lk66V72gETM",
	"longerTextInput": "_85gzyDQuU_Y8P240mI182",
	"topicNameInput": "_1M-I5LzOLBNsumQn58jyof"
};