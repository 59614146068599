// @flow


class ApiUrl {

    static BACKEND_STATUS_CHECK_API = "/v1/public/status";
    static BACKEND_UPDATE_LOG_API = "/v1/public/update/log";
    static LEARNING_LINKS_API = "/v1/public/learning/links";

    static TOPIC_CREATE_API = "/v1/topic/create-topic";
    static TOPIC_CREATE_LIMIT_API = "/v1/topic/create/limit/create-topic-limit";
    static TOPIC_DETAIL_API = "/v1/topic/:topicId";
    static TOPIC_UPDATE_API = "/v1/topic/update-topic";
    static TOPIC_NEW_DATA_TYPE_API = "/v1/topic/datatype/new";
    static TOPIC_UPDATE_DATA_TYPE_API = "/v1/topic/datatype/update-data-type";
    static TOPIC_DELETE_DATA_TYPE_API = "/v1/topic/datatype/delete-data-type";
    static TOPIC_DELETE_API = "/v1/topic/delete";
    static TOPIC_LISTING_API = "/v1/topic/list";
    static TOPIC_SEND_NEW_MESSAGE_API = "/v1/public/topic/message/new";
    static TOPIC_SEND_NEW_MESSAGE_BY_TOPIC_NAME_API = "/v1/topic/message/new/topicName";
    static TOPIC_CHARTABLE_LISTING_API = "/v1/topic/list/chartable";
    static TOPIC_UPDATE_LOCATION_API = "/v1/topic/location/update-topic-location";
    static TOPIC_REMOVE_LOCATION_API = "/v1/topic/location/remove-topic-location";
    static TOPIC_ID_API = "/v1/public/topic/name/:topicName";

    static PROJECT_CREATE_API = "/v1/project/create";
    static PROJECT_CREATE_LIMIT_API = "/v1/project/create/limit/create-project-limit";
    static PROJECT_DETAIL_API = "/v1/project/:projectId";
    static PROJECT_UPDATE_API = "/v1/project/update";
    static PROJECT_DELETE_API = "/v1/project/create";
    static PROJECT_LISTING_API = "/v1/project/list";
    static PROJECT_DELETE_WITH_DATA_API = "/v1/project/:projectId/delete-project-with-data";
    static PROJECT_GET_SHARE_TOKEN_API = "/v1/project/share/token";
    static PROJECT_JOIN_BY_TOKEN_API = "/v1/project/share/join";
    static PROJECT_REMOVE_USER_API = "/v1/project/:projectId/share/remove-:userId";
    static PROJECT_CHECK_TOKEN_STATUS_API = "/v1/project/share/verify";
    static PROJECT_FILE_LIST_API = "/v1/project/:projectId/file/get-project-file-list";
    static PROJECT_FILE_UPLOAD_API = "/v1/project/:projectId/file/upload-project-file";
    static PROJECT_FILE_DOWNLOAD_API = "/v1/project/:projectId/file/:fileId/download/:filename";
    static PROJECT_FILE_DELETE_API = "/v1/project/:projectId/file/:fileId/delete-project-file";
    static PROJECT_COORDINATE_API = "/v1/public/project/embed-map-token/:embedMapToken";

    static EVENT_TRIGGER_CREATE_API = "/v1/applet/create";
    static EVENT_TRIGGER_UPDATE_API = "/v1/applet/update";
    static EVENT_TRIGGER_LISTING_API = "/v1/applet/list";
    static EVENT_TRIGGER_DETAIL_API = "/v1/applet/detail/:appletId";
    static EVENT_TRIGGER_DELETE_API = "/v1/applet/delete/:appletId";
    static EVENT_TRIGGER_TRIGGER_OBJECT_DETECTION_API = "/v1/trigger/:projectId/object-detection/:detectedObjects";
    static EVENT_TRIGGER_TRIGGER_FACE_DETECTION_API = "/v1/trigger/:projectId/face-detection";

    static LINK_NEW_TOPIC_TO_PROJECT = "/v1/project/topic/create-and-link";
    static LINK_EXISTING_TOPIC_TO_PROJECT = "/v1/project/topic/link";
    static UNLINK_TOPIC_FROM_PROJECT = "/v1/project/topic/unlink";

    static CHART_CREATE_API = "/v1/chart/create";
    static CHART_DELETE_API = "/v1/chart/delete";
    static CHART_UPDATE_API = "/v1/chart/update";
    static CHART_DETAIL_API = "/v1/chart/:chartId";
    static CHART_DETAIL_BY_TOKEN_API = "/v1/public/chart/token/:token";
    static CHART_LIST_RECENT_API = "/v1/chart/list/recent";
    static CHART_LIST_TOPIC_RELATED_API = "/v1/chart/list/topic/related";
    static CHART_LIST_PROJECT_RELATED_API = "/v1/chart/list/project/related";

    static DATA_RECORD_LIST_RECENT_API = "/v1/record/list/recent";
    static DATA_RECORD_LIST_ALL_API = "/v1/record/list/all";
    static DATA_RECORD_LIST_HISTORICAL_API = "/v1/record/list/historical";

    static DASHBOARD_CREATE_API = "/v1/dashboard/create";
    static DASHBOARD_LISTING_API = "/v1/dashboard/list";
    static DASHBOARD_DETAIL_API = "/v1/dashboard/:dashboardId";
    static DASHBOARD_UPDATE_API = "/v1/dashboard/update";
    static DASHBOARD_ADD_CHART_API = "/v1/dashboard/chart/add";
    static DASHBOARD_DELETE_API = "/v1/dashboard/delete";

    static DATA_RECORD_EXPORT_BY_PROJECT_API = "/v1/record/export/project_data.xlsx";

    static WHITELIST_EMAIL_API = "/v1/email/list/whitelist";
    static WHITELIST_EMAIL_DELETE_API = "/v1/email/list/whitelist-delete-:whitelistEmailId";
    static WHITELIST_EMAIL_UPDATE_API = "/v1/email/list/whitelist-update";
    static WHITELIST_EMAIL_CREATE_API = "/v1/email/list/whitelist-create";

    static USER_LISTING_BY_WHITE_LIST_API = "/v1/user/list/whitelisted-user";
    static USER_LISTING_BY_LICENSE_TARGET_API = "/v1/user/list/licensed-user";
    static USER_LISTING_BY_EVENT_API = "/v1/user/list/event-user";

    static USER_DETAIL_API = "/v1/user/user-detail-:userId";
    static USER_MYSELF_API = "/v1/user/me";
    static USER_CREATE_BY_GOOGLE_SIGN_UP_API = "/v1/user/register/register-with-google";
    static USER_SETUP_PASSWORD_API = "/v1/user/setup-password";
    static USER_CHANGE_PASSWORD_API = "/v1/user/change-password";
    static SEND_FORGET_PASSWORD_EMAIL_API = "/v1/public/user/send-forget-password-email";
    static USER_CREATE_API = "/v1/user/create";
    static USER_LOGIN_API = "/login";
    static USER_TOKEN_API = "/oauth/token";
    static USER_LOGOUT_API = "/logout";
    static USER_SUBSCRIPTION_API = "/v1/user/subscription/subscription-status";

    static LICENSE_CONSUME_API = "/v1/license/consume-license";
    static LICENSE_GET_API = "/v1/public/license/license-status";
    static LICENSE_FILE_DOWNLOAD_API = "/v1/public/license/download/:filename";

    static EVENT_CODE_CONSUME_API = "/v1/event-code/consume-event-code";
    static EVENT_CODE_LIST_API = "/v1/event-code/list-event-code";
    static EVENT_CODE_DETAIL_API = "/v1/event-code/event-code-detail-:eventCodeId";
    static EVENT_CODE_UPDATE_API = "/v1/event-code/event-code-update";
    static EVENT_CODE_CREATE_API = "/v1/event-code/event-code-create";

    static OPEN_API_WEATHER_OPTIONS = "/v1/public/weather/latest/options";
    static OPEN_API_LATEST_WEATHER = "/v1/public/weather/latest/:typeName/:stationName";

    static ADMIN_MAINTENANCE_LIST_API = "/v1/maintenance-period/maintenance-period-list";
    static ADMIN_MAINTENANCE_NEXT_API = "/v1/maintenance-period/next-maintenance-period";
    static ADMIN_MAINTENANCE_CREATE_API = "/v1/maintenance-period/maintenance-period-create";
    static ADMIN_MAINTENANCE_UPDATE_API = "/v1/maintenance-period/maintenance-period-update";
    static ADMIN_MAINTENANCE_DELETE_API = "/v1/maintenance-period/maintenance-period-delete-:maintenancePeriodId";
    static ADMIN_LICENSE_BATCH_CREATE_API = "/v1/admin/license/license-batch-create";
    static ADMIN_LICENSE_BATCH_DETAIL_API = "/v1/admin/license/license-batch-:licenseBatchId";
    static ADMIN_LICENSE_OVERALL_INFO_API = "/v1/admin/license/license-overall-info";
    static ADMIN_LICENSE_LISTING_API = "/v1/admin/license/license-list";
    static ADMIN_LICENSE_TARGET_LISTING_API = "/v1/admin/license/license-target-list";
    static ADMIN_LICENSE_TARGET_DETAIL_API = "/v1/admin/license/detail/license-target-:licenseTargetId";
    static ADMIN_LICENSE_TARGET_CREATE_API = "/v1/admin/license/license-target-create";
    static ADMIN_LICENSE_TARGET_UPDATE_API = "/v1/admin/license/license-target-update";
    static ADMIN_SCHOOL_LISTING_API = "/v1/admin/school/list";
    static ADMIN_SCHOOL_DETAIL_API = "/v1/admin/school/:schoolId";
    static ADMIN_SCHOOL_APPLICATION_APPROVE_API = "/v1/admin/school/application/approve/:schoolId";
    static ADMIN_SCHOOL_APPLICATION_REJECT_API = "/v1/admin/school/application/reject/:schoolId";

    static USER_GUEST_CREATE_API = "/v1/public/user/register/register-with-event-code";
    static USER_CREATE_WITH_LICENSE_API = "/v1/public/user/create/create-user-with-license";
    static SCHOOL_SIGNUP_API = "/v1/public/school/register";
}

export default ApiUrl;