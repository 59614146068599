exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3e-1rdWRrgVZA-h7mMixUm {\n    color: red;\n}\n\n._3e-1rdWRrgVZA-h7mMixUm input {\n    border: 1px solid red;\n}\n._3e-1rdWRrgVZA-h7mMixUm select {\n    border: 1px solid red;\n}\n\n._15IW7mKWSv4C5Lyh7zOVVM {\n    padding: 10px 10px 15px 15px;\n    font-size: 1.4em;\n}\n\n._2YYEHDPIkcSsyTuECFRe8S {\n    border-right: 1px solid #ebedf2;\n}\n\n._1wQ64weur7z-usktwgumrf {\n    padding: 10px 15px;\n}\n\n._1xFa76wy47feHjLDObPwlm {\n    display: inline-block;\n    font-size: 1.1em;\n    padding: 5px 15px 5px 5px;\n}\n\n._3Ax8uBbSRfTEQjzQpCcjZZ {\n    display: inline-block;\n    min-width: 200px;\n}\n\n._3Ph-atZUGQ-oSRlpYrORoo {\n    border: 2px solid #36A3FB !important;\n}", ""]);

// exports
exports.locals = {
	"error": "_3e-1rdWRrgVZA-h7mMixUm",
	"title": "_15IW7mKWSv4C5Lyh7zOVVM",
	"on_off_wrapper": "_2YYEHDPIkcSsyTuECFRe8S",
	"on_off_item": "_1wQ64weur7z-usktwgumrf",
	"on_off_item_title": "_1xFa76wy47feHjLDObPwlm",
	"on_off_item_value": "_3Ax8uBbSRfTEQjzQpCcjZZ",
	"selected_button_style": "_3Ph-atZUGQ-oSRlpYrORoo"
};