// @flow
import React from 'react';
import styles from './FilterFragment.css';
import {LLMsg} from "../../../IntlCapture";

type OrOperatorFragmentProps = {
    onChangeCallback: (conditionKey: number, operatorIndex: number) => void,
    conditionKey: number,
    operatorIndex: number
}


const OrOperatorFragment = (props: OrOperatorFragmentProps) => {
    return <span className={styles.orOperator} onClick={() => {
        props.onChangeCallback(props.conditionKey, props.operatorIndex);
    }}>
        {LLMsg("EVENT_TRIGGER.OR")}
    </span>;
};

export default OrOperatorFragment;