exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1BUI20hGSnMDYc8Yv0kfSc {\n    float: right;\n    margin-right: 10px;\n    margin-top: 10px;\n}", ""]);

// exports
exports.locals = {
	"buttonWrapper": "_1BUI20hGSnMDYc8Yv0kfSc"
};