// @flow

import PageUrl from "./PageUrl";

export default class VirtualSmartHomePageUrl {

    static getVirtualLightBulbUrl(): string {
        return PageUrl.VIRTUAL_SMART_HOME_LIGHT_BULB_URL;
    }

}