// @flow

import React from 'react';
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import SocketIOService from "../../../service/SocketIOService";
import Constant from "../../../bundle/Constant";
import type {ChartConfig, ChartSource} from "../../../model/model";
import styles from './index.css';
import {LLMsg} from "../../../IntlCapture";
import Api from "../../../bundle/Api";
import LoadingUtil from "../../../util/LoadingUtil";
import ToastrUtil from "../../../util/ToastrUtil";

type PushButtonChartFragmentState = {}
type PushButtonChartFragmentProps = {
    sources: Array<ChartSource>,
    chartId: string,
    title: string,
    config: ChartConfig,
    sizingClass?: string
}

class PushButtonChartFragment extends React.Component<PushButtonChartFragmentProps, PushButtonChartFragmentState> {

    constructor(props: any) {
        super(props);
        this.clickButton = this.clickButton.bind(this);
    }

    clickButton = () => {

        let source: ChartSource = this.props.sources[0];
        let topicId = source.topic.id;
        let onValue = this.props.config.onValue;
        let outputType = this.props.config.outputType;
        let message = SocketIOService.constructMakerCloudMessage(source.dataRecordType ? source.dataRecordType.keyName + "=" + onValue : onValue);
        LoadingUtil.showFullScreenLoading();
        Api.services.topic.doSendNewMessageToTopic(topicId, outputType, message).then((response) => {
            if (response.status === 200) {
                ToastrUtil.show(LLMsg(onValue), Constant.toastr.defaultOptions, ToastrUtil.success);
                LoadingUtil.hideFullScreenLoading();
            } else {
                LoadingUtil.hideFullScreenLoading();
                ToastrUtil.show(LLMsg("COMMON.ACTION.ACTION_FAILED"), Constant.toastr.noAutoHiding, ToastrUtil.error);
            }
        }).catch(() => {
            LoadingUtil.hideFullScreenLoading();
            ToastrUtil.show(LLMsg("COMMON.ACTION.ACTION_FAILED"), Constant.toastr.noAutoHiding, ToastrUtil.error);
        });

    };

    render() {


        let content;
        let style = {
            fontSize: '50px',
            color: this.props.config.color,
            borderColor: this.props.config.color,
            backgroundColor: "white",
            margin: '30px 30px 30px 10px',
            cursor: 'pointer'
        };
        if (this.props.config.onText) {

            content = <button style={style}
                              onClick={this.clickButton}
                              type="button"
                              className="btn m-btn btn-outline m-btn--custom">
                {this.props.config.onText}
            </button>
        } else {

            content =
                <i className={this.props.config.onStyle}
                   onClick={this.clickButton}
                   style={{fontSize: '80px', margin: '30px 30px 30px 10px', ...style}}/>
        }

        return <div className={this.props.sizingClass || "portlet_height_1x"}>
            <Link to={Page.internals.urls.chart.getChartDetailPageUrl(this.props.chartId, this.props.title)}
                  className={"mb-2 m--icon-font-size-lg5 no-a-effect"}>{this.props.title}</Link>
            <div className="m-stack m-stack--ver m-stack--general">
                <div
                    className={"m-stack__item m-stack__item--center m-stack__item--middle " + styles.value}
                    style={{color: this.props.config.color}}
                >
                    {content}
                </div>
            </div>
        </div>;

    }
}

export default PushButtonChartFragment;