// @flow


import React from 'react';
import styles from './index.css';
import {ClassNames} from "../../../../bundle/ClassNames";

type Props = {
    label: string,
    backgroundColor: string,
    onClick?: Function,
    subItems?: Array<{ label: string, onClick: Function }>
}
export default class BlockPanelItem extends React.Component <Props, any> {
    render() {
        return <div className={styles.wrapper}
                    style={{
                        backgroundColor: this.props.backgroundColor,
                        border: "solid 3px " + this.props.backgroundColor
                    }}
                    onClick={this.props.onClick}
        >
            {this.props.label}
            <i className={ClassNames(
                styles.dropdownArrow,
                this.props.subItems ? "fas fa-caret-right fa-lg" : "fa fa-plus",
            )}/>

            {
                this.props.subItems && <div className={styles.dropdownWrapper}>
                    {
                        this.props.subItems && this.props.subItems.map((subItem, index) => {
                            return this.renderDropdownItem(subItem.label, subItem.onClick, index);
                        })
                    }
                </div>
            }
        </div>;
    }


    renderDropdownItem(label: string, onClick: Function, index: number) {
        return <div className={styles.dropdownItem}
                    key={index}
                    style={{
                        backgroundColor: this.props.backgroundColor,
                        border: "solid 3px " + this.props.backgroundColor
                    }}
                    onClick={onClick}>
            {label}
        </div>
    }
}