// @flow

import React, {Component} from 'react';
import Page from "../../../bundle/Page";
import WeatherOpenApi from "../../WeatherOpenApi";
import HeaderFragment from "../../header/HeaderFragment";

class WeatherOpenApiPage extends Component<any, any> {

    render() {
        Page.updateTitle("OPEN_API.WEATHER_API_TITLE");
        return <div className={"m-grid m-grid--hor m-grid--root m-page"}>
            <div
                className={"m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"}>
                <HeaderFragment/>
                <WeatherOpenApi/>
            </div>
        </div>
    }
}

export default WeatherOpenApiPage;