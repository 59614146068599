// @flow

import React, {Component, useRef} from 'react';
import Config from "../../../../config/Config";
import Api from "../../../../bundle/Api";
import styles from './GoogleSignInButtonWrapper.css';
import Page from "../../../../bundle/Page";
import {withRouter} from "react-router";
import {LLMsg} from "../../../../IntlCapture";
import UrlBuilder from "../../../../url/UrlBuilder";
import ErrorUtil from "../../../../util/ErrorUtil";
import UserDao from "../../../../dao/UserDao";
import LoadingUtil from "../../../../util/LoadingUtil";

type GoogleSignInButtonWrapperProps = {
    match?: Object,
    location?: Object,
    history?: Object
}

class GoogleSignInButtonWrapper extends Component<GoogleSignInButtonWrapperProps, any> {
    constructor(props) {
        super(props);
        this.divRef = React.createRef();
    }
    divRef;

    componentDidMount(): * {
        if (typeof window === "undefined" || !window.google) {
            return;
        }

        try {
            console.log(Config.GOOGLE_CLIENT_ID);
            console.log(window.google.accounts);
            window.google.accounts.id.initialize({
                client_id: Config.GOOGLE_CLIENT_ID,
                callback: async (res) => {
                    this.loginByGoogle(res.credential);
                },
            });
            const opts: GsiButtonConfiguration = {
                type: "standard",
                theme: "filled_blue",
                size: "medium",
                text: "signin_with",
                logo_alignment: "left",
            }
            window.google.accounts.id.renderButton(this.divRef.current, {opts});
        } catch (error) {
            console.log(error);
        }
    }

    loginByGoogle = (googleIdToken: string) => {

        console.log("googleIdToken", googleIdToken);
        let createUserProfileByGoogleToken = () => {

            //create an account in maker cloud if the profile is not existed
            Api.services.user.doCreateUserByGoogleSignUp().then((createResponse) => {

                if (createResponse.status === 200) {
                    Api.services.user.getMe().then((userResponse) => {
                        LoadingUtil.hideFullScreenLoading();
                        if (userResponse.status === 200) {

                            UserDao.updateUser(userResponse);
                            const ref = UrlBuilder.getQueryString()["ref"];
                            if (ref) {
                                this.props.history && this.props.history.push(ref);
                            } else {
                                this.props.history && this.props.history.push(Page.internals.urls.misc.home());
                            }
                        }
                    }).catch((e) => {
                        LoadingUtil.hideFullScreenLoading();
                        ErrorUtil.promptError(e);
                        this.props.history && this.props.history.push(Page.internals.urls.user.logout());
                    });
                }
            }).catch((e) => {
                LoadingUtil.hideFullScreenLoading();
                ErrorUtil.promptError(e);
                this.props.history && this.props.history.push(Page.internals.urls.user.logout());
            });
        };

        let createUserIfNotExist = () => {

            // check if the user has created profile in maker cloud
            Api.services.user.getMe().then((userResponse) => {
                if (userResponse.status === 200) {
                    LoadingUtil.hideFullScreenLoading();
                    UserDao.updateUser(userResponse);
                    const ref = UrlBuilder.getQueryString()["ref"];
                    if (ref) {
                        this.props.history && this.props.history.push(ref);
                    } else {
                        this.props.history && this.props.history.push(Page.internals.urls.misc.home());
                    }
                }
            }).catch((e) => {
                createUserProfileByGoogleToken();
            });

        };

        LoadingUtil.showFullScreenLoading();

        // login auth server, if auth server does not have the account, create account in auth server automatically
        Api.services.user.loginByGoogleToken(googleIdToken).then((loginResponse) => {
            if (loginResponse.status === 200) {
                let accessToken = loginResponse.data.access_token;
                let refreshToken = loginResponse.data.refresh_token;
                let expiresIn = loginResponse.data.expires_in;
                if (accessToken) {
                    Api.services.user.updateToken(accessToken, refreshToken, expiresIn);
                    createUserIfNotExist();
                }
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            console.log("error=", e);
        });
    };

    googleCallback = (response: any) => {
        let googleIdToken = response.credential;
        if (googleIdToken) {
            this.loginByGoogle(googleIdToken);
        }
    };

    render() {

        return <div ref={this.divRef}
                    className={" btn  m-btn m-btn--pill  m-btn  m-btn m-btn--icon"}>
                   <span>
                      <i className="fab fa-google"/>
                      <span>{LLMsg("LOGIN_PAGE.GOOGLE")}</span>
                   </span>
        </div>
    }

}

export default withRouter(GoogleSignInButtonWrapper);