// @flow

import React from 'react';
import styles from './index.css';
import {LLMsg} from "../../../../IntlCapture";
import _ from 'lodash';
import {ClassNames} from "../../../../bundle/ClassNames";
import Api from "../../../../bundle/Api";
import UserDao from "../../../../dao/UserDao";
import ErrorUtil from "../../../../util/ErrorUtil";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";

type State = {
    isTimerCounting: boolean,
    isSettingOpen: boolean,
    timerInterval: number,
    minScore: number
}
type Props = {
    projectId: string
}
export default class ObjectDetectionFragment extends React.Component<Props, State> {

    videoRef = React.createRef();
    canvasRef = React.createRef();
    resultClassRef = React.createRef();
    timerRef = React.createRef();
    detectedObjectListRef = React.createRef();

    detectedObjectSet: Set<string> = new Set();
    currentTimerTime: number = 5;

    state = {
        isTimerCounting: true,
        isSettingOpen: false,
        timerInterval: 5,
        minScore: 0.5
    }

    timer;

    componentWillUnmount() {
        clearInterval(this.timer);
        window.stream && window.stream.getTracks().forEach(function (track) {
            track.stop();
        });
    }

    sendDetectedObject() {
        let result: string = Array.from(this.detectedObjectSet).join(',');
        if (result) {
            console.log("result", result);
            Api.services.eventTrigger.trigger.objectDetection(this.props.projectId, result).then((response) => {
            })
            this.detectedObjectSet.clear();
        }
    }

    stopTimer() {
        if (this.state.isTimerCounting) {
            this.setState({isTimerCounting: false});
        }
        clearInterval(this.timer);
    }


    startTimer() {
        if (!this.state.isTimerCounting) {
            this.setState({isTimerCounting: true});
        }

        this.timer = setInterval(() => {
            if (this.currentTimerTime === 0) {
                this.currentTimerTime = this.state.timerInterval;
                this.sendDetectedObject();
            } else {
                this.currentTimerTime = this.currentTimerTime - 1;
            }
            if (this.timerRef.current) {
                this.timerRef.current.innerHTML = this.currentTimerTime;
            }
        }, 1000);
    }

    componentDidMount() {

        if (!UserDao.permission.allowAi) {
            ErrorUtil.showPermissionNotAllowed(() => {
                RouterHistory().push(Page.internals.urls.license.myLicenseHome());
            });
            return;
        }

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const webCamPromise = navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: {
                        facingMode: "user"
                    }
                })
                .then(stream => {
                    window.stream = stream;
                    this.videoRef.current.srcObject = stream;
                    return new Promise((resolve, reject) => {
                        this.videoRef.current.onloadedmetadata = () => {
                            resolve();
                        };
                    });
                });
            const modelPromise = window.cocoSsd.load();
            Promise.all([modelPromise, webCamPromise])
                .then(values => {
                    this.detectFrame(this.videoRef.current, values[0]);
                    this.startTimer();
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    detectFrame = (video, model) => {
        model.detect(video).then(predictions => {
            let result = [];
            predictions.forEach((item) => {
                let classname = item.class;
                let tempScore = (_.round(item.score, 2) * 100) + "";
                let score;
                if (tempScore.startsWith("100")) {
                    score = 100 + "%"
                } else {
                    score = tempScore.substring(0, 2) + "%";
                }
                result.push("<span class='" + styles.detectedObjectItem + "'>" + classname + " (" + score + ")" + "</span>");
                if (item.score > this.state.minScore) {
                    this.detectedObjectSet.add(classname);
                }
            })
            if (this.resultClassRef.current && this.detectedObjectListRef.current) {
                this.resultClassRef.current.innerHTML = result.join("");
                let detectedObjectList = [];
                this.detectedObjectSet.forEach((value1, value2, set) => {
                    detectedObjectList.push(
                        "<span class='" + styles.detectedObjectItem + "'>" + value1 + "</span>"
                    );
                })
                this.detectedObjectListRef.current.innerHTML = detectedObjectList.join("");
            }
            this.renderPredictions(predictions);
            requestAnimationFrame(() => {
                this.detectFrame(video, model);
            });
        });
    };

    renderPredictions = predictions => {
        if (!this.canvasRef.current) {
            return;
        }
        const ctx = this.canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        // Font options.
        const font = "16px sans-serif";
        ctx.font = font;
        ctx.textBaseline = "top";
        predictions.forEach(prediction => {
            const x = prediction.bbox[0];
            const y = prediction.bbox[1];
            const width = prediction.bbox[2];
            const height = prediction.bbox[3];
            // Draw the bounding box.
            ctx.strokeStyle = "#00FFFF";
            ctx.lineWidth = 4;
            ctx.strokeRect(x, y, width, height);
            // Draw the label background.
            ctx.fillStyle = "#00FFFF";
            const textWidth = ctx.measureText(prediction.class).width;
            const textHeight = parseInt(font, 10); // base 10
            ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
        });

        predictions.forEach(prediction => {
            const x = prediction.bbox[0];
            const y = prediction.bbox[1];
            // Draw the text last to ensure it's on top.
            ctx.fillStyle = "#000000";
            ctx.fillText(prediction.class, x, y);
        });
    };

    updateTimerInterval(newInterval: number) {
        if (this.state.timerInterval !== newInterval) {
            this.setState({timerInterval: newInterval});
            this.currentTimerTime = newInterval;
        }
    }

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.box}>
                    <video
                        autoPlay
                        playsInline
                        muted
                        ref={this.videoRef}
                        width="600"
                        height="500"
                    />
                    <canvas
                        className={styles.canvas}
                        ref={this.canvasRef}
                        width="600"
                        height="500"
                    />
                </div>
                <div className={ClassNames(styles.result, "noselect")}>
                    <h1>{LLMsg("AI.IMAGE_TRANSFER.DETECTED_OBJECTS")}</h1>
                    <div className={styles.resultClass} ref={this.resultClassRef}>
                        {LLMsg("COMMON.ACTION.LOADING_MESSAGE")}
                    </div>
                    <hr/>
                    <div>
                        <h1>
                            {LLMsg("AI.IMAGE_TRANSFER.TRIGGER_IN_PREFIX")}
                            <span ref={this.timerRef}>5</span>
                            {LLMsg("AI.IMAGE_TRANSFER.TRIGGER_IN_SECONDS")}
                            {
                                this.state.isTimerCounting && (
                                    <div className={styles.iconButton} onClick={() => {
                                        this.stopTimer();
                                    }}><i className="fa fa-pause-circle"/></div>
                                )
                            }
                            {
                                !this.state.isTimerCounting && (
                                    <div className={styles.iconButton} onClick={() => {
                                        this.startTimer();
                                    }}><i className="fa fa-play-circle"/></div>
                                )
                            }
                            <div className={styles.iconButton}
                                 onClick={() => {
                                     this.setState({isSettingOpen: !this.state.isSettingOpen})
                                 }}>
                                <i className="fa fa-cog"/>
                            </div>
                        </h1>
                        {
                            this.state.isSettingOpen && <div className={styles.settingWrapper}>
                                <div>
                                    <div className={styles.settingLabel}>{LLMsg("AI.IMAGE_TRANSFER.SEND_INTERVAL")}</div>
                                    <div
                                        onClick={() => {
                                            this.updateTimerInterval(5)
                                        }}
                                        className={ClassNames(styles.settingButton, {[styles.active]: this.state.timerInterval === 5})}>5s
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.updateTimerInterval(10)
                                        }}
                                        className={ClassNames(styles.settingButton, {[styles.active]: this.state.timerInterval === 10})}>10s
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.updateTimerInterval(15)
                                        }}
                                        className={ClassNames(styles.settingButton, {[styles.active]: this.state.timerInterval === 15})}>15s
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.updateTimerInterval(30)
                                        }}
                                        className={ClassNames(styles.settingButton, {[styles.active]: this.state.timerInterval === 30})}>30s
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.settingLabel}>{LLMsg("AI.IMAGE_TRANSFER.MIN_SCORE")}</div>

                                    <div
                                        onClick={() => {
                                            this.setState({minScore: 0.25})
                                        }}
                                        className={ClassNames(styles.settingButton, {[styles.active]: this.state.minScore === 0.25})}>25%
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.setState({minScore: 0.5})
                                        }}
                                        className={ClassNames(styles.settingButton, {[styles.active]: this.state.minScore === 0.5})}>50%
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.setState({minScore: 0.75})
                                        }}
                                        className={ClassNames(styles.settingButton, {[styles.active]: this.state.minScore === 0.75})}>75%
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.setState({minScore: 1})
                                        }}
                                        className={ClassNames(styles.settingButton, {[styles.active]: this.state.minScore === 1})}>100%
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.resultClass} ref={this.detectedObjectListRef}/>
                </div>
            </div>
        );
    }
}