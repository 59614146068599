// @flow

import React, {Component} from 'react';
import Constant from "../../../bundle/Constant";
import ClipboardJS from "clipboard";
import {LLMsg} from "../../../IntlCapture";

type CopyChartEmbedUrlButtonFragmentProps = {
    text: string
}

class CopyChartEmbedUrlButtonFragment extends Component<CopyChartEmbedUrlButtonFragmentProps, any> {
    buttonRef: any;

    constructor(props: CopyChartEmbedUrlButtonFragmentProps) {
        super(props);
        this.buttonRef = React.createRef();
    }

    initClipboard() {

        let clipboard = new ClipboardJS(this.buttonRef);

        clipboard.on('success', function (e) {
            window.toastr.options = Constant.toastr.defaultOptions;
            window.toastr.success(LLMsg("COMMON.ACTION.COPY_SUCCESS"));
        });
    }

    componentDidMount() {
        this.initClipboard();
    }

    render() {

        return <button type="button"
                       data-clipboard-text={this.props.text}
                       ref={(ref) => {
                           this.buttonRef = ref;
                       }}
                       // style={{color: this.props.color}}
                       className={"ml-2 pb-3 btn btn-link m-btn--pill btn-md"}>
            <i className="fa fa-copy"/> {LLMsg("COMMON.CHART.COPY_EMBEDDED_CHART_URL")}
        </button>;
    }
};
export default CopyChartEmbedUrlButtonFragment;