import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import UrlBuilder from "../UrlBuilder";
import Api from "../../bundle/Api";

export default class TopicApiUrlHelper {

    static getTopicCreateUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_CREATE_API, []);
    }

    static getTopicSendNewMessageUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_SEND_NEW_MESSAGE_API, []);
    }
    static getTopicSendNewMessageByTopicNameUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_SEND_NEW_MESSAGE_BY_TOPIC_NAME_API, []);
    }

    static getTopicDetailUrl(topicId: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_DETAIL_API, [{
            key: ":topicId",
            value: topicId
        }]);
    }

    static getTopicIdByNameUrl(topicName: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_ID_API, [{
            key: ":topicName",
            value: topicName
        }])
    }

    static getCreateTopicLimitUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_CREATE_LIMIT_API, []);
    }

    static update = {
        description: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_UPDATE_API, []);
        },
        newDataType: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_NEW_DATA_TYPE_API, []);
        },
        updateDataType: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_UPDATE_DATA_TYPE_API, []);
        },
        deleteDataType: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_DELETE_DATA_TYPE_API, []);
        },
        updateTopicLocation: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_UPDATE_LOCATION_API, []);
        },
        removeTopicLocation: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_REMOVE_LOCATION_API, []);
        }
    };

    static getListOfTopicUrl(topicIds?: Array<string>): string {

        let queryString = "";
        if (topicIds && topicIds.length > 0) {
            queryString = "?" + UrlBuilder.toQueryString(
                {"topicIds": topicIds.join(",")}
            );
        }

        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_LISTING_API, []) + queryString;
    }

    static getListOfChartableTopicUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.TOPIC_CHARTABLE_LISTING_API, []);
    }

}