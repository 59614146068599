// @flow

import React, {Component} from 'react';
import './App.css';
import AppRoute from "./AppRoute";
import {Provider} from "react-redux";
import {createStore} from "redux";
import rootReducer from "./redux/reducer";
import AppIntl from "./AppIntl";

type AppState = {}
type AppProps = {}

export type Match = {
    params: { [key: string]: ?string },
    isExact: boolean,
    path: string,
    url: string,
};

class App extends Component<AppProps, AppState> {

    render() {
        const store = createStore(rootReducer,
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
        try {
            // Api.services.user.startRefreshTokenTimer(parseInt(DataStore.cookies.get(Constant.cookies.user.expiresIn)) - Date.now(), 3);
        } catch (e) {
        }
        return (
            <Provider store={store}>
                <AppIntl>
                    <AppRoute/>

                </AppIntl>
            </Provider>
        )
    }
}

export default App;
