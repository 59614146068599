// @flow

import React, {Component} from "react";
import Constant from "../../bundle/Constant";
import DataTyeListFragment from "./DataTyeListFragment";
import {LLMsg} from "../../IntlCapture";

type MappedDataRecordTypeListFragmentProps = {
    dataRecordTypes: Array<{
        id: string,
        description: string,
        dataRecordTypeName: string,
        keyName: string,
        devices: Array<Object>
    }>,
    topicId: string
}

class MappedDataRecordTypeListFragment extends Component<MappedDataRecordTypeListFragmentProps, any> {

    sliderRef: any;

    constructor(props: MappedDataRecordTypeListFragmentProps) {
        super(props);
        this.sliderRef = React.createRef();
    }


    render() {

        let settings;
        let heightClass;

        let responsive = [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ];

        if (this.props.dataRecordTypes && this.props.dataRecordTypes.length > 3) {
            settings = {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 3.5,
                slidesToScroll: 3,
                swipeToSlide: false,
                slidesPerRow: 2,
                arrows: false,
                responsive: responsive,
            };
        } else {
            settings = {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 3.5,
                slidesToScroll: 3,
                swipeToSlide: false,
                slidesPerRow: 1,
                arrows: false,
                responsive: responsive,
            };
        }

        return <div className={Constant.portlet.size.colWidth.w12}>
            <div className="m-portlet mb-0">
                <div className="m-portlet__body">
                    <div className={" ml-1 mr-1"}>
                        <div>
                            <h2>{LLMsg("COMMON.DATA_TYPE.DATA_TYPE_LIST")}</h2>
                            <DataTyeListFragment dataRecordTypes={this.props.dataRecordTypes}
                                                 topicId={this.props.topicId} settings={settings}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;

    }
}

export default MappedDataRecordTypeListFragment;