// @flow

import React from 'react';
import styles from "./index.css";
import type {Permission} from "../../../model/model";
import {LLMsg} from "../../../IntlCapture";

type Props = {
    permission: Permission
};

export default class PermissionSummaryFragment extends React.Component <Props, any> {
    renderIsSupported(isSupport: boolean) {
        return isSupport ? LLMsg("PERMISSION.SUPPORTED") : LLMsg("PERMISSION.NOT_SUPPORT");
    }

    renderIsUnlimited(value: number) {
        return value === -1 ? LLMsg("PERMISSION.UNLIMITED") : value;
    }

    renderProjectPermission() {
        return <React.Fragment>
            <div className={styles.item}>
                <div className={styles.groupTitle}>
                    {LLMsg("PERMISSION.PROJECT")}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.SHARE_PROJECT")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowShareProject)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.PROJECT_COUNT")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsUnlimited(this.props.permission.allowProjectCount)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.TOPIC_COUNT")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsUnlimited(this.props.permission.allowTopicCount)}
                </div>
            </div>
        </React.Fragment>;
    }

    renderDataPermission() {

        return <React.Fragment>
            <div className={styles.item}>
                <div className={styles.groupTitle}>
                    {LLMsg("PERMISSION.DATA")}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.INTERVAL_LIMIT")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsUnlimited(this.props.permission.allowDataIntervalLimitSec)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.DAILY_LIMIT")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsUnlimited(-1)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.EXPORT_DATA")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowExportData)}
                </div>
            </div>
        </React.Fragment>;
    }

    renderEventTriggerPermission() {

        return <React.Fragment>
            <div className={styles.item}>
                <div className={styles.groupTitle}>
                    {LLMsg("PERMISSION.EVENT_TRIGGER")}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.MQTT_TRIGGER")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowMqttTrigger)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.WEB_HOOK_TRIGGER")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowWebHookTrigger)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.DIALOGFLOW_TRIGGER")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowDialogFlowTrigger)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.MQTT_ACTION")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowMqttAction)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.IFTTT_ACTION")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowIftttAction)}
                </div>
            </div>
        </React.Fragment>;
    }

    renderProjectMapPermission() {

        return <React.Fragment>
            <div className={styles.item}>
                <div className={styles.groupTitle}>
                    {LLMsg("PERMISSION.PROJECT_MAP")}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.DYNAMIC_LOCATION_UPDATE")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowDynamicLocationUpdate)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.EMBED_PROJECT_MAP")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowEmbedProjectMap)}
                </div>
            </div>
        </React.Fragment>;
    }

    renderChartPermission() {

        return <React.Fragment>
            <div className={styles.item}>
                <div className={styles.groupTitle}>
                    {LLMsg("PERMISSION.CHART")}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.LIVE_DATA")}
                </div>
                <div className={styles.itemValue}>

                    {this.renderIsSupported(true)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.HISTORICAL_DATA")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(true)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.BASIC_CHART")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(true)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.CIRCLE_POINT_MAP")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowCirclePointMap)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.EMBED_CHART")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowEmbedChart)}
                </div>
            </div>
        </React.Fragment>;
    }

    renderWidgetPermission() {

        return <React.Fragment>
            <div className={styles.item}>
                <div className={styles.groupTitle}>
                    {LLMsg("PERMISSION.WIDGET")}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.BUTTON")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(true)}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("PERMISSION.TOGGLE")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(true)}
                </div>
            </div>
        </React.Fragment>;
    }

    renderAiPermission() {
        return <React.Fragment>
            <div className={styles.item}>
                <div className={styles.groupTitle}>
                    {LLMsg("COMMON.PROJECT.AI")}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {LLMsg("COMMON.PROJECT.AI")}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(this.props.permission.allowAi)}
                </div>
            </div>
        </React.Fragment>;
    }

    renderOpenApiPermission() {
        return <React.Fragment>
            <div className={styles.item}>
                <div className={styles.groupTitle}>
                    {"Open API"}
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTitle}>
                    {"Open API"}
                </div>
                <div className={styles.itemValue}>
                    {this.renderIsSupported(false)}
                </div>
            </div>
        </React.Fragment>;
    }

    render() {
        return <div className={"m-portlet m-portlet--unair " + styles.portletBox}>

            <div className={styles.sectionHeader}>
                {LLMsg("PERMISSION.YOUR_PERMISSIONS")}
            </div>
            {
                this.props.permission && <div className="m-portlet__body">
                    {this.renderProjectPermission()}
                    {this.renderDataPermission()}
                    {this.renderEventTriggerPermission()}
                    {this.renderProjectMapPermission()}
                    {this.renderChartPermission()}
                    {this.renderWidgetPermission()}
                    {this.renderAiPermission()}
                    {this.renderOpenApiPermission()}
                </div>

            }
        </div>
    }
}