// @flow

import React from 'react';
import styles from './index.css';
import type {MaintenancePeriod} from "../../../../model/model";
import LoadingUtil from "../../../../util/LoadingUtil";
import Api from "../../../../bundle/Api";
import swal from "sweetalert2";
import {LLMsg} from "../../../../IntlCapture";
import ErrorUtil from "../../../../util/ErrorUtil";
import TimeUtil from "../../../../util/TimeUtil";

type MaintenancePeriodUpdateFragmentProps = {
    close: Function,
    onUpdatedCallback: Function,
    maintenancePeriod: MaintenancePeriod
}
export default class MaintenancePeriodUpdateFragment extends React.Component<MaintenancePeriodUpdateFragmentProps, any> {

    startDate: string;
    endDate: string;
    showSinceDate: string;

    onUpdate = () => {

        let startDate = TimeUtil.convertTimeFormat(this.startDate, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        let endDate = TimeUtil.convertTimeFormat(this.endDate, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        let showSinceDate = TimeUtil.convertTimeFormat(this.showSinceDate, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);

        LoadingUtil.showFullScreenLoading();
        Api.services.maintenanceService.update(this.props.maintenancePeriod.id, startDate, endDate, showSinceDate).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            this.props.close();
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success");
            }
            this.props.onUpdatedCallback();

        }).catch((error) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(error);
            this.props.close();
        });
    };


    componentDidMount() {

        this.startDate = TimeUtil.convertTimeFormat(this.props.maintenancePeriod.startFrom, TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        this.endDate = TimeUtil.convertTimeFormat(this.props.maintenancePeriod.endUntil, TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        this.showSinceDate = TimeUtil.convertTimeFormat(this.props.maintenancePeriod.showSince, TimeUtil.FULL_DATE_DISPLAY_FORMAT);

        this.forceUpdate();

    }

    render() {

        return <div className={styles.wrapper}>
            <div className={styles.title}>Update Maintenance Period</div>
            <hr/>

            <div className={styles.content}>
                <div>
                    <div className={styles.label}>
                        Start Date
                    </div>
                    <div className={styles.value}>
                        <input type="email" className="form-control m-input"
                               defaultValue={this.startDate}
                               onChange={(event) => {
                                   this.startDate = event.target.value;
                               }}
                        />
                    </div>
                </div>
                <div className={styles.timeDifferentArrow}><i className="fa fa-arrow-alt-circle-down fa-2x"/></div>
                <div>
                    <div className={styles.label}>
                        End Date
                    </div>
                    <div className={styles.value}>
                        <input type="email" className="form-control m-input"
                               defaultValue={this.endDate}
                               onChange={(event) => {
                                   this.endDate = event.target.value;
                               }}
                        />
                    </div>
                </div>
                <hr/>
                <div>
                    <div className={styles.label}>
                        Show Message Since
                    </div>
                    <div className={styles.value}>
                        <input type="email" className="form-control m-input"
                               defaultValue={this.showSinceDate}
                               onChange={(event) => {
                                   this.showSinceDate = event.target.value;
                               }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.footer_button}>
                <button type="button" className="btn btn-secondary ml-1" onClick={() => {
                    this.props.close();
                }}>
                    Cancel
                </button>
                <button type="button" className="btn btn-primary ml-1" onClick={() => {
                    this.onUpdate();
                }}>
                    Update
                </button>
            </div>
        </div>;
    }
}