// @flow

class TopicAction {
    static CREATE_TOPIC_ACTION_TYPE = "CREATE_TOPIC_ACTION";
    static GET_LIST_OF_TOPIC_ACTION_TYPE = "GET_LIST_OF_TOPIC_ACTION";
    static GET_ONE_TOPIC_ACTION_TYPE = "GET_ONE_TOPIC_ACTION";
    static TOPIC_UPDATED = "TOPIC_UPDATED_ACTION";
    static TOPIC_NEW_DATA_ACTION_TYPE = "TOPIC_NEW_DATA_ACTION_TYPE";

}

export default TopicAction;