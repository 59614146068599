// @flow

import React from 'react';
import styles from './index.css';
import AppletDetailLink from "../AppletDetailLink";
import type {Applet} from "../../../../model/model";
import {LLMsg} from "../../../../IntlCapture";
import {ClassNames} from "../../../../bundle/ClassNames";
import Constant from "../../../../bundle/Constant";

type Props = {
    appletList: Array<Applet>,
    currentAppletId: number
}
export default class AppletListWrapper extends React.Component <Props, any> {
    render() {
        return <div className={styles.wrapper}
                    style={{
                        backgroundColor: Constant.theme.eventTrigger.appletList.color,
                        border: "solid 3px " + Constant.theme.eventTrigger.appletList.color
                    }}>
            {LLMsg("EVENT_TRIGGER.APPLET_LIST")}
            <i className={ClassNames("fas fa-caret-right", styles.dropdownArrow)}/>
            {
                this.props.appletList && <div className={styles.dropdownWrapper}>
                    {
                        this.props.appletList && this.props.appletList.length === 0 && <div className={styles.noRecord}>
                            {LLMsg("COMMON.ACTION.NO_RECORD")}
                        </div>
                    }
                    {
                        this.props.appletList && this.props.appletList.map((applet: Applet) => {
                            return <AppletDetailLink
                                key={applet.id}
                                appletName={applet.name}
                                active={applet.id === this.props.currentAppletId}
                                projectId={applet.projectId}
                                appletId={applet.id}
                                enabled={applet.enabled}
                            />
                        })
                    }
                </div>
            }
        </div>;
    }

}