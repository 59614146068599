// @flow

import Config from "../../config/Config";
import Api from "../../bundle/Api";

export default class BackendStatusApiUrlHelper {

    static getStatus(): string {
        return Config.API_MAKER_CLOUD + Api.paths.BACKEND_STATUS_CHECK_API;
    }

    static getUpdateLogs(): string {
        return Config.API_MAKER_CLOUD + Api.paths.BACKEND_UPDATE_LOG_API;
    }

    static getLearningLinks(): string {
        return Config.API_MAKER_CLOUD + Api.paths.LEARNING_LINKS_API;
    }
}