// @flow

import PageUrl from "./PageUrl";
import StringUtil from "../../util/StringUtil";

export default class SchoolPageUrl {

    static list(): string {
        return PageUrl.SCHOOL_URL;
    }

    static detail(schoolId: string, schoolName: string): string {
        return StringUtil.replaceAllText(PageUrl.SCHOOL_DETAIL_URL, [
            {
                key: ":schoolId",
                value: schoolId + ""
            }, {
                key: ":schoolName",
                value: StringUtil.removeBlacklistChar(schoolName)
            }
        ]);
    }


}