// @flow


import PageUrl from "./PageUrl";

export default class ExternalPageUrlBuilder {

    static scaleHome(): string {
        return PageUrl.SCALE_HOME_URL;
    }
    static scaleContactUs(): string {
        return PageUrl.SCALE_CONTACT_US_URL;
    }
}