// @flow

import React from 'react';
import {LLMsg} from "../../../IntlCapture";
import styles from "../../user/GuestRegisterFormWrapper/index.css";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import LoadingUtil from "../../../util/LoadingUtil";
import Api from "../../../bundle/Api";
import ErrorUtil from "../../../util/ErrorUtil";
import swal from "sweetalert2";
import {renderToStaticMarkup} from "react-dom/server";
import {RouterHistory} from "../../../AppRoute";
import TimeUtil from "../../../util/TimeUtil";
import type {LicenseDetail} from "../../../model/model";
import _ from 'lodash';
import {withRouter} from "react-router";


type LicenseDetailPublicViewFragmentState = {
    licenseDetail?: LicenseDetail
}


class LicenseDetailPublicViewFragment extends React.Component<any, LicenseDetailPublicViewFragmentState> {

    licenseKeyInput: any;

    constructor(props: any) {
        super(props);
        this.state = {};
        this.checkLicense = this.checkLicense.bind(this);
    }

    checkLicense = (licenseKey: string) => {
        let isValid = true;

        if (!licenseKey) {
            swal(LLMsg("COMMON.ACTION.NO_RECORD"), LLMsg("REGISTER_PAGE.LICENSE_KEY_INVALID"), "error");
            isValid = false;
        }

        if (!isValid) {
            return;
        }

        if (licenseKey) {
            LoadingUtil.showFullScreenLoading();
            Api.services.license.getLicenseDetail(licenseKey).then((response) => {
                LoadingUtil.hideFullScreenLoading();
                if (response.status === 200) {
                    RouterHistory().replace(Page.internals.urls.license.licenseDetailPage(licenseKey));
                    this.setState({licenseDetail: response.data});

                    // swal({
                    //     html: renderToStaticMarkup(LicenseDetailPrompt(response.data)),
                    //     showCancelButton: false,
                    //     confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
                    // })
                }
            }).catch((e) => {
                this.setState({licenseDetail: undefined});
                LoadingUtil.hideFullScreenLoading();
                if (ErrorUtil.hasErrorCode(e, ErrorUtil.LICENSE_NOT_FOUND)) {
                    window.toastr.error(LLMsg("COMMON.ACTION.NO_RECORD"), LLMsg("REGISTER_PAGE.LICENSE_KEY_INVALID"));
                } else {
                    ErrorUtil.promptError(e);
                }
            })
        }
    };


    render() {
        const licenseKey = this.props.match && this.props.match.params["licenseKey"];

        return <div
            className="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper">
            <div className="m-login__body">
                <div className="m-login__signin">
                    <div className="m-login__title">
                        <h3>{LLMsg("MY_SUB.VIEW_LICENSE_DETAIL_BUTTON")}</h3>
                    </div>
                    <form className="">
                        <div className="form-group m-form__group">
                            <div className={"input-group"}>
                                <input className="form-control" type="text"
                                       placeholder={LLMsg("MY_SUB.ENTER_LICENSE_KEY")}
                                       defaultValue={licenseKey}
                                       autoFocus={true}
                                       name="username" autoComplete="off" ref={(licenseKeyInput) => {
                                    this.licenseKeyInput = licenseKeyInput
                                }}/>
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => {
                                        this.checkLicense(this.licenseKeyInput.value)
                                    }}>
                                        {LLMsg("COMMON.ACTION.VIEW")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.licenseDetail && <div className={"pl-4"}>
                                <div className={"mb-2"}>
                                    {LLMsg("MY_SUB.LICENSE_KEY") + ": " + _.get(this.state, "licenseDetail.licenseKey", "")}
                                </div>
                                <div className={"mb-2"}>
                                    {LLMsg("MY_SUB.LICENSED_TO") + ": " + _.get(this.state, "licenseDetail.licenseTargetName", "")}
                                </div>
                                <div className={"mb-2"}>
                                    {LLMsg("MY_SUB.EXPIRY_DATE") + ": " + TimeUtil.convertTimeFormat(_.get(this.state, "licenseDetail.trialEndDate", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                                </div>
                                <div className={"mb-2"}>
                                    {LLMsg("MY_SUB.CONSUMED") + ": " + _.get(this.state, "licenseDetail.currentConsumptionCount", "") + "/" + _.get(this.state, "licenseDetail.maxConsumptionCount", "")}
                                </div>
                                <div className={"mb-2"}>
                                    {LLMsg("MY_SUB.CONCURRENT_LOGIN") + ": " + _.get(this.state, "licenseDetail.currentConcurrentLoginCount", "") + "/" + _.get(this.state, "licenseDetail.maxConcurrentLoginCount", "")}
                                </div>
                            </div>
                        }
                        <hr className={"mt-5"}/>
                        <div className={styles.expiryNotice}>
                            {LLMsg("MY_SUB.ENTER_LICENSE_KEY_HINTS")}
                        </div>
                    </form>
                    <div className="m-login__action">
                        <Link to={Page.internals.urls.misc.home()} className="m-link">
                            <span>{LLMsg("COMMON.HOME.BACK_TO_HOME")}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(LicenseDetailPublicViewFragment);