// @flow

import React, {Component} from 'react'
import {Redirect, Route} from 'react-router-dom'
import connect from "react-redux/es/connect/connect";
import Api from "./bundle/Api";
import type {SimpleAction} from "./redux/reducer";
import Page from "./bundle/Page";
import HttpUtil from "./util/HttpUtil";
import {withRouter} from "react-router";
import UserDao from "./dao/UserDao";
import NotAllowedPage from "./component/page/NotAllowedPage";
import * as PubSub from "pubsub-js";
import UrlBuilder from "./url/UrlBuilder";
import MyLicensePage from "./component/page/license/MyLicensePage";

type ProtectedRouteProps = {
    component: any,
    path: string,
    action?: SimpleAction,
    role: string,

    match?: Object,
    location?: Object,
    history?: Object
}
type ProtectedRouteState = {
    isLogin: boolean
}

class ProtectedRoute extends Component <ProtectedRouteProps, ProtectedRouteState> {

    constructor(props: ProtectedRouteProps) {
        super(props);
        this.state = {isLogin: Api.services.user.isLogin()};
        this.onReceivedUnauthorized = this.onReceivedUnauthorized.bind(this);
    }

    subToken: any;

    componentDidMount() {
        this.subToken = PubSub.subscribe(Api.actions.user.GET_MYSELF_DONE_ACTION_TYPE, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subToken);
    }

    onReceivedUnauthorized = () => {
        Api.services.user.logout();
        this.props.history && this.props.history.push(Page.internals.urls.user.login(UrlBuilder.getCurrentUrl()));
    };

    componentWillMount() {
        HttpUtil.setResponseInterceptor(this.onReceivedUnauthorized);
    }

    componentDidUpdate(prevProps: ProtectedRouteProps) {
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.user.USER_LOGIN_ACTION_TYPE)) {
            this.setState({isLogin: Api.services.user.isLogin()});
        }
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.user.USER_LOGOUT_ACTION_TYPE)) {
            this.setState({isLogin: Api.services.user.isLogin()});
        }
    }

    render() {
        let {component: Component, role: role, ...rest} = this.props;

        return <Route {...rest} render={(childProps) => {
            if (this.state.isLogin) {
                if (role && !UserDao.role.includes(role)) {
                    return <NotAllowedPage/>;
                }
                if (!UserDao.enabled) {
                    return <MyLicensePage/>;
                }
                return <Component {...childProps}/>;
            } else {
                return <Redirect to={Page.internals.urls.user.login(UrlBuilder.getCurrentUrl() || undefined)}/>
            }
        }}/>;
    }
};

const mapStateToProps = state => {
    return {
        action: state.ActionReducer.action
    };
};
export default withRouter(connect(mapStateToProps, null)(ProtectedRoute));
