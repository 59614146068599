// @flow

import {Link} from "react-router-dom";
import React from "react";

type SidebarSingleItemProps = {
    linkTo: string | Object,
    displayText: string,
    iconClassname: string,
    colorCode: string
}

const SidebarSingleItem = (props: SidebarSingleItemProps) => {

    const {linkTo, displayText, iconClassname, colorCode} = props;
    return <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true">
        <Link className="m-menu__link m-menu__toggle"
              title={displayText} to={linkTo}>

            <i className={"m-menu__link-icon fa-2x " + iconClassname}
               style={{color: colorCode}}/>
            <span className="m-menu__link-text sidebar_menu_span"
                  style={{color: colorCode, fontWeight: 900}}>{displayText}</span>
            {/*<i className="m-menu__ver-arrow la la-angle-right"/>*/}
        </Link>
    </li>
};

export default SidebarSingleItem;