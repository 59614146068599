// @flow

import React, {Component} from 'react';
import Api from "../../../../bundle/Api";
import {withRouter} from "react-router";
import type {Match} from "../../../../App";
import SchoolInfoItem from "./SchoolInfoItem";
import SchoolInfoItemWrapper from "./SchoolInfoItemWrapper";
import styles from "./SchoolInfoItem.css";
import {LLMsg} from "../../../../IntlCapture";
import Constant from "../../../../bundle/Constant";
import _ from 'lodash';
import SchoolApplication from "./SchoolApplication";
import type {SimpleAction} from "../../../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import * as PubSub from "pubsub-js";
import TimeUtil from "../../../../util/TimeUtil";

type SchoolDetailFragmentState = {
    school: Object,

    action?: SimpleAction,
    location?: Object,
    history?: Object,
    match?: Match
}

class SchoolDetailFragment extends Component<any, SchoolDetailFragmentState> {

    state = {
        school: {}
    };

    subToken: any;

    componentDidMount() {

        this.subToken = PubSub.subscribe(Api.actions.school.UPDATED_SCHOOL_APPLICATION_ACTION_TYPE, () => {
            this.getSchoolDetail();
        });
        this.getSchoolDetail();
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.subToken);
    }

    getSchoolDetail = () => {

        const schoolId = this.props.match && this.props.match.params["schoolId"];
        Api.services.school.detail(schoolId).then((response) => {
            if (response.status === 200) {
                this.setState({school: response.data});
            }
        }).catch(() => {

        });
    };

    render() {

        const {school} = this.state;
        let totalPending = 0;
        let totalApproved = 0;
        let totalRejected = 0;
        if (school.schoolApplications) {
            school.schoolApplications.forEach((schoolApplication) => {
                if (schoolApplication.status === Constant.school.application.status.pending) {
                    totalPending++;
                } else if (schoolApplication.status === Constant.school.application.status.rejected) {
                    totalRejected++;
                } else if (schoolApplication.status === Constant.school.application.status.approved) {
                    totalApproved++;
                }
            });
        }

        let totalApplication = _.get(school, "schoolApplications.length", 0);
        let applicationStatus = totalApplication > 0 ? <span>
            <span className={"mr-4"}>
                {LLMsg("COMMON.SCHOOL.TOTAL") + totalApplication + LLMsg("COMMON.SCHOOL.APPLICATION")}
            </span>
            <span className={"mr-4"}>
                {totalApproved + " " + LLMsg("COMMON.SCHOOL.APPROVED")}
            </span>
            <span className={"mr-4"}>
            {totalRejected + " " + LLMsg("COMMON.SCHOOL.REJECTED")}
            </span>
            <span className={"mr-4"}>
            {totalPending + " " + LLMsg("COMMON.SCHOOL.PENDING")}
            </span>
            {
                (totalPending > 0) &&
                <i className={"fa fa-exclamation " + styles.approvalStatusMark}/>
            }
        </span> : <span>{LLMsg("COMMON.SCHOOL.NO_PENDING_APPLICATION")}</span>;
        return <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__body">
                <SchoolInfoItemWrapper>
                    <SchoolInfoItem label={LLMsg("COMMON.SCHOOL.SCHOOL_ID")} value={school.id}/>
                    <SchoolInfoItem label={LLMsg("COMMON.SCHOOL.SCHOOL_NAME")} value={school.schoolName}/>
                    <SchoolInfoItem label={LLMsg("COMMON.SCHOOL.SCHOOL_EMAIL_DOMAIN")} value={school.emailDomain}/>
                    <SchoolInfoItem label={LLMsg("COMMON.SCHOOL.VALID_DATE")}
                                    value={school.validFrom && school.validTo ?
                                        TimeUtil.convertTimeFormat(school.validFrom, TimeUtil.FULL_DATE_DISPLAY_FORMAT) + " - " + TimeUtil.convertTimeFormat(school.validTo, TimeUtil.FULL_DATE_DISPLAY_FORMAT) :
                                        LLMsg("COMMON.SCHOOL.INACTIVE")}/>
                    <SchoolInfoItem label={LLMsg("COMMON.SCHOOL.APPLICATION_STATUS")} value={applicationStatus}/>

                    {
                        totalApplication > 0 && <SchoolApplication schoolApplications={school.schoolApplications}/>
                    }

                </SchoolInfoItemWrapper>
            </div>
        </div>;
    }
}


const mapStateToProps = state => {
    return {
        action: state.ActionReducer.action
    };
};
export default withRouter(connect(mapStateToProps, null)(SchoolDetailFragment));