// @flow

import React, {Component} from 'react';

class FilterWrapper extends Component <any, any> {
    render() {
        return <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
            <div className="row align-items-center">
                <div className="col-xl-8 order-2 order-xl-1">
                    <div className="form-group m-form__group row align-items-center">
                        <div className="col-md-4">
                            <div className="m-form__group m-form__group--inline">
                                <div className="m-form__label">
                                    <label>Status:</label>
                                </div>
                                <div className="m-form__control">
                                    <div
                                        className="dropdown bootstrap-select form-control m-bootstrap-select">
                                        <select className="form-control m-bootstrap-select"
                                                id="m_form_status" tabIndex={-98}>
                                            <option value>All</option>
                                            <option value={1}>Pending</option>
                                            <option value={2}>Delivered</option>
                                            <option value={3}>Canceled</option>
                                        </select>
                                        <button type="button"
                                                className="btn dropdown-toggle bs-placeholder btn-light"
                                                data-toggle="dropdown" role="button" data-id="m_form_status"
                                                title="All">
                                            <div className="filter-option">
                                                <div className="filter-option-inner">All</div>
                                            </div>
                                            &nbsp;<span className="bs-caret"><span
                                            className="caret"/></span></button>
                                        <div className="dropdown-menu " role="combobox">
                                            <div className="inner show" role="listbox" aria-expanded="false"
                                                 tabIndex={-1}>
                                                <ul className="dropdown-menu inner show"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-none m--margin-bottom-10"/>
                        </div>
                        <div className="col-md-4">
                            <div className="m-form__group m-form__group--inline">
                                <div className="m-form__label">
                                    <label className="m-label m-label--single">Type:</label>
                                </div>
                                <div className="m-form__control">
                                    <div
                                        className="dropdown bootstrap-select form-control m-bootstrap-select">
                                        <select className="form-control m-bootstrap-select" id="m_form_type"
                                                tabIndex={-98}>
                                            <option value>All</option>
                                            <option value={1}>Online</option>
                                            <option value={2}>Retail</option>
                                            <option value={3}>Direct</option>
                                        </select>
                                        <button type="button"
                                                className="btn dropdown-toggle bs-placeholder btn-light"
                                                data-toggle="dropdown" role="button" data-id="m_form_type"
                                                title="All">
                                            <div className="filter-option">
                                                <div className="filter-option-inner">All</div>
                                            </div>
                                            &nbsp;<span className="bs-caret"><span
                                            className="caret"/></span></button>
                                        <div className="dropdown-menu " role="combobox">
                                            <div className="inner show" role="listbox" aria-expanded="false"
                                                 tabIndex={-1}>
                                                <ul className="dropdown-menu inner show"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-md-none m--margin-bottom-10"/>
                        </div>
                        <div className="col-md-4">
                            <div className="m-input-icon m-input-icon--left">
                                <input type="text" className="form-control m-input" placeholder="Search..."
                                       id="generalSearch"/>
                                <span className="m-input-icon__icon m-input-icon__icon--left">
                        <span><i className="la la-search"/></span>
                      </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default FilterWrapper;