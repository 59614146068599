// @flow
import React from 'react';
import type {SubscriptionStatus} from "../../model/model";
import ErrorUtil from "../../util/ErrorUtil";
import Api from "../../bundle/Api";
import styles from './MySubscriptionStatusFragment.css';
import TimeUtil from "../../util/TimeUtil";
import UserDao from "../../dao/UserDao";
import {LLMsg} from "../../IntlCapture";
import LoadingUtil from "../../util/LoadingUtil";
import PermissionSummaryFragment from "./PermissionSummaryFragment";

type MySubscriptionStatusFragmentState = {
    subscriptionStatus: SubscriptionStatus
}

class MySubscriptionStatusFragment extends React.Component<any, MySubscriptionStatusFragmentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            subscriptionStatus: {}
        };

    }

    componentDidMount() {
        LoadingUtil.showFullScreenLoading();
        Api.services.user.getMySubscriptionStatus().then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({
                    subscriptionStatus: response.data
                })
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })
    }

    render() {
        let isConcurrentLoginLimitReached = false;
        if (this.state.subscriptionStatus && this.state.subscriptionStatus.licenseSubscription && this.state.subscriptionStatus.licenseSubscription.currentConcurrentLoginUsername) {

            let now = this.state.subscriptionStatus.licenseSubscription.currentConcurrentLoginUsername.includes(UserDao.email) ?
                this.state.subscriptionStatus.licenseSubscription.currentConcurrentLoginCount :
                this.state.subscriptionStatus.licenseSubscription.currentConcurrentLoginCount + 1;
            let max = this.state.subscriptionStatus.licenseSubscription.maxConcurrentLoginCount;

            if (now > max) {
                isConcurrentLoginLimitReached = true;
            }
        }
        return <div>
            <div className={"m-portlet m-portlet--unair " + styles.portletBox}>
                <div className="m-portlet__body">

                    <div className={styles.accountStatusBox}>
                        {LLMsg("MY_SUB.SUB_STATUS_PREFIX")}
                        {
                            this.state.subscriptionStatus.isActive ?
                                <span className={styles.accountActiveSpan}>
                                {LLMsg("MY_SUB.ACTIVE")}
                            </span> :
                                <span className={styles.accountInactiveSpan}>
                                {LLMsg("MY_SUB.INACTIVE")}
                            </span>
                        }
                    </div>
                    <hr/>
                    <div>
                        {
                            this.state.subscriptionStatus.isWhitelistEmail && <div className={styles.item}>
                            <span className={styles.itemCheck}>
                                <i className="fa fa-check-circle"/>
                            </span>
                                {LLMsg("MY_SUB.WHITELIST_STATEMENT")}
                            </div>
                        }
                        {
                            this.state.subscriptionStatus.isSchoolSubscription && <div className={styles.item}>
                              <span className={styles.itemCheck}>
                                <i className="fa fa-check-circle"/>
                            </span>
                                {LLMsg("MY_SUB.SCHOOL_SUB_STATEMENT")}
                                <div className={"mt-2 ml-5 " + styles.itemDesc}>
                                    {LLMsg("COMMON.SCHOOL.SCHOOL_NAME")}: {this.state.subscriptionStatus.school.schoolName}
                                </div>
                            </div>
                        }
                        {
                            this.state.subscriptionStatus.isEventSubscription && <div className={styles.item}>
                              <span className={styles.itemCheck}>
                                <i className="fa fa-check-circle"/>
                            </span>
                                {LLMsg("MY_SUB.EVENT_CODE_STATEMENT")}
                                <div className={"mt-2 ml-5 " + styles.itemDesc}>

                                    <div key={this.state.subscriptionStatus.event.id}>
                                        {LLMsg("COMMON.EVENT.EVENT_NAME")}: {this.state.subscriptionStatus.event.name}
                                    </div>

                                </div>
                            </div>
                        }
                        {
                            this.state.subscriptionStatus.licenseSubscription && <div className={styles.item}>
                                {
                                    this.state.subscriptionStatus.isLicenseSubscription &&
                                    <span className={styles.itemCheck}>
                                    <i className="fa fa-check-circle"/>
                                </span>
                                }
                                {
                                    !this.state.subscriptionStatus.isLicenseSubscription &&
                                    <span className={styles.itemCross}>
                                    <i className="fa fa-times-circle"/>
                                </span>
                                }
                                {LLMsg("MY_SUB.LICENSE_SUB_STATEMENT")}

                                {
                                    this.state.subscriptionStatus.licenseSubscription &&
                                    <div className={"mt-2 ml-5 " + styles.itemDesc}>
                                        <div>{LLMsg("MY_SUB.LICENSE_KEY") + ": " + this.state.subscriptionStatus.licenseSubscription.licenseKey}</div>
                                        <div>{LLMsg("MY_SUB.LICENSED_TO") + ": " + this.state.subscriptionStatus.licenseSubscription.licenseTargetName}</div>
                                        <div
                                            className={isConcurrentLoginLimitReached ? styles.limitReached : ""}>
                                            {
                                                LLMsg("MY_SUB.CONCURRENT_LOGIN") + ": "
                                                + (
                                                    this.state.subscriptionStatus.licenseSubscription.currentConcurrentLoginUsername &&
                                                    this.state.subscriptionStatus.licenseSubscription.currentConcurrentLoginUsername.includes(UserDao.email)
                                                        ? this.state.subscriptionStatus.licenseSubscription.currentConcurrentLoginCount :
                                                        this.state.subscriptionStatus.licenseSubscription.currentConcurrentLoginCount + 1)
                                                + "/"
                                                + this.state.subscriptionStatus.licenseSubscription.maxConcurrentLoginCount
                                            }
                                        </div>
                                        <div>
                                            {
                                                LLMsg("MY_SUB.CONSUMED") + ": "
                                                + this.state.subscriptionStatus.licenseSubscription.currentConsumptionCount
                                                + "/"
                                                + this.state.subscriptionStatus.licenseSubscription.maxConsumptionCount
                                            }
                                        </div>
                                        <div
                                            className={TimeUtil.isBeforeNow(this.state.subscriptionStatus.licenseSubscription.expiryDate) ? styles.limitReached : ""}>
                                            {
                                                LLMsg("MY_SUB.EXPIRE_DATE") + ": "
                                                + TimeUtil.convertTimeFormat(this.state.subscriptionStatus.licenseSubscription.expiryDate, TimeUtil.FULL_DATE_DISPLAY_FORMAT)
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <hr/>
                        {
                            this.state.subscriptionStatus.effectiveUntil &&
                            <div>
                                {LLMsg("MY_SUB.EXPIRE_DATE") + " "}
                                {TimeUtil.convertTimeFormat(this.state.subscriptionStatus.effectiveUntil, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}</div>
                        }
                        {
                            !this.state.subscriptionStatus.effectiveUntil && this.state.subscriptionStatus.isActive &&
                            <div>
                                {LLMsg("MY_SUB.NO_EXPIRE_DATE")}
                            </div>
                        }
                    </div>
                </div>
            </div>
            {this.state.subscriptionStatus &&
            <PermissionSummaryFragment permission={this.state.subscriptionStatus.permission}/>}
        </div>
    }
}

export default MySubscriptionStatusFragment;