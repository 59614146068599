// @flow
import React, {Component} from 'react';
import Image from "../../../bundle/Image";
import styles from './index.css'
import {LLMsg} from "../../../IntlCapture";
import SocketIOService from "../../../service/SocketIOService";
import Constant from "../../../bundle/Constant";
import Api from "../../../bundle/Api";
import _ from 'lodash';
import UrlBuilder from "../../../url/UrlBuilder";
import {withRouter} from "react-router";
import LoadingUtil from "../../../util/LoadingUtil";
import VirtualSmartHomeSettingFragment from "../VirtualSmartHomeSettingFragment";

type VirtualLightBulbFragmentState = {
    topicName: string,
    dataKey: string,
    turnOn: string,
    turnOff: string,
    lightBulbStatus: boolean,
    topicId: number,
    topicNameError: string
}

class VirtualLightBulbFragment extends Component<any, VirtualLightBulbFragmentState> {

    socket: {} = {};

    state = {
        lightBulbStatus: false,
        topicId: null,
        topicNameError: ""
    }

    constructor(props: any) {
        super();
    }

    componentWillMount() {
        let topicName = UrlBuilder.getQueryString()["topicName"];
        let dataKey = UrlBuilder.getQueryString()["dataKey"];
        let turnOn = UrlBuilder.getQueryString()["turnOn"];
        let turnOff = UrlBuilder.getQueryString()["turnOff"];
        this.setState({
            topicName: topicName || "",
            dataKey: dataKey || "",
            turnOn: turnOn || "",
            turnOff: turnOff || ""
        });
    }

    componentDidMount() {
        this.setupSocket();
        this.setupSocketEvent();
    }

    setupSocket() {
        let socket: any = SocketIOService.getSocket();

        // exit previous room
        if (this.state.topicId) {
            socket.emit(Constant.socket.data.room.leave, "T" + this.state.topicId);

            this.setState({
                topicId: null
            })
        }

        let topicName = this.state.topicName

        if (topicName.trim() != "") {
            LoadingUtil.showFullScreenLoading();

            // get topic id by topic name
            Api.services.topic.doGetTopicIdByName(topicName).then((response) => {
                LoadingUtil.hideFullScreenLoading();

                if (response.status === 200) {
                    let topicId = response.data.topicId;

                    this.setState({
                        topicId: topicId
                    })

                    // socket join room
                    socket.emit(Constant.socket.data.room.join, "T" + topicId);

                    this.setState({
                        topicNameError: ""
                    })
                }
            }).catch((e) => {
                LoadingUtil.hideFullScreenLoading();
                this.setState({
                    topicNameError: LLMsg("COMMON.TOPIC.TOPIC_NAME_NOT_FOUND")
                })
            })
        }
    }

    setupSocketEvent() {
        let socket: any = SocketIOService.getSocket();

        socket.on(Constant.socket.data.room.newData, (data) => {
            let dataObj = SocketIOService.extractChartDataMessage(data);

            // Update light bulb status
            let status = _.get(dataObj, "records[" + this.state.dataKey + "]", undefined)
            if (status === this.state.turnOn) {
                this.setState({
                    lightBulbStatus: true
                })
            } else if (status === this.state.turnOff) {
                this.setState({
                    lightBulbStatus: false
                })
            }
        });
    }

    onSettingCallback = (topicName: string,
                        dataKey: string,
                        turnOn: string,
                        turnOff: string) => {

        let newStateObj = {
            topicName: topicName,
            dataKey: dataKey,
            turnOn: turnOn,
            turnOff: turnOff
        }

        this.setState(newStateObj, ()=> {
            // update url param
            let queryString = "?" + UrlBuilder.toQueryString(newStateObj);
            this.props.history.push(window.location.pathname + queryString);

            this.setupSocket();
        })
    }

    render() {
        return <div className="row">
            <div className="col-lg-9">
                <div className="m-portlet">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <h3 className="m-portlet__head-text">
                                    {LLMsg("COMMON.VIRTUAL.VIRTUAL_SMART_HOME")} - {LLMsg("COMMON.VIRTUAL.VIRTUAL_LIGHT_BULB")}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body">'
                        <div className="m-section">
                            <div className="m-section__content">
                                <div className="row">
                                    <div className={"col-lg-4 " + styles.light_bulb_image_wrapper}>
                                        <img src={
                                            this.state.lightBulbStatus == true ? Image.urls.getLightBulbOnIcon() : Image.urls.getLightBulbOffIcon()
                                        } className={styles.light_bulb_image}/>
                                    </div>
                                    <div className="col-lg-8">
                                        <VirtualSmartHomeSettingFragment topicName={this.state.topicName}
                                                                         dataKey={this.state.dataKey}
                                                                         turnOn={this.state.turnOn}
                                                                         turnOff={this.state.turnOff}
                                                                         topicNameError={this.state.topicNameError}
                                                                         callback={this.onSettingCallback}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withRouter(VirtualLightBulbFragment);