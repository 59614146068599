// @flow

import React, {Component} from 'react';
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import Api from "../../../bundle/Api";
import LazyLoad, {forceCheck} from 'react-lazyload';

import MappedTopicListFragment from "../../topic/MappedTopicListFragment";
import type {SimpleAction} from "../../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import Page from "../../../bundle/Page";
import ProjectNameFragment from "./ProjectNameFragment";
import LoadingUtil from "../../../util/LoadingUtil";
import ErrorUtil from "../../../util/ErrorUtil";
import type {ChartMessage, Project, Topic} from "../../../model/model";
import _ from 'lodash';
import {RouterHistory} from "../../../AppRoute";
import ProjectDetailMapWrapper from "./ProjectDetailMapWrapper";
import {LLMsg} from "../../../IntlCapture";
import DataTriggerFragment from "../../dataTrigger/DataTriggerFragment";
import OpenApiListFragment from "../../openApi/OpenApiListFragment";
import ProjectOverviewFragment from "./ProjectOverviewFragment";
import ProjectOverviewAiFragment from "../../ai/ProjectOverviewAiFragment";
import UrlBuilder from "../../../url/UrlBuilder";
import {ClassNames} from "../../../bundle/ClassNames";
import {Link} from "react-router-dom";
import AiDetailWrapperFragment from "../../ai/AiDetailWrapperFragment";
import Constant from "../../../bundle/Constant";
import SocketIOService from "../../../service/SocketIOService";
import PubSub from "pubsub-js";
import OpenApiDetailFragment from "../../openApi/OpenApiDetailFragment";
import RelatedProjectChartFragment from "../../chart/RelatedProjectChartFragment";

type ProjectDetailFragmentProps = {
    action?: SimpleAction,
    location?: Object,
    history?: Object,
    match?: Match
};

type ProjectDetailFragmentState = {
    project: Project,
    showProjectInfo: boolean,
    showTopics: boolean,
    showRealTimeRecord: boolean
};

class ProjectDetailFragment extends Component<ProjectDetailFragmentProps, ProjectDetailFragmentState> {

    constructor(props) {
        super(props);

        this.state = {
            project: {},
            showProjectInfo: true,
            showTopics: true,
            showRealTimeRecord: true
        };
        const projectId = this.props.match && this.props.match.params["projectId"];
        this.getProjectDetail(projectId || "");

    }

    getProjectDetail = (projectId: string) => {

        LoadingUtil.showFullScreenLoading();
        Api.services.project.detail(projectId).then((response) => {
            LoadingUtil.hideFullScreenLoading();

            if (response.status === 200) {

                let socket: any = SocketIOService.getSocket();
                let project: Project = response.data;

                project.topics && project.topics.forEach((topic: Topic) => {
                    socket.emit(Constant.socket.data.room.join, "T" + topic.id);
                });

                socket.on(Constant.socket.data.room.newData, (data) => {
                    let dataObj: ChartMessage = SocketIOService.extractChartDataMessage(data);
                    PubSub.publish(Api.actions.topic.TOPIC_NEW_DATA_ACTION_TYPE, dataObj);
                });
                this.setState({project: response.data});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
            RouterHistory().push(Page.internals.urls.project.list());
        });
    };

    componentWillUnmount() {
        this.unsubscribeLiveData();
    }

    unsubscribeLiveData() {

        let socket: any = SocketIOService.getSocket();
        let project: Project = this.state.project;
        project.topics && project.topics.forEach(topic => {
            socket.emit(Constant.socket.data.room.leave, "T" + topic.id);
        });
    }

    componentDidUpdate(prevProps, prevState) {

        const projectId = this.props.match && this.props.match.params["projectId"];
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.project.PROJECT_UPDATED)) {
            this.getProjectDetail(projectId || "");
        }
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.dataType.CREATE_DATA_TYPE_ACTION)) {
            this.getProjectDetail(projectId || "");
        }
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.dataType.UPDATE_DATA_TYPE_ACTION)) {
            this.getProjectDetail(projectId || "");
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.forceCheckLazyLoad();
        }
    }

    getSection() {
        const appletId = this.props.match && this.props.match.params["appletId"];
        let queryObject = UrlBuilder.getQueryString();

        let section = queryObject.section || Constant.projectSection.overall;
        if (appletId) section = Constant.projectSection.eventTrigger;
        return section;
    }

    renderTabs() {

        let project: Project = this.state.project;
        const projectId = project.id;
        const projectName = project.projectName;

        let queryObject = UrlBuilder.getQueryString();
        delete queryObject.ai;
        delete queryObject.openApi;
        const section = this.getSection();

        return <ul className="nav nav-pills nav-fill nav-tabs  m-tabs-line m-tabs-line--2x m-tabs-line--brand mb-0"
                   role="tablist">
            <li className="nav-item m-tabs__item">
                <Link
                    className={ClassNames("nav-link m-tabs__link ", {"show active": section === Constant.projectSection.overall})}
                    to={Page.internals.urls.project.detail(projectId, projectName, {
                        ...queryObject,
                        section: Constant.projectSection.overall
                    })}
                    aria-selected="true">{LLMsg("COMMON.PROJECT.PROJECT_OVERVIEW")}</Link>
            </li>

            <li className="nav-item m-tabs__item">
                <Link
                    className={ClassNames("nav-link m-tabs__link ", {"show active": section === Constant.projectSection.iot})}
                    to={Page.internals.urls.project.detail(projectId, projectName, {
                        ...queryObject,
                        section: Constant.projectSection.iot
                    })}
                    aria-selected="false">
                    {LLMsg("COMMON.PROJECT.IOT")}
                </Link>
            </li>

            <li className="nav-item m-tabs__item">
                <Link
                    className={ClassNames("nav-link m-tabs__link ", {"show active": section === Constant.projectSection.ai})}
                    to={Page.internals.urls.project.detail(projectId, projectName, {
                        ...queryObject,
                        section: Constant.projectSection.ai
                    })}
                    aria-selected="false">
                    {LLMsg("COMMON.PROJECT.AI")}
                </Link>
            </li>

            <li className="nav-item m-tabs__item">
                <Link
                    className={ClassNames("nav-link m-tabs__link ", {"show active": section === Constant.projectSection.chart})}
                    to={Page.internals.urls.project.detail(projectId, projectName, {
                        ...queryObject,
                        section: Constant.projectSection.chart
                    })}
                    aria-selected="false">{LLMsg("COMMON.CHART.CHART")}</Link>
            </li>

            {/*<li className="nav-item m-tabs__item">*/}
            {/*    <Link*/}
            {/*        className={ClassNames("nav-link m-tabs__link ", {"show active": section === Constant.projectSection.openApi})}*/}
            {/*        to={Page.internals.urls.project.detail(projectId, projectName, {*/}
            {/*            ...queryObject,*/}
            {/*            section: Constant.projectSection.openApi*/}
            {/*        })}*/}
            {/*        aria-selected="false">*/}
            {/*        {LLMsg("COMMON.PROJECT.OPEN_API")}*/}
            {/*    </Link>*/}
            {/*</li>*/}

            <li className="nav-item m-tabs__item">
                <Link
                    className={ClassNames("nav-link m-tabs__link ", {"show active": section === Constant.projectSection.eventTrigger})}
                    to={Page.internals.urls.project.detail(projectId, projectName, {
                        ...queryObject,
                        section: Constant.projectSection.eventTrigger
                    })}
                    aria-selected="false">{LLMsg("PERMISSION.EVENT_TRIGGER")}</Link>
            </li>
        </ul>
    }

    forceCheckLazyLoad() {
        forceCheck();
    }

    render() {
        const project: Project = this.state.project || {};
        const section = this.getSection();
        const aiSection = UrlBuilder.getQueryString().ai || "";
        const openApiSection = UrlBuilder.getQueryString().openApi || "";

        Page.updateTitle("PAGE.TITLE.PROJECT_DETAIL", [{
            key: "PROJECT_NAME",
            value: project.projectName
        }]);

        let showProjectMap: boolean = false;
        for (const topic: Topic of _.get(project, "topics", [])) {
            if (topic.latitude && topic.longitude) {
                showProjectMap = true;
                break;
            }
        }
        return <div>
            <div className={"row"}>
                <div className={"col-12 pt-2 pl-2 pr-2 pb-0"}>
                    <div className="m-portlet mb-0">
                        <div className="m-portlet__body">
                            <ProjectNameFragment projectId={project.id} projectName={project.projectName}/>
                        </div>
                    </div>
                </div>
                <div className={"col-12 pt-0 pl-2 pr-2 pb-2"}>
                    <div className="m-portlet mb-0">
                        {this.renderTabs()}
                    </div>
                </div>
            </div>

            <div className="tab-content">
                <div
                    className={ClassNames("tab-pane", {"show active": section === Constant.projectSection.overall})}>
                    {
                        project.id && <LazyLoad><ProjectOverviewFragment project={project}/></LazyLoad>
                    }
                </div>
                <div
                    className={ClassNames("tab-pane", {"show active": section === Constant.projectSection.iot})}>
                    <LazyLoad>
                        <MappedTopicListFragment projectId={project.id}
                                                 project={project}
                                                 topics={project.topics}/>
                    </LazyLoad>
                </div>
                <div
                    className={ClassNames("tab-pane", {"show active": section === Constant.projectSection.ai})}>
                    {
                        project && !aiSection && <LazyLoad>
                            <ProjectOverviewAiFragment project={project}/>
                        </LazyLoad>
                    }
                    {
                        project && aiSection && <LazyLoad>
                            <AiDetailWrapperFragment projectId={project.id}/>
                        </LazyLoad>
                    }
                </div>
                <div
                    className={ClassNames("tab-pane", {"show active": section === Constant.projectSection.chart})}>
                    {project.id && <LazyLoad>
                        {
                            showProjectMap && (
                                <div className={"row"}>
                                    <ProjectDetailMapWrapper
                                        topics={project.topics}
                                        projectId={project.id}
                                        embedMapToken={project.embedMapToken}
                                    />
                                </div>
                            )
                        }
                        <RelatedProjectChartFragment
                            projectId={project.id}/>
                    </LazyLoad>
                    }
                </div>
                <div
                    className={ClassNames("tab-pane", {"show active": section === Constant.projectSection.openApi})}>

                    {
                        !openApiSection && <LazyLoad>
                            <OpenApiListFragment project={this.state.project}/>
                        </LazyLoad>
                    }
                    {
                        openApiSection && <LazyLoad>
                            <OpenApiDetailFragment openApiType={openApiSection} project={this.state.project}/>
                        </LazyLoad>
                    }

                </div>
                <div
                    className={ClassNames("tab-pane", {"show active": section === Constant.projectSection.eventTrigger})}>
                    {
                        project.id && <LazyLoad>
                            <DataTriggerFragment project={project}/>
                        </LazyLoad>
                    }
                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = state => {
    return {
        action: state.ActionReducer.action
    };
};

export default withRouter(connect(mapStateToProps, null)(ProjectDetailFragment));