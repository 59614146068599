exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._9jlMHI2Sgmgli6rFQzTdw {\n}\n\n._9jlMHI2Sgmgli6rFQzTdw > div {\n    background-color: white !important;\n}\n\n._2CFU2bgbOaW37l6lk3er6E {\n    font-size: 1.5em;\n    margin-left: 10px;\n}\n\n._3Bc08br9TYfNQeo1vGRBEu {\n    font-size: 1.2em;\n    display: block;\n    color: #8c8ea4;\n    margin-left: 10px;\n}\n\n._1M_NVg-NTUgZqefMyXoxhP {\n    font-size: 2em;\n}\n\n.lEZ5_Wctnr8i1cLy52lxS {\n\n}\n\n._80Cg_nixsouEyqapucQ5g {\n    cursor: pointer;\n    font-size: 1.5em;\n    color: #585661;\n    border: 3px solid white !important;\n}\n\n._80Cg_nixsouEyqapucQ5g:hover {\n    background-color: #f4f5f8 !important;\n}", ""]);

// exports
exports.locals = {
	"item": "_9jlMHI2Sgmgli6rFQzTdw",
	"itemTitle": "_2CFU2bgbOaW37l6lk3er6E",
	"itemSubtitle": "_3Bc08br9TYfNQeo1vGRBEu",
	"itemNumber": "_1M_NVg-NTUgZqefMyXoxhP",
	"itemButtonWrapper": "lEZ5_Wctnr8i1cLy52lxS",
	"itemButton": "_80Cg_nixsouEyqapucQ5g"
};