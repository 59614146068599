// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../../IntlCapture";
import Api from "../../../../bundle/Api";
import FilterWrapper from "./FilterWrapper";
import SchoolListTable from "./SchoolListTable";
import Constant from "../../../../bundle/Constant";

type SchoolListFragmentState = {
    schools: Array<Object>
}


class SchoolListFragment extends Component<any, SchoolListFragmentState> {

    state = {
        schools: []
    };

    componentDidMount() {
        this.getSchoolList();
    }

    getSchoolList = () => {
        window.mApp.block("#project_detail_fragment", {
            overlayColor: "#000000",
            type: "loader",
            state: "success",
            message: LLMsg("COMMON.ACTION.LOADING_MESSAGE")
        });
        Api.services.school.list().then((response) => {
            window.mApp.unblock("#project_detail_fragment");
            if (response.status === 200) {
                this.setState({schools: response.data});
            }
        }).catch(() => {
            window.mApp.unblock("#project_detail_fragment");
        });
    };

    render() {

        const massagedSchools = this.state.schools && this.state.schools.map((school) => {
            let totalProcessedApplication = 0;
            if (school.schoolApplications) {
                school.schoolApplications.forEach((schoolApplication) => {
                    if (schoolApplication.status !== Constant.school.application.status.pending) {
                        totalProcessedApplication++;
                    }
                });
            }
            return {
                id: school.id,
                schoolName: school.schoolName,
                emailDomain: school.emailDomain,
                validUntil: school.validTo,
                totalProcessedApplication: totalProcessedApplication,
                totalApplication: school.schoolApplications.length
            }
        });

        return <div>
            <div className="m-portlet m-portlet--mobile">
                <div className="m-portlet__head">
                    <div className="m-portlet__head-caption">
                        <div className="m-portlet__head-title">
                            <h3 className="m-portlet__head-text">
                                {LLMsg("COMMON.SCHOOL.SCHOOL_LIST")}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="m-portlet__body">
                    {/*<FilterWrapper/>*/}
                    <SchoolListTable schools={massagedSchools}/>
                </div>
            </div>

        </div>;
    }
}

export default SchoolListFragment;