// @flow
import React, {Component} from 'react';
import Constant from "../../bundle/Constant";
import LineChartFragment from "./LineChartFragment";
import GaugeChartFragment from "./GaugeChartFragment";
import Api from "../../bundle/Api";
import _ from 'lodash';
import ChartDropdownFragment from "./ChartDropdownFragment";
import type {SimpleAction} from "../../redux/reducer";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../IntlCapture";
import type {Chart} from "../../model/model";
import ToggleButtonChartFragment from "./ToggleButtonChartFragment";
import PushButtonChartFragment from "./PushButtonChartFragment";
import PlainTextChartFragment from "./PlainTextChartFragment";
import PointMapChartFragment from "./PointMapChartFragment";
import LazyLoad, {forceCheck} from 'react-lazyload';

type RelatedTopicChartFragmentProps = {
    topicId: string,
    action?: SimpleAction
}
type RelatedTopicChartFragmentState = {
    charts: Array<Chart>
}

class RelatedTopicChartFragment extends Component<RelatedTopicChartFragmentProps, RelatedTopicChartFragmentState> {

    constructor(props: RelatedTopicChartFragmentProps) {
        super(props);
        this.state = {charts: []}
    }

    componentDidMount() {
        this.getRelatedTopicChart(this.props.topicId);
    }

    getRelatedTopicChart(topicId: string) {
        window.mApp.block(".RelatedTopicChartFragment", {
            overlayColor: "#000000",
            type: "loader",
            state: "success",
            message: LLMsg("COMMON.ACTION.LOADING_MESSAGE")
        });
        Api.services.chart.doGetRelatedTopicChart(topicId).then(response => {
            window.mApp.unblock(".RelatedTopicChartFragment");
            if (response.status === 200) {
                this.setState({charts: response.data});
            }
        }).catch(() => {
            window.mApp.unblock(".RelatedTopicChartFragment");
        });
    }

    componentDidUpdate(prevProps: RelatedTopicChartFragmentProps) {

        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.chart.RENAMED_CHART)) {
            this.getRelatedTopicChart(this.props.topicId);
        }

        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.chart.DELETED_CHART)) {
            this.getRelatedTopicChart(this.props.topicId);
        }
    }

    render() {
        let charts = this.state.charts;
        return charts.map((chart: Chart, index) => {
            let chartType = chart.chartType.name;
            let title = chart.name;

            let sizeClass = (chartType === Constant.chart.type.basicLineChart || chartType === Constant.chart.type.pointMap) ? Constant.portlet.size.colWidth.w6 : Constant.portlet.size.colWidth.w3;
            return <div className={sizeClass + " RelatedTopicChartFragment"} key={index}>
                <div className="m-portlet mb-0">
                    <div className="m-portlet__body">
                        <div className={"portlet_right_top_buttons "}>
                            <ChartDropdownFragment isDefaultChart={chart.isDefaultChart} chartId={chart.id}
                                                   chartType={chartType} chartName={title}/>
                        </div>
                        <LazyLoad>
                            {
                                chartType === Constant.chart.type.basicLineChart &&
                                <LineChartFragment title={title} chartId={chart.id}
                                                   aggregate={null} endTime={null}
                                                   interval={null} isLive={true} startTime={null}
                                                   config={chart.chartConfig}
                                                   sources={chart.chartSources}/>
                            }
                            {
                                chartType === Constant.chart.type.gauge &&
                                <GaugeChartFragment title={title} chartId={chart.id}
                                                    config={chart.chartConfig}
                                                    sources={chart.chartSources}/>
                            }
                            {
                                chartType === Constant.chart.type.plainTextChart &&
                                <PlainTextChartFragment title={title} chartId={chart.id}
                                                        config={chart.chartConfig}
                                                        sources={chart.chartSources}/>
                            }
                            {
                                chartType === Constant.chart.type.toggleButton &&
                                <ToggleButtonChartFragment title={title} chartId={chart.id}
                                                           config={chart.chartConfig}
                                                           sources={chart.chartSources}/>
                            }
                            {
                                chartType === Constant.chart.type.pushButton &&
                                <PushButtonChartFragment title={title} chartId={chart.id}
                                                         config={chart.chartConfig}
                                                         sources={chart.chartSources}/>
                            }
                            {
                                chartType === Constant.chart.type.pointMap &&
                                <PointMapChartFragment title={title} chartId={chart.id}
                                                       config={chart.chartConfig}
                                                       sources={chart.chartSources}/>
                            }
                        </LazyLoad>
                    </div>
                </div>
            </div>;
        });
    }
}

const mapStateToProps = state => {
    return {
        action: state.ActionReducer.action
    };
};
export default withRouter(connect(mapStateToProps, null)(RelatedTopicChartFragment));
