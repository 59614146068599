// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../../IntlCapture";
import styles from "../../eventCode/EventCodeDetailFragment/index.css";
import type {LicenseDetail, LicenseGenerationBatch} from "../../../../model/model";
import Api from "../../../../bundle/Api";
import ErrorUtil from "../../../../util/ErrorUtil";
import LoadingUtil from "../../../../util/LoadingUtil";
import {withRouter} from "react-router";
import TimeUtil from "../../../../util/TimeUtil";

type LicenseBatchDetailFragmentState = {
    licenseBatch: ? LicenseGenerationBatch
}


type LicenseBatchDetailFragmentProps = {
    match?: Object,
    location?: Object,
    history?: Object
}

class LicenseBatchDetailFragment extends Component<LicenseBatchDetailFragmentProps, LicenseBatchDetailFragmentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            licenseBatch: null
        }
    }


    constructItem(title: any, content: any) {
        return <div className={styles.row}>
            <div className={styles.title}>
                {title}
            </div>
            <div
                className={styles.content}>
                {content}
            </div>
        </div>
    }

    componentDidMount() {
        this.getLicenseList();
    }

    getLicenseList() {
        const licenseBatchId = this.props.match && this.props.match.params["licenseBatchId"];

        LoadingUtil.showFullScreenLoading();
        Api.services.license.getLicenseBatchDetail(licenseBatchId || "").then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({licenseBatch: response.data});
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    }


    render() {

        const licenseBatch: LicenseGenerationBatch = this.state.licenseBatch || {};

        let status = "";
        if (licenseBatch.startGenerationTime && licenseBatch.completeGenerationTime) {
            status = "Completed"
        } else if (!licenseBatch.startGenerationTime && !licenseBatch.completeGenerationTime) {
            status = "Pending"
        } else if (licenseBatch.startGenerationTime && !licenseBatch.completeGenerationTime) {
            status = "Generating"
        }
        return <div className="m-portlet">
            <div className="m-portlet__body">
                <div className="m-section">
                    {this.constructItem(("ID"), licenseBatch.id)}
                    {this.constructItem(("Max Consumption"), licenseBatch.maxConsumptionCount)}
                    {this.constructItem(("Max Concurrent Access"), licenseBatch.maxConcurrentCount)}
                    {this.constructItem(("Number of License"), licenseBatch.licenseCount)}
                    {this.constructItem(("Target Name"), licenseBatch.licenseTarget && licenseBatch.licenseTarget.targetName)}
                    {this.constructItem(("Start Time"), TimeUtil.convertTimeFormat(licenseBatch.startGenerationTime, TimeUtil.FULL_DATE_DISPLAY_FORMAT))}
                    {this.constructItem(("Complete Time"), TimeUtil.convertTimeFormat(licenseBatch.completeGenerationTime, TimeUtil.FULL_DATE_DISPLAY_FORMAT))}
                    {this.constructItem(("Generation Status"), status)}
                    {licenseBatch.licenseDocFilename && this.constructItem(("License File"),
                        <button type={"button"}
                                className={"btn btn-secondary m-btn m-btn--custom m-btn--label-success m-btn--bolder " + licenseBatch.filename}
                                onClick={() => {
                                    Api.services.license.downloadLicenseFile(licenseBatch.licenseDocFilename)
                                }}>Download here</button>
                    )}
                    <hr/>
                    {this.constructItem((""),
                        <div>
                            <div>
                                <span className={styles.licenseIndex}>
                                    <strong>{"#"}</strong>
                                    </span>
                                <span className={styles.licenseKey}>
                                    <strong>{"License Key"}</strong>
                                </span>
                                <span className={styles.licenseDate}>
                                    <strong>{"Start Date"}</strong>
                                </span>
                                <span className={styles.licenseDate}>
                                    <strong>{"End Date"}</strong>
                                </span>
                            </div>
                            {
                                licenseBatch.licenses && licenseBatch.licenses.map((license, index) => {
                                    return <div key={license.licenseKey}>
                                        <span className={styles.licenseIndex}>{index + 1}</span>
                                        <span className={styles.licenseKey}>{license.licenseKey}</span>
                                        <span className={styles.licenseDate}>
                                            {license.trialStartDate ? TimeUtil.convertTimeFormat(license.trialStartDate, TimeUtil.FULL_DATE_DISPLAY_FORMAT) : "N/A"}
                                        </span>
                                        <span className={styles.licenseDate}>
                                            {license.trialEndDate ? TimeUtil.convertTimeFormat(license.trialEndDate, TimeUtil.FULL_DATE_DISPLAY_FORMAT) : "N/A"}
                                        </span>
                                    </div>
                                })
                            }
                        </div>
                    )}

                </div>
            </div>
        </div>;
    }
}

export default withRouter(LicenseBatchDetailFragment);