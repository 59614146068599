exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2k1Pfw-ZP7VNuQdhFECr7V {\n    color: red !important;\n    border-color: red;\n}", ""]);

// exports
exports.locals = {
	"errorInput": "_2k1Pfw-ZP7VNuQdhFECr7V"
};