exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2mJq-TWQk_Y4AmfzVk4euc {\n    margin-bottom: 50px;\n}\n\n._3OJkPWbANCKTy1_0y3OIpb:hover {\n    transform: scale(1.01);\n}\n\n._3OtM3XWXLYx0-BiqJVKQYf {\n    -moz-user-select: none;\n    -webkit-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    -o-user-select: none;\n}\n\n._3OJkPWbANCKTy1_0y3OIpb {\n    display: inline-block;\n    box-shadow: 0 0 36px rgba(0, 0, 0, 0.1) !important;\n    cursor: pointer;\n    background-color: white;\n}\n\n._3VANDVokslbvKF67WkYBYN {\n    border-top: solid 1px #dadada;\n    padding: 40px;\n    min-height: 200px;\n    color: #425066;\n\n}\n\n._1_C-ozP9DKpP2fUiWyWfBI {\n    display: block;\n    font-size: 22px;\n    font-weight: bold;\n    line-height: 26px;\n    margin-bottom: 20px;\n}\n\n._37amaCLqUFHYWkztlAHRfm {\n    display: block;\n    font-size: 14px;\n    line-height: 22px;\n    margin-bottom: 20px;\n}\n\n.pgfen-b0cb9EOV-GIZr0q {\n\n}\n\n._1II_5FkK4dfUGEYhYjAXuT {\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    width: 200px;\n    padding: 50px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_2mJq-TWQk_Y4AmfzVk4euc",
	"itemInner": "_3OJkPWbANCKTy1_0y3OIpb",
	"item": "_3OtM3XWXLYx0-BiqJVKQYf",
	"itemContent": "_3VANDVokslbvKF67WkYBYN",
	"title": "_1_C-ozP9DKpP2fUiWyWfBI",
	"desc": "_37amaCLqUFHYWkztlAHRfm",
	"itemImageBox": "pgfen-b0cb9EOV-GIZr0q",
	"itemImage": "_1II_5FkK4dfUGEYhYjAXuT"
};