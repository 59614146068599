// @flow

import React, {Component} from 'react';


class LicenseListTableHeader extends Component<any, any> {

    render() {
        return <thead className="m-datatable__head">
        <tr className="m-datatable__row" style={{left: '0px'}}>
            <th className="m-datatable__cell m-datatable__toggle-detail m-datatable__cell--sort">
                <span style={{width: '21px'}}/>
            </th>
            <th className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '50px'}}>
                    {"ID"}
               </span>
            </th>
            <th className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '100px'}}>
                    {"Package Name"}
               </span>
            </th>
            <th className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '100px'}}>
                    {"License Count"}
               </span>
            </th>
            <th className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '100px'}}>
                    {"Trial Period"}
               </span>
            </th>
            <th className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '120px'}}>
                    {"Max Consumption"}
               </span>
            </th>
            <th className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '140px'}}>
                    {"Max Concurrent Login"}
               </span>
            </th>
            <th className="m-datatable__cell m-datatable__cell--sort">
                <span style={{width: '100px'}}>
                    {"Status"}
               </span>
            </th>
        </tr>
        </thead>;
    }
}

export default LicenseListTableHeader;