// @flow

import React from 'react';
import styles from './index.css';
import UrlBuilder from "../../../url/UrlBuilder";
import Constant from "../../../bundle/Constant";
import ImageStyleTransferFragment from "./ImageStyleTransferFragment";
import image from './../ProjectOverviewAiFragment/image.svg';
import PoseCaptureFragment from "./PoseCaptureFragment";
import FaceDetectionFragment from "./FaceDetectionFragment";
import ImageClassificationFragment from "./ImageClassificationFragment";
import ObjectDetectionFragment from "./ObjectDetectionFragment";
import AudioClassificationFragment from "./AudioClassificationFragment";
import {LLMsg} from "../../../IntlCapture";

type Props = {
    projectId: string
}
export default class AiDetailWrapperFragment extends React.PureComponent<Props, any> {

    render() {

        const aiSection = UrlBuilder.getQueryString().ai || "";
        return (
            <div className={"row"}>
                <div className={"col-12 p-2"}>
                    <div className="m-portlet mb-0">
                        <div className={"m-portlet__body"}>
                            <div className={styles.wrapper}>
                                <div className={styles.header}>
                                    <img alt="" className={styles.headerTitleImage} src={image}/>

                                    {aiSection === Constant.aiSection.imageStyleTransfer &&
                                    <span>{LLMsg("AI.IMAGE_STYLE_TRANSFER_TITLE")}</span>}

                                    {/*{aiSection === Constant.aiSection.imageClassification &&*/}
                                    {/*<span>{LLMsg("AI.IMAGE_CLASSIFICATION_TITLE")}</span>}*/}

                                    {/*{aiSection === Constant.aiSection.audioClassification &&*/}
                                    {/*<span>{LLMsg("AI.AUDIO_CLASSIFICATION_TITLE")}</span>}*/}

                                    {/*{aiSection === Constant.aiSection.poseCapture &&*/}
                                    {/*<span>{LLMsg("AI.POSE_CAPTURE_TITLE")}</span>}*/}

                                    {aiSection === Constant.aiSection.faceDetection &&
                                    <span>{LLMsg("AI.FACE_DETECTION_TITLE")}</span>}

                                    {aiSection === Constant.aiSection.imageRecognition &&
                                    <span>{LLMsg("AI.IMAGE_RECOGNITION_TITLE")}</span>}

                                </div>
                                <div className={styles.content}>
                                    {aiSection === Constant.aiSection.imageStyleTransfer &&
                                    <ImageStyleTransferFragment/>}
                                    {/*{aiSection === Constant.aiSection.imageClassification &&*/}
                                    {/*<ImageClassificationFragment/>}*/}
                                    {/*{aiSection === Constant.aiSection.audioClassification &&*/}
                                    {/*<AudioClassificationFragment/>}*/}
                                    {/*{aiSection === Constant.aiSection.poseCapture && <PoseCaptureFragment/>}*/}
                                    {aiSection === Constant.aiSection.faceDetection && <FaceDetectionFragment projectId={this.props.projectId}/>}
                                    {aiSection === Constant.aiSection.imageRecognition &&
                                    <ObjectDetectionFragment projectId={this.props.projectId}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}