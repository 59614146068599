exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36P26k4dces98LyPNk6p9o {\n    height: 100%;\n    padding: 2.2rem 2.2rem;\n}\n\n._31Z13tmZp83TVYinPUD4XS {\n    opacity: 0.3;\n    margin-right: 4px;\n    position:absolute;\n    bottom:0;\n    right:0;\n}\n\n._31Z13tmZp83TVYinPUD4XS:hover {\n    opacity: 1;\n}", ""]);

// exports
exports.locals = {
	"mapWrapper": "_36P26k4dces98LyPNk6p9o",
	"watermark": "_31Z13tmZp83TVYinPUD4XS"
};