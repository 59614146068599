// @flow

import React, {Component} from 'react';
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import Constant from "../../../bundle/Constant";
import Api from "../../../bundle/Api";
import type {SimpleAction} from "../../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import LineChartFragment from "../LineChartFragment";
import GaugeChartFragment from "../GaugeChartFragment";
import _ from "lodash";
import Page from "../../../bundle/Page";
import AddChartToAnyDashboardButton from "./AddChartToAnyDashboardButton";
import DeleteChartButton from "./DeleteChartButton";
import RenameChartButton from "./RenameChartButton";
import {LLMsg} from "../../../IntlCapture";
import ChartSourceFragment from "./ChartSourceFragment";
import PlainTextChartFragment from "../PlainTextChartFragment";
import type {Chart} from "../../../model/model";
import ToggleButtonChartFragment from "../ToggleButtonChartFragment";
import PushButtonChartFragment from "../PushButtonChartFragment";
import LoadingUtil from "../../../util/LoadingUtil";
import ChartHistoryDataFragment from "./ChartHistoryDataFragment";
import CopyChartEmbedUrlButtonFragment from "./CopyChartEmbedUrlButtonFragment";
import PointMapChartFragment from "../PointMapChartFragment";
import UserDao from "../../../dao/UserDao";

type ChartDetailFragmentProps = {
    action?: SimpleAction,
    location?: Object,
    history?: Object,
    match?: Match
};

type ChartDetailFragmentState = {
    chart: Chart,
    startTime: string,
    endTime: string,
    interval: string,
    aggregate: string,
    isLive: boolean
};

class ChartDetailFragment extends Component<ChartDetailFragmentProps, ChartDetailFragmentState> {

    chartNameRef: any;
    colorRef: any;
    onValueRef: any;
    offValueRef: any;
    minValueRef: any;
    maxValueRef: any;
    outputTypeRef: any;
    displayTextRef: any;

    constructor(props) {
        super(props);
        this.state = {chart: null, isLive: true, startTime: "", endTime: "", interval: "", aggregate: ""};
        const chartId = this.props.match && this.props.match.params["chartId"];
        this.getChartDetail(chartId || "");
    }

    showLiveData = () => {
        this.setState({
            isLive: true,
            startTime: undefined,
            endTime: undefined,
            interval: undefined,
            aggregate: undefined
        });
    };


    searchHistoryData = (startTime: string, endTime: string, interval: string, aggregate: string) => {
        console.log(startTime, endTime, interval, aggregate);
        this.setState({
            isLive: false,
            startTime: startTime,
            endTime: endTime,
            interval: interval,
            aggregate: aggregate
        });
    };


    getChartDetail = (chartId: string) => {

        LoadingUtil.showFullScreenLoading();
        Api.services.chart.get.detail(chartId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({chart: response.data});
            }
        }).catch(() => {
            LoadingUtil.hideFullScreenLoading();
        });
    };

    componentDidUpdate(prevProps, prevState) {

        const chartId = this.props.match && this.props.match.params["chartId"];
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.chart.RENAMED_CHART)) {
            this.getChartDetail(chartId || "");
        }
        if (Api.actions.detectChange(prevProps.action, this.props.action, Api.actions.chart.DELETED_CHART)) {
            this.props.history && this.props.history.push(Page.internals.urls.misc.home());
        }
    }

    render() {
        let chart: Chart = this.state.chart;
        if (!chart || !chart.id) {
            return <div/>;
        }
        let editMode = false;
        let chartType = _.get(this.state, "chart.chartType.name", "");
        let title = chart.name;

        let chartConfig = chart.chartConfig || {};

        Page.updateTitle("PAGE.TITLE.CHART_DETAIL", [{
            key: "CHART_NAME",
            value: title
        }]);
        return <div className={Constant.portlet.size.colWidth.w12 + ""}>
            <div className="m-portlet mb-0">

                <div className="m-stack m-stack--ver m-stack--general pt-5 pb-2 pl-5 pr-5">
                    <div className="m-stack__item m-stack__item--middle m-stack__item--fluid" style={{width: 300}}>
                        <h3>{LLMsg("COMMON.CHART.CHART")}: {title}
                            <RenameChartButton chartId={chart.id}
                                               chartName={title}/>

                        </h3>
                    </div>
                    <div className="m-stack__item m-stack__item--right m-stack__item--middle">
                        {
                            UserDao.permission.allowEmbedChart && <CopyChartEmbedUrlButtonFragment
                                text={Page.convertToFullPath(Page.internals.urls.chart.getEmbeddedChartUrl(chart.shareToken))}/>
                        }
                        <AddChartToAnyDashboardButton chartId={chart.id} chartType={chartType}/>
                        {
                            !chart.isDefaultChart && <DeleteChartButton chartId={chart.id}/>
                        }

                    </div>
                </div>
                <div className={"pt-2 pb-5 pl-5 pr-5"}>
                    <hr/>

                    <div className="form-group m-form__group row">
                        <label className="col-12 col-lg-2 col-md-2 col-form-label">
                            {LLMsg("COMMON.CHART.SELECTED_CHART_SOURCE")}
                        </label>
                        <div className="col-10">
                            <ChartSourceFragment chartType={chartType}
                                                 sources={chart.chartSources}/>
                        </div>
                    </div>
                    <div className="form-group m-form__group row">
                        <label htmlFor="example-text-input"
                               className="col-2 col-form-label">
                            {LLMsg("COMMON.CHART.CHART_NAME")}
                        </label>
                        <div className="col-10">
                            <input className="form-control m-input" type="text"
                                   defaultValue={chart.name}
                                   readOnly={true}
                                   ref={(ref) => {
                                       this.chartNameRef = ref
                                   }}/>
                        </div>
                    </div>

                    {
                        chartType === Constant.chart.type.pushButton && <React.Fragment>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.BUTTON_ACTIVE_VALUE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           defaultValue={chartConfig.onValue}
                                           readOnly={true}
                                           ref={(ref) => {
                                               this.onValueRef = ref
                                           }}/>
                                </div>
                            </div>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.OUTPUT_TYPE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           defaultValue={chartConfig.outputType}
                                           readOnly={true}
                                           ref={(ref) => {
                                               this.outputTypeRef = ref
                                           }}/>
                                </div>
                            </div>
                        </React.Fragment>

                    }
                    {
                        chartConfig.color &&
                        <div className="form-group m-form__group row">
                            <label htmlFor="example-text-input"
                                   className="col-2 col-form-label">
                                {LLMsg("COMMON.CHART.CHART_COLOR")}
                            </label>
                            <div className="col-10">
                                <i className="fa fa-square-full m--icon-font-size-lg5 mt-2 ml-1"
                                   title={chartConfig.color}
                                   style={{color: chartConfig.color}}/>
                                {
                                    editMode && <input
                                        className="form-control m-input"
                                        type="color"
                                        defaultValue={chartConfig.color}
                                        readOnly={true}
                                        ref={(ref) => {
                                            this.colorRef = ref
                                        }}/>
                                }
                            </div>
                        </div>
                    }
                    {
                        chartType === Constant.chart.type.toggleButton && <React.Fragment>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.BUTTON_ACTIVE_VALUE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           defaultValue={chartConfig.onValue}
                                           readOnly={true}
                                           ref={(ref) => {
                                               this.onValueRef = ref
                                           }}/>
                                </div>
                            </div>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.BUTTON_INACTIVE_VALUE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           defaultValue={chartConfig.offValue}
                                           readOnly={true}
                                           ref={(ref) => {
                                               this.offValueRef = ref
                                           }}/>
                                </div>
                            </div>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.OUTPUT_TYPE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           defaultValue={chartConfig.outputType}
                                           readOnly={true}
                                           ref={(ref) => {
                                               this.outputTypeRef = ref
                                           }}/>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {
                        chartType === Constant.chart.type.gauge && <React.Fragment>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.CHART_MIN_VALUE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           defaultValue={chartConfig.minValue}
                                           readOnly={true}
                                           ref={(ref) => {
                                               this.minValueRef = ref
                                           }}/>
                                </div>
                            </div>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.CHART_MAX_VALUE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           readOnly={true}
                                           defaultValue={chartConfig.maxValue}
                                           ref={(ref) => {
                                               this.maxValueRef = ref
                                           }}/>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {
                        chartType === Constant.chart.type.pointMap && <React.Fragment>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.CHART_MIN_VALUE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           defaultValue={chartConfig.minValue}
                                           readOnly={true}
                                           ref={(ref) => {
                                               this.minValueRef = ref
                                           }}/>
                                </div>
                            </div>
                            <div className="form-group m-form__group row">
                                <label htmlFor="example-text-input"
                                       className="col-2 col-form-label">
                                    {LLMsg("COMMON.CHART.CHART_MAX_VALUE")}
                                </label>
                                <div className="col-10">
                                    <input className="form-control m-input" type="text"
                                           readOnly={true}
                                           defaultValue={chartConfig.maxValue}
                                           ref={(ref) => {
                                               this.maxValueRef = ref
                                           }}/>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

                {
                    chartType === Constant.chart.type.basicLineChart &&
                    <ChartHistoryDataFragment chartType={chartType}
                                              searchHistoryData={this.searchHistoryData}
                                              showLiveData={this.showLiveData}/>
                }
                <div className=" m-portlet__body portlet_right_top_hover_trigger">
                    {
                        chartType === Constant.chart.type.basicLineChart &&
                        <LineChartFragment title={""} chartId={chart.id}
                                           isDetailMode={true}
                                           config={chartConfig}
                                           sizingClass={"portlet_height_2x"}
                                           sources={chart.chartSources}
                                           aggregate={this.state.aggregate}
                                           endTime={this.state.endTime}
                                           interval={this.state.interval}
                                           isLive={this.state.isLive}
                                           startTime={this.state.startTime}/>
                    }
                    {
                        chartType === Constant.chart.type.gauge &&
                        <GaugeChartFragment title={""} chartId={chart.id}
                                            config={chartConfig}
                                            sources={chart.chartSources}/>
                    }
                    {
                        chartType === Constant.chart.type.plainTextChart &&
                        <PlainTextChartFragment title={chart.name}
                                                chartId={chart.id}
                                                config={chartConfig}
                                                sources={chart.chartSources}/>
                    }
                    {
                        chartType === Constant.chart.type.toggleButton &&
                        <ToggleButtonChartFragment title={""}
                                                   chartId={chart.id}
                                                   config={chartConfig}
                                                   sources={chart.chartSources}/>
                    }

                    {
                        chartType === Constant.chart.type.pushButton &&
                        <PushButtonChartFragment title={""}
                                                 chartId={chart.id}
                                                 config={chartConfig}
                                                 sources={chart.chartSources}/>
                    }

                    {
                        chartType === Constant.chart.type.pointMap &&
                        <PointMapChartFragment title={""}
                                               chartId={chart.id}
                                               config={chartConfig}
                                               sources={chart.chartSources}
                                               sizingClass={"portlet_height_2x"}/>
                    }

                </div>
            </div>
        </div>
            ;
    }
}


const mapStateToProps = state => {
    return {
        action: state.ActionReducer.action
    };
};

export default withRouter(connect(mapStateToProps, null)(ChartDetailFragment));