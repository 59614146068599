// @flow


import React from "react";
import Constant from "../../../../bundle/Constant";
import {LLMsg} from "../../../../IntlCapture";
import ChartTypeItem from "./ChartTypeItem";
import UserDao from "../../../../dao/UserDao";

type ChartTypeSelectFragmentProps = {
    show: boolean
}

class ChartTypeSelectFragment extends React.Component<ChartTypeSelectFragmentProps, any> {


    render() {

        return <div style={{display: this.props.show ? "block" : "none"}}>
            <h3>
                <strong>{LLMsg("COMMON.STEP.STEP_1")} - </strong>
                <span>{LLMsg("COMMON.CHART.CHOOSE_CHART_TYPE")}</span>
            </h3>
            <hr/>

            <h4 className={"mt-4"}>{LLMsg("COMMON.CHART.CHART")}</h4>
            <div className="row">
                <ChartTypeItem chartType={Constant.chart.type.basicLineChart}
                               defaultChecked={true}
                               inputName={"createChart_typeSelect"}/>

                <ChartTypeItem chartType={Constant.chart.type.gauge}
                               defaultChecked={false}
                               inputName={"createChart_typeSelect"}/>

                <ChartTypeItem chartType={Constant.chart.type.plainTextChart}
                               defaultChecked={false}
                               inputName={"createChart_typeSelect"}/>

                <ChartTypeItem chartType={Constant.chart.type.pointMap}
                               defaultChecked={false}
                               disabled={!UserDao.permission.allowCirclePointMap}
                               inputName={"createChart_typeSelect"}/>

            </div>
            <hr/>

            <h4 className={"mt-4"}>{LLMsg("COMMON.CHART.CONTROL_BUTTON")}</h4>
            <div className="row">
                <ChartTypeItem chartType={Constant.chart.type.pushButton}
                               defaultChecked={false}
                               inputName={"createChart_typeSelect"}/>

                <ChartTypeItem chartType={Constant.chart.type.toggleButton}
                               defaultChecked={false}
                               inputName={"createChart_typeSelect"}/>
            </div>
            <hr/>
        </div>
    }
};


export default ChartTypeSelectFragment;