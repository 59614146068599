// @flow

import React from "react";
import {LLMsg} from "../../../IntlCapture";

const TopicListTableNoRecord = () => {

    return <tr role="row" className="odd">
        <td colSpan={6}><div className="m-datatable--error m--icon-font-size-lg1  text-center">
            {LLMsg("COMMON.ACTION.NO_RECORD")}
        </div></td>
    </tr>;
};

export default TopicListTableNoRecord;