// @flow

import React from 'react';
import {createAndLinkTopicToProjectPrompt} from "../../topic/CreateAndLinkTopicToProjectButton";
import styles from './ProjectDetailActionBar.css';
import {ClassNames} from "../../../bundle/ClassNames";
import {LLMsg} from "../../../IntlCapture";
import Image from "../../../bundle/Image";
import Page from "../../../bundle/Page";
import {RouterHistory} from "../../../AppRoute";
import {createAction} from "../../../redux/reducer";
import Api from "../../../bundle/Api";
import {withRouter} from "react-router";
import connect from "react-redux/lib/connect/connect";
import UserDao from "../../../dao/UserDao";
import ErrorUtil from "../../../util/ErrorUtil";
import {shareProjectPrompt} from "../ShareProjectButton";
import TopicMessageSendingToolFragment from "../../topic/TopicMessageSendingToolFragment";

type ProjectDetailActionBarProps = {
    dispatchTopicInProjectUpdated?: () => void,
    projectId: string,
    projectName: string,
    showing: boolean
};

class ProjectDetailActionBar extends React.Component<ProjectDetailActionBarProps, any> {

    renderButton(src: any, label: string, onClick: Function) {
        return <div className={ClassNames(styles.button, "col-4")} onClick={onClick}>
            <div className={styles.buttonText}>
                <img src={src}
                     className={styles.buttonIcon}/>
            </div>
            <div className={styles.buttonText}>
                {label}
            </div>
        </div>;
    }

    render() {
        return <div className={ClassNames("m-portlet mb-0", styles.wrapper)}>
            <div className="m-portlet__body">
                <div className={"row"}>
                    {
                        this.renderButton(
                            Image.urls.getCreateChartIcon(),
                            LLMsg("COMMON.CHART.CREATE_CHART"),
                            () => {
                                RouterHistory().push(Page.internals.urls.chart.getChartCreatePageUrl());
                            }
                        )
                    }
                    {
                        this.renderButton(
                            Image.urls.getCreateTopicIcon(),
                            LLMsg("COMMON.TOPIC.CREATE_TOPIC"),
                            () => {
                                createAndLinkTopicToProjectPrompt(this.props.projectId, this.props.dispatchTopicInProjectUpdated);
                            }
                        )
                    }
                    {
                        this.renderButton(
                            Image.urls.getShareProjectIcon(),
                            LLMsg("COMMON.ACTION.SHARE"),
                            () => {
                                if (UserDao.permission.allowShareProject) {
                                    shareProjectPrompt(this.props.projectId, this.props.projectName);
                                } else {
                                    ErrorUtil.showPermissionNotAllowed();
                                }
                            }
                        )
                    }
                    <TopicMessageSendingToolFragment>
                        {
                            this.renderButton(
                                Image.urls.getEventTriggerIcon(),
                                LLMsg("EVENT_TRIGGER.PUBLISH_MQTT_MESSAGE"),
                                undefined
                            )
                        }
                    </TopicMessageSendingToolFragment>

                    {
                        this.renderButton(
                            Image.urls.getExportProjectDataIcon(),
                            LLMsg("COMMON.PROJECT.DOWNLOAD_DATA_UNDER_PROJECT_TAB"),
                            () => {
                                if (UserDao.permission.allowExportData) {
                                    Api.services.record.download.dataRecordByProjectId(this.props.projectId, this.props.projectName);
                                } else {
                                    ErrorUtil.showPermissionNotAllowed();
                                }
                            }
                        )
                    }
                    {
                        this.renderButton(
                            Image.urls.getProjectFileIcon(),
                            LLMsg("COMMON.PROJECT.MY_FILES"),
                            () => {
                                window.scrollTo(0, document.body.scrollHeight);
                            }
                        )
                    }
                </div>
            </div>
        </div>;
    }
}

const mapDispatchToProps = dispatch => ({
    dispatchTopicInProjectUpdated: () => dispatch(createAction(Api.actions.project.PROJECT_UPDATED))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(ProjectDetailActionBar));