exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1rV8K3mrohp43vhMNLa0GN {\n    height: 100%;\n    min-height: 100%;\n}\n\n._1RQ-znR9nkPUYqduJnh-1J {\n    height: 175px;\n    min-height: 175px;\n    max-height: 175px;\n}\n\n.-c4-GV_vmCfr1_pk8L-Qe {\n    cursor: pointer;\n    display: inline-block;\n    padding: 5px 10px !important;\n    font-size: 16px;\n    background-color: #4e73df;\n    color: white;\n    margin-right: 5px;\n}\n\n._3IfDz2eGEnFWI0zfZxnw_r {\n    margin-bottom: 20px;\n}\n\n._1xYzlcmmqqtwGGrdRNBciu {\n    /*margin-left: 15px;*/\n    text-align: center;\n    border-right: 1px solid lightgrey;\n}\n\n._1xYzlcmmqqtwGGrdRNBciu:nth-last-child(1) {\n    border-right: none;\n}\n\n._3gzqUhE1-00xvAARRNOy1w {\n    display: block;\n    font-weight: bolder;\n    font-size: 1.5em;\n}\n\n.YPjpakY9fue3viMZnmES0._2IPVWQ-7MwG7cr1fYFVSQY .jzl5bttumTdQwq0y0xNWM {\n    color: #4e73df;\n\n}\n\n.YPjpakY9fue3viMZnmES0._1Zhvbj0RGOcyHprHDiw8LC .jzl5bttumTdQwq0y0xNWM {\n    color: #f63e78;\n}\n\n.YPjpakY9fue3viMZnmES0.nepPTrl3HMIsgDQx6purG .jzl5bttumTdQwq0y0xNWM {\n    color: #faa040;\n}\n\n.jzl5bttumTdQwq0y0xNWM {\n    font-weight: bold;\n    font-size: 48px;\n    display: block;\n}\n\n._2yxferM0LtCp-RDfeDIKJX {\n    font-size: 26px;\n    margin-bottom: 13px;\n}\n\n\n._2ikJcP5_2vH60gmPo78svX {\n    background-color: white;\n    text-align: center;\n    cursor: pointer;\n    padding-bottom: 22px;\n    padding-top: 23px;\n}\n\n._2xONALqXkgFiepRLvFMPOT {\n\n    font-weight: bolder;\n    display: block;\n    font-size: 1.5em;\n    margin-top: 10px;\n}\n\n._1RvUlWT56alTSVUmWVBts3 {\n    width: 60px;\n    margin-left: 10px;\n}", ""]);

// exports
exports.locals = {
	"fullHeight": "_1rV8K3mrohp43vhMNLa0GN",
	"sectionHeight": "_1RQ-znR9nkPUYqduJnh-1J",
	"topicShortcut": "-c4-GV_vmCfr1_pk8L-Qe",
	"statsItem": "_3IfDz2eGEnFWI0zfZxnw_r",
	"stats": "_1xYzlcmmqqtwGGrdRNBciu",
	"statsTitle": "_3gzqUhE1-00xvAARRNOy1w",
	"statsSection": "YPjpakY9fue3viMZnmES0",
	"style1": "_2IPVWQ-7MwG7cr1fYFVSQY",
	"statsValue": "jzl5bttumTdQwq0y0xNWM",
	"style2": "_1Zhvbj0RGOcyHprHDiw8LC",
	"style3": "nepPTrl3HMIsgDQx6purG",
	"statsSectionTitle": "_2yxferM0LtCp-RDfeDIKJX",
	"button": "_2ikJcP5_2vH60gmPo78svX",
	"buttonText": "_2xONALqXkgFiepRLvFMPOT",
	"buttonIcon": "_1RvUlWT56alTSVUmWVBts3"
};