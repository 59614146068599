// @flow

import React, {Component} from 'react';
import styles from './SchoolInfoItem.css';

type SchoolInfoItemProps = {
    label: string,
    value: any
}

class SchoolInfoItem extends Component<SchoolInfoItemProps, any> {

    render() {
        const {label, value} = this.props;
        return <div className={"m-stack m-stack--ver m-stack--general " + styles.item}>
            <div className={"m-stack__item m-stack__item--right m-stack__item--middle " + styles.itemLabel}>
                {label}
            </div>
            <div className={"m-stack__item  m-stack__item--left m-stack__item--middle m-stack__item--fluid " + styles.itemValue}>
                {value}
            </div>
        </div>
    }
}

export default SchoolInfoItem;