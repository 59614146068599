// @flow

import React from 'react';
import _ from 'lodash';
import type {Project, Topic} from "../../model/model";
import styles from './MappedTopicListFragment.css';
import image from './no_topic_image.svg';
import CreateAndLinkTopicToProjectButton from "./CreateAndLinkTopicToProjectButton";
import TopicOverviewStatsFragment from "./TopicOverviewStatsFragment";
import MappedTopicItem from "./MappedTopicItem";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {ClassNames} from "../../bundle/ClassNames";

type MappedTopicListFragmentProps = {
    project: Project,
    projectId: string,
}

class MappedTopicListFragment extends React.PureComponent <MappedTopicListFragmentProps, any> {

    constructor(props: MappedTopicListFragmentProps) {
        super(props);
    }

    render() {
        let topics = _.sortBy(this.props.project.topics, t => {
            return t.topicId
        });
        return <div>
            {
                (topics && topics.length > 0) && <TopicOverviewStatsFragment project={this.props.project}/>
            }
            <div className={"row"}>
                {
                    (!topics || topics.length === 0) && <div className={"col-12 p-2"}>
                        <div className={ClassNames("m-portlet")}>
                            <div className={"m-portlet__body"}>
                                <div className={styles.noTopicImageBox}>
                                    <img src={image} alt={""} className={styles.noTopicImage}/>
                                    <CreateAndLinkTopicToProjectButton projectId={this.props.projectId}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (topics && topics.length > 0) && (
                        <div className={"col-12 p-2"}>
                            <Tabs>
                                <TabList className={ClassNames(styles.tabHeaderWrapper, "row ml-0 mr-0 pl-0")}>
                                    {
                                        topics && _.sortBy(topics, ['id']).map((topic: Topic, index) => {
                                            return <Tab key={index}
                                                        className={ClassNames(styles.tab, "col")}>{topic.description}<br/>{topic.topicName}
                                            </Tab>
                                        })
                                    }
                                </TabList>
                                {
                                    topics && _.sortBy(topics, ['id']).map((topic: Topic, index: number) => {
                                        return <TabPanel key={index} className={styles.tabContentWrapper}>
                                            <MappedTopicItem topic={topic} projectId={this.props.projectId}/>
                                        </TabPanel>
                                    })
                                }
                            </Tabs>
                        </div>
                    )
                }
            </div>
        </div>
    }

}

export default MappedTopicListFragment;