// @flow

import React from 'react';
import {LLMsg} from "../../IntlCapture";
import {Link} from "react-router-dom";
import Page from "../../bundle/Page";
import CopyOpenApiButtonFragment from "./CopyOpenApiButtonFragment";
import Api from "../../bundle/Api";
import type {WeatherOpenApiOptions} from "../../model/model";
import _ from 'lodash';

type State = {
    options: WeatherOpenApiOptions
}

class WeatherOpenApi extends React.Component<any, State> {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        Api.services.openApiService.getWeatherAvailableOption().then((response) => {
            if (response.status === 200) {
                this.setState({options: response.data});
            }
        });
    }

    getOpenApiUrl(type: string, station: string, withoutDomain?: boolean) {
        return Api.urls.openApi.getLatestWeather(type, station, withoutDomain);
    }

    addHttpsIfNeeded(url: string) {
        if (!url.startsWith("http")) {
            return "https:" + url;
        }

        return url;
    }

    renderTemperatureTable() {
        if (!this.state.options) {
            return null;
        }
        return <div className="table-responsive">
            <div
                className={"text-center m--icon-font-size-lg1 m--font-bolder mb-4"}>{LLMsg("OPEN_API.WEATHER.TEMPERATURE")}</div>
            <table className="table table-bordered">
                <tbody>
                {
                    _.map(this.state.options.temperature, (value, key) => {
                        let httpUrl = "http:" + this.getOpenApiUrl("temperature", key);
                        let httpsUrl = this.addHttpsIfNeeded(this.getOpenApiUrl("temperature", key));
                        let urlWithoutDomain = this.getOpenApiUrl("temperature", key, true);
                        return <tr>
                            <td>{value} <a href={httpsUrl} className={"ml-2"} target={"_blank"}><i
                                className="fas fa-external-link-alt"/></a></td>
                            <td width={50}>
                                <CopyOpenApiButtonFragment text={httpUrl} label={"HTTP"}/>
                                <CopyOpenApiButtonFragment text={httpsUrl} label={"HTTPS"}/>
                                <CopyOpenApiButtonFragment text={urlWithoutDomain} label={"MakeCode Extension"}/>
                            </td>
                        </tr>;
                    })
                }
                </tbody>
            </table>
        </div>;
    }

    renderHumidityTable() {
        if (!this.state.options) {
            return null;
        }
        return <div className="table-responsive">
            <div
                className={"text-center m--icon-font-size-lg1 m--font-bolder mt-4 mb-4"}>{LLMsg("OPEN_API.WEATHER.HUMIDITY")}</div>
            <table className="table table-bordered">
                <tbody>
                {
                    _.map(this.state.options.humidity, (value, key) => {
                        let httpUrl = "http:" + this.getOpenApiUrl("humidity", key);
                        let httpsUrl = this.addHttpsIfNeeded(this.getOpenApiUrl("humidity", key));
                        let urlWithoutDomain = this.getOpenApiUrl("temperature", key, true);
                        return <tr>
                            <td>{value} <a href={httpsUrl} className={"ml-2"} target={"_blank"}><i
                                className="fas fa-external-link-alt"/></a></td>
                            <td width={50}>
                                <CopyOpenApiButtonFragment text={httpUrl} label={"HTTP"}/>
                                <CopyOpenApiButtonFragment text={httpsUrl} label={"HTTPS"}/>
                                <CopyOpenApiButtonFragment text={urlWithoutDomain} label={"MakeCode Extension"}/>
                            </td>
                        </tr>;
                    })
                }
                </tbody>
            </table>
        </div>;
    }

    renderRainfallTable() {
        if (!this.state.options) {
            return null;
        }
        return <div className="table-responsive">
            <div
                className={"text-center m--icon-font-size-lg1 m--font-bolder mt-4 mb-4"}>{LLMsg("OPEN_API.WEATHER.RAINFALL")}</div>
            <table className="table table-bordered">
                <tbody>
                {
                    _.map(this.state.options.rainfall, (value, key) => {
                        let httpUrl = "http:" + this.getOpenApiUrl("rainfall", key);
                        let httpsUrl = this.addHttpsIfNeeded(this.getOpenApiUrl("rainfall", key));
                        let urlWithoutDomain = this.getOpenApiUrl("rainfall", key, true);
                        return <tr>
                            <td>{value} <a href={httpsUrl} className={"ml-2"} target={"_blank"}><i
                                className="fas fa-external-link-alt"/></a></td>
                            <td width={50}>
                                <CopyOpenApiButtonFragment text={httpUrl} label={"HTTP"}/>
                                <CopyOpenApiButtonFragment text={httpsUrl} label={"HTTPS"}/>
                                <CopyOpenApiButtonFragment text={urlWithoutDomain} label={"MakeCode Extension"}/>
                            </td>
                        </tr>;
                    })
                }
                </tbody>
            </table>
        </div>;
    }

    renderUvIndexTable() {
        if (!this.state.options) {
            return null;
        }
        return <div className="table-responsive">
            <div
                className={"text-center m--icon-font-size-lg1 m--font-bolder mt-4 mb-4"}>{LLMsg("OPEN_API.WEATHER.UV_INDEX")}</div>
            <table className="table table-bordered">
                <tbody>
                {
                    _.map(this.state.options.uvindex, (value, key) => {
                        let httpUrl = "http:" + this.getOpenApiUrl("uvindex", key);
                        let httpsUrl = this.addHttpsIfNeeded(this.getOpenApiUrl("uvindex", key));
                        let urlWithoutDomain = this.getOpenApiUrl("uvindex", key, true)
                        return <tr>
                            <td>{value} <a href={httpsUrl} className={"ml-2"} target={"_blank"}><i
                                className="fas fa-external-link-alt"/></a></td>
                            <td width={50}>
                                <CopyOpenApiButtonFragment text={httpUrl} label={"HTTP"}/>
                                <CopyOpenApiButtonFragment text={httpsUrl} label={"HTTPS"}/>
                                <CopyOpenApiButtonFragment text={urlWithoutDomain} label={"MakeCode Extension"}/>
                            </td>
                        </tr>;
                    })
                }
                </tbody>
            </table>
        </div>;
    }

    render() {
        return <div
            className="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper">
            <div className="m-login__body mt-5">
                <div className="m-login__signin mt-5">
                    <div className="m-login__title">
                        <h3>{LLMsg("OPEN_API.WEATHER_API_TITLE")}</h3>
                    </div>
                    {this.renderTemperatureTable()}
                    {this.renderHumidityTable()}
                    {this.renderUvIndexTable()}
                    {this.renderRainfallTable()}
                    <div className="m-login__action">
                        <Link to={Page.internals.urls.misc.home()} className="m-link">
                            <span>{LLMsg("COMMON.HOME.BACK_TO_HOME")}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>;
    }

}

export default WeatherOpenApi;