// @flow

import React, {Component} from "react";

class HeaderSidebarDisplayToggle extends Component <any, any> {
    render() {
        return <div className="m-stack__item m-stack__item--middle m-brand__tools">
            {/* BEGIN: Left Aside Minimize Toggle */}
            <a href="javascript:void(0)" id="m_aside_left_minimize_toggle"
               className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block ">
                <span style={{backgroundColor: "white"}} className={"make_code_sidebar_toggle_color"}/>
            </a>
            {/* END */}
            {/* BEGIN: Responsive Aside Left Menu Toggler */}
            <a href="javascript:;" id="m_aside_left_offcanvas_toggle"
               className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
                <span style={{backgroundColor: "white"}} className={"make_code_sidebar_toggle_color"}/>
            </a>
            <a id="" href="javascript:window.$('body').toggleClass('m-topbar--on')"
               className="m-brand__icon m--visible-tablet-and-mobile-inline-block">
                <i className="flaticon-more" style={{color: "white"}}/>
            </a>
            {/* BEGIN: Topbar Toggler */}

        </div>

    }
};

export default HeaderSidebarDisplayToggle;