// @flow

import React from 'react';
import _ from 'lodash';
import Api from "../../bundle/Api";
import swal from "sweetalert2";
import {withRouter} from "react-router";
import type {Match} from "../../App";
import {createAction} from "../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../IntlCapture";
import styles from "./TopicDetailFragment/index.css";
import Image from "../../bundle/Image";


type RenameTopicButtonProps = {
    topic: ?{ topicId: string, topicDescription: string },

    dispatchTopicDescriptionUpdated?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}

const changeTopicDescriptionPrompt = (topicId: string, topicDescription: string, dispatchTopicUpdatedCallback: ?any) => {

    if (!topicId || !topicDescription) {
        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        return;
    }

    swal({
        title: LLMsg("COMMON.TOPIC.CHANGE_TOPIC_DESCRIPTION_TITLE"),
        input: 'text',
        inputValue: topicDescription,
        inputPlaceholder: LLMsg("COMMON.TOPIC.TOPIC_DESC_PLACEHOLDER"),
        showCancelButton: true,
        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
        cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value) {
                    resolve()
                } else {
                    resolve(LLMsg("COMMON.TOPIC.TOPIC_DESC_EMPTY"));
                }
            })
        }
    }).then((e) => {
        if (e.dismiss) {
            return;
        }
        if (e.value) {
            Api.services.topic.update.description(topicId, e.value).then(response => {
                if (response.status === 200) {
                    swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success");
                    dispatchTopicUpdatedCallback && dispatchTopicUpdatedCallback();
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch(() => {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            });
        } else {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        }
    });

};

const RenameTopicButton = (props: RenameTopicButtonProps) => {
    let topicId = _.get(props, "topic.topicId", "");
    let topicDescription = _.get(props, "topic.description", "");
    return <div
        onClick={() => {
            changeTopicDescriptionPrompt(topicId, topicDescription, props.dispatchTopicDescriptionUpdated);
        }}
        className={styles.actionDiv + " col-6"}>
        <img src={Image.urls.getPencilIcon()}
             className={styles.actionImageIcon}/>
        <span className={styles.actionLabel}>
                                      {LLMsg("COMMON.TOPIC.UPDATE_TOPIC")}
                </span>
    </div>;

};

const mapDispatchToProps = dispatch => ({
    dispatchTopicDescriptionUpdated: () => dispatch(createAction(Api.actions.topic.TOPIC_UPDATED))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(RenameTopicButton));