// @flow

import type {ToObject} from "./PageUrl";
import StringUtil from "../../util/StringUtil";
import Page from "../../bundle/Page";

export default class DashboardPageUrl {

    static getDashboardPageUrl(dashboardId?: string, dashboardName?: string): ToObject {
        return {
            pathname: dashboardId ? StringUtil.replaceAllText(Page.internals.paths.DASHBOARD_URL, [{
                key: ":dashboardId",
                value: dashboardId
            }, {
                key: ":dashboardName",
                value: StringUtil.removeBlacklistChar(dashboardName || "")
            }]) : Page.internals.paths.DASHBOARD_URL
        };
    }

    static getDashboardDefaultPageUrl(): ToObject {
        return {
            pathname: Page.internals.paths.DASHBOARD_DEFAULT_URL
        };
    }


}