// @flow

import React from 'react';
import ErrorUtil from "../../../util/ErrorUtil";
import Api from "../../../bundle/Api";
import styles from './index.css';
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import swal from "sweetalert2";
import {LLMsg} from "../../../IntlCapture";
import {RouterHistory} from "../../../AppRoute";

type ChangePasswordFragmentState = {

    showPassword1Error: boolean,
    showPassword2Error: boolean,
    showPasswordNotMatch: boolean,
    showOldPasswordError: boolean

}

class ChangePasswordFragment extends React.Component<any, ChangePasswordFragmentState> {

    oldPasswordRef: any;
    password1Ref: any;
    password2Ref: any;
    state: ChangePasswordFragmentState = {
        showPassword1Error: false,
        showPassword2Error: false,
        showPasswordNotMatch: false,
        showOldPasswordError: false
    };

    constructor(props: any) {
        super(props);
        this.oldPasswordRef = React.createRef();
        this.password1Ref = React.createRef();
        this.password2Ref = React.createRef();

        this.setupPassword = this.setupPassword.bind(this);
    }

    setupPassword = () => {
        let oldPassword = this.oldPasswordRef.value;
        let password1 = this.password1Ref.value;
        let password2 = this.password2Ref.value;

        let hasError = false;
        let errorState = {
            showPassword1Error: false,
            showPassword2Error: false,
            showPasswordNotMatch: false,
            showOldPasswordError: false
        };

        if (!oldPassword) {
            errorState.showOldPasswordError = true;
            hasError = true;
        }
        if (!password1 || password1.length < 6 || password1.length > 20) {
            errorState.showPassword1Error = true;
            hasError = true;
        }
        if (!password2 || password2.length < 6 || password2.length > 20) {
            errorState.showPassword2Error = true;
            hasError = true;
        }

        if (password1 !== password2) {
            errorState.showPasswordNotMatch = true;
            hasError = true;
        }

        if (hasError) {
            this.setState({
                ...errorState
            });
            return;
        }
        Api.services.user.doChangeAccountPassword(oldPassword, password1).then((response) => {
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success").then(() => {
                    RouterHistory().push(Page.internals.urls.misc.home());
                });
            }
        }).catch((e) => {
            if (ErrorUtil.hasErrorCode(e, ErrorUtil.USER_PASSWORD_NOT_MATCH)) {
                this.setState({
                    showPassword1Error: false,
                    showPassword2Error: false,
                    showPasswordNotMatch: false,
                    showOldPasswordError: true
                });

            } else {
                ErrorUtil.promptError(e);
            }
        })

    };

    render() {
        return <div className="m-portlet">

            <form className="m-form m-form--fit m-form--label-align-right">
                <div className="m-portlet__body">
                    <div className="form-group m-form__group m--margin-top-10">
                        <h3>{LLMsg("PASSWORD.CHANGE_PASSWORD_TITLE")}</h3>
                    </div>
                    <div className="form-group m-form__group row">
                        <label htmlFor="example-password-input" className="col-2 col-form-label">
                            {LLMsg("PASSWORD.OLD_PASSWORD")}
                        </label>
                        <div className="col-10">
                            <input
                                className={"form-control m-input " + (this.state.showOldPasswordError ? styles.errorInput : "")}
                                type="password"
                                ref={(oldPasswordRef) => {
                                    this.oldPasswordRef = oldPasswordRef
                                }}
                            />
                            {
                                this.state.showOldPasswordError && <span
                                    className={"m-form__help " + styles.errorInput}>
                                {LLMsg("PASSWORD.CURRENT_PASSWORD_INCORRECT")}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="form-group m-form__group row">
                        <label htmlFor="example-password-input" className="col-2 col-form-label">
                            {LLMsg("PASSWORD.NEW_PASSWORD")}
                        </label>
                        <div className="col-10">
                            <input
                                className={"form-control m-input " + (this.state.showPassword1Error ? styles.errorInput : "")}
                                type="password"
                                ref={(password1Ref) => {
                                    this.password1Ref = password1Ref
                                }}
                            />
                            <span
                                className={"m-form__help " + (this.state.showPassword1Error ? styles.errorInput : "")}>
                                {LLMsg("PASSWORD.PASSWORD_LENGTH_HINTS")}
                            </span>
                        </div>
                    </div>
                    <div className="form-group m-form__group row">
                        <label htmlFor="example-password-input" className="col-2 col-form-label">
                            {LLMsg("PASSWORD.NEW_PASSWORD_AGAIN")}
                        </label>
                        <div className="col-10">
                            <input
                                className={"form-control m-input " + (this.state.showPassword2Error ? styles.errorInput : "")}

                                type="password"
                                ref={(password2Ref) => {
                                    this.password2Ref = password2Ref
                                }}
                            />
                            {
                                this.state.showPassword1Error &&
                                <span
                                    className={"m-form__help " + (this.state.showPassword2Error ? styles.errorInput : "")}>
                                   {LLMsg("PASSWORD.PASSWORD_LENGTH_HINTS")}
                                </span>
                            }

                            {
                                this.state.showPasswordNotMatch && <div
                                    className={"m-form__help " + styles.errorInput}>
                                    {LLMsg("PASSWORD.NEW_PASSWORD_NOT_MATCH")}
                                </div>

                            }
                        </div>
                    </div>
                </div>
                <div className="m-portlet__foot m-portlet__foot--fit">
                    <div className="m-form__actions">
                        <div className="row">
                            <div className="col-2">
                            </div>
                            <div className="col-10">
                                <button type="button" className="btn btn-success" onClick={this.setupPassword}>
                                    {LLMsg("PASSWORD.CHANGE_PASSWORD_BUTTON")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default ChangePasswordFragment;
