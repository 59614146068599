exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Y5bNc80M8OmBq8ULoy6vT {\n    width: 240px;\n    font-weight: bold;\n    display: inline-block;\n    padding: 3px 10px;\n}\n\n._1B7SAi0R8KSpROMAbBQASI {\n    display: inline-block;\n}\n\n.UR4v8BoINJt8pQ0Rsn4_E {\n    margin-bottom: 3px;\n}", ""]);

// exports
exports.locals = {
	"title": "_2Y5bNc80M8OmBq8ULoy6vT",
	"content": "_1B7SAi0R8KSpROMAbBQASI",
	"row": "UR4v8BoINJt8pQ0Rsn4_E"
};