exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body {\n    margin: 0;\n    padding: 0;\n    font-family: sans-serif;\n}\n\n._1ogQKQag7xsaFE51yt3Rnw {\n    -webkit-touch-callout: none; /* iOS Safari */\n    -webkit-user-select: none; /* Safari */\n    -khtml-user-select: none; /* Konqueror HTML */\n    -moz-user-select: none; /* Firefox */\n    -ms-user-select: none; /* Internet Explorer/Edge */\n    user-select: none;\n    /* Non-prefixed version, currently\n                                     supported by Chrome and Opera */\n}", ""]);

// exports
exports.locals = {
	"noselect": "_1ogQKQag7xsaFE51yt3Rnw"
};