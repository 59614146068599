// @flow

import {createReduxAction} from "../reducer";
import Api from "../../bundle/Api";
import LoadingUtil from "../../util/LoadingUtil";

export default class ProjectDispatcher {

    static doGetListOfProject = (dispatch: Function) => {
        LoadingUtil.showFullScreenLoading();
        dispatch(createReduxAction(Api.actions.project.GET_LIST_OF_PROJECT_ACTION_TYPE, false));

        Api.services.project.doGetListOfProject().then((projectListResponse) => {
            LoadingUtil.hideFullScreenLoading();
            dispatch(createReduxAction(Api.actions.project.GET_LIST_OF_PROJECT_ACTION_TYPE, true, projectListResponse.data, projectListResponse.status));
        });

    };
}