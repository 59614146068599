// @flow

import React from 'react';
import ErrorUtil from "../../../util/ErrorUtil";
import Api from "../../../bundle/Api";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import {LLMsg} from "../../../IntlCapture";
import UrlBuilder from "../../../url/UrlBuilder";
import StringUtil from "../../../util/StringUtil";
import LoadingUtil from "../../../util/LoadingUtil";
import styles from "./index.css";

type RegisterWithLicenseFormWrapperProps = {
    match?: Object,
    location?: Object,
    history?: Object
}

type ResetPasswordFragmentState = {
    showUsernameNotExist: boolean,
    showResetCoolDownError: boolean,
}


class ResetPasswordFragment extends React.Component<any, ResetPasswordFragmentState> {

    usernameInput: any;

    constructor(props: RegisterWithLicenseFormWrapperProps) {
        super(props);
        this.state = {
            showUsernameNotExist: false,
            showResetCoolDownError: false
        };
        this.resetPassword = this.resetPassword.bind(this);
    }

    resetPassword = () => {
        const username = this.usernameInput.value;

        let isValid = true;
        if (!StringUtil.validateEmail(username)) {
            this.setState({showUsernameNotExist: true});
            isValid = false;
        }

        if (!isValid) {
            return;
        }

        this.setState({showUsernameNotExist: false, showResetCoolDownError: false});

        LoadingUtil.showFullScreenLoading();
        Api.services.user.doResetUserPassword(username).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {

            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })

    };

    componentDidMount() {
        this.usernameInput.focus();
    }

    render() {
        const ref = UrlBuilder.getQueryString()["ref"];

        return <div
            className="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper">
            <div className="m-login__body">
                <div className="m-login__signin">
                    <div className="m-login__title">
                        <h3>{LLMsg("PASSWORD.RESET_PASSWORD_TITLE")}</h3>
                    </div>
                    <form className="m-login__form m-form">
                        {
                            this.state.showUsernameNotExist &&
                            <div className={styles.errorPrompt + " alert"}
                                 role="alert">
                                {LLMsg("PASSWORD.RESET_PASSWORD_EMAIL_NOT_EXIST")}
                            </div>
                        }
                        {
                            this.state.showResetCoolDownError &&
                            <div className={styles.errorPrompt + " alert"}
                                 role="alert">
                                {LLMsg("PASSWORD.RESET_PASSWORD_COOL_DOWN")}
                            </div>
                        }
                        <div className="form-group m-form__group">
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("REGISTER_PAGE.GUEST_USERNAME")}
                                   name="username" autoComplete="off" ref={(usernameInput) => {
                                this.usernameInput = usernameInput
                            }}/>
                        </div>
                        <hr className={"mt-5"}/>
                        <div className={styles.notice}>
                            {LLMsg("PASSWORD.RESET_PASSWORD_NOTICE")}
                        </div>
                    </form>
                    <div className="m-login__action">
                        <Link to={Page.internals.urls.user.login(ref)} className="m-link">
                            <span>{LLMsg("REGISTER_PAGE.BACK_TO_LOGIN_PAGE")}</span>
                        </Link>
                        <a href="javascript:" onClick={() => {
                            this.resetPassword()
                        }}>
                            <button className="btn btn-accent m-btn m-btn--pill p-3">
                                {LLMsg("PASSWORD.RESET_PASSWORD_RESET_BUTTON")}
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default ResetPasswordFragment;
