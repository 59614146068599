// @flow

import React from 'react';
import styles from './index.css';
import {ClassNames} from "../../../../bundle/ClassNames";
import {LLMsg} from "../../../../IntlCapture";
import Api from "../../../../bundle/Api";
import UserDao from "../../../../dao/UserDao";
import {BrowserRouter} from "react-router-dom";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";
import ErrorUtil from "../../../../util/ErrorUtil";

let model, ctx, videoWidth, videoHeight, video, canvas;

type State = {
    isTimerCounting: boolean,
    isSettingOpen: boolean,
    timerInterval: number,
    minScore: number
}

type Props = {
    projectId: string
}
export default class FaceDetectionFragment extends React.Component<Props, State> {

    videoRef: any;
    outputRef: any;
    stream: any;
    timerRef = React.createRef();

    detectedFaceCount: number = 0;
    currentTimerTime: number = 5;

    state = {
        isTimerCounting: true,
        isSettingOpen: false,
        timerInterval: 5
    }

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.outputRef = React.createRef();

    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.stream && this.stream.getTracks().forEach(function (track) {
            track.stop();
        });
    }


    sendDetectedFaces() {

        if (this.detectedFaceCount > 0) {
            Api.services.eventTrigger.trigger.faceDetection(this.props.projectId).then((response) => {
            })
            console.log("sending");
        }
    }

    stopTimer() {
        if (this.state.isTimerCounting) {
            this.setState({isTimerCounting: false});
        }
        clearInterval(this.timer);
    }


    startTimer() {
        if (!this.state.isTimerCounting) {
            this.setState({isTimerCounting: true});
        }

        this.timer = setInterval(() => {
            if (this.currentTimerTime === 0) {
                this.currentTimerTime = this.state.timerInterval;
                this.sendDetectedFaces();
            } else {
                this.currentTimerTime = this.currentTimerTime - 1;
            }
            if (this.timerRef.current) {
                this.timerRef.current.innerHTML = this.currentTimerTime;
            }
        }, 1000);
    }

    async setupCamera() {
        video = this.videoRef.current;

        this.stream = await navigator.mediaDevices.getUserMedia({
            'audio': false,
            'video': {facingMode: 'user'},
        });

        video.srcObject = this.stream;

        return new Promise((resolve) => {
            video.onloadedmetadata = () => {
                resolve(video);
            };
        });
    }

    renderPrediction = async () => {
        const returnTensors = false;
        const flipHorizontal = false;
        const annotateBoxes = true;
        const predictions = await model.estimateFaces(video, returnTensors, flipHorizontal, annotateBoxes);

        if (predictions.length > 0) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.detectedFaceCount = predictions.length;
            for (let i = 0; i < predictions.length; i++) {
                if (returnTensors) {
                    predictions[i].topLeft = predictions[i].topLeft.arraySync();
                    predictions[i].bottomRight = predictions[i].bottomRight.arraySync();
                    if (annotateBoxes) {
                        predictions[i].landmarks = predictions[i].landmarks.arraySync();
                    }
                }

                const start = predictions[i].topLeft;
                const end = predictions[i].bottomRight;
                const size = [end[0] - start[0], end[1] - start[1]];
                ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
                ctx.fillRect(start[0], start[1], size[0], size[1]);

                if (annotateBoxes) {
                    const landmarks = predictions[i].landmarks;

                    ctx.fillStyle = "blue";
                    for (let j = 0; j < landmarks.length; j++) {
                        const x = landmarks[j][0];
                        const y = landmarks[j][1];
                        ctx.fillRect(x, y, 5, 5);
                    }
                }
            }
        } else {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        requestAnimationFrame(this.renderPrediction);
    };

    async componentDidMount(): void {

        if (!UserDao.permission.allowAi) {
            ErrorUtil.showPermissionNotAllowed(() => {
                RouterHistory().push(Page.internals.urls.license.myLicenseHome());
            });
            return;
        }

        await window.tf.setBackend("wasm");
        await this.setupCamera();
        video.play();

        videoWidth = video.videoWidth;
        videoHeight = video.videoHeight;
        video.width = videoWidth;
        video.height = videoHeight;

        canvas = this.outputRef.current;
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        ctx = canvas.getContext('2d');
        ctx.fillStyle = "rgba(255, 0, 0, 0.5)";

        model = await window.blazeface.load();

        this.renderPrediction();
        this.startTimer();

    }


    updateTimerInterval(newInterval: number) {
        if (this.state.timerInterval !== newInterval) {
            this.setState({timerInterval: newInterval});
            this.currentTimerTime = newInterval;
        }
    }

    render() {
        return <div>
            <div className={styles.box}>
                <video autoPlay={true} ref={this.videoRef} className={styles.video}/>
                <canvas ref={this.outputRef} className={styles.canvas}/>
            </div>
            <div className={ClassNames(styles.result, "noselect")}>
                <div>
                    <h1>
                        {LLMsg("AI.IMAGE_TRANSFER.TRIGGER_IN_PREFIX")}
                        <span ref={this.timerRef}>5</span>
                        {LLMsg("AI.IMAGE_TRANSFER.TRIGGER_IN_SECONDS")}

                        {
                            this.state.isTimerCounting && (
                                <div className={styles.iconButton} onClick={() => {
                                    this.stopTimer();
                                }}><i className="fa fa-pause-circle"/></div>
                            )
                        }
                        {
                            !this.state.isTimerCounting && (
                                <div className={styles.iconButton} onClick={() => {
                                    this.startTimer();
                                }}><i className="fa fa-play-circle"/></div>
                            )
                        }
                        <div className={styles.iconButton}
                             onClick={() => {
                                 this.setState({isSettingOpen: !this.state.isSettingOpen})
                             }}>
                            <i className="fa fa-cog"/>
                        </div>
                    </h1>
                    {
                        this.state.isSettingOpen && <div className={styles.settingWrapper}>
                            <div>
                                <div className={styles.settingLabel}>{LLMsg("AI.IMAGE_TRANSFER.SEND_INTERVAL")}</div>
                                <div
                                    onClick={() => {
                                        this.updateTimerInterval(5)
                                    }}
                                    className={ClassNames(styles.settingButton, {[styles.active]: this.state.timerInterval === 5})}>5s
                                </div>
                                <div
                                    onClick={() => {
                                        this.updateTimerInterval(10)
                                    }}
                                    className={ClassNames(styles.settingButton, {[styles.active]: this.state.timerInterval === 10})}>10s
                                </div>
                                <div
                                    onClick={() => {
                                        this.updateTimerInterval(15)
                                    }}
                                    className={ClassNames(styles.settingButton, {[styles.active]: this.state.timerInterval === 15})}>15s
                                </div>
                                <div
                                    onClick={() => {
                                        this.updateTimerInterval(30)
                                    }}
                                    className={ClassNames(styles.settingButton, {[styles.active]: this.state.timerInterval === 30})}>30s
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.resultClass} ref={this.detectedObjectListRef}/>
            </div>
        </div>
    }
}