exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1LLwcLLXZGy2fOBTT7iKMd {\n    float: right;\n    margin-top: 15px;\n}\n\n._2Fd2GHPEOeWQs73G4xGWP9 {\n    border: 1px solid #d5d5d5;\n    margin-bottom: 20px;\n    padding: 15px;\n    display: block;\n    height: 90px;\n    width: 100%;\n}\n\n._2MVltyfEtqB-jpVja8vc5D {\n    width: 60px;\n    height: 60px;\n    margin-right: 15px;\n    font-size: 38px;\n    display: inline;\n    float: left;\n    border: 1px solid #eeeeee;\n    text-align: center;\n    vertical-align: center;\n}\n\n._2ddJ97yKYjtEnYB1YQvUwj {\n    display: inline;\n    float: left;\n}\n\n.MnvNC_7KcUPTaXKOl7EIF {\n    display: block;\n}\n\n._39WTUhL8YL1AHmHJBQD8rK {\n\n    display: inline-block;\n    font-weight: bolder;\n    width: 100px;\n}\n\n._1LJTBNKyXBfpvAePa5Pq0U {\n    display: inline-block;\n\n}\n\n._31NA-A_Bcrsv80pMkagBs6 {\n    float: right;\n    text-align: right;\n}\n\n._1uagXgFxLyMG7OAsXRbLea {\n\n    margin-left: 10px;\n    display: block;\n}", ""]);

// exports
exports.locals = {
	"create_button": "_1LLwcLLXZGy2fOBTT7iKMd",
	"item": "_2Fd2GHPEOeWQs73G4xGWP9",
	"item_id": "_2MVltyfEtqB-jpVja8vc5D",
	"item_record_wrapper": "_2ddJ97yKYjtEnYB1YQvUwj",
	"item_record": "MnvNC_7KcUPTaXKOl7EIF",
	"item_label": "_39WTUhL8YL1AHmHJBQD8rK",
	"item_value": "_1LJTBNKyXBfpvAePa5Pq0U",
	"item_action_wrapper": "_31NA-A_Bcrsv80pMkagBs6",
	"item_action_button": "_1uagXgFxLyMG7OAsXRbLea"
};