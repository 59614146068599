// @flow

import React, {Component} from 'react';
import HeaderFragment from "../header/HeaderFragment";
import BodyWrapper from "../body/BodyWrapper";
import SidebarWrapper from "../sidebar/SidebarWrapper";
import GeneralSidebarFragment from "../sidebar/GeneralSidebarFragment";
import ContentWrapper from "../content/ContentWrapper";
import ContentHeaderFragment from "../content/ContentHeaderFragment";
import FooterFragment from "../footer/FooterFragment";
import BackToTopButton from "../BackToTopButton";
import StickyQuickNav from "../SickyQuickNav";
import ContentInnerWrapper from "../content/ContentInnerWrapper";
import Breadcrumb from "../content/Breadcrumb";
import DashboardWrapperFragment from "../dashboard/DashboardWrapperFragment";
import {LLMsg} from "../../IntlCapture";

type DashboardPageProps = {

    match?: Object,
    location?: Object,
    history?: Object
}

class DashboardPage extends Component<DashboardPageProps, any> {
    render() {

        return <div className="m-grid m-grid--hor m-grid--root m-page">
            <HeaderFragment/>
            <BodyWrapper>
                <SidebarWrapper>
                    <GeneralSidebarFragment/>
                </SidebarWrapper>
                <ContentWrapper>
                    <ContentHeaderFragment>
                        <Breadcrumb items={[{linkTo: "", displayText: LLMsg("COMMON.DASHBOARD.DASHBOARD")}]}/>
                    </ContentHeaderFragment>
                    <ContentInnerWrapper>
                        <DashboardWrapperFragment/>
                    </ContentInnerWrapper>
                </ContentWrapper>
            </BodyWrapper>

            <FooterFragment/>
            <BackToTopButton/>
            <StickyQuickNav/>
        </div>
    }
}

export default DashboardPage;