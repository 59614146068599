// @flow

import React from 'react';
import styles from './index.css';
import Api from "../../../../bundle/Api";
import swal from "sweetalert2";
import {LLMsg} from "../../../../IntlCapture";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";
import ErrorUtil from "../../../../util/ErrorUtil";
import type {CreateLicenseGenerationBatch, LicenseTarget} from "../../../../model/model";
import {Link} from "react-router-dom";
import {ClassNames} from "../../../../bundle/ClassNames";
import TimeUtil from "../../../../util/TimeUtil";
import moment from "moment";

type LicenseCreateFragmentState = {
    licenseTargets: Array<LicenseTarget>,
    selectedLicenseTargetId: number,
    selectedLicenseType: string
}

export const LicenseType = {
    custom: {
        key: "CUSTOM",
        name: "Custom",
        defaultValue: {
            // other
            maxRegisterTime: 1,
            maxConcurrentLogin: 1,
            expireDay: 7,

            // project
            allowShareProject: false,
            allowProjectCount: 10,
            allowTopicCount: 20,

            // data
            allowDataIntervalLimitSec: 5,
            allowExportData: false,
            allowViewableHistoryDay: 14,

            // event trigger
            allowMqttTrigger: true,
            allowWebHookTrigger: false,
            allowDialogFlowTrigger: false,
            allowMqttAction: true,
            allowIftttAction: false,

            // project map
            allowDynamicLocationUpdate: true,
            allowEmbedProjectMap: false,

            // chart
            allowCirclePointMap: false,
            allowEmbedChart: false,

            // ai
            ai: false,

        }
    },
    basicP1: {
        key: "BASIC_P1",
        name: "Basic (4 Months)",
        defaultValue: {
            // other
            maxRegisterTime: 1,
            maxConcurrentLogin: 1,
            expireDay: 120,

            // project
            allowShareProject: true,
            allowProjectCount: 10,
            allowTopicCount: 40,

            // data
            allowDataIntervalLimitSec: 1,
            allowExportData: true,
            allowViewableHistoryDay: 28,

            // event trigger
            allowMqttTrigger: true,
            allowWebHookTrigger: false,
            allowDialogFlowTrigger: false,
            allowMqttAction: true,
            allowIftttAction: false,

            // project map
            allowDynamicLocationUpdate: true,
            allowEmbedProjectMap: false,

            // chart
            allowCirclePointMap: false,
            allowEmbedChart: false,

            // ai
            ai: false,

        }
    },
    basicP2: {
        key: "BASIC_P2",
        name: "Basic (12 Months)",
        defaultValue: {
            // other
            maxRegisterTime: 1,
            maxConcurrentLogin: 1,
            expireDay: 365,

            // project
            allowShareProject: true,
            allowProjectCount: 25,
            allowTopicCount: 100,

            // data
            allowDataIntervalLimitSec: 1,
            allowExportData: true,
            allowViewableHistoryDay: 28,

            // event trigger
            allowMqttTrigger: true,
            allowWebHookTrigger: false,
            allowDialogFlowTrigger: false,
            allowMqttAction: true,
            allowIftttAction: false,

            // project map
            allowDynamicLocationUpdate: true,
            allowEmbedProjectMap: false,

            // chart
            allowCirclePointMap: false,
            allowEmbedChart: false,

            // ai
            ai: false,

        }
    },
    fullP1: {
        key: "FULL_P1",
        name: "Full (4Months)",
        defaultValue: {
            // other
            maxRegisterTime: 1,
            maxConcurrentLogin: 1,
            expireDay: 120,

            // project
            allowShareProject: true,
            allowProjectCount: 10,
            allowTopicCount: 40,

            // data
            allowDataIntervalLimitSec: 1,
            allowExportData: true,
            allowViewableHistoryDay: 120,

            // event trigger
            allowMqttTrigger: true,
            allowWebHookTrigger: true,
            allowDialogFlowTrigger: true,
            allowMqttAction: true,
            allowIftttAction: true,

            // project map
            allowDynamicLocationUpdate: true,
            allowEmbedProjectMap: true,

            // chart
            allowCirclePointMap: true,
            allowEmbedChart: true,

            // ai
            ai: false,

        }
    },
    fullP2: {
        key: "FULL_P2",
        name: "Full (12 Months)",
        defaultValue: {
            // other
            maxRegisterTime: 1,
            maxConcurrentLogin: 1,
            expireDay: 365,

            // project
            allowShareProject: true,
            allowProjectCount: 25,
            allowTopicCount: 100,

            // data
            allowDataIntervalLimitSec: 1,
            allowExportData: true,
            allowViewableHistoryDay: 365,

            // event trigger
            allowMqttTrigger: true,
            allowWebHookTrigger: true,
            allowDialogFlowTrigger: true,
            allowMqttAction: true,
            allowIftttAction: true,

            // project map
            allowDynamicLocationUpdate: true,
            allowEmbedProjectMap: true,

            // chart
            allowCirclePointMap: true,
            allowEmbedChart: true,

            // ai
            ai: false,

        }
    },
};

class LicenseCreateFragment extends React.Component<any, LicenseCreateFragmentState> {

    state = {
        licenseTargets: [],
        selectedLicenseTargetId: 0
    };

    currentLicenseType: string;

    licenseCountRef: any;
    maxConsumptionCountRef: any;
    maxConcurrentCountRef: any;
    trialDaysRef: any;
    expireDateRef: any;

    shareProjectRef: any;
    projectCountRef: any;
    topicCountRef: any;

    dataIntervalLimitSecRef: any;
    exportDataRef: any;
    viewableHistoryDayRef: any;

    mqttTriggerRef: any;
    webHookTriggerRef: any;
    dialogFlowTriggerREf: any;
    mqttActionRef: any;
    iftttActionRef: any;

    dynamicLocationUpdateRef: any;
    embedProjectMapRef: any;

    circlePointMapRef: any;
    embedChartRef: any;
    aiRef: any;


    constructor(props: any) {
        super(props);
        this.licenseCountRef = React.createRef();
        this.maxConsumptionCountRef = React.createRef();
        this.maxConcurrentCountRef = React.createRef();
        this.trialDaysRef = React.createRef();
        this.expireDateRef = React.createRef();

        // project
        this.shareProjectRef = React.createRef();
        this.projectCountRef = React.createRef();
        this.topicCountRef = React.createRef();

        // data
        this.dataIntervalLimitSecRef = React.createRef();
        this.exportDataRef = React.createRef();
        this.viewableHistoryDayRef = React.createRef();

        // event trigger
        this.mqttTriggerRef = React.createRef();
        this.webHookTriggerRef = React.createRef();
        this.dialogFlowTriggerREf = React.createRef();
        this.mqttActionRef = React.createRef();
        this.iftttActionRef = React.createRef();

        // project map
        this.dynamicLocationUpdateRef = React.createRef();
        this.embedProjectMapRef = React.createRef();

        // chart
        this.circlePointMapRef = React.createRef();
        this.embedChartRef = React.createRef();

        // ai
        this.aiRef = React.createRef();

        this.state = {
            selectedLicenseType: LicenseType.custom.key
        };
        this.changeLicenseType = this.changeLicenseType.bind(this);
    }

    componentDidMount() {
        this.getLicenseTargetList();
        this.changeLicenseType(LicenseType.custom.key);
    }

    handleOptionChange = (changeEvent: any) => {
        this.setState({
            selectedLicenseTargetId: changeEvent.target.value || 0
        });
    };

    getLicenseTargetList = () => {

        Api.services.license.getLicenseTargetList().then((response) => {
            if (response.status === 200) {
                let licenseTargets: Array<LicenseTarget> = response.data;
                this.setState({licenseTargets});
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
        });
    };

    create = () => {

        let createLicenseGenerationBatch: CreateLicenseGenerationBatch = {};
        createLicenseGenerationBatch.licenseType = this.currentLicenseType;
        createLicenseGenerationBatch.licenseCount = this.licenseCountRef.value;
        createLicenseGenerationBatch.maxConsumptionCount = this.maxConsumptionCountRef.value;
        createLicenseGenerationBatch.maxConcurrentCount = this.maxConcurrentCountRef.value;
        createLicenseGenerationBatch.trialDays = this.trialDaysRef.value || undefined;
        createLicenseGenerationBatch.expireDate = TimeUtil.convertTimeFormat(TimeUtil.readTime(this.expireDateRef.value, TimeUtil.FULL_DATE_DISPLAY_FORMAT), TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        createLicenseGenerationBatch.licenseTargetId = this.state.selectedLicenseTargetId;
        createLicenseGenerationBatch.canShareProject = this.shareProjectRef.checked;
        createLicenseGenerationBatch.projectCount = this.projectCountRef.value;
        createLicenseGenerationBatch.topicCount = this.topicCountRef.value;
        createLicenseGenerationBatch.dataIntervalLimitSec = this.dataIntervalLimitSecRef.value;
        createLicenseGenerationBatch.canExportData = this.exportDataRef.checked;
        createLicenseGenerationBatch.viewableHistoryDay = this.viewableHistoryDayRef.value;
        createLicenseGenerationBatch.canMqttTrigger = this.mqttTriggerRef.checked;
        createLicenseGenerationBatch.canWebHookTrigger = this.webHookTriggerRef.checked;
        createLicenseGenerationBatch.canDialogFlowTrigger = this.dialogFlowTriggerREf.checked;
        createLicenseGenerationBatch.canMqttAction = this.mqttActionRef.checked;
        createLicenseGenerationBatch.canIftttAction = this.iftttActionRef.checked;
        createLicenseGenerationBatch.canDynamicLocationUpdate = this.dynamicLocationUpdateRef.checked;
        createLicenseGenerationBatch.canEmbedProjectMap = this.embedProjectMapRef.checked;
        createLicenseGenerationBatch.canCirclePointMap = this.circlePointMapRef.checked;
        createLicenseGenerationBatch.canEmbedChart = this.embedChartRef.checked;
        createLicenseGenerationBatch.canAi = this.aiRef.checked;

        Api.services.license.batchCreate(createLicenseGenerationBatch).then((response) => {
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.ADD_SUCCESS"), "", "success").then(() => {
                    RouterHistory().push(Page.internals.urls.license.adminHome());
                });
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
        });
    };

    changeLicenseType(licenseTypeKey: string) {
        let defaultValue;
        if (licenseTypeKey === LicenseType.custom.key) {
            defaultValue = LicenseType.custom.defaultValue;

        } else if (licenseTypeKey === LicenseType.basicP1.key) {
            defaultValue = LicenseType.basicP1.defaultValue;

        } else if (licenseTypeKey === LicenseType.basicP2.key) {
            defaultValue = LicenseType.basicP2.defaultValue;

        } else if (licenseTypeKey === LicenseType.fullP1.key) {
            defaultValue = LicenseType.fullP1.defaultValue;

        } else if (licenseTypeKey === LicenseType.fullP2.key) {
            defaultValue = LicenseType.fullP2.defaultValue;

        }
        this.currentLicenseType = licenseTypeKey;

        // other
        this.maxConsumptionCountRef.value = defaultValue.maxConcurrentLogin;
        this.maxConcurrentCountRef.value = defaultValue.maxConcurrentLogin;
        this.trialDaysRef.value = undefined;
        this.expireDateRef.value = TimeUtil.convertTimeFormat(moment().add(defaultValue.expireDay, "d").hour(23).minute(59).second(59), TimeUtil.FULL_DATE_DISPLAY_FORMAT);

        // project
        this.shareProjectRef.checked = defaultValue.allowShareProject;
        this.projectCountRef.value = defaultValue.allowProjectCount;
        this.topicCountRef.value = defaultValue.allowTopicCount;

        // data
        this.dataIntervalLimitSecRef.value = defaultValue.allowDataIntervalLimitSec;
        this.exportDataRef.checked = defaultValue.allowExportData;
        this.viewableHistoryDayRef.value = defaultValue.allowViewableHistoryDay;

        // event trigger
        this.mqttTriggerRef.checked = defaultValue.allowMqttTrigger;
        this.webHookTriggerRef.checked = defaultValue.allowWebHookTrigger;
        this.dialogFlowTriggerREf.checked = defaultValue.allowDialogFlowTrigger;
        this.mqttActionRef.checked = defaultValue.allowMqttAction;
        this.iftttActionRef.checked = defaultValue.allowIftttAction;

        // project map
        this.dynamicLocationUpdateRef.checked = defaultValue.allowDynamicLocationUpdate;
        this.embedProjectMapRef.checked = defaultValue.allowEmbedProjectMap;

        // chart
        this.circlePointMapRef.checked = defaultValue.allowCirclePointMap;
        this.embedChartRef.checked = defaultValue.allowEmbedChart;

        // AI
        this.aiRef.checked = defaultValue.allowAi;

        this.setState({selectedLicenseType: licenseTypeKey})

    }


    renderBasicSetting() {
        return <React.Fragment>
            <div className={styles.inputGroupHeader}>
                Basic Setting
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-text-input" className="col-4 col-form-label">
                    Number of License
                </label>
                <div className="col-8">
                    <input className="form-control m-input"
                           autoFocus={true}
                           ref={(licenseCountRef) => {
                               this.licenseCountRef = licenseCountRef
                           }} min={1} defaultValue={1} type="number"/>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    License Type
                </label>
                <div className="col-8">
                    <div
                        onClick={() => {
                            this.changeLicenseType(LicenseType.custom.key)
                        }}
                        className={ClassNames(styles.licenseTypeButton, {[styles.active]: this.state.selectedLicenseType === LicenseType.custom.key})}>
                        {LicenseType.custom.name}
                    </div>
                    <div
                        onClick={() => {
                            this.changeLicenseType(LicenseType.basicP1.key)
                        }}
                        className={ClassNames(styles.licenseTypeButton, {[styles.active]: this.state.selectedLicenseType === LicenseType.basicP1.key})}>
                        {LicenseType.basicP1.name}
                    </div>
                    <div
                        onClick={() => {
                            this.changeLicenseType(LicenseType.basicP2.key)
                        }}
                        className={ClassNames(styles.licenseTypeButton, {[styles.active]: this.state.selectedLicenseType === LicenseType.basicP2.key})}>
                        {LicenseType.basicP2.name}
                    </div>
                    <div
                        onClick={() => {
                            this.changeLicenseType(LicenseType.fullP1.key)
                        }}
                        className={ClassNames(styles.licenseTypeButton, {[styles.active]: this.state.selectedLicenseType === LicenseType.fullP1.key})}>
                        {LicenseType.fullP1.name}
                    </div>
                    <div
                        onClick={() => {
                            this.changeLicenseType(LicenseType.fullP2.key)
                        }}
                        className={ClassNames(styles.licenseTypeButton, {[styles.active]: this.state.selectedLicenseType === LicenseType.fullP2.key})}>
                        {LicenseType.fullP2.name}
                    </div>
                </div>
            </div>

            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Max number of Consumption per license
                </label>
                <div className="col-8">
                    <input
                        className="form-control m-input"
                        ref={(maxConsumptionCountRef) => {
                            this.maxConsumptionCountRef = maxConsumptionCountRef
                        }} min={1} defaultValue={72} type="number"/>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Max number of Concurrent Login per license
                </label>
                <div className="col-8">
                    <input
                        className="form-control m-input"
                        ref={(maxConcurrentCountRef) => {
                            this.maxConcurrentCountRef = maxConcurrentCountRef
                        }} min={1} defaultValue={6} type="number"/>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Expire in (after the first consumption)
                </label>
                <div className="col-8">
                    <input
                        placeholder={"relative days (eg: 7)"}
                        className={ClassNames("form-control m-input", styles.expireDateInput)}
                        ref={(trialDaysRef) => {
                            this.trialDaysRef = trialDaysRef
                        }} type="number"/>
                    <div className={styles.expireDateInputOr}>Or</div>
                    <input
                        placeholder={"absolute date (eg: 2020-04-29 23:59:59"}
                        className={ClassNames("form-control m-input", styles.expireDateInput)}
                        style={{width: "250px"}}
                        ref={(ref) => {
                            this.expireDateRef = ref
                        }} type="text"/>
                </div>
            </div>
        </React.Fragment>
    }

    renderProjectSetting() {
        return <React.Fragment>
            <div className={styles.inputGroupHeader}>
                Project
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Share Project
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.shareProjectRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Project Count
                </label>
                <div className="col-8">
                    <input
                        className="form-control m-input"
                        ref={(ref) => {
                            this.projectCountRef = ref
                        }} type="number"/>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Topic Count
                </label>
                <div className="col-8">
                    <input
                        className="form-control m-input"
                        ref={(ref) => {
                            this.topicCountRef = ref
                        }} type="number"/>
                </div>
            </div>
        </React.Fragment>
    }

    renderDataSetting() {
        return <React.Fragment>
            <div className={styles.inputGroupHeader}>
                Data
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Data Interval Limit (Second)
                </label>
                <div className="col-8">
                    <input
                        className="form-control m-input"
                        ref={(ref) => {
                            this.dataIntervalLimitSecRef = ref
                        }} type="number"/>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Export Data
                </label>
                <div className="col-8">

                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.exportDataRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Viewable History (Days)
                </label>
                <div className="col-8">
                    <input
                        className="form-control m-input"
                        ref={(ref) => {
                            this.viewableHistoryDayRef = ref
                        }} type="number"/>
                </div>
            </div>
        </React.Fragment>
    }

    renderEventTriggerSetting() {
        return <React.Fragment>
            <div className={styles.inputGroupHeader}>
                Event Trigger
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    MQTT Trigger
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.mqttTriggerRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Webhook Trigger
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.webHookTriggerRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    DialogFlow Trigger
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.dialogFlowTriggerREf = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    MQTT Action
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.mqttActionRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    IFTTT Action
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.iftttActionRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
        </React.Fragment>
    }

    renderProjectMapSetting() {
        return <React.Fragment>
            <div className={styles.inputGroupHeader}>
                Project Map
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Dynamic Location Update
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.dynamicLocationUpdateRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Embed Project map
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.embedProjectMapRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
        </React.Fragment>
    }

    renderChartSetting() {
        return <React.Fragment>
            <div className={styles.inputGroupHeader}>
                Chart
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Circle Point Map
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.circlePointMapRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    Embed Chart
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.embedChartRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
        </React.Fragment>
    }

    renderAiSetting() {
        return <React.Fragment>
            <div className={styles.inputGroupHeader}>
                AI
            </div>
            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                <label htmlFor="example-search-input" className="col-4 col-form-label">
                    AI Feature
                </label>
                <div className="col-8">
                    <label className={styles.checkBoxContainer}>
                        <input type={"checkbox"} className={styles.inputCheckbox}
                               ref={(ref) => {
                                   this.aiRef = ref
                               }}/>
                        <span className={styles.checkboxCheckMark}/>
                    </label>
                </div>
            </div>
        </React.Fragment>
    }

    render() {
        return <div>
            <div className={"mb-3 mt-3 p-3 " + styles.formWrapper}>

                <div className="m-portlet m-portlet--tab">
                    <form className="m-form m-form--fit m-form--label-align-right">
                        <div className="m-portlet__body pt-1">

                            <div className={styles.titleWrapper}>

                                <div className={styles.title}>
                                    Create License in batch
                                </div>
                                <div className={styles.subTitle}>
                                    Each batch of license is licensed to a single license target. you cannot edit again
                                    after it is created.
                                </div>
                            </div>
                            <hr/>


                            {this.renderBasicSetting()}
                            {this.renderProjectSetting()}
                            {this.renderDataSetting()}
                            {this.renderEventTriggerSetting()}
                            {this.renderProjectMapSetting()}
                            {this.renderChartSetting()}
                            {this.renderAiSetting()}

                            <div className={ClassNames("form-group m-form__group row", styles.inputRow)}>
                                <label htmlFor="example-search-input" className="col-4 col-form-label">
                                    Licensing to
                                    <div className={styles.refreshButton} onClick={this.getLicenseTargetList}>
                                        <i className="fa fa-sync-alt"/>
                                    </div>
                                </label>
                                <div className="col-8">
                                    <div className="pt-2 m-form__group form-group">
                                        {
                                            this.state.licenseTargets && this.state.licenseTargets.map((licenseTarget: LicenseTarget) => {
                                                return <div key={licenseTarget.id} className="m-radio-list">
                                                    <label className="m-radio m-radio--bold">
                                                        <input type="radio" name={"licenseTarget"}
                                                               defaultChecked={this.state.selectedLicenseTargetId === licenseTarget.id}
                                                               onChange={this.handleOptionChange}
                                                               defaultValue={licenseTarget.id}/>
                                                        {licenseTarget.targetName} BY {licenseTarget.companyName}
                                                        <span/>
                                                    </label>
                                                </div>
                                            })
                                        }

                                        <span className="m-form__help">
        {"Cannot find the License Target? Create "}
                                            <Link
                                                to={Page.internals.urls.license.createLicenseTarget()}>Here
        </Link>
        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__foot m-portlet__foot--fit">
                            <div className="m-form__actions">
                                <div className="row">
                                    <div className="col-4">
                                    </div>
                                    <div className="col-8">
                                        {
                                            <button type="button" className="btn btn-success"
                                                    onClick={this.create}>Submit
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>;
    }
}

export default LicenseCreateFragment;