// @flow

import React from 'react';
import _ from 'lodash';
import Api from "../../../bundle/Api";
import swal from "sweetalert2";
import Page from "../../../bundle/Page";
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import {LLMsg} from "../../../IntlCapture";


type DeleteDashboardButtonProps = {
    dashboard: ?{ dashboardId: string, dashboardName: string },

    location?: Object,
    history?: Object,
    match?: Match
}

const deleteDashboardPrompt = (dashboardId: string, dashboardName: string, routerHistory: any) => {

    if (!dashboardId || !dashboardName) {
        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        return;
    } else {
        swal({
            title: LLMsg("COMMON.DASHBOARD.DELETE_DASHBOARD"),
            type: 'warning',
            showCancelButton: !0,
            confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
            cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        }).then((e) => {
            if (e.dismiss) {
                return;
            }
            Api.services.dashboard.delete(dashboardId).then(response => {
                if (response.status === 200) {
                    swal(LLMsg("COMMON.ACTION.REMOVE_SUCCESS"), "", "success");
                    routerHistory.push(Page.internals.urls.misc.home());
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch(() => {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            });
        });


    }
};

const DeleteDashboardButton = (props: DeleteDashboardButtonProps) => {
    let dashboardId = _.get(props, "dashboard.dashboardId", "");
    let dashboardName = _.get(props, "dashboard.dashboardName", "");

    return <li className="m-portlet__nav-item">
        <span data-container="body" data-toggle="m-tooltip" data-placement="top" title={""}
              data-original-title={LLMsg("COMMON.DASHBOARD.DELETE_DASHBOARD")}
              onClick={(e) => {
                  deleteDashboardPrompt(dashboardId, dashboardName, props.history);
              }}
              className="m-portlet__nav-link m-portlet__nav-link--icon cursor-pointer">
            <i className="fa fa-times"/>
        </span>
    </li>;
};

export default withRouter(DeleteDashboardButton);