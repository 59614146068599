// @flow

import React from 'react';
import _ from 'lodash';
import Api from "../../../bundle/Api";
import swal from "sweetalert2";
import {withRouter} from "react-router";
import type {Match} from "../../../App";
import {createAction} from "../../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import Constant from "../../../bundle/Constant";
import {LLMsg} from "../../../IntlCapture";


type RenameDashboardButtonProps = {
    dashboard: ?{ dashboardId: string, dashboardName: string },

    dispatchDashboardRenamed?: () => void,
    location?: Object,
    history?: Object,
    match?: Match
}

const renameDashboardPrompt = (dashboardId: string, dashboardName: string, dispatchDashboardRenamed: ? any) => {

    if (!dashboardId || !dashboardName) {
        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        return;
    } else {

        swal({
            title: LLMsg("COMMON.DASHBOARD.UPDATE_DASHBOARD"),
            input: 'text',
            inputValue: dashboardName,
            inputPlaceholder: LLMsg("COMMON.DASHBOARD.DASHBOARD_PLACEHOLDER"),
            showCancelButton: true,
            confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
            cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value) {
                        resolve()
                    } else {
                        resolve(LLMsg("COMMON.DASHBOARD.DASHBOARD_NAME_EMPTY"));
                    }
                })
            }
        }).then((e) => {
            if (e.dismiss) {
                return;
            }
            if (e.value) {
                Api.services.dashboard.update.name(dashboardId, e.value).then(response => {
                    if (response.status === 200) {
                        swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success");
                        dispatchDashboardRenamed && dispatchDashboardRenamed();
                    } else {
                        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                    }
                }).catch(() => {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                });
            } else {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            }
        });

    }
};

const RenameDashboardButton = (props: RenameDashboardButtonProps) => {
    let dashboardId = _.get(props, "dashboard.dashboardId", "");
    let dashboardName = _.get(props, "dashboard.dashboardName", "");

    return <li className="m-portlet__nav-item">
        <span data-container="body" data-toggle="m-tooltip" data-placement="top" title={""}
              data-original-title={LLMsg("COMMON.DASHBOARD.UPDATE_DASHBOARD")}
              onClick={() => {
                  renameDashboardPrompt(dashboardId, dashboardName, props.dispatchDashboardRenamed);
              }}
              className="m-portlet__nav-link m-portlet__nav-link--icon cursor-pointer">
            <i className={Constant.theme.edit.icon}/>
        </span>
    </li>;
};

const mapDispatchToProps = dispatch => ({
    dispatchDashboardRenamed: () => dispatch(createAction(Api.actions.dashboard.DASHBOARD_RENAMED))
});

export default withRouter(connect(
    null,
    mapDispatchToProps
)(RenameDashboardButton));