// @flow

import React from 'react';
import Popup from "reactjs-popup";
import styles from './index.css';
import {LLMsg} from "../../../IntlCapture";
import Api from "../../../bundle/Api";
import Constant from "../../../bundle/Constant";

type State = {
    dsn: string,
    dn: string,
    lat: string,
    lng: string,
    topicName: string,
    dataTypeKey: string,
    value: string
}

export default class TopicMessageSendingToolFragment extends React.Component <any, State> {

    state = {}

    constructor(props) {
        super(props);
        this.onTopicNameChanged = this.onTopicNameChanged.bind(this);
        this.onDnChanged = this.onDnChanged.bind(this);
        this.onDsnChanged = this.onDsnChanged.bind(this);
        this.onLatChanged = this.onLatChanged.bind(this);
        this.onLngChanged = this.onLngChanged.bind(this);
        this.onDataTypeKeyChanged = this.onDataTypeKeyChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }


    getResult(): string {
        let resultArray: Array<string> = [];
        if (this.state.dn) {
            resultArray.push("_dn=" + this.state.dn);
        }
        if (this.state.dsn) {
            resultArray.push("_dsn=" + this.state.dsn);
        }
        if (this.state.lat) {
            resultArray.push("_lat=" + this.state.lat);
        }
        if (this.state.lng) {
            resultArray.push("_lng=" + this.state.lng);
        }
        if (this.state.dataTypeKey && this.state.value) {
            resultArray.push(this.state.dataTypeKey + "=" + this.state.value);
        } else if (this.state.value) {
            resultArray.push(this.state.value);
        }
        return resultArray.join(",");
    }

    renderButton() {
        return <div className={"btn btn-link m-btn m-btn--custom"}>
            {LLMsg("COMMON.TOPIC.MQTT_SEND_MESSAGE_TOOL")}
        </div>;
    }

    onTopicNameChanged(e) {
        let value = e.target.value;
        this.setState({topicName: value});
    }

    onLatChanged(e) {
        let value = e.target.value;
        this.setState({lat: value});
    }

    onLngChanged(e) {
        let value = e.target.value;
        this.setState({lng: value});
    }

    onDsnChanged(e) {
        let value = e.target.value;
        this.setState({dsn: value});
    }

    onDnChanged(e) {
        let value = e.target.value;
        this.setState({dn: value});
    }

    onDataTypeKeyChanged(e) {
        let value = e.target.value;
        this.setState({dataTypeKey: value});
    }

    onValueChanged(e) {
        let value = e.target.value;
        this.setState({value: value});
    }

    sendMessage() {
        let message = this.getResult();

        Api.services.topic.doSendNewMessageToTopicByTopicName(this.state.topicName, "", message).then((response) => {
            if (response.status === 200) {
                window.toastr.options = Constant.toastr.defaultOptions;
                window.toastr.success(LLMsg("COMMON.TOPIC.TOPIC_MESSAGE_SENT"));
            } else {
                window.toastr.options = Constant.toastr.defaultOptions;
                window.toastr.error(LLMsg("COMMON.ACTION.ACTION_FAILED"));
            }
        }).catch(() => {
            window.toastr.options = Constant.toastr.defaultOptions;
            window.toastr.error(LLMsg("COMMON.ACTION.ACTION_FAILED"));
        });
    }

    renderPopup(close) {
        return <div className={styles.wrapper}>
            <h3 className={"mb-3"}>
                {LLMsg("COMMON.TOPIC.MQTT_SEND_MESSAGE_TOOL")}
            </h3>
            <div className="form-group m-form__group mt-4">
                <label htmlFor="exampleInputEmail1">{LLMsg("COMMON.TOPIC.TOPIC_NAME")}</label>
                <input type="email" className="form-control m-input"
                       onChange={this.onTopicNameChanged}
                       value={this.state.topicName}
                       placeholder={LLMsg("COMMON.TOPIC.TOPIC_NAME")}/>
            </div>
            <div className="form-group m-form__group mt-4">
                <label htmlFor="exampleInputEmail1">{LLMsg("COMMON.DEVICE.DEVICE")}</label>
                <div className={"row"}>
                    <div className={"col"}>
                        <input type="email" className="form-control m-input"
                               onChange={this.onDsnChanged}
                               value={this.state.dsn} placeholder={LLMsg("COMMON.DEVICE.DEVICE_SERIAL_NUMBER")}/>
                    </div>
                    <div className={"col"}>
                        <input type="email" className="form-control m-input"
                               onChange={this.onDnChanged}
                               value={this.state.dn}
                               placeholder={LLMsg("COMMON.DEVICE.DEVICE_NAME")}/>
                    </div>
                </div>
            </div>
            <div className="form-group m-form__group mt-4">
                <label htmlFor="exampleInputEmail1">{LLMsg("PERMISSION.DYNAMIC_LOCATION_UPDATE")}</label>
                <div className={"row"}>
                    <div className={"col"}>
                        <input type="email" className="form-control m-input"
                               onChange={this.onLatChanged}
                               value={this.state.lat} placeholder={LLMsg("COMMON.CHART.LATITUDE")}/>
                    </div>
                    <div className={"col"}>
                        <input type="email" className="form-control m-input"
                               onChange={this.onLngChanged}
                               value={this.state.lng}
                               placeholder={LLMsg("COMMON.CHART.LONGITUDE")}/>
                    </div>
                </div>
            </div>
            <div className="form-group m-form__group mt-4">
                <label htmlFor="exampleInputEmail1">{LLMsg("PERMISSION.DATA")}</label>
                <div className={"row"}>
                    <div className={"col"}>
                        <input type="email" className="form-control m-input "
                               value={this.state.dataTypeKey}
                               onChange={this.onDataTypeKeyChanged}
                               placeholder={LLMsg("COMMON.DATA_TYPE.DATA_TYPE")}/>
                    </div>
                    <div className={"col"}>
                        <input type="email" className="form-control m-input "
                               value={this.state.value}
                               onChange={this.onValueChanged}
                               placeholder={LLMsg("PERMISSION.DATA")}/>
                    </div>
                </div>
            </div>
            <div className="form-group m-form__group mt-4">
                <label htmlFor="exampleInputEmail1">{LLMsg("EVENT_TRIGGER.MESSAGE")}</label>
                <div className={styles.result}>
                    {this.getResult()}
                </div>
            </div>
            <div className={"text-right"}>
                <button type="button" onClick={this.sendMessage} className="btn btn-primary m-btn--wide">
                    {LLMsg("COMMON.ACTION.SEND")}
                </button>
            </div>
        </div>
    }

    render() {
        return <Popup
            trigger={
                this.props.children || this.renderButton()
            }
            modal
            closeOnDocumentClick
        >
            {close => (
                this.renderPopup(close)
            )}
        </Popup>;
    }
}