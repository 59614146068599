// @flow

import React, {Component} from 'react';
import styles from './SchoolInfoItem.css';

type SchoolInfoItemWrapperProps = {
    children: any
}

class SchoolInfoItemWrapper extends Component<SchoolInfoItemWrapperProps, any> {

    render() {
        return <div className={styles.itemWrapper}>
            {this.props.children}
        </div>
    }
}

export default SchoolInfoItemWrapper;