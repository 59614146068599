// @flow

import type {ToObject} from "./PageUrl";
import PageUrl from "./PageUrl";
import UrlBuilder from "../UrlBuilder";
import StringUtil from "../../util/StringUtil";

export default class ChartPageUrl {


    static getLineChartPageUrl(roomName: string): ToObject {
        return {
            pathname: PageUrl.CHARTS_LINE_CHART_URL,
            search: "?" + UrlBuilder.toQueryString({"rm": roomName})
        };
    }

    static getChartCreatePageUrl(projectId: ?string, topicId: ?string, dataRecordTypeId: ?string, deviceId: ?string): string {
        return PageUrl.CHARTS_CREATE_URL;
    }

    static getChartDetailPageUrl(chartId: string, chartName: string) {

        return StringUtil.replaceAllText(PageUrl.CHARTS_DETAIL_URL, [
            {
                key: ":chartId",
                value: chartId + ""
            }, {
                key: ":chartName",
                value: chartName ? StringUtil.removeBlacklistChar(chartName) : "detail"
            }
        ]);
    }


    static getEmbeddedChartUrl(token: string): string {
        return StringUtil.replaceAllText(PageUrl.CHART_EMBEDDED_URL, [
            {
                key: ":token",
                value: token
            }
        ]);
    }

}