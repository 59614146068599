// @flow

import React, {Component} from 'react';
import HeaderFragment from "../../header/HeaderFragment";
import BodyWrapper from "../../body/BodyWrapper";
import SidebarWrapper from "../../sidebar/SidebarWrapper";
import GeneralSidebarFragment from "../../sidebar/GeneralSidebarFragment";
import ContentWrapper from "../../content/ContentWrapper";
import ContentHeaderFragment from "../../content/ContentHeaderFragment";
import FooterFragment from "../../footer/FooterFragment";
import BackToTopButton from "../../BackToTopButton";
import StickyQuickNav from "../../SickyQuickNav";
import ContentInnerWrapper from "../../content/ContentInnerWrapper";
import Breadcrumb from "../../content/Breadcrumb";
import AdminWhiteListUserListFragment from "../../admin/user/AdminWhiteListEmailListFragment";

class AdminUserListPage extends Component<any, any> {
    render() {
        return <div className="m-grid m-grid--hor m-grid--root m-page">
            <HeaderFragment/>
            <BodyWrapper>
                <SidebarWrapper>
                    <GeneralSidebarFragment/>
                </SidebarWrapper>
                <ContentWrapper>
                    <ContentHeaderFragment displayText={"Admin: whitelist user"}>
                        <Breadcrumb/>

                    </ContentHeaderFragment>
                    <ContentInnerWrapper>
                        <AdminWhiteListUserListFragment/>
                    </ContentInnerWrapper>
                </ContentWrapper>
            </BodyWrapper>
            <FooterFragment/>
            <BackToTopButton/>
            <StickyQuickNav/>
        </div>
    }
}

export default AdminUserListPage;