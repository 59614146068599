// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux'
import _ from "lodash";
import ProjectListTableRecord from "./ProjectListTableRecord";
import ProjectListCreateProjectButton from "./ProjectListCreateProjectButton";
import ProjectListRefreshtButton from "./ProjectListRefreshtButton";
import ProjectListTableNoRecord from "./ProjectListTableNoRecord";
import Api from "../../../bundle/Api";
import {LLMsg} from "../../../IntlCapture";
import Image from "../../../bundle/Image";

type ProjectListFragmentProps = {
    getProjectList?: Function,
    projects?: Object
}


class ProjectListFragment extends Component<ProjectListFragmentProps, any> {

    constructor(props) {
        super(props);
        this.getProjectList();

    }

    getProjectList = () => {
        this.props.getProjectList && this.props.getProjectList();
    };

    render() {

        const projects = _.get(this.props, "projects.payload.projects", []);

        return <div className="m-portlet m-portlet--mobile">
            <div className="m-stack m-stack--hor m-stack--general" >
                <div className="m-stack__item m-stack__item--center">
                    <img src={Image.urls.getProjectWallpaperImage()} width={"100%"}/>
                </div>
            </div>
            <div className="m-portlet__head">

                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                        <h3 className="m-portlet__head-text">
                            {LLMsg("COMMON.PROJECT.PROJECT_LIST")}
                        </h3>
                    </div>
                </div>
                <div className="m-portlet__head-tools">
                    <ul className="m-portlet__nav">
                        <ProjectListRefreshtButton callback={this.getProjectList}/>
                        <ProjectListCreateProjectButton/>
                    </ul>
                </div>
            </div>
            <div className="m-portlet__body pt-0">
                <div id="m_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                        <div className="col-sm-12">
                            <table
                                className="table table-hover table-checkable dataTable no-footer dtr-inline"
                                id="m_table_1" role="grid" aria-describedby="m_table_1_info" style={{width: 1065}}>

                                <tbody>
                                {
                                    (!projects || projects.length === 0) && <ProjectListTableNoRecord/>
                                }
                                {
                                    projects && _.sortBy(projects, ["id"]).map((project, index) => {
                                        return <ProjectListTableRecord key={index} projectId={project.id}
                                                                       index={index + 1}
                                                                       topics={project.topics}
                                                                       projectName={project.projectName}/>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = state => {
    return {
        projects: state.ProjectReducer[Api.actions.project.GET_LIST_OF_PROJECT_ACTION_TYPE]
    };
};

const mapDispatchToProps = dispatch => ({
    getProjectList: () => Api.dispatchers.project.doGetListOfProject(dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectListFragment);
