exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1OFkExUfVOXrIzp2WpBM-w {\n    height: 380px;\n}\n\n.sfDSugB9g6yAJ60BaDh7s {\n    display: block;\n    font-size: 24px;\n    margin: 10px 20px;\n    text-align: left;\n    font-weight: bold;\n}\n\n._1SMlMR0xq61-WgkKgVj3O9 {\n    padding: 20px;\n    text-align: center;\n}\n\n.nbepYhF9Aw0s-LPXK3zdg {\n    width: 100%;\n    text-align: center;\n    margin: 10px;\n    color: #172a70;\n}\n\n.F7TXr5_padJHYPKdoxuAn {\n    display: inline-block;\n    width: 200px;\n    font-size: 16px;\n    text-align: right;\n    padding-right: 20px;\n}\n\n._2AO3Ekkzw9_ySLjSVX_ab {\n    display: inline-block;\n    width: 200px;\n}\n\n._2LtBxHfHeq6eMCAs9FFSN1 {\n    text-align: right;\n    margin: 20px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_1OFkExUfVOXrIzp2WpBM-w",
	"title": "sfDSugB9g6yAJ60BaDh7s",
	"content": "_1SMlMR0xq61-WgkKgVj3O9",
	"timeDifferentArrow": "nbepYhF9Aw0s-LPXK3zdg",
	"label": "F7TXr5_padJHYPKdoxuAn",
	"value": "_2AO3Ekkzw9_ySLjSVX_ab",
	"footer_button": "_2LtBxHfHeq6eMCAs9FFSN1"
};