// @flow

import React, {Component} from "react";

type SidebarDropdownProps = {
    titleDisplayName: string,
    iconClassName: string,
    colorCode: string,
    children?: any
}

class SidebarDropdown extends Component<SidebarDropdownProps, any> {
    render() {

        const {titleDisplayName, iconClassName, colorCode} = this.props;
        return <li className="m-menu__item  m-menu__item--submenu"
                   aria-haspopup="true">
            <a href="javascript:;" className="m-menu__link m-menu__toggle"
               title={titleDisplayName}>
                <i className={iconClassName + " fa-2x"}
                   style={{color: colorCode}}/>
                <span
                    className="m-menu__link-text sidebar_menu_span" style={{color: colorCode, fontWeight: 900}}>{titleDisplayName}</span>
                <i className="m-menu__ver-arrow la la-angle-right"/>
            </a>
            <div className="m-menu__submenu ">
                <span className="m-menu__arrow"/>

                <ul className="m-menu__subnav">
                    <li className="m-menu__item  m-menu__item--parent" aria-haspopup="true">
                        <span
                            className="m-menu__link"><span className="m-menu__link-text">{titleDisplayName}</span>
                        </span>
                    </li>
                    {this.props.children}
                </ul>
            </div>
        </li>

    }
}

export default SidebarDropdown;