exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2reimPYa1jdWuhnczGdOBv {\n    font-size: 1.8em;\n    margin-bottom: 7px;\n}\n\n._16y7rxB5PUHf4dICqC_jBJ {\n    float: right;\n    margin-bottom: 7px;\n}\n\n._16y7rxB5PUHf4dICqC_jBJ button {\n    margin-left: 10px;\n}\n\n.NNWohnx6RtDhuTPprSc1w {\n    height: 480px;\n}", ""]);

// exports
exports.locals = {
	"title": "_2reimPYa1jdWuhnczGdOBv",
	"actionBox": "_16y7rxB5PUHf4dICqC_jBJ",
	"projectDetailPageHeight": "NNWohnx6RtDhuTPprSc1w"
};