exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2dONclyZhK_fi8aJCdAAp1 {\n    width: 180px;\n    font-weight: bold;\n    display: inline-block;\n    padding: 3px 10px;\n}\n\n._2dvOj1g6h-6mnEecvatj3p {\n    display: inline-block;\n}\n\n._3Y8HVjghG--Ekll_fHgt9I {\n}", ""]);

// exports
exports.locals = {
	"title": "_2dONclyZhK_fi8aJCdAAp1",
	"content": "_2dvOj1g6h-6mnEecvatj3p",
	"row": "_3Y8HVjghG--Ekll_fHgt9I"
};