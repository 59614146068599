exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Y9lxnrOYRCaykLjATNTVE {\n\n}\n\n._2t5GbWrKb9HBILBCuA-AY4 {\n    border-left: 5px solid #0a6aa1;\n    padding: 5px 20px;\n    font-size: 20px;\n}\n\n._28iFFtijWwf97KjwCw787- {\n    margin-top: 10px;\n    margin-bottom: 15px;\n    padding: 15px;\n    font-size: 18px;\n\n}\n\n._3SUaHofMm62EXMA9xeF-SQ {\n    padding-top: 10px;\n    padding-bottom: 10px;\n    font-size: smaller;\n}\n\n._20oTnBn0e323Z8RqfhTmXZ {\n    display: inline;\n    padding: 10px;\n    background-color: rgba(9, 30, 66, 0.08);\n    margin-left: 10px;\n    margin-right: 10px;\n    border: none;\n\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n}\n\n._3v1RvnwZYlmJ3REQUZYYMe {\n\n}\n\n._1GUXajB_xi2xl2611nITSC {\n    margin-top: 10px;\n}\n\n._1LmGBzioDvdrid8szLrNuz {\n\n    font-size: 1.75rem;\n    line-height: 34px;\n    display: inline;\n    font-weight: 500;\n}\n\n._1j3nLju6OE_dMCWo_iYlFk {\n    color: #0a6aa1;\n    font-size: 1.75rem;\n    line-height: 34px;\n    display: inline;\n    margin-left: 20px;\n}\n\n.BitDw38ELRd3L66aof83b {\n    border-left: 5px solid #0a6aa1;\n    padding: 5px 20px;\n    font-size: 20px;\n    margin-top: 20px;\n    margin-bottom: 30px;\n}\n\n._2R6cL9hKyRREkWkK24AUgr {\n    cursor: pointer;\n    display: block;\n    padding: 20px;\n    border: 1px solid #d7d8d6;\n    margin-bottom: 30px;\n}", ""]);

// exports
exports.locals = {
	"titleBox": "_1Y9lxnrOYRCaykLjATNTVE",
	"title": "_2t5GbWrKb9HBILBCuA-AY4",
	"titleBoxDesc": "_28iFFtijWwf97KjwCw787-",
	"apiKeyBox": "_3SUaHofMm62EXMA9xeF-SQ",
	"apiKey": "_20oTnBn0e323Z8RqfhTmXZ",
	"apiGroup": "_3v1RvnwZYlmJ3REQUZYYMe",
	"apiItemDesc": "_1GUXajB_xi2xl2611nITSC",
	"apiItemTitle": "_1LmGBzioDvdrid8szLrNuz",
	"apiItemTitleArrow": "_1j3nLju6OE_dMCWo_iYlFk",
	"apiGroupTitle": "BitDw38ELRd3L66aof83b",
	"apiItemBox": "_2R6cL9hKyRREkWkK24AUgr"
};