// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import {saveAs} from 'file-saver';
import type {CreateLicenseGenerationBatch} from "../model/model";

class LicenseService {

    static downloadLicenseFile(filename: string): Object {
        return HttpUtil.ajax(Api.urls.license.downloadLicenseFile(filename), Api.methods.get, {
            'Content-Type': 'application/json',
        }, undefined, "blob").then((response) => {
            if (response.status === 200) {
                saveAs(response.data, filename);
            }
        })
    }

    static list(): Object {
        return HttpUtil.ajax(Api.urls.license.LicenseList(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static getOverallInfo(): Object {
        return HttpUtil.ajax(Api.urls.license.overallInfo(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static getLicenseTargetList(): Object {

        return HttpUtil.ajax(Api.urls.license.licenseTargetList(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static getLicenseTargetDetail(licenseTargetId: number): Object {
        return HttpUtil.ajax(Api.urls.license.licenseTargetDetail(licenseTargetId), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static getLicenseBatchDetail(licenseBatchId: string): Object {
        return HttpUtil.ajax(Api.urls.license.licenseBatchDetail(licenseBatchId), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static createLicenseTarget(packageName: string, companyName: string): Object {
        return HttpUtil.ajax(Api.urls.license.createLicenseTarget(), "POST", {
            'Content-Type': 'application/json',
        }, {targetName: packageName, companyName});
    }

    static consumeLicense(licenseKey: string): Object {
        return HttpUtil.ajax(Api.urls.license.consumeLicense(), "POST", {
            'Content-Type': 'application/json',
        }, {licenseKey});
    }

    static getLicenseDetail(licenseKey: string): Object {
        return HttpUtil.ajax(Api.urls.license.getLicenseDetail(licenseKey), "GET", {
            'Content-Type': 'application/json',
        }, {licenseKey});
    }

    static updateLicenseTarget(licenseTargetId: number, packageName: string, companyName: string): Object {
        return HttpUtil.ajax(Api.urls.license.updateLicenseTarget(), "POST", {
            'Content-Type': 'application/json',
        }, {licenseTargetId, targetName: packageName, companyName});
    }

    static batchCreate(createLicenseGenerationBatch: CreateLicenseGenerationBatch): Object {
        return HttpUtil.ajax(Api.urls.license.batchCreate(), "POST", {
            'Content-Type': 'application/json',
        }, {
            ...createLicenseGenerationBatch
        });
    }
}

export default LicenseService;