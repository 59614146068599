// @flow

import React, {Component} from "react";

class SidebarWrapper extends Component<any, any> {
    render() {
        return <React.Fragment>
            <button className="m-aside-left-close  m-aside-left-close--skin-light "
                    id="m_aside_left_close_btn"><i className="la la-close"/></button>
            <div id="m_aside_left" className="m-grid__item	m-aside-left  m-aside-left--skin-light ">
                {this.props.children}
            </div>
        </React.Fragment>;
    }
}

export default SidebarWrapper;