// @flow

import React from 'react';
import swal from "sweetalert2";
import Image from "../../bundle/Image";
import Api from "../../bundle/Api";
import Page from "../../bundle/Page";
import {withRouter} from "react-router";
import {LLMsg} from "../../IntlCapture";

type CreateDashboardButtonProps = {
    match: Object,
    location: Object,
    history: Object
}

export const createDashboardPrompt = (routerHistory: any) => {

    swal({
        title: LLMsg("COMMON.DASHBOARD.CREATE_DASHBOARD"),
        imageUrl: Image.urls.getDashboardImage(),
        imageWidth: 400,
        input: 'text',
        inputValue: "",
        showCancelButton: !0,
        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
        cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        inputValidator: (value) => {
            return !value && LLMsg("COMMON.DASHBOARD.DASHBOARD_NAME_EMPTY")
        }
    }).then((e) => {
        if (e.dismiss) {
            return;
        }
        Api.services.dashboard.create(e.value).then(response => {
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), "", "success").then((e) => {
                    routerHistory.push(Page.internals.urls.dashboard.getDashboardPageUrl(response.data.id, response.data.name))
                });
            } else {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            }
        }).catch(() => {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        });
    });
};

const CreateDashboardButton = (props: CreateDashboardButtonProps) => {

    return <button type="button" className="btn btn-outline-info m-btn m-btn--pill m-btn--wide btn-sm" onClick={(e) => {

        createDashboardPrompt(props.history);
    }}>
        {LLMsg("COMMON.DASHBOARD.CREATE_DASHBOARD")}
    </button>;
};


export default withRouter(CreateDashboardButton);