// @flow


import OpenApi from "../../bundle/OpenApi";

export default class SwaggerUrlBuilder {
    static getImageRecognition(): string {
        return OpenApi.paths.AI_IMAGE_RECOGNITION;
    }

    static getImageClassification(): string {
        return OpenApi.paths.AI_IMAGE_CLASSIFICATION;
    }

    static getProject(): string {
        return OpenApi.paths.MC_PROJECT;
    }

}
