// @flow

import React from 'react';
import styles from './ConfirmCreateAppletButton.css';
import {LLMsg} from "../../../IntlCapture";

type DeleteAppletButtonProps = {
    deleteCallback: () => void
}

class DeleteAppletButton extends React.Component<DeleteAppletButtonProps, any> {
    render() {
        return <div className={styles.buttonWrapper} onClick={() => {
            this.props.deleteCallback();
        }}>
            <button type="button" className="btn">
                {LLMsg("COMMON.ACTION.DELETE")}
            </button>
        </div>;
    }
}

export default DeleteAppletButton;