exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Z8DdWc-erQIQIt0-VO0BT {\n    display: inline-block;\n    padding-top: 5px;\n}\n\n._2rrY3EBpUOmS6-8fkBAIMO {\n    margin-left: 10px;\n}\n\n.YSGCU1tCIc9EAB4kFiBCK:hover {\n    background-color: #0a6aa1 !important;\n    color: white;\n}\n\n.cRe6Gj0ACcp3gQ77fbeD6 {\n}\n\n._3d12fum1TEcJwyDdY2g9Et {\n}\n\n._23AaMXE7hULYDyJlKfu3DG {\n    margin-bottom: 0;\n    /*padding-left: 10px;*/\n}\n\n._1t0MF6Ab6TwAD0BQTe6aE2[aria-selected=\"true\"] {\n    font-weight: bold;\n    font-size: larger;\n    border-top: 5px solid #faa040;\n    border-left: 1px solid lightgrey;\n    border-right: 1px solid lightgrey;\n\n}\n\n._1t0MF6Ab6TwAD0BQTe6aE2 {\n    display: inline-block;\n    border-radius: 5px 5px 0 0;\n    cursor: pointer;\n    font-size: 16px;\n    padding: 10px 20px;\n    text-decoration: none;\n    background-color: white;\n    margin-right: 5px;\n    text-align: center;\n    border-top: 1px solid #efefef;\n    border-left: 1px solid #efefef;\n    border-right: 1px solid #efefef;\n}\n\n.YSGCU1tCIc9EAB4kFiBCK {\n    border-left: 5px solid #0a6aa1;\n    padding: 10px 20px;\n    font-size: 20px;\n    margin-top: 20px;\n    margin-right: 5px;\n    margin-bottom: 30px;\n}\n\n._2wizikZuP4bbJk4Nmqh9Ti {\n    background-color: white;\n}\n\n._38j1tTnMp3lzZfLeUVBJZb {\n\n    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;\n}\n\n._1wnaCxQgu1eyFltdddshM9 {\n\n    margin-top: 100px;\n    margin-bottom: 100px;\n    text-align: center;\n}\n\n._3j1AuJYal7fVoAb0_RNB_A {\n\n    width: 50%;\n    max-width: 400px;\n\n    -moz-user-select: none;\n    -webkit-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    -o-user-select: none;\n}\n", ""]);

// exports
exports.locals = {
	"topicName": "_2Z8DdWc-erQIQIt0-VO0BT",
	"topicNameInput": "_2rrY3EBpUOmS6-8fkBAIMO",
	"topicTitle": "YSGCU1tCIc9EAB4kFiBCK",
	"tabWrapper": "cRe6Gj0ACcp3gQ77fbeD6",
	"tabContentWrapper": "_3d12fum1TEcJwyDdY2g9Et",
	"tabHeaderWrapper": "_23AaMXE7hULYDyJlKfu3DG",
	"tab": "_1t0MF6Ab6TwAD0BQTe6aE2",
	"tableHead": "_2wizikZuP4bbJk4Nmqh9Ti",
	"table": "_38j1tTnMp3lzZfLeUVBJZb",
	"noTopicImageBox": "_1wnaCxQgu1eyFltdddshM9",
	"noTopicImage": "_3j1AuJYal7fVoAb0_RNB_A"
};