exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._155UjFjOFn7IBJBNjrEYe1 {\n\n    background-color: #fff;\n    background-clip: border-box;\n    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;\n    padding: 40px;\n    height: 600px;\n    /*min-width: 500px;*/\n    overflow-x: auto;\n    overflow-y: hidden;\n    margin-bottom: 20px;\n}\n\n._1ixlqN6ZVXgaShbn9w6kvp {\n    margin-top: 50px;\n    margin-bottom: 50px;\n    height: 380px;\n}\n\n._33LG7b72wIQm6ZXAZyQqtd {\n    border-left: 5px solid #0a6aa1;\n    padding: 5px 20px;\n    font-size: 20px;\n    margin-bottom: 40px;\n}\n\n\n.FmFFAdqz50zCDKB4COiyx {\n    position: absolute;\n    top: 35px;\n    right: 35px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_155UjFjOFn7IBJBNjrEYe1",
	"chart": "_1ixlqN6ZVXgaShbn9w6kvp",
	"title": "_33LG7b72wIQm6ZXAZyQqtd",
	"explainButton": "FmFFAdqz50zCDKB4COiyx"
};