// @flow

import React, {Component} from 'react';
import SchoolApplicationTableRow from "./SchoolApplicationTableRow";


type SchoolApplicationTableContentProps = {
    schoolApplications: Array<Object>
};

class SchoolApplicationTableContent extends Component<SchoolApplicationTableContentProps, any> {

    render() {

        return <tbody className="m-datatable__body">
        {
            this.props.schoolApplications && this.props.schoolApplications.map((schoolApplication, index) => {
                return <SchoolApplicationTableRow
                    key={index}
                    id={schoolApplication.id}
                    applicationStatus={schoolApplication.status}
                    createdOn={schoolApplication.createdOn}
                    eventName={schoolApplication.event.name}
                    eventCode={schoolApplication.event.eventCode}
                    eventEnd={schoolApplication.event.endUntil}
                    eventStart={schoolApplication.event.startFrom}

                    teacherName={schoolApplication.teacher.name}
                    teacherTel={schoolApplication.teacher.tel}
                    teacherRoleRemarks={schoolApplication.teacher.roleRemarks}
                    teacherRole={schoolApplication.teacher.role}
                    teacherEmail={schoolApplication.teacher.email}

                />
            })
        }
        </tbody>;
    }
}

export default SchoolApplicationTableContent;