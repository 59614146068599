// @flow

import React from 'react';
import Constant from "../../../bundle/Constant";
import Api from "../../../bundle/Api";
import {deleteChartPrompt} from "../ChartDropdownFragment/DeleteChartDropdownButton";
import {createAction} from "../../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../../IntlCapture";

type RenameChartButtonProps = {

    dispatchChartDeleted?: Function,
    chartId: string
}

const DeleteChartButton = (props: RenameChartButtonProps) => {

    return <button className="btn btn-outline-danger m-btn " onClick={() => {
        deleteChartPrompt(props.chartId, props.dispatchChartDeleted);
    }}>
        <span>
            <i className={Constant.theme.delete.icon}/>

            <span className={"m--hidden-mobile ml-2"}>
                {LLMsg("COMMON.ACTION.DELETE")}
            </span>
        </span>
    </button>;
};

const mapDispatchToProps = dispatch => ({
    dispatchChartDeleted: () => dispatch(createAction(Api.actions.chart.DELETED_CHART))
});

export default connect(null, mapDispatchToProps)(DeleteChartButton);
