// @flow

import React from "react";
import {Link} from "react-router-dom";
import Image from "../../bundle/Image";

const HeaderLogo = () => {

    const alt = "Maker Cloud Icon";

    return <div className="m-stack__item m-stack__item--middle m-brand__logo">
        <Link to="/" className="m-brand__logo-wrapper">
            <img width={"150px"} alt={alt} src={Image.urls.getMakerCloudWhiteIcon()}/>
        </Link>
    </div>;
};

export default HeaderLogo;