exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1GM1RygR07HzEUn3e6T_wF:hover {\n    background-color: grey !important;\n    color: white;\n}\n\n._1GM1RygR07HzEUn3e6T_wF {\n    position: relative;\n    text-align: center;\n    width: 30px;\n    height: 30px;\n    line-height: 30px;\n    border-radius: 15px;\n    vertical-align: middle;\n    cursor: pointer;\n    color: white;\n    border: 2px solid #9dc1d3;\n}\n\n._2QwodUzaNozKYuAv3CMZV0, .NZGtQMoWzPwhGiXVY_Uqc {\n    width: 50%;\n    display: inline-block;\n    padding: 10px;\n    border: 3px solid #eeeeee;\n    background-color: white;\n    text-align: center;\n}\n\n._2QwodUzaNozKYuAv3CMZV0:hover, .NZGtQMoWzPwhGiXVY_Uqc:hover {\n    border: 1px solid #403f76;\n}\n\n.jj1RvdlpSEszuI6IEE5bw {\n    background-color: #eeeeee;\n    padding: 10px 25px;\n    margin-bottom: 0px;\n    font-size: 20px;\n}\n\n.hhWaIoWsKIvN-po-tsmK6 {\n    background-color: #eeeeee;\n    cursor: pointer;\n    margin-bottom: 15px;\n    font-size: 20px;\n    padding: 0px 10px 5px 10px;\n}\n", ""]);

// exports
exports.locals = {
	"addNewCondition": "_1GM1RygR07HzEUn3e6T_wF",
	"addNewConditionItemSameLevel": "_2QwodUzaNozKYuAv3CMZV0",
	"addNewConditionItemSubLevel": "NZGtQMoWzPwhGiXVY_Uqc",
	"addNewConditionItemLabel": "jj1RvdlpSEszuI6IEE5bw",
	"addNewConditionItemContentWrapper": "hhWaIoWsKIvN-po-tsmK6"
};