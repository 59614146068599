// @flow

import React from 'react';
import LoadingUtil from "../../../../util/LoadingUtil";
import ErrorUtil from "../../../../util/ErrorUtil";
import Api from "../../../../bundle/Api";
import {withRouter} from "react-router";
import _ from "lodash";
import TimeUtil from "../../../../util/TimeUtil";
import styles from "./index.css";
import type {Event} from "../../../../model/model";
import Constant from "../../../../bundle/Constant";
import {LLMsg} from "../../../../IntlCapture";

type EventCodeDetailFragmentState = {
    event: ?Event,
    edit: boolean
}

type EventCodeDetailFragmentProps = {
    match?: Object,
    location?: Object,
    history?: Object
}

class EventCodeDetailFragment extends React.Component<EventCodeDetailFragmentProps, EventCodeDetailFragmentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            event: undefined,
            edit: false
        }
    }

    constructItem(title: any, content: any) {
        return <div className={styles.row}>
            <div className={styles.title}>
                {title}
            </div>
            <div
                className={styles.content}>
                {content}
            </div>
        </div>
    }

    constructEditItem(title: any, content: any, updateCallback: Function) {
        return <div className={styles.row}>
            <div className={styles.title}>
                {title}
            </div>
            <div
                className={styles.content}>
                <input
                    className={"form-control m-input"}
                    type="text"
                    defaultValue={content}
                    onChange={(event) => {
                        updateCallback(event.target.value);
                    }}
                />
            </div>
        </div>;
    }

    getEventDetail = () => {

        const eventCodeId = this.props.match && this.props.match.params["eventCodeId"];

        LoadingUtil.showFullScreenLoading();
        Api.services.eventCode.getEventCodeDetail(eventCodeId || "").then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({event: response.data, edit: false});
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
            LoadingUtil.hideFullScreenLoading();
        });
    };

    componentDidMount(props: any) {
        this.getEventDetail();
    }

    updateForm = () => {
        this.setState({edit: false});

        let eventId = this.state.event && this.state.event.id || "";
        let startTime = TimeUtil.convertTimeFormat(this.startTime, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        let endUntil = TimeUtil.convertTimeFormat(this.endUntil, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        let regEndDate = TimeUtil.convertTimeFormat(this.regEndDate, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);

        LoadingUtil.showFullScreenLoading();
        Api.services.eventCode.updateEventCode(
            eventId, this.eventCode, this.eventName, startTime, endUntil, regEndDate
        ).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                window.toastr.options = Constant.toastr.defaultOptions;
                window.toastr.success("Updated!");
                this.getEventDetail();

            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    };

    eventCode: string = "";
    eventName: string = "";
    startTime: string = "";
    endUntil: string = "";
    regEndDate: string = "";

    render() {

        let event: ?Event = this.state.event;

        this.eventCode = _.get(event, "eventCode", "");
        this.eventName = _.get(event, "name", "");
        this.startTime = TimeUtil.convertTimeFormat(_.get(event, "startFrom", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        this.endUntil = TimeUtil.convertTimeFormat(_.get(event, "endUntil", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        this.regEndDate = _.get(event, "userRegistrationEndDate", "");

        return <div className="m-portlet">
            <div className="m-portlet__body">
                {
                    !this.state.edit && <div className="m-section">
                        {this.constructItem("Event Code", _.get(event, "eventCode", ""))}
                        {this.constructItem("Event Name", _.get(event, "name", ""))}
                        {this.constructItem("Event Start Time", TimeUtil.convertTimeFormat(_.get(event, "startFrom", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT))}
                        {this.constructItem("Event End Until", TimeUtil.convertTimeFormat(_.get(event, "endUntil", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT))}
                        {this.constructItem("User Registration End Date", TimeUtil.convertTimeFormat(_.get(event, "userRegistrationEndDate", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT))}
                    </div>
                }
                {
                    this.state.edit && <div className="m-section">
                        {
                            this.constructEditItem("Event Code", _.get(event, "eventCode", ""), (value: string) => {
                                this.eventCode = value
                            })
                        }
                        {
                            this.constructEditItem("Event Name", _.get(event, "name", ""), (value: string) => {
                                this.eventName = value
                            })
                        }
                        {
                            this.constructEditItem("Event Start Time", TimeUtil.convertTimeFormat(_.get(event, "startFrom", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT), (value: string) => {
                                this.startTime = value
                            })
                        }
                        {
                            this.constructEditItem("Event End Until", TimeUtil.convertTimeFormat(_.get(event, "endUntil", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT), (value: string) => {
                                this.endUntil = value
                            })
                        }
                        {
                            this.constructEditItem("User Registration End Date", TimeUtil.convertTimeFormat(_.get(event, "userRegistrationEndDate", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT), (value: string) => {
                                this.regEndDate = value
                            })
                        }
                    </div>
                }
                <div>
                    {
                        !this.state.edit && <button type="button" className="btn btn-success" onClick={() => {
                            this.setState({edit: true});
                        }}>
                            Edit
                        </button>
                    }
                    {
                        this.state.edit && <button type="button" className="btn btn-success" onClick={() => {
                            this.updateForm();
                        }}>
                            Update
                        </button>
                    }
                    {
                        this.state.edit && <button type="button" className="btn btn-secondary" onClick={() => {
                            this.setState({edit: false});
                        }}>
                            Cancel
                        </button>
                    }
                </div>
            </div>
        </div>;
    }
}

export default withRouter(EventCodeDetailFragment);