// @flow
import React from 'react';
import styles from './EventCodeConsumeFragment.css';
import ErrorUtil from "../../util/ErrorUtil";
import Api from "../../bundle/Api";
import swal from "sweetalert2";
import {LLMsg} from "../../IntlCapture";
import Page from "../../bundle/Page";
import {RouterHistory} from "../../AppRoute";
import Image from "../../bundle/Image";

type EventCodeConsumeFragmentState = {
    eventCodeInputValue: string
}

class EventCodeConsumeFragment extends React.Component<any, EventCodeConsumeFragmentState> {


    constructor(props: any) {
        super(props);
        this.state = {eventCodeInputValue: ""}
    }

    consumeEventCode = () => {
        let eventCode = this.state.eventCodeInputValue;
        if (eventCode) {
            Api.services.eventCode.consumeEventCode(eventCode).then((response) => {
                if (response.status === 200) {

                    swal(LLMsg("COMMON.ACTION.ADD_SUCCESS"), "", "success").then(() => {
                        RouterHistory().push(Page.internals.urls.misc.home());
                        window.location.reload();
                    });
                }
            }).catch((e) => {
                ErrorUtil.promptError(e);
            })
        }
    };

    render() {
        return <div className={"m-portlet m-portlet--unair " + styles.box}>
            <div className="m-portlet__body pt-2">
                <div>
                    <div className={styles.imageBox}>
                        <img src={Image.urls.getRegisterWithEventCodeImage()} width={100}/>

                    </div>
                    <div className={styles.title}>
                        {LLMsg("MY_SUB.ENTER_EVENT_CODE")}
                    </div>
                    <div className="input-group">
                        <input type="text"
                               className={"form-control m-input "}
                               onChange={(e) => {
                                   this.setState({eventCodeInputValue: (e.target.value || "").toUpperCase()})
                               }}
                               value={this.state.eventCodeInputValue}
                               placeholder={LLMsg("MY_SUB.EVENT_CODE")}
                        />
                    </div>
                    <div className={styles.desc}>
                        {LLMsg("MY_SUB.ENTER_EVENT_CODE_HINTS")}
                    </div>
                    <button type="button"
                            className={"btn btn-success " + styles.submitBtn}
                            onClick={this.consumeEventCode}
                    >
                        {LLMsg("MY_SUB.SUBMIT_BUTTON")}
                    </button>
                </div>
            </div>
        </div>

    }
}

export default EventCodeConsumeFragment;