// @flow

import React from 'react';
import styles from "./action.css";
import StringUtil from "../../../util/StringUtil";
import {DataRecordType} from "../../../model/model";
import {LLMsg} from "../../../IntlCapture";
import EventTriggerItemFragment from "../DataTriggerFragment/EventTriggerItemFragment";
import Constant from "../../../bundle/Constant";
import MessageInputWrapper from "../DataTriggerFragment/EventTriggerItemFragment/MessageInputWrapper";
import _ from "lodash";

type PublishMqttMessageActionFragmentProps = {
    index: number,
    displayIndex: number,
    publishContent: ? string,
    topicName: ? string,
    topics: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }>,
    onDeleteCallback: (index: number) => void,
    handleMessageValueChange: (message: string, index: number) => void,
    handleTopicNameChange: (topicName: string, index: number) => void

}
type PublishMqttMessageActionFragmentState = {
    topicNameError: boolean,
    publishContentError: boolean
}

class PublishMqttMessageActionFragment extends React.Component<PublishMqttMessageActionFragmentProps, PublishMqttMessageActionFragmentState> {

    constructor(props: PublishMqttMessageActionFragmentProps) {
        super(props);

        this.state = {
            topicNameError: false,
            publishContentError: false
        };

    }

    validateAction = (): boolean => {
        let valid = true;
        let publishContentError = false;
        let topicNameError = false;
        if (StringUtil.isOnlySpaceOrEmpty(this.props.publishContent)) {
            valid = false;
            publishContentError = true;
        }
        if (StringUtil.isOnlySpaceOrEmpty(this.props.topicName)) {
            valid = false;
            topicNameError = true;
        }
        this.setState({
            publishContentError: publishContentError, topicNameError: topicNameError
        });
        return valid;
    };

    handleMessageValueChange = (publishContent: string) => {

        this.props.handleMessageValueChange(publishContent, this.props.index);
        this.setState({
            publishContentError: false
        })
    };

    handleTopicNameChange = (e: any) => {

        let topicName = e.target.value;
        this.props.handleTopicNameChange(topicName, this.props.index);
        this.setState({
            topicNameError: false
        })
    };

    render() {


        return <span className={styles.actionItem}>

            <EventTriggerItemFragment
                allowDelete={true}
                backgroundColor={Constant.theme.eventTrigger.action.color}
                titleContent={
                    <span>
                        {LLMsg("EVENT_TRIGGER.PUBLISH")}
                        <MessageInputWrapper
                            className={styles.actionTextInput}
                            showError={this.state.publishContentError || this.state.topicNameError}
                            placeholder={LLMsg("EVENT_TRIGGER.MESSAGE")}
                            onChange={this.handleMessageValueChange}
                            value={this.props.publishContent}
                            index={this.props.index}
                            topics={_.sortBy(this.props.topics, ['id'])}
                        />
                        {LLMsg("EVENT_TRIGGER.TO_TOPIC")}

                        <select
                            className={
                                "form-control form-inline m-input m-input--air "
                                + styles.topicNameInput +
                                (this.state.topicNameError ? " " + styles.errorInput : "")
                            }
                            value={this.props.topicName}
                            onChange={this.handleTopicNameChange}
                        >

                            <option value={""}>
                                {LLMsg("EVENT_TRIGGER.TOPIC_NAME")}
                            </option>
                            {
                                this.props.topics && this.props.topics.map((topicDropdownValue, index) => {
                                    return <option key={index}
                                                   value={topicDropdownValue.topicName}>
                                        {topicDropdownValue.topicName + " (" + topicDropdownValue.description + ")"}
                                    </option>;
                                })
                            }
                        </select>
                            </span>
                }
                onDelete={() => {
                    this.props.onDeleteCallback(this.props.index);
                }}
            />


        </span>;
    }
}

export default PublishMqttMessageActionFragment;