// @flow


import React, {Component} from "react";
import _ from "lodash";
import {LLMsg} from "../../../IntlCapture";


class CreateTopicDataTypeFragment extends Component<any, any> {

    dataTypeRefs: Array<{
        dataRecordNameInput: any,
        dataRecordKeyInput: any,
        dataRecordDescriptionInput: any
    }> = [{
        dataRecordNameInput: React.createRef(),
        dataRecordKeyInput: React.createRef(),
        dataRecordDescriptionInput: React.createRef()
    }];


    getSelectedResult = (): Array<{ name: string, key: string, description: string }> => {

        return _.remove(this.dataTypeRefs.map(dataTypeRef => {
            if (!dataTypeRef) {
                return null;
            }
            if (!dataTypeRef.dataRecordNameInput.value && !dataTypeRef.dataRecordKeyInput.value && !dataTypeRef.dataRecordDescriptionInput.value) {
                return null;
            }
            return {
                name: dataTypeRef.dataRecordNameInput.value,
                key: dataTypeRef.dataRecordKeyInput.value,
                description: dataTypeRef.dataRecordDescriptionInput.value,
            }
        }), (o => {
            return !!o
        }));
    };

    clearSelectedResult = () => {
        this.dataTypeRefs = [{
            dataRecordNameInput: React.createRef(),
            dataRecordKeyInput: React.createRef(),
            dataRecordDescriptionInput: React.createRef()
        }];
        this.forceUpdate();
    };

    addDataType() {
        this.dataTypeRefs.push({
            dataRecordNameInput: React.createRef(),
            dataRecordKeyInput: React.createRef(),
            dataRecordDescriptionInput: React.createRef()
        });
        this.forceUpdate();
    }

    removeDataType(index: number) {
        delete this.dataTypeRefs[index];
        this.forceUpdate();
    }


    render() {
        return <div className="form-group m-form__group row">
            <label className="col-form-label col-3">{LLMsg("COMMON.DATA_TYPE.DATA_TYPE_LIST")}</label>

            <div className="col-9 form-inline">
                {
                    this.dataTypeRefs.map((dataTypeRef, index) => {
                        if (!dataTypeRef) {
                            return null;
                        }
                        return <div key={index} className="form-group mb-2">
                            <input className="form-control m-input" type="text" id="data_type_name_input"
                                   placeholder={LLMsg("COMMON.DATA_TYPE.DATA_TYPE_NAME")}
                                   defaultValue={dataTypeRef.dataRecordNameInput.value}
                                   ref={(dataRecordNameInput) => {
                                       dataTypeRef.dataRecordNameInput = dataRecordNameInput
                                   }}/>
                            <input className="form-control" type="text" maxLength="10" id="data_type_key_input"
                                   placeholder={LLMsg("COMMON.DATA_TYPE.DATA_TYPE_KEY")}
                                   defaultValue={dataTypeRef.dataRecordKeyInput.value}
                                   ref={(dataRecordKeyInput) => {
                                       dataTypeRef.dataRecordKeyInput = dataRecordKeyInput
                                   }}/>
                            <input className="form-control m-input" type="text"
                                   placeholder={LLMsg("COMMON.DATA_TYPE.DATA_TYPE_DESC")}
                                   ref={(dataRecordDescriptionInput) => {
                                       dataTypeRef.dataRecordDescriptionInput = dataRecordDescriptionInput
                                   }}/>
                            {
                                index !== this.dataTypeRefs.length - 1 &&
                                <button className="btn  btn-link m-btn m-btn--icon m-btn--icon-only" onClick={(e) => {
                                    this.removeDataType(index)
                                }}>
                                    <i className="fa fa-minus"/>
                                </button>
                            }
                            {
                                index === this.dataTypeRefs.length - 1 &&
                                <button className="btn  btn-link m-btn m-btn--icon m-btn--icon-only" onClick={(e) => {
                                    this.addDataType()
                                }}>
                                    <i className="fa fa-plus"/>
                                </button>
                            }
                        </div>;
                    })
                }
            </div>
        </div>;
    }
};


export default CreateTopicDataTypeFragment;