exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1LRY0VqwCzWGzTZizBd3ik {\n    width: 50%;\n    max-width: 500px;\n\n    margin-bottom: 20px;\n    -moz-user-select: none;\n    -webkit-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    -o-user-select: none;\n}\n\n._1WNhWj6AAORhXqSm1FGujb {\n    margin-top: 100px;\n    margin-bottom: 100px;\n    text-align: center;\n}\n\n._2dl45R3SI5g6xxQH20N77D {\n    text-align: right;\n    padding-bottom: 20px;\n}", ""]);

// exports
exports.locals = {
	"noChartImage": "_1LRY0VqwCzWGzTZizBd3ik",
	"noChartImageBox": "_1WNhWj6AAORhXqSm1FGujb",
	"createButtonBox": "_2dl45R3SI5g6xxQH20N77D"
};