exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sgaaxRuJMMRYAkuuqLAuZ {\n\n    margin: 10px 15px 5px 25px;\n    color: #585661;\n}\n\n._2WeEzXHTD18tBvjjr5g53C {\n\n    border-color: #f4516c;\n    color: #f4516c;\n    background-color: #F7F7F9;\n    font-weight: 400;\n    margin-top: 10px;\n}\n\n._1Umk4bi0ZhFfcQpGo_4Y6W {\n    margin-top: 20px;\n    font-size: 1.2em;\n}\n\n._2ji_84b7FSazp27MJjaQBT{\n    margin-bottom: 10px !important;\n}\n\n._16AibVcFyZWP3fd-_zt4hI {\n    font-size: 1.5em;\n}\n\n.qdkEtPLQtgnDa_Jo4LmSG {\n    border-radius: 40px;\n    border: none;\n    padding: 5px 1.1rem;\n    margin-top: 1.3rem;\n    background-color: #F7F7F9;\n}\n\n._1VDWUmraiHR8aprfkavIEn {\n    border-radius: 40px;\n    border: none;\n    padding: 5px 1.1rem;\n    margin-top: 1.3rem;\n    background-color: #F7F7F9;\n}\n\n.ZXQ4UiWmbyxANgQQyy6C1 {\n    padding-left: 25px !important;\n    margin-bottom: 5px;\n}", ""]);

// exports
exports.locals = {
	"expiryNotice": "sgaaxRuJMMRYAkuuqLAuZ",
	"errorPrompt": "_2WeEzXHTD18tBvjjr5g53C",
	"sectionTitle": "_1Umk4bi0ZhFfcQpGo_4Y6W",
	"formTitle": "_2ji_84b7FSazp27MJjaQBT",
	"groupLabel": "_16AibVcFyZWP3fd-_zt4hI",
	"dropdown": "qdkEtPLQtgnDa_Jo4LmSG",
	"teachingRoleBox": "_1VDWUmraiHR8aprfkavIEn",
	"teachingRoleRemarks": "ZXQ4UiWmbyxANgQQyy6C1"
};