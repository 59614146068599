// @flow

import React from 'react';
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import SocketIOService from "../../../service/SocketIOService";
import Constant from "../../../bundle/Constant";
import _ from "lodash";
import type {ChartConfig, ChartMessage, ChartSource} from "../../../model/model";
import styles from './index.css';

type PlainTextChartFragmentProps = {
    sources: Array<ChartSource>,
    chartId: string,
    title: string,
    config: ChartConfig,
    sizingClass?: string
}

class PlainTextChartFragment extends React.Component<PlainTextChartFragmentProps, any> {

    valueRef: any = React.createRef();

    componentDidMount() {
        this.setupSocketEvent();
    }

    componentWillUnmount() {
        let socket: any = SocketIOService.getSocket();
        this.props.sources.forEach(source => {
            socket.emit(Constant.socket.data.room.leave, "T" + source.topic.id);
        });
    }

    setupSocketEvent() {

        if (!this.props.sources || this.props.sources.length <= 0) {
            this.setState({hasData: false});
            return;
        }

        let socket: any = SocketIOService.getSocket();
        this.props.sources.forEach((source: ChartSource) => {
            socket.emit(Constant.socket.data.room.join, "T" + source.topic.id);
        });
        socket.on(Constant.socket.data.room.newData, (data) => {
            let dataObj: ChartMessage = SocketIOService.extractChartDataMessage(data);
            let source: ChartSource = this.props.sources[0];
            console.log("source=", source, "dataObj=", dataObj);

            if (source.topic.id !== dataObj.topicId) return;
            if (source.device && source.device.id !== dataObj.deviceId) return;
            if (!source.dataRecordType) return;
            if (!_.get(dataObj, "records[" + source.dataRecordType.keyName + "]", undefined)) return;

            if (this.valueRef) this.valueRef.innerHTML = _.get(dataObj, "records[" + source.dataRecordType.keyName + "]", undefined);
        });
    }

    render() {
        return <div className={this.props.sizingClass || "portlet_height_1x"}>
            <Link to={Page.internals.urls.chart.getChartDetailPageUrl(this.props.chartId, this.props.title)}
                  className={"mb-2 m--icon-font-size-lg5 no-a-effect"}>{this.props.title}</Link>
            <div className="m-stack m-stack--ver m-stack--general">
                <div
                    ref={(ref) => {
                        this.valueRef = ref;
                    }}
                    className={"m-stack__item m-stack__item--center m-stack__item--middle + " + styles.value}
                    style={{color: this.props.config.color}}
                >
                    N/A
                </div>
            </div>
        </div>;

    }
}

export default PlainTextChartFragment;