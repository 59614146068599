// @flow


import SwaggerUrl from "../url/swagger/SwaggerUrl";
import SwaggerUrlBuilder from "../url/swagger/SwaggerUrlBuilder";

class OpenApi {

    static paths = SwaggerUrl;
    static urls = SwaggerUrlBuilder;
}

export default OpenApi;