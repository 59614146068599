// @flow

import React from 'react';
import styles from "./action.css";
import StringUtil from "../../../util/StringUtil";
import {LLMsg} from "../../../IntlCapture";
import EventTriggerItemFragment from "../DataTriggerFragment/EventTriggerItemFragment";
import Constant from "../../../bundle/Constant";
import shareGoogleSheet from './share_google_sheet.png';

type Props = {
    index: number,
    displayIndex: number,
    spreadsheetId: ? string,
    onDeleteCallback: (index: number) => void,
    handleSpreadsheetIdChange: (topicName: string, index: number) => void
}

type State = {
    spreadsheetIdError: boolean,
    spreadsheetUrl: string,
}

class GoogleSheetUpdateActionFragment extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            spreadsheetIdError: false,
            spreadsheetUrl: "",
        };

        this.spreadsheetUrlInputRef = React.createRef();
    }

    spreadsheetUrlInputRef;

    validateAction = (): boolean => {
        let valid = true;
        let spreadsheetIdError = false;
        if (StringUtil.isOnlySpaceOrEmpty(this.props.spreadsheetId)) {
            valid = false;
            spreadsheetIdError = true;
        }
        this.setState({
            spreadsheetIdError: spreadsheetIdError
        });
        return valid;
    };

    extractSpreadsheetId = (url: string) => {
        // url example: https://docs.google.com/spreadsheets/d/****/edit#gid=0
        const regex = /https:\/\/docs\.google\.com\/spreadsheets\/d\/(.*)\/.*/i;
        const found = url.match(regex);
        return found ? found[1] : "";
    }

    generateSpreadsheetLink = (spreadsheetId: string) => {
        return "https://docs.google.com/spreadsheets/d/" + spreadsheetId + "/edit";
    }

    handleSpreadsheetIdValueChange = () => {

        let spreadsheetUrl = this.spreadsheetUrlInputRef.current.value;

        const spreadsheetId = this.extractSpreadsheetId(spreadsheetUrl);
        if (spreadsheetId) {
            this.props.handleSpreadsheetIdChange(spreadsheetId, this.props.index);
            this.setState({
                spreadsheetIdError: false
            })
        } else {
            this.setState({
                spreadsheetIdError: true
            })
        }
    };

    render() {

        return <span className={styles.actionItem}>
            <EventTriggerItemFragment
                allowDelete={true}
                backgroundColor={Constant.theme.eventTrigger.action.color}
                titleContent={
                    <span>
                        {LLMsg("EVENT_TRIGGER.UPDATE_GOOGLE_SHEET")}
                    </span>
                }
                extraContent={
                    <div style={{fontSize: "smaller"}}>
                        <div className={"mb-3"}>
                            <div>
                                {LLMsg("EVENT_TRIGGER.ALLOW_EDIT_GOOGLE_SHEET")}
                            </div>
                            <img src={shareGoogleSheet} width={450} className={"mt-2"}
                                 alt={"share google spreadsheet"}/>
                        </div>
                        {
                            !this.props.spreadsheetId && <div className={"mb-3"}>
                                {LLMsg("EVENT_TRIGGER.GOOGLE_SHEET_URL")} :
                                <div className={"row mt-3"}>
                                    <div className={"col-8"}>
                                        <input
                                            className={styles.actionTextInput + " form-control" +
                                            (this.state.spreadsheetIdError ? " " + styles.errorInput : "")}
                                            type="text"
                                            ref={this.spreadsheetUrlInputRef}
                                            placeholder={"https://docs.google.com/spreadsheets/d/***********/edit"}
                                            defaultValue={this.state.spreadsheetUrl}/>
                                    </div>
                                    <div className={"col-4"}>
                                        <button type={"button"}
                                                className={"btn m-btn"}
                                                onClick={this.handleSpreadsheetIdValueChange}>
                                            {LLMsg("EVENT_TRIGGER.GET_GOOGLE_SHEET_ID")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.props.spreadsheetId && (
                                <div>
                                    <i className="far fa-check-circle fa-lg mr-3" style={{color: "lightgreen"}}/>
                                    {LLMsg("EVENT_TRIGGER.GOOGLE_SHEET_URL")} :
                                    <a target={"_blank"}
                                       className={"no-a-effect"}
                                       href={this.generateSpreadsheetLink(this.props.spreadsheetId || "")}
                                       style={{
                                           display: "block",
                                           fontStyle: "italic",
                                           fontSize: "small"
                                       }}>
                                        {this.props.spreadsheetId || ""}
                                        <i className="fas fa-external-link-alt ml-2"/>
                                    </a>
                                    <button type={"button"}
                                            className={"btn m-btn btn-sm mt-3 mb-3"}
                                            onClick={() => {
                                                this.props.handleSpreadsheetIdChange("", this.props.index);
                                            }}>
                                        {LLMsg("EVENT_TRIGGER.CHANGE_GOOGLE_SHEET_URL")}
                                    </button>
                                </div>)
                        }
                    </div>
                }
                onDelete={() => {
                    this.props.onDeleteCallback(this.props.index);
                }}
            />
        </span>;
    }
}

export default GoogleSheetUpdateActionFragment;