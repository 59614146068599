// @flow

import React from 'react';
import CreateConditionButton from "./CreateConditionButton";
import _ from 'lodash';
import styles from './FilterFragment.css';
import AndOperatorFragment from "./AndOperatorFragment";
import OrOperatorFragment from "./OrOperatorFragment";
import {SUB_FILTER_TYPE, TOPIC_KEY_VALUE_MESSAGE_TYPE, TOPIC_MESSAGE_VALUE_TYPE} from "./FilterFragment";
import TopicSimpleMessageCondition from "./TopicSimpleMessageCondition";
import TopicKeyValueMessageCondition from "./TopicKeyValueMessageCondition";
import {DataRecordType} from "../../../model/model";
import Constant from "../../../bundle/Constant";

export type FilterCondition = {
    key: number,
    type: string,
    subConditionKeys?: Array<number>,
    conditionBetweenSubConditions?: Array<string>,
    topicName?: string,
    subjectKey?: string,
    comparatorKey?: string,
    publishContent?: string,

}

type ConditionFragmentProps = {

    currentRenderingConditionKey: number,
    conditionSet: Array<FilterCondition>,
    topics: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }>,
    filterId: string,

    onDeleteCallback: (conditionKey: number) => void,
    onAndOrOperatorChangedCallback: (conditionKey: number, operatorIndex: number) => void,

    createTopicTextContains: (conditionKey: number, sameLevel: boolean) => void,
    createTopicKeyValueCompare: (conditionKey: number, sameLevel: boolean) => void,

    updateInputField: (conditionKey: number, fieldName: string, fieldValue: string) => void
}

class ConditionFragment extends React.Component<ConditionFragmentProps, any> {

    constructor(props: ConditionFragmentProps) {
        super(props);

    }

    childConditionRefs: Array<any>;

    validateCondition = (): void => {
        this.childConditionRefs.forEach((ref) => {
            if (ref && ref.validateCondition) {
                ref.validateCondition();
            }
        });
    };

    render() {

        const currentCondition = _.find(this.props.conditionSet, (condition) => {
            return condition.key === this.props.currentRenderingConditionKey;
        });

        if (!currentCondition) {
            return <CreateConditionButton
                conditionKey={0}
                createTopicKeyValueCompare={this.props.createTopicKeyValueCompare}
                createTopicTextContains={this.props.createTopicTextContains}
            />;
        }

        let childConditionRefs: Array<any> = [];
        if (currentCondition.type === SUB_FILTER_TYPE) {
            currentCondition.subConditionKeys && currentCondition.subConditionKeys.forEach(() => {
                childConditionRefs.push(React.createRef());
            });
        } else {
            childConditionRefs.push(React.createRef());
        }

        this.childConditionRefs = childConditionRefs;


        if (currentCondition.type === SUB_FILTER_TYPE) {

            return <div className={styles.filterGroupOutside}>
                <div className={styles.filterGroup}>
                    {
                        currentCondition.subConditionKeys && currentCondition.subConditionKeys.map((subConditionKey, index) => {
                            return <div key={this.props.filterId + "_" + index}>
                                <ConditionFragment
                                    key={this.props.filterId + "_" + index}
                                    currentRenderingConditionKey={subConditionKey}
                                    filterId={this.props.filterId}
                                    conditionSet={this.props.conditionSet}
                                    onDeleteCallback={this.props.onDeleteCallback}
                                    onAndOrOperatorChangedCallback={this.props.onAndOrOperatorChangedCallback}
                                    updateInputField={this.props.updateInputField}
                                    topics={this.props.topics}
                                    createTopicKeyValueCompare={this.props.createTopicKeyValueCompare}
                                    createTopicTextContains={this.props.createTopicTextContains}
                                    ref={(ref) => {
                                        this.childConditionRefs[index] = ref;
                                    }}
                                />

                                {
                                    currentCondition.subConditionKeys &&
                                    index !== currentCondition.subConditionKeys.length - 1 &&
                                    currentCondition.conditionBetweenSubConditions &&
                                    currentCondition.conditionBetweenSubConditions[index] === "AND" &&
                                    <AndOperatorFragment conditionKey={currentCondition.key}
                                                         operatorIndex={index}
                                                         onChangeCallback={this.props.onAndOrOperatorChangedCallback}/>
                                }
                                {
                                    currentCondition.subConditionKeys &&
                                    index !== currentCondition.subConditionKeys.length - 1 &&
                                    currentCondition.conditionBetweenSubConditions &&
                                    currentCondition.conditionBetweenSubConditions[index] === "OR" &&
                                    <OrOperatorFragment conditionKey={currentCondition.key}
                                                        operatorIndex={index}
                                                        onChangeCallback={this.props.onAndOrOperatorChangedCallback}/>
                                }
                            </div>
                        })
                    }

                    <CreateConditionButton
                        conditionKey={currentCondition.key}
                        createTopicKeyValueCompare={this.props.createTopicKeyValueCompare}
                        createTopicTextContains={this.props.createTopicTextContains}/>

                    <div className={styles.wrapperAfter} style={{
                        borderTop: "10px solid " + Constant.theme.eventTrigger.filter.color
                    }}/>
                </div>
            </div>;

        } else if (currentCondition.type === TOPIC_MESSAGE_VALUE_TYPE) {
            return <TopicSimpleMessageCondition key={currentCondition.key}
                                                publishContent={currentCondition.publishContent || ""}
                                                comparatorKey={currentCondition.comparatorKey || ""}
                                                topicName={currentCondition.topicName || ""}
                                                topics={this.props.topics}
                                                ref={(ref) => {
                                                    this.childConditionRefs[0] = ref;
                                                }}
                                                onDeleteCallback={this.props.onDeleteCallback}
                                                updateInputField={this.props.updateInputField}
                                                conditionKey={currentCondition.key}/>;
        } else if (currentCondition.type === TOPIC_KEY_VALUE_MESSAGE_TYPE) {
            return <TopicKeyValueMessageCondition key={currentCondition.key}
                                                  publishContent={currentCondition.publishContent || ""}
                                                  subjectKey={currentCondition.subjectKey || ""}
                                                  comparatorKey={currentCondition.comparatorKey || ""}
                                                  topicName={currentCondition.topicName || ""}
                                                  topics={this.props.topics}
                                                  ref={(ref) => {
                                                      this.childConditionRefs[0] = ref;
                                                  }}
                                                  onDeleteCallback={this.props.onDeleteCallback}
                                                  updateInputField={this.props.updateInputField}
                                                  conditionKey={currentCondition.key}/>;
        }

        return null;
    }


}

export default ConditionFragment;