// @flow

import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";

export default class EventCodeApiUrlHelper {

    static consumeEventCode(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.EVENT_CODE_CONSUME_API, []);
    }

    static getEventCodeList(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.EVENT_CODE_LIST_API, []);
    }

    static getEventCodeDetail(eventCodeId: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.EVENT_CODE_DETAIL_API, [{
            key: ":eventCodeId",
            value: eventCodeId
        }])
    }

    static updateEventCodeDetail(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.EVENT_CODE_UPDATE_API, [])
    }
    static createEventCodeDetail(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.EVENT_CODE_CREATE_API, [])
    }
}