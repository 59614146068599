exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PIsCn5gCWQhKEIcNBSGRQ {\n    position: absolute;\n    top: 30px;\n    right: 30px;\n    padding: 10px;\n    cursor: pointer;\n    border-radius: 5px;\n}\n\n.PIsCn5gCWQhKEIcNBSGRQ:hover {\n    background-color: lightgrey;\n}\n\n._101ncf-xf5y3glSpu-nFX4 {\n    border-left: 5px solid #0a6aa1;\n    padding: 5px 20px;\n    font-size: 20px;\n}\n\n._1MOMK2vCFNBLwU3BOh7Pyz {\n    text-align: center;\n}\n\n._3H2RSPjBXLdGisnXV1LWZe {\n    height: 40px;\n}\n\n._15vo1cQCI-U8OIskUMgTxN {\n    width: 100%;\n    background-color: #fbfbfb !important;\n    color: #575962;\n}\n\n._15vo1cQCI-U8OIskUMgTxN thead tr th {\n    color: #0f0f11;\n}\n\n._1rsAiN7xgHzDMQBuOHtTJl {\n}\n\n._2xDnDY6J14IKcs37OrRrVR {\n    margin-top: 20px;\n    margin-bottom: 35px;\n}\n\n._2s57vmgZXae_Rmlq4sr4Cs {\n    margin: 2px 5px 2px 5px;\n    padding: 5px;\n    font-weight: 500;\n}\n\n._1nD2lZX_8v0hZaKmAuVjDS {\n    padding-right: 10px;\n    font-weight: 600;\n    width: 120px;\n}\n\n.GcittnonSuQ3jtqrBSwOb {\n    margin-right: 15px;\n    padding: 5px 10px 5px 10px;\n    font-weight: 500;\n    border: 1px solid #ccc;\n    background: #ffffff;\n\n}\n\n._3336_5K5UJDzZzkx70g5Z7 {\n    word-break: break-all;\n}\n\n._2KPGgLFKFQTl7tTkHXwoIU {\n    font-size: 2em;\n    text-align: center;\n    width: 100%;\n}\n\n._1yUqsE_Y6HjLr8xf1HjRkv {\n    text-align: center;\n    font-size: 1.1em;\n}\n\n\n._3jQtWAgWwaji8ZPF6KZAsp {\n    position: absolute;\n    top: 35px;\n    right: 70px;\n}\n\n", ""]);

// exports
exports.locals = {
	"expandButton": "PIsCn5gCWQhKEIcNBSGRQ",
	"header": "_101ncf-xf5y3glSpu-nFX4",
	"rightBox": "_1MOMK2vCFNBLwU3BOh7Pyz",
	"downloadBox": "_3H2RSPjBXLdGisnXV1LWZe",
	"table": "_15vo1cQCI-U8OIskUMgTxN",
	"messageBoxBody": "_1rsAiN7xgHzDMQBuOHtTJl",
	"messageBox": "_2xDnDY6J14IKcs37OrRrVR",
	"message": "_2s57vmgZXae_Rmlq4sr4Cs",
	"messageDate": "_1nD2lZX_8v0hZaKmAuVjDS",
	"messageTopic": "GcittnonSuQ3jtqrBSwOb",
	"messageContent": "_3336_5K5UJDzZzkx70g5Z7",
	"noMessage": "_2KPGgLFKFQTl7tTkHXwoIU",
	"tooManyMessageWarning": "_1yUqsE_Y6HjLr8xf1HjRkv",
	"explainButton": "_3jQtWAgWwaji8ZPF6KZAsp"
};