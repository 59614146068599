// @flow

import React from 'react';
import Constant from "../../../../bundle/Constant";
import GaugeConfigFragment from "./GaugeConfigFragment";
import PlainTextConfigFragment from "./PlainTextConfigFragment";
import ToggleConfigFragment from "./ToggleConfigFragment";
import LineChartConfigFragment from "./LineChartConfigFragment";
import ButtonConfigFragment from "./ButtonConfigFragment";
import PointMapConfigFragment from "./PointMapConfigFragment";

type WidgetConfigFragmentProps = {
    chartType: string,
    show: boolean,
    innerRef: any
}

class WidgetConfigFragment extends React.Component<WidgetConfigFragmentProps, any> {

    render() {

        let content;
        if (this.props.chartType === Constant.chart.type.basicLineChart) {
            content = <LineChartConfigFragment ref={this.props.innerRef}/>
        } else if (this.props.chartType === Constant.chart.type.gauge) {
            content = <GaugeConfigFragment ref={this.props.innerRef}/>
        } else if (this.props.chartType === Constant.chart.type.plainTextChart) {
            content = <PlainTextConfigFragment ref={this.props.innerRef}/>
        } else if (this.props.chartType === Constant.chart.type.toggleButton) {
            content = <ToggleConfigFragment ref={this.props.innerRef}/>
        } else if (this.props.chartType === Constant.chart.type.pushButton) {
            content = <ButtonConfigFragment ref={this.props.innerRef}/>
        } else if (this.props.chartType === Constant.chart.type.pointMap) {
            content = <PointMapConfigFragment ref={this.props.innerRef}/>
        }

        return <div style={{display: this.props.show ? "block" : "none"}}>{content}</div>
    }
}

export default WidgetConfigFragment;