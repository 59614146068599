// @flow
import React from 'react';
import LicenseConsumeFragment from "./LicenseConsumeFragment";
import MySubscriptionStatusFragment from "./MySubscriptionStatusFragment";
import styles from "./LicenseConsumeFragment.css";
import EventCodeConsumeFragment from "./EventCodeConsumeFragment";
import {LLMsg} from "../../IntlCapture";

class MyLicenseFragment extends React.Component<any, any> {
    render() {
        return <div className={"row"}>
            <div className={"col-lg-5"}>
                <div className="m-portlet m-portlet--tabs">
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-tools">
                            <ul className="nav nav-tabs m-tabs-line m-tabs-line--primary m-tabs-line--2x"
                                role="tablist">
                                <li className="nav-item m-tabs__item">
                                    <a className="nav-link m-tabs__link active" data-toggle="tab" href="#m_tabs_6_1"
                                       role="tab">
                                        {LLMsg("MY_SUB.USE_LICENSE_KEY")}
                                    </a>
                                </li>
                                <li className="nav-item m-tabs__item">
                                    <a className="nav-link m-tabs__link" data-toggle="tab" href="#m_tabs_6_2"
                                       role="tab">
                                        {LLMsg("MY_SUB.USE_EVENT_CODE")}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="m-portlet__body">
                        <div className="tab-content">
                            <div className="tab-pane active" id="m_tabs_6_1" role="tabpanel">
                                <LicenseConsumeFragment/>
                            </div>
                            <div className="tab-pane" id="m_tabs_6_2" role="tabpanel">
                                <EventCodeConsumeFragment/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"col-lg-7"}>
                <MySubscriptionStatusFragment/>
            </div>

        </div>
    }
}

export default MyLicenseFragment;