// @flow

import React from 'react';
import styles from "./AppletNameFragment.css";
import {LLMsg} from "../../../IntlCapture";
import {ClassNames} from "../../../bundle/ClassNames";
import EventTriggerItemFragment from "../DataTriggerFragment/EventTriggerItemFragment";

type AppletNameFragmentState = {
    showError: boolean,
    appletName: string,
    enabled: boolean
}
type AppletNameFragmentProps = {
    appletName: string,
    totalAppletCount: number,
    enabled: boolean
}

class AppletNameFragment extends React.Component<AppletNameFragmentProps, AppletNameFragmentState> {

    constructor(props: AppletNameFragmentProps) {
        super(props);
        this.state = {
            showError: false,
            appletName: props.appletName,
            enabled: props.enabled
        };

        this.validateAppletName = this.validateAppletName.bind(this);
        this.getAppletName = this.getAppletName.bind(this);
        this.getEnabled = this.getEnabled.bind(this);
        this.onAppletNameChanged = this.onAppletNameChanged.bind(this);
        this.onEnableButtonClicked = this.onEnableButtonClicked.bind(this);
    }

    validateAppletName = (): boolean => {
        let isValid: boolean = !!this.state.appletName;
        this.setState({
            showError: !isValid
        });
        return isValid;
    };

    getAppletName = (): string => {
        return this.state.appletName;
    };

    getEnabled = (): boolean => {
        return this.state.enabled;
    };

    componentDidUpdate(prevProps: AppletNameFragmentProps) {
        if (this.props.appletName !== prevProps.appletName) {
            this.setState({
                appletName: this.props.appletName,
                enabled: this.props.enabled,
                showError: false
            });
        }
    }

    onAppletNameChanged = (e: any) => {
        let name = e.target.value;

        this.setState({
            appletName: name,
            showError: !name
        })
    };

    onEnableButtonClicked = (e: any) => {
        this.setState({
            enabled: !this.state.enabled
        })
    };

    render() {
        return <div className={styles.container}>
            <EventTriggerItemFragment
                allowDelete={false}
                backgroundColor={"rgb(23, 108, 191)"}
                extraContent={
                    this.state.showError && <div
                        className="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-danger alert-dismissible fade show"
                        role="alert">
                        <div className="m-alert__icon">
                            <i className="flaticon-exclamation-1"/>
                            <span/>
                        </div>
                        <div className="m-alert__text">
                            <strong>
                                {LLMsg("EVENT_TRIGGER.VALIDATION_FAILED")}
                            </strong>
                            {LLMsg("EVENT_TRIGGER.APPLET_NAME_VALIDATION_FAILED")}
                        </div>
                    </div>
                }
                titleContent={
                    <span>
                        {LLMsg("EVENT_TRIGGER.APPLET_NAME")}
                        <div className={styles.inputWrapper}>

                            <input type="text"
                                   className={ClassNames("form-control form-inline m-input ",
                                       styles.input,
                                       this.state.showError ? " " + styles.errorInput : "")}
                                   value={this.state.appletName}
                                   onChange={this.onAppletNameChanged}
                                   placeholder={LLMsg("EVENT_TRIGGER.APPLET")}
                            />
                        </div>

                    </span>
                }
            />
            <div className={styles.enableWrapper}
                 onClick={this.onEnableButtonClicked}
                 style={{
                     backgroundColor: this.state.enabled ? "#0a8cf0" : "rgba(74,87,98,0.3)",
                     color: this.state.enabled ? "white" : "black"
                 }}>
                <div className={styles.enableLabel}>
                    {LLMsg("EVENT_TRIGGER.APPLET_ENABLE")}
                </div>
                <div className={styles.enableButtonWrapper}>
                    <i className=
                           {
                               "fa " +
                               (this.state.enabled ? "fa-toggle-on" : "fa-toggle-off")
                           }
                    />
                </div>
            </div>
        </div>;
    }
}

export default AppletNameFragment;