// @flow

import React from 'react';
import Api from "../../../bundle/Api";
import swal from "sweetalert2";
import {LLMsg} from "../../../IntlCapture";
import LoadingUtil from "../../../util/LoadingUtil";
import {RouterHistory} from "../../../AppRoute";
import Page from "../../../bundle/Page";
import ErrorUtil from "../../../util/ErrorUtil";


type Props = {
    projectId: string
}

export const DeleteProjectButton = (props: Props) => {

    let deleteProjectWithData = () => {
        LoadingUtil.showFullScreenLoading();
        let projectId = props.projectId;
        Api.services.project.update.deleteProjectWithData(projectId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                LoadingUtil.hideFullScreenLoading();
                swal(LLMsg("COMMON.ACTION.REMOVE_SUCCESS"), "", "success");
                RouterHistory().push(Page.internals.urls.misc.home());
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        });
    };
    return <button
        className={"btn btn-secondary btn-sm m-btn"}
        onClick={deleteProjectWithData}>

        <i className="flaticon-delete mr-2"/>
        <span className="m-nav__link-text">{LLMsg("COMMON.ACTION.DELETE")}</span>
    </button>;
};
