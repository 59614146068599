// @flow

import Constant from "../bundle/Constant";
import swal from "sweetalert2";
import {LLMsg} from "../IntlCapture";
import React from "react";
import {renderToStaticMarkup} from "react-dom/server";

export default class ErrorUtil {

    static USER_ALREADY_SETUP_PASSWORD = "MC_AUTH_ERR_USER_0007";
    static USER_PASSWORD_NOT_MATCH = "MC_AUTH_ERR_USER_0006";
    static LICENSE_NOT_FOUND = "MC_ERR_LIC_0001";

    static hasErrorCode(e: any, errorCode: string): boolean {
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0) {
            for (const error of e.response.data.errors) {
                if (errorCode === error.code) {
                    return true;
                }
            }
        }

        return false;
    }

    static promptError(e: any): void {
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0) {
            e.response.data.errors.forEach((error) => {
                window.toastr.options = {
                    ...Constant.toastr.defaultOptions,
                    ...Constant.toastr.noAutoHiding
                };
                window.toastr.error(error.description, error.code);
            })
        }
    }

    static showPermissionNotAllowed(callback?: Function): void {

        prompt = () => {
            return <div>
                <img src={"https://image.flaticon.com/icons/svg/2055/2055833.svg"} width={"100px"}/>
                <div style={{fontWeight: "bolder", fontSize: "22px", margin: "10px"}}>
                    {LLMsg("MY_SUB.UPGRADE_LICENSE_TITLE")}
                </div>
                <div>
                    {LLMsg("MY_SUB.UPGRADE_LICENSE_MSG")}
                </div>
            </div>;
        };

        swal({
            html: renderToStaticMarkup(prompt()),
            showCancelButton: false,
            confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
        }).then(() => {
            callback && callback();
        });
    }

}
