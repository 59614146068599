// @flow

import React, {Component} from 'react';
import styles from './SchoolApplicationItem.css';

type SchoolApplicationItemWrapperProps = {
    children: any
}

class SchoolApplicationItemWrapper extends Component<SchoolApplicationItemWrapperProps, any> {

    render() {
        return <td className={styles.itemWrapper}>
            {this.props.children}
        </td>
    }
}

export default SchoolApplicationItemWrapper;