// @flow

import React, {Component} from 'react';
import SchoolListTableHeader from "./SchoolListTableHeader";
import SchoolListTableContentWrapper from "./SchoolListTableContentWrapper";

type SchoolListTableProps = {
    schools: Array<{
        id: string,
        schoolName: string,
        emailDomain: string,
        validUntil: string,
        totalApplication: number,
        totalProcessedApplication: number
    }>
}

class SchoolListTable extends Component <SchoolListTableProps, any> {
    render() {

        const {schools} = this.props;

        return <div className="m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <table className="m-datatable__table" id="html_table" width="100%"
                   style={{display: 'block', minHeight: '300px', overflowX: 'auto'}}>
                <SchoolListTableHeader/>
                <SchoolListTableContentWrapper schools={schools}/>
            </table>
            {/*<SchoolListPaginationWrapper/>*/}
        </div>;
    }
}

export default SchoolListTable;