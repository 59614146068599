// @flow
import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import UrlBuilder from "../UrlBuilder";
import Api from "../../bundle/Api";

export default class ChartApiUrlHelper {

    static getCreateChartUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.CHART_CREATE_API, []);
    }

    static getDeleteChartUrl(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.CHART_DELETE_API, []);
    }

    static getRecentChartUrl(count: number): string {

        let queryString = "";
        if (count && count > 1) {
            queryString = "?" + UrlBuilder.toQueryString({"count": count});
        }

        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.CHART_LIST_RECENT_API, []) + queryString;
    }

    static get = {
        detail: (chartId: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.CHART_DETAIL_API, [{
                key: ":chartId",
                value: chartId
            }]);
        },
        byToken: (token: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.CHART_DETAIL_BY_TOKEN_API, [{
                key: ":token",
                value: token
            }]);
        }
    };

    static update = {
        name: (): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.CHART_UPDATE_API, []);
        },
    };

    static getRelatedTopicChartUrl(topicId: string): string {

        let queryString = "";
        if (topicId) {
            queryString = "?" + UrlBuilder.toQueryString({"topicId": topicId});
        }

        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.CHART_LIST_TOPIC_RELATED_API, []) + queryString;
    }

    static getRelatedProjectChartUrl(projectId: string): string {

        let queryString = "";
        if (projectId) {
            queryString = "?" + UrlBuilder.toQueryString({"projectId": projectId});
        }

        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.CHART_LIST_PROJECT_RELATED_API, []) + queryString;
    }


}