// @flow

import React from "react";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import Constant from "../../../bundle/Constant";
import _ from 'lodash';
import {LLMsg} from "../../../IntlCapture";

type ProjectListTableRecordProps = {
    index: number,
    projectId: string,
    projectName: string,
    topics: Array<Object>
}

const ProjectListTableRecord = (props: ProjectListTableRecordProps) => {
    const {projectId, projectName, index, topics} = props;
    let size = "m--icon-font-size-lg3";
    return <tr role="row" className="odd">
        <td>
            <Link to={Page.internals.urls.project.detail(projectId, projectName)}
                  className={"no-a-effect cell_link " + size}
                  style={{color: Constant.theme.project.color, fontWeight: 400}}>
                <i className={Constant.theme.project.icon + " mr-2 fa-1x"}/>{index}. {projectName}
            </Link>

            <span className={"m--icon-font-size-lg0 mr-2"}
                  style={{color: Constant.theme.topic.color}}>
                <i className={Constant.theme.topic.icon + " ml-4 mr-2 fa-1x"}/>
                {LLMsg("COMMON.TOPIC.TOPIC")}:
            </span>

            {
                topics && _.sortBy(topics, ["id"]).map((topic, index) => {
                    return <Link key={index} to={Page.internals.urls.topic.detail(topic.id, topic.description)}
                                 className={"m--icon-font-size-lg1 cursor-pointer"}
                                 style={{color: "grey", fontWeight: 400}}>
                        <button key={index} type="button"
                                style={{
                                    borderColor: "#f9f9f9",
                                    color: Constant.theme.topic.color,
                                    background: "#f9f9f9"
                                }}
                                className="btn m-btn--pill m-btn btn-sm m--margin-right-5 pt-1 pb-1 btm-sm m--margin-bottom-5 m--margin-top-5">

                            {topic.description}
                        </button>
                    </Link>
                })
            }
        </td>
    </tr>;
};

export default ProjectListTableRecord;