exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._5Zk_8plLGL-CjPI7zmf4F {\n\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_5Zk_8plLGL-CjPI7zmf4F"
};