exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".y4XF2QhRlhBu7ZsV1AeK3 {\n    margin-left: 10px;\n    cursor: pointer;\n    border: solid 1px whitesmoke;\n    display: block;\n    padding: 10px;\n    border-radius: 6px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    background-color: #ebedf2;\n}\n\n._1ohLGiElQHucXmmw2LHGs3 {\n    padding-left: 15px;\n}\n\n.PuKC3aZTElZ1rPagZJap2 {\n    font-size: 1.5em;\n}\n\n._311lHw9sgz8kFAXeAtVsv- {\n    color: #575962;\n}", ""]);

// exports
exports.locals = {
	"button": "y4XF2QhRlhBu7ZsV1AeK3",
	"content": "_1ohLGiElQHucXmmw2LHGs3",
	"title": "PuKC3aZTElZ1rPagZJap2",
	"desc": "_311lHw9sgz8kFAXeAtVsv-"
};