// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux'
import Api from "../../../bundle/Api";
import ProjectTypeSelect from "./ProjectTypeSelect";
import swal from "sweetalert2";
import Page from "../../../bundle/Page";
import {withRouter} from "react-router";
import {LLMsg} from "../../../IntlCapture";
import ErrorUtil from "../../../util/ErrorUtil";

type ProjectCreateFragmentProps = {
    createProject?: Function,
    createProjectResult?: Object,

    match?: Object,
    location?: Object,
    history?: Object
}


class ProjectCreateFragment extends Component<ProjectCreateFragmentProps, any> {

    constructor(props) {
        super(props);
    }

    projectNameInput: any;

    componentDidMount() {
        this.projectNameInput.focus();
    }

    createProject = () => {
        const projectName = this.projectNameInput.value;
        if (!projectName) {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.PROJECT.MISSING_PROJECT_NAME"), "warning");
            return;
        }

        Api.services.topic.doGetCreateTopicLimit().then((response) => {
            if (response.status === 200) {
                if (response.data.allowCreate) {
                    Api.services.project.doCreateProject(projectName).then((response) => {
                        if (response.status === 200) {
                            swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), LLMsg("COMMON.PROJECT.CREATE_SUCCESS"), "success").then(() => {
                                this.props.history && this.props.history.push(Page.internals.urls.project.detail(response.data.id, response.data.projectName));
                            });
                            this.projectNameInput.value = "";
                        } else {
                            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                        }
                    }).catch(() => {
                        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                    });
                } else {
                    ErrorUtil.showPermissionNotAllowed();
                }
            }

        }).catch((error =>
                ErrorUtil.promptError(error)
        ));
    };

    render() {
        return <div className={"row"}>
            <div className={"col-md-12"}>
                <div className="m-portlet m-portlet--mobile">
                    <div className="m-portlet__body">
                        <ProjectTypeSelect/>
                        <div className="form-group m-form__group">
                            <label className={"m--icon-font-size-lg3"}>
                                {LLMsg("COMMON.PROJECT.CREATE_PROJECT")}
                            </label>
                            <div className="input-group mt-2">
                                <input type="text" className="form-control m--icon-font-size-lg0"
                                       placeholder={LLMsg("COMMON.PROJECT.PROJECT_NAME")}
                                       ref={(projectNameInput) => {
                                           this.projectNameInput = projectNameInput
                                       }}/>
                                <div className="input-group-append">
                                    <button className="btn btn-primary m--icon-font-size-lg0" type="button"
                                            onClick={() => {
                                                this.createProject();
                                            }}>
                                        {LLMsg("COMMON.PROJECT.CREATE_PROJECT")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = state => {
    console.log("state.ProjectReducer", state.ProjectReducer);
    return {
        createProjectResult: state.ProjectReducer[Api.actions.project.CREATE_PROJECT_ACTION_TYPE]
    };
};

export default withRouter(connect(mapStateToProps, null)(ProjectCreateFragment));
