// @flow

import UserDao from "../dao/UserDao";
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import DataStore from "../store/DataStore";
import Constant from "../bundle/Constant";
import axios from "axios";
import {RouterHistory} from "../AppRoute";
import Page from "../bundle/Page";
import UrlBuilder from "../url/UrlBuilder";

class UserService {

    static loginByToken(username: string, password: string): Object {
        let bodyFormData = new FormData();
        bodyFormData.set('username', username);
        bodyFormData.set('password', password);
        bodyFormData.set('client_id', "MAKER_CLOUD_FRONTEND");
        bodyFormData.set('client_secret', "MAKER_CLOUD_FRONTEND_SECRET");
        bodyFormData.set('grant_type', "password");
        return HttpUtil.ajax(Api.urls.user.getOauthTokenUrl(), "POST", {
            'Content-Type': 'multipart/form-data'
        }, bodyFormData);
    }

    static loginByDirectAccessKey(email: string, directAccessKey: string): Object {
        let bodyFormData = new FormData();
        bodyFormData.set('username', email);
        bodyFormData.set('direct_access_key', directAccessKey);
        bodyFormData.set('client_id', "MAKER_CLOUD_FRONTEND");
        bodyFormData.set('client_secret', "MAKER_CLOUD_FRONTEND_SECRET");
        bodyFormData.set('grant_type', "direct_access_key");
        return HttpUtil.ajax(Api.urls.user.getOauthTokenUrl(), "POST", {
            'Content-Type': 'multipart/form-data'
        }, bodyFormData);
    }

    static doSetupPasswordForNewAccount(newPassword: string): Object {
        return HttpUtil.ajax(Api.urls.user.getSetupPasswordForNewAccountUrl(), "POST", {}, {
            password: newPassword
        });
    }

    static doChangeAccountPassword(oldPassword: string, newPassword: string): Object {
        return HttpUtil.ajax(Api.urls.user.getChangeAccountPasswordUrl(), "POST", {}, {
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    }

    static doCreateUserByGoogleSignUp(): Object {
        return HttpUtil.ajax(Api.urls.user.getCreateUserByGoogleSignUpUrl(), "POST", {});
    }

    static doCreateGuestUser(email: string, eventCode: string): Object {
        return HttpUtil.ajax(Api.urls.user.getCreateGuestUserUrl(), "POST", {}, {
            "email": email,
            "eventCode": eventCode
        });
    }

    static doCreateUserWithLicense(email: string, licenseKey: string): Object {
        return HttpUtil.ajax(Api.urls.user.getCreateUserWithLicenseUrl(), "POST", {}, {
            "email": email,
            "licenseKey": licenseKey
        });
    }

    static doResetUserPassword(email: string): Object {
        return HttpUtil.ajax(Api.urls.user.doResetUserPassword(), "POST", {}, {
            "email": email
        });
    }

    static doSignUpSchool(schoolName: string, email: string, teacherName: string, teacherRole: string, teacherRoleRemarks: string, teacherTel: string, eventCode: string): Object {
        return HttpUtil.ajax(Api.urls.user.getSignUpSchoolUrl(), "POST", {}, {
            schoolName, email, teacherName, teacherRole, teacherRoleRemarks, teacherTel, eventCode
        });
    }

    static doCreateUser(): Object {
        return HttpUtil.ajax(Api.urls.user.getCreateUserUrl(), "POST", {});
    }

    static getMe(): Object {
        return HttpUtil.ajax(Api.urls.user.getUserMyselfUrl(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static loginByGoogleToken(googleIdToken: string): Object {
        let bodyFormData = new FormData();
        bodyFormData.set('google_id_token', googleIdToken);
        bodyFormData.set('client_id', "MAKER_CLOUD_FRONTEND");
        bodyFormData.set('client_secret', "MAKER_CLOUD_FRONTEND_SECRET");
        bodyFormData.set('grant_type', "google_sign_in");
        return HttpUtil.ajax(Api.urls.user.getOauthTokenUrl(), "POST", {
            'Content-Type': 'multipart/form-data'
        }, bodyFormData);
    }

    static logout(): void {
        Api.services.user.clearRefreshTokenTimer();
        DataStore.cookies.set(Constant.cookies.user.accessToken, "");
        DataStore.cookies.set(Constant.cookies.user.refreshToken, "");
    }

    static updateToken(accessToken: string, refreshToken: string, expiresIn: number): void {
        DataStore.cookies.set(Constant.cookies.user.accessToken, accessToken);
        DataStore.cookies.set(Constant.cookies.user.refreshToken, refreshToken);

        let buffer = 30; // 30 seconds buffer

        if (accessToken && refreshToken) {
            Api.services.user.startRefreshTokenTimer(expiresIn, buffer);
        }
        DataStore.cookies.set(Constant.cookies.user.expiresIn, Date.now() + (expiresIn * 1000) - (buffer * 1000) + "");
    }

    static startRefreshTokenTimer(expiresIn: number, buffer: number) {
        Api.services.user.refreshTokenTimer = setTimeout(() => {
            Api.services.user.refreshToken();
        }, (expiresIn - buffer) * 1000);
    }

    static clearRefreshTokenTimer() {
        clearTimeout(Api.services.user.refreshTokenTimer);
    }

    static refreshTokenTimer: any;

    static async refreshToken(): Object {
        if (!DataStore.cookies.get(Constant.cookies.user.refreshToken)) {
            return;
        }
        let bodyFormData = new FormData();
        bodyFormData.set('refresh_token', DataStore.cookies.get(Constant.cookies.user.refreshToken));
        bodyFormData.set('client_id', "MAKER_CLOUD_FRONTEND");
        bodyFormData.set('client_secret', "MAKER_CLOUD_FRONTEND_SECRET");
        bodyFormData.set('grant_type', "refresh_token");

        await axios({
            method: "POST",
            url: Api.urls.user.getOauthTokenUrl(),
            headers: {'Content-Type': 'multipart/form-data'},
            data: bodyFormData,
        }).then((response) => {
            if (response.status === 200) {
                let accessToken = response.data.access_token;
                let refreshToken = response.data.refresh_token;
                let expiresIn = response.data.expires_in;
                Api.services.user.updateToken(accessToken, refreshToken, expiresIn);
            }
        }).catch(() => {
            RouterHistory().push(Page.internals.urls.user.logout(UrlBuilder.getCurrentUrl()));
        });
    }

    static isLogin() {
        return UserDao.isLogin();
    }

    static doGetListOfWhiteListEmail(): Object {
        return HttpUtil.ajax(Api.urls.user.getListOfWhitelistEmailUrl(), Api.methods.get, {});
    }

    static doDeleteWhitelistEmail(whitelistEmailId: string): Object {
        return HttpUtil.ajax(Api.urls.user.getDeleteWhitelistEmailUrl(whitelistEmailId), Api.methods.post, {});
    }

    static doUpdateWhitelistEmail(whitelistEmailId: number, whitelistEmail: string, remarks: string): Object {
        return HttpUtil.ajax(Api.urls.user.getUpdateWhitelistEmailUrl(), Api.methods.post, {}, {
            whitelistEmailId, whitelistEmail, remarks
        });
    }

    static doCreateWhitelistEmail(whitelistEmail: string, remarks: string): Object {
        return HttpUtil.ajax(Api.urls.user.getCreateWhitelistEmailUrl(), Api.methods.post, {}, {
            whitelistEmail, remarks
        });
    }

    static doGetListOfWhiteListedUser(): Object {
        return HttpUtil.ajax(Api.urls.user.getListOfWhitelistedUserUrl(), Api.methods.get, {});
    }

    static doGetListOfUserByLicenseTargetId(targetId: number): Object {
        return HttpUtil.ajax(Api.urls.user.getListOfUserByLicenseTargetIdUrl(targetId), Api.methods.get, {});
    }

    static doGetListOfUserByEventCodeId(eventCodeId: number): Object {
        return HttpUtil.ajax(Api.urls.user.getListOfUserByEventIdUrl(eventCodeId), Api.methods.get, {});
    }

    static doGetUserDetail(userId: string) {
        return HttpUtil.ajax(Api.urls.user.getUserDetailUrl(userId), Api.methods.get, {});
    }

    static getMySubscriptionStatus(): Object {
        return HttpUtil.ajax(Api.urls.user.getMySubscriptionStatusUrl(), Api.methods.get, {});
    }
}

export default UserService;