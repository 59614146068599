// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import Constant from "../bundle/Constant";

class ChartService {

    static doGetRecentChart(count: number) {
        return HttpUtil.ajax(Api.urls.chart.getRecentChartUrl(count), Api.methods.get, {
            'Content-Type': 'application/json',
        });
    }

    static doGetRelatedTopicChart(topicId: string) {
        return HttpUtil.ajax(Api.urls.chart.getRelatedTopicChartUrl(topicId), Api.methods.get, {
            'Content-Type': 'application/json',
        });
    }

    static doGetRelatedProjectChart(projectId: string) {
        return HttpUtil.ajax(Api.urls.chart.getRelatedProjectChartUrl(projectId), Api.methods.get, {
            'Content-Type': 'application/json',
        });
    }

    static doDeleteChart(chartId: string): Object {
        return HttpUtil.ajax(Api.urls.chart.getDeleteChartUrl(), Api.methods.post, {
            'Content-Type': 'application/json',
        }, {
            "chartId": chartId
        });
    }

    static get = {
        detail: (chartId: string): Object => {
            return HttpUtil.ajax(Api.urls.chart.get.detail(chartId), Api.methods.get, {
                'Content-Type': 'application/json',
            });
        },
        byToken: (token: string): Object => {
            return HttpUtil.ajax(Api.urls.chart.get.byToken(token), Api.methods.get, {
                'Content-Type': 'application/json',
            });
        }
    };

    static update = {
        name: (chartId: string, newName: string): Object => {
            return HttpUtil.ajax(Api.urls.chart.update.name(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {
                "chartId": chartId,
                "chartName": newName
            });
        }
    };

    static doCreateChart(chartTypeName: string, configs: Object, chartSourceConfigs: Array<Object>): Object {
        return HttpUtil.ajax(Api.urls.chart.getCreateChartUrl(), Api.methods.post, {
            'Content-Type': 'application/json',
        }, {
            "chartType": chartTypeName,
            "configs": configs,
            chartSourceConfigs
        });
    }

}

export default ChartService;