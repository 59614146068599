// @flow

import React, {Component, PureComponent} from 'react';
import ProjectMessageFragment from "../ProjectMessageFragment";
import type {Project, Topic} from "../../../../model/model";
import ProjectUserListFragment from "../ProjectUserListFragment";
import ProjectFileListFragment from "../ProjectFileListFragment";
import styles from './index.css';
import Stats from "../../../content/Stats";
import {ClassNames} from "../../../../bundle/ClassNames";
import ProjectOverviewRadarChartFragment from "../ProjectOverviewRadarChartFragment";
import ProjectOverviewTopicChartFragment from "../ProjectOverviewTopicChartFragment";
import * as PubSub from "pubsub-js";
import Api from "../../../../bundle/Api";
import {LLMsg} from "../../../../IntlCapture";
import ProjectDetailActionBar from "../ProjectDetailActionBar";
import ProjectTutorialLinkFragment from "../ProjectTutorialLinkFragment";
import ProjectOverviewStatsFragment from "../ProjectOverviewStatsFragment";


type Props = {
    project: Project
}
type State = { dataCount: number }

export default class ProjectOverviewFragment extends React.PureComponent<Props, State> {

    state = {dataCount: 0};

    subToken: any;

    componentDidMount(): * {

        let project: Project = this.props.project;
        let totalData = 0;
        project.topics.forEach((topic: Topic) => {
            totalData += topic.totalNumberOfDataRecords;
        });

        this.setState({dataCount: totalData});

        this.subToken = PubSub.subscribe(Api.actions.topic.TOPIC_NEW_DATA_ACTION_TYPE, () => {
            this.setState({dataCount: this.state.dataCount + 1});
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subToken);
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        if (prevProps.project.id !== this.props.project.id) {
            let project: Project = this.props.project;
            let totalData = 0;
            project.topics.forEach((topic: Topic) => {
                totalData += topic.totalNumberOfDataRecords;
            });
            this.setState({dataCount: totalData});
        }
    }

    render(): React$Node {

        let project: Project = this.props.project;

        return <div>
            <div className={"row"}>
                <div className={ClassNames("col-12 col-lg-6 p-2")}>
                    <ProjectDetailActionBar projectId={project.id} projectName={project.projectName}/>
                </div>
                <div className={ClassNames("col-12 col-lg-6 p-2")}>
                    <ProjectTutorialLinkFragment/>
                </div>
            </div>
            <div className={"row"}>
                <ProjectMessageFragment projectId={project.id} project={project}/>
                <div className={ClassNames("col-12 col-lg-6 p-2")}>
                    <ProjectOverviewStatsFragment project={project} dataCount={this.state.dataCount}/>
                </div>
                <div className={"col-12 col-lg-6 p-2"}>
                    <ProjectOverviewRadarChartFragment project={this.props.project}/>
                </div>
                <div className={"col-12 col-lg-6 p-2"}>
                    <ProjectOverviewTopicChartFragment project={project}/>
                </div>
                <div className={"col-12 col-lg-6 p-2"}>
                    <ProjectUserListFragment projectName={project.projectName} projectId={project.id}
                                             projectUserList={project.projectUsers}/>
                </div>
                <div className={"col-12 col-lg-6 p-2"}>
                    <ProjectFileListFragment projectId={project.id} projectFiles={project.projectFiles}/>
                </div>
            </div>

        </div>
    }
}