// @flow
import React from 'react';
import {LLMsg} from "../../../../IntlCapture";
import type {DataRecordType, Device, Topic} from "../../../../model/model";
import * as _ from "lodash";
import LoadingUtil from "../../../../util/LoadingUtil";
import Api from "../../../../bundle/Api";
import ErrorUtil from "../../../../util/ErrorUtil";
import UrlBuilder from "../../../../url/UrlBuilder";
import styles from "./index.css";

type ChartSourceSelectFragmentProps = {
    isSingleSource: boolean,
    showSimpleMessageOption: boolean,
    showColorOption: boolean,
    showDeviceOption: boolean,
    showDescriptionOption: boolean,
    showLocationOption: boolean,
    onUpdateCallback?: Function,
    onTriggerPickingLocation? : Function,
    pickedLocation?: window.google.maps.latLng
}

type ChartSourceSelectFragmentState = {
    sources: Array<?{ topicId?: number, dataTypeId?: number, deviceId?: number, colorCode?: string, description?: string, latitude?: number, longitude?: number}>,
    topics: Array<Topic>,
    isKeyValueSourceMode: boolean
}

const DEFAULT_COLOR = "#000000";

class ChartSourceSelectFragment extends React.Component<ChartSourceSelectFragmentProps, ChartSourceSelectFragmentState> {

    pickingLocationIndex: number;

    constructor(props: any) {
        super(props);

        this.state = {
            sources: [{tempChartSourceId: this.getRandomString(8), colorCode: DEFAULT_COLOR, latitude: 0, longitude: 0}],
            topics: [],
            isKeyValueSourceMode: true
        };

        this.onTopicChanged = this.onTopicChanged.bind(this);
        this.getConfigs = this.getConfigs.bind(this);
    }

    getConfigs = (): Array<{ tempChartSourceId: String, topicId: number, dataTypeId: number, deviceId: number, colorCode: string, description: string, latitude: number, longitude: number}> => {
        return _.chain(this.state.sources)
            .map((source) => {
                return source ? {
                    tempChartSourceId: source.tempChartSourceId,
                    topicId: source.topicId,
                    dataTypeId: source.dataTypeId,
                    deviceId: source.deviceId,
                    colorCode: source.colorCode,
                    description: source.description,
                    latitude: source.latitude,
                    longitude: source.longitude
                } : null
            })
            .pull(null)
            .value();
    };

    componentDidMount(props: any) {
        this.getChartableTopic();
    }

    componentDidUpdate() {
        if (this.pickingLocationIndex != null && this.pickingLocationIndex != undefined && this.props.pickedLocation) {
            let lat = this.props.pickedLocation.lat();
            let lng = this.props.pickedLocation.lng();

            let sources = this.state.sources;
            let source = this.state.sources[this.pickingLocationIndex];
            source.latitude = lat;
            source.longitude = lng;

            sources[this.pickingLocationIndex] = source;

            this.pickingLocationIndex = null;

            this.setState({
                sources: sources
            }, ()=>{
                this.forceUpdate();
                this.props.onUpdateCallback();
            });
        }
    }

    getChartableTopic() {
        LoadingUtil.showFullScreenLoading();

        if (this.props.showDeviceOption) {

            Api.services.topic.doGetChartableTopic().then(response => {
                LoadingUtil.hideFullScreenLoading();
                if (response.status === 200) {
                    let responseData = response.data;
                    this.setState({topics: responseData.topics});
                }
            }).catch((e) => {
                ErrorUtil.promptError(e);
                LoadingUtil.hideFullScreenLoading();
            });
        } else {

            Api.services.topic.doGetListOfTopic().then(response => {
                LoadingUtil.hideFullScreenLoading();
                if (response.status === 200) {
                    let responseData = response.data;
                    this.setState({topics: responseData.topics});
                }
            }).catch((e) => {
                ErrorUtil.promptError(e);
                LoadingUtil.hideFullScreenLoading();
            });
        }

    }

    onTopicChanged = (sourceIndex: number, value: number) => {

        let sources = this.state.sources;
        if (sources[sourceIndex]) {
            sources[sourceIndex].topicId = value;

            // update lat lng
            this.state.topics.some(topic => {
                if (topic.id == value && topic.latitude != null && topic.longitude != null) {
                    sources[sourceIndex].latitude = topic.latitude;
                    sources[sourceIndex].longitude = topic.longitude;
                    return true;
                }
            });

            this.setState({sources: sources});
        }

        if (this.props.onUpdateCallback != null) {
            this.props.onUpdateCallback();
        }

    };

    onDataTypeChanged = (sourceIndex: number, value: number) => {

        let sources = this.state.sources;
        if (sources[sourceIndex]) {
            sources[sourceIndex].dataTypeId = value;
            this.setState({sources: sources});
        }

        if (this.props.onUpdateCallback != null) {
            this.props.onUpdateCallback();
        }

    };

    onDeviceChanged = (sourceIndex: number, value: number) => {

        let sources = this.state.sources;
        if (sources[sourceIndex]) {
            sources[sourceIndex].deviceId = value;
            this.setState({sources: sources});
        }

        if (this.props.onUpdateCallback != null) {
            this.props.onUpdateCallback();
        }

    };

    onColorChanged = (sourceIndex: number, value: string) => {

        let sources = this.state.sources;
        if (sources[sourceIndex]) {
            sources[sourceIndex].colorCode = value;
            this.setState({sources: sources});
        }

        if (this.props.onUpdateCallback != null) {
            this.props.onUpdateCallback();
        }

    };

    onDescriptionChanged = (sourceIndex: number, value: string) => {

        let sources = this.state.sources;
        if (sources[sourceIndex]) {
            sources[sourceIndex].description = value;
            this.setState({sources: sources});
        }

        if (this.props.onUpdateCallback != null) {
            this.props.onUpdateCallback();
        }

    };

    onLatitudeChanged = (sourceIndex: number, value: string) => {

        let sources = this.state.sources;
        if (sources[sourceIndex]) {
            sources[sourceIndex].latitude = value;
            this.setState({sources: sources});
        }

        if (this.props.onUpdateCallback != null) {
            this.props.onUpdateCallback();
        }

    };

    onLongitudeChanged = (sourceIndex: number, value: string) => {

        let sources = this.state.sources;
        if (sources[sourceIndex]) {
            sources[sourceIndex].longitude = value;
            this.setState({sources: sources});
        }

        if (this.props.onUpdateCallback != null) {
            this.props.onUpdateCallback();
        }

    };

    deleteSource = (sourceIndex: number) => {
        let sources = this.state.sources;
        sources[sourceIndex] = undefined;

        this.setState({sources: sources});

        if (this.props.onUpdateCallback != null) {
            this.props.onUpdateCallback();
        }
    };

    addSource = () => {
        let sources = this.state.sources;
        sources[sources.length] = {tempChartSourceId: this.getRandomString(8), topic: undefined, dataTypeId: undefined, deviceId: undefined, colorCode: "#000000", description: undefined, latitude: 0, longitude: 0};

        this.setState({sources: sources});
    };

    onChangedToSimpleMessageSource = () => {
        this.setState({isKeyValueSourceMode: false});
        this.getChartableTopic();

    };

    onChangedToKeyValueSource = () => {
        this.setState({isKeyValueSourceMode: true});
        this.getChartableTopic();

    };

    getRandomString = (length: number): string => {
        return Math.random().toString(36).slice(-length);
    }

    render() {
        let currentIndex = 1;
        return <div>
            {
                this.props.showSimpleMessageOption && <div className="m-form__group form-group">
                    <div className="m-radio-inline">
                        <label className={"mr-3"}>
                            {LLMsg("COMMON.CHART.MESSAGE_TYPE")}
                            :</label>
                        <label className="m-radio">
                            <input type="radio" checked={this.state.isKeyValueSourceMode} onChange={() => {
                                this.onChangedToKeyValueSource();
                            }}/>
                            {LLMsg("COMMON.CHART.KEY_VALUE_PAIR")}
                            <span/>
                        </label>
                        <label className="m-radio">
                            <input type="radio" checked={!this.state.isKeyValueSourceMode} onChange={() => {
                                this.onChangedToSimpleMessageSource();
                            }}/>
                            {LLMsg("COMMON.CHART.SIMPLE_MESSAGE")}
                            <span/>
                        </label>
                    </div>
                </div>
            }
            <table className="table table-bordered m-table">
                <thead>
                <tr>
                    {!this.props.isSingleSource && (
                        <th>{" "}</th>
                    )}
                    <th>{LLMsg("COMMON.TOPIC.TOPIC")}</th>
                    {
                        this.state.isKeyValueSourceMode &&
                        <th>{LLMsg("COMMON.DATA_TYPE.DATA_TYPE")}</th>
                    }
                    {
                        this.props.showDeviceOption &&
                        <th>{LLMsg("COMMON.DEVICE.DEVICE")}</th>
                    }
                    {
                        this.props.showDescriptionOption &&
                        <th>{LLMsg("COMMON.CHART.SOURCE_DESCRIPTION")}</th>
                    }
                    {
                        this.props.showLocationOption &&
                        <th>{LLMsg("COMMON.CHART.LATITUDE")}</th>
                    }
                    {
                        this.props.showLocationOption &&
                        <th>{LLMsg("COMMON.CHART.LONGITUDE")}</th>
                    }
                    {
                        this.props.showColorOption &&
                        <th>{LLMsg("COMMON.CHART.CHART_COLOR")}</th>
                    }
                    {
                        this.props.showLocationOption &&
                        <th>{" "}</th>
                    }
                    {!this.props.isSingleSource && (
                        <th>{" "}</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {
                    this.state.sources && this.state.sources.map((source, index) => {
                        if (!source) {
                            return null;
                        }
                        let selectedTopic: Topic = _.find(this.state.topics, {"id": parseInt(source.topicId)});
                        let selectedDataType: DataRecordType = selectedTopic && _.find(selectedTopic.dataRecordTypes, {"id": parseInt(source.dataTypeId)});

                        return <tr key={index}>
                            {!this.props.isSingleSource && (
                                <th scope="row">{currentIndex++}</th>
                            )}
                            <td>
                                <select
                                    onChange={(e) => {
                                        this.onTopicChanged(index, parseInt(e.target.value));
                                    }}
                                    className={"form-control form-inline m-input m-input--air"}
                                >
                                    <option value={""}>
                                        {LLMsg("COMMON.TOPIC.TOPIC")}
                                    </option>

                                    {
                                        _.sortBy(this.state.topics, ['topicName']).map((topic, index) => {
                                            return <option key={index}
                                                           value={topic.id}>
                                                {topic.topicName + " (" + topic.description + ")"}
                                            </option>;

                                        })
                                    }
                                </select>
                            </td>
                            {
                                this.state.isKeyValueSourceMode &&
                                <td>
                                    <select
                                        onChange={(e) => {
                                            this.onDataTypeChanged(index, parseInt(e.target.value));
                                        }}
                                        className={"form-control form-inline m-input m-input--air"}
                                    >
                                        <option value={""}>
                                            {LLMsg("COMMON.DATA_TYPE.DATA_TYPE")}
                                        </option>
                                        {

                                            selectedTopic && _.sortBy(selectedTopic.dataRecordTypes, ['keyName']).map((dataType: DataRecordType, index) => {
                                                return <option key={index}
                                                               value={dataType.id}>
                                                    {dataType.dataRecordTypeName + " (" + dataType.keyName + ")"}
                                                </option>;

                                            })
                                        }
                                    </select>
                                </td>
                            }

                            {
                                this.props.showDeviceOption &&
                                <td>
                                    <select
                                        onChange={(e) => {
                                            this.onDeviceChanged(index, parseInt(e.target.value));
                                        }}
                                        className={"form-control form-inline m-input m-input--air"}
                                    >
                                        <option value={""}>
                                            {LLMsg("COMMON.DEVICE.DEVICE")}
                                        </option>
                                        {
                                            selectedDataType && _.sortBy(selectedDataType.devices, ['serialNumber']).map((device: Device, index) => {
                                                return <option key={index}
                                                               value={device.id}>
                                                    {device.serialName + " (" + device.serialNumber + ")"}
                                                </option>;

                                            })
                                        }
                                    </select>
                                </td>
                            }
                            {
                                this.props.showDescriptionOption && <td>
                                    <input className="form-control m-input" type="text"
                                           onChange={(e) => {
                                               this.onDescriptionChanged(index, e.target.value);
                                           }}/>
                                </td>
                            }
                            {
                                this.props.showLocationOption && <td>
                                    <input className={"form-control m-input " + styles.numberNoSpinner}type="number"
                                           value={source.latitude}
                                           onChange={(e) => {
                                               this.onLatitudeChanged(index, e.target.value);
                                           }}/>
                                </td>
                            }
                            {
                                this.props.showLocationOption && <td>
                                    <input className={"form-control m-input " + styles.numberNoSpinner} type="number"
                                           value={source.longitude}
                                           onChange={(e) => {
                                               this.onLongitudeChanged(index, e.target.value);
                                           }}/>
                                </td>
                            }
                            {
                                this.props.showColorOption && <td>
                                    <input className="form-control m-input" type="color" defaultValue={"#000000"}
                                           onChange={(e) => {
                                               this.onColorChanged(index, e.target.value);
                                           }}/>
                                </td>
                            }
                            {
                                this.props.showLocationOption &&
                                <td>
                                    <button
                                        onClick={() => {
                                            this.pickingLocationIndex = index;
                                            this.props.onTriggerPickingLocation();
                                        }}
                                        className="btn btn-outline-metal m-btn m-btn--icon btn-sm m-btn--icon-only">
                                        <i className="fa fa-map-marker-alt"/>
                                    </button>
                                </td>
                            }
                            {
                                !this.props.isSingleSource &&
                                <td>
                                    <button
                                        onClick={() => {
                                            this.deleteSource(index)
                                        }}
                                        className="btn btn-outline-metal m-btn m-btn--icon btn-sm m-btn--icon-only">
                                        <i className="fa fa-times"/>
                                    </button>
                                </td>
                            }
                        </tr>;
                    })
                }


                {
                    !this.props.isSingleSource && <tr>
                        <th scope="row"/>
                        <td colSpan={9}>
                            <button
                                onClick={() => {
                                    this.addSource();
                                }}
                                className="btn btn-outline-success float-right">
                                {LLMsg("COMMON.ACTION.ADD")}
                            </button>
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
            ;
    }
}

export default ChartSourceSelectFragment;