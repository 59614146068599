exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".y_aXKJVl3f2NfgyQCUmoR {\n    display: inline-block;\n    padding-top: 5px;\n    font-size: 20px;\n\n}\n\n.kKRXZEXHX0fC2Rf4nIAuY {\n    margin-left: 10px;\n    font-size: 20px;\n}\n\n._5W_yNfQdbT83KHBTPaNGh {\n    margin-top: -10px;\n}\n\n._1BiZVsiA087njupjnCOgU7[aria-selected=\"true\"] {\n    background-color: #0a6aa1;\n    color: white;\n    /*border-top: 1px solid #0a6aa1;*/\n    /*border-left: 1px solid #0a6aa1;*/\n    /*border-right: 1px solid #0a6aa1;*/\n}\n\n._2oamlq_OI7H_CQSy0X2toI {\n}\n\n._2fzh6VDvgoT0vo323VaUMe {\n}\n\n._1HGTKgu3llfYEd0BQ_y0Yy {\n    border-bottom: 3px solid #0a6aa1;\n    margin-bottom: 0;\n    padding-left: 10px;\n}\n\n._1BiZVsiA087njupjnCOgU7 {\n    display: inline-block;\n    border-radius: 5px 5px 0 0;\n    cursor: pointer;\n    margin-right: 10px;\n    font-size: 16px;\n    padding: 10px 20px;\n    text-decoration: none;\n    background-color: #efefef;\n}\n\n._2ds2P1y5f97bnZTVZn8gp6 {\n    position: absolute;\n    top: 40px;\n    right: 20px;\n}\n\n._30qQu-ocWKyZXeZ0Pro_fI {\n    font-size: 20px;\n    margin-right: 5px;\n    display: block;\n}\n\n._1ZktaVCKyrqSGneYYQ0pc5 {\n    background-color: white;\n}\n\n._1xM5e7_mginbEAAHibYKt1 {\n\n    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;\n}\n\n._2ZAiMOaWWQWVgkqGCzN-jX {\n\n    margin-top: 100px;\n    margin-bottom: 100px;\n    text-align: center;\n}\n\n.xmz9bpfarjfFhhdlZEvuo {\n\n    width: 50%;\n    max-width: 400px;\n\n    -moz-user-select: none;\n    -webkit-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    -o-user-select: none;\n}\n\n", ""]);

// exports
exports.locals = {
	"topicName": "y_aXKJVl3f2NfgyQCUmoR",
	"topicNameInput": "kKRXZEXHX0fC2Rf4nIAuY",
	"copyButton": "_5W_yNfQdbT83KHBTPaNGh",
	"tab": "_1BiZVsiA087njupjnCOgU7",
	"tabWrapper": "_2oamlq_OI7H_CQSy0X2toI",
	"tabContentWrapper": "_2fzh6VDvgoT0vo323VaUMe",
	"tabHeaderWrapper": "_1HGTKgu3llfYEd0BQ_y0Yy",
	"rightTopTitleButtonWrapper": "_2ds2P1y5f97bnZTVZn8gp6",
	"topicTitle": "_30qQu-ocWKyZXeZ0Pro_fI",
	"tableHead": "_1ZktaVCKyrqSGneYYQ0pc5",
	"table": "_1xM5e7_mginbEAAHibYKt1",
	"noTopicImageBox": "_2ZAiMOaWWQWVgkqGCzN-jX",
	"noTopicImage": "xmz9bpfarjfFhhdlZEvuo"
};