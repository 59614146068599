// @flow


class ImageUrl {

    static USER_PROFILE_IMAGE_URL = "/image/user.svg";
    static LANGUAGE_TOGGLE_BACKGROUND_IMAGE_URL = "/image/quick_actions_bg.jpg";
    static REGISTER_SCHOOL_IMAGE_URL = "/image/register_school_Image.svg";
    static REGISTER_WITH_LICENSE_URL = "/image/register_with_license_image.svg";
    static CHECK_LICENSE_ICON_URL = "/image/check_license_icon.svg";
    static REGISTER_WITH_EVENT_CODE_URL = "/image/register_with_event_code_image.svg";
    static HUGE_BANNER_BACKGROUND_URL = "/image/bg_4.jpg";
    static DASHBOARD_IMAGE_URL = "/image/Dashboard.png";
    static MAKER_CLOUD_ICON_IMAGE_WHITE_URL = "/image/MakerCloud_White.png";
    static MAKER_CLOUD_ICON_IMAGE_URL = "/image/SCALEMakerCloud.png";
    static MAKER_CLOUD_WITH_SCALE_ICON_IMAGE_URL = "/image/SCALEMakerCloud_White.png";
    static GAUGE_CHART_IMAGE_URL = "/image/Gauge.png";
    static PLAIN_TEXT_CHART_IMAGE_URL = "/image/plain_text_chart.png";
    static POINT_MAP_CHART_IMAGE_URL = "/image/point_map_chart.png";
    static PUBLISH_DATA_IMAGE_URL = "/image/publish_data_image.svg";

    static BASIC_LINE_CHART_IMAGE_URL = "/image/BasicLineChart.png";
    static PROJECT_ICON_IMAGE_URL = "/image/project_default_image.png";
    static PROJECT_WALLPAPER_IMAGE_URL = "/image/project_huge_image.png";
    static USER_JOINED_IMAGE_URL = "/image/user_joined.png";

    static CREATE_TOPIC_COLOR_ICON_URL = "/image/create_topic_color.svg";
    static SHARE_PROJECT_COLOR_ICON_URL = "/image/share_project_color.svg";
    static EVENT_TRIGGER_COLOR_ICON_URL = "/image/event_trigger_color.svg";
    static EXPORT_PROJECT_DATA_COLOR_ICON_URL = "/image/export_project_color.svg";
    static CREATE_CHART_COLOR_ICON_URL = "/image/create_chart_color.svg";
    static SHOW_PROJECT_FILE_COLOR_ICON_URL = "/image/show_project_file_color.svg";
    static PENCIL_ICON_URL = "/image/pencil.svg";

    static LIGHTBULB_ON_IMAGE_URL = "/image/lightBulb_on.svg";
    static LIGHTBULB_OFF_IMAGE_URL = "/image/lightBulb_off.svg";
}

export default ImageUrl;