// @flow

import React from 'react';
import styles from "./index.css";
import type {LicenseTarget} from "../../../../model/model";
import Api from "../../../../bundle/Api";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";
import {LLMsg} from "../../../../IntlCapture";
import {withRouter} from "react-router";
import TimeUtil from "../../../../util/TimeUtil";
import _ from "lodash";
import LoadingUtil from "../../../../util/LoadingUtil";
import ErrorUtil from "../../../../util/ErrorUtil";

type LicenseTargetDetailFragmentState = {
    licenseTarget: LicenseTarget
}

class LicenseTargetDetailFragment extends React.Component<any, LicenseTargetDetailFragmentState> {

    state: LicenseTargetDetailFragmentState = {
        licenseTarget: {}
    };

    constructor(props: any) {
        super(props);

    }

    componentDidMount() {
        this.getLicenseTargetDetail();
    }

    getLicenseTargetDetail = () => {
        const licenseTargetId = this.props.match && this.props.match.params["licenseTargetId"];
        if (licenseTargetId) {
            LoadingUtil.showFullScreenLoading();
            Api.services.license.getLicenseTargetDetail(licenseTargetId).then((response) => {
                LoadingUtil.hideFullScreenLoading();
                if (response.status === 200) {
                    const licenseTarget: LicenseTarget = response.data;
                    this.setState({
                        licenseTarget
                    });
                }
            }).catch((e) => {
                ErrorUtil.promptError(e);
                LoadingUtil.hideFullScreenLoading();
            });
        }
    };

    constructItem(title: any, content: any) {
        return <div className={styles.row}>
            <div className={styles.title}>
                {title}
            </div>
            <div
                className={styles.content}>
                {content}
            </div>
        </div>
    }

    render() {

        const licenseTarget = this.state.licenseTarget;
        const createdOn = TimeUtil.convertTimeFormat(_.get(licenseTarget, "createdOn", ""), TimeUtil.FULL_DATE_DISPLAY_FORMAT);

        return <div className="m-portlet">
            <div className="m-portlet__body">
                <div className="m-section">
                    {this.constructItem(LLMsg("ID"), licenseTarget.id)}
                    {this.constructItem(LLMsg("Company Name"), licenseTarget.companyName)}
                    {this.constructItem(LLMsg("Target Name"), licenseTarget.targetName)}
                    {this.constructItem(LLMsg("Created on"), createdOn)}
                </div>
                <button type="button" className="btn btn-success" onClick={() => {
                    RouterHistory().push(Page.internals.urls.license.updateLicenseTarget(licenseTarget.id))
                }}>
                    Edit
                </button>
            </div>
        </div>;
    }
}

export default withRouter(LicenseTargetDetailFragment);