// @flow


import PageUrl from "../url/page/PageUrl";
import ProjectPageUrl from "../url/page/ProjectPageUrl";
import TopicPageUrl from "../url/page/TopicPageUrl";
import ChartPageUrl from "../url/page/ChartPageUrl";
import DashboardPageUrl from "../url/page/DashboardPageUrl";
import UserPageUrl from "../url/page/UserPageUrl";
import PageUrlBuilder from "../url/page/PageUrlBuilder";
import ExternalPageUrlBuilder from "../url/page/ExternalPageUrlBuilder";
import {LLMsg} from "../IntlCapture";
import SchoolPageUrl from "../url/page/SchoolPageUrl";
import LicensePageUrl from "../url/page/LicensePageUrl";
import EventCodePageUrl from "../url/page/EventCodePageUrl";
import MaintenancePeriodPageUrl from "../url/page/MaintenancePeriodPageUrl";
import VirtualSmartHomePageUrl from "../url/page/VirtualSmartHomePageUrl";

class Page {

    static internals = {
        paths: PageUrl,
        urls: {
            user: UserPageUrl,
            dashboard: DashboardPageUrl,
            chart: ChartPageUrl,
            project: ProjectPageUrl,
            topic: TopicPageUrl,
            school: SchoolPageUrl,
            license: LicensePageUrl,
            eventCode: EventCodePageUrl,
            misc: PageUrlBuilder,
            maintenancePeriod: MaintenancePeriodPageUrl,
            virtualSmartHome: VirtualSmartHomePageUrl
        }
    };
    static externals = ExternalPageUrlBuilder;
    static updateTitle = (id: string, data: ?Array<{ key: string, value: string }>) => {
        let params = {};
        data && data.forEach((item) => {
            params[item.key] = item.value;
        });

        window.document.title = LLMsg(id, params) + LLMsg("PAGE.TITLE.POSTFIX");
    };

    static convertToFullPath = (relativePath: string) => {
        return window.location.origin + relativePath;
    };


}

export default Page;