// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../../IntlCapture";

class SchoolListTableHeader extends Component <any, any> {
    render() {
        return <thead className="m-datatable__head">
          <tr className="m-datatable__row" style={{left: '0px'}}>
                <th className="m-datatable__cell m-datatable__cell--sort">
                    <span style={{width: '40px'}}>
                        {LLMsg("COMMON.SCHOOL.SCHOOL_ID")}
                    </span>
                </th>
                <th className="m-datatable__cell m-datatable__cell--sort">
                    <span style={{width: '110px'}}>
                        {LLMsg("COMMON.SCHOOL.SCHOOL_NAME")}
                    </span>
                </th>
                <th className="m-datatable__cell m-datatable__cell--sort">
                    <span style={{width: '110px'}}>
                        {LLMsg("COMMON.SCHOOL.SCHOOL_EMAIL_DOMAIN")}
                    </span>
                </th>
                <th className="m-datatable__cell m-datatable__cell--sort">
                    <span style={{width: '200px'}}>
                        {LLMsg("COMMON.SCHOOL.VALID_UNTIL")}
                    </span>
                </th>
                <th className="m-datatable__cell m-datatable__cell--sort">
                    <span style={{width: '150px'}}>
                        {LLMsg("COMMON.SCHOOL.APPLICATION_STATUS")}
                    </span>
                </th>
            </tr>
        </thead>;
    }
}

export default SchoolListTableHeader;