exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NOoJxvQzodvF7EQGuzNHn {\n    border-left: 5px solid #0a6aa1;\n    padding: 5px 20px;\n    font-size: 20px;\n}\n\n._3AgMLkqLZ58JCHoE-1zHXX {\n    width: 100%;\n    overflow-wrap: break-word;\n}\n\n.OyZ1hdFbofCxohjrDFl1h {\n\n    -moz-user-select: none;\n    -webkit-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    -o-user-select: none;\n}\n\n._4kmIwDJbWkiEczapH3O12 {\n    margin-top: 20px;\n}\n\n.FcJcdh7NdfwlxkhF83Icg {\n    text-align: center;\n}\n\n._20Sg8MVHH7vDIRZOkaG5TL {\n    text-align: right;\n    margin-top: 50px;\n    margin-bottom: 20px;\n}\n\n._2KYYqzTjn77IaYwePZgwJe {\n    font-size: 16px;\n}\n\n.WQbdwckzPbAOanfyL0CSR {\n    position: absolute;\n    top: 35px;\n    right: 35px;\n}", ""]);

// exports
exports.locals = {
	"title": "NOoJxvQzodvF7EQGuzNHn",
	"username": "_3AgMLkqLZ58JCHoE-1zHXX",
	"userTitleImage": "OyZ1hdFbofCxohjrDFl1h",
	"userBoxList": "_4kmIwDJbWkiEczapH3O12",
	"imageBox": "FcJcdh7NdfwlxkhF83Icg",
	"shareToUserRow": "_20Sg8MVHH7vDIRZOkaG5TL",
	"tableWrapper": "_2KYYqzTjn77IaYwePZgwJe",
	"explainButton": "WQbdwckzPbAOanfyL0CSR"
};