// @flow

import React, {Component} from 'react';
import styles from './SchoolListTable.css';
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";
import {LLMsg} from "../../../../IntlCapture";

type SchoolListTableContentItemProps = {
    id: string,
    schoolName: string,
    emailDomain: string,
    validUntil: string,
    totalApplication: number,
    totalProcessedApplication: number
}

class SchoolListTableContentItem extends Component <SchoolListTableContentItemProps, any> {
    render() {

        const {id, schoolName, emailDomain, totalApplication, totalProcessedApplication, validUntil} = this.props;
        return <tr data-row={0} className={"m-datatable__row " + styles.row} onClick={() => {
            RouterHistory().push(Page.internals.urls.school.detail(id, schoolName));
        }}>
            <td data-field="OrderID" className="m-datatable__cell">
                <span style={{width: '40px'}}>
                    {id}
                </span>
            </td>
            <td data-field="OrderID" className="m-datatable__cell">
                <span style={{width: '110px'}}>
                    {schoolName}
                </span>
            </td>
            <td data-field="OrderID" className="m-datatable__cell">
                <span style={{width: '110px'}}>
                    {emailDomain}
                </span>
            </td>
            <td data-field="OrderID" className="m-datatable__cell">
                <span style={{width: '200px'}}>
                    {validUntil ? validUntil : LLMsg("COMMON.SCHOOL.INACTIVE")}
                </span>
            </td>
            <td data-field="OrderID" className="m-datatable__cell">
                {
                    totalApplication > 0 && <span style={{width: '150px'}}>
                        {totalProcessedApplication}/{totalApplication} {LLMsg("COMMON.SCHOOL.PROCESSED")}
                        {
                            (totalProcessedApplication !== totalApplication && totalApplication !== 0) &&
                            <i className={"fa fa-exclamation " + styles.approvalStatusMark}/>
                        }
                    </span>
                } {
                totalApplication === 0 && <span style={{width: '150px'}}>
                    {LLMsg("COMMON.SCHOOL.NO_PENDING_APPLICATION")}
                    </span>
            }
            </td>
        </tr>;

    }
}

export default SchoolListTableContentItem;