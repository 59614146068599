// @flow

import React from 'react';
import type {MaintenancePeriod} from "../../../model/model";
import Api from "../../../bundle/Api";
import TimeUtil from "../../../util/TimeUtil";
import {LLMsg} from "../../../IntlCapture";
import LoadingUtil from "../../../util/LoadingUtil";
import _ from 'lodash';

type MaintenancePeriodPromptState = {
    maintenancePeriod: ? MaintenancePeriod
}

export default class MaintenancePeriodPrompt extends React.Component<any, MaintenancePeriodPromptState> {

    constructor(props: any) {
        super(props);
        this.state = {
            maintenancePeriod: null
        }
    }

    componentDidMount() {

        LoadingUtil.showFullScreenLoading();
        Api.services.maintenanceService.getNext().then((res) => {
            LoadingUtil.hideFullScreenLoading();
            if (res.status === 200) {
                this.setState({
                    maintenancePeriod: res.data
                })
            }
        }).catch(() => {
            LoadingUtil.hideFullScreenLoading();
        });
    }

    render() {
        if (!this.state.maintenancePeriod) {
            return null;
        }

        if (TimeUtil.isAfterNow(this.state.maintenancePeriod.showSince)) {
            return null;
        }

        let params = {
            START_FROM: TimeUtil.convertTimeFormat(_.get(this.state, "maintenancePeriod.startFrom", ""), TimeUtil.FULL_DATE_NO_SECOND_DISPLAY_FORMAT),
            END_UNTIL: TimeUtil.convertTimeFormat(_.get(this.state, "maintenancePeriod.endUntil", ""), TimeUtil.FULL_DATE_NO_SECOND_DISPLAY_FORMAT)
        };
        let msg = LLMsg("MAINTAIN.PRE_MAINTENANCE_PERIOD_WARNING_MESSAGE", params);


        return <div
            className="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-brand alert-dismissible fade show"
            role="alert">
            <div className="m-alert__icon">
                <i className="flaticon-exclamation-1"/>
                <span/>
            </div>
            <div className="m-alert__text">
                <strong>{LLMsg("MAINTAIN.MAINTENANCE_PERIOD_LABEL")}</strong><br/>
                {msg}
            </div>
            <div className="m-alert__close">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                </button>
            </div>
        </div>;
    }

}
