exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2ORowGt2UVzo5tyBq1A3Fq {\n    display: flex;\n}\n\n._1RDPxlCjStlgTHXdIWS_KK {\n    background-color: whitesmoke;\n    border-right: solid 1px lightgrey;\n    width: 220px;\n    padding: 15px;\n    display: inline-block;\n\n}\n\n._1lpy1EK6DmmfQxgndkpQbM:hover {\n    background-color: grey;\n}\n\n._1Tv3H5mM1TEbe5fFGuqqWo {\n    background-color: #e4e4e4;\n}\n\n._1lpy1EK6DmmfQxgndkpQbM {\n    font-size: 15px;\n    display: block;\n    padding: 15px;\n    cursor: pointer;\n    text-align: center;\n    border-bottom: 3px solid #eeedf4;\n    background-color: #e5e5e5;\n    color: black !important;\n}\n\n._3rt8nHoIuMiaAh7xbWgmE6 {\n    display: inline-block;\n    width: calc(100% - 220px);\n    padding: 20px 40px;\n    background-color: #e6e6e6;\n}\n\n._20oXkXaMKd4ys1eJTXn4Cm {\n    min-height: 300px;\n}\n\n._6rZB9RZoh5qnTeXQEsICo {\n    font-size: 24px;\n}\n\n._1un-spzbDpmsGECG4i-cvL {\n    height: 8px;\n    width: 8px;\n    margin-top: 6px;\n    border-radius: 50%;\n    display: inline-block;\n    float: left;\n}\n\n._11TZ8NsK7LYpg6e3O_s7bd {\n    background-color: #05dd15;\n}\n\n._3RqoJNzvgpMARg9dnA3Cn2 {\n    background-color: #ff0000;\n}\n\n._1aHnGboSSyc98E9VCdoeET:hover {\n    color: white;\n}\n\n._1aHnGboSSyc98E9VCdoeET {\n    background-color: rgb(23, 108, 191);\n    color: white !important;\n    display: block;\n    padding: 10px 10px 10px 20px;\n    font-size: 16px;\n    border-radius: 10px;\n    height: 44px;\n    position: relative;\n    margin-top: 10px;\n}\n\n\n._1wmnFrSIgJWqf_kkSI5tt8 {\n    position: absolute;\n    right: 15px;\n    color: white;\n    font-size: 16px;\n    height: 24px;\n    line-height: 24px;\n    vertical-align: middle;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_2ORowGt2UVzo5tyBq1A3Fq",
	"appletListWrapper": "_1RDPxlCjStlgTHXdIWS_KK",
	"appletListItem": "_1lpy1EK6DmmfQxgndkpQbM",
	"appletListActiveItem": "_1Tv3H5mM1TEbe5fFGuqqWo",
	"appletContent": "_3rt8nHoIuMiaAh7xbWgmE6",
	"appletContentInner": "_20oXkXaMKd4ys1eJTXn4Cm",
	"appletContentTitle": "_6rZB9RZoh5qnTeXQEsICo",
	"appletEnabledDot": "_1un-spzbDpmsGECG4i-cvL",
	"appletEnabledDotEnable": "_11TZ8NsK7LYpg6e3O_s7bd",
	"appletEnabledDotDisable": "_3RqoJNzvgpMARg9dnA3Cn2",
	"newAppletButton": "_1aHnGboSSyc98E9VCdoeET",
	"dropdownArrow": "_1wmnFrSIgJWqf_kkSI5tt8"
};