// @flow

import React from 'react';
import styles from './index.css';
import {LLMsg} from "../../../../IntlCapture";
import LoadingUtil from "../../../../util/LoadingUtil";
import ToastrUtil from "../../../../util/ToastrUtil";
import Constant from "../../../../bundle/Constant";
import Api from "../../../../bundle/Api";
import ErrorUtil from "../../../../util/ErrorUtil";
import {ClassNames} from "../../../../bundle/ClassNames";
import * as PubSub from "pubsub-js";
import type {ChartMessage} from "../../../../model/model";

type TopicMapFragmentState = {
    hasEdited: boolean
}

type TopicMapFragmentProps = {
    topicId: string,
    latitude: number,
    longitude: number,
    isProjectDetailPage?: boolean
}

const defaultZoomLevel = 10;

class TopicMapFragment extends React.Component <TopicMapFragmentProps, TopicMapFragmentState> {

    lat: ? number = 22.3193;
    lng: ?number = 114.1694;
    lastLat: ?number = this.lat;
    lastLng: ?number = this.lng;
    map: any;
    marker: any;
    mapRef: any;
    latRef: any = React.createRef();
    lngRef: any = React.createRef();

    subToken: any;

    constructor(props: TopicMapFragmentProps) {
        super(props);
        this.state = {
            hasEdited: false
        };
        this.backToOriginal = this.backToOriginal.bind(this);
        this.removeTopicLocation = this.removeTopicLocation.bind(this);
        this.updateTopicLocation = this.updateTopicLocation.bind(this);

        this.lastLat = this.props.latitude;
        this.lastLng = this.props.longitude;
        this.lat = this.props.latitude;
        this.lng = this.props.longitude;
    }

    backToOriginal = () => {
        this.setState({hasEdited: false});
        this.lat = this.lastLat;
        this.lng = this.lastLng;
        let pos = new window.google.maps.LatLng(this.lat, this.lng);
        this.marker.setPosition(pos);
        this.map.panTo(pos);
        this.map.setZoom(defaultZoomLevel);

        this.latRef.current.innerHTML = this.lat || "N/A";
        this.lngRef.current.innerHTML = this.lng || "N/A";
    };


    componentWillUnmount() {
        PubSub.unsubscribe(this.subToken);
    }

    removeTopicLocation = () => {

        LoadingUtil.showFullScreenLoading();
        Api.services.topic.update.removeTopicLocation(this.props.topicId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.lastLat = null;
                this.lat = null;
                this.lastLng = null;
                this.lng = null;
                this.setState({hasEdited: false});

                this.latRef.current.innerHTML = this.lat || "N/A";
                this.lngRef.current.innerHTML = this.lng || "N/A";
                ToastrUtil.show(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), Constant.toastr.defaultOptions, ToastrUtil.success);
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
            LoadingUtil.hideFullScreenLoading();
        })

    };

    updateTopicLocation = () => {
        LoadingUtil.showFullScreenLoading();
        Api.services.topic.update.updateTopicLocation(this.props.topicId, this.lat, this.lng).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.lastLat = this.lat;
                this.lastLng = this.lng;
                this.setState({hasEdited: false});
                ToastrUtil.show(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), Constant.toastr.defaultOptions, ToastrUtil.success);
            }
        }).catch((e) => {
            ErrorUtil.promptError(e);
            LoadingUtil.hideFullScreenLoading();
        })
    };

    componentDidUpdate(prevProps: TopicMapFragmentProps) {

        if (this.props.latitude && this.props.longitude &&
            !prevProps.latitude && !prevProps.longitude
        ) {
            this.onTopicLocationUpdated(this.props.latitude, this.props.longitude);
        }

    }

    onTopicLocationUpdated = (lat: number, lng: number) => {
        this.lastLat = lat;
        this.lastLng = lng;
        this.lat = lat;
        this.lng = lng;
        this.latRef.current.innerHTML = lat || "N/A";
        this.lngRef.current.innerHTML = lng || "N/A";
        let myLatlng = new window.google.maps.LatLng(lat, lng);
        this.marker.setPosition(myLatlng);
        this.map.panTo(myLatlng);
        this.map.setZoom(defaultZoomLevel);

    }

    componentDidMount() {

        this.subToken = PubSub.subscribe(Api.actions.topic.TOPIC_NEW_DATA_ACTION_TYPE, (actionName: string, data: ChartMessage) => {
            console.log("checking for new location", data.topicId, this.props.topicId, data);
            if (data.topicId === this.props.topicId && data.latitude && data.longitude) {
                this.onTopicLocationUpdated(data.latitude, data.longitude);
            }
        });

        let myLatlng = new window.google.maps.LatLng(this.lat, this.lng);
        this.map = new window.google.maps.Map(this.mapRef, {
            zoom: 14,
            center: myLatlng,
            streetViewControl: false,
            mapTypeControl: false
        });

        this.marker = new window.google.maps.Marker({
            map: this.map,
            title: 'Current Location',
            position: new window.google.maps.LatLng(this.lat, this.lng)
        });

        if (!(this.props.latitude && this.props.longitude) && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {

                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
                this.lastLat = position.coords.latitude;
                this.lastLng = position.coords.longitude;

                let pos = new window.google.maps.LatLng(this.lat, this.lng);
                this.map.panTo(pos);
                this.map.setZoom(defaultZoomLevel);

            });
        }

        this.map.addListener('center_changed', () => {
        });
        this.map.addListener('click', (e) => {
            if (!this.state.hasEdited) {
                this.setState({hasEdited: true});
            }

            let latLng = e.latLng;

            this.lat = latLng.lat();
            this.lng = latLng.lng();
            this.marker.setPosition(latLng);
        });

        this.marker.addListener('click', () => {
            this.map.setCenter(this.marker.getPosition());
        });
    }

    render() {
        let height = this.props.isProjectDetailPage ? "400px" : "300px";
        return <div className={""}>
            <div
                className={ClassNames("m-portlet mb-0", {[styles.projectDetailPageHeight]: this.props.isProjectDetailPage})}>
                <div className="m-portlet__body p-4">
                    <div className={"row"}>
                        <div className={"col-6"}>
                            {
                                !this.props.isProjectDetailPage && <div className={styles.title}>
                                    {LLMsg("COMMON.TOPIC.TOPIC_LOCATION")}
                                </div>
                            }

                            <div>
                                <h6>
                                    {LLMsg("COMMON.CHART.LATITUDE") + " "}
                                    <span ref={this.latRef}>{this.props.latitude || "N/A"}</span>
                                </h6>
                                <h6>
                                    {LLMsg("COMMON.CHART.LONGITUDE") + " "}
                                    <span ref={this.lngRef}>{this.props.longitude || "N/A"}</span>
                                </h6>
                            </div>
                        </div>
                        {
                            this.state.hasEdited && <div className={"col-6"}>
                                <div className={styles.actionBox}>

                                    <button type="button" className="btn btn-secondary"
                                            onClick={this.backToOriginal}>
                                        {LLMsg("COMMON.ACTION.CANCEL")}
                                    </button>
                                    <button type="button" className="btn btn-info"
                                            onClick={this.updateTopicLocation}>
                                        {LLMsg("COMMON.ACTION.CONFIRM")}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                    <div ref={(ref) => {
                        this.mapRef = ref;
                    }} style={{height: height, width: "100%"}}/>
                </div>
            </div>
        </div>
    }
}

export default TopicMapFragment;