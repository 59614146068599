exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Yckf05m-unf6wClCjPm9u {\n    font-size: 16px;\n    display: block;\n    color: white;\n    cursor: pointer;\n    padding: 10px 15px 10px 15px;\n    font-weight: bold;\n    background-color: grey;\n    position: relative;\n}\n\n._3Nhdn1RZ9F1ccCGkMegcT1 {\n    position: absolute;\n    right: 15px;\n    color: white;\n    font-size: 16px;\n    height: 24px;\n    line-height: 24px;\n    vertical-align: middle;\n}\n\n._2HOJEt_cNWEoj_5uo0yHtc {\n    color: black;\n}\n\n._3Yckf05m-unf6wClCjPm9u:hover .vZM4p_EgHBT9yDOPkbH_J {\n    display: block;\n}\n\n.vZM4p_EgHBT9yDOPkbH_J {\n    display: none;\n    position: absolute;\n    right: -200px;\n    cursor: initial;\n    z-index: 2;\n    top: 0;\n    width: 200px;\n    border: solid 1px grey;\n    background-color: white;\n    padding: 20px;\n}\n\n.ZZuGshdv_sHvepN1Qodfc {\n    color: white;\n    display: block;\n    padding: 10px 25px 10px 20px;\n    cursor: pointer;\n    font-size: 16px;\n    border-radius: 10px;\n    margin-top: 10px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_3Yckf05m-unf6wClCjPm9u",
	"dropdownArrow": "_3Nhdn1RZ9F1ccCGkMegcT1",
	"noRecord": "_2HOJEt_cNWEoj_5uo0yHtc",
	"dropdownWrapper": "vZM4p_EgHBT9yDOPkbH_J",
	"dropdownItem": "ZZuGshdv_sHvepN1Qodfc"
};