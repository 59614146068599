import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";
import UrlBuilder from "../UrlBuilder";

export default class EventTriggerApiUrlHelper {

    static create(): string {
        return StringUtil.replaceAllText(Config.ET_API_MAKER_CLOUD + Api.paths.EVENT_TRIGGER_CREATE_API, []);
    }

    static update(): string {
        return StringUtil.replaceAllText(Config.ET_API_MAKER_CLOUD + Api.paths.EVENT_TRIGGER_UPDATE_API, []);
    }

    static delete(appletId: string, projectId: string): string {

        let queryString = "?" + UrlBuilder.toQueryString(
            {"projectId": projectId}
        );
        return StringUtil.replaceAllText(Config.ET_API_MAKER_CLOUD + Api.paths.EVENT_TRIGGER_DELETE_API, [{
            key: ":appletId",
            value: appletId
        }]) + queryString;
    }


    static get = {
        list: (projectId: string): string => {
            let queryString = "?" + UrlBuilder.toQueryString(
                {"projectId": projectId}
            );
            return StringUtil.replaceAllText(Config.ET_API_MAKER_CLOUD + Api.paths.EVENT_TRIGGER_LISTING_API) + queryString;
        },
        detail: (appletId: string, projectId: string): string => {

            let queryString = "?" + UrlBuilder.toQueryString(
                {"projectId": projectId}
            );
            return StringUtil.replaceAllText(Config.ET_API_MAKER_CLOUD + Api.paths.EVENT_TRIGGER_DETAIL_API, [{
                key: ":appletId",
                value: appletId
            }]) + queryString;
        }
    }

    static trigger = {
        objectDetection: (projectId: string, detectedObject: string): string => {
            return StringUtil.replaceAllText(
                Config.ET_API_MAKER_CLOUD + Api.paths.EVENT_TRIGGER_TRIGGER_OBJECT_DETECTION_API, [
                    {
                        key: ":projectId",
                        value: projectId
                    },
                    {
                        key: ":detectedObjects",
                        value: detectedObject
                    }
                ]);
        },
        faceDetection: (projectId: string): string => {
            return StringUtil.replaceAllText(Config.ET_API_MAKER_CLOUD + Api.paths.EVENT_TRIGGER_TRIGGER_FACE_DETECTION_API, [{
                key: ":projectId",
                value: projectId
            }]);
        }
    }

}