const messages = {

    /* PAGE_TITLE_01 */ 'PAGE.TITLE.HOME': '主頁',
    /* PAGE_TITLE_02 */ 'PAGE.TITLE.PRIVACY': '私隐政策',
    /* PAGE_TITLE_03 */ 'PAGE.TITLE.LEGAL': '使用条款',
    /* PAGE_TITLE_04 */ 'PAGE.TITLE.CONTACT_US': '联系我们',
    /* PAGE_TITLE_05 */ 'PAGE.TITLE.LOGIN': '請先登錄',
    /* PAGE_TITLE_06 */ 'PAGE.TITLE.PROJECT_CREATE': '創建項目',
    /* PAGE_TITLE_07 */ 'PAGE.TITLE.PROJECT_LISTING': '項目列表',
    /* PAGE_TITLE_08 */ 'PAGE.TITLE.PROJECT_DETAIL': '{PROJECT_NAME} - 詳細項目資料',
    /* PAGE_TITLE_09 */ 'PAGE.TITLE.TOPIC_CREATE': '創建主題',
    /* PAGE_TITLE_10 */ 'PAGE.TITLE.TOPIC_LISTING': '主題列表',
    /* PAGE_TITLE_11 */ 'PAGE.TITLE.TOPIC_DETAIL': '{TOPIC_NAME} - 詳細主題資料',
    /* PAGE_TITLE_12 */ 'PAGE.TITLE.DASHBOARD': '{DASHBOARD_NAME} - 儀表板',
    /* PAGE_TITLE_13 */ 'PAGE.TITLE.CHART_DETAIL': '{CHART_NAME} - 詳細圖表資料',
    /* PAGE_TITLE_14 */ 'PAGE.TITLE.CHART_CREATE': '創建圖表',
    /* PAGE_TITLE_15 */ 'PAGE.TITLE.POSTFIX': ' -  Maker Cloud  - STEM教學的物聯網平台',
    /* PAGE_TITLE_16 */ 'PAGE.TITLE.PAGE_NOT_FOUND': '找不到網頁',
    /* PAGE_TITLE_17 */ 'PAGE.TITLE.LOGOUT': '登出',
    /* PAGE_TITLE_18 */ 'PAGE.TITLE.CHANGE_PASSWORD': '更改密碼',
    /* PAGE_TITLE_19 */ 'PAGE.TITLE.MY_SUBSCRIPTION': '我的訂閱狀態',
    /* PAGE_TITLE_20 */ 'PAGE.TITLE.SETUP_PASSWORD': '初始化密碼',
    /* PAGE_TITLE_21 */ 'PAGE.TITLE.REGISTRATION_SELECTION': '註冊賬戶',
    /* PAGE_TITLE_22 */ 'PAGE.TITLE.REGISTER_FOR_SCHOOL': '註冊學校帳戶',
    /* PAGE_TITLE_23 */ 'PAGE.TITLE.REGISTER_WITH_LICENSE_KEY': '以授權碼註冊帳戶',
    /* PAGE_TITLE_24 */ 'PAGE.TITLE.REGISTER_WITH_EVENT_CODE': '以活動代碼註冊帳戶',
    /* PAGE_TITLE_25 */ 'PAGE.TITLE.RESET_PASSWORD': '重設密碼申請',
    /* PAGE_TITLE_26 */ 'PAGE.TITLE.VIRTUAL_LIGHT_BULB': '虛擬燈泡',
    /* PAGE_TITLE_27 */ 'PAGE.TITLE.WHATS_NEW': '最新消息',

    /* LOG_01 */        'LOGIN_PAGE.TITLE': '歡迎您，請登錄！',
    /* LOG_02 */        'LOGIN_PAGE.PRIVACY': '隱私',
    /* LOG_03 */        'LOGIN_PAGE.LEGAL': '法律',
    /* LOG_04 */        'LOGIN_PAGE.CONTACT': '聯繫',
    /* LOG_05 */        'LOGIN_PAGE.DESC': 'Smart Campus Active Learning Environment',
    /* LOG_06 */        'LOGIN_PAGE.SUB_DESC': 'Of, By And For Tomorrow\'s Smart Citizen',
    /* LOG_07 */        'LOGIN_PAGE.COPYRIGHT': 'Maker Cloud',
    /* LOG_08 */        'LOGIN_PAGE.NO_ACCOUNT': '沒有賬號？',
    /* LOG_09 */        'LOGIN_PAGE.REGISTER': '註冊',
    /* LOG_10 */        'LOGIN_PAGE.USERNAME': '電郵地址',
    /* LOG_11 */        'LOGIN_PAGE.PASSWORD': '密碼',
    /* LOG_12 */        'LOGIN_PAGE.FORGET_PASSWORD': '忘記密碼 ？',
    /* LOG_13 */        'LOGIN_PAGE.LOGIN': '登入',
    /* LOG_14 */        'LOGIN_PAGE.OR': '或',
    /* LOG_15 */        'LOGIN_PAGE.GOOGLE': 'Google 登錄',
    /* LOG_16 */        'LOGIN_PAGE.GUEST_LOGIN': '以活動碼註冊帳戶',
    /* LOG_17 */        'LOGIN_PAGE.SCHOOL_SIGN_UP': '註冊學校帳戶',
    /* LOG_18 */        'LOGIN_PAGE.WRONG_USERNAME_OR_PASSWORD': '錯誤的用戶名或密碼。 如果忘記密碼，請點擊“忘記密碼”重置您的密碼',
    /* LOG_19 */        'LOGIN_PAGE.MORE_RESOURCES': '其他資源',

    /* REG_01 */        'REGISTER_PAGE.REGISTER_ACCOUNT': '註冊賬戶',
    /* REG_02 */        'REGISTER_PAGE.REGISTER_GUEST_ACCOUNT': '以訪客身份註冊',
    /* REG_03 */        'REGISTER_PAGE.BACK_TO_LOGIN_PAGE': '已經註冊了一個帳戶？ 立即登錄您的帳戶！',
    /* REG_04 */        'REGISTER_PAGE.GUEST_EXPIRY_NOTICE': '一旦您以訪客身份註冊，訪客帳戶將在7天后到期。 如果您有任何問題，請與我們聯繫',
    /* REG_05 */        'REGISTER_PAGE.GUEST_USERNAME': '電郵地址',
    /* REG_06 */        'REGISTER_PAGE.CONFIRM_REGISTER_GUEST': '創建訪客帳號',
    /* REG_07 */        'REGISTER_PAGE.INVALID_GUEST_USERNAME': '請提供一個有效的電子郵件',
    /* REG_08 */        'REGISTER_PAGE.EVENT_CODE_LABEL': '活動碼',
    /* REG_09 */        'REGISTER_PAGE.EVENT_CODE_INVALID': '無效的活動碼',
    /* REG_10 */        'REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT': '註冊學校帳戶',
    /* REG_11 */        'REGISTER_PAGE.REGISTER_SCHOOL_ACCOUNT_NOTICE': '提交申請後，我們會盡快處理您的申請。 申請獲得批准後，您將收到一封確認電子郵件。 我們可能會與您聯繫並驗證您的資料。',
    /* REG_12 */        'REGISTER_PAGE.TEACHER_NAME': '教師姓名',
    /* REG_13 */        'REGISTER_PAGE.TEACHER_ROLE': '任教科目/負責項目',
    /* REG_14 */        'REGISTER_PAGE.TEACHER_TEL': '辦公室電話/流動電話',
    /* REG_15 */        'REGISTER_PAGE.PERSONAL_INFO_LABEL': '個人資料',
    /* REG_16 */        'REGISTER_PAGE.EVENT_INFO_LABEL': '活動資料',
    /* REG_17 */        'REGISTER_PAGE.NO_EVENT_CODE': '如果您沒有活動碼，請聯繫support@scaleinnnotech.com申請活動碼。',
    /* REG_18 */        'REGISTER_PAGE.TEACHER_ROLE_REMARKS': '任教科目/負責項目(備註/ 說明)',
    /* REG_19 */        'REGISTER_PAGE.TEACHER_NAME_INVALID': '請提供一個教師姓名',
    /* REG_20 */        'REGISTER_PAGE.TEACHER_TEL_INVALID': '請提供有效的電話號碼',
    /* REG_21 */        'REGISTER_PAGE.TEACHER_ROLE_INVALID': '請選擇一個項目',
    /* REG_22 */        'REGISTER_PAGE.TEACHER_ROLE_REMARKS_INVALID': '請填寫備註',
    /* REG_23 */        'REGISTER_PAGE.SCHOOL_SIGN_UP_SUCCESS': '您的申請已提交',
    /* REG_24 */        'REGISTER_PAGE.SCHOOL_NAME_LABEL': '學校全名',
    /* REG_25 */        'REGISTER_PAGE.SCHOOL_NAME_INVALID': '請提供學校全名',
    /* REG_26 */        'REGISTER_PAGE.REGISTER_WITH_LICENSE': '以授權碼註冊帳戶',
    /* REG_27 */        'REGISTER_PAGE.REGISTER_WITH_LICENSE_DESCRIPTION': '立即購買含了 Maker Cloud 的硬件以獲取授權碼，享受 Maker Cloud 的樂趣。',
    /* REG_28 */        'REGISTER_PAGE.REGISTER_FOR_SCHOOL_DESCRIPTION': '立即為您的學生註冊，享受最優質的 Maker Cloud 功能，使您的STEM課堂更豐富。',
    /* REG_29 */        'REGISTER_PAGE.REGISTER_WITH_EVENT_CODE_DESCRIPTION': '立即註冊訪客帳號，試用7天的Maker Cloud。',
    /* REG_30 */        'REGISTER_PAGE.LICENSE_KEY_INVALID': '無效的授權碼',

    /* PW_01 */         'PASSWORD.RESET_PASSWORD_TITLE': '重設密碼申請',
    /* PW_02 */         'PASSWORD.RESET_PASSWORD_NOTICE': '提交申請後，重設密碼的連結將發送到你的電子郵件。請耐心等候，如未能收到郵件，請確保電郵地址正確，並檢查垃圾郵件匣。',
    /* PW_03 */         'PASSWORD.RESET_PASSWORD_COOL_DOWN': '你已提交過重設密碼申請，請於一分鐘後才提交新的申請。',
    /* PW_04 */         'PASSWORD.RESET_PASSWORD_RESET_BUTTON': '重設密碼',
    /* PW_05 */         'PASSWORD.RESET_PASSWORD_EMAIL_NOT_EXIST': '用戶電郵不存在',
    /* PW_06 */         'PASSWORD.OLD_PASSWORD': '現有密碼',
    /* PW_07 */         'PASSWORD.NEW_PASSWORD': '新密碼',
    /* PW_08 */         'PASSWORD.NEW_PASSWORD_AGAIN': '再次輸入新密碼',
    /* PW_09 */         'PASSWORD.PASSWORD_LENGTH_HINTS': '密碼長度應在6到20之間',
    /* PW_10 */         'PASSWORD.SKIP_SETUP_PASSWORD': '暫時不設定密碼',
    /* PW_11 */         'PASSWORD.CHANGE_PASSWORD_BUTTON': '更改密碼',
    /* PW_12 */         'PASSWORD.CURRENT_PASSWORD_INCORRECT': '現有密碼不正確',
    /* PW_13 */         'PASSWORD.CHANGE_PASSWORD_TITLE': '更改密碼',
    /* PW_14 */         'PASSWORD.NEW_PASSWORD_NOT_MATCH': '請輸入相同的密碼',
    /* PW_15 */         'PASSWORD.SETUP_PASSWORD_TITLE': '初始化密碼',
    /* PW_16 */         'PASSWORD.SETUP_PASSWORD_NOTICE': '如果你使用谷歌服務登入，你不一定需要建立密碼。如你不是使用第三方認證登入，請建立密碼。',
    /* PW_17 */         'PASSWORD.SETUP_PASSWORD_BUTTON': '建立密碼',
    /* PW_18 */         'PASSWORD.SETUP_PASSWORD_PASSWORD_EXISTED': '你已曾經建立密碼，請到更改密碼頁。',

    /* HEA_LANG_01 */   'HEADER.SELECT_LANGUAGE': '選擇語言',
    /* HEA_LANG_02 */   'HEADER.LANGUAGE_TC': '繁體中文',
    /* HEA_LANG_03 */   'HEADER.LANGUAGE_SC': '简体中文',
    /* HEA_LANG_04 */   'HEADER.LANGUAGE_EN': 'English',

    /* HEA_USER_01 */   'HEADER.USER.FAQ': '常見問題',
    /* HEA_USER_02 */   'HEADER.USER.SUPPORT': '聯繫',
    /* HEA_USER_03 */   'HEADER.USER.LOGOUT': '登出',

    /* FOO_01 */        'COMMON.FOO.SCALE': 'Maker Cloud - SCALE InnoTech Limited',
    /* FOO_02 */        'COMMON.FOO.CONTACT_US': '聯繫我們',
    /* FOO_03 */        'COMMON.FOO.PRIVACY': '私隱政策聲明',
    /* FOO_04 */        'COMMON.FOO.COPYRIGHT': '版權及知識產權',
    /* FOO_05 */        'COMMON.FOO.DISCLAIMER': '免責聲明',
    /* FOO_06 */        'COMMON.FOO.TERMS': '服務條款',
    /* FOO_07 */        'COMMON.FOO.MAKER_CLOUD': 'Maker Cloud',
    /* FOO_08 */        'COMMON.FOO.THIRD_PARTY': '第三方軟件使用',

    /* TT_PROJ_01 */    'COMMON.PROJECT.PROJECT': '項目',
    /* TT_PROJ_02 */    'COMMON.PROJECT.PROJECT_NAME': '項目名稱',
    /* TT_PROJ_03 */    'COMMON.PROJECT.CREATE_PROJECT': '創建項目',
    /* TT_PROJ_04 */    'COMMON.PROJECT.UPDATE_PROJECT': '更新項目',
    /* TT_PROJ_05 */    'COMMON.PROJECT.DELETE_PROJECT': '刪除項目',
    /* TT_PROJ_06 */    'COMMON.PROJECT.SHARE_PROJECT': '分享項目',
    /* TT_PROJ_07 */    'COMMON.PROJECT.ADD_TOPIC': '將主題添加到項目',
    /* TT_PROJ_08 */    'COMMON.PROJECT.REMOVE_TOPIC': '從項目中刪除主題',
    /* TT_PROJ_09 */    'COMMON.PROJECT.TITLE_LABEL': '選擇項目類型：',
    /* TT_PROJ_10 */    'COMMON.PROJECT.TYPE.IOT.NAME': '物聯網項目',
    /* TT_PROJ_11 */    'COMMON.PROJECT.TYPE.IOT.DESC': '將 Micro-bit 連接到MakerCloud，享受許多功能，包括數據可視化和儀表板演示',
    /* TT_PROJ_12 */    'COMMON.PROJECT.TYPE.LBS.NAME': '位置基礎服務項目（LBS）',
    /* TT_PROJ_13 */    'COMMON.PROJECT.TYPE.LBS.DESC': '讓您的 Micro-bit 成為Ibeacon或Eddystone，跟踪您當前的位置，定義可步行節點，為您提供到達目的地的最短路徑, 即將推出...',
    /* TT_PROJ_14 */    'COMMON.PROJECT.TYPE.MLP.NAME': '機器學習項目',
    /* TT_PROJ_15 */    'COMMON.PROJECT.TYPE.MLP.DESC': '即將推出...',
    /* TT_PROJ_16 */    'COMMON.PROJECT.POWERED_BY_QBS': '由QBS提供支持',
    /* TT_PROJ_17 */    'COMMON.PROJECT.PROJECT_NAME_PLACEHOLDER': '新的項目名稱',
    /* TT_PROJ_18 */    'COMMON.PROJECT.CREATE_SUCCESS': '您的項目已創建。',
    /* TT_PROJ_19 */    'COMMON.PROJECT.MISSING_PROJECT_NAME': '缺少项目名称',
    /* TT_PROJ_20 */    'COMMON.PROJECT.UPDATE_SUCCESS': '您的項目已更新。',
    /* TT_PROJ_21 */    'COMMON.PROJECT.PROJECT_LIST': '項目列表',
    /* TT_PROJ_22 */    'COMMON.PROJECT.REMOVE_TOPIC_TITLE': '從項目中刪除主題',
    /* TT_PROJ_23 */    'COMMON.PROJECT.REMOVE_TOPIC_DESC': '這不會刪除主題，您可以稍後將其添加回項目！',
    /* TT_PROJ_24 */    'COMMON.PROJECT.SHOW_BRIEF_INFO_TAB': '基本信息',
    /* TT_PROJ_25 */    'COMMON.PROJECT.SHOW_TOPICS_UNDER_PROJECT_TAB': '相關主題',
    /* TT_PROJ_26 */    'COMMON.PROJECT.SHOW_CHART_UNDER_PROJECT_TAB': '相關圖表',
    /* TT_PROJ_27 */    'COMMON.PROJECT.DOWNLOAD_DATA_UNDER_PROJECT_TAB': '下載數據',
    /* TT_PROJ_28 */    'COMMON.PROJECT.EVENT_TRIGGER': '數據觸發',
    /* TT_PROJ_29 */    'COMMON.PROJECT.QRCODE_DESC': '掃描二維碼以加入項目或與其他人分享鏈接以加入項目',
    /* TT_PROJ_30 */    'COMMON.PROJECT.ALREADY_JOINED_PROJECT': '你已經加入了這個項目',
    /* TT_PROJ_31 */    'COMMON.PROJECT.VIEW_PROJECT_LABEL': '您需要瀏覽項目內容嗎？',
    /* TT_PROJ_32 */    'COMMON.PROJECT.INVALID_PROJECT_SHARE_TOKEN': '該邀請碼無效，無法加入該項目。',
    /* TT_PROJ_33 */    'COMMON.PROJECT.CONFIRM_TO_JOIN_PROJECT': '你確定加入這個項目{PROJECT_NAME}嗎？',
    /* TT_PROJ_34 */    'COMMON.PROJECT.DATA_VIEWER': '即時數據記錄',
    /* TT_PROJ_35 */    'COMMON.PROJECT.SHOW_100_RECORD': '最多顯示100條記錄',
    /* TT_PROJ_36 */    'COMMON.PROJECT.DELETE_PROJECT_AND_DATA': '刪除項目和所有數據',
    /* TT_PROJ_37 */    'COMMON.PROJECT.MORE_SETTING': '更多設定',
    /* TT_PROJ_38 */    'COMMON.PROJECT.MY_FILES': '我的檔案',
    /* TT_PROJ_39 */    'COMMON.PROJECT.FILE_LIST': '檔案列表',
    /* TT_PROJ_40 */    'COMMON.PROJECT.FILE_UPLOAD_BOX_TEXT': '拖到此處或點擊以上載文件',
    /* TT_PROJ_41 */    'COMMON.PROJECT.FILE_NAME': '檔案名稱',
    /* TT_PROJ_42 */    'COMMON.PROJECT.FILE_SIZE': '檔案大小',
    /* TT_PROJ_43 */    'COMMON.PROJECT.ADD_FILE': '加入檔案',
    /* TT_PROJ_44 */    'COMMON.PROJECT.COPY_EMBEDDED_MAP_URL': '複製嵌入連結',
    /* TT_PROJ_45 */    'COMMON.PROJECT.TOTAL_NUMBER_OF_DATA': '數據數量',
    /* TT_PROJ_46 */    'COMMON.PROJECT.NUMBER_OF_EVENT_TRIGGER': '數據觸發數量',
    /* TT_PROJ_47 */    'COMMON.PROJECT.NUMBER_OF_CHART': '圖表數量',
    /* TT_PROJ_48 */    'COMMON.PROJECT.NUMBER_OF_PROJECT_FILE': '檔案數量',
    /* TT_PROJ_49 */    'COMMON.PROJECT.NUMBER_OF_PROJECT_USER': '用戶數量',
    /* TT_PROJ_50 */    'COMMON.PROJECT.IOT_USAGE': '物聯網\n 功能用量',
    /* TT_PROJ_51 */    'COMMON.PROJECT.AI_USAGE': '人工智能\n 功能用量',
    /* TT_PROJ_52 */    'COMMON.PROJECT.VISUALIZATION_USAGE': '圖表\n 功能用量',
    /* TT_PROJ_53 */    'COMMON.PROJECT.OPEN_API_USAGE': '開放API\n功能用量',
    /* TT_PROJ_54 */    'COMMON.PROJECT.EVENT_TRIGGER_USAGE': '數據觸發\n 功能用量',
    /* TT_PROJ_55 */    'COMMON.PROJECT.COLLABORATION_USAGE': '協作\n 功能用量',
    /* TT_PROJ_56 */    'COMMON.PROJECT.TOTAL_MESSAGE_COUNT': '總數據數量',
    /* TT_PROJ_57 */    'COMMON.PROJECT.NUMBER_OF_DATA_TYPE': '數據類型數量',
    /* TT_PROJ_58 */    'COMMON.PROJECT.NUMBER_OF_TOPIC': '主題數量',
    /* TT_PROJ_59 */    'COMMON.PROJECT.PROJECT_OVERVIEW': '項目簡介',
    /* TT_PROJ_60 */    'COMMON.PROJECT.IOT': '物聯網',
    /* TT_PROJ_61 */    'COMMON.PROJECT.AI': '人工智能',
    /* TT_PROJ_62 */    'COMMON.PROJECT.OPEN_API': '開放應用程式介面',
    /* TT_PROJ_63 */    'COMMON.PROJECT.EXPLAIN_REAL_TIME_LOGGER': '即時顯示項目的活動記錄，包括物聯網的數據，數據觸發，Open API 的使用',
    /* TT_PROJ_64 */    'COMMON.PROJECT.EXPLAIN_FILE_LIST': '你可以上載代碼文件，例如：MakeCode/ Python 等等',
    /* TT_PROJ_65 */    'COMMON.PROJECT.EXPLAIN_TOPIC_MESSAGE_COUNT': '顯示物聯網主題的數據數量',
    /* TT_PROJ_66 */    'COMMON.PROJECT.EXPLAIN_USER_LIST': '邀請別人同共編輯你的項目',
    /* TT_PROJ_67 */    'COMMON.PROJECT.PROJECT_USAGE_ANALYSIS': '用量分析',
    /* TT_PROJ_68 */    'COMMON.PROJECT.EXPLORE_AND_LEARN': '探索/教學',
    /* TT_PROJ_69 */    'COMMON.PROJECT.CREATE_CHART_SOCKET': '已創建圖表{CHART_NAME}',
    /* TT_PROJ_70 */    'COMMON.PROJECT.UPLOADED_NEW_FILE_SOCKET': '上載了新文件{FILE_NAME}',
    /* TT_PROJ_71 */    'COMMON.PROJECT.REMOVED_FILE': '已刪除文件{FILE_NAME}',
    /* TT_PROJ_72 */    'COMMON.PROJECT.NEW_USER_JOINED': '已加入新用戶{USERNAME}',
    /* TT_PROJ_73 */    'COMMON.PROJECT.REMOVED_USER': '已刪除用戶{USERNAME}',

    /* TT_TOP_01 */    'COMMON.TOPIC.TOPIC': '主題',
    /* TT_TOP_02 */    'COMMON.TOPIC.TOPIC_DESCRIPTION': '主題描述',
    /* TT_TOP_03 */    'COMMON.TOPIC.TOPIC_NAME': '主題名稱',
    /* TT_TOP_04 */    'COMMON.TOPIC.TOPIC_LIST': '主題列表',
    /* TT_TOP_05 */    'COMMON.TOPIC.CREATE_TOPIC': '創建主題',
    /* TT_TOP_06 */    'COMMON.TOPIC.UPDATE_TOPIC': '更新主題',
    /* TT_TOP_07 */    'COMMON.TOPIC.DELETE_TOPIC': '刪除主題',
    /* TT_TOP_08 */    'COMMON.TOPIC.ADD_PROJECT': '將項目添加到主題',
    /* TT_TOP_09 */    'COMMON.TOPIC.REMOVE_PROJECT': '從主題中刪除項目',
    /* TT_TOP_10 */    'COMMON.TOPIC.TALK_TO_TOPIC': '發送消息到主題',
    /* TT_TOP_11 */    'COMMON.TOPIC.SHARE_TOPIC': '分享主題',
    /* TT_TOP_12 */    'COMMON.TOPIC.ACTIVE': '啟用',
    /* TT_TOP_13 */    'COMMON.TOPIC.TOPIC_NAME_PLACEHOLDER': '主題名稱將自動生成',
    /* TT_TOP_14 */    'COMMON.TOPIC.TOPIC_DESC_PLACEHOLDER': '描述你的主題',
    /* TT_TOP_15 */    'COMMON.TOPIC.TOPIC_MESSAGE_PLACEHOLDER': '文字信息',
    /* TT_TOP_16 */    'COMMON.TOPIC.TOPIC_MESSAGE_EMPTY': '文字信息不能為空白',
    /* TT_TOP_17 */    'COMMON.TOPIC.TOPIC_MESSAGE_SENT': '文字信息成功發送到主題',
    /* TT_TOP_18 */    'COMMON.TOPIC.NO_PROJECT_SELECTED': '沒有選擇任何項目',
    /* TT_TOP_19 */    'COMMON.TOPIC.TOPIC_DESC_EMPTY': '主題描述不能為空白',
    /* TT_TOP_20 */    'COMMON.TOPIC.CHANGE_TOPIC_DESCRIPTION_TITLE': '更改主題描述',
    /* TT_TOP_21 */    'COMMON.TOPIC.TOPIC_NAME_NOT_FOUND': '找不到主題名稱',
    /* TT_TOP_22 */    'COMMON.TOPIC.MQTT_SEND_MESSAGE_TOOL': '物聯網信息發送工具',
    /* TT_TOP_23 */    'COMMON.TOPIC.TOPIC_LOCATION': '主題定位',

    /* TT_DATP_01 */   'COMMON.DATA_TYPE.DATA_TYPE': '數據類型',
    /* TT_DATP_02 */   'COMMON.DATA_TYPE.DATA_TYPE_KEY': '識別碼',
    /* TT_DATP_03 */   'COMMON.DATA_TYPE.DATA_TYPE_NAME': '名稱',
    /* TT_DATP_04 */   'COMMON.DATA_TYPE.DATA_TYPE_DESC': '描述',
    /* TT_DATP_05 */   'COMMON.DATA_TYPE.CREATE_DATA_TYPE': '創建數據類型',
    /* TT_DATP_06 */   'COMMON.DATA_TYPE.UPDATE_DATA_TYPE': '更新數據類型',
    /* TT_DATP_07 */   'COMMON.DATA_TYPE.DELETE_DATA_TYPE': '刪除數據類型',
    /* TT_DATP_08 */   'COMMON.DATA_TYPE.DATA_TYPE_LIST': '數據類型列表',
    /* TT_DATP_09 */   'COMMON.DATA_TYPE.UNIQUE_LABEL': '數據類型不能重複',
    /* TT_DATP_10 */   'COMMON.DATA_TYPE.NAME_PLACEHOLDER': '您的數據類型名稱',
    /* TT_DATP_11 */   'COMMON.DATA_TYPE.KEY_PLACEHOLDER': '您的數據類型識別碼',
    /* TT_DATP_12 */   'COMMON.DATA_TYPE.DESC_PLACEHOLDER': '描述您的數據類型',
    /* TT_DATP_13 */   'COMMON.DATA_TYPE.INVALID_FIELD': '無效的數據類型名稱或識別碼',
    /* TT_DATP_14 */   'COMMON.DATA_TYPE.DELETE_DATA_TYPE_HELP_TEXT': '您確定要刪除數據類型嗎？所有這些數據類型下的數據也將被刪除。此操作無法恢復。',

    /* TT_DASH_01 */   'COMMON.DASHBOARD.DASHBOARD': '儀表板',
    /* TT_DASH_02 */   'COMMON.DASHBOARD.DELETE_DASHBOARD': '刪除儀表板',
    /* TT_DASH_03 */   'COMMON.DASHBOARD.CREATE_DASHBOARD': '創建儀表板',
    /* TT_DASH_04 */   'COMMON.DASHBOARD.UPDATE_DASHBOARD': '更新儀表板',
    /* TT_DASH_05 */   'COMMON.DASHBOARD.SWITCH_DASHBOARD': '切換儀表板',
    /* TT_DASH_06 */   'COMMON.DASHBOARD.NO_CHART_IN_DASHBOARD': '此儀表板中沒有圖表，您可以將已創建的圖表添加到此儀表板中',
    /* TT_DASH_07 */   'COMMON.DASHBOARD.DASHBOARD_CREATED': '您的新儀表盤已創建。',
    /* TT_DASH_08 */   'COMMON.DASHBOARD.DASHBOARD_PLACEHOLDER': '儀表板名稱',
    /* TT_DASH_09 */   'COMMON.DASHBOARD.ADD_CHART_TO_DASHBOARD': '添加到儀表板',
    /* TT_DASH_10 */   'COMMON.DASHBOARD.DASHBOARD_NAME_EMPTY': '儀表板名稱不能為空白',
    /* TT_DASH_11 */   'COMMON.DASHBOARD.SELECT_A_DASHBOARD': '將圖表添加到哪個儀表板？',
    /* TT_DASH_12 */   'COMMON.DASHBOARD.NO_DASHBOARD_SELECTED': '請選擇一個儀表板',

    /* TT_CHAR_01 */   'COMMON.CHART.CHART': '圖表',
    /* TT_CHAR_02 */   'COMMON.CHART.CREATE_CHART': '創建圖表',
    /* TT_CHAR_03 */   'COMMON.CHART.DELETE_CHART': '刪除圖表',
    /* TT_CHAR_04 */   'COMMON.CHART.UPDATE_CHART': '更新圖表',
    /* TT_CHAR_05 */   'COMMON.CHART.VIEW_CHART_DETAIL': '查看詳細圖表資料',
    /* TT_CHAR_06 */   'COMMON.CHART.CREATE_CHART_TIPS': '只有具有數據的會顯示在這裡',
    /* TT_CHAR_07 */   'COMMON.CHART.CHART_NAME_EMPTY': '圖表名稱不能為空白',
    /* TT_CHAR_08 */   'COMMON.CHART.CHART_NAME': '圖表名稱',
    /* TT_CHAR_09 */   'COMMON.CHART.RECENT_CHART': '最近創建的圖表',
    /* TT_CHAR_10 */   'COMMON.CHART.CHOOSE_CHART_TYPE': '選擇圖表類型',
    /* TT_CHAR_11 */   'COMMON.CHART.GAUGE_CHART': '計量表',
    /* TT_CHAR_12 */   'COMMON.CHART.LINE_CHART': '折線圖',
    /* TT_CHAR_13 */   'COMMON.CHART.CHART_SETTING': '圖表設置',
    /* TT_CHAR_14 */   'COMMON.CHART.TOGGLE': '切換按鈕',
    /* TT_CHAR_15 */   'COMMON.CHART.CHECKBOX': '複選方塊',
    /* TT_CHAR_16 */   'COMMON.CHART.ON_OFF_BUTTON': '開關按鈕',
    /* TT_CHAR_17 */   'COMMON.CHART.PUSH_BUTTON': '單點按鈕',
    /* TT_CHAR_18 */   'COMMON.CHART.PLAIN_TEXT': '純文字',
    /* TT_CHAR_19 */   'COMMON.CHART.CONTROL_BUTTON': '控制按鈕',
    /* TT_CHAR_20 */   'COMMON.CHART.CHOOSE_SOURCE': '選擇數據來源',
    /* TT_CHAR_21 */   'COMMON.CHART.SELECT_CHART_TYPE_FIRST': '請選擇圖表類型',
    /* TT_CHAR_22 */   'COMMON.CHART.SELECTED_CHART_SOURCE': '選擇圖表來源',
    /* TT_CHAR_23 */   'COMMON.CHART.SELECTED_CHART_TYPE': '已選擇的圖表類型',
    /* TT_CHAR_24 */   'COMMON.CHART.BUTTON_DISPLAY_TEXT': '顯示文字',
    /* TT_CHAR_25 */   'COMMON.CHART.CHART_COLOR': '顏色',
    /* TT_CHAR_26 */   'COMMON.CHART.BUTTON_ACTIVE_VALUE': '啟動時數值',
    /* TT_CHAR_27 */   'COMMON.CHART.BUTTON_INACTIVE_VALUE': '非啟動時數值',
    /* TT_CHAR_28 */   'COMMON.CHART.CHART_MIN_VALUE': '最小值',
    /* TT_CHAR_29 */   'COMMON.CHART.CHART_MAX_VALUE': '最大值',
    /* TT_CHAR_30 */   'COMMON.CHART.LOCK_BUTTON': '鎖開關',
    /* TT_CHAR_31 */   'COMMON.CHART.PLAY_BUTTON': '播放開關',
    /* TT_CHAR_32 */   'COMMON.CHART.SOUND_BUTTON': '音量開關',
    /* TT_CHAR_33 */   'COMMON.CHART.CHART_HISTORY_STARTING_FROM': '開始時間',
    /* TT_CHAR_34 */   'COMMON.CHART.CHART_HISTORY_UNTIL': '完結時間',
    /* TT_CHAR_35 */   'COMMON.CHART.CHART_HISTORY_FOR_EVERY': '相隔時間',
    /* TT_CHAR_36 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_VALUE': '數值取樣方法',
    /* TT_CHAR_37 */   'COMMON.CHART.CHART_HISTORY_EVERY_MONTH': '每月',
    /* TT_CHAR_38 */   'COMMON.CHART.CHART_HISTORY_EVERY_DAY': '每天',
    /* TT_CHAR_39 */   'COMMON.CHART.CHART_HISTORY_EVERY_HOUR': '每小時',
    /* TT_CHAR_40 */   'COMMON.CHART.CHART_HISTORY_EVERY_MINUTE': '每分鐘',
    /* TT_CHAR_41 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_AVG': '平均',
    /* TT_CHAR_42 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_SUM': '總和',
    /* TT_CHAR_43 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_MIN': '最低值',
    /* TT_CHAR_44 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_MAX': '最大值',
    /* TT_CHAR_45 */   'COMMON.CHART.CHART_HISTORY_AGGREGATE_COUNT': '總次數',
    /* TT_CHAR_46 */   'COMMON.CHART.CHART_LIVE_DATA': '最新數據',
    /* TT_CHAR_47 */   'COMMON.CHART.CHART_HISTORY_DATA': '歷史數據',
    /* TT_CHAR_48 */   'COMMON.CHART.OUTPUT_TYPE': '傳送格式',
    /* TT_CHAR_49 */   'COMMON.CHART.KEY_VALUE_PAIR': '數據類型信息',
    /* TT_CHAR_50 */   'COMMON.CHART.SIMPLE_MESSAGE': '純文字訊息',
    /* TT_CHAR_51 */   'COMMON.CHART.MESSAGE_TYPE': '信息類別',
    /* TT_CHAR_52 */   'COMMON.CHART.CHOOSE_STYLE': '請選擇款式或輸入文字',
    /* TT_CHAR_53 */   'COMMON.CHART.WHEN_BUTTON_ON': '開啟時',
    /* TT_CHAR_54 */   'COMMON.CHART.WHEN_BUTTON_OFF': '關閉時',
    /* TT_CHAR_55 */   'COMMON.CHART.COPY_EMBEDDED_CHART_URL': '複製嵌入連結',
    /* TT_CHAR_56 */   'COMMON.CHART.POINT_MAP': '圓形符號地圖',
    /* TT_CHAR_57 */   'COMMON.CHART.SOURCE_DESCRIPTION': '描述',
    /* TT_CHAR_58 */   'COMMON.CHART.LATITUDE': '緯度',
    /* TT_CHAR_59 */   'COMMON.CHART.LONGITUDE': '經度',
    /* TT_CHAR_60 */   'COMMON.CHART.NOT_AVAILABLE_GOOGLE_MAP': '你的所在地未能支援 Google 地圖',
    /* TT_CHAR_61 */   'COMMON.CHART.MIN_MAX_DESCRIPTION': '請提供數據的最小及最大值',
    /* TT_CHAR_62 */   'COMMON.CHART.CHART_TOOLBAR_MOVE': '掌形工具',
    /* TT_CHAR_63 */   'COMMON.CHART.CHART_TOOLBAR_SELECT': '選擇工具',
    /* TT_CHAR_64 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_XY': '放大',
    /* TT_CHAR_65 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_X': '放大 x 軸',
    /* TT_CHAR_66 */   'COMMON.CHART.CHART_TOOLBAR_ZOOM_Y': '放大 y 軸',
    /* TT_CHAR_67 */   'COMMON.CHART.SHOW_LATEST_POINT': '顯示最新',
    /* TT_CHAR_67 */   'COMMON.CHART.SHOW_LATEST_POINT_ALL': '所有',

    /* TT_SCH_01 */    'COMMON.SCHOOL.SCHOOL_LIST': '學校名單',
    /* TT_SCH_02 */    'COMMON.SCHOOL.SCHOOL_ID': '編號',
    /* TT_SCH_03 */    'COMMON.SCHOOL.SCHOOL_NAME': '學校名稱',
    /* TT_SCH_04 */    'COMMON.SCHOOL.SCHOOL_EMAIL_DOMAIN': '電子郵件',
    /* TT_SCH_05 */    'COMMON.SCHOOL.VALID_UNTIL': '有效期至',
    /* TT_SCH_06 */    'COMMON.SCHOOL.VALID_DATE': '有效期',
    /* TT_SCH_07 */    'COMMON.SCHOOL.APPLICATION_STATUS': '申請狀態',
    /* TT_SCH_08 */    'COMMON.SCHOOL.NO_PENDING_APPLICATION': '沒有待處理的申請',
    /* TT_SCH_09 */    'COMMON.SCHOOL.INACTIVE': '未啟用',
    /* TT_SCH_10 */    'COMMON.SCHOOL.PROCESSED': '已處理',
    /* TT_SCH_11 */    'COMMON.SCHOOL.APPLICATION': '申請',
    /* TT_SCH_12 */    'COMMON.SCHOOL.TOTAL': '總共',
    /* TT_SCH_13 */    'COMMON.SCHOOL.APPROVED': '已批准',
    /* TT_SCH_14 */    'COMMON.SCHOOL.REJECTED': '已拒絕',
    /* TT_SCH_15 */    'COMMON.SCHOOL.PENDING': '待處理',
    /* TT_SCH_16 */    'COMMON.SCHOOL.SUBMISSION_TIME': '申請時間',
    /* TT_SCH_17 */    'COMMON.SCHOOL.APPROVE': '批准',
    /* TT_SCH_18 */    'COMMON.SCHOOL.REJECT': '拒絕',
    /* TT_SCH_19 */    'COMMON.SCHOOL.OFFICIAL_REMARKS': '备注',
    /* TT_SCH_20 */    'COMMON.SCHOOL.CONFIRM_APPROVE_APPLICATION': '您確定要批准申請？',
    /* TT_SCH_21 */    'COMMON.SCHOOL.CONFIRM_REJECT_APPLICATION': '你確定拒絕申請嗎？',

    /* TT_EVENT_01 */  'COMMON.EVENT.EVENT_NAME': '活動名稱',
    /* TT_EVENT_02 */  'COMMON.EVENT.EVENT_CODE': '活動代碼',
    /* TT_EVENT_03 */  'COMMON.EVENT.EVENT_TRIAL_PERIOD': '活動試用期',

    /* TT_VIRT_01 */  'COMMON.VIRTUAL.VIRTUAL_SMART_HOME': '虛擬智能家居',
    /* TT_VIRT_02 */  'COMMON.VIRTUAL.SETTING': '設定',
    /* TT_VIRT_03 */  'COMMON.VIRTUAL.HARDWARE_SETTING': '硬件設定',
    /* TT_VIRT_04 */  'COMMON.VIRTUAL.TURN_ON': '開啟',
    /* TT_VIRT_05 */  'COMMON.VIRTUAL.TURN_OFF': '關閉',
    /* TT_VIRT_06 */  'COMMON.VIRTUAL.TURN_ON_PLACEHOLDER': '開啟',
    /* TT_VIRT_07 */  'COMMON.VIRTUAL.TURN_OFF_PLACEHOLDER': '關閉',
    /* TT_VIRT_08 */  'COMMON.VIRTUAL.SAVE': '下載',
    /* TT_VIRT_09 */  'COMMON.VIRTUAL.LIGHT_BULB': '燈泡',
    /* TT_VIRT_10 */  'COMMON.VIRTUAL.VIRTUAL_LIGHT_BULB': '虛擬燈泡',

    /* ET_01 */        'EVENT_TRIGGER.TRIGGER_TITLE': '當以下情況發生時',
    /* ET_02 */        'EVENT_TRIGGER.FILTER_TITLE': '當以下條件為正確',
    /* ET_03 */        'EVENT_TRIGGER.ACTION_TITLE': '然後',
    /* ET_04 */        'EVENT_TRIGGER.NEW_TOPIC_MESSAGE_TRIGGER': '主題收到任何信息',
    /* ET_05 */        'EVENT_TRIGGER.MACHINE_LEARNING_COMPLETED': '人工智能程序完成',
    /* ET_06 */        'EVENT_TRIGGER.AND': '及',
    /* ET_07 */        'EVENT_TRIGGER.OR': '或',
    /* ET_08 */        'EVENT_TRIGGER.SAME_LEVEL': '加到同一層',
    /* ET_09 */        'EVENT_TRIGGER.SUB_LEVEL': '加到下一層',
    /* ET_10 */        'EVENT_TRIGGER.TOPIC': 'MQTT 主題',
    /* ET_11 */        'EVENT_TRIGGER.S_MESSAGE': '的信息',
    /* ET_12 */        'EVENT_TRIGGER.S': '的',
    /* ET_13 */        'EVENT_TRIGGER.SUBJECT_KEY': '數據類型',
    /* ET_14 */        'EVENT_TRIGGER.OBJECT_KEY': '內容',
    /* ET_15 */        'EVENT_TRIGGER.COMPARATOR': '比較',
    /* ET_16 */        'EVENT_TRIGGER.TOPIC_NAME': '主題名稱',
    /* ET_17 */        'EVENT_TRIGGER.ADD': '加入',
    /* ET_18 */        'EVENT_TRIGGER.PUBLISH_MQTT_MESSAGE': '發佈信息',
    /* ET_19 */        'EVENT_TRIGGER.SEND_SMS': '發送 SMS 信息',
    /* ET_10 */        'EVENT_TRIGGER.SEND_EMAIL': '發送電郵',
    /* ET_21 */        'EVENT_TRIGGER.PUBLISH': '發送',
    /* ET_22 */        'EVENT_TRIGGER.TO_TOPIC': '到 MQTT 主題',
    /* ET_23 */        'EVENT_TRIGGER.APPLET': '小程式',
    /* ET_24 */        'EVENT_TRIGGER.VALIDATION_FAILED': '資料錯誤！',
    /* ET_25 */        'EVENT_TRIGGER.TRIGGER_VALIDATION_FAILED': '請確保您已選擇至少一個條件並填寫正確的信息',
    /* ET_26 */        'EVENT_TRIGGER.FILTER_VALIDATION_FAILED': '請確保您已選擇至少一個條件並填寫正確的信息',
    /* ET_27 */        'EVENT_TRIGGER.ACTION_VALIDATION_FAILED': '請確保您已選擇至少一個操作並填寫正確的信息',
    /* ET_28 */        'EVENT_TRIGGER.ADD_NEW_FILTER': '加入條件',
    /* ET_29 */        'EVENT_TRIGGER.WHEN': '當',
    /* ET_30 */        'EVENT_TRIGGER.CONDITION': '條件',
    /* ET_31 */        'EVENT_TRIGGER.SIMPLE_MESSAGE_CONDITION': '當 MQTT 主題收到指定內容',
    /* ET_32 */        'EVENT_TRIGGER.KEY_VALUE_CONDITION': '當 MQTT 主題收到數據類型為指定內容',
    /* ET_33 */        'EVENT_TRIGGER.MESSAGE': '信息',
    /* ET_34 */        'EVENT_TRIGGER.IF': '如果',
    /* ET_35 */        'EVENT_TRIGGER.CONTAINS': '包含',
    /* ET_36 */        'EVENT_TRIGGER.NOT_CONTAIN': '不包含',
    /* ET_37 */        'EVENT_TRIGGER.CREATE_APPLET': '新增小程式',
    /* ET_38 */        'EVENT_TRIGGER.APPLET_LIST': '小程式列表',
    /* ET_39 */        'EVENT_TRIGGER.APPLET_NAME': '小程式名稱',
    /* ET_40 */        'EVENT_TRIGGER.APPLET_NAME_VALIDATION_FAILED': '請給你的小程式命名',
    /* ET_41 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_ACTION': '觸發 IFTTT Webhook',
    /* ET_42 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_EVENT': 'IFTTT Webhook 事件',
    /* ET_43 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_KEY': 'IFTTT Webhook 鑰匙',
    /* ET_44 */        'EVENT_TRIGGER.IFTTT_WEBHOOK_JSON_CONTENT': '補充信息',
    /* ET_45 */        'EVENT_TRIGGER.WEBHOOK_IS_TRIGGERED': 'Webhook 被觸發',
    /* ET_46 */        'EVENT_TRIGGER.WEBHOOK_NAME': 'Webhook名稱',
    /* ET_47 */        'EVENT_TRIGGER.WEBHOOK_URL': 'Webhook網址',
    /* ET_48 */        'EVENT_TRIGGER.WEBHOOK_URL_HIDDEN_DESCRIPTION': 'Webhook網址會在創建後顯示',
    /* ET_49 */        'EVENT_TRIGGER.DIALOGFLOW_INTENT_IS_TRIGGED': 'Dialogflow Intent被觸發',
    /* ET_50 */        'EVENT_TRIGGER.INTENT_NAME': 'Intent名稱',
    /* ET_51 */        'EVENT_TRIGGER.DIALOGFLOW_URL': 'Dialogflow網址',
    /* ET_52 */        'EVENT_TRIGGER.DIALOGFLOW_URL_HIDDEN_DESCRIPTION': 'Dialogflow網址會在創建後顯示',
    /* ET_53 */        'EVENT_TRIGGER.APPLET_ENABLE': '啓用',
    /* ET_54 */        'EVENT_TRIGGER.MQTT_TRIGGER_NAME': 'MQTT 觸發',
    /* ET_55 */        'EVENT_TRIGGER.WEB_HOOK_TRIGGER_NAME': 'Webhook 觸發',
    /* ET_56 */        'EVENT_TRIGGER.AI_TRIGGER_NAME': 'AI 觸發',
    /* ET_57 */        'EVENT_TRIGGER.DIALOG_FLOW_TRIGGER_NAME': 'DialogFlow 觸發',
    /* ET_58 */        'EVENT_TRIGGER.CONDITION_FILTER_NAME': '條件篩選',
    /* ET_59 */        'EVENT_TRIGGER.IFTTT_ACTION_NAME': '通知 IFTTT',
    /* ET_60 */        'EVENT_TRIGGER.MQTT_ACTION_NAME': '通知 MQTT',
    /* ET_61 */        'EVENT_TRIGGER.OBJECT_IS_DETECTED': '當圖像識別發現物件',
    /* ET_62 */        'EVENT_TRIGGER.FACE_IS_DETECTED': '當人瞼識別發現人瞼',
    /* ET_63 */        'EVENT_TRIGGER.SEND_HTTP_RESPONSE_ACTION': '設定 HTTP Response',
    /* ET_64 */        'EVENT_TRIGGER.SEND_HTTP_RESPONSE_ACTION_TITLE': '設定 HTTP Response',
    /* ET_65 */        'EVENT_TRIGGER.FACE_DETECTION_TRIGGER': '人臉檢測觸發',
    /* ET_66 */        'EVENT_TRIGGER.OBJECT_DETECTION_TRIGGER': '圖像識別觸發',
    /* ET_67 */        'EVENT_TRIGGER.UPDATE_GOOGLE_SHEET': '更新 Google 表格',
    /* ET_68 */        'EVENT_TRIGGER.ALLOW_EDIT_GOOGLE_SHEET': '第 1 步：允許我們編輯您的 Google 表格',
    /* ET_69 */        'EVENT_TRIGGER.GOOGLE_SHEET_URL': '第 2 步：Google 表格 URL',
    /* ET_70 */        'EVENT_TRIGGER.GET_GOOGLE_SHEET_ID': '獲取 ID',
    /* ET_71 */        'EVENT_TRIGGER.CHANGE_GOOGLE_SHEET_URL': '更改網址',
    /* ET_72 */        'EVENT_TRIGGER.GOOGLE_SHEET_FEATURE_TITLE': '更新 Google 表格',

    /* MY_SUB_01 */    'MY_SUB.MY_SUBSCRIPTION': '我的訂閱狀態',
    /* MY_SUB_02 */    'MY_SUB.MANAGE_MY_SUBSCRIPTION': '管理我的訂閱狀態',
    /* MY_SUB_03 */    'MY_SUB.USE_LICENSE_KEY': '使用授權碼',
    /* MY_SUB_04 */    'MY_SUB.USE_EVENT_CODE': '使用活動碼',
    /* MY_SUB_05 */    'MY_SUB.ENTER_LICENSE_KEY': '輸入授權碼',
    /* MY_SUB_06 */    'MY_SUB.LICENSE_KEY': '授權碼',
    /* MY_SUB_07 */    'MY_SUB.ENTER_LICENSE_KEY_HINTS': '如果授權碼已過期，請聯繫我們績期。',
    /* MY_SUB_08 */    'MY_SUB.ENTER_EVENT_CODE': '輸入活動碼',
    /* MY_SUB_09 */    'MY_SUB.EVENT_CODE': '活動碼',
    /* MY_SUB_10 */    'MY_SUB.ENTER_EVENT_CODE_HINTS': '除非另有說明，否則活動碼將在7天后過期。',
    /* MY_SUB_11 */    'MY_SUB.SUBMIT_BUTTON': '使用',
    /* MY_SUB_12 */    'MY_SUB.SUB_STATUS_PREFIX': '您的帳戶已',
    /* MY_SUB_13 */    'MY_SUB.ACTIVE': '生效',
    /* MY_SUB_14 */    'MY_SUB.INACTIVE': '過期',
    /* MY_SUB_15 */    'MY_SUB.WHITELIST_STATEMENT': '您已被加入到白名單中。',
    /* MY_SUB_16 */    'MY_SUB.SCHOOL_SUB_STATEMENT': '你的學校或組織已申請大量授權。',
    /* MY_SUB_17 */    'MY_SUB.EVENT_CODE_STATEMENT': '你已申請活動碼授權。',
    /* MY_SUB_18 */    'MY_SUB.LICENSE_SUB_STATEMENT': '你已申請授權碼授權。',
    /* MY_SUB_19 */    'MY_SUB.LICENSED_TO': '授權到',
    /* MY_SUB_20 */    'MY_SUB.CONCURRENT_LOGIN': '同時登入人數限制',
    /* MY_SUB_21 */    'MY_SUB.CONSUMED': '最多使用人次',
    /* MY_SUB_22 */    'MY_SUB.EXPIRY_DATE': '到期日',
    /* MY_SUB_23 */    'MY_SUB.NO_EXPIRE_DATE': '您的帳戶不會過期。',
    /* MY_SUB_24 */    'MY_SUB.EXPIRE_DATE': '到期時間',
    /* MY_SUB_25 */    'MY_SUB.VIEW_LICENSE_DETAIL_BUTTON': '查看授權碼資料',
    /* MY_SUB_26 */    'MY_SUB.MANAGE_LICENSE': '授權碼管理',
    /* MY_SUB_27 */    'MY_SUB.UPGRADE_LICENSE_TITLE': '升級授權',
    /* MY_SUB_28 */    'MY_SUB.UPGRADE_LICENSE_MSG': '升級您的授權以享受更多功能!',

    /* OA_001 */    'OPEN_API.OPEN_API_DESC': '使用應用程式介面 (API) 連接到 MakerCloud 的各種服務。應用程式介面提供 HTTP 接口，用戶可以透過網絡客戶端 (Network Client）連接。',
    /* OA_002 */    'OPEN_API.OPEN_API_KEY_TITLE': '連接鑰匙 (Open Api Token)',
    /* OA_003 */    'OPEN_API.AI_CATEGORY': '人工智能 (AI) 類別',
    /* OA_004 */    'OPEN_API.IMAGE_RECOGNITION_TITLE': '圖像識別',
    /* OA_005 */    'OPEN_API.IMAGE_RECOGNITION_DESC': '圖像識別是指利用電腦對圖像進行處理、分析和理解，以識別圖像中的物件',
    /* OA_006 */    'OPEN_API.IMAGE_CLASSIFICATION_TITLE': '圖像分類',
    /* OA_007 */    'OPEN_API.IMAGE_CLASSIFICATION_DESC': '圖像分類是根據圖像的分析結果，使用分類器把圖像分類',
    /* OA_008 */    'OPEN_API.FACE_ANALYSIS_TITLE': '人臉分析',
    /* OA_009 */    'OPEN_API.FACE_ANALYSIS_DESC': '人臉分析能即時分析你的臉部位置，並顯示出來',
    /* OA_010 */    'OPEN_API.TEXT_ANALYSIS_TITLE': '文字分析',
    /* OA_011 */    'OPEN_API.TEXT_ANALYSIS_DESC': '使用文字分析將非結構化文字轉換成有意義的見解。取得情感分析、關鍵片語擷取及語言與實體偵測',
    /* OA_012 */    'OPEN_API.AUDIO_ANALYSIS_TITLE': '音訊分析',
    /* OA_013 */    'OPEN_API.AUDIO_ANALYSIS_DESC': '音訊分析將音訊轉譯成文字',
    /* OA_014 */    'OPEN_API.IOT_CATEGORY': '物聯網 (IoT) 類別',
    /* OA_015 */    'OPEN_API.IOT_DATA_STORE_TITLE': '物聯網數據儲存',
    /* OA_016 */    'OPEN_API.IOT_DATA_STORE_DESC': '將物聯網的硬體數據儲存到創客雲，並根據不同的主題、數據類型，硬體分類數據',
    /* OA_017 */    'OPEN_API.IOT_LOCATION_TITLE': '物聯網位置應用',
    /* OA_018 */    'OPEN_API.IOT_LOCATION_DESC': '根據物聯網主題的地理位置，而作出計算的應用',
    /* OA_019 */    'OPEN_API.IOT_TOPIC_TITLE': '物聯網主題應用',
    /* OA_020 */    'OPEN_API.IOT_TOPIC_DESC': '使用數據接口取得物聯網主題的資料',
    /* OA_021 */    'OPEN_API.MAKER_CLOUD_CATEGORY': '創客雲 (MakerCloud) 類別',
    /* OA_022 */    'OPEN_API.UCOA_TITLE': '用戶自訂開放接口',
    /* OA_023 */    'OPEN_API.UCOA_DESC': '由用戶自行定義的開放應用程式介面，用戶能設定對應的輸入、處理邏輯及輸出內容',
    /* OA_024 */    'OPEN_API.PROJECT_API_TITLE': '項目應用',
    /* OA_025 */    'OPEN_API.PROJECT_API_DESC': '使用數據接口取得創客雲項目的資料',
    /* OA_026 */    'OPEN_API.OPEN_DATA_CATEGORY': '開放數據 (Open Data) 類別',
    /* OA_027 */    'OPEN_API.WEATHER_API_TITLE': '天氣數據應用',
    /* OA_028 */    'OPEN_API.WEATHER_API_DESC': '使用數據接口取得即時、一天、及七天的天氣資料',
    /* OA_029 */    'OPEN_API.CURRENCY_API_TITLE': '貨幣匯率應用',
    /* OA_030 */    'OPEN_API.CURRENCY_API_DESC': '使用數據接口取得每天不同的凡貨幣匯率',
    /* OA_031 */    'OPEN_API.SCHOOL_API_TITLE': '學校列表',
    /* OA_032 */    'OPEN_API.SCHOOL_API_DESC': '使用數據接口取得香港所有學校的資料',
    /* OA_033 */    'OPEN_API.GENERIC_DATA_STORE_APPLICATION_CATEGORY': '通用數據儲存及應用類別',
    /* OA_034 */    'OPEN_API.GENERIC_DATA_STORE_TITLE': '通用數據儲存',
    /* OA_035 */    'OPEN_API.GENERIC_DATA_STORE_DESC': '讓用戶把不同類型都數據儲存到創客雲',
    /* OA_036 */    'OPEN_API.ACCESS_CONTROL_TITLE': '自訂存取控制',
    /* OA_037 */    'OPEN_API.ACCESS_CONTROL_DESC': '讓用戶設定存取控制的權限',
    /* OA_038 */    'OPEN_API.TIME_TRACKER_TITLE': '計時器',
    /* OA_039 */    'OPEN_API.TIME_TRACKER_DESC': '讓用戶開始、暫停，及繼續計時',
    /* OA_040 */    'OPEN_API.CODE_FILE_STORAGE_TITLE': '代碼文件儲存',
    /* OA_041 */    'OPEN_API.CODE_FILE_STORAGE_DESC': '讓用戶上傳及下載代碼文件',
    /* OA_042 */    'OPEN_API.OPEN_API_TITLE': '開放應用程式介面',
    /* OA_042 */    'OPEN_API.WEATHER.TEMPERATURE': '溫度',
    /* OA_042 */    'OPEN_API.WEATHER.HUMIDITY': '濕度',
    /* OA_042 */    'OPEN_API.WEATHER.UV_INDEX': '紫外線指數',
    /* OA_042 */    'OPEN_API.WEATHER.RAINFALL': '降雨量',

    /* AI_001 */    'AI.IMAGE_RECOGNITION_TITLE': '圖像識別',
    /* AI_002 */    'AI.IMAGE_RECOGNITION_DESC': '圖像識別是指利用電腦對圖像進行處理、分析和理解，以識別圖像中的物件',
    /* AI_003 */    'AI.IMAGE_STYLE_TRANSFER_TITLE': '圖片藝術師',
    /* AI_004 */    'AI.IMAGE_STYLE_TRANSFER_DESC': '使用深度學習分析一張圖片的特性,並應用到另一張圖片上, 最後產生一張新的圖片',
    /* AI_005 */    'AI.IMAGE_CLASSIFICATION_TITLE': '圖像分類',
    /* AI_006 */    'AI.IMAGE_CLASSIFICATION_DESC': '圖像分類是根據圖像的分析結果，使用分類器把圖像分類',
    /* AI_007 */    'AI.AUDIO_CLASSIFICATION_TITLE': '聲音分類',
    /* AI_008 */    'AI.AUDIO_CLASSIFICATION_DESC': '聲音分類是根據聲音的分析結果，使用分類器把聲音分類',
    /* AI_009 */    'AI.POSE_CAPTURE_TITLE': '姿勢檢測',
    /* AI_010 */    'AI.POSE_CAPTURE_DESC': '使用機器學習來檢測和分類您的姿勢',
    /* AI_011 */    'AI.FACE_DETECTION_TITLE': '人臉檢測',
    /* AI_012 */    'AI.FACE_DETECTION_DESC': '使用機器學習來檢測圖像中人臉的位置',
    /* AI_013 */    'AI.IMAGE_TRANSFER.STEP_1': '第一步：上傳第一張圖片',
    /* AI_014 */    'AI.IMAGE_TRANSFER.STEP_2': '第二步：上傳你想要的風格',
    /* AI_015 */    'AI.IMAGE_TRANSFER.STEP_3': '風格轉換後的結果',
    /* AI_016 */    'AI.IMAGE_TRANSFER.DETECTED_OBJECTS': '檢測到的物體',
    /* AI_017 */    'AI.IMAGE_TRANSFER.TRIGGER_IN_SECONDS': ' 秒內觸發',
    /* AI_018 */    'AI.IMAGE_TRANSFER.SEND_INTERVAL': '發送間隔',
    /* AI_019 */    'AI.IMAGE_TRANSFER.MIN_SCORE': '最低分數',
    /* AI_020 */    'AI.IMAGE_TRANSFER.TRIGGER_IN_PREFIX': '在 ',

    /* PER_01 */    'PERMISSION.YOUR_PERMISSIONS': '您的權限',
    /* PER_02 */    'PERMISSION.PROJECT': '項目',
    /* PER_03 */    'PERMISSION.SHARE_PROJECT': '分享項目',
    /* PER_04 */    'PERMISSION.PROJECT_COUNT': '項目數量',
    /* PER_05 */    'PERMISSION.TOPIC_COUNT': '主題數量',
    /* PER_06 */    'PERMISSION.DATA': '數據',
    /* PER_07 */    'PERMISSION.INTERVAL_LIMIT': '間隔限制',
    /* PER_08 */    'PERMISSION.DAILY_LIMIT': '每日限額',
    /* PER_09 */    'PERMISSION.EXPORT_DATA': '下載數據',
    /* PER_10 */    'PERMISSION.EVENT_TRIGGER': '數據觸發',
    /* PER_11 */    'PERMISSION.MQTT_TRIGGER': 'MQTT 觸發',
    /* PER_12 */    'PERMISSION.WEB_HOOK_TRIGGER': 'Webhook 觸發',
    /* PER_13 */    'PERMISSION.DIALOGFLOW_TRIGGER': 'Dialogflow 觸發',
    /* PER_14 */    'PERMISSION.MQTT_ACTION': '通知 MQTT',
    /* PER_15 */    'PERMISSION.IFTTT_ACTION': '通知 IFTTT',
    /* PER_16 */    'PERMISSION.PROJECT_MAP': '項目地圖',
    /* PER_17 */    'PERMISSION.DYNAMIC_LOCATION_UPDATE': '動態位置更新',
    /* PER_18 */    'PERMISSION.EMBED_PROJECT_MAP': '項目地圖嵌入',
    /* PER_19 */    'PERMISSION.CHART': '圖表',
    /* PER_20 */    'PERMISSION.LIVE_DATA': '最新數據',
    /* PER_21 */    'PERMISSION.HISTORICAL_DATA': '歷史數據',
    /* PER_22 */    'PERMISSION.BASIC_CHART': '基本圖表 (折線圖, 計量表, 純文字)',
    /* PER_23 */    'PERMISSION.CIRCLE_POINT_MAP': '圓形符號地圖',
    /* PER_24 */    'PERMISSION.EMBED_CHART': '圖表嵌入',
    /* PER_25 */    'PERMISSION.WIDGET': '控制按鈕',
    /* PER_26 */    'PERMISSION.BUTTON': '單點按鈕',
    /* PER_27 */    'PERMISSION.TOGGLE': '切換按鈕',
    /* PER_28 */    'PERMISSION.SUPPORTED': '支援',
    /* PER_29 */    'PERMISSION.UNLIMITED': '無限',
    /* PER_30 */    'PERMISSION.NOT_SUPPORT': '不支援',

    /* MAINTAIN_01 */  'MAINTAIN.PRE_MAINTENANCE_PERIOD_WARNING_MESSAGE': '系統將於{START_FROM}至{END_UNTIL}進行升級，屆時系統服務將受到影響或暫時性中斷。如有任何問題，請電郵至support@scaleinnotech.com。',
    /* MAINTAIN_02 */  'MAINTAIN.MAINTENANCE_PERIOD_LABEL': '系統升級',

    /* TT_ACT_01 */    'COMMON.ACTION.ADD': '增加',
    /* TT_ACT_02 */    'COMMON.ACTION.CREATE': '創建',
    /* TT_ACT_03 */    'COMMON.ACTION.EDIT': '編輯',
    /* TT_ACT_04 */    'COMMON.ACTION.SHARE': '分享',
    /* TT_ACT_05 */    'COMMON.ACTION.SEND': '發送',
    /* TT_ACT_06 */    'COMMON.ACTION.REMOVE': '除去',
    /* TT_ACT_07 */    'COMMON.ACTION.DELETE': '刪除',
    /* TT_ACT_08 */    'COMMON.ACTION.SEARCH': '搜索',
    /* TT_ACT_09 */    'COMMON.ACTION.COPY': '複製',
    /* TT_ACT_10 */    'COMMON.ACTION.ACTION_FAILED': '指令失效。 請稍後再試，如果您認為這是一個錯誤，請與我們聯繫',
    /* TT_ACT_11 */    'COMMON.ACTION.NO_RECORD': '沒有找到記錄',
    /* TT_ACT_12 */    'COMMON.ACTION.LOADING_MESSAGE': '請稍候..',
    /* TT_ACT_13 */    'COMMON.ACTION.CONFIRM': '確認',
    /* TT_ACT_14 */    'COMMON.ACTION.CANCEL': '取消',
    /* TT_ACT_15 */    'COMMON.ACTION.ADD_SUCCESS': '成功添加！',
    /* TT_ACT_16 */    'COMMON.ACTION.CREATE_SUCCESS': '成功創建！',
    /* TT_ACT_17 */    'COMMON.ACTION.UPDATE_SUCCESS': '成功更新！',
    /* TT_ACT_18 */    'COMMON.ACTION.REMOVE_SUCCESS': '成功刪除了！',
    /* TT_ACT_19 */    'COMMON.ACTION.FAILED': '無法完成！',
    /* TT_ACT_20 */    'COMMON.ACTION.COPY_SUCCESS': '已成功複製！',
    /* TT_ACT_21 */    'COMMON.ACTION.SHOW': '顯示：',
    /* TT_ACT_22 */    'COMMON.ACTION.VIEW': '立即查看',
    /* TT_ACT_23 */    'COMMON.ACTION.EXPAND': '放大',
    /* TT_ACT_24 */    'COMMON.ACTION.COLLAPSE': '缩小',
    /* TT_ACT_25 */    'COMMON.ACTION.CLOSE': '關閉',
    /* TT_ACT_26 */    'COMMON.ACTION.PREVIOUS': '上一步',
    /* TT_ACT_27 */    'COMMON.ACTION.NEXT': '下一步',
    /* TT_ACT_28 */    'COMMON.ACTION.OK': '確定',
    /* TT_ACT_29 */    'COMMON.ACTION.FILL_IN_ALL_DATA': '請填寫所有資料',
    /* TT_ACT_30 */    'COMMON.ACTION.SAVE': '保存',
    /* TT_ACT_31 */    'COMMON.ACTION.MQTT_APPLET_TRIGGERED': '{APPLET_NAME} 的 MQTT 觸發 {NAME} 已觸發',
    /* TT_ACT_32 */    'COMMON.ACTION.WEBHOOK_APPLET_TRIGGERED': '{APPLET_NAME} 的 Webhook 觸發 {NAME} 已觸發',
    /* TT_ACT_33 */    'COMMON.ACTION.DIALOG_FLOW_APPLET_TRIGGERED': '{APPLET_NAME} 的 DialogFlow 觸發 {NAME} 已觸發',
    /* TT_ACT_34 */    'COMMON.ACTION.APPLET_FILTER_RESULT_SUCCESS': '{APPLET_NAME} 條件檢查為通過',
    /* TT_ACT_35 */    'COMMON.ACTION.APPLET_FILTER_RESULT_FALL': '{APPLET_NAME} 條件檢查不通過，將不進行動作',
    /* TT_ACT_36 */    'COMMON.ACTION.IFTTT_ACTION_DONE': '{APPLET_NAME} 已通知 IFTTT ',
    /* TT_ACT_37 */    'COMMON.ACTION.MQTT_ACTION_DONE': '{APPLET_NAME} 已發送信息到MQTT',
    /* TT_ACT_38 */    'COMMON.ACTION.HTTP_RESPONSE_ACTION_DONE': '{APPLET_NAME} 已發送HTTP 回應',
    /* TT_ACT_39 */    'COMMON.ACTION.TOPIC_RECEIVED_MSG': '{TOPIC_NAME} 收到信息 {MSG}',
    /* TT_ACT_40 */    'COMMON.ACTION.OBJECT_DETECTION_APPLET_TRIGGERED': '{APPLET_NAME} 的圖像識別觸發 {NAME} 已觸發',
    /* TT_ACT_41 */    'COMMON.ACTION.FACE_DETECTION_APPLET_TRIGGERED': '{APPLET_NAME} 的人臉檢測觸發已觸發',
    /* TT_ACT_42 */    'COMMON.ACTION.RENAME': '更改名稱',

    /* TT_HOME_01 */   'COMMON.HOME': '主頁',
    /* TT_HOME_02 */   'COMMON.HOME.BACK_TO_HOME': '返回主頁',

    /* TT_BRD_01 */    'COMMON.BREADCRUMB.DETAIL': '詳細資料',

    /* TT_DEVI_01 */   'COMMON.DEVICE.DEVICE': '物聯網設備',
    /* TT_DEVI_02 */   'COMMON.DEVICE.DEVICE_SERIAL_NUMBER': '設備序列號',
    /* TT_DEVI_03 */   'COMMON.DEVICE.DEVICE_NAME': '設備名稱',

    /* TT_MENU_01 */   'COMMON.MENU.USER_LIST': '用戶列表',
    /* TT_MENU_02 */   'COMMON.MENU.WHITELIST_USER': '白名單用戶',

    /* TT_COM_01 */   'COMMON.CREATED_ON': '創建時間',
    /* TT_COM_02 */   'COMMON.CREATED_BY': '創建者',
    /* TT_COM_03 */   'COMMON.MODIFIED_ON': '修改者',
    /* TT_COM_04 */   'COMMON.MODIFIED_BY': '修改時間',
    /* TT_COM_05 */   'COMMON.ID': '編號',
    /* TT_COM_06 */   'COMMON.STEP.STEP_1': '第1步',
    /* TT_COM_07 */   'COMMON.STEP.STEP_2': '第2步',
    /* TT_COM_08 */   'COMMON.STEP.STEP_3': '第3步',
    /* TT_COM_09 */   'COMMON.STEP.STEP_4': '第4步',
    /* TT_COM_10 */   'COMMON.TIME': '時間'


};
export default messages;