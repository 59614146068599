// @flow

import React, {PureComponent} from "react";
import HeaderLogo from "./HeaderLogo";
import HeaderSearchFragment from "./HeaderSearchFragment";
import HeaderUserProfileFragment from "./HeaderUserProfileFragment";
import HeaderSidebarDisplayToggle from "./HeaderSidebarDisplayToggle";
import HeaderLanguageFragment from "./HeaderLanguageFragment";
import UserDao from "../../dao/UserDao";


type HeaderFragmentProps = {}

class HeaderFragment extends PureComponent<HeaderFragmentProps, any> {

    constructor(props: HeaderFragmentProps) {
        super(props);
        window.$('body').removeClass("m-aside-left--on");
    }

    componentDidMount() {
        window.mLayout.init();
    }

    render() {
        return <header id="m_header" className="m-grid__item    m-header make_code_header_background"
                       m-minimize-offset={200}
                       m-minimize-mobile-offset={200}>
            <div className="m-container m-container--fluid m-container--full-height">
                <div className="m-stack m-stack--ver m-stack--desktop">

                    <div className="m-stack__item m-brand  m-brand--skin-light  make_code_header_background">
                        <div className="m-stack m-stack--ver m-stack--general m-stack--fluid">
                            <HeaderLogo/>
                            <HeaderSidebarDisplayToggle/>
                        </div>
                    </div>

                    <div className="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                        <div id="m_header_topbar"
                             className="m-topbar  m-stack m-stack--ver m-stack--general">
                            <div className="m-stack__item m-topbar__nav-wrapper">
                                <ul className="m-topbar__nav m-nav m-nav--inline">
                                    {/*<HeaderSearchFragment/>*/}
                                    <HeaderLanguageFragment/>
                                    {
                                        UserDao.isLogin() && <HeaderUserProfileFragment/>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>;
    }
}

export default HeaderFragment;