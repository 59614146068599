// @flow

import React from 'react';
import Popup from "reactjs-popup";
import styles from './index.css';
import MaintenancePeriodCreateFragment from "../MaintenancePeriodCreateFragment";
import MaintenancePeriodUpdateFragment from "../MaintenancePeriodUpdateFragment";
import swal from "sweetalert2";
import {LLMsg} from "../../../../IntlCapture";
import Api from "../../../../bundle/Api";
import PubSub from "pubsub-js";
import type {MaintenancePeriod} from "../../../../model/model";
import LoadingUtil from "../../../../util/LoadingUtil";
import ErrorUtil from "../../../../util/ErrorUtil";
import TimeUtil from "../../../../util/TimeUtil";

type MaintenancePeriodListFragmentState = {
    maintenancePeriodList: Array<MaintenancePeriod>
}
export default class MaintenancePeriodListFragment extends React.Component <any, MaintenancePeriodListFragmentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            maintenancePeriodList: []
        };

        this.refreshList = this.refreshList.bind(this);
    }

    refreshList = () => {
        this.getMaintenancePeriodList();
    };

    getMaintenancePeriodList = () => {

        LoadingUtil.showFullScreenLoading();
        Api.services.maintenanceService.list().then((res) => {
            LoadingUtil.hideFullScreenLoading();
            if (res.status === 200) {
                this.setState({
                    maintenancePeriodList: res.data
                });
            }
        }).catch((err) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(err);
        });
    };

    componentDidMount() {

        this.getMaintenancePeriodList();
    }

    renderItem = (maintenancePeriod: MaintenancePeriod, order: number) => {
        return <div className={styles.item} key={maintenancePeriod.id}>
            <div className={styles.item_id}>{order}</div>
            <div className={styles.item_record_wrapper}>
                <div className={styles.item_record}>
                    <span className={styles.item_label}>Start Date: </span>
                    <span className={styles.item_value}>
                        {TimeUtil.convertTimeFormat(maintenancePeriod.startFrom, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                    </span>
                </div>
                <div className={styles.item_record}>
                    <span className={styles.item_label}>Finish Date: </span>
                    <span className={styles.item_value}>
                        {TimeUtil.convertTimeFormat(maintenancePeriod.endUntil, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                    </span>
                </div>
                <div className={styles.item_record}>
                    <span className={styles.item_label}>Prompt Since: </span>
                    <span className={styles.item_value}>
                        {TimeUtil.convertTimeFormat(maintenancePeriod.showSince, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                    </span>
                </div>
            </div>
            <div className={styles.item_action_wrapper}>
                <Popup
                    trigger={
                        <button
                            className={"btn btn-outline-primary btn-sm 	m-btn m-btn--icon m-btn--icon-only " + styles.item_action_button}>
                            <i className="fa fa-edit"/>
                        </button>
                    }
                    modal
                    closeOnDocumentClick
                    position="right center">
                    {close => (
                        <MaintenancePeriodUpdateFragment close={close} maintenancePeriod={maintenancePeriod}
                                                         onUpdatedCallback={this.refreshList}/>
                    )}
                </Popup>

                <button
                    className={"btn btn-outline-accent btn-sm 	m-btn m-btn--icon m-btn--icon-only " + styles.item_action_button}
                    onClick={() => {

                        swal({
                            text: LLMsg("COMMON.ACTION.DELETE"),
                            showCancelButton: !0,
                            confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
                            cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
                        }).then((e) => {
                            if (e.dismiss) {
                                return;
                            }
                            LoadingUtil.showFullScreenLoading();
                            Api.services.maintenanceService.delete(maintenancePeriod.id).then(response => {
                                LoadingUtil.hideFullScreenLoading();
                                this.refreshList();
                                if (response.status === 200) {
                                    swal(LLMsg("COMMON.ACTION.REMOVE_SUCCESS"), "", "success");
                                } else {
                                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                                }
                            }).catch((err) => {
                                LoadingUtil.hideFullScreenLoading();
                                ErrorUtil.promptError(err);
                            });
                        });
                    }}
                >
                    <i className="fa fa-times"/>
                </button>
            </div>
        </div>
    };

    render() {
        return <div className="m-portlet">
            <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                        <h3 className="m-portlet__head-text">
                            Maintenance Schedule
                        </h3>
                    </div>
                </div>

                <div className={styles.create_button}>

                    <Popup
                        trigger={
                            <button type="button" className={["btn btn-primary"].join(' ')}>Create</button>
                        }
                        modal
                        closeOnDocumentClick
                    >
                        {close => (
                            <MaintenancePeriodCreateFragment close={close} onCreatedCallback={this.refreshList}/>
                        )}
                    </Popup>

                </div>
            </div>
            <div className="m-portlet__body">
                <div className="m-section">
                    <div className="m-section__content">
                        {
                            this.state.maintenancePeriodList.length === 0 && <div>no record</div>
                        }
                        {
                            this.state.maintenancePeriodList.map((maintenancePeriod: MaintenancePeriod, index: number) => {
                                return this.renderItem(maintenancePeriod, index + 1);
                            })
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}