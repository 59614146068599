// @flow
import React, {Component} from 'react';
import SocketIOService from "../../../service/SocketIOService";
import _ from "lodash";
import moment from "moment-timezone";
import Constant from "../../../bundle/Constant";
import Api from "../../../bundle/Api";
import {withRouter} from "react-router";
import Page from "../../../bundle/Page";
import {Link} from "react-router-dom";
import type {ChartConfig, ChartSource, DataRecord, DataRecordType, Device, Topic} from "../../../model/model";
import styles from './index.css';
import {LLMsg} from "../../../IntlCapture";
import LoadingUtil from "../../../util/LoadingUtil";
import {ClassNames} from "../../../bundle/ClassNames";

const am4core = window.am4core;
const am4charts = window.am4charts;

type LineChartFragmentProps = {
    sources: Array<ChartSource>,
    title: string,
    chartId: string,
    config: ChartConfig,
    isDetailMode?: boolean,

    isLive: boolean,
    startTime: string,
    endTime: string,
    interval: string,
    aggregate: string,

    sizingClass?: string,

    match?: Object,
    location?: Object,
    history?: Object
}
type LineChartFragmentStates = {
    hasData: boolean,
    currentCursorBehavior: string,
    currentShowLatestCount: number
}

const CursorBehavior = {
    zoomXY: "zoomXY",
    zoomY: "zoomY",
    zoomX: "zoomX",
    panXY: "panXY",
    panY: "panY",
    panX: "panX",
    selectXY: "selectXY",
    selectY: "selectY",
    selectX: "selectX"
};

const ShowLatestCount = {
    latest10: 10,
    latest50: 50,
    latest100: 100,
    latest200: 200,
    latest500: 500,
    latest1000: 1000,
    unset: null,
    all: -1,
};

const defaultCursorBehavior = CursorBehavior.panXY;
const defaultShowLatestCount = ShowLatestCount.latest10;
const defaultSeriesTensionX = 1;
const defaultMinBulletDistance = 15;

class LineChartFragment extends Component<LineChartFragmentProps, LineChartFragmentStates> {
    socket: Object = {};
    lineChart: Object = {};
    sourceIdColorMap: {} = {};
    inputTimeFormat = "YYYY-MM-D HH:mm:ss";
    chartAcceptFormat = "YYYY-MM-DD HH:mm:ss Z";
    state: LineChartFragmentStates = {
        hasData: true,
        currentCursorBehavior: defaultCursorBehavior,
        currentShowLatestCount: defaultShowLatestCount,
    };

    chart;
    dateAxis;
    chartRef: any;
    chartSourceIdAndSeriesValueMap = {};

    constructor(props: LineChartFragmentProps) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidUpdate(prevProps: LineChartFragmentProps) {

        if (
            this.props.isLive !== prevProps.isLive ||
            this.props.startTime !== prevProps.startTime ||
            this.props.endTime !== prevProps.endTime ||
            this.props.interval !== prevProps.interval ||
            this.props.aggregate !== prevProps.aggregate
        ) {
            this.unsubscribeLiveData();
            this.fetchHistoryData();
        }

        if (this.props.isLive && !prevProps.isLive) {
            this.subscribeLiveData();
        }

    }

    componentWillUnmount() {
        this.unsubscribeLiveData();
        this.chart && this.chart.dispose();
    }

    unsubscribeLiveData() {

        let socket: any = SocketIOService.getSocket();
        this.props.sources.forEach(source => {
            socket.emit(Constant.socket.data.room.leave, "T" + source.topic.id);
        });
    }

    subscribeLiveData() {

        if (!this.props.sources || this.props.sources.length <= 0) {
            this.setState({hasData: false});
            return;
        }

        let socket: any = SocketIOService.getSocket();
        this.props.sources.forEach(source => {
            socket.emit(Constant.socket.data.room.join, "T" + source.topic.id);
        });
        socket.on(Constant.socket.data.room.newData, (data) => {
            let dataObj = SocketIOService.extractChartDataMessage(data);

            let source: ChartSource = {};
            this.props.sources.forEach((src: ChartSource) => {

                if (!((src.topic.id !== dataObj.topicId) ||
                    (src.device && src.device.id !== dataObj.deviceId) ||
                    (!_.get(dataObj, "records[" + src.dataRecordType.keyName + "]", undefined)))) {
                    source = src;
                }

            });
            if (!source.id) return;

            let seriesId = this.chartSourceIdAndSeriesValueMap[source.id];

            let isNewDataSet = this.chartSourceIdAndSeriesValueMap[source.id];
            if (!isNewDataSet) {

                seriesId = this.chart.series.length + 1;

                let series = this.chart.series.push(new am4charts.LineSeries());
                this.setupSeries(series, seriesId, this.sourceIdColorMap[source.id]);

                this.chartSourceIdAndSeriesValueMap[source.id] = "value" + seriesId;

                let bullet = series.bullets.push(new am4charts.CircleBullet());
                this.setupBullet(bullet, source);

            }

            let date = moment(dataObj.timeArrival, this.chartAcceptFormat).toDate();

            this.props.sources.forEach((src: ChartSource) => {

                let keyName = src.dataRecordType.keyName;
                let value = dataObj.records[keyName];
                let index = this.chart.data.length;
                let data = {};
                data[seriesId] = value;
                data["date"] = date;
                this.chart.addData(data);

            });

            this.zoomToLastNumberOfPoint(this.state.currentShowLatestCount, true);

        });


        this.fetchHistoryData();
    }

    removeAllChartSeriesAndData() {
        this.chart.data = [];
        this.chart.series.setAll([]);
        // this.chart.scrollbarX && this.chart.scrollbarX.invalidate();
        // this.chart.validateData();

        // while (this.chart.series.length !== 0) {
        //     this.chart.series.removeIndex(0).dispose();
        // }
    }

    setupSeries(series: any, seriesId: number, color: string) {

        series.bullets.push(new am4charts.Bullet());
        series.dataFields.valueY = "value" + seriesId;
        series.dataFields.dateX = "date";
        series.tensionX = defaultSeriesTensionX;

        series.minBulletDistance = defaultMinBulletDistance;
        series.stroke = am4core.color(color);
        series.name = "Series #" + seriesId;
        series.stacked = true;

        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
    }

    setupBullet(bullet: any, source: ChartSource) {
        bullet.tooltipText = "Value: [bold]{valueY}[/]" +
            "\n Date: [bold]{dateX}[/]" +
            "\n Topic: [bold]" + source.topic.topicName + "[/]" +
            (source.dataRecordType ? "\n Type: [bold]" + source.dataRecordType.keyName + "[/]" : "") +
            (source.device ? "\n Device: [bold]" + source.device.serialName + "[/]" : "")
        ;

        bullet.fill = am4core.color(this.sourceIdColorMap[source.id]);

        let bulletHover = bullet.states.create("hover");
        bulletHover.properties.scale = 1.3;
    }

    fetchHistoryData() {

        if (this.props.isLive === true || this.props.isLive === undefined) {
            // live

            LoadingUtil.showFullScreenLoading();

            this.removeAllChartSeriesAndData();

            this.props.sources.forEach((source) => {
                LoadingUtil.showFullScreenLoading();

                let promise;
                if (this.props.isDetailMode) {
                    promise = Api.services.record.doGetRecentDataRecord(source.topic.id, source.dataRecordType.id, source.device.id, 10);
                } else {
                    promise = Api.services.record.doGetRecentDataRecord(source.topic.id, source.dataRecordType.id, source.device.id, 10);
                }
                promise.then((response) => {
                    if (response.status === 200 && response.data && response.data.length > 0) {

                        let dataRecords: Array<DataRecord> = response.data;
                        dataRecords.reverse();

                        let seriesId = this.chart.series.length + 1;

                        let series = this.chart.series.push(new am4charts.LineSeries());
                        this.setupSeries(series, seriesId, this.sourceIdColorMap[source.id]);

                        this.chartSourceIdAndSeriesValueMap[source.id] = "value" + seriesId;

                        let bullet = series.bullets.push(new am4charts.CircleBullet());
                        this.setupBullet(bullet, source);

                        let dataList = [];
                        // Update data
                        dataRecords.forEach((dataRecord: DataRecord, index: number) => {
                            let data = {};
                            data["value" + seriesId] = dataRecord.value;
                            data["date"] = moment(dataRecord.createdOn, this.chartAcceptFormat).toDate();
                            dataList.push(data);
                        });

                        this.chart.addData(dataList);


                        if (this.props.isDetailMode) {

                            let scrollbarX = new am4charts.XYChartScrollbar();
                            scrollbarX.series.push(series);

                            this.chart.scrollbarX = scrollbarX;
                        }

                    }
                    LoadingUtil.hideFullScreenLoading();

                }).catch(() => {
                    LoadingUtil.hideFullScreenLoading();
                });
            });
        } else {

            // show history

            let startTime = this.props.startTime;
            let endTime = this.props.endTime;
            let interval = this.props.interval;
            let aggregate = this.props.aggregate;

            this.removeAllChartSeriesAndData();

            this.props.sources.forEach((source) => {
                Api.services.record.doGetAggregateHistoryDataRecord(source.topic.id, source.dataRecordType.id, source.device.id, startTime, endTime, interval, aggregate).then((response) => {
                    if (response.status === 200 && response.data && response.data.length > 0) {

                        let dataRecords: Array<DataRecord> = response.data;
                        // dataRecords.reverse();

                        let seriesId = this.chart.series.length + 1;

                        let series = this.chart.series.push(new am4charts.LineSeries());

                        this.setupSeries(series, seriesId, this.sourceIdColorMap[source.id]);

                        this.chartSourceIdAndSeriesValueMap[source.id] = "value" + seriesId;

                        let bullet = series.bullets.push(new am4charts.CircleBullet());
                        this.setupBullet(bullet, source);

                        let dataList = [];

                        // Update data
                        dataRecords.forEach((dataRecord: DataRecord, index: number) => {
                            let data = {};
                            data["value" + seriesId] = dataRecord.value;
                            data["date"] = moment(dataRecord.createdOn, this.chartAcceptFormat).toDate();
                            dataList.push(data);
                            console.log(data);
                        });

                        this.chart.addData(dataList);
                        this.setState({currentShowLatestCount: ShowLatestCount.all});
                        if (this.props.isDetailMode) {
                            this.chart.invalidateRawData();

                            console.log("setting new scroll bar x");
                            let scrollbarX = new am4charts.XYChartScrollbar();
                            scrollbarX.series.push(series);

                            this.chart.scrollbarX = scrollbarX;
                            this.chart.scrollbarX.invalidate();
                            this.chart.invalidateRawData();
                        }
                        this.zoomToShowAll(true);

                    }

                });
            });

        }

    }

    static getLabelNameByDataTypeName(topic: Topic, dataRecordType: DataRecordType, device: Device) {
        return topic.topicName + "/" + (dataRecordType ? dataRecordType.keyName : "*") + "/" + (device ? device.serialName : "*");
    }

    setupCursor() {
        this.chart.cursor = new am4charts.XYCursor();
        // chart.cursor.lineX.stroke = am4core.color("#8F3985");
        this.chart.cursor.lineX.strokeWidth = 4;
        this.chart.cursor.lineX.strokeOpacity = 0.2;
        this.chart.cursor.lineX.strokeDasharray = "";

        // chart.cursor.lineY.stroke = am4core.color("#8F3985");
        this.chart.cursor.lineY.strokeWidth = 4;
        this.chart.cursor.lineY.strokeOpacity = 0.2;
        this.chart.cursor.lineY.strokeDasharray = "";
        this.chart.cursor.behavior = defaultCursorBehavior;

    }

    componentDidMount() {

        // am4core.useTheme(window.am4themes_animated);
        this.chart = am4core.create(this.chartRef.current, am4charts.XYChart);
        this.chart.legend = new am4charts.Legend();
        this.setupCursor();
        this.chart.data = [];

        this.dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        this.dateAxis.keepSelection = true;
        this.dateAxis.dataFields.category = "category";
        this.dateAxis.extraMin = 0.1;
        this.dateAxis.extraMax = 0.1;
        this.dateAxis.baseInterval = {
            timeUnit: "second",
            count: 1
        };

        this.chart.yAxes.push(new am4charts.ValueAxis());
        // this.chart.zoomOutButton.dispatchImmediately("showAll");
        this.chart.zoomOutButton.events.on("hit", (ev) => {
            this.setState({currentShowLatestCount: ShowLatestCount.all});
        });

        let colorArray: Array<string> = (this.props.config.colorList || "").split(",");

        _.sortBy(this.props.sources, ['id']).map((source: ChartSource, index: number) => {
            this.sourceIdColorMap[source.id] = colorArray[index];
        });
        // this.chart.events.on("ready", () => {
        //     this.zoomToLastNumberOfPoint(0, false);
        // });
        this.subscribeLiveData();
    }

    setupGrouping() {
        this.dateAxis.groupData = true;
    }

    zoomToShowAll(isDelayed: boolean) {
        this.zoomToLastNumberOfPoint(this.chart.data.length, isDelayed);
    }

    zoomToLastNumberOfPoint(count: number, isDelayed: boolean) {

        let end = this.chart.data.length - 1;
        let start = end - count > 0 ? end - count : 0;
        if (this.chart.data[start] && this.chart.data[end]) {
            let startDate = this.chart.data[start].date;
            // let endDate = new Date();
            // let endDate = this.chart.data[end].date;
            let endDate = new Date(this.chart.data[end].date.getTime());
            if (this.props.interval === Constant.chart.interval.MONTH) {
                endDate.setMonth(endDate.getMonth() + 1);
            } else if (this.props.interval === Constant.chart.interval.DAY) {
                endDate.setDate(endDate.getDate() + 1);
            } else if (this.props.interval === Constant.chart.interval.HOUR) {
                endDate.setHours(endDate.getHours() + 1);
            } else if (this.props.interval === Constant.chart.interval.MINUTE) {
                endDate.setMinutes(endDate.getMinutes() + 1);
            } else {
                endDate.setSeconds(endDate.getSeconds() + 1);
            }
            setTimeout(() => {
                // this.dateAxis.zoomToIndexes(start, end);
                this.dateAxis.zoomToDates(startDate, endDate, true, true);
            }, isDelayed ? 1500 : 0);
        }

    }

    renderZoomToButton(count: number) {
        let activeClassname = "btn-accent";
        let inactiveClassname = "btn-secondary";
        return <div className="btn-group mt-3 mr-1" role="group">
            <button type="button"
                    className={"m-btn btn " + (this.state.currentShowLatestCount === count ? activeClassname : inactiveClassname)}
                    onClick={() => {
                        this.setState({currentShowLatestCount: count});
                        if (count === ShowLatestCount.all) {
                            this.zoomToShowAll(false);
                        } else {
                            this.zoomToLastNumberOfPoint(count, false);
                        }

                    }}>
                <span
                    className={"pr-2 pt-2"}>{count !== ShowLatestCount.all ? count + "" : LLMsg("COMMON.CHART.SHOW_LATEST_POINT_ALL")}</span>
            </button>
        </div>;
    }

    renderCursorBehaviorButton(cursorBehavior: string, displayText: string, iconClassname: string) {
        let activeClassname = "btn-accent";
        let inactiveClassname = "btn-secondary";
        return <div className="btn-group mr-1" role="group">
            <button type="button"
                    className={"m-btn btn " + (this.state.currentCursorBehavior === cursorBehavior ? activeClassname : inactiveClassname)}
                    onClick={() => {
                        this.setState({currentCursorBehavior: cursorBehavior});
                        this.chart.cursor.behavior = cursorBehavior;
                    }}>
                <span className={"pr-2 pt-2"}>{displayText}</span>
                <i className={iconClassname}/>
            </button>
        </div>
    }

    renderToolBar() {
        return <div className={styles.toolBar}>
            {this.renderCursorBehaviorButton(CursorBehavior.panXY, LLMsg("COMMON.CHART.CHART_TOOLBAR_MOVE"), "la la-hand-paper-o")}
            {this.renderCursorBehaviorButton(CursorBehavior.selectXY, LLMsg("COMMON.CHART.CHART_TOOLBAR_SELECT"), "la la-square-o")}
            {this.renderCursorBehaviorButton(CursorBehavior.zoomXY, LLMsg("COMMON.CHART.CHART_TOOLBAR_ZOOM_XY"), "la la-arrows-alt")}
            {this.renderCursorBehaviorButton(CursorBehavior.zoomX, LLMsg("COMMON.CHART.CHART_TOOLBAR_ZOOM_X"), "la la-arrows-h")}
            {this.renderCursorBehaviorButton(CursorBehavior.zoomY, LLMsg("COMMON.CHART.CHART_TOOLBAR_ZOOM_Y"), "la la-arrows-v")}
            <br/>
            <span className={styles.toolBarTitle}>{LLMsg("COMMON.CHART.SHOW_LATEST_POINT")}</span>
            {this.renderZoomToButton(ShowLatestCount.latest10)}
            {this.renderZoomToButton(ShowLatestCount.latest50)}
            {this.renderZoomToButton(ShowLatestCount.latest100)}
            {this.renderZoomToButton(ShowLatestCount.latest200)}
            {this.renderZoomToButton(ShowLatestCount.latest500)}
            {this.renderZoomToButton(ShowLatestCount.latest1000)}
            {/*{this.renderZoomToButton(ShowLatestCount.all)}*/}
            <br/>
        </div>;
    }

    render() {
        let sizingClassname = this.props.sizingClass || "portlet_height_1x";
        if (!this.state.hasData) {
            return <div className={sizingClassname}>
                <div>No Data</div>
            </div>;
        }
        return <div
            className={ClassNames(sizingClassname, this.props.isDetailMode ? styles.extraMarginButton : " pb-5")}>
            <Link to={Page.internals.urls.chart.getChartDetailPageUrl(this.props.chartId, this.props.title)}
                  className={"mb-2 m--icon-font-size-lg5 no-a-effect"}>{this.props.title}</Link>
            {
                this.props.isDetailMode && this.renderToolBar()
            }
            <div ref={this.chartRef} style={{width: "100%", height: "100%"}}/>
        </div>;
    }
}

export default withRouter(LineChartFragment);