// @flow

import React from 'react';
import type {ChartMessage, ChartSource, DataRecord, Topic} from "../../../model/model";
import TableScrollbar from "react-table-scrollbar";
import {ClassNames} from "../../../bundle/ClassNames";
import styles from "../MappedTopicListFragment.css";
import TimeUtil from "../../../util/TimeUtil";
import * as PubSub from "pubsub-js";
import Api from "../../../bundle/Api";
import {LLMsg} from "../../../IntlCapture";

type Props = {
    topic: Topic
}

type State = {
    socketData: Array<ChartMessage>
}
export default class TopicDataLoggerFragment extends React.Component<Props, any> {

    subToken: any;

    state = {
        socketData: []
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subToken);
    }

    componentDidMount() {
        this.subToken = PubSub.subscribe(Api.actions.topic.TOPIC_NEW_DATA_ACTION_TYPE, (actionName: string, data: ChartMessage) => {
            let socketData = this.state.socketData;
            socketData.push(data);
            this.setState({socketData: socketData});
        });
    }

    render() {
        let dataRows = [];

        this.state.socketData.forEach((data: ChartMessage) => {
            for (const [key, value] of Object.entries(data.records)) {
                dataRows.push(<tr key={dataRows.length}>
                    <td>
                        {TimeUtil.convertTimeFormat(data.timeArrival, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                    </td>
                    <td>
                        {key || ""}
                    </td>
                    <td>
                        {data.deviceSerialNumber}
                    </td>
                    <td>
                        {value}
                    </td>
                </tr>);
            }
        });

        return <TableScrollbar rows={9}>
            <table
                className={ClassNames("table table-striped m-table")}>
                <thead className={styles.tableHead}>
                <tr>
                    <th>{LLMsg("COMMON.TIME")}</th>
                    <th>{LLMsg("COMMON.DATA_TYPE.DATA_TYPE")}</th>
                    <th>{LLMsg("COMMON.DEVICE.DEVICE")}</th>
                    <th>{LLMsg("EVENT_TRIGGER.MESSAGE")}</th>
                </tr>
                </thead>
                <tbody>
                {dataRows}
                {
                    this.props.topic.recentDataRecords.map((dataRecord: DataRecord, index: number) => {
                        return <tr key={index}>
                            <td>
                                {TimeUtil.convertTimeFormat(dataRecord.createdOn, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                            </td>
                            <td>
                                {dataRecord.dataRecordType ? dataRecord.dataRecordType.keyName : "-"}
                            </td>
                            <td>
                                {dataRecord.device ? dataRecord.device.serialNumber : "-"}
                            </td>
                            <td>
                                {dataRecord.value}
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </TableScrollbar>

    }
}