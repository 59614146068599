// @flow

import React from 'react';
import styles from './index.css';
import TimeUtil from "../../../../util/TimeUtil";
import moment from "moment";
import ErrorUtil from "../../../../util/ErrorUtil";
import LoadingUtil from "../../../../util/LoadingUtil";
import swal from "sweetalert2";
import {LLMsg} from "../../../../IntlCapture";
import Api from "../../../../bundle/Api";

type MaintenancePeriodCreateFragmentProps = {
    close: Function,
    onCreatedCallback: Function
}

export default class MaintenancePeriodCreateFragment extends React.Component<MaintenancePeriodCreateFragmentProps, any> {

    startDate: string;
    endDate: string;
    showSinceDate: string;

    onCreate = () => {

        let startDate = TimeUtil.convertTimeFormat(this.startDate, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        let endDate = TimeUtil.convertTimeFormat(this.endDate, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);
        let showSinceDate = TimeUtil.convertTimeFormat(this.showSinceDate, TimeUtil.FULL_DATE_WITH_TIMEZONE_FORMAT);

        LoadingUtil.showFullScreenLoading();
        Api.services.maintenanceService.create(startDate, endDate, showSinceDate).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            this.props.close();
            if (response.status === 200) {
                swal(LLMsg("COMMON.ACTION.CREATE_SUCCESS"), "", "success");
            }
            this.props.onCreatedCallback();
        }).catch((error) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(error);
            this.props.close();
        });
    };


    render() {

        this.startDate = TimeUtil.convertTimeFormat(moment().hour(18).minute(0).second(0), TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        this.endDate = TimeUtil.convertTimeFormat(moment().hour(20).minute(0).second(0), TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        this.showSinceDate = TimeUtil.convertTimeFormat(moment().hour(0).minute(0).second(0), TimeUtil.FULL_DATE_DISPLAY_FORMAT);
        return <div className={styles.wrapper}>
            <div className={styles.title}>Create Maintenance Period</div>
            <hr/>

            <div className={styles.content}>
                <div>
                    <div className={styles.label}>
                        Start Date
                    </div>
                    <div className={styles.value}>
                        <input type="email" className="form-control m-input"
                               defaultValue={this.startDate}
                               onChange={(event) => {
                                   this.startDate = event.target.value;
                               }}
                        />
                    </div>
                </div>
                <div className={styles.timeDifferentArrow}><i className="fa fa-arrow-alt-circle-down fa-2x"/></div>
                <div>
                    <div className={styles.label}>
                        End Date
                    </div>
                    <div className={styles.value}>
                        <input type="email" className="form-control m-input"
                               defaultValue={this.endDate}
                               onChange={(event) => {
                                   this.endDate = event.target.value;
                               }}
                        />
                    </div>
                </div>
                <hr/>
                <div>
                    <div className={styles.label}>
                        Show Message Since
                    </div>
                    <div className={styles.value}>
                        <input type="email" className="form-control m-input"
                               defaultValue={this.showSinceDate}
                               onChange={(event) => {
                                   this.showSinceDate = event.target.value;
                               }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.footer_button}>
                <button type="button" className="btn btn-secondary ml-1" onClick={() => {
                    this.props.close();
                }}>
                    Cancel
                </button>
                <button type="button" className="btn btn-primary ml-1" onClick={() => {
                    this.onCreate();
                }}>
                    Create
                </button>
            </div>
        </div>;
    }
}