// @flow

import React, {Component} from 'react';
import styles from './LicenseListTable.css';

type LicenseListTableRowDetailWrapperProps = {
    children: any
}

class LicenseListTableRowDetailWrapper extends Component<LicenseListTableRowDetailWrapperProps, any> {

    render() {
        return <td className={styles.itemWrapper}>
            {this.props.children}
        </td>
    }
}

export default LicenseListTableRowDetailWrapper;