// @flow

import React, {Component} from 'react';
import type {WhitelistEmail} from "../../../../model/model";
import styles from './index.css';
import Api from "../../../../bundle/Api";
import ErrorUtil from "../../../../util/ErrorUtil";
import LoadingUtil from "../../../../util/LoadingUtil";

type AdminWhiteListUserListFragmentState = {
    whitelistUserList: Array<WhitelistEmail>,
    currentEditId: ? number
}

class AdminWhiteListEmailListFragment extends Component<any, AdminWhiteListUserListFragmentState> {

    constructor(props: any) {
        super(props);
        this.state = {
            whitelistUserList: [],
            currentEditId: undefined
        };
    }

    getEmails = () => {

        LoadingUtil.showFullScreenLoading();
        Api.services.user.doGetListOfWhiteListEmail().then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.setState({whitelistUserList: response.data, currentEditId: undefined})
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })
    };

    componentDidMount(props: any) {
        this.getEmails();
    }

    currentEditEmail: string;
    currentEditRemarks: string;
    createEmail: string;
    createRemarks: string;

    createWhitelistEmail = () => {
        LoadingUtil.showFullScreenLoading();
        Api.services.user.doCreateWhitelistEmail(this.createEmail, this.createRemarks).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.getEmails();
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })
    };

    updateWhitelistEmail = () => {
        LoadingUtil.showFullScreenLoading();
        Api.services.user.doUpdateWhitelistEmail(this.state.currentEditId || 0, this.currentEditEmail, this.currentEditRemarks).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.getEmails();
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })
    };

    deleteEmail = (whitelistEmailId: string) => {
        LoadingUtil.showFullScreenLoading();
        Api.services.user.doDeleteWhitelistEmail(whitelistEmailId).then((response) => {
            LoadingUtil.hideFullScreenLoading();
            if (response.status === 200) {
                this.getEmails();
            }
        }).catch((e) => {
            LoadingUtil.hideFullScreenLoading();
            ErrorUtil.promptError(e);
        })
    };

    render() {
        return <div className="m-portlet">
            <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                        <h3 className="m-portlet__head-text">
                            User List
                        </h3>
                    </div>
                </div>
            </div>
            <div className="m-portlet__body">
                <div className="m-section">
                    <div className="m-section__content">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Whitelist Email</th>
                                    <th>Remarks</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.whitelistUserList.map((email: WhitelistEmail, index: number) => {

                                        if (this.state.currentEditId === email.id) {
                                            this.currentEditEmail = email.whitelistEmail;
                                            this.currentEditRemarks = email.remarks;
                                        }

                                        return <tr key={email.id}
                                                   className={styles.userRow}>
                                            <th scope="row">{index + 1}</th>
                                            {this.state.currentEditId !== email.id && <td>{email.whitelistEmail}</td>}
                                            {
                                                this.state.currentEditId === email.id && <td>
                                                    <input defaultValue={email.whitelistEmail}
                                                           className={styles.emailEditInput} onChange={(e) => {
                                                        this.currentEditEmail = e.target.value;
                                                    }}/>
                                                </td>
                                            }
                                            {this.state.currentEditId !== email.id && <td>{email.remarks}</td>}
                                            {
                                                this.state.currentEditId === email.id && <td>
                                                    <input defaultValue={email.remarks}
                                                           className={styles.remarksEditInput} onChange={(e) => {
                                                        this.currentEditRemarks = e.target.value;
                                                    }}/>
                                                </td>
                                            }
                                            <td>
                                                {
                                                    this.state.currentEditId !== email.id && <button type="button"
                                                                                                     className="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only mr-2"
                                                                                                     onClick={() => {
                                                                                                         this.setState({currentEditId: email.id})
                                                                                                     }}>
                                                        <i className="fa fa-pen"/>
                                                    </button>
                                                }
                                                {
                                                    this.state.currentEditId !== email.id && <button type="button"
                                                                                                     className="btn btn-outline-metal m-btn m-btn--icon m-btn--icon-only"
                                                                                                     onClick={() => {
                                                                                                         this.deleteEmail(email.id + "");
                                                                                                     }}>
                                                        <i className="fa fa-times"/>
                                                    </button>
                                                }
                                                {
                                                    this.state.currentEditId === email.id && <button type="button"
                                                                                                     className="btn btn-outline-success m-btn mr-2"
                                                                                                     onClick={() => {
                                                                                                         this.updateWhitelistEmail();
                                                                                                     }}>
                                                        OK
                                                    </button>
                                                }
                                                {
                                                    this.state.currentEditId === email.id && <button type="button"
                                                                                                     className="btn btn-outline-metal m-btn"
                                                                                                     onClick={() => {
                                                                                                         this.setState({currentEditId: undefined})
                                                                                                     }}>
                                                        Cancel
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    })
                                }

                                <tr className={styles.userRow}>
                                    <th scope="row"></th>
                                    <td>
                                        <input
                                            className={styles.emailEditInput} onChange={(e) => {
                                            this.createEmail = e.target.value;
                                        }}/>
                                    </td>
                                    <td>
                                        <input
                                            className={styles.emailEditInput} onChange={(e) => {
                                            this.createRemarks = e.target.value;
                                        }}/>
                                    </td>
                                    <td>
                                        <button type="button"
                                                className="btn btn-outline-success m-btn"
                                                onClick={() => {
                                                    this.createWhitelistEmail();
                                                }}>
                                            Create
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            ;
    }
}

export default AdminWhiteListEmailListFragment;
