// @flow

import React from 'react';
import Page from "../../../bundle/Page";
import styles from "../DataTriggerFragment/index.css";
import {Link} from "react-router-dom";

type AppletDetailLinkProps = {

    active: boolean,
    projectId: string,
    appletId: string,
    appletName: string,
    enabled: boolean
}

class AppletDetailLink extends React.Component<AppletDetailLinkProps, any> {

    render() {
        return <div>
            <Link
                to={Page.internals.urls.project.eventTriggerDetail(this.props.projectId, this.props.appletId)}
                className={
                    styles.appletListItem + " no-a-effect "
                    + (this.props.active ? styles.appletListActiveItem : "")
                }>
                <span className={
                    styles.appletEnabledDot + " " +
                    (this.props.enabled ? styles.appletEnabledDotEnable : styles.appletEnabledDotDisable)
                }/>
                {this.props.appletName}
            </Link>
        </div>
    }
}


export default AppletDetailLink;