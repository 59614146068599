exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2y0TNFwQsBkuUQQeBbIQTn {\n    color: #716aca;\n    margin-left: 10px;\n}\n\n._3GgBtIr817noI2m6rQnbaB {\n    cursor: pointer;\n    left: 0;\n}\n\n._3GgBtIr817noI2m6rQnbaB:hover {\n    background-color: whitesmoke;\n}", ""]);

// exports
exports.locals = {
	"approvalStatusMark": "_2y0TNFwQsBkuUQQeBbIQTn",
	"row": "_3GgBtIr817noI2m6rQnbaB"
};