// @flow

import React, {Component} from 'react';
import HeaderFragment from "../header/HeaderFragment";
import BodyWrapper from "../body/BodyWrapper";
import SidebarWrapper from "../sidebar/SidebarWrapper";
import GeneralSidebarFragment from "../sidebar/GeneralSidebarFragment";
import ContentWrapper from "../content/ContentWrapper";
import ContentHeaderFragment from "../content/ContentHeaderFragment";
import FooterFragment from "../footer/FooterFragment";
import BackToTopButton from "../BackToTopButton";
import StickyQuickNav from "../SickyQuickNav";
import ContentInnerWrapper from "../content/ContentInnerWrapper";
import Breadcrumb from "../content/Breadcrumb";
import DashboardWrapperFragment from "../dashboard/DashboardWrapperFragment";
import {LLMsg} from "../../IntlCapture";
import PlatformUpdateLogFragment from "../platformUpdateLog/PlatformUpdateLogFragment";
import MaintenancePeriodPrompt from "../maintenance/MaintenancePeriodPrompt";
import Page from "../../bundle/Page";

class PlatformUpdateLogPage extends Component<any, any> {
    render() {

        Page.updateTitle("PAGE.TITLE.WHATS_NEW");

        return <div className="m-grid m-grid--hor m-grid--root m-page">
            <HeaderFragment/>
            <BodyWrapper>
                <SidebarWrapper>
                    <GeneralSidebarFragment/>
                </SidebarWrapper>
                <ContentWrapper>
                    <ContentHeaderFragment>
                        <Breadcrumb items={[{linkTo: "", displayText: LLMsg("PAGE.TITLE.WHATS_NEW")}]}/>
                    </ContentHeaderFragment>
                    <ContentInnerWrapper>
                        <MaintenancePeriodPrompt/>
                        <PlatformUpdateLogFragment/>
                    </ContentInnerWrapper>
                </ContentWrapper>
            </BodyWrapper>

            <FooterFragment/>
            <BackToTopButton/>
            <StickyQuickNav/>
        </div>
    }
}

export default PlatformUpdateLogPage;