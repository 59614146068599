exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CZAzSGhMZuXR9XijVRD2o {\n    border-top: 1px solid #eee;\n    left: 0;\n    cursor: pointer;\n}\n\n.CZAzSGhMZuXR9XijVRD2o:hover {\n    background-color: #eee;\n}\n\n._18FWhAFZu_z2HQvAAdbCgu {\n    color: #716aca;\n    margin-left: 10px;\n}", ""]);

// exports
exports.locals = {
	"row": "CZAzSGhMZuXR9XijVRD2o",
	"approvalStatusMark": "_18FWhAFZu_z2HQvAAdbCgu"
};