exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1pHSzgvN4hjpOgw6jKQEWs {\n}\n\n._2QsAUyIUjN-DPmMMF_6w_O {\n    margin-top: 20px;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n._1E_1eJtG_pmE5JTTcmLfXr {\n    text-align: center;\n    cursor: pointer;\n    margin-bottom: 20px;\n}\n\n._3r7i_t3vT92i-K3XJ9XxjZ {\n\n    font-weight: bolder;\n    display: block;\n    font-size: 1.5em;\n    margin-top: 10px;\n}\n\n._26VM9WYdwJ8jgj0MYJVcu9 {\n    width: 60px;\n    margin-left: 10px;\n}\n\n._3uHg0x1GKA5uf9gI5oYgn0 {\n    height: 300px;\n}", ""]);

// exports
exports.locals = {
	"actionButton": "_1pHSzgvN4hjpOgw6jKQEWs",
	"actionDiv": "_2QsAUyIUjN-DPmMMF_6w_O",
	"button": "_1E_1eJtG_pmE5JTTcmLfXr",
	"buttonText": "_3r7i_t3vT92i-K3XJ9XxjZ",
	"buttonIcon": "_26VM9WYdwJ8jgj0MYJVcu9",
	"wrapper": "_3uHg0x1GKA5uf9gI5oYgn0"
};