// @flow

export default class ToastrUtil {

    static success = window.toastr.success;
    static error = window.toastr.error;

    static show(message: string, config: any, type: any) {
        window.toastr.options = config;
        type(message);
    }

}
