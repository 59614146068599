// @flow

import DataStore from '../store/DataStore';
import Constant from "../bundle/Constant";
import Image from "../bundle/Image";
import _ from "lodash";
import Api from "../bundle/Api";
import PubSub from "pubsub-js";
import SocketIOService from "../service/SocketIOService";
import type {Permission} from "../model/model";

class UserDao {
    static isLogin: () => boolean = () => {
        return !!DataStore.cookies.get(Constant.cookies.user.accessToken);
    };
    static id: String;
    static username: String;
    static firstName: String;
    static lastName: String;
    static email: String;
    static pictureUrl: string;
    static accountNonExpired: boolean;
    static accountNonLocked: boolean;
    static credentialsNonExpired: boolean;
    static enabled: boolean;
    static schoolName: string;
    static schoolEmailDomain: string;
    static permission: Permission;
    static role: Array<string> = [];
    static updateUser = (response: Object) => {

        if (response.status === 200) {
            UserDao.id = response.data.id;
            UserDao.pictureUrl = Image.urls.getUserProfileImage(123);
            UserDao.firstName = response.data.firstName;
            UserDao.lastName = response.data.lastName;
            UserDao.email = response.data.email;
            UserDao.enabled = response.data.enabled;
            UserDao.credentialsNonExpired = response.data.credentialsNonExpired;
            UserDao.accountNonExpired = response.data.accountNonExpired;
            UserDao.accountNonLocked = response.data.accountNonLocked;
            UserDao.schoolEmailDomain = _.get(response, "data.school.emailDomain", "");
            UserDao.schoolName = _.get(response, "data.school.schoolName", "");
            UserDao.role = response.data.authorities.map((userAuthority) => userAuthority.authority);
            UserDao.permission = response.data.subscriptionStatus.permission;

        }
    };

    static fetchMe = (): any => {
        if (UserDao.isLogin()) {
            Api.services.user.getMe().then((response) => {
                if (response.status === 200) {
                    UserDao.updateUser(response);
                    PubSub.publish(Api.actions.user.GET_MYSELF_DONE_ACTION_TYPE, '');

                    let socket: any = SocketIOService.getSocket();
                    socket.emit(Constant.socket.data.room.userConnected, UserDao.email);

                }
            });
        }
    }
}

export default UserDao;