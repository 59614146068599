// @flow

import React from 'react';
import styles from "./FilterFragment.css";
import {
    COMPARATOR,
    FIELD_NAME_COMPARATOR_KEY,
    FIELD_NAME_PUBLISH_CONTENT,
    FIELD_NAME_TOPIC_NAME
} from "./FilterFragment";
import StringUtil from "../../../util/StringUtil";
import {DataRecordType} from "../../../model/model";
import {LLMsg} from "../../../IntlCapture";
import EventTriggerItemFragment from "../DataTriggerFragment/EventTriggerItemFragment";
import Constant from "../../../bundle/Constant";
import {ClassNames} from "../../../bundle/ClassNames";

type TopicSimpleMessageConditionProps = {
    conditionKey: number,
    topicName: string,
    comparatorKey: string,
    publishContent: string,
    topics: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }>,
    onDeleteCallback: (conditionKey: number) => void,
    updateInputField: (conditionKey: number, fieldName: string, fieldValue: string) => void
}
type TopicSimpleMessageConditionState = {
    showTopicNameError: boolean,
    showComparatorKeyError: boolean,
    showPublishContentError: boolean
}

class TopicSimpleMessageCondition extends React.Component<TopicSimpleMessageConditionProps, TopicSimpleMessageConditionState> {

    constructor(props: TopicSimpleMessageConditionProps) {
        super(props);
        this.state = {
            showTopicNameError: false,
            showComparatorKeyError: false,
            showPublishContentError: false
        };
    }

    validateCondition = (): void => {

        let showTopicNameError = false;
        let showComparatorKeyError = false;
        let showPublishContentError = false;
        if (StringUtil.isOnlySpaceOrEmpty(this.props.topicName)) {
            showTopicNameError = true;
        }
        if (StringUtil.isOnlySpaceOrEmpty(this.props.comparatorKey)) {
            showComparatorKeyError = true;
        }
        if (StringUtil.isOnlySpaceOrEmpty(this.props.publishContent)) {
            showPublishContentError = true;
        }


        this.setState({
            showTopicNameError, showComparatorKeyError, showPublishContentError
        });

    };

    onTopicNameChanged = (e: any) => {
        let topicName = e.target.value;

        this.setState({
            showTopicNameError: false
        });
        this.props.updateInputField(this.props.conditionKey, FIELD_NAME_TOPIC_NAME, topicName);
    };

    onComparatorChanged = (e: any) => {
        let comparatorKey = e.target.value;
        console.log("comparatorKey=", e, e.target, e.target.value);

        this.setState({
            showComparatorKeyError: false
        });
        this.props.updateInputField(this.props.conditionKey, FIELD_NAME_COMPARATOR_KEY, comparatorKey);
    };

    onPublishContentChanged = (e: any) => {
        let content = e.target.value;

        this.setState({
            showPublishContentError: false
        });
        this.props.updateInputField(this.props.conditionKey, FIELD_NAME_PUBLISH_CONTENT, content);
    };

    render() {
        return <div className={styles.condition}>
            <EventTriggerItemFragment
                onDelete={() => {
                    this.props.onDeleteCallback(this.props.conditionKey);
                }}
                allowDelete={true}
                showError={false}
                backgroundColor={Constant.theme.eventTrigger.filter.color}
                titleContent={
                    <span>
                                {LLMsg("EVENT_TRIGGER.IF")}
                        {LLMsg("EVENT_TRIGGER.TOPIC")}
                        <select
                            onChange={this.onTopicNameChanged}
                            className={ClassNames("form-control form-inline m-input m-input--air",
                                styles.select,
                                (this.state.showTopicNameError ? " " + styles.errorInput : ""))}
                            value={this.props.topicName}
                        >
                                    <option value={""}>
                                        {LLMsg("EVENT_TRIGGER.TOPIC_NAME")}
                                    </option>

                            {
                                this.props.topics.map((topicDropdownValue, index) => {
                                    return <option key={index}
                                                   value={topicDropdownValue.topicName}>
                                        {topicDropdownValue.topicName + " (" + topicDropdownValue.description + ")"}
                                    </option>;

                                })
                            }
                                </select>
                        {LLMsg("EVENT_TRIGGER.S_MESSAGE")}
                        <select
                            className={ClassNames("form-control m-input m-input--air",
                                styles.select,
                                (this.state.showComparatorKeyError ? " " + styles.errorInput : ""))}
                            value={this.props.comparatorKey}
                            onChange={this.onComparatorChanged}>

                                    <option value={""}>
                                        {LLMsg("EVENT_TRIGGER.COMPARATOR")}
                                    </option>
                            {
                                Object.entries(COMPARATOR()).map((entry: any, index) => {
                                    return <option key={index}
                                                   value={entry[1].key}>
                                        {entry[1].label}
                                    </option>
                                })
                            }
                                </select>
                                <input type="text"
                                       className={ClassNames("form-control form-inline m-input m-input--air",
                                           styles.select,
                                           (this.state.showPublishContentError ? " " + styles.errorInput : ""))}
                                       value={this.props.publishContent}
                                       onChange={this.onPublishContentChanged}
                                       placeholder={LLMsg("EVENT_TRIGGER.OBJECT_KEY")}
                                />
                        </span>
                }
            />


        </div>
    }
}

export default TopicSimpleMessageCondition;