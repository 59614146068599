exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2WaQgXgmQXb6EQOPGA19JK {\n    width: 240px;\n    font-weight: bold;\n    display: inline-block;\n    padding: 3px 10px;\n}\n\n._2Dsyu6nixnvEgq4EoyXPL {\n    display: inline-block;\n}\n\n._3C5kc5XQWUu39I00NzNeLG {\n    margin-bottom: 3px;\n}\n\n._1pt5X0n2MFjA7Bps7tXdvd {\n    width: 50px;\n    display: inline-block;\n    border: 1px solid #d5d5d5;\n    padding: 5px 10px;\n}\n\n._2pWqEfmZftwy7Mj1oDlEf8 {\n    width: 150px;\n    display: inline-block;\n    border: 1px solid #d5d5d5;\n    padding: 5px 10px;\n}\n\n.idQr60V1C-5TxoMQ45YnN {\n    width: 150px;\n    display: inline-block;\n    border: 1px solid #d5d5d5;\n    padding: 5px 10px;\n}", ""]);

// exports
exports.locals = {
	"title": "_2WaQgXgmQXb6EQOPGA19JK",
	"content": "_2Dsyu6nixnvEgq4EoyXPL",
	"row": "_3C5kc5XQWUu39I00NzNeLG",
	"licenseIndex": "_1pt5X0n2MFjA7Bps7tXdvd",
	"licenseKey": "_2pWqEfmZftwy7Mj1oDlEf8",
	"licenseDate": "idQr60V1C-5TxoMQ45YnN"
};