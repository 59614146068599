// @flow

import React from "react";

type ProjectListCreateProjectButtonProps = {
    callback: Function
}

const ProjectListRefreshtButton = (props: ProjectListCreateProjectButtonProps) => {

    return <li className="m-portlet__nav-item">
        <button type="button" className="btn btn-secondary m-btn--wide" onClick={(e) => {
            props.callback()
        }}>
            <i className="flaticon-refresh"/>
        </button>
    </li>;
};

export default ProjectListRefreshtButton;