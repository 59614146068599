// @flow

import axios from "axios";
import DataStore from "../store/DataStore";
import Constant from "../bundle/Constant";
import Api from "../bundle/Api";

class HttpUtil {

    static methods = {
        get: "GET",
        post: "POST",
        put: "PUT",
        delete: "DELETE"
    };

    static setResponseInterceptor(responseErrorCallback: any) {
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response && error.response.status === 401) {
                responseErrorCallback();
            }
            return Promise.reject(error);
        });
    }

    static async ajax(url: string, method: string, headers: Object, body: ?Object, responseType?: string) {

        let needToRefreshToken = !!DataStore.cookies.get(Constant.cookies.user.refreshToken);
        try {
            needToRefreshToken = needToRefreshToken && (Date.now() > parseInt(DataStore.cookies.get(Constant.cookies.user.expiresIn)));
        } catch (e) {
            needToRefreshToken = false;
        }
        if (needToRefreshToken) {
            await Api.services.user.refreshToken();
        }

        // Send a POST request
        headers = {
            ...headers,
            "Authorization": "Bearer " + DataStore.cookies.get(Constant.cookies.user.accessToken)
        };
        // console.log("Sending ajax, url=", url, ", method=", method, ", headers=", headers, ", body=", body);
        return axios({
            method: method,
            url: url,
            headers: headers,
            data: body,
            responseType: responseType || "json",
            crossDomain: 'true'
        });
    }

}

export default HttpUtil;