// @flow

import React, {useRef} from 'react';
import styles from './index.css';
import IframeResizer from 'iframe-resizer-react'

export default class ImageClassificationFragment extends React.Component<any, any> {

    render() {
        return <div className={styles.box}>
            <IframeResizer
                src="https://teachablemachine.withgoogle.com/train/image"
                width="100%"
                allow="camera;microphone"
                height={900}
                scrolling="yes"
                className={styles.iframe}
            />
        </div>
    }
}