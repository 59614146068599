// @flow

import React, {Component} from 'react';
import Api from "../../../bundle/Api";
import type {UpdateLog} from "../../../model/model";


type State = {
    updateLogs: Array<UpdateLog>
}

class PlatformUpdateLogFragment extends Component<any, State> {

    state = {
        updateLogs: []
    };

    componentDidMount(): * {

        Api.services.backendStatusService.getUpdateLogs().then((response) => {
            if (response.status === 200) {
                console.log(response.data);
                this.setState({updateLogs: response.data})
            }
        })
    }

    renderItem(log: UpdateLog) {

        let messages: Array<string> = log.message.split("\\n");
        return <div className={"mb-4"}>
            <h3 className={"mb-3"}>Version: {log.version}</h3>
            <ul>
                {
                    messages.map((message: string) => {
                        return <li>{message}</li>;
                    })
                }
            </ul>
        </div>
    }

    render() {
        return <div className="m-portlet m-portlet--mobile">
            <div className="m-portlet__body">
                {
                    this.state.updateLogs.map((updateLog: UpdateLog) => {
                        return this.renderItem(updateLog);
                    })
                }
            </div>
        </div>;
    }
}

export default PlatformUpdateLogFragment;