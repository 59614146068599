exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._16BodTxUPuAEhvUaV6Girk {\n    font-size: 80px;\n    padding-bottom: 20px;\n}", ""]);

// exports
exports.locals = {
	"value": "_16BodTxUPuAEhvUaV6Girk"
};