// @flow

import React, {Component} from "react";
import {Link} from "react-router-dom";
import type {ToObject} from "../../url/page/PageUrl";

type SidebarDropdownItemProps = {
    isActive: boolean,
    linkTo: string | ToObject,
    displayText: string
}

class SidebarDropdownItem extends Component<SidebarDropdownItemProps, any> {
    render() {

        const {linkTo, displayText, isActive} = this.props;

        return <li className={"m-menu__item " + (isActive ? " m-menu__item--active" : "")} aria-haspopup="true"
                   m-menu-link-redirect="1">
            <Link to={linkTo} title={displayText} className="m-menu__link ">
                <i className={"m-menu__link-bullet m-menu__link-bullet--dot "}><span/></i>
                <span className="m-menu__link-text">{displayText}</span>
            </Link>
        </li>
    }
}

export default SidebarDropdownItem;