// @flow

import React, {Component} from 'react';
import styles from './index.css';
import type {LicenseTarget} from "../../../../model/model";
import TimeUtil from "../../../../util/TimeUtil";
import {RouterHistory} from "../../../../AppRoute";
import Page from "../../../../bundle/Page";

type LicenseTargetTableContentItemProps = {
    licenseTarget: LicenseTarget
}

class LicenseTargetTableContentItem extends Component <LicenseTargetTableContentItemProps, any> {
    render() {

        const licenseTarget: LicenseTarget = this.props.licenseTarget;
        return <tr data-row={0} className={"m-datatable__row " + styles.row} onClick={()=>{
            RouterHistory().push(Page.internals.urls.license.licenseTargetDetailPage(licenseTarget.id));
        }}>
            <td className="m-datatable__cell">
                <span style={{width: '50px'}} className={"pl-3"}>
                    {licenseTarget.id}
                </span>
            </td>
            <td className="m-datatable__cell">
                <span style={{width: '150px'}}>
                    {licenseTarget.targetName}
                </span>
            </td>
            <td className="m-datatable__cell">
                <span style={{width: '150px'}}>
                    {licenseTarget.companyName}
                </span>
            </td>
            <td className="m-datatable__cell">
                <span style={{width: '150px'}}>
                    {TimeUtil.convertTimeFormat(licenseTarget.createdOn, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}
                </span>
            </td>
        </tr>;

    }
}

export default LicenseTargetTableContentItem;