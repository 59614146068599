exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2n70PF5By39Uvjazzs35IT {\n    display: flex;\n}\n\n._2dTACeGdnPND7gUGfKoGD- {\n    position: relative;\n}\n\n._9vJP0U2OymBQ1uC5j05F {\n    position: absolute;\n    left: 0;\n}\n\n._86pVTZS3mQuzIXTgyxavq {\n    width: 100%;\n    padding: 25px;\n    overflow-wrap: break-word;\n}\n\n._22KrV4rhXnq77D07ODj3MD {\n    font-size: 25px;\n    font-weight: bolder;\n    min-height: 150px;\n    text-wrap: normal;\n}\n\n._2yaZoLgyA5TeHeOepfdAxN {\n\n}\n\n._2WQNjJo__yZzimc6bbtP47 {\n    display: inline-block;\n    padding: 10px 20px;\n    background-color: #0F9E5E;\n    margin-right: 10px;\n    border-radius: 15px;\n    margin-bottom: 10px;\n    color: white;\n}\n\n._2g7UiHMiluPTH5RztBfEwy {\n    padding: 5px 15px;\n    cursor: pointer;\n    display: inline-block;\n    color: grey;\n}\n\n._2g7UiHMiluPTH5RztBfEwy i {\n    font-size: 25px;\n}\n\n._3iMq7DHxdVHCgYtmym0_QD {\n    font-size: 20px;\n    margin-bottom: 10px;\n    margin-left: 5px;\n}\n\n.zWKecJVX6TStjQ63NjWLf {\n    border: 1px solid grey;\n    border-radius: 15px;\n    padding: 15px;\n    margin-bottom: 15px;\n}\n\n._1qeBgNjm9tcXdTh9oKudmq {\n    display: inline-block;\n    padding: 10px 20px;\n    background-color: lightgrey;\n    margin-right: 10px;\n    border-radius: 15px;\n    margin-bottom: 10px;\n    color: darkslategrey;\n    font-size: 20px;\n    cursor: pointer;\n}\n\n._1qeBgNjm9tcXdTh9oKudmq._3I6CNnEagI5iphOanCTQeg {\n    border: 2px solid darkgray;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_2n70PF5By39Uvjazzs35IT",
	"box": "_2dTACeGdnPND7gUGfKoGD-",
	"canvas": "_9vJP0U2OymBQ1uC5j05F",
	"result": "_86pVTZS3mQuzIXTgyxavq",
	"resultClass": "_22KrV4rhXnq77D07ODj3MD",
	"resultScore": "_2yaZoLgyA5TeHeOepfdAxN",
	"detectedObjectItem": "_2WQNjJo__yZzimc6bbtP47",
	"iconButton": "_2g7UiHMiluPTH5RztBfEwy",
	"settingLabel": "_3iMq7DHxdVHCgYtmym0_QD",
	"settingWrapper": "zWKecJVX6TStjQ63NjWLf",
	"settingButton": "_1qeBgNjm9tcXdTh9oKudmq",
	"active": "_3I6CNnEagI5iphOanCTQeg"
};