// @flow

import {createReduxAction} from "../reducer";
import TopicAction from "../action/TopicAction";
import Api from "../../bundle/Api";

export default class TopicDispatcher {

    static getTopicDetail = (dispatch: Function, topicId: string) => {
        dispatch(createReduxAction(Api.actions.topic.GET_ONE_TOPIC_ACTION_TYPE, false));

        Api.services.topic.getTopicDetail(topicId).then((response) => {
            console.log("response=", response);
            dispatch(createReduxAction(Api.actions.topic.GET_ONE_TOPIC_ACTION_TYPE, true, response.data, response.status));
        });
    };

    static doCreateTopic = (dispatch: Function, description: string, isActive: boolean, projectIds: Array<number>, dataRecordTypes: Array<{ name: string, key: string, description: string }>) => {
        dispatch(createReduxAction(Api.actions.topic.CREATE_TOPIC_ACTION_TYPE, false));

        Api.services.topic.doCreateTopic(description, isActive, projectIds, dataRecordTypes).then((response) => {
            console.log("response=", response);
            dispatch(createReduxAction(Api.actions.topic.CREATE_TOPIC_ACTION_TYPE, true, response.data, response.status));
        });
    };

    static doGetListOfTopic = (dispatch: Function) => {
        dispatch(createReduxAction(TopicAction.GET_LIST_OF_TOPIC_ACTION_TYPE, false));

        Api.services.topic.doGetListOfTopic().then((response) => {
            console.log("response=", response);
            dispatch(createReduxAction(Api.actions.topic.GET_LIST_OF_TOPIC_ACTION_TYPE, true, response.data, response.status));
        });

    };
}