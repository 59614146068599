// @flow

import React from 'react';
import HeaderFragment from "../../header/HeaderFragment";
import BodyWrapper from "../../body/BodyWrapper";
import SidebarWrapper from "../../sidebar/SidebarWrapper";
import GeneralSidebarFragment from "../../sidebar/GeneralSidebarFragment";
import ContentWrapper from "../../content/ContentWrapper";
import ContentHeaderFragment from "../../content/ContentHeaderFragment";
import Breadcrumb from "../../content/Breadcrumb";
import ContentInnerWrapper from "../../content/ContentInnerWrapper";
import FooterFragment from "../../footer/FooterFragment";
import BackToTopButton from "../../BackToTopButton";
import StickyQuickNav from "../../SickyQuickNav";
import MaintenancePeriodListFragment from "../../admin/maintenacePeriod/MaintenancePeriodListFragment";

export default class AdminMaintenancePeriodPage extends React.Component<any, any> {
    render() {

        return <div className="m-grid m-grid--hor m-grid--root m-page">
            <HeaderFragment/>
            <BodyWrapper>
                <SidebarWrapper>
                    <GeneralSidebarFragment/>
                </SidebarWrapper>
                <ContentWrapper>
                    <ContentHeaderFragment displayText={"Admin: Maintenance Period"}>
                        <Breadcrumb items={[
                            {displayText: "Maintenance Period"}
                        ]}/>
                    </ContentHeaderFragment>
                    <ContentInnerWrapper>
                        <MaintenancePeriodListFragment/>
                    </ContentInnerWrapper>
                </ContentWrapper>
            </BodyWrapper>

            <FooterFragment/>
            <BackToTopButton/>
            <StickyQuickNav/>
        </div>
    }


}