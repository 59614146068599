// @flow

import React from 'react';
import styles from "./action.css";
import StringUtil from "../../../util/StringUtil";
import {DataRecordType} from "../../../model/model";
import {LLMsg} from "../../../IntlCapture";
import EventTriggerItemFragment from "../DataTriggerFragment/EventTriggerItemFragment";
import Constant from "../../../bundle/Constant";
import MessageInputWrapper from "../DataTriggerFragment/EventTriggerItemFragment/MessageInputWrapper";
import _ from "lodash";

type Props = {
    index: number,
    displayIndex: number,
    publishContent: ? string,
    topics: Array<{ topicName: string, description: string, dataRecordTypes: Array<DataRecordType> }>,
    onDeleteCallback: (index: number) => void,
    handleMessageValueChange: (message: string, index: number) => void
}
type State = {
    publishContentError: boolean
}

class HttpResponseActionFragment extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            publishContentError: false
        };

    }

    validateAction = (): boolean => {
        let valid = true;
        let publishContentError = false;
        if (StringUtil.isOnlySpaceOrEmpty(this.props.publishContent)) {
            valid = false;
            publishContentError = true;
        }
        this.setState({
            publishContentError: publishContentError
        });
        return valid;
    };

    handleMessageValueChange = (publishContent: string) => {

        this.props.handleMessageValueChange(publishContent, this.props.index);
        this.setState({
            publishContentError: false
        })
    };

    render() {


        return <span className={styles.actionItem}>

            <EventTriggerItemFragment
                allowDelete={true}
                backgroundColor={Constant.theme.eventTrigger.action.color}
                titleContent={
                    <span>
                        {LLMsg("EVENT_TRIGGER.SEND_HTTP_RESPONSE_ACTION")}
                        <MessageInputWrapper
                            className={styles.actionTextInput}
                            showError={this.state.publishContentError}
                            placeholder={LLMsg("EVENT_TRIGGER.MESSAGE")}
                            onChange={this.handleMessageValueChange}
                            value={this.props.publishContent}
                            index={this.props.index}
                            topics={_.sortBy(this.props.topics, ['id'])}
                        />
                    </span>
                }
                onDelete={() => {
                    this.props.onDeleteCallback(this.props.index);
                }}
            />


        </span>;
    }
}

export default HttpResponseActionFragment;