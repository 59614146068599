exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2SyTjEM5cu6FEmlFiXjmcQ {\n    padding-bottom: 10px;\n}\n\n._1-yFqBNwgLv0Zl9ykys-AQ {\n}\n\n.GiuMZ1ROkPXrpahciryJ7 {\n    padding: 10px;\n    width: 200px;\n    font-weight: bold;\n    border-right: 1px solid #eee;\n\n}\n\n._2iQKi0xxFlviBMhgH3QK7g {\n    padding: 10px;\n}", ""]);

// exports
exports.locals = {
	"itemWrapper": "_2SyTjEM5cu6FEmlFiXjmcQ",
	"item": "_1-yFqBNwgLv0Zl9ykys-AQ",
	"itemLabel": "GiuMZ1ROkPXrpahciryJ7",
	"itemValue": "_2iQKi0xxFlviBMhgH3QK7g"
};