exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Xwku0IYR9_MKZP_B7yU_F {\n    height: 380px;\n}\n\n.qo7gEzR23e2RSAReZPztb {\n    display: block;\n    font-size: 24px;\n    margin: 10px 20px;\n    text-align: left;\n    font-weight: bold;\n}\n\n._3avgYf9rwbOYUV1c7VAjVm {\n    padding: 20px;\n    text-align: center;\n}\n\n._2t2xKNMp8zg3SiTV1fQ5cW {\n    width: 100%;\n    text-align: center;\n    margin: 10px;\n    color: #172a70;\n}\n\n._11fGeK2su8_BqCokEDv2I7 {\n    display: inline-block;\n    width: 200px;\n    font-size: 16px;\n    text-align: right;\n    padding-right: 20px;\n}\n\n.cpn9iyQz2NXijzcELlbk1 {\n    display: inline-block;\n    width: 200px;\n}\n\n._3g3j812ovCzO7xieIoKUjf {\n    text-align: right;\n    margin: 20px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "_3Xwku0IYR9_MKZP_B7yU_F",
	"title": "qo7gEzR23e2RSAReZPztb",
	"content": "_3avgYf9rwbOYUV1c7VAjVm",
	"timeDifferentArrow": "_2t2xKNMp8zg3SiTV1fQ5cW",
	"label": "_11fGeK2su8_BqCokEDv2I7",
	"value": "cpn9iyQz2NXijzcELlbk1",
	"footer_button": "_3g3j812ovCzO7xieIoKUjf"
};