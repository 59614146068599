exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1IuFrWoysi5-0roE3Ajn1I {\n    border: 1px solid whitesmoke;\n    padding: 10px;\n}\n\n.Nc_4hNfaNz63N709JrKKc {\n    margin: 5px;\n}", ""]);

// exports
exports.locals = {
	"box": "_1IuFrWoysi5-0roE3Ajn1I",
	"checkBox": "Nc_4hNfaNz63N709JrKKc"
};