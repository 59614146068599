// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";

class TopicService {

    static doGetListOfTopic(topicIds?: Array<string>): Object {
        return HttpUtil.ajax(Api.urls.topic.getListOfTopicUrl(topicIds), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static doGetChartableTopic(): Object {
        return HttpUtil.ajax(Api.urls.topic.getListOfChartableTopicUrl(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static getTopicDetail(topicId: string): Object {
        return HttpUtil.ajax(Api.urls.topic.getTopicDetailUrl(topicId), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static doSendNewMessageToTopic(topicId: string, outputType: string, message: string): Object {
        return HttpUtil.ajax(Api.urls.topic.getTopicSendNewMessageUrl(), "POST", {
            'Content-Type': 'application/json',
        }, {
            "topicId": topicId,
            "outputType": outputType,
            "message": message
        });
    }

    static doSendNewMessageToTopicByTopicName(topicName: string, outputType: string, message: string): Object {
        return HttpUtil.ajax(Api.urls.topic.getTopicSendNewMessageByTopicNameUrl(), "POST", {
            'Content-Type': 'application/json',
        }, {
            "topicName": topicName,
            "outputType": outputType,
            "message": message
        });
    }

    static doGetTopicIdByName(topicName: string): Object {
        return HttpUtil.ajax(Api.urls.topic.getTopicIdByNameUrl(topicName), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static update = {
        description: (topicId: string, newDescription: string): Object => {
            return HttpUtil.ajax(Api.urls.topic.update.description(), Api.methods.put, {
                'Content-Type': 'application/json',
            }, {topicId, description: newDescription});
        },
        newDataType: (topicId: string, newName: string, newKey: string, newDescription: string): Object => {
            return HttpUtil.ajax(Api.urls.topic.update.newDataType(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {
                topicId: topicId,
                dataTypeName: newName,
                dataTypeKey: newKey,
                dataTypeDescription: newDescription
            });
        },
        updateDataType: (topicId: string, dataTypeId: string, newName: string, newKey: string, newDescription: string): Object => {
            return HttpUtil.ajax(Api.urls.topic.update.updateDataType(), Api.methods.put, {
                'Content-Type': 'application/json',
            }, {
                topicId: topicId,
                dataTypeId: dataTypeId,
                dataTypeName: newName,
                dataTypeKey: newKey,
                dataTypeDescription: newDescription
            });
        },
        deleteDataType: (topicId: string, dataTypeId: string): Object => {
            return HttpUtil.ajax(Api.urls.topic.update.deleteDataType(), Api.methods.delete, {
                'Content-Type': 'application/json',
            }, {
                topicId: topicId,
                dataTypeId: dataTypeId
            });
        },
        updateTopicLocation: (topicId: string, lat: ? number, lng: ? number): Object => {
            return HttpUtil.ajax(Api.urls.topic.update.updateTopicLocation(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {
                topicId: topicId,
                lat: lat,
                lng: lng
            });
        },
        removeTopicLocation: (topicId: string): Object => {
            return HttpUtil.ajax(Api.urls.topic.update.removeTopicLocation(), Api.methods.post, {
                'Content-Type': 'application/json',
            }, {
                topicId: topicId
            });
        }
    };


    static doCreateTopic(description: string, isActive: boolean, projectIds: Array<number>, dataRecordTypes: Array<{ name: string, key: string, description: string }>): Object {
        return HttpUtil.ajax(Api.urls.topic.getTopicCreateUrl(), "POST", {
            'Content-Type': 'application/json',
        }, {
            "description": description,
            "isActive": isActive,
            "projectIds": projectIds ? projectIds.join(",") : undefined,
            "dataRecordTypes": dataRecordTypes ? dataRecordTypes.map(dataRecordType => ({
                "name": dataRecordType.name,
                "key": dataRecordType.key,
                "description": dataRecordType.description
            })) : undefined
        });
    }

    static doGetCreateTopicLimit(): Object {
        return HttpUtil.ajax(Api.urls.topic.getCreateTopicLimitUrl(), Api.methods.get, {
            'Content-Type': 'application/json',
        });
    }
}

export default TopicService;