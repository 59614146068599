// @flow
import React from 'react';
import {LLMsg} from "../../../../../IntlCapture";
import Constant from "../../../../../bundle/Constant";
import ChartTypeItem from "../../ChartTypeSelectFragment/ChartTypeItem";
import styles from './index.css';
import ChartSourceSelectFragment from "../../ChartSourceSelectFragment/index";

type ToggleConfigFragmentProps = {}
type ToggleConfigFragmentState = {
    onColor: string,
    offColor: string,
    offStyleClassname: string,
    onStyleClassname: string,

    chartNameError: boolean,
    chartSourceError: boolean,
    onTextOrStyleError: boolean,
    onValueError: boolean,
    offTextOrStyleError: boolean,
    offValueError: boolean,
}

class ToggleConfigFragment extends React.Component <ToggleConfigFragmentProps, ToggleConfigFragmentState> {
    constructor(props: ToggleConfigFragmentProps) {
        super(props);
        this.state = {
            onColor: "",
            offColor: "",
            offStyleClassname: "",
            onStyleClassname: "",

            chartNameError: false,
            chartSourceError: false,
            onTextOrStyleError: false,
            onValueError: false,
            offTextOrStyleError: false,
            offValueError: false
        };
        this.getConfigs = this.getConfigs.bind(this);
        this.validate = this.validate.bind(this);
    }

    chartName: string;
    outputType: string;
    onValue: string;
    offValue: string;
    onText: string;
    offText: string;
    chartSourceRef: any = React.createRef();

    getConfigs = () => {
        return {

            "CHART_NAME": this.chartName,
            "OUTPUT_TYPE": this.outputType,
            "ON_VALUE": this.onValue,
            "ON_TEXT": this.onText,
            "ON_COLOR": this.state.onColor,
            "ON_STYLE": this.state.onStyleClassname,
            "OFF_VALUE": this.offValue,
            "OFF_TEXT": this.offText,
            "OFF_COLOR": this.state.offColor,
            "OFF_STYLE": this.state.offStyleClassname,
            "CHART_SOURCE": this.chartSourceRef.current && this.chartSourceRef.current.getConfigs()
        }
    };

    validate = () => {
        let isValid = true;
        let error = {
            chartNameError: false,
            chartSourceError: false,
            onTextOrStyleError: false,
            onValueError: false,
            offTextOrStyleError: false,
            offValueError: false
        };

        if (!this.chartName) {
            isValid = false;
            error.chartNameError = true;
        }

        if (!this.onValue) {
            isValid = false;
            error.onValueError = true;
        }

        if (!this.onText && !this.state.onStyleClassname) {
            isValid = false;
            error.onTextOrStyleError = true;
        }

        if (!this.offValue) {
            isValid = false;
            error.offValueError = true;
        }

        if (!this.offText && !this.state.offStyleClassname) {
            isValid = false;
            error.offTextOrStyleError = true;
        }

        let configs = this.chartSourceRef.current ? this.chartSourceRef.current.getConfigs() : [];

        configs.map((config) => {
            if (!config.topicId) {
                isValid = false;
                error.chartSourceError = true;
            }
        });

        this.setState(error);
        return isValid;
    };


    render() {

        return <div>
            <h3>
                <strong>{LLMsg("COMMON.STEP.STEP_2")} - </strong>
                <span>{LLMsg("COMMON.CHART.CHART_SETTING")}</span>
            </h3>
            <hr/>
            <div>

                <div className="row">
                    <div className={"col-6"}>
                        <div
                            className={"form-group m-form__group row " + (this.state.chartNameError ? styles.error : "")}>
                            <div className={"col-12 " + styles.title}>
                                {LLMsg("COMMON.CHART.CHART_NAME")}
                            </div>
                            <div className="col-12">
                                <input className="form-control m-input" type="text" onChange={(e) => {
                                    this.chartName = e.target.value;
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-6"}>
                    </div>
                    <div className={"col-6"}>

                        <div className="form-group m-form__group row">
                            <div className={styles.title + " col-12"}>
                                {LLMsg("COMMON.CHART.SELECTED_CHART_TYPE")}
                            </div>
                            <div className="col-12">
                                <ChartTypeItem chartType={Constant.chart.type.toggleButton}
                                               defaultChecked={true}
                                               inputName={"chart_config_radio"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"form-group m-form__group row " + (this.state.chartSourceError ? styles.error : "")}>
                    <div className={"col-12 " + styles.title}>
                        {LLMsg("COMMON.CHART.SELECTED_CHART_SOURCE")}
                    </div>
                    <div className="col-12">
                        <ChartSourceSelectFragment isSingleSource={true}
                                                   ref={this.chartSourceRef}
                                                   showSimpleMessageOption={true}
                                                   showDeviceOption={false}
                                                   showColorOption={false}/>
                    </div>
                </div>


                <div className="form-group m-form__group row">
                    <div className={"col-6 " + styles.on_off_wrapper}>
                        <div className={styles.title}>
                            {LLMsg("COMMON.CHART.WHEN_BUTTON_ON")}
                        </div>

                        <div className={styles.on_off_item}>
                            <div className={styles.on_off_item_title}>
                                <div>{LLMsg("COMMON.CHART.CHART_COLOR")}</div>
                            </div>
                            <div className={styles.on_off_item_value}>
                                <input className="form-control m-input" type="color" onChange={(e) => {
                                    this.setState({onColor: e.target.value})
                                }}/>
                            </div>
                        </div>
                        <div className={styles.on_off_item + " " + (this.state.onValueError ? styles.error : "")}>

                            <div className={styles.on_off_item_title}>
                                <div>{LLMsg("COMMON.CHART.BUTTON_ACTIVE_VALUE")}</div>
                            </div>
                            <div className={styles.on_off_item_value}>
                                <input className="form-control m-input" type="text" onChange={(e) => {
                                    this.onValue = e.target.value;
                                }}/>
                            </div>
                        </div>
                        <div className={styles.on_off_item + " " + (this.state.onTextOrStyleError ? styles.error : "")}>

                            <div className={styles.on_off_item_title}>
                                <div>{LLMsg("COMMON.CHART.CHOOSE_STYLE")}</div>
                            </div>
                            <div className={styles.on_off_item_value}>
                                <input className="form-control m-input" type="text"
                                       placeholder={LLMsg("COMMON.CHART.BUTTON_DISPLAY_TEXT")}
                                       onChange={(e) => {
                                           this.onText = e.target.value;
                                       }}/>
                            </div>
                            <div className={styles.on_off_item_value}>
                                <div className={styles.on_off_item_value}>
                                    {
                                        Constant.chart.button.style.map((className: string) => {
                                            let isSelected: boolean = this.state.onStyleClassname === className;
                                            return <button
                                                onClick={() => {
                                                    this.setState({onStyleClassname: className});
                                                }}
                                                className={"btn btn-secondary m-btn m-btn--icon m-btn--icon-only m-2 " + (isSelected ? styles.selected_button_style : "")}>

                                                <i className={className + " fa-2x"}
                                                   style={{color: this.state.onColor}}/>
                                            </button>;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-6 " + styles.on_off_wrapper}>
                        <div className={styles.title}>
                            {LLMsg("COMMON.CHART.WHEN_BUTTON_OFF")}
                        </div>

                        <div className={styles.on_off_item}>
                            <div className={styles.on_off_item_title}>
                                <div>{LLMsg("COMMON.CHART.CHART_COLOR")}</div>
                            </div>
                            <div className={styles.on_off_item_value}>
                                <input className="form-control m-input" type="color" onChange={(e) => {
                                    this.setState({offColor: e.target.value})
                                }}/>
                            </div>
                        </div>
                        <div className={styles.on_off_item + " " + (this.state.offValueError ? styles.error : "")}>

                            <div className={styles.on_off_item_title}>
                                <div>{LLMsg("COMMON.CHART.BUTTON_INACTIVE_VALUE")}</div>
                            </div>
                            <div className={styles.on_off_item_value}>
                                <input className="form-control m-input" type="text" onChange={(e) => {
                                    this.offValue = e.target.value;
                                }}/>
                            </div>
                        </div>
                        <div
                            className={styles.on_off_item + " " + (this.state.offTextOrStyleError ? styles.error : "")}>

                            <div className={styles.on_off_item_title}>
                                <div>{LLMsg("COMMON.CHART.CHOOSE_STYLE")}</div>
                            </div>
                            <div className={styles.on_off_item_value}>
                                <input className="form-control m-input" type="text"
                                       placeholder={LLMsg("COMMON.CHART.BUTTON_DISPLAY_TEXT")}
                                       onChange={(e) => {
                                           this.offText = e.target.value;
                                       }}/>
                            </div>
                            <div className={styles.on_off_item_value}>
                                {
                                    Constant.chart.button.style.map((className: string) => {
                                        let isSelected: boolean = this.state.offStyleClassname === className;
                                        return <button
                                            onClick={() => {
                                                this.setState({offStyleClassname: className});
                                            }}
                                            className={"btn btn-secondary m-btn m-btn--icon m-btn--icon-only m-2 " + (isSelected ? styles.selected_button_style : "")}>
                                            <i className={className + " fa-2x"}
                                               style={{color: this.state.offColor}}/>
                                        </button>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={"col-12"}>
                        <div
                            className={"form-group m-form__group row"}>
                            <div className={"col-12 " + styles.title}>
                                {LLMsg("COMMON.CHART.OUTPUT_TYPE")}
                            </div>
                            <div className="col-12">
                                <input className="form-control m-input" type="text" onChange={(e) => {
                                    this.outputType = e.target.value;
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default ToggleConfigFragment;