exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1wrWshdbXE4ZM7cTwtVJvv {\n    margin-left: 7px;\n    font-style: italic;\n    color: #34bfa3;\n}\n\n._2XdEMykS68q2U94j28KKCx {\n    margin-left: 7px;\n    color: #f4516c;\n}\n\n._2bZRSltChlxA2O5qaQKLS- {\n    font-size: 2em;\n\n}\n\n._1FLqPjlLuMC6PDOr5flwk9 {\n    font-family: \"Arial Black\", Gadget, sans-serif\n}\n\n._2ICyaeEi5C0Z-Z5ULjl3rp {\n    margin-top: 15px;\n}\n\n._3CkQkxsPbXoGCdffOsA8Yv {\n    color: #a4a7bd;\n}\n\n.TnVV9xY4Gu2SQ3j6JmiXM {\n    margin-right: 10px;\n    color: #34bfa3;\n}\n._1duzX2Uk7pBzeafCvISOPn {\n    margin-right: 10px;\n    color: #f4516c;\n}\n\n._3QCibiTe-Pe1NdNHL34RvK {\n    color: #f4516c;\n\n}", ""]);

// exports
exports.locals = {
	"accountActiveSpan": "_1wrWshdbXE4ZM7cTwtVJvv",
	"accountInactiveSpan": "_2XdEMykS68q2U94j28KKCx",
	"accountStatusBox": "_2bZRSltChlxA2O5qaQKLS-",
	"portletBox": "_1FLqPjlLuMC6PDOr5flwk9",
	"item": "_2ICyaeEi5C0Z-Z5ULjl3rp",
	"itemDesc": "_3CkQkxsPbXoGCdffOsA8Yv",
	"itemCheck": "TnVV9xY4Gu2SQ3j6JmiXM",
	"itemCross": "_1duzX2Uk7pBzeafCvISOPn",
	"limitReached": "_3QCibiTe-Pe1NdNHL34RvK"
};