// @flow

import React from 'react';
import {ClassNames} from "../../../bundle/ClassNames";
import {Link} from "react-router-dom";
import Page from "../../../bundle/Page";
import styles from "./index.css";
import {LLMsg} from "../../../IntlCapture";
import CreateDataRecordTypeInlineButton from "../../dataRecord/CreateDataRecordTypeInlineButton";
import UnlinkTopicFromProjectButton from "../UnlinkTopicFromProjectButton";
import CopyTopicNameButtonFragment from "../CopyTopicNameButtonFragment";
import DataTyeListFragment from "../../dataRecord/DataTyeListFragment";
import TopicDataLoggerFragment from "../TopicDataLoggerFragment";
import TopicMapFragment from "../../chart/MapFragment/TopicMapFragment";
import type {Topic} from "../../../model/model";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import LazyLoad, {forceCheck} from 'react-lazyload';

type Props = {
    topic: Topic,
    projectId: number
}
export default class MappedTopicItem extends React.Component <Props, any> {
    render() {
        const topic: Topic = this.props.topic;
        return <div className={"col-12 p-0"}>
            <div className={ClassNames("m-portlet mb-0")}>
                <div className="m-portlet__body">
                    <div
                        className={ClassNames("text-left mb-4")} style={{width: "100%"}}>
                        <span className={styles.topicTitle}>{LLMsg("COMMON.TOPIC.TOPIC")} {topic.description}</span>

                        <div className={styles.topicName}>
                            {LLMsg("COMMON.TOPIC.TOPIC_NAME")}:
                        </div>
                        <span className={styles.topicNameInput}>{topic.topicName}</span>
                        <CopyTopicNameButtonFragment
                            className={ClassNames("ml-2 btn btn-link m-btn--pill btn-sm m-btn--label-metal", styles.copyButton)}
                            color={"grey"} text={topic.topicName}/>


                        <div className={styles.rightTopTitleButtonWrapper}>
                            <Link to={Page.internals.urls.topic.detail(topic.id, topic.description)}
                                  type="button"
                                  className={ClassNames("btn btn-warning m-btn btn-sm")}>
                                {LLMsg("COMMON.BREADCRUMB.DETAIL")}
                            </Link>
                            <UnlinkTopicFromProjectButton topicId={topic.id}
                                                          projectId={this.props.projectId}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-6"}>
                            <div className={styles.tabWrapper}>
                                <Tabs>
                                    <TabList className={styles.tabHeaderWrapper}>
                                        <Tab className={styles.tab}>{LLMsg("COMMON.DATA_TYPE.DATA_TYPE")}</Tab>

                                        <div className="float-right">
                                            <CreateDataRecordTypeInlineButton topicId={topic.id}/>
                                        </div>
                                    </TabList>
                                    <TabPanel className={styles.tabContentWrapper}>
                                        <DataTyeListFragment dataRecordTypes={topic.dataRecordTypes}
                                                             topicId={topic.id} settings={this.settings}/>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                        <div className={"col-12 col-lg-6"}>
                            <div className={styles.tabWrapper}>
                                <Tabs>
                                    <TabList className={styles.tabHeaderWrapper}>
                                        <Tab className={styles.tab}>{LLMsg("PERMISSION.HISTORICAL_DATA")}</Tab>
                                    </TabList>
                                    <TabPanel className={styles.tabContentWrapper}>
                                        <TopicDataLoggerFragment topic={topic}/>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>


                        <div className={"col-12"}>
                            <div className={styles.tabWrapper}>
                                <Tabs>
                                    <TabList className={styles.tabHeaderWrapper}>
                                        <Tab className={styles.tab}>{LLMsg("COMMON.TOPIC.TOPIC_LOCATION")}</Tab>
                                    </TabList>
                                    <TabPanel className={styles.tabContentWrapper}>
                                        <LazyLoad>
                                            <TopicMapFragment topicId={topic.id} latitude={topic.latitude}
                                                              isProjectDetailPage={true}
                                                              longitude={topic.longitude}/>
                                        </LazyLoad>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>

                </div>
                <hr/>
            </div>
        </div>
    }
}