// @flow

import React, {Component} from 'react';
import TopicListTableRecord from "./TopicListTableRecord";
import TopicListCreateTopicButton from "./TopicListCreateTopicButton";
import TopicListRefreshtButton from "./TopicListRefreshtButton";
import TopicListTableNoRecord from "./TopicListTableNoRecord";
import Api from "../../../bundle/Api";
import {LLMsg} from "../../../IntlCapture";

type TopicListFragmentProps = {}

type TopicListFragmentState = {
    topics?: Object
}

class TopicListFragment extends Component<TopicListFragmentProps, TopicListFragmentState> {

    constructor(props: TopicListFragmentProps) {
        super(props);
        this.state = {};
        this.getTopicList();
    }

    getTopicList = () => {
        window.mApp.block("#TopicListFragment", {
            overlayColor: "#000000",
            type: "loader",
            state: "success",
            message:  LLMsg("COMMON.ACTION.LOADING_MESSAGE")
        });
        Api.services.topic.doGetListOfTopic().then((response) => {
            window.mApp.unblock("#TopicListFragment");
            if (response.status === 200) {
                this.setState({topics: response.data.topics});
            }
        }).catch(() => {
            window.mApp.unblock("#TopicListFragment");
        });
    };

    render() {

        const topics = this.state.topics || [];

        return <div className="m-portlet m-portlet--mobile" id={"TopicListFragment"}>
            <div className="m-portlet__head">
                <div className="m-portlet__head-caption">
                    <div className="m-portlet__head-title">
                        <h3 className="m-portlet__head-text">
                            {LLMsg("COMMON.TOPIC.TOPIC_LIST")}
                        </h3>
                    </div>
                </div>
                <div className="m-portlet__head-tools">
                    <ul className="m-portlet__nav">
                        <TopicListRefreshtButton callback={this.getTopicList}/>
                        <TopicListCreateTopicButton/>
                    </ul>
                </div>
            </div>
            <div className="m-portlet__body pt-0">
                <div id="m_table_1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                        <div className="col-sm-12">
                            <table
                                className="table table-hover table-checkable dataTable no-footer dtr-inline"
                                id="m_table_1" role="grid" aria-describedby="m_table_1_info" style={{width: 1065}}>
                                <tbody>
                                {
                                    (!topics || topics.length === 0) && <TopicListTableNoRecord/>
                                }
                                {
                                    topics && topics.map((topic, index) => {
                                        return <TopicListTableRecord key={index} topicId={topic.id}
                                                                     description={topic.description}
                                                                     index={index + 1}
                                                                     isActive={topic.isActive}
                                                                     topicName={topic.topicName}
                                                                     projects={topic.projects}
                                        />
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default TopicListFragment;
