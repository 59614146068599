// @flow

import React, {Component} from 'react';
import styles from "../SchoolInfoItem.css";
import SchoolApplicationTableHeader from "./SchoolApplicationTableHeader";
import SchoolApplicationTableContent from "./SchoolApplicationTableContent";
import {withRouter} from "react-router";

type SchoolApplicationProps = {
    schoolApplications: Array<Object>
}

class SchoolApplication extends Component<SchoolApplicationProps, any> {

    render() {
        return <div className={"m-stack m-stack--ver m-stack--general " + styles.item}>
            <div
                className={"m-stack__item  m-stack__item--left m-stack__item--middle m-stack__item--fluid " + styles.itemValue}>

                <div className="m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded"
                     id="mytable">
                    <table className="m-datatable__table"
                           style={{display: 'block', minHeight: '300px', overflowX: 'auto'}}>

                        <SchoolApplicationTableHeader/>
                        <SchoolApplicationTableContent
                            schoolApplications={this.props.schoolApplications}
                        />
                    </table>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(SchoolApplication);