exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".iVjHR03F3p_p2AE_Jyt94 {\n    text-align: left;\n    padding: 30px;\n}\n\n.sLeVu53LihQok-cb_arU1 {\n    background-color: #eeeeee;\n    display: block;\n    text-align: center;\n    font-size: larger;\n    line-height: 50px;\n    min-height: 50px;\n\n    overflow-wrap: break-word;\n    padding: 20px 40px;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "iVjHR03F3p_p2AE_Jyt94",
	"result": "sLeVu53LihQok-cb_arU1"
};