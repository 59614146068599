// @flow

import React from "react";
import {LLMsg} from "../../../IntlCapture";

const ProjectTypeSelect = () => {
    return <div className="form-group m-form__group">
        <label htmlFor="example_input_full_name" className={" m--icon-font-size-lg3 mb-3"}>
            {LLMsg("COMMON.PROJECT.TITLE_LABEL")}
        </label>
        <div className="row">
            <div className="col-lg-6">
                <label className="m-option">
                  <span className="m-option__control">
                    <span className="m-radio m-radio--brand m-radio--check-bold">
                      <input type="radio" name="m_option_1" defaultChecked={true} defaultValue={1}/>
                      <span/>
                    </span>
                  </span>
                    <span className="m-option__label">
                        <span className="m-option__head">
                          <span className="m-option__title  m--icon-font-size-lg1">
                            {LLMsg("COMMON.PROJECT.TYPE.IOT.NAME")}
                          </span>
                        </span>
                        <span className="m-option__body m--icon-font-size-lg0">
                            {LLMsg("COMMON.PROJECT.TYPE.IOT.DESC")}
                        </span>
                  </span>
                </label>
            </div>
            <div className="col-lg-6">
                <label className="m-option">
                  <span className="m-option__control">
                    <span className="m-radio m-radio--brand m-radio--check-bold">
                      <input type="radio" name="m_option_1" defaultValue={1}/>
                      <span/>
                    </span>
                  </span>
                    <span className="m-option__label">
                    <span className="m-option__head">
                      <span className="m-option__title m--icon-font-size-lg1">
                            {LLMsg("COMMON.PROJECT.TYPE.LBS.NAME")}
                      </span>
                      <span className="m-option__focus">
                            {LLMsg("COMMON.PROJECT.POWERED_BY_QBS")}
                      </span>
                    </span>
                    <span className="m-option__body m--icon-font-size-lg0">
                            {LLMsg("COMMON.PROJECT.TYPE.LBS.DESC")}
                    </span>
                  </span>
                </label>
            </div>
            <div className="col-lg-6">
                <label className="m-option">
                  <span className="m-option__control">
                    <span className="m-radio m-radio--brand m-radio--check-bold">
                      <input type="radio" name="m_option_1" defaultValue={1}/>
                      <span/>
                    </span>
                  </span>
                    <span className="m-option__label">
                    <span className="m-option__head">
                      <span className="m-option__title m--icon-font-size-lg1">
                            {LLMsg("COMMON.PROJECT.TYPE.MLP.NAME")}
                      </span>
                    </span>
                    <span className="m-option__body m--icon-font-size-lg0">
                            {LLMsg("COMMON.PROJECT.TYPE.MLP.DESC")}
                    </span>
                  </span>
                </label>
            </div>
        </div>
    </div>;
};

export default ProjectTypeSelect;