// @flow

import StringUtil from "../../util/StringUtil";
import Config from "../../config/Config";
import Api from "../../bundle/Api";

export default class SchoolApiUrlHelper {

    static list(): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_SCHOOL_LISTING_API, []);
    }

    static detail(schoolId: string): string {
        return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_SCHOOL_DETAIL_API, [{
            key: ":schoolId",
            value: schoolId
        }]);
    }

    static application = {
        approve: (schoolId: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_SCHOOL_APPLICATION_APPROVE_API, [{
                key: ":schoolId",
                value: schoolId
            }]);
        },
        reject: (schoolId: string): string => {
            return StringUtil.replaceAllText(Config.API_MAKER_CLOUD + Api.paths.ADMIN_SCHOOL_APPLICATION_REJECT_API, [{
                key: ":schoolId",
                value: schoolId
            }]);
        }
    }

}