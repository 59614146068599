// @flow
import HttpUtil from "../util/HttpUtil";
import Api from "../bundle/Api";
import {saveAs} from 'file-saver';

class EventCodeService {

    static consumeEventCode(eventCode: string): Object {
        return HttpUtil.ajax(Api.urls.eventCode.consumeEventCode(), "POST", {
            'Content-Type': 'application/json',
        }, {eventCode});
    }

    static list(): Object {
        return HttpUtil.ajax(Api.urls.eventCode.getEventCodeList(), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static getEventCodeDetail(eventCodeId: string): Object {
        return HttpUtil.ajax(Api.urls.eventCode.getEventCodeDetail(eventCodeId), "GET", {
            'Content-Type': 'application/json',
        });
    }

    static updateEventCode(eventCodeId: string, eventCode: string, eventName: string,
                           startTime: string, endUntil: string, regEndDate: string): Object {
        return HttpUtil.ajax(Api.urls.eventCode.updateEventCodeDetail(), "POST", {
            'Content-Type': 'application/json',
        }, {eventCodeId, eventCode, eventName, startTime, endUntil, regEndDate});
    }

    static createEventCode(eventCode: string, eventName: string,
                           startTime: string, endUntil: string, regEndDate: string): Object {
        return HttpUtil.ajax(Api.urls.eventCode.createEventCodeDetail(), "POST", {
            'Content-Type': 'application/json',
        }, {eventCode, eventName, startTime, endUntil, regEndDate});
    }


}

export default EventCodeService;