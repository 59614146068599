exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3NN8TjwBOttL6OIYZtN0MV {\n    padding: 10px 10px 15px 15px;\n    font-size: 1.4em;\n}\n\n.A7VsWhvcUMOyledhtq58d {\n    color: red;\n}\n\n.A7VsWhvcUMOyledhtq58d input {\n    border: 1px solid red;\n}\n.A7VsWhvcUMOyledhtq58d select {\n    border: 1px solid red;\n}\n", ""]);

// exports
exports.locals = {
	"title": "_3NN8TjwBOttL6OIYZtN0MV",
	"error": "A7VsWhvcUMOyledhtq58d"
};