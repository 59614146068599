// @flow

import React, {Component} from 'react';
import GoogleSignInButtonWrapper from "./GoogleSignInButtonWrapper";
import RegisterButtonWrapper from "./RegisterButtonWrapper";
import MoreResourcesButtonWrapper from "./MoreResourcesButtonWrapper";

class OthersLoginFragment extends Component<any, any> {

    render() {
        return <div className="m-login__options">

            <div className="text-center" style={{margin: "0 auto"}}>
                <GoogleSignInButtonWrapper/>
                <RegisterButtonWrapper/>
                <MoreResourcesButtonWrapper/>
            </div>
        </div>
    }

}

export default OthersLoginFragment;