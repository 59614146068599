exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Q4jhhsiKZy3rPC6DBJHqF {\n}\n\n._3OYP3PMUm7AaEEe09gQ098 {\n    text-align: center;\n}\n\n._3i96esCLxGiTyvCUDKpy74 {\n    margin-bottom: 20px;\n}\n\n.rd9V7XkkGaNWRP9dJICGy {\n    font-weight: bold;\n    font-size: 1.2em;\n    margin-top: 25px;\n    margin-bottom: 25px;\n}\n\n._28g1vV42Gg-nGimTMKQOVp {\n    margin-top: 25px;\n    margin-bottom: 25px;\n    font-size: 0.9em;\n\n}\n\n._3WIT-aq9ADhOgz_r_a0LbU {\n    float: right;\n}\n", ""]);

// exports
exports.locals = {
	"box": "_2Q4jhhsiKZy3rPC6DBJHqF",
	"imageBox": "_3OYP3PMUm7AaEEe09gQ098",
	"titleBox": "_3i96esCLxGiTyvCUDKpy74",
	"title": "rd9V7XkkGaNWRP9dJICGy",
	"desc": "_28g1vV42Gg-nGimTMKQOVp",
	"submitBtn": "_3WIT-aq9ADhOgz_r_a0LbU"
};