// @flow

import React from 'react';
import {LLMsg} from "../../../IntlCapture";
import RenameProjectButton from "./RenameProjectButton";
import styles from './ProjectNameFragment.css';
import {DeleteProjectButton} from "./DeleteProjectButton";

type ProjectNameFragmentProps = {
    projectName: string,
    projectId: string
}


class ProjectNameFragment extends React.Component<ProjectNameFragmentProps, any> {

    render() {
        return <div>
            <div>{LLMsg("COMMON.PROJECT.PROJECT_NAME")}</div>
            <h3 className={styles.title}><u><strong>{this.props.projectName}</strong></u></h3>
            <div className={styles.floatBox}>
                <RenameProjectButton projectId={this.props.projectId} projectName={this.props.projectName}/>
                <DeleteProjectButton projectId={this.props.projectId}/>
            </div>
        </div>

    }
}

export default ProjectNameFragment;