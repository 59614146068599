exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1mflATaUZSygewZRzzbOvb {\n    margin-left: 10px;\n    cursor: pointer;\n    border: solid 1px whitesmoke;\n    display: block;\n    padding: 10px;\n    border-radius: 6px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    background-color: #ebedf2;\n}\n\n._1DzhfC2CvTl1F7AngYGYRJ {\n    padding-left: 15px;\n}\n\n._10cHupaBkG_bWXvJ_wr0wu {\n    font-size: 1.5em;\n}\n\n._2Gr9EMaXgy56lQ4kT7oiJ4 {\n    color: #575962;\n\n}", ""]);

// exports
exports.locals = {
	"button": "_1mflATaUZSygewZRzzbOvb",
	"content": "_1DzhfC2CvTl1F7AngYGYRJ",
	"title": "_10cHupaBkG_bWXvJ_wr0wu",
	"desc": "_2Gr9EMaXgy56lQ4kT7oiJ4"
};