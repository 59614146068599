// @flow

import React from 'react';
import styles from './index.css';
import PubSub from "pubsub-js";
import Api from "../../../../bundle/Api";
import Image from "../../../../bundle/Image";
import {LLMsg} from "../../../../IntlCapture";
import Constant from "../../../../bundle/Constant";
import ErrorUtil from "../../../../util/ErrorUtil";
import {ClassNames} from "../../../../bundle/ClassNames";

type ChartTypeItemProps = {
    defaultChecked: boolean,
    chartType: string,
    inputName: string,
    disabled?: boolean
}

class ChartTypeItem extends React.Component<ChartTypeItemProps, any> {
    render() {
        let content;
        let displayText;

        if (this.props.chartType === Constant.chart.type.basicLineChart) {

            displayText = LLMsg("COMMON.CHART.LINE_CHART");
            content = <img src={Image.urls.getBasicLineChartImage()} height={"100px"}/>

        } else if (this.props.chartType === Constant.chart.type.gauge) {

            displayText = LLMsg("COMMON.CHART.GAUGE_CHART");
            content = <img src={Image.urls.getGaugeChartImage()} height={"100px"}/>

        } else if (this.props.chartType === Constant.chart.type.plainTextChart) {

            displayText = LLMsg("COMMON.CHART.PLAIN_TEXT");
            content = <img src={Image.urls.getPlainTextChartImage()} height={"100px"}/>

        } else if (this.props.chartType === Constant.chart.type.toggleButton) {

            displayText = LLMsg("COMMON.CHART.TOGGLE");
            content =
                <i className="fa fa-toggle-on"
                   style={{fontSize: '80px', color: 'cadetblue', margin: '30px 30px 30px 10px'}}/>

        } else if (this.props.chartType === Constant.chart.type.pushButton) {

            displayText = LLMsg("COMMON.CHART.PUSH_BUTTON");
            content =
                <button style={{
                    fontSize: '50px',
                    color: 'darkolivegreen',
                    borderColor: 'darkolivegreen',
                    margin: '30px 30px 30px 10px'
                }} type="button"
                        className="btn m-btn btn-outline m-btn--custom">
                    {"GO"}
                </button>;

        } else if (this.props.chartType === Constant.chart.type.pointMap) {

            displayText = LLMsg("COMMON.CHART.POINT_MAP");
            content = <img src={Image.urls.getPointMapChartImage()} height={"100px"}/>
        }

        return <div className={ClassNames(styles.item, {[styles.disabled]: this.props.disabled})} onClick={() => {
            if (this.props.disabled) {
                ErrorUtil.showPermissionNotAllowed();
            }
        }}>
            <label className="m-option">
                <span className="m-option__control">
                    <span className="m-radio m-radio--brand m-radio--check-bold ">
                      <input type="radio" defaultChecked={this.props.defaultChecked}
                             onChange={(e) => {
                                 PubSub.publish(Api.actions.chart.CREATE_CHART_TYPE_CHANGED_ACTION, e.target.value);
                             }}
                             name={this.props.inputName}
                             disabled={!!this.props.disabled}
                             defaultValue={this.props.chartType}/>
                          <span/>
                    </span>

                </span>
                <span className="m-option__label">
                    <span className="m-option__head">
                        <span
                            className="m-option__title">
                            {displayText}
                        </span>
                    </span>
                     <span className="m-option__body pull-left">
                         {content}
                     </span>
                </span>
            </label>
        </div>;
    }

}

export default ChartTypeItem;