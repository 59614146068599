// @flow

import React, {Component} from 'react';
import HugeBannerFragment from "../../content/HugeBannerFragment";
import Page from "../../../bundle/Page";
import LicenseDetailPublicViewFragment from "../../license/LicenseDetailPublicViewFragment/index";

class LicenseDetailPage extends Component<any, any> {

    render() {
        Page.updateTitle("MY_SUB.VIEW_LICENSE_DETAIL_BUTTON");
        return <div className={"m-grid m-grid--hor m-grid--root m-page"}>
            <div
                className={"m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"}>
                <HugeBannerFragment/>
                <LicenseDetailPublicViewFragment/>
            </div>
        </div>
    }
}

export default LicenseDetailPage;