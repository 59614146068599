exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2YtUOFlLfaFoG-k9qmq1Vg {\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n._3Z62d4RuhmEDX3rPZT_sUl {\n    background-color: #fbfbfb;\n}\n\n._7kaH6n-apg7_-5idEF1GE {\n    padding: 10px;\n    width: 200px;\n    border-right: 1px solid #eee;\n    border-bottom: 1px solid #eee;\n}\n\n.PWiaqn4L2eIiy4rHsdbp {\n    padding: 10px;\n    border-bottom: 1px solid #eee;\n}\n\n._3MTfZx49vKjBd47_OJSNki {\n    color: #716aca;\n    margin-left: 10px;\n}", ""]);

// exports
exports.locals = {
	"itemWrapper": "_2YtUOFlLfaFoG-k9qmq1Vg",
	"item": "_3Z62d4RuhmEDX3rPZT_sUl",
	"itemLabel": "_7kaH6n-apg7_-5idEF1GE",
	"itemValue": "PWiaqn4L2eIiy4rHsdbp",
	"approvalStatusMark": "_3MTfZx49vKjBd47_OJSNki"
};