// @flow

import React, {Component} from 'react';
import {LLMsg} from "../../../../../IntlCapture";


class SchoolApplicationTableHeader extends Component<any, any> {

    render() {
        return <thead className="m-datatable__head">
        <tr className="m-datatable__row" style={{left: '0px'}}>
            <th className="m-datatable__cell m-datatable__toggle-detail m-datatable__cell--sort">
                <span style={{width: '21px'}}/>
            </th>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                                <span style={{width: '50px'}}>
                                    {LLMsg("COMMON.ID")}
                               </span>
            </th>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                                <span style={{width: '150px'}}>
                                    {LLMsg("COMMON.EVENT.EVENT_NAME")}
                               </span>
            </th>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                                <span style={{width: '150px'}}>
                                    {LLMsg("REGISTER_PAGE.TEACHER_NAME")}
                               </span>
            </th>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                                <span style={{width: '100px'}}>
                                    {LLMsg("COMMON.SCHOOL.APPLICATION_STATUS")}
                               </span>
            </th>
            <th data-field="OrderID" className="m-datatable__cell m-datatable__cell--sort">
                                <span style={{width: '200px'}}>
                                    {LLMsg("COMMON.SCHOOL.SUBMISSION_TIME")}
                               </span>
            </th>
        </tr>
        </thead>;
    }
}

export default SchoolApplicationTableHeader;