// @flow

import React, {Component} from "react";
import MapEmbeddedFragment from "../../project/MapEmbeddedFragment";

class ProjectMapEmbeddedPage extends Component<any, any> {
    render() {
        return <MapEmbeddedFragment/>;
    }
}

export default ProjectMapEmbeddedPage;