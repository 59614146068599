exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3W2iujiwASwsz-Qvdt7KEg {\n    margin-left: 10px;\n    cursor: pointer;\n    border: solid 1px whitesmoke;\n    display: block;\n    padding: 10px;\n    border-radius: 6px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    background-color: #ebedf2;\n}\n\n._22_EOIIemmrY-RpbmoPEn5 {\n    padding-left: 15px;\n}\n\n._1ldm_CN5n3_gUvQmyJAF-T {\n    font-size: 1.5em;\n}\n\n._2JiVI7QfsH-b6CRSrIqB1v {\n    color: #575962;\n\n}", ""]);

// exports
exports.locals = {
	"button": "_3W2iujiwASwsz-Qvdt7KEg",
	"content": "_22_EOIIemmrY-RpbmoPEn5",
	"title": "_1ldm_CN5n3_gUvQmyJAF-T",
	"desc": "_2JiVI7QfsH-b6CRSrIqB1v"
};