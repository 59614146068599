// @flow
import React, {Component} from "react";
import CopyrightFragment from "./CopyrightFragment";
import Page from "../../bundle/Page";
import {LLMsg} from "../../IntlCapture";
import {Link} from "react-router-dom";

type FooterFragmentProps = {}

class FooterFragment extends Component<FooterFragmentProps, any> {
    render() {
        return <footer className="m-grid__item		m-footer ">
            <div className="m-container m-container--fluid m-container--full-height m-page__container">
                <div className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
                    <CopyrightFragment/>
                    <div
                        className="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
                        <ul className="m-footer__nav m-nav m-nav--inline m--pull-right">
                            <li className="m-nav__item">
                                <a href={Page.externals.scaleContactUs()} target="_blank" className="m-nav__link">
                                    <span className="m-nav__link-text">
                                        {LLMsg("COMMON.FOO.CONTACT_US")}
                                    </span>
                                </a>
                            </li>
                            <li className="m-nav__item">
                                <a href={Page.internals.urls.misc.privacy()} target="_blank" className="m-nav__link">
                                    <span className="m-nav__link-text">
                                        {LLMsg("COMMON.FOO.PRIVACY")}
                                    </span>
                                </a>
                            </li>
                            <li className="m-nav__item">
                                <a href={Page.internals.urls.misc.copyright()} target="_blank" className="m-nav__link">
                                    <span className="m-nav__link-text">
                                        {LLMsg("COMMON.FOO.COPYRIGHT")}
                                    </span>
                                </a>
                            </li>
                            <li className="m-nav__item">
                                <a href={Page.internals.urls.misc.disclaimer()} target="_blank" className="m-nav__link">
                                    <span className="m-nav__link-text">
                                        {LLMsg("COMMON.FOO.DISCLAIMER")}
                                    </span>
                                </a>
                            </li>
                            <li className="m-nav__item">
                                <a href={Page.internals.urls.misc.terms()} target="_blank" className="m-nav__link">
                                    <span className="m-nav__link-text">
                                        {LLMsg("COMMON.FOO.TERMS")}
                                    </span>
                                </a>
                            </li>
                            <li className="m-nav__item">
                                <a href={Page.internals.urls.misc.thirdPartyUse()} target="_blank"
                                   className="m-nav__link">
                                    <span className="m-nav__link-text">
                                        {LLMsg("COMMON.FOO.THIRD_PARTY")}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>;
    }
}

export default FooterFragment;