// @flow

import PageUrl from "./PageUrl";
import StringUtil from "../../util/StringUtil";

export default class EventCodePageUrl {

    static admin = {
        list: (): string => {
            return PageUrl.ADMIN_EVENT_CODE_LIST_URL;
        },
        detail(eventCodeId: string): string {
            return StringUtil.replaceAllText(PageUrl.ADMIN_EVENT_CODE_DETAIL_URL, [
                {
                    key: ":eventCodeId",
                    value: eventCodeId
                }
            ]);
        },
        create: (): string => {
            return PageUrl.ADMIN_EVENT_CODE_CREATE_URL;
        }
    };

}