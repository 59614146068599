exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3mceSubgEFsUN64RQkLX8V {\n    margin-left: 10px;\n}\n\n._10tt2wGY6gqDl5_4DO-Mqq {\n    flex-wrap: wrap;\n    display: flex;\n    text-align: center;\n}\n\n._-5j_3VD2hrGMGK8v-pVXr {\n    margin-bottom: 20px;\n}", ""]);

// exports
exports.locals = {
	"statsRow": "_3mceSubgEFsUN64RQkLX8V",
	"statsBox": "_10tt2wGY6gqDl5_4DO-Mqq",
	"statsItem": "_-5j_3VD2hrGMGK8v-pVXr"
};