// @flow

import React from 'react';
import Constant from "../../../bundle/Constant";
import Api from "../../../bundle/Api";
import swal from "sweetalert2";
import {createAction} from "../../../redux/reducer";
import connect from "react-redux/es/connect/connect";
import {LLMsg} from "../../../IntlCapture";

type RenameChartButtonFragmentProps = {
    chartId: string,
    chartName: string,

    dispatchChartRenamed?: any
}

export const renameChartPrompt = (chartName: string, chartId: string, callback: ?Function) => {

    swal({
        title: LLMsg("COMMON.CHART.UPDATE_CHART"),
        input: 'text',
        type: 'info',
        inputValue: chartName,
        inputPlaceholder: LLMsg("COMMON.CHART.CHART_NAME"),
        showCancelButton: true,
        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
        cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value) {
                    resolve()
                } else {
                    resolve(LLMsg("COMMON.CHART.CHART_NAME_EMPTY"));
                }
            })
        }
    }).then((e) => {
        if (e.dismiss) {
            return;
        }
        if (e.value) {
            Api.services.chart.update.name(chartId, e.value).then(response => {
                if (response.status === 200) {
                    swal(LLMsg("COMMON.ACTION.UPDATE_SUCCESS"), "", "success");
                    callback && callback();
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            }).catch(() => {
                swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
            });
        } else {
            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
        }
    })
};
const RenameChartDropdownButton = (props: RenameChartButtonFragmentProps) => {
    return <li className="m-nav__item ">
        <span className="m-nav__link cursor-pointer" onClick={() => {
            renameChartPrompt(props.chartName, props.chartId, props.dispatchChartRenamed);
        }}>
            <i className={"m-nav__link-icon fa-2x " + Constant.theme.edit.icon}/>
            <span className="m-nav__link-text pl-2 m--icon-font-size-lg1 ">
                {LLMsg("COMMON.CHART.UPDATE_CHART")}
            </span>
        </span>
    </li>
};

const mapDispatchToProps = dispatch => ({
    dispatchChartRenamed: () => dispatch(createAction(Api.actions.chart.RENAMED_CHART))
});

export default connect(null, mapDispatchToProps)(RenameChartDropdownButton);

