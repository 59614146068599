// @flow

import React from 'react';
import Constant from "../../../bundle/Constant";
import swal from "sweetalert2";
import Api from "../../../bundle/Api";
import {LLMsg} from "../../../IntlCapture";

type AddChartToAnyDashboardFragmentProps = {
    chartId: string,
    chartType: string
}

export const addChartToAnyDashboardPrompt = (chartId: string, chartType: string) => {

    Api.services.dashboard.get.list().then((response) => {
        if (response.status === 200) {
            let dashboards: ?Array<Object> = response.data;
            if (!dashboards || dashboards.length === 0) {
                swal("You don't have any dashboard", 'error');
            }
            let inputOptions: { [key: string]: string } = {};
            dashboards && dashboards.forEach(dashboard => {
                inputOptions[dashboard.id] = dashboard.name;
            });
            swal({
                title: LLMsg("COMMON.DASHBOARD.SELECT_A_DASHBOARD"),
                input: 'select',
                type: 'info',
                inputOptions: inputOptions,
                inputPlaceholder: LLMsg("COMMON.DASHBOARD.NO_DASHBOARD_SELECTED"),
                showCancelButton: true,
                confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
                cancelButtonText: LLMsg("COMMON.ACTION.CANCEL"),
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value) {
                            resolve()
                        } else {
                            resolve(LLMsg("COMMON.DASHBOARD.NO_DASHBOARD_SELECTED"));
                        }
                    })
                }
            }).then((e) => {
                if (e.dismiss) {
                    return;
                }
                if (e.value) {

                    let size = Constant.portlet.size.width.w6;
                    if (chartType === Constant.chart.type.gauge) {
                        size = Constant.portlet.size.width.w3;
                    }
                    Api.services.dashboard.update.addChart(e.value, chartId, size).then(response => {
                        if (response.status === 200) {
                            swal(LLMsg("COMMON.ACTION.ADD_SUCCESS"), "", "success");
                        } else {
                            swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                        }
                    }).catch(() => {
                        swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                    });
                } else {
                    swal(LLMsg("COMMON.ACTION.FAILED"), LLMsg("COMMON.ACTION.ACTION_FAILED"), "error");
                }
            })
        }
    });
}

const AddChartToAnyDashboardDropdownButton = (props: AddChartToAnyDashboardFragmentProps) => {
    return <li className="m-nav__item ">
        <span className="m-nav__link cursor-pointer" onClick={() => {
            addChartToAnyDashboardPrompt(props.chartId, props.chartType);
        }}>
            <i className={"m-nav__link-icon fa-2x " + Constant.theme.dashboard.icon}/>
            <span className="m-nav__link-text pl-2 m--icon-font-size-lg1 ">
                {LLMsg("COMMON.DASHBOARD.ADD_CHART_TO_DASHBOARD")}
            </span>
        </span>
    </li>
};
export default AddChartToAnyDashboardDropdownButton;