exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2v8e_FP1k8gQ4h8_EoLxGr {\n    float: right;\n}\n\n._2cSs8X8f28vfncw-o2-Fdq {\n    display: inline-block;\n    cursor: default;\n}", ""]);

// exports
exports.locals = {
	"floatBox": "_2v8e_FP1k8gQ4h8_EoLxGr",
	"title": "_2cSs8X8f28vfncw-o2-Fdq"
};