// @flow

import React, {Component} from 'react';
import HeaderFragment from "../../header/HeaderFragment";
import BodyWrapper from "../../body/BodyWrapper";
import SidebarWrapper from "../../sidebar/SidebarWrapper";
import GeneralSidebarFragment from "../../sidebar/GeneralSidebarFragment";
import ContentWrapper from "../../content/ContentWrapper";
import ContentHeaderFragment from "../../content/ContentHeaderFragment";
import FooterFragment from "../../footer/FooterFragment";
import BackToTopButton from "../../BackToTopButton";
import StickyQuickNav from "../../SickyQuickNav";
import ContentInnerWrapper from "../../content/ContentInnerWrapper";
import TopicDetailFragment from "../../topic/TopicDetailFragment/index";
import Breadcrumb from "../../content/Breadcrumb";
import Page from "../../../bundle/Page";
import {LLMsg} from "../../../IntlCapture";

class TopicDetailPage extends Component<any, any> {
    render() {
        return <div className="m-grid m-grid--hor m-grid--root m-page">
            <HeaderFragment/>
            <BodyWrapper>
                <SidebarWrapper>
                    <GeneralSidebarFragment/>
                </SidebarWrapper>
                <ContentWrapper>
                    <ContentHeaderFragment>
                        <Breadcrumb items={[
                            {linkTo: Page.internals.urls.topic.list(), displayText: LLMsg("COMMON.TOPIC.TOPIC_LIST")},
                            {displayText: LLMsg("COMMON.TOPIC.TOPIC")},
                            {displayText: LLMsg("COMMON.BREADCRUMB.DETAIL")}
                        ]}/>
                    </ContentHeaderFragment>
                    <ContentInnerWrapper>
                        <TopicDetailFragment/>
                    </ContentInnerWrapper>
                </ContentWrapper>
            </BodyWrapper>

            <FooterFragment/>
            <BackToTopButton/>
            <StickyQuickNav/>
        </div>
    }
}

export default TopicDetailPage;