// @flow
import React from 'react';
import styles from './FilterFragment.css';
import {LLMsg} from "../../../IntlCapture";

type AndOperatorFragmentProps = {
    onChangeCallback: (conditionKey: number, operatorIndex: number) => void,
    conditionKey: number,
    operatorIndex: number
}

const AndOperatorFragment = (props: AndOperatorFragmentProps) => {
    return <span className={styles.andOperator} onClick={() => {
        props.onChangeCallback(props.conditionKey, props.operatorIndex);
    }}>
        {LLMsg("EVENT_TRIGGER.AND")}
    </span>;
};

export default AndOperatorFragment;