exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".J0h9Ja4bXYOrN9HYw5cHM {\n    width: 100%;\n}\n\n._363qTKquAOx15OoOvdd9_1 {\n    border: none;\n}", ""]);

// exports
exports.locals = {
	"box": "J0h9Ja4bXYOrN9HYw5cHM",
	"iframe": "_363qTKquAOx15OoOvdd9_1"
};