// @flow
import React from 'react';
import styles from './LicenseConsumeFragment.css';
import ErrorUtil from "../../util/ErrorUtil";
import Api from "../../bundle/Api";
import swal from "sweetalert2";
import {LLMsg} from "../../IntlCapture";
import Page from "../../bundle/Page";
import {RouterHistory} from "../../AppRoute";
import Image from "../../bundle/Image";
import {renderToStaticMarkup} from 'react-dom/server';
import LoadingUtil from "../../util/LoadingUtil";
import type {LicenseDetail} from "../../model/model";
import TimeUtil from "../../util/TimeUtil";

type LicenseConsumeFragmentState = {
    licenseKeyInputValue: string
}

export function LicenseDetailPrompt(licenseDetail: LicenseDetail) {
    return <div>
        <div>{LLMsg("MY_SUB.LICENSE_KEY") + ": " + licenseDetail.licenseKey}</div>
        <div>{LLMsg("MY_SUB.LICENSED_TO") + ": " + licenseDetail.licenseTargetName}</div>
        <div>{LLMsg("MY_SUB.EXPIRY_DATE") + ": " + TimeUtil.convertTimeFormat(licenseDetail.trialEndDate, TimeUtil.FULL_DATE_DISPLAY_FORMAT)}</div>
        <div>{LLMsg("MY_SUB.CONSUMED") + ": " + licenseDetail.currentConsumptionCount + "/" + licenseDetail.maxConsumptionCount}</div>
        <div>{LLMsg("MY_SUB.CONCURRENT_LOGIN") + ": " + licenseDetail.currentConcurrentLoginCount + "/" + licenseDetail.maxConcurrentLoginCount}</div>
    </div>;
}

class LicenseConsumeFragment extends React.Component<any, LicenseConsumeFragmentState> {


    constructor(props: any) {
        super(props);
        this.state = {licenseKeyInputValue: ""}
    }

    consumeLicense = () => {
        let licenseKey = this.state.licenseKeyInputValue;
        if (licenseKey) {
            Api.services.license.consumeLicense(licenseKey).then((response) => {
                if (response.status === 200) {
                    swal(LLMsg("COMMON.ACTION.ADD_SUCCESS"), "", "success").then(() => {
                        RouterHistory().push(Page.internals.urls.misc.home());
                        window.location.reload();
                    });
                }
            }).catch((e) => {
                ErrorUtil.promptError(e);
            })
        }
    };

    viewLicense = () => {
        let licenseKey = this.state.licenseKeyInputValue;
        if (licenseKey) {
            LoadingUtil.showFullScreenLoading();
            Api.services.license.getLicenseDetail(licenseKey).then((response) => {
                LoadingUtil.hideFullScreenLoading();
                if (response.status === 200) {
                    swal({
                        html: renderToStaticMarkup(LicenseDetailPrompt(response.data)),
                        showCancelButton: false,
                        confirmButtonText: LLMsg("COMMON.ACTION.CONFIRM"),
                    })
                }
            }).catch((e) => {
                LoadingUtil.hideFullScreenLoading();
                if (ErrorUtil.hasErrorCode(e, ErrorUtil.LICENSE_NOT_FOUND)) {
                    swal(LLMsg("COMMON.ACTION.NO_RECORD"), LLMsg("REGISTER_PAGE.LICENSE_KEY_INVALID"), "error");
                } else {
                    ErrorUtil.promptError(e);
                }
            })
        }
    };

    render() {
        return <div className={"m-portlet m-portlet--unair " + styles.box}>
            <div className="m-portlet__body pt-2">
                <div>
                    <div className={styles.imageBox}>
                        <img src={Image.urls.getRegisterWithLicenseImage()} width={100}/>

                    </div>
                    <div className={styles.title}>
                        {LLMsg("MY_SUB.ENTER_LICENSE_KEY")}
                    </div>
                    <div className="input-group">
                        <input type="text"
                               className={"form-control m-input "}
                               onChange={(e) => {
                                   this.setState({licenseKeyInputValue: (e.target.value || "").toUpperCase()})
                               }}
                               autoFocus={true}
                               value={this.state.licenseKeyInputValue}
                               placeholder={LLMsg("MY_SUB.LICENSE_KEY")}/>
                    </div>
                    <div className={styles.desc}>
                        {LLMsg("MY_SUB.ENTER_LICENSE_KEY_HINTS")}
                    </div>
                    <button type="button"
                            className={"btn btn-success " + styles.submitBtn}
                            onClick={this.consumeLicense}>
                        {LLMsg("MY_SUB.SUBMIT_BUTTON")}
                    </button>
                    <button type="button"
                            className={"btn btn-link no-a-effect " + styles.checkBtn}
                            onClick={this.viewLicense}>
                        {LLMsg("MY_SUB.VIEW_LICENSE_DETAIL_BUTTON")}
                    </button>
                </div>
            </div>
        </div>

    }
}

export default LicenseConsumeFragment;