// @flow

import React, {Component} from "react";
import _ from 'lodash';
import {LLMsg} from "../../IntlCapture";
import UpdateDataRecordTypeButton from "./UpdateDataRecordTypeButton";
import DeleteDataRecordTypeButton from "./DeleteDataRecordTypeButton";
import type {DataRecordType} from "../../model/model";
import {ClassNames} from "../../bundle/ClassNames";

type DataTyeListFragmentProps = {
    settings: Object,
    dataRecordTypes: Array<{
        id: string,
        description: string,
        dataRecordTypeName: string,
        keyName: string,
        devices: Array<Object>
    }>,
    topicId: string
}

class DataTyeListFragment extends Component<DataTyeListFragmentProps, any> {

    constructor(props: DataTyeListFragmentProps) {
        super(props);
    }

    returnEmptyDataTypeRow(index: number) {
        return <tr key={index}>
            <th scope="row">{"-"}</th>
            <td>{"-"}</td>
            <td>{"-"}</td>
            <td>{"-"}</td>
            <td>{"-"}</td>
        </tr>;
    }

    render() {
        let emptyRowCount = 5 - this.props.dataRecordTypes.length;
        emptyRowCount = emptyRowCount < 0 ? 0 : emptyRowCount;

        let emptyRow = [];
        for (let i = 0; i < emptyRowCount; i++) {
            emptyRow.push(this.returnEmptyDataTypeRow(i));
        }

        return <div className={""} style={{boxShadow: "0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important"}}>
            <table className={ClassNames("table table-bordered table-striped m-table")}>
                <thead>
                <tr>
                    <th width={20}>#</th>
                    <th>{LLMsg("COMMON.DATA_TYPE.DATA_TYPE")}</th>
                    <th>{LLMsg("COMMON.DATA_TYPE.DATA_TYPE_KEY")}</th>
                    <th>{LLMsg("COMMON.DATA_TYPE.DATA_TYPE_DESC")}</th>
                    <th style={{width: "100px"}}>{" "}</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.props.dataRecordTypes && this.props.dataRecordTypes.length > 0 && _.sortBy(this.props.dataRecordTypes, ['id']).map((dataRecordType: DataRecordType, index) => {
                        return <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{dataRecordType.dataRecordTypeName}</td>
                            <td>{dataRecordType.keyName}</td>
                            <td>{dataRecordType.description}</td>
                            <td>
                                <UpdateDataRecordTypeButton topicId={this.props.topicId}
                                                            dataTypeId={dataRecordType.id}
                                                            dataTypeDescription={dataRecordType.description}
                                                            dataTypeKey={dataRecordType.keyName}
                                                            dataTypeName={dataRecordType.dataRecordTypeName}/>

                                <DeleteDataRecordTypeButton topicId={this.props.topicId}
                                                            dataTypeId={dataRecordType.id}/>
                            </td>
                        </tr>
                    })
                }
                {emptyRow}
                </tbody>
            </table>
            <div style={{backgroundColor: "inherit", border: "0px"}}>
            </div>
        </div>
    }
}

export default DataTyeListFragment;