// @flow
import React, {Component} from "react";
import SidebarSingleItem from "./SidebarSingleItem";
import SidebarDropdown from "./SidebarDropdown";
import SidebarDropdownItem from "./SidebarDropdownItem";
import SidebarGroup from "./SidebarGroup";
import Page from "../../bundle/Page";
import Constant from "../../bundle/Constant";
import {LLMsg} from "../../IntlCapture";
import SidebarGroupHeader from "./SidebarGroupHeader";
import UserDao from "../../dao/UserDao";
import * as PubSub from "pubsub-js";
import Api from "../../bundle/Api";

type SidebarProps = {}

class GeneralSidebarFragment extends Component<SidebarProps, any> {

    subToken: any;

    componentDidMount() {
        this.subToken = PubSub.subscribe(Api.actions.user.GET_MYSELF_DONE_ACTION_TYPE, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subToken);
    }

    render() {
        return <div id="m_ver_menu"
                    className="m-aside-menu  m-aside-menu--skin-light m-aside-menu--submenu-skin-light  m-scroller ps ps--active-y"
                    m-menu-vertical={1} m-menu-scrollable={1} m-menu-dropdown-timeout={500}>

            <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">

                {
                    UserDao.role.includes(Constant.authority.student) && <SidebarGroup>
                        <SidebarSingleItem linkTo={Page.internals.urls.misc.home()} displayText={LLMsg("COMMON.HOME")}
                                           colorCode={Constant.theme.home.color}
                                           iconClassname={Constant.theme.home.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.project.list()}
                                           displayText={LLMsg("COMMON.PROJECT.PROJECT_LIST")}
                                           colorCode={Constant.theme.project.color}
                                           iconClassname={Constant.theme.project.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.dashboard.getDashboardDefaultPageUrl()}
                                           displayText={LLMsg("COMMON.DASHBOARD.DASHBOARD")}
                                           colorCode={Constant.theme.dashboard.color}
                                           iconClassname={Constant.theme.dashboard.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.chart.getChartCreatePageUrl()}
                                           displayText={LLMsg("COMMON.CHART.CHART")}
                                           colorCode={Constant.theme.chart.color}
                                           iconClassname={Constant.theme.chart.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.license.myLicenseHome()}
                                           displayText={LLMsg("MY_SUB.MY_SUBSCRIPTION")}
                                           colorCode={Constant.theme.license.color}
                                           iconClassname={Constant.theme.license.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.misc.updateLogs()}
                                           displayText={LLMsg("PAGE.TITLE.WHATS_NEW")}
                                           colorCode={Constant.theme.whatsNew.color}
                                           iconClassname={Constant.theme.whatsNew.icon}/>

                    </SidebarGroup>
                }
                {
                    UserDao.role.includes(Constant.authority.makerCloudAdmin) && <SidebarGroup>
                        <hr/>
                        <SidebarGroupHeader displayText={"Admin Group"}/>
                        <SidebarSingleItem linkTo={Page.internals.urls.school.list()}
                                           displayText={LLMsg("COMMON.SCHOOL.SCHOOL_LIST")}
                                           colorCode={Constant.theme.school.color}
                                           iconClassname={Constant.theme.school.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.license.adminHome()}
                                           displayText={LLMsg("MY_SUB.MANAGE_LICENSE")}
                                           colorCode={Constant.theme.adminLicense.color}
                                           iconClassname={Constant.theme.adminLicense.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.eventCode.admin.list()}
                                           displayText={LLMsg("COMMON.EVENT.EVENT_CODE")}
                                           colorCode={Constant.theme.eventCode.color}
                                           iconClassname={Constant.theme.eventCode.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.user.admin.list()}
                                           displayText={LLMsg("COMMON.MENU.USER_LIST")}
                                           colorCode={Constant.theme.user.color}
                                           iconClassname={Constant.theme.user.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.user.admin.whitelistUserList()}
                                           displayText={LLMsg("COMMON.MENU.WHITELIST_USER")}
                                           colorCode={Constant.theme.whitelistUser.color}
                                           iconClassname={Constant.theme.whitelistUser.icon}/>

                        <SidebarSingleItem linkTo={Page.internals.urls.maintenancePeriod.admin.list()}
                                           displayText={LLMsg("MAINTAIN.MAINTENANCE_PERIOD_LABEL")}
                                           colorCode={Constant.theme.maintenance.color}
                                           iconClassname={Constant.theme.maintenance.icon}/>
                        <SidebarSingleItem linkTo={Page.internals.urls.virtualSmartHome.getVirtualLightBulbUrl()}
                                           displayText={LLMsg("COMMON.VIRTUAL.VIRTUAL_LIGHT_BULB")}
                                           colorCode={Constant.theme.virtualSmartHomeLightBulb.color}
                                           iconClassname={Constant.theme.virtualSmartHomeLightBulb.icon}/>
                    </SidebarGroup>
                }
            </ul>
        </div>;
    }
}

export default GeneralSidebarFragment;