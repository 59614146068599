exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3P1zK4zIgbNtSbrqv4MlsR {\n    text-align: center;\n}\n._3mzGBrZn8JD9Jbo8xixz5J {\n    width: 100%;\n    max-width: 240px;\n    margin-bottom: 24px;\n}", ""]);

// exports
exports.locals = {
	"light_bulb_image_wrapper": "_3P1zK4zIgbNtSbrqv4MlsR",
	"light_bulb_image": "_3mzGBrZn8JD9Jbo8xixz5J"
};